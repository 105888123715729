import React, { useState } from 'react';
import './ShowOrderStatus.css';
import chefCooking from '../../Assets/Image/chefCooking.jpg';
import qrCode from "../../Assets/Icons/qr-code-icon.png";
import QRCodeModal from './QRCodeModal';

const ShowOrderStatus = ({ orderStatus, menuType, restaurant }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className='d-flex justify-content-between'>
      {
        orderStatus === "Ready" && (menuType === "Online" || menuType === "Delivery")
          ?
          <button className="qr-code-btn" title="Get QR code" onClick={() => setModalShow(true)}>
            <img src={qrCode} alt="qr code icon" />
          </button>
          :
          ""
      }
      <div className='mx-2'>
        <img className='chefCookingIcon' src={chefCooking} alt='chef cooking' />
      </div>
      <div>
        {orderStatus === 'Pending' ? (
          <p className='order-status pending-status'> {orderStatus} </p>
        ) : orderStatus === 'Preparing' ? (
          <p className='order-status preparing-status'> {orderStatus} </p>
        ) : orderStatus === 'Ready' ? (
          <p className='order-status ready-status'> {orderStatus} </p>
        ) : orderStatus === 'Out for delivery' ? (
          <p className='order-status out-for-delivery-status'>
            {' '}
            {orderStatus}{' '}
          </p>
        ) : <p className="order-status queue-status">{orderStatus === 'Queue' ? 'Order Placed' : orderStatus}</p>}
      </div>

      <QRCodeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        restaurant={restaurant}
      />
    </div>
  );
};

export default ShowOrderStatus;
