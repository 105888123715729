import React from 'react';
import SharePostDisplay from './PostDisplay/SharePostDisplay/SharePostDisplay';

const TestSharePost = () => {
    return (
        <div >
            <div className='row'>
                <div className='col-2'>

                </div>
                <div className='col-6'>
                    <SharePostDisplay />
                </div>
                <div className='col-2'>

                </div>
            </div>

        </div>
    );
};

export default TestSharePost;