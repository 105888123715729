// Initial state for the cart
const initialState = {
    items: [],
    totalCount: 0,
};

// Action types
const SET_CART_DATA = "SET_CART_DATA";
const INCREMENT_ITEM_COUNT = "INCREMENT_ITEM_COUNT";
const DECREASE_ITEM_COUNT = "DECREASE_ITEM_COUNT";

// Reducer function
const cartCounterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CART_DATA:
            const totalCount = action.payload.reduce(
                (count, restaurant) =>
                    count +
                    restaurant.subOrders.reduce(
                        (subCount, subOrder) => subCount + subOrder.itemDetails.length,
                        0
                    ),
                0
            );
            return {
                ...state,
                items: action.payload,
                totalCount,
            };

        case INCREMENT_ITEM_COUNT:
            return {
                ...state,
                totalCount: state.totalCount + action.payload,
            };

        case DECREASE_ITEM_COUNT:
            return {
                ...state,
                totalCount: state.totalCount - action.payload,
            }

        default:
            return state;
    }
};

// Action creators
export const setCartItems = (cartData) => ({
    type: SET_CART_DATA,
    payload: cartData,
});

export const incrementItemCount = (count) => ({
    type: INCREMENT_ITEM_COUNT,
    payload: count,
});

export const decreaseItemCount = (count) => ({
    type: DECREASE_ITEM_COUNT,
    payload: count,
})

export default cartCounterReducer;  