import { FETCH_FILTER_BAR_DATA } from "../../actions/types";

const initialState = {
    allSectionSubSection: {
        sectionList: [],
        subSectionList: []
    },
};

const filterBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTER_BAR_DATA:
            return {
                ...state,
                allSectionSubSection: action.payload,
            };
        default:
            return state;
    }
};

export default filterBarReducer;
