import { message } from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import bellImg from "../../Assets/Icons/bell-ring.png";
import bellBottomImg from "../../Assets/Icons/bottom-bell.png";
import likedImg from "../../Assets/Image/user.png";
import FollowImg from "../../Assets/Image/Follow.png";
import loadingGif from "../../Assets/Image/loading.gif";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import NotificationData from "./NotificationData";
function Notification({ chat, usedIn }) {
  // console.log(typeof usedIn);
  const [showDetails, setShowDetails] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const notificationDetails = useRef(null);
  const item = useRef(null);
  const navbarTopRef = useRef(null);
  const [activeCategory, setActiveCategory] = useState("Activity");
  const category = ["Activity", "You", "Likes"];
  const [userId, setUserId] = useState();
  const [notifications, setNotifications] = useState([]);
  const [totalNotification, setTotalNotification] = useState(0);
  const [isDivVisible, setDivVisible] = useState(true);
  const [showLoding, setShowLoading] = useState(true);
  const [isTop, setIsTop] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [totalPage, setTotalPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // new
  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(true);
  //

  // tabs
  const [activeTab, setActiveTab] = useState(1);

  // State for Tab 1
  const [tab1Data, setTab1Data] = useState([]);
  const [tab1Page, setTab1Page] = useState(1);
  const [tab1HasMore, setTab1HasMore] = useState(true);

  // State for Tab 2
  const [tab2Data, setTab2Data] = useState([]);
  const [tab2Page, setTab2Page] = useState(1);
  const [tab2HasMore, setTab2HasMore] = useState(true);

  // State for Tab 3
  const [tab3Data, setTab3Data] = useState([]);
  const [tab3Page, setTab3Page] = useState(1);
  const [tab3HasMore, setTab3HasMore] = useState(true);

  const fetchDataForTab = (tab, page, initial = false) => {
    // Make API call based on the provided tab and update the corresponding state
    // You'll need to implement this logic for each tab separately

    if (tab == 1 && tab1HasMore) {
      axios
        .get(
          `${process.env.REACT_APP_SECRET}/api/Notification/GetNotificationByPage?userId=${userId}&&pageNumber=${page}&&pageSize=${perPage}`
        )
        .then((res) => {
          if (res.data.notificationList == 0) {
            setTab1HasMore(false);
          }
          if (tab1Data.length < res.data.totalNotification) {
            setTab1Data((prevValue) => [
              ...prevValue,
              ...res.data.notificationList,
            ]);
          }

          if (!initial) {
            setTab1Page(tab1Page + 1);
          }
        });
    }
    if (tab == 2 && tab2HasMore) {
      axios
        .get(
          `${process.env.REACT_APP_SECRET}/api/Notification/GetAllYouNotificationListByUserId?userId=${userId}&&pageNumber=${page}&&pageSize=${perPage}`
        )
        .then((res) => {
          if (res.data.notificationList == 0) {
            setTab2HasMore(false);
          }
          if (tab2Data.length < res.data.totalNotification) {
            setTab2Data((prevValue) => [
              ...prevValue,
              ...res.data.notificationList,
            ]);
          }

          if (!initial) {
            setTab2Page(tab2Page + 1);
          }
        });
    }
    if (tab == 3 && tab3HasMore) {
      axios
        .get(
          `${process.env.REACT_APP_SECRET}/api/Notification/LikeNotificationListByUserId?userId=${userId}&&pageNumber=${page}&&pageSize=${perPage}`
        )
        .then((res) => {
          if (res.data.notificationList == 0) {
            setTab3HasMore(false);
          }
          if (tab3Data.length < res.data.totalNotification) {
            setTab3Data((prevValue) => [
              ...prevValue,
              ...res.data.notificationList,
            ]);
          }

          if (!initial) {
            setTab3Page(tab3Page + 1);
          }
        });
    }
    fetchTotalNotification(userId);
  };
  // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    fetchTotalNotification(userId);
    // Optionally, you can reset the page and data for the new active tab here
  };
  useEffect(() => {
    // Fetch initial data when the component mounts for the active tab
    if (userId) {
      fetchDataForTab(activeTab, 1, true);
    }
  }, [userId, activeTab]);

  const [urls, setUrls] = useState([
    {
      Activity: "api/Notification/NotificationListByUserId?userId=",
      You: "api/Notification/GetAllYouNotificationListByUserId?userId=",
      Likes: "api/Notification/LikeNotificationListByUserId?userId=",
    },
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDetails &&
        notificationDetails.current &&
        !notificationDetails.current.contains(event.target) &&
        !item.current.contains(event.target)
      ) {
        setShowDetails(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  // onclick
  useEffect(() => {
    var decoded = {};
    const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      setUserId(decoded.UserId);
      // fetchNotification(decoded.UserId);
      fetchTotalNotification(decoded.UserId);
    }
  }, []);

  useEffect(() => {
    setShowLoading(true);
    if (userId) {
      axios
        // .get(
        //   `${process.env.REACT_APP_SECRET}/${urls[0][activeCategory]}${userId}`
        // )
        .get(
          `${process.env.REACT_APP_SECRET}/api/Notification/GetNotificationByPage?userId=${userId}&&pageNumber=1&&pageSize=${perPage}`
        )
        .then((res) => {
          setNotifications(res.data.notificationList);
          setTotalPage(Math.ceil(res.data.totalNotification / perPage));
          setShowLoading(false);
        });
    }
  }, [userId, activeCategory]);

  // scroll
  useEffect(() => {
    // window.addEventListener("scroll", (event) => {
    //   const filterBarRect = navbarTopRef?.current?.getBoundingClientRect();
    //   const isAboveViewport = filterBarRect.top === 0 ? true : false;
    //   setNavbarOnTop(isAboveViewport);
    //   var top = filterBarRect.top * -1;
    //   var scrollval = scrollValue * -1;
    //   if (top > scrollval) {
    //     setSlideUp(true);
    //     setShowDetails(false);
    //   } else if (top <= 0) {
    //     setSlideUp(true);
    //   } else {
    //     setSlideUp(false);
    //     setShowDetails(false);
    //   }
    //   var s = filterBarRect.top;
    //   setscrollValue(s);
    // });
  }, []);

  // total notification
  const fetchTotalNotification = (userId) => {
    if (userId) {
      axios
        .get(
          `${process.env.REACT_APP_SECRET}/api/Notification/GetUnreadNotificationCountByUserId?id=${userId}`
        )
        .then((res) => {
          setTotalNotification(res.data);
        })
        .catch();
    }
  };

  // new notification response
  useEffect(() => {
    if (Object.keys(chat.newNotification).length > 0) {
      console.log("--------------");
      console.log(chat.newNotification);
      console.log("-------------");
      setTotalNotification(totalNotification + 1);
      tab1Data.unshift(chat.newNotification);
    }

    // notifications.unshift(chat.newNotification);
  }, [chat.newNotification]);

  // popup Handler
  const popupHandler = () => {
    fetchTotalNotification(userId);
    if (showDetails) {
      setShowDetails(false);
    } else {
      setShowDetails(true);
    }
  };

  const fetchMoreData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SECRET}/api/Notification/GetNotificationByPage?userId=${userId}&pageNumber=${page}&pageSize=${perPage}`
      )
      .then((res) => {
        if (res.data.notificationList.length == 0) {
          setHasMore(false);
        }
        setNotifications((prevValue) => [
          ...prevValue,
          ...res.data.notificationList,
        ]);

        setPage((prevValue) => prevValue + 1);
      });
  };
  return (
    <div className=" ">
      <div ref={navbarTopRef}></div>
      <div
        className={`${showDetails ? "notification_details" : "d-none"} `}
        ref={notificationDetails}
      >
        <div className="notification_modal">
          <h5 className="text-center w-bolder ">Notifications</h5>
          <ul className="notfication_cat">
            {category.map((cat, indx) => (
              <li
                className={indx + 1 == activeTab ? "active" : ""}
                // onClick={() => setActiveCategory(cat)}
                onClick={() => handleTabChange(indx + 1)}
                key={indx}
              >
                {cat}
              </li>
            ))}
          </ul>
          {/* modal content */}
          <div className="notifications_contents">
            {notifications.length > 0 ? (
              <>
                {showLoding ? (
                  <img
                    src={loadingGif}
                    alt=""
                    className="img-fluid"
                    style={{
                      width: "8rem",
                      height: "8rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "inherit",
                    }}
                  />
                ) : (
                  <>
                    {" "}
                    {activeTab === 1 && (
                      <InfiniteScroll
                        dataLength={tab1Data.length}
                        next={() => fetchDataForTab(1, tab1Page + 1)}
                        hasMore={tab1HasMore}
                        height={400}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            {tab1Data.length == 0 ? (
                              <b>No Notification</b>
                            ) : (
                              <b>You Have viewed all Notifications!</b>
                            )}
                          </p>
                        }
                        loader={
                          <img
                            src={loadingGif}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "8rem",
                              height: "8rem",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "inherit",
                            }}
                          />
                        }
                      >
                        {tab1Data.map((notification, indx) => (
                          <NotificationData
                            key={indx}
                            notification={notification}
                            indx={indx}
                            FollowImg={FollowImg}
                            likedImg={likedImg}
                            tab={1}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                    {activeTab === 2 && (
                      <InfiniteScroll
                        dataLength={tab2Data.length}
                        next={() => fetchDataForTab(2, tab2Page + 1)}
                        hasMore={tab2HasMore}
                        height={400}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            {tab2Data.length == 0 ? (
                              <b>No Notification</b>
                            ) : (
                              <b>You Have viewed all Notifications!</b>
                            )}
                          </p>
                        }
                        loader={
                          <img
                            src={loadingGif}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "8rem",
                              height: "8rem",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "inherit",
                            }}
                          />
                        }
                      >
                        {tab2Data.map((notification, indx) => (
                          <NotificationData
                            key={indx}
                            notification={notification}
                            indx={indx}
                            FollowImg={FollowImg}
                            likedImg={likedImg}
                            tab={2}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                    {activeTab === 3 && (
                      <InfiniteScroll
                        dataLength={tab3Data.length}
                        next={() => fetchDataForTab(3, tab3Page + 1)}
                        hasMore={tab3HasMore}
                        height={400}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            {tab3Data.length == 0 ? (
                              <b>No Notification</b>
                            ) : (
                              <b>You Have viewed all Notifications!</b>
                            )}
                          </p>
                        }
                        loader={
                          <img
                            src={loadingGif}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "8rem",
                              height: "8rem",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "inherit",
                            }}
                          />
                        }
                      >
                        {tab3Data.map((notification, indx) => (
                          <NotificationData
                            key={indx}
                            notification={notification}
                            indx={indx}
                            FollowImg={FollowImg}
                            likedImg={likedImg}
                            tab={3}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                  </>
                )}
              </>
            ) : (
              <h5 className="text-center">No Notification</h5>
            )}
          </div>
        </div>
      </div>
      <div ref={item}>
        <div className="">
          <div className={usedIn === "bottomBar" ? "popup-bottom" : "popup"}>
            <img
              onClick={() => popupHandler()}
              src={usedIn === "bottomBar" ? bellBottomImg : bellImg}
              // className= {usedIn === 'bottomBar' ? 'h-10 w-10' : 'bellIcon'}
              style={
                usedIn === "bottomBar"
                  ? { width: "32px", height: "32px" }
                  : { width: "24px", height: "24px" }
              }
              alt="bell"
            />
            <span className="count">{totalNotification}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  chat: state.chatReducer,
});
export default connect(mapStateToProps)(Notification);
