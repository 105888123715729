export const getFoodStatus = (activeStatus, outOfHourStatus) => {
    if (activeStatus === 0 && outOfHourStatus === 0) {
      return "Unavailable";
    } else if (activeStatus === 0 && outOfHourStatus === 1) {
      return "Unavailable";
    } else if (activeStatus === 1 && outOfHourStatus === 1) {
      return "Out of hour";
    } else if (activeStatus === 1 && outOfHourStatus === 0) {
      return "Available";
    } else {
      return "Unknown status";
    }
  };