import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedMenu } from '../../../actions/Menu/selectedMenuActions';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'swiper/css';

import 'swiper/css/navigation';

import './MenuSlider.css';
import backLeft from '../../../Assets/Icons/left.png';
import table from '../../../Assets/Icons/table.png';
import biker from '../../../Assets/Icons/biker.png';

//react slick start

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  Menuselect,
  FoodItemList,
  SearchLoader,
  SelectedMenu,
} from '../../../actions';
import { GetFoodListByRestaurantId } from '../../Restaurant/RestaurantApi';
import { Navigation, Pagination } from 'swiper';
import { Card } from 'react-bootstrap';

//react slick end

function MenuSlider(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const { resname } = useParams();
  const dispatch = useDispatch();
  const MenuList = useSelector((state) => state.menulist);
  const [MenuSelect, setMenuSelect] = useState();

  //swiper start
  const [fromHome, SetFromHome] = useState(true);
  useEffect(() => {
    console.log(props);
    if (props.from === 2) {
      SetFromHome(false);
    }
  });
  //swiper end

  //slick start
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '10%',
    slidesToShow: 2,
    speed: 500,
  };

  const icons = ["instore", "online"];

  const getRandom = (arr) => {
    return arr[(Math.floor(Math.random() * arr.length))]
  }

  console.log("icons: ", getRandom(icons));

  //slick end
  // const GetFoodListByRestaurantId = async (restaurantId, pageNo, pageSize) => {

  //     var res = {
  //       foodItemList: [],
  //       totalCount:0
  //   }

  //     await axios
  //     .get(apiAddress + "/api/FoodItems/GetFoodItemForClientendByRestaurantIdPaged?urlname="+restaurantId+"&pageNo="+pageNo+"&pageSize="+pageSize)
  //     .then((response) => {
  //       res.totalCount=response.data.totalCount
  //       res.foodItemList= response.data.fooditemsList

  //     });
  //     return res
  //   }

  const [showArrows, setShowArrows] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth > 767);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const GetFoodListByRestaurantIdAndMenuid = async (
    restaurantId,
    menuId,
    pageNo,
    pageSize
  ) => {
    dispatch(SearchLoader(true));
    var res = {
      foodItemList: [],
      totalCount: 0,
    };
    await axios
      .get(
        apiAddress +
        '/api/FoodItems/GetFoodItemForClientendByRestaurantUrlAndMenuIdPaged?urlname=' +
        restaurantId +
        '&menuId=' +
        menuId +
        '&pageNo=' +
        pageNo +
        '&pageSize=' +
        pageSize
      )
      .then((response) => {
        res.totalCount = response.data.totalCount;
        res.foodItemList = response.data.fooditemsList;
        dispatch(SearchLoader(false));
      });
    return res;
  };

  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  const handleMenuSelection = (data) => {
    setMenuSelect(data);
    if (MenuSelect != data) {
      //   dispatch(Menuselect(data));
      dispatch(SelectedMenu(data));
      // var res = GetFoodListByRestaurantIdAndMenuid(resname, data, 1, 10)
      // res.then((res) => {
      //     dispatch(FoodItemList(res))
      // })
    } else {
      //   dispatch(SearchLoader(true));
      dispatch(SelectedMenu(null));
      setMenuSelect(null);
      var res = GetFoodListByRestaurantId(resname, 1, 10);
      res.then((res) => {
        dispatch(FoodItemList(res));
        dispatch(SearchLoader(false));
      });
    }
  };
  return (
    <div className='menuslider'>
      <div>
        {/* <Swiper
          className='menusliderDiv'
          spaceBetween={30}
          grabCursor={false}
          centeredSlides={false}
          slidesPerView={'auto'}
          hashNavigation={{
            watchState: true,
          }}
          navigation={true}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
        >
          {MenuList.length > 0
            ? MenuList.map((d, i) => {
                return (
                  <div>
                    <SwiperSlide>
                      <div
                        className={`d-flex cardShadow cardMargin align-items-center justify-content-around  pb-1  selectionSingleButton ${
                          MenuSelect == d.id ? 'onSelected' : ''
                        }`}
                        value={'store menue'}
                        onClick={() => handleMenuSelection(d.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <h4>{d.menuName}</h4>
                        <img
                          className='menu-selection-icon '
                          src={d.icon}
                          alt='...'
                        />
                      </div>
                    </SwiperSlide>
                  </div>
                );
              })
            : ''}
        </Swiper> */}

        <Swiper
          className='menusliderDiv menuslider row row-cols-1  row-cols-md-4'
          spaceBetween={30}
          grabCursor={false}
          centeredSlides={false}
          slidesPerView={'auto'}
          hashNavigation={{
            watchState: true,
          }}
          // breakpoints={{
          //   300: {
          //     slidesPerView: 1,
          //     spaceBetween: 5,
          //   },
          //   640: {
          //     slidesPerView: 1,
          //     spaceBetween: 5,
          //   },
          //   768: {
          //     slidesPerView: 2,
          //     spaceBetween: 10,
          //   },
          //   1024: {
          //     slidesPerView: 3,
          //     spaceBetween: 10,
          //   },
          //   1200: {
          //     slidesPerView: 3,
          //     spaceBetween: 10,
          //   },
          //   1400: {
          //     slidesPerView: 3,
          //     spaceBetween: 10,
          //   },
          // }}

          breakpoints={{
            300: {
              slidesPerView: 0.5,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 1.3,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 1.1,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 1.8,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
          }}
          navigation={showArrows}
          modules={[Navigation]}
        // pagination={{
        //   dynamicBullets: true,
        //   clickable: true,
        // }}
        >
          {MenuList?.map((d, index) => (
            <SwiperSlide key={index} className='slide-animation'>
              <div
                className={`d-flex align-items-center justify-content-evenly p-2 py-2 selectionSingleButton ${MenuSelect === d.id ? 'onSelected' : ''
                  }`}
                value={'store menu'}
                onClick={() => {
                  handleMenuSelection(d.id);
                  const searchPlaceholder =
                    d.menuName.split('').length > 7
                      ? `Search ${d.menuName.split('').slice(0, 7).join('')}...`
                      : `Search ${d.menuName}`;
                  props.setSearchPlaceholder(searchPlaceholder);
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <div className="menu-type-icon">
                  <img src={`${getRandom(icons) === "online" ? biker : table}`} alt="menu type" />
                </div>

                <Card.Title
                  style={{
                    marginBottom: '0',
                    fontSize: '1.6rem',
                    fontWeight: '500',
                    margin: '0 0.1em'
                  }}
                >
                  {capitalizeFirstLetter(d.menuName)}
                </Card.Title>
                <Card.Img
                  className='menu-selection-icon'
                  src={d.icon}
                  alt={d.menuName}
                  style={{
                    width: '65px',
                    height: '65px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MenuSlider;
