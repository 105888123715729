import React from 'react';
import removeIcon from './../../../Assets/Icons/remove.png';
import editIcon from './../../../Assets/Icons/icon-edit.png';
import '../Components/EditShareYourThoughtsModal.css';

export default function ModalImages({
  croppedImages,
  setCroppedImage,
  initialData,
  setInitialData,
  handleEditImage,
  handleCancelImage,
}) {
  console.log('initial_____', initialData);
  return (
    <>
      {initialData?.map((imageUrl, index) => (
        <div
          key={index}
          style={{
            margin: '10px 0',
            position: 'relative',
          }}
        >
          <button
            type='button'
            style={{
              position: 'absolute',
              top: '5px',
              left: '5px',
              zIndex: '1',
              border: 'none',
              background: `${
                croppedImages[index]?.oneByOne ||
                croppedImages[index]?.fourByFive
                  ? 'lightgray'
                  : 'white'
              }`,
              padding: '5px 10px',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => handleEditImage(imageUrl, index)}
          >
            <span aria-hidden='true'>
              <img
                src={editIcon}
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '5px',
                }}
                alt=''
              />
            </span>
            {croppedImages[index]?.oneByOne
              ? 'Edited (1:1)'
              : croppedImages[index]?.fourByFive
              ? 'Edited (4:5)'
              : 'Edit'}
          </button>
          {/* Cross button */}
          <button
            type='button'
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: '1',
              border: 'none',
              background: 'border-box',
            }}
            onClick={() => handleCancelImage(imageUrl.id, index)}
          >
            <span aria-hidden='true'>
              <img
                src={removeIcon}
                style={{ height: '30px', opacity: '0.5' }}
                alt=''
              />
            </span>
          </button>
          {/* Image */}

          {croppedImages[index]?.oneByOne ? (
            <img
              src={croppedImages[index].oneByOne}
              alt='original'
              className='cropped-image'
              style={{
                width: '98%',
                height: '40%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          ) : croppedImages[index]?.oneByOne &&
            croppedImages[index]?.fourByFive ? (
            <img
              src={croppedImages[index].oneByOne}
              alt='original'
              className='cropped-image'
              style={{
                width: '98%',
                height: '40%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          ) : croppedImages[index]?.fourByFive &&
            !croppedImages[index]?.oneByOne ? (
            <img
              src={croppedImages[index].fourByFive}
              alt='original'
              className='cropped-image'
              style={{
                width: '98%',
                height: '40%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          ) : (
            <img
              src={imageUrl.url}
              alt='initial'
              className='cropped-image'
              style={{
                width: '98%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          )}
        </div>
      ))}
    </>
  );
}
