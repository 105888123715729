import React, { useRef, useState } from "react";
import cardImage from "./../Assets/Image/banner.jpg";
import img1 from "./../Assets/Image/1 (1).jpg";
import img2 from "./../Assets/Image/1 (1).png";
import img3 from "./../Assets/Image/1 (2).jpg";
import cakeImg from './../Assets/Image/cake1 1.png';
import chickenImg from './../Assets/Image/delicious-chicken-table 3.png';
import kababImg from './../Assets/Image/Kabab 1.png';
import latteCoffeeImg from './../Assets/Image/latte-coffee-cup 1.png';
import pastaImg from './../Assets/Image/Pasta 1.png';
import soupImg from './../Assets/Image/Soup 3.png';
import bannerImg from './../Assets/Image/banner.jpg';
import LeoImg from './../Assets/Image/Leo.png';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper";
import Rating from "react-rating";
import './test.css'

export default function Test() {
    return (
        <div className="customContainer">
            <div className=" row row-cols-1 row-cols-sm-3 row-cols-md-4  row-cols-lg-5">


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="col"  >
                    <div className="card custom-cart h-100 ">

                        <div className="d-flex justify-content-end cartIcon">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa fa-plus-circle "></i>
                            {/* <i className="fa-regular fa-circle-plus"></i> */}
                        </div>

                        <img src={img1} className="img-fluid rounded-start w-100" alt="..." />

                        <div className="d-flex justify-content-between mt-3">

                            <h5>Bread Toast</h5>
                            <h6>10.5$</h6>

                        </div>

                        <p className='mb-1'>Topped with tomato, bocconcini, cheese, grated parmigiano...</p>

                        <div className='rating-Font mb-1'>4.5 <Rating className="ratingStar"
                            initialRating={Number(4.5)}
                            emptySymbol="far fa-star icon-color"
                            fullSymbol="fas fa-star icon-color"
                            readonly ></Rating></div>

                        <div className='d-flex justify-content-between comment-View mt-1'>
                            <img src={LeoImg} alt="" className='foodcart-img' />
                            <div className='d-flex flex-column'>
                                <p>&nbsp; <span className='userName'>Leo</span> Leo Love the art of travel. So many plac...</p>
                                <p>&nbsp; 12 Feb 2023</p>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </div >
    )
};
