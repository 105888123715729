export const SET_NEW_MESSAGE_RESPONSE = "SET_NEW_MESSAGE_RESPONSE";
export const SET_NEW_NOTIFICATION_RESPONSE = "SET_NEW_NOTIFICATION_RESPONSE";

// Define all your action types here
export const CHANGE_LOAD_TRUE = 'changeLoadTrue';
export const CHANGE_LOAD_FALSE = 'changeLoadFalse';
export const GET_CHANGE_LOG = 'getChangeLog';
export const OPEN_REGISTER_PAGE_TRUE = 'openRegisterPageTrue';
export const OPEN_REGISTER_PAGE_FALSE = 'openRegisterPageFalse';
export const RELOAD_PAGE_TRUE = 'reloadPageTrue';
export const RELOAD_PAGE_FALSE = 'reloadPageFalse';
export const SET_USERNAME = 'setName';
export const SET_SEARCH_NAME = 'searchValue';
export const SET_COMMENT_TEXT = 'setCommentText';
export const SEARCH_CLICK = 'searchClick';
export const COMMENT_IMG_CLICK = 'commentImgClicked';
export const CLOSE_WINDOW = 'closeWindow';
export const CLOSE_FORGET_PASSWORD = 'closeForgetPassword';
export const OPEN_FORGET_PASSWORD = 'openForgetPassword';
export const SET_MENU_LIST = 'menulist';
export const MENU_SELECT = 'menuselection';
export const FOOD_ITEM_LIST = 'setfooditems';
export const SEARCH_LOADER = 'setsearchloader';
export const SELECTED_MENU = 'setSelectedMenu';
export const SELECTED_SECTION_OR_SUBSECTION = 'setSelectedSectionOrSubsection';

// Menu Reducers Action Types 
export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';

export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';
export const SELECT_FIRST_MENU_ITEM = 'SELECT_FIRST_MENU_ITEM';

export const FETCH_FILTER_BAR_DATA = 'FETCH_FILTER_BAR_DATA';

export const GET_SEARCH_BAR_DATA = 'GET_SEARCH_BAR_DATA';
export const SEARCH_ON_MENU = 'SEARCH_ON_MENU';
export const FILTERED_SECTION_BY_MENU = "FILTERED_SECTION_BY_MENU";

export const FILTERED_ITEM_DATA = 'Filterd_Item_Data';
export const SEARCHING_START = 'Searching_Start';