const initialState = false

const searchLoader = (state = initialState, action) => {
  switch (action.type) {
    case 'setsearchloader':
      return action.payload

    default:
      return state
  }
}
export default searchLoader