import React from 'react'
import './SubscriptionOffer.css'
import Recommendation from '../Recommendation/Recommendation'
import DiscountOffer from '../DiscountOffer/DiscountOffer'
import SubscriptionSlider from './subscriptionslider/SubscriptionSlider'

function SubscriptionOffer(props) {
  return (
    <div className='subscriptionCard'>
      <h3 style={{ color: '#f2cd81' }}>Subscription menu</h3>
      <div className=''>

        <SubscriptionSlider from={2} />
      </div>

    </div>
  )
}

export default SubscriptionOffer