import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './FilterBar.css';

/* Redux */
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { SelectedSectionOrSubsection, SelectedMenu } from '../../../../actions';
import { setSelectedMenu } from '../../../../actions/Menu/selectedMenuActions';
import { selectFirstMenuItem } from '../../../../actions/Menu/menuActions';
import { fetchFilterBarData } from '../../../../actions/FilterBar/filterbarAction';
import { filteringSectionByMenu } from '../../../../actions/FilterBar/filteringSectionByMenu';

/* Components Importing */
import FilterDialogue from '../FilterDialogue/FilterDialogue';
import BottomBar from '../BottomBar/BottomBar';
import SearchBar from '../../../SearchBar/SearchBar';

/* Png Importing */
import filter from '../../../../Assets/Icons/filter.png';
import Apps from '../../../../Assets/Icons/app.png';
import backLeft from '../../../../Assets/Icons/left.png';
import dots from '../../../../Assets/Icons/dots.png';
import next from '../../../../Assets/Icons/Nextx.png';

import soup from '../../../../Assets/Icons/hot-soup.png';
import spaguetti from '../../../../Assets/Icons/spaguetti.png';
import shop from '../../../../Assets/Icons/shopping-cart.png';
import boiled from '../../../../Assets/Icons/boiled.png';
import cake from '../../../../Assets/Icons/cake.png';
import special from '../../../../Assets/Icons/badge.png';
import coffee from '../../../../Assets/Icons/coffee.png';

const FilterBar = (props) => {

  /* *** Props *** */
  const { onApplyFilters } = props;
  const { searchData } = props
  const { onValueChange } = props;
  const { resUrl } = props;
  const { currentCategory } = props;
  const { SubSection } = props;
  const { searchedItems } = props;
  const hasFoodItem = props.showHide;
  const apiAddress = process.env.REACT_APP_SECRET;

  /* Redux State */
  const dispatch = useDispatch();
  const searchClick = useSelector((state) => state.searchiconClick);
  const selectedMenu = useSelector(state => state.selectedMenu.selectedMenu);
  const firstSelectedMenu = useSelector(state => state.firstMenu.selectedFirstMenuItem);
  const filterbarDataFromRedux = useSelector(state => state.filterbar.allSectionSubSection);

  /* Local State */
  const [FilterBarData, setFilterBarData] = useState(); /* this is mapping */
  const [allSection, setAllSection] = useState({ sectionList: [], subSectionList: [] });
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setAllSection(filterbarDataFromRedux);
  }, [filterbarDataFromRedux]);

  const [filteredFilterBarData, setFilteredFilterBarData] = useState();
  const [searchValue, setSearchValue] = useState('search');
  const [searchedItemsSection, setSearchedItemsSection] = useState([]);
  const [isFilterBarFixed, setIsFilterBarFixed] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [selectedPreviousFilter, setselectedPreviousFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [targetSectionId, setTargetSectionId] = useState("");

  const containerRef = useRef(null);
  const filterBarRef = useRef(null);
  const scrollContainerRef = useRef(null);

  /* Getting Filter Bar Data */

  useEffect(() => {
    if (resUrl) {
      dispatch(fetchFilterBarData(resUrl));
    }
  }, [resUrl, dispatch]);

  // useEffect(() => {
  //   if (!resUrl) {
  //     setFilterBarData(props?.reloadAllFood);
  //   }
  // }, [props?.reloadAllFood]);

  /* Filtering Section and SubSection based on Selected Menu */
  const FilterByMenu = (menu) => {
    if (!allSection || !allSection.sectionList || !allSection.subSectionList) {
      console.error('allSection or sectionList or subSectionList is undefined or null');
      return {
        sectionList: [],
        subSectionList: []
      };
    }

    const updatedSectionList = allSection.sectionList.filter(section =>
      section.menuId === menu.id
    );

    const updatedSubSectionList = allSection.subSectionList.filter(subSection =>
      subSection.menuId === menu.id
    );

    return {
      sectionList: updatedSectionList,
      subSectionList: updatedSubSectionList
    };
  };


  useEffect(() => {
    if (firstSelectedMenu && allSection.sectionList.length > 0) {
      const response = FilterByMenu(firstSelectedMenu);
      setFilterBarData(response);
      dispatch(filteringSectionByMenu(response));
    } else {
      setFilterBarData({ sectionList: [], subSectionList: [] });
      dispatch(filteringSectionByMenu({ sectionList: [], subSectionList: [] }));
    }
  }, [firstSelectedMenu, allSection, selectedMenu]);

  useEffect(() => {
    if (selectedMenu) {
      const filteredData = FilterByMenu(selectedMenu);
      setFilterBarData(filteredData);
      dispatch(filteringSectionByMenu(filteredData));
      dispatch(selectFirstMenuItem(null));
    } else if (selectedMenu === null && firstSelectedMenu === null) {
      setFilterBarData({ sectionList: [], subSectionList: [] });
      dispatch(filteringSectionByMenu({ sectionList: [], subSectionList: [] }));
    }
  }, [selectedMenu, allSection, firstSelectedMenu]);

  /** Scrolling to Category by Clicking on Section(Click Event on Section) **/
  // const scrollToCategory = (index) => {
  //   console.log("scrolling to section: ", index)
  //   const categoryElement = document.getElementById(`${index}`);
  //   if (categoryElement) {
  //     console.log("scrolling to section 2: ", categoryElement)
  //     categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     // window.scrollBy({ top: -80, left: 0, behavior: 'smooth', });
  //   }
  // };
  
  const scrollToCategory = (index) => {
    setTimeout(() => {
      const categoryElement = document.getElementById(`${index}`);
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.log("Target section not found:", index);
      }
    }, 100); // Small delay to wait for DOM updates
  };

  useEffect(() => {
    if (!loading && targetSectionId) {
      // Ensure scrollToCategory runs after loading is complete
      scrollToCategory(targetSectionId);
  
      // Reset the target section ID
      setTargetSectionId("");
    }
  }, [loading, targetSectionId]);

  // const scrollContainerRef = useRef(null);
  //   const searchName = useSelector((state) => state.changeSearchName)

  /** Scrolling up event Logic for Filter bar **/
  const [scrollUp, setScrollUp] = useState(false);
  useEffect(() => {
    if (scrollUp) {
      onValueChange(true);
    } else {
      onValueChange(false)
    }
  }, [scrollUp])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollUp(currentScrollTop < lastScrollTop);
      setLastScrollTop(currentScrollTop);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }

  }, [lastScrollTop]);

  /*** Filter modal popoup functionality ***/
  const [filters, setFilters] = useState({
    sortOption: "relevance",
    priceRange: [10.5, 1000],
    offers: {
      freeDeliver: false,
      offer: false,
      onlinePayment: false
    }
  });

  const openModal = () => {
    document.body.classList.add('modal-open');
    setShowModal(true);
  };


  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setShowModal(false);
  };

  const clearFilters = () => {
    setFilters({
      sortOption: "relevance",
      priceRange: [10.5, 1000],
      offers: {
        freeDelivery: false,
        offer: false,
        onlinePayment: false
      }
    });
  };

  const applyFilters = () => {
    onApplyFilters(filters);
  }


  /* bottombar modal opening closing functionality */
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible((prevIsModalVisible) => !prevIsModalVisible);
  };

  const handleDropDownClick = (event) => {
    event.stopPropagation();
    toggleModal();
  };

  /* right arrow button functionality */
  const handleScrollRight = () => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const maxScrollAmount = container.scrollWidth - containerWidth;
    const newScrollAmount = Math.min(
      scrollAmount + containerWidth,
      maxScrollAmount
    );
    container.scrollTo({ left: newScrollAmount, behavior: 'smooth' });
    setScrollAmount(newScrollAmount);
  };

  /* left arrow button functionality */
  const handleScrollLeft = () => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const maxScrollAmount = container.scrollWidth - containerWidth;
    const newScrollAmount = Math.max(scrollAmount - containerWidth, 0);

    // Check if there is anything left to scroll to the left
    if (newScrollAmount === scrollAmount) {
      return;
    }

    container.scrollTo({ left: newScrollAmount, behavior: 'smooth' });
    setScrollAmount(newScrollAmount);
  };

  // filter select and auto scroll functionality

  // when props.isFilterBarFixed is false no filter will be selected initially start
  // const handleFilterClick = (filter) => {
  //   if (!props.isFilterBarFixed) {
  //     setSelectedFilter(filter === selectedFilter ? null : filter);
  //     return;
  //   }

  //   setSelectedFilter(filter === selectedFilter ? null : filter);
  // };
  // when props.isFilterBarFixed is false no filter will be selected initially end

  //All filter will selected initially when props.isFilterBarFixed is false start
  // const handleFilterClick = (filter) => {
  //   setSelectedFilter(filter === selectedFilter ? null : filter);
  // };

  /* All filter will selected initially when props.isFilterBarFixed is false end */
  /*
    when props.isFilterBarFixed became false
     from true then automatically selected filter
     will became unselected start
  */

  const topbarForFilterBar = useRef(null);
  const [scrollValue, setscrollValue] = useState(0);
  useEffect(() => {
    if (!props.isFilterBarFixed) {
      setSelectedFilter(null);
    }
  }, [props.isFilterBarFixed]);

  const handleFilterClick = (data) => {
    props.setAllButtonClicked(true);

    // dispatch(SelectedMenu(null));

    // if (filter === selectedFilter) {
    //   props.reloadAllFood(data.restaurantId, true);
    //   props.getSectionSubsectionId(null, '', false);
    // } else {
    //   setSelectedFilter(filter === selectedFilter ? null : filter);
    //   // props.getSectionSubsectionId(data, type, true); //Error are Getting from here
    // }
  };

  /* when props.isFilterBarFixed became false from true then automatically selected filter will became unselected end */
  useEffect(() => {
    const handleScroll = () => {
      if (!props.isFilterBarFixed) {
        return; // Exit early if isFilterBarFixed is falsy
      }
      var val = 0;
      if (topbarForFilterBar.current != null) {
        const filterBarRect =
          topbarForFilterBar.current.getBoundingClientRect();
        const isAboveViewport = filterBarRect.top === 0 ? true : false;
        var top = filterBarRect.top * -1;
        var scrollval = scrollValue * -1;
        if (top > scrollval) {
          val = 0;
        } else if (top === 0) {
          val = 1;
        } else {
          val = 0;
        }
        var s = filterBarRect.top;
        const scrollY = window.scrollY;
        const filterElements = document.querySelectorAll('.filterbar-img-div');
        const filterCount = filterElements.length;

        // Calculate the index of the selected filter based on the scroll position
        let selectedFilterIndex =
          Math.floor((scrollY - 2600) / 800) % filterCount;
        var previousSelectedVal = selectedFilterIndex - 1;

        // Adjust the calculation to start selecting from the first filter
        selectedFilterIndex =
          selectedFilterIndex >= 0 ? selectedFilterIndex : filterCount - 1;

        // Set the selected filter based on the index
        const selectedFilter =
          filterElements[selectedFilterIndex]?.getAttribute('data-filter');
        setSelectedFilter(selectedFilter);
        // Scroll the container to the selected filter
        if (val === 1) {
          const selectedFilterElement = filterElements[selectedFilterIndex + 1];
          if (selectedFilterElement) {
            selectedFilterElement.scrollIntoView({});
          }
        } else {
          const selectedFilterElement = filterElements[selectedFilterIndex];
          if (selectedFilterElement) {
            selectedFilterElement.scrollIntoView({});
          }
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up function to remove scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // const [selectedBtn, setSelectedBtn] = useState(0);

  // function handleButtonClick(index) {
  //   setSelectedBtn(index);
  //   const dropdownItems = document.querySelectorAll('.dropdown-item');

  //   dropdownItems.forEach((item) => {
  //     item.classList.remove('selected');
  //   });

  //   const selectedDropdownItem = document.querySelector(
  //     `.dropdown-item:nth-child(${index})`
  //   );
  //   selectedDropdownItem.classList.add('selected');
  //   if (index === 4) {
  //     setShowModal(true);
  //   }
  // }
  // const handleShop = (props) => {
  //   setSearchValue(props);
  // };

  /* Humberger & search popup functionality */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // getting search data from the child component "SearchBar"
  const handleSearch = (data) => {
    searchData(data);
  };

  /* if click elsewhere, show the the sub-section unselected */
  const [selectedSubSectionInCash, setSelectedSubSectionInCash] = useState(null);
  useEffect(() => {
    setSelectedFilter(null);
  }, [props.subSectionClicked]);

  // Setting SubSectionId to show selected
  useEffect(() => {
    setSelectedFilter(selectedSubSectionInCash);
  }, [selectedSubSectionInCash]);

  // Setting Section Id;
  const [clickedSectionId, setClickedSectionId] = useState(null);
  useEffect(() => {
    setClickedSectionId(props.clickedSectionId);
  }, [props.clickedSectionId]);

  console.log("filter bar data: ", allSection?.sectionList)

  return (
    <div
      ref={filterBarRef}
      className={`
        ${hasFoodItem ? '' : 'hide'}
        filter-bar 
        ${isFilterBarFixed ? 'fixed' : ''} 
        ${scrollUp ? 'scroll-up' : ''}
      `}>
      <BottomBar isModalVisible={isModalVisible} toggleModal={toggleModal} />
      <div
        className={` d-flex justify-content-center
      ${props.isFilterBarFixed && searchClick
            ? 'filterbarGapOnClick filterBarSticky filterbar-on-searchshow'
            : 'filterbar filterbarGap'
          }
      ${!props.isFilterBarFixed && searchClick ? 'filterbar-off-searchshow' : ''
          }`}
      >
        <div className={`d-flex `} style={{ width: '100%', margin: '0px' }}>
          <div
            className=''
            style={{
              display:
                props.isFilterBarFixed && windowWidth > 766 ? 'block' : 'none',
            }}
          >
            <div
              className=''
              style={{ display: 'inline-block' }}
              ref={containerRef}
            >
              <div className='menuSelectionImgDiv d-flex align-items-center'>
                <SearchBar
                  getSearchValue={handleSearch}
                />
              </div>
            </div>
          </div>
          {/* Left arrow markup */}
          {scrollAmount > 0 && (
            <div className='col-1 d-flex flex-column justify-content-center m-3 arrow-button-div d-none d-md-flex p-1 rounded-circle'>
              <div
                className='btn arrow-button-div-btn p-0'
                onClick={handleScrollLeft}
              >
                <img
                  className='object-fit-contain w-100 h-100'
                  src={backLeft}
                />
              </div>
            </div>
          )}
          {/* Search Bar */}
          <div
            className='col'
            style={{
              display:
                props.isFilterBarFixed && windowWidth < 767 ? 'block' : 'none',
            }}
          >
            <div
              className=''
              style={{ display: 'inline-block' }}
              ref={containerRef}
            >
              <div className='menuSelectionImgDiv d-flex align-items-center'>
                <SearchBar
                  getSearchValue={handleSearch}
                />
                <img
                  className='dropDownfilterBarImageIcon'
                  src={dots}
                  onClick={handleDropDownClick}
                  alt='Shop Icon'
                />
              </div>
            </div>
          </div>
          {/* main div to hold all, setion and subsection buttons */}
          <div
            className={`${searchClick
              ? 'menuSelection-filterOnClick menuSelection-filter'
              : 'menuSelection-filter'
              } col-9`}
            ref={containerRef}
          >
            <div ref={topbarForFilterBar}></div>
            <div className='d-flex'>
              {/* All Section Button */}
              <div
                className={`text-center filterbar-img-div ${selectedFilter === 'Apps' ? '' : ''
                  }`}
                // data-filter='Apps'
                onClick={() => handleFilterClick('Apps')}
              >
                <img className='' src={Apps} alt='Apps' />
                <div className='text-img'>All</div>
              </div>

              {/* Section List--start */}
              <div className='d-flex'>
                {allSection?.sectionList
                  .sort((a, b) => a.keywordName.localeCompare(b.keywordName))
                  .map((d, i) => {
                    return (
                      <div
                        key={d.id}
                        className={`text-center filterbar-img-div 
                        ${clickedSectionId === d.id || currentCategory.substring(0, 30).includes(d.keywordName) ? 'selected' : ''}`}
                        data-filter={d.keywordName}
                        onClick={() => {
                          if (props.subSectionClicked) {
                            props.setSubSectionClicked(false);
                            props.setSelectedSubSectionId(null);
                            
                            setLoading(true);
                            dispatch(fetchFilterBarData(resUrl)).then(() => {
                              setLoading(false);
                            });
                            
                            setTargetSectionId(d.id);
                          }else{
                            scrollToCategory(d.id);
                          }
                        }}
                      // onClick={
                      //   () => {
                      //     // props.setSectionClicked(true);
                      //     // props.setClickedSectionId(d.id);
                      //     scrollToCategory(d.id)
                      //   }
                      // }
                      // onClick={() =>
                      //   handleFilterClick(d.keywordName, d, 'section')
                      // }
                      >
                        <img className='' src={d.icon ? d.icon : d.iconId} alt={d.keywordName} />
                        <div style={{ zIndex: '200' }} className='text-img large-tooltip' title={d.keywordName}>
                          {d.keywordName.length > 8 ? `${d.keywordName.substring(0, 7)}...` : d.keywordName}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* Section---end */}

              {/* Sub Section --start */}
              <div className='d-flex gap-1'>
                {
                  // filterbarDataFromRedux?.subSectionList
                  allSection?.subSectionList
                    ?.sort((a, b) => a.keywordName.localeCompare(b.keywordName))
                    .map((d, i) => {
                      return (
                        <div
                          className=
                          {`text-center filterbar-img-div
                            ${selectedFilter === d.id ? 'selected' : ''}
                            ${d.keywordName === SubSection ? 'subSectionSelected' : ''}
                          `}
                          data-filter={d.keywordName}
                          onClick={() => {
                            setSelectedSubSectionInCash(d.id);
                            props.setSubSectionClicked(true);
                            props.setSelectedSubSectionId(d.id);
                            setTargetSectionId("")
                            console.log("filter bar data 2 clicked")
                          }}
                        >
                          <img className='' src={d.icon ? d.icon : d.iconId} alt={d.keywordName} />
                          <div className='text-img'>{d.keywordName.length > 8 ? `${d.keywordName.substring(0, 7)}...` : d.keywordName}</div>
                        </div>
                      );
                    })}
              </div>
              {/* Sub Section--end */}

            </div>
          </div>

          {/* Right Arrow Markup */}
          <div className='col-1 d-flex flex-column justify-content-center arrow-button-div d-none d-md-flex p-1 rounded-circle'>
            <div
              className='btn arrow-button-div-btn p-0'
              onClick={handleScrollRight}
            >
              <img className='object-fit-contain w-100 h-100' src={next} />
            </div>
          </div>
          {/* Filter Button */}
          <div className='col-1 d-flex flex-column justify-content-center filter-button-div d-none d-md-flex'>
            <div
              className='btn filter-button-div-btn justify-content-center d-flex align-content-center'
              onClick={openModal}
            >
              <img className='object-fit-contain' src={filter} />
              <span className='ms-2 '>Filters</span>
            </div>
          </div>
        </div>
      </div>

      {/* Models Coding Started */}
      <div>
        <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}
        ></div>
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex='-1' role='dialog' style={{ display: showModal ? 'block' : 'none', zIndex: 9999 }} onClick={(e) => { if (e.target === e.currentTarget) { closeModal(); } }}>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content model-content-filterDialogue'>
              {/* <div className="modal-header p-1 ps-3">
                <div className="btn btn-close-filterdialogue justify-content-center align-content-center" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </div>
                <h5 className="modal-title m-auto">Filters</h5>
              </div> */}
              <div className='modal-body'>
                <FilterDialogue filters={filters} setFilters={setFilters} />
              </div>
              <div className='modal-footer'>
                <div className='btn btn-close-filterdialogue me-auto fs-6'
                  onClick={closeModal}
                >
                  <span aria-hidden='true' onClick={clearFilters}>Clear all</span>
                </div>
                <div className='btn btn-close-filterdialogueApply text-white w-25 ms-auto fs-6 ' onClick={closeModal}
                >
                  <span
                    aria-hidden='true'
                    onClick={applyFilters}
                  >Apply</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Models Coding End */}
    </div>
  );
};

const getValue = (props) => {
  return 1;
};

export default FilterBar;
