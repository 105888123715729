import React from 'react';

const StarRating = ({ rating = 4.5, starImage = 'path_to_your_star_image' }) => {
  return (
    <div className='d-flex gap-0 align-items-center'>
      <img
        src={starImage}
        alt='star'
        className='searchStar'
        style={{ width: '13px', height: '13px'}}
      />
      <span style={{ fontSize: '13px', marginTop: '3px' }}>{rating}</span>
    </div>
  );
};

export default StarRating;
