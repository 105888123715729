// ConfirmationModal.js
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <b>{'Discard Changes?'}</b>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' className='text-dark'>
          Are you sure you want to discard your changes? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant='outlined'
          style={{
            borderRadius: 20,
            border: '1px solid #371b17',
            padding: '10px 20px',
            marginRight: 10,
            color: 'black',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant='contained'
          autoFocus
          style={{
            backgroundColor: '#371b17',
            borderRadius: 20,
            padding: '10px 20px',
          }}
        >
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
