// React and related libraries
import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactPaginate from "react-paginate";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
// import 'swiper/swiper-bundle.min.css';
import Rating from "react-rating";
import more from "../../../Assets/Icons/more2.png";
import LeoImg from "./../../../Assets/Image/Leo.png";

// Component imports
import Test3 from "../../Test3";
import Product from "../ProductComponents/ProductCard";
import FilterBar from "../../Restaurant/MenuSelection/FilterBar/FilterBar";
import PaginatedItems from "./SearchPagination";
import Navbar from "../../Navbar/Navbar";
import RestaurantCard from "../RestaurantComponents/RestaurantCard";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { Pagination, Navigation, FreeMode } from "swiper";

// Bootstrap and CSS
import { Modal, Tab, Nav, Button } from "react-bootstrap";
import "./SearchResult.css";

// Utility and State Management
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// Asset imports
import backBtn from "./../../../Assets/Icons/back.png";
import logo from "./../../../Assets/Image/LogoTransparent.png";
import searchIcont from "./../../../Assets/Icons/searchIcon2.png";
import searchIcontCoffee from "./../../../Assets/Icons/searchIcon.png";
import searchair from "./../../../Assets/Image/SearchAir.png";
import loginImg from "./../../../Assets/Image/login.png";
import loginDesktopImg from "./../../../Assets/Image/login-Desktop.png";
import homeImg from "./../../../Assets/Image/home.png";
import menuImg from "./../../../Assets/Image/menu.png";
import leo from "./../../../Assets/Image/Leo.png";
import fuchka from "./../../../Assets/Image/1 51.png";
import star from "./../../../Assets/Icons/starCoffe.png";
import PKB from "./../../../Assets/Image/PKB.png";
import pasta from "./../../../Assets/Image/Pasta 1.png";
import search from "./../../../Assets/Icons/searchIcon.png";
import loadingGif from "./../../../Assets/Image/loading.gif";
import FoodCard from "../../Home/FoodSelection/FoodCard";
import backLeft from "../../../Assets/Icons/left.png";
import backRight from "../../../Assets/Icons/Nextx.png";
import remove from "../../../Assets/Icons/remove.png";
import { sliceText } from "../../../utils/sliceText";

// Commented import (Review if needed)
// import Cart from '../Cart/Cart';

function SearchResult(props) {
  // React hooks
  const location = useLocation();
  const propsData = location.state;
  const apiAddress = process.env.REACT_APP_SECRET;

  // Carousel and Scroll Handling
  const myRef = useRef();
  const [intersecting, setIntersecting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const searchDialogdivRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);

  // State for search section
  const [SearchData, setSearchData] = useState({
    searchDataList: [],
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  // State for data collections
  const [dataCollections, setDataCollections] = useState([]);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [clickedFilterId, setClickedFilterId] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const [filters, setFilters] = useState([]);

  //#region Swiper Navigation Button functionality
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const handleSlideChange = (swiper) => {
    if (swiper.isBeginning) {
      setShowPrev(false);
    } else {
      setShowPrev(true);
    }

    if (swiper.isEnd) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  useEffect(() => {
      const content = document.querySelector('.swiper-horizontal');
      if(content) {
        const isScrollable = content.scrollWidth > content.clientWidth;
        if (isScrollable) { setShowNext(true); }
      }
    }, [filters]);

  //#endregion

  // Fetch data on component mount
  useEffect(() => {
    axios
      .get(`${apiAddress}/api/FoodCategory/getFoodCategory`)
      .then((response) => {
        console.log("data from id: ", response.data);
        setDataCollections(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiAddress]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // Fetch search data with pagination
  const GetSearchDataPaged = async (pageNo, pageSize, initial = false) => {
    // setIsLoading(true);
    await axios
      .get(
        `${apiAddress}/api/Search/GetSearchDataPagedAsync?searchStr=${propsData}&pageNo=${pageNo}&pageSize=${pageSize}`
      )
      .then((response) => {
        // setSearchData((prev) => ({
        //   searchDataList: [
        //     ...prev.searchDataList,
        //     ...response.data.searchDataList,
        //   ],
        //   totalCount: response.data.totalCount,
        // }));
        setSearchData((prevData) => ({
          searchDataList: initial
            ? response.data.searchDataList
            : [...prevData.searchDataList, ...response.data.searchDataList],
          totalCount: response.data.totalCount,
        }));
        setIsLoading(false);
      });
    if (!initial) {
      setPageNumber(pageNumber + 1);
    }
  };

  useMemo(() => {
    if (SearchData.searchDataList.length > 0) {
      const sections = SearchData.searchDataList.map((item) => ({
        id: item.keywordId,
        keyword: item.foodKeyword,
        keywordImage: item.keywordImage,
      }));

      const subSection = SearchData.searchDataList.map((item) => ({
        id: item.keywordTagId,
        keyword: item.keywordtag,
        keywordImage: item.keywordTagImage,
      }));

      const allFilters = sections.concat(subSection);
      const newFilters = allFilters.filter((item) => item.id !== null);

      const uniqueFilters = newFilters.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      );

      setFilters(uniqueFilters); // Update filters with the new list
    }
  }, [SearchData.searchDataList]);

  // Initial fetch of search data
  useEffect(() => {
    GetSearchDataPaged(1, 12, true);
  }, [propsData]);

  // Handle click outside of search dialog to close modal
  const handleClickOutside = (event) => {
    if (
      searchDialogdivRef.current &&
      !searchDialogdivRef.current.contains(event.target)
    ) {
      setShowModal(false);
    }
  };

  // Add and remove event listener for clicks outside the modal
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Navbar related state and function
  const [blur, setBlur] = useState(false);
  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  // Dummy or placeholder variables
  const dataSelectedKeyword = null;

  // Placeholder functions (commented-out or not used)
  // const times = 6;
  // const currentState = useSelector((state) => state.changeLoad);
  // const isRegisterPage = useSelector((state) => state.openRegisterPage);
  // const isReloadPage = useSelector((state) => state.testReducers);
  // const dispatch = useDispatch();
  // const [userVal, setUserValue] = useState('');
  // const [profileImage, setProfileImage] = useState('');
  // const [navbarOnTop, setNavbarOnTop] = useState(true);
  // const [scrollValue, setscrollValue] = useState(0);
  // const [slideUp, setSlideUp] = useState(false);
  // const navbarTopRef = useRef(null);
  // const [allSelected, setAllSelected] = useState(false);
  // const [foodSelected, setFoodSelected] = useState(false);
  // const [resturantSelected, setRestaurantSelected] = useState(false);
  // const [show, setShow] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  // const [searchInput, setSearchInput] = useState('');
  // const [category, setCategory] = useState('');

  // Pagination test (commented-out)
  const itemsPerPage = 10;
  const pageCount = Math.ceil(SearchData.totalCount / itemsPerPage);
  const handlePageClick = (event) => {
    GetSearchDataPaged(propsData, event.selected + 1, 6);
  };

  // Navbar-related functions (commented-out)
  // const handleLogout = () => { ... };
  // const goToHome = () => { ... };
  // const hideModalInfo = () => { ... };
  // const showModalInfo = () => { ... };
  // const handleCloseModal = () => { ... };
  // function handleSearchIcon(){ ... }
  // function handleAllButtonClick() { ... }
  // function handleFoodButtonClick() { ... }
  // function handleRestaurantButtonClick() { ... }
  // const handleInputChange = (event) => { ... };

  const handleScrollingUpValueChange = (data) => { };

  // Automatic screen size calculation
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 500);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClickFilter = (id) => {
    if (id === null) {
      // "All" filter clicked: reset to show all items
      setClickedFilterId(null);  // Deselect any filter
      setIsFilterClicked(false); // "All" filter selected
      setFilteredItems([]);      // Reset filtered items (show all)
    } else if (clickedFilterId === id) {
      // The same filter clicked again: deselect it and select "All"
      setClickedFilterId(null);  // Deselect the filter
      setIsFilterClicked(false); // Select "All"
      setFilteredItems([]);      // Reset filtered items (show all)
    } else {
      // New filter clicked: apply the filter and update state
      const filtered = SearchData.searchDataList.filter(
        (item) => item.keywordId === id || item.keywordTagId === id
      );
      setClickedFilterId(id); // Set the selected filter ID
      setIsFilterClicked(true); // Mark that a filter is active (not "All")
      setFilteredItems(filtered); // Apply the filtered items
    }
  }

  useEffect(() => {
    let lastScrollTop = 0;
    
    const handleScroll = () => {
      const content = document.getElementsByClassName("FilterBarMain")[0];
      if (content) {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
        if (currentScrollTop > lastScrollTop) {
          // Scrolling down
          content.style.transition = "all 0.3s ease";
          content.style.background = "white";
          content.style.top = "-90px";
        } else {
          // Scrolling up
          content.style.transition = "all 0.3s ease";
          content.style.background = "white";
          content.style.top = "-10px";
          setScrolling(true);
        }
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      }
  
      content.style.position = "fixed";
      content.style.width = "100%";
      content.style.zIndex = "100";
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div style={{ background: "yellow"}}>
        <Navbar handleNavbar={searchDialogOpen} showSeeMore={false} />
      </div>

      {scrolling && <div style={{ height: "160px"}}></div>}

      {/* Displaying those tag items */}
      <div className="FoodSelection FilterBarMain customContainer pt-4 mt-4 mb-4">
        <div className={`g-4 mx-auto justify-content-center mt-5 selectionButton scrollbtnMobile  ${intersecting ? "HideSelectionSingleButton" : ""}`} ref={myRef} >
          {/* <div className="keyword-container"> */}
          <div className="slider-btn-wrapper">

            <button ref={prevRef} style={{ display: showPrev ? "" : "none" }}  className="custom-prev-button prev-button">
              <img src={backLeft} alt="left icon" />
            </button>
            <button ref={nextRef} style={{ display: showNext ? "" : "none" }} className="custom-next-button next-button">
              <img src={backRight} alt="right icon" />
            </button>

            <Swiper
              spaceBetween={3}
              onSlideChange={handleSlideChange}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                1000: {
                  slidesPerView: 12,
                },
                700: {
                  slidesPerView: 6,
                },
                500: {
                  slidesPerView: 5,
                },
                300: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
            >
              {/* "All" filter button */}
              <SwiperSlide
                className={`slider-content ${clickedFilterId === null ? "active-filter" : ""}`}
                onClick={() => handleClickFilter(null)} // Clicking "All" deselects any filter
              >
                <div className="keyword">
                  <div className="filter-img">
                    <img
                      src={"https://gotastelivestorage.blob.core.windows.net/gotasteimages/5f3632b2-78db-416c-a337-7af934c77e4f"}
                      alt="slider"
                    />
                  </div>
                  <p className="filter-name">All</p>
                </div>
              </SwiperSlide>

              {/* Filter buttons */}
              {filters?.map((item, idx) => (
                <SwiperSlide
                  key={idx}
                  className={`slider-content ${clickedFilterId === item.id ? "active-filter" : ""}`}
                  onClick={() => handleClickFilter(item.id)} // Clicking a filter will either select or deselect it
                >
                  <div className="keyword">
                    <div className="filter-img">
                      <img
                        src={item.keywordImage ? item.keywordImage : "https://gotastelivestorage.blob.core.windows.net/gotasteimages/5f3632b2-78db-416c-a337-7af934c77e4f"}
                        alt="slider"
                      />
                    </div>
                    <p className="filter-name large-tooltip" title={item.keyword}>{sliceText(item.keyword, 10)}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <Swiper
              spaceBetween={3}
              slidesPerView={20}
              // navigation
              // pagination={{ clickable: true }}
              breakpoints={{
                370: {
                  slidesPerView: 5,
                },
                500: {
                  slidesPerView: 5,
                },
                700: {
                  slidesPerView: 7,
                },
                1000: {
                  slidesPerView: 12,
                }
              }}
            >
              {dataCollections?.map((d, index) => (
                <SwiperSlide>
                  <div className="keyword-item" key={index}>
                    <img
                      className="img-fluid"
                      src={d.categoryImagePath ? d.categoryImagePath : "https://gotastelivestorage.blob.core.windows.net/gotasteimages/5f3632b2-78db-416c-a337-7af934c77e4f"}
                      alt="..."
                      style={{ width: '30px', height: '30px' }}
                    />
                    <p style={{ fontSize: '14px' }}>{d.categoryName}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> */}
          </div>
        </div>
      </div>

      {/* Showing food Items */}
      {
        isFilterClicked ?
          filteredItems.length > 0
            ?
            <div className={`testContainer ${isMobile ? 'mx-0' : 'mx-4'}`}>
              {
                filteredItems?.map((item, idx) => <FoodCard key={idx} foodItemData={item}></FoodCard>)
              }
            </div>
            :
            <div>No food item available</div>
          :
          <div className={`search-result-container ${blur ? "backgroundBlur" : ""}`}>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "5rem" }} >
                <img src={loadingGif} alt="" />
              </div>
            ) : (
              <InfiniteScroll
                className={`${isMobile ? 'mx-0' : 'mx-4'}`}
                style={{ minHeight: "150vh"}}
                dataLength={SearchData.searchDataList.length}
                next={() => GetSearchDataPaged(pageNumber + 1, 6)}
                hasMore={SearchData.searchDataList.length < SearchData.totalCount}
                loader={
                  <div className="d-flex justify-content-center ">
                    <img src={loadingGif} alt="" />
                  </div>
                }
              >
                <div className='testContainer'>
                  {SearchData.searchDataList.map((dt, i) => {
                    return <FoodCard key={i} foodItemData={dt}></FoodCard>;
                  })}
                </div>
                {/* <div className={`testContainer ${isMobile ? 'mx-0' : 'mx-4'} item-container`}> */}
                {/* <div className="search-result-container">
                {SearchData.searchDataList.length > 0
                  ? SearchData.searchDataList.map((d, outerIndex) => {
                    return (
                      <div className="item" key={d.foodItemId}> */}
                {/* <div className="image-container-search-page">
                          <img src={d?.foodImageList[0]?.itemImagePath} alt="food item" />
                        </div> */}
                {/* <Swiper
                          spaceBetween={1}
                          slidesPerView={1}
                          onSlideChange={() => console.log('slide change')}
                          onSwiper={(swiper) => console.log(swiper)}
                          className="image-container-search-page"
                        >
                          {
                            d.foodImageList?.map(image => <SwiperSlide key={image.id} className="image-slider">
                              <img src={image?.itemImagePath} alt="food item" />
                            </SwiperSlide>
                            )
                          }
                        </Swiper> */}
                {/* <div className="image-container-search-page">
                          <Swiper
                            spaceBetween={1}
                            slidesPerView={3.5}
                            breakpoints={{
                              300: {
                                slidesPerView: 1,
                              },
                              500: {
                                slidesPerView: 1,
                              },
                              640: {
                                slidesPerView: 2,
                              },
                              768: {
                                slidesPerView: 3,
                              },
                              1024: {
                                slidesPerView: 3.5,
                              },
                            }}
                          >
                            {d.foodImageList.map((item, innerIndex) => (
                              <SwiperSlide key={`${item.id}-${outerIndex}-${innerIndex}`}>
                                <img src={item.itemImagePath} alt='Food' />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div> */}
                {/* Item details goes here */}
                {/* <div className="food-details-cart mt-2">
                          <div className="details">
                            <div className="d-flex justify-content-between commentLove">
                              <div className="d-flex commentLove">
                                <i className="fa-regular fa-heart "></i>
                                <i className="fa-sharp fa-regular fa-comment-dots"></i> */}
                {/* three dot start */}
                {/* <div class="dropdown">
                                  <span
                                    class="dropdown-toggle"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={more}
                                      alt="dot"
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: "2px",
                                      }}
                                    />
                                  </span>
                                  <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Save
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Offers
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Subscribe
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="rating-Font mb-1 d-flex align-items-center starRating"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              >
                                <Rating
                                  className="ratingStar"
                                  initialRating={Number(4.5)}
                                  emptySymbol="far fa-star icon-color"
                                  fullSymbol="fas fa-star icon-color"
                                  readonly
                                ></Rating>
                                4.5
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/orderpage">
                                <h3
                                  className="m-0 p-0"
                                  style={{
                                    color: "#3d1e19",
                                    fontFamily: "Poppins",
                                    fontSize: "1rem",
                                    fontWeight: 800,
                                  }}
                                >
                                  {d.foodItemName}
                                </h3>
                              </Link>

                              <h4 className="m-0 p-0">£{d.price}</h4>
                            </div> */}
                {/* <p className="py-.2">{d.description}</p>  */}
                {/* <Link
                              to={"/pizzaburg"}
                              style={{ textDecoration: "none" }}
                            >
                              <div className="d-flex align-items-center gap-2 pb-2">
                                <div>
                                  <img
                                    src={d.restaurantLogo}
                                    style={{ width: "2rem" }}
                                    alt={d.name}
                                  />
                                </div>
                                <h6>{d.restaurantName}</h6>
                              </div>
                            </Link>
                            <div className="d-flex align-items-center gap-3 justify-content">
                              <img src={LeoImg} className="me-1 userImg" alt="" />
                              <p style={{ margin: '0px' }}>
                                Leo Love the art of travel. So many plac So many foods...{" "}
                              </p>
                            </div>
                          </div> */}

                {/* <div className="details"> */}
                {/* <p>More details slot</p> */}
                {/* </div> */}
                {/* </div> */}
                {/* Item details */}
                {/* </div> */}
                {/* ); */}
                {/* }) */}
                {/* : ""} */}
                {/* </div> */}
              </InfiniteScroll>
            )
            }

            {/* <div id="container">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div> */}
          </div >
      }
    </>
  );
}

export default SearchResult;
