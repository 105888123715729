import React, { useCallback, useEffect, useState } from "react";
import OrderDetails from "../OrderDetails/OrderDetails";
import { Accordion, button } from "react-bootstrap";
import "./RestaurantOrderDetails.css";
import { FilledInput, Input } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";

const RestaurantOrderDetails = ({
  restaurant,
  handleSetTip,
  tips,
  slectedTableHistroyForValidation
  // onUpdateServiceCharge,
}) => {
  const [restaurantId, setRestaurantId] = useState("");
  const [tableInfos, setTableInfos] = useState([]);
  const [selectedTable, setSelectedTable] = useState({});
  const [userId, setUserId] = useState("");
  const [disabledAmount, setDisabledAmount] = useState(false);
  const [disabledPercentage, setDisabledPercentage] = useState(false);

  /* Calculating Total of SubOrders */
  const totalBasePrice = () => {
    if (!restaurant) return 0;
    let total = 0;

    restaurant.subOrders.forEach((subOrder) => {
      subOrder.itemDetails.forEach((item) => {
        // Calculate base item price
        const itemTotal = item.basePrice + item.vatInAmount + item.sdInAmount;
        total += itemTotal * item.foodItemQuantity;

        // Calculate add-ons
        (item.addOns || []).forEach((addOn) => {
          total +=
            addOn.addOnBasePrice * addOn.addOnQuantity +
            addOn.addOnVatAmount +
            addOn.addOnSdAmount;

          // Calculate options for each add-on
          (addOn.options || []).forEach((option) => {
            const optionTotal =
              option.optionBasePrice +
              option.optionVatAmount +
              option.optionSdAmount;
            total += optionTotal * option.optionQuantity;

            // Calculate sub-options for each option
            (option.subOptions || []).forEach((subOption) => {
              const subOptionTotal =
                subOption.subOptionBasePrice +
                subOption.subOptionVatAmount +
                subOption.subOptionSdAmount;
              total += subOptionTotal;
            });
          });
        });
      });
    });

    return total.toFixed(2);
  };

  // Tips Section
  const [specify, setSpecify] = useState(false);

  // const assignTips = (amount) => {
  //     setTips(amount);
  // }

  const handleSpecifyInputValue = (event, restaurantId) => {
    const value = parseFloat(event.target.value) || 0; // Ensure the value is a number

    if (!value || parseFloat(value) === 0) {
      // If value is empty or zero, re-enable both inputs
      setDisabledPercentage(false);
      setDisabledAmount(false);
    } else {
      // If value is non-zero, disable the percentage input
      setDisabledPercentage(true);
    }

    handleSetTip(restaurantId, value, "amount"); // Update tip for the specified restaurant
  };

  const handleSpecifyPercentageInput = (event, restaurantId) =>{
    const value= parseFloat(event.target.value) || 0;

    if (!value || parseFloat(value) === 0) {
      // If value is empty or zero, re-enable both inputs
      setDisabledAmount(false);
      setDisabledPercentage(false);
    } else {
      // If value is non-zero, disable the amount input
      setDisabledAmount(true);
    }

    handleSetTip(restaurantId, value, "percentage");
  }

  // useEffect(() => {
  //     alert(tips);
  // }, [tips]);

  useEffect(() => {
    const token = localStorage.getItem("gotasteClienttoken");
    if (token) {
      const user = jwtDecode(token);
      setUserId(user.UserId);
    }
  }, []);

  const getTableDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET}/api/Cart/getTableDetailsByUserIdAndRestaurantId?userId=${userId}&restaurantId=${restaurantId}`
      );
      setSelectedTable(response.data);
      slectedTableHistroyForValidation(response.data);
    } catch (error) {
      console.log("Error fetching table info: ", error);
    }
  }, [userId, restaurantId]);

  useEffect(() => {
    const storedTableDetails = sessionStorage.getItem("tableData");
    const parsedTableDetails = JSON.parse(storedTableDetails);

    if (
      (!storedTableDetails ||
        parsedTableDetails.restaurantId !== restaurantId) &&
      restaurantId
    ) {
      // axios
      // .get(
      //   `${process.env.REACT_APP_SECRET}/api/RestaurantTable/GetAllRestaurantTableInfosByRestaurantId?restaurantId=${restaurantId}`
      // )
      // .then((response) => {
      //   const tables = response.data;
      // //   setTableInfos(tables);
      //   setSelectedTable(tables[0]);
      // })
      // .catch((error) => {
      //   console.error('Error fetching table info:', error);
      // });
      getTableDetails();
    }

    setSelectedTable(parsedTableDetails);
  }, [getTableDetails]);

  return (
    <Accordion className="my-3 parent-body-shadow" defaultActiveKey={null}>
      <Accordion.Item eventKey={`${1}`}>
        <Accordion.Header
          className="parent-header-shadow"
          onClick={() => setRestaurantId(restaurant?.cartRestaurantId)}
        >
          <div className="d-flex align-items-center">
            <div className="header-weight-bold">
              {restaurant?.restaurantName}
            </div>
          </div>
          <div className="header-weight-bold">&#163; {totalBasePrice()}</div>
        </Accordion.Header>

        <Accordion.Body className="order-details-container">
          {restaurant.subOrders.map((subOrder) => {

            if (subOrder.orderTypeName !== "In-Store") {
              return (
                <OrderDetails
                  key={subOrder.id}
                  title={subOrder.orderTypeName}
                  restaurant={restaurant}
                  // onUpdateServiceCharge={onUpdateServiceCharge}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  selectedOkTable = {slectedTableHistroyForValidation}
                />
              );
            }
            if (subOrder.orderTypeName === "In-Store") {
              return (
                <OrderDetails
                  key={subOrder.id}
                  title={"In-Store"}
                  restaurant={restaurant}
                  // onUpdateServiceCharge={onUpdateServiceCharge}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  selectedOkTable = {slectedTableHistroyForValidation}
                />
              );
            }
          })}

          <div className="tips-window">
            <h5 style={{ fontWeight: "bold", marginBottom: "7px" }}>
              Tip Your Restaurant
            </h5>
            <div className="tips-button-group">
              <button
                onClick={() => handleSetTip(restaurant?.cartRestaurantId, 0, "amount")}
                className={`price-button controlled-font-size ${tips === 0 ? "price-button-active" : ""
                  }`}
              >
                Not Now
              </button>
              <button
                onClick={() => handleSetTip(restaurant?.cartRestaurantId, 10, "amount")}
                className={`price-button ${tips === 10 ? "price-button-active" : ""
                  }`}
              >
                &#163;10
              </button>
              <button
                onClick={() => handleSetTip(restaurant?.cartRestaurantId, 20, "amount")}
                className={`price-button ${tips === 20 ? "price-button-active" : ""
                  }`}
              >
                &#163;20
              </button>
              <button
                onClick={() => handleSetTip(restaurant?.cartRestaurantId, 30, "amount")}
                className={`price-button ${tips === 30 ? "price-button-active" : ""
                  }`}
              >
                &#163;30
              </button>
              <button
                onClick={() => handleSetTip(restaurant?.cartRestaurantId, 50, "amount")}
                className={`price-button ${tips === 50 ? "price-button-active" : ""
                  }`}
              >
                &#163;50
              </button>
            </div>
            <div className="specify-group">
              <button
                onClick={() => setSpecify(!specify)}
                className={`price-button ${specify ? "price-button-active me-2" : ""
                  }`}
              >
                Specify
              </button>
              {specify && (
                // <input
                //     className={`specify-input`}
                //     placeholder='Enter amount'
                //     type="number"
                //     min="0"
                //     onChange={(e) => handleSpecifyInputValue(e, restaurant?.cartRestaurantId)}
                // />
                <>
                  <div className="w-100">
                    <input
                      className={`specify-input ${specify ? "specify-input-active" : ""
                        }`}
                      placeholder="Enter an amount"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        handleSpecifyInputValue(e, restaurant?.cartRestaurantId)
                      }
                      disabled={disabledAmount}
                    />
                  </div>
                  <div className="fw-bold ms-4">Or</div>
                  <div className="w-100">
                    <input
                      className={`specify-input ${specify ? "specify-input-active" : ""
                        }`}
                      placeholder="Enter a percentage"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        handleSpecifyPercentageInput(e, restaurant?.cartRestaurantId)
                      }
                      disabled={disabledPercentage}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default RestaurantOrderDetails;
