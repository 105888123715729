// FoodModal.jsx
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import OrderCartUpdate from '../OrderCartUpdate/OrderCartUpdate';
import loadingImg from './../../../../Assets/Image/LoadingSpin.gif';
import { Skeleton } from 'antd';
import OrderCart from '../../../OrderPage/OrderCart/OrderCart';
import "./FoodModalUpdate.css";

const FoodModalUpdate = ({
  show,
  handleClose,
  orderDetails,
  selectedItemImage,
  selectedItemId,
  cart,
  setCart,
  loading
}) => {
  const [tabView, setTabView] = useState(1);
  // const selectedItemInCart = cart?.find((item) =>
  //   item.itemDetails.some((detail) => detail.itemId === selectedItemId)
  // );

  const findFoodItem = (cartData, foodItemId) => {
    for (const cart of cartData) {
      for (const subOrder of cart.subOrders) {
        const foodItem = subOrder.itemDetails.find(item => item.foodItemId === foodItemId);
        if (foodItem) {
          return foodItem
        }
      }
    }

    return null;
  }

  const selectedItemInCart = findFoodItem(cart, selectedItemId);

  console.log("food item clicked data: ", show, orderDetails, selectedItemImage, selectedItemId, cart, loading, selectedItemInCart);

  return (
    <Modal show={show} onHide={handleClose} className="modal-xl ">
      <Modal.Body style={{ padding: "0px", borderRadius: "10px" }}>
        {orderDetails != null ? (
          <>
            <button
              type="button"
              className="btn-close btn-modal"
              onClick={handleClose}
            ></button>
            <div className="row align-items-center bg-black foodModalParent">
              <div className="col-12 col-md-6 d-flex justify-content-center galleryModalImgDiv  ">
                <Swiper
                  spaceBetween={2}
                  grabCursor={false}
                  centeredSlides={false}
                  slidesPerView={"auto"}
                  cssMode={true}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="foodCart h-100 "
                  // initialSlide={selectedIndex}
                >
                  {orderDetails.foodItemImages?.length > 0 ? (
                    orderDetails.foodItemImages?.map((d, index) => {
                      return (
                        <SwiperSlide key={d.itemImagePath}>
                          <img
                            src={d.itemImagePath}
                            className="img-fluid "
                            alt={d.description}

                          // onClick={props.onClickInsideTest3}
                          />
                        </SwiperSlide>
                      );
                    })
                  ) : selectedItemImage ? (
                    <SwiperSlide key={"selectedItemImage"}>
                      <img
                        src={selectedItemImage}
                        className="img-fluid"
                        alt="..."
                      // onClick={props.onClickInsideTest3}
                      />
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide key="loading">
                      <img
                        src={loadingImg}
                        className="img-fluid h-10"
                        alt="..."
                        style={{
                          height: "5rem !important",
                          objectFit: "contain",
                        }}
                      // onClick={props.onClickInsideTest3}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
              <div className="col-12 col-md-6 bg-white foodModalRight">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-body  ">
                    <h3 style={{ color: "#3D1E19" }}>
                      <strong>{orderDetails?.itemName || <Skeleton />}</strong>
                    </h3>
                    <p className="my-1 text-muted">
                      {selectedItemImage?.description}{" "}
                    </p>
                    {/* <div className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={selectedItemImage?.restaurantLogo}
                          className='me-1 userImg'
                          alt=''
                        />
                        <h6>{selectedItemImage?.name}</h6>
                      </div>

                      <button className="follow-BUtton btn ms-1">Follow</button>
                    </div> */}
                    <hr className="mb-0" />
                  </div>
                </div>
                {orderDetails != null ? (
                  <>
                    <div className="col-12 ">
                      <div className="">
                        <div className="col-12 p-3 d-flex justify-content-between distanceBottom">
                          <button
                            data-mdb-ripple-color="dark"
                            type="button"
                            className={` dsrButton ${tabView === 1 ? "onselecteddsrButton" : ""
                              }`}
                            onClick={() => {
                              setTabView(1);
                            }}
                          >
                            Details
                          </button>
                          <button
                            data-mdb-ripple-color="dark"
                            type="button"
                            className={` dsrButton ${tabView === 2 ? "onselecteddsrButton" : ""
                              }`}
                            onClick={() => {
                              setTabView(2);
                            }}
                          >
                            Story
                          </button>
                          <button
                            data-mdb-ripple-color="dark"
                            type="button"
                            className={` dsrButton ${tabView === 3 ? "onselecteddsrButton" : ""
                              }`}
                            onClick={() => {
                              setTabView(3);
                            }}
                          >
                            Instructions
                          </button>
                        </div>
                      </div>
                      <div className=" orderCartRow">
                        <div className="col-12 d-flex justify-content-center">
                          <div className="col-12 d-flex justify-content-center">
                            <div className="card OrderfoodcardDetailsParagraph my-1 p-2  paragraph card-design">
                              {orderDetails != null && (
                                <>
                                  {tabView === 1 ? (
                                    <>
                                      {orderDetails.foodItemDetailList.map(
                                        (dt, i) => (
                                          <p key={i}>{dt.description}</p>
                                        )
                                      )}
                                    </>
                                  ) : tabView === 2 ? (
                                    <>
                                      <p>{orderDetails.foodItemStory.story}</p>
                                    </>
                                  ) : (
                                    <>
                                      {orderDetails.foodItemInstructionList.map(
                                        (dt, i) => (
                                          <div key={i}>
                                            <h5>{dt.name}</h5>
                                            <p style={{ fontSize: ".9rem" }}>
                                              {dt.description}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <br></br>
                      </div>
                      {/* mobileDisplay end */}
                    </div>

                    <OrderCartUpdate
                      handleClose={handleClose}
                      orderDetails={orderDetails}
                      from={"modal"}
                      itemId={selectedItemId}
                      selectedItem={selectedItemImage}
                      selectedItemInCart={selectedItemInCart}
                      cart={cart}
                    />
                  </>
                ) : (
                  <img
                    src={loadingImg}
                    className="img-fluid h-10"
                    alt="..."
                    style={{
                      height: "5rem !important",
                      objectFit: "contain",
                    }}
                  // onClick={props.onClickInsideTest3}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          // <div className='d-flex justify-content-center'>
          //   <div>
          //     <img
          //       src={loadingImg}
          //       className='img-fluid h-10'
          //       alt='...'
          //       style={{
          //         height: '5rem !important',
          //         objectFit: 'contain',
          //       }}
          //       // onClick={props.onClickInsideTest3}
          //     />
          //   </div>
          // </div>
          <div className="d-flex justify-content-center">
            <div className="w-100">
              <Skeleton.Image
                className="img-fluid w-100"
                style={{
                  height: "300px",
                }}
              />

              <div className="card" style={{ border: "0px" }}>
                <div className="card-body">
                  <h3 style={{ color: "#3D1E19" }}>
                    <Skeleton active />
                  </h3>
                  <p className="my-1 text-muted">
                    <Skeleton active />
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Skeleton.Avatar className="me-1" active />
                      <h6>
                        <Skeleton active />
                      </h6>
                    </div>
                  </div>
                  <hr className="mb-0" />
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Skeleton active paragraph={{ rows: 3 }} />
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FoodModalUpdate;
