import React, { useEffect, useState, useRef, useCallback } from "react";
import filter from '../../../Assets/Icons/filter.png';
import { IoClose } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "../../Home/FoodSelection/FoodSelection.css";
import "../RestaurantDetailView/RestaurantDetailView.css";
import search from "./../../../Assets/Icons/searchIcon.png";
import "./MenuSelection.css";
import MenuSlider from "../../GalleryView/menuslider/MenuSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "../../GalleryView/menuslider/slider";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedMenu } from "../../../actions/Menu/selectedMenuActions";
import {
  getSearchValue,
  setSearchValue,
} from "../../../actions/SearchBar/searchAtion";
import { selectFirstMenuItem } from "../../../actions/Menu/menuActions";
import { searchingOption } from "../../../actions/Menu/searchingActions";
import { setFilteredItemData } from "../../../actions/SearchBar/SuggestedItemData";
import { setSearchingStart } from "../../../actions/SearchBar/SearchingStart";
import axios from "axios";
import searchIcon from '../../../Assets/Icons/searchIcon.png';
import OrderCart from "../../OrderPage/OrderCart/OrderCart";
import FoodModal from "../../Home/FoodSelection/FoodModal";
import { sliceText } from "../../../utils/sliceText";
import { } from "@testing-library/react";
import StatusCapsule from "./Components/StatusCapsule";
import { getFoodStatus } from "../../../utils/getFoodStatus";

const ManuSelection = (props) => {
  const { resname } = useParams();
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search on all menus");
  const oneScheduleClick = props.oneScheduleClick;

  // useEffect(() => {
  //   alert(searchPlaceholder);
  // }, [searchPlaceholder]);

  const [SearchedFoodList, setSearchedFoodList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [showModalCard, setShowModalCard] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedItemImage, setSelectedItemImage] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showOutOfHourModal, setShowOutOfHourModal] = useState(false);
  const [showUnavailableReasonModal, setShowUnavailableReasonModal] = useState(false);

  const apiAddress = process.env.REACT_APP_SECRET;
  // const [scrollAmount, setScrollAmount] = useState(0);
  // const containerRef = useRef(null);

  const dispatch = useDispatch();
  const searchValueFromRedux = useSelector(state => state.searchValue.value);
  const itemDataFromRedux = useSelector(state => state.itemDataForSuggestion.foodItems);

  // const handleScrollRight = () => {
  //   const container = containerRef.current;
  //   const containerWidth = container.clientWidth;
  //   const maxScrollAmount = container.scrollWidth - containerWidth;
  //   const newScrollAmount = Math.min( scrollAmount + containerWidth, maxScrollAmount );
  //   container.scrollTo({ left: newScrollAmount, behavior: 'smooth' });
  //   setScrollAmount(newScrollAmount);
  // };

  // Dynamic Url Value from search Field
  // const location = useLocation();
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const searchParam = params.get('s') || '';

  //   setUrlValue(searchParam);
  // }, [location.search]);

  // const handleSearchClick = () => {
  //   // const value = e.target.value
  //   setSearchPlaceholder('Search store menue');
  // };
  // const handleCorporateClick = () => {
  //   setSearchPlaceholder('Search corporate menue');
  // };
  // const handleSubscriptionClick = () => {
  //   setSearchPlaceholder('Search subscription');
  // };

  //select start
  // const [selectedOption, setSelectedOption] = useState(null);
  // const options = [
  //   { value: 'barger', label: 'Barger' },
  //   { value: 'pizza', label: 'Pizza' },
  //   { value: 'chicken', label: 'Chicken' },
  // ];
  // function handleOptionChange(selectedOption) {
  //   setSelectedOption(selectedOption);
  // }

  //select end

  //search start
  const [isVisisbleDiv, setIsvisibleDiv] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const GetSearchData = async (searchStr) => {
    await axios
      .get(
        apiAddress +
        "/api/Search/GetFoodSearchDataByRestaurantUrl?resUrl=" +
        resname +
        "&searchStr=" +
        searchStr
      )
      .then((response) => {
        setSearchedFoodList(response.data);
      });
  };

  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 200);
  //   };
  // };

  // const handleSearch = (event) => {
  //   var data = event.target.value;
  //   if (data != '') {
  //     setIsvisibleDiv(true);
  //     GetSearchData(data);
  //   } else {
  //     setIsvisibleDiv(false);
  //   }
  //   setDialogData(data);
  // };

  // const optimizedVersion = useCallback(debounce(handleSearch), []);

  const handleIsClick = () => {
    setIsvisibleDiv(false);

    setDialogData("");
  };

  //search end

  //  search modal outside click start

  const searchDialogdivRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchDialogdivRef.current &&
      !searchDialogdivRef.current.contains(event.target)
    ) {
      setIsvisibleDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // search modal outside click end

  //swiper start
  const [fromHome, SetFromHome] = useState(true);
  useEffect(() => {
    if (props.from === 2) {
      SetFromHome(false);
    }
  });
  //swiper end

  // Menu Selection from Redux State
  const [selectedMenu, setMenu] = useState();
  const selectedMenuFromRedux = useSelector(
    (state) => state.selectedMenu.selectedMenu
  );
  const firstSelectedMenuFromRedux = useSelector(
    (state) => state.firstMenu.selectedFirstMenuItem
  );

  useEffect(() => {
    if (selectedMenuFromRedux !== null) {
      setMenu(selectedMenuFromRedux);
    } else if (firstSelectedMenuFromRedux !== null) {
      setMenu(firstSelectedMenuFromRedux);
    } else {
      setMenu("");
    }
  }, [selectedMenuFromRedux, firstSelectedMenuFromRedux]);

  // Search Functionality
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);

  useEffect(() => {
    if (searchValueFromRedux === "") {
      setShowSearchSuggestions(false);
    }
  }, [searchValueFromRedux]);

  const selectedItemName = (selectedItemName) => {
    const foodItemNames = selectedItemName.map(item => item.itemName)
    dispatch(setSearchValue(foodItemNames));
    setShowSearchSuggestions(false);
    dispatch(setSearchingStart(true));
  }

  const [userValue, setUserValue] = useState("");
  /* Store Search Data into Redux */
  const handleChange = (event) => {
    setUserValue(event.target.value);
    dispatch(setSearchValue(event.target.value));
    setShowSearchSuggestions(true);
  };

  const [searchOption, setSearchOption] = useState("allMenu");

  useEffect(() => {
    if (selectedMenuFromRedux === null && firstSelectedMenuFromRedux === null) {
      dispatch(searchingOption("globall"));
    }
  }, [selectedMenuFromRedux, firstSelectedMenuFromRedux]);

  const handleSearchOptionChange = (option) => {
    if (option.target.value === "selectedMenu") {
      setSearchOption("selectedMenu");
      if (
        selectedMenuFromRedux !== null ||
        firstSelectedMenuFromRedux !== null
      ) {
        dispatch(searchingOption("onSelectedMenu"));
        setSearchPlaceholder("Search on " + (selectedMenu?.menuName || firstSelectedMenuFromRedux?.menuName || "No menu is selected"));
      } else {
        dispatch(searchingOption("globall"));
        setSearchPlaceholder("Search on all menus");
      }
    } else {
      setSearchOption("allMenu");
      dispatch(searchingOption("globall"));
      setSearchPlaceholder("Search on all menus");
    }
    setShowSetting(false);
  };

  useEffect(() => {
    if (selectedMenuFromRedux === null) {
      dispatch(searchingOption("globall"));
    }
  }, [selectedMenuFromRedux]);

  // search option visiblility controll
  const [showSetting, setShowSetting] = useState(false);

  const settingsClick = () => {
    setShowSetting(prev => !prev);
  };

  // ouside click functionality for search Options
  const dropdownRef = useRef(null);
  const dropdownRefMobile = useRef(null);
  const searchSuggestion = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
        (dropdownRefMobile && !dropdownRefMobile.current.contains(event.target))
      ) {
        setTimeout(() => { setShowSetting(false); }, 130);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };

  }, [showSetting]);

  /* making the last element borderlesss */
  useEffect(() => {
    const dividers = document.querySelectorAll('.divider-suggestions');
    if (dividers.length > 0) {
      dividers[dividers.length - 1].style.color = 'red';
    }
  }, [itemDataFromRedux]);

  const timeClicked = () => {
    oneScheduleClick();
  }

  const extractContext = (description, searchString) => {
    if (!searchString || !description) return ''; // If no search string or description, return empty string

    const regex = new RegExp(`(\\S+\\s+){0,2}(\\S*${searchString}\\S*)(\\s+\\S+){0,2}`, 'gi'); // Match the searchString and up to 2 words before and after
    const match = description.match(regex);

    if (match) {
      // Return the matched context with '...' before and after
      return `...${match[0].trim()}...`;
    }

    return ''; // If no match found, return an empty string
  };

  const highlightMatch = (text, manupulate = "no") => {
    // debugger
    if (!searchValueFromRedux) return text;

    const regex = new RegExp(`(${searchValueFromRedux})`, 'gi');
    const parts = text.split(regex);
    if (manupulate === "no") {
      return parts.map((part, index) =>
        part.toLowerCase() === searchValueFromRedux.toLowerCase() ? (
          <span key={index} style={{ backgroundColor: '#ffc824' }}>{part}</span>
        ) : part
      );
    } else {
      return parts.map((part, index) =>
        part.toLowerCase() === searchValueFromRedux.toLowerCase() ? (
          <span key={index} style={{ backgroundColor: '#ffc824' }}>{part}</span>
        ) : ".." + part.substring(1, 3)
      );
    }
  };

  const getFoodStatus = (activeStatus, outOfHourStatus) => {
    if (activeStatus === 0 && outOfHourStatus === 0) {
      return "Unavailable";
    } else if (activeStatus === 0 && outOfHourStatus === 1) {
      return "Unavailable";
    } else if (activeStatus === 1 && outOfHourStatus === 1) {
      return "Out of hour";
    } else if (activeStatus === 1 && outOfHourStatus === 0) {
      return "Available";
    } else {
      return "Unknown status";
    }
  };

  const getItemDetails = (data) => {
    // setIsLoading(true);
    setShowModalCard(true);
    setOrderDetails(null);
    // setIsLoading(false);
    setSelectedItemId(null);
    setSelectedItemImage([]);
    axios.get(
      apiAddress + '/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=' + data.id
    ).then((response) => {
      if (getFoodStatus(response?.data?.isActive, response?.data?.status) === "Out of hour") {
        setShowOutOfHourModal(true);
      }
      if (getFoodStatus(response?.data?.isActive, response?.data?.status) === "Unavailable") {
        setShowUnavailableReasonModal(true);
      }

      const updatedResponseData = {
        ...response.data,
        restaurantId: response.data.restaurantId,
      };
      setOrderDetails(updatedResponseData);

      setSelectedItemId(data.foodItemId);
      setSelectedItemImage(updatedResponseData);
      // setIsLoading(false);
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => setShowModalCard(false);

  return (
    <div className="row align-items-center flex-row-revers">
      {/* Desktop search UI */}
      <div className="col-12 col-md-4 menuSliderDesktop searchBox">
        <div
          className="d-flex flex-row position-relative"
          ref={searchDialogdivRef}
        // style={{ width: "100%" }}
        >
          {/* Search on option section */}
          {showSetting && (
            <div
              className="dropdown-menu show searchOption position-absolute"
              ref={dropdownRef}
              style={{ zIndex: '5000' }}
            >
              {/* Search on all menu button */}
              <button
                className={`dropdown-item ${searchOption === "allMenu" ? "active" : ""
                  }`}
                onClick={() => handleSearchOptionChange({ target: { value: "allMenu" } })}
              >
                Search on all menus
              </button>
              {/* show selected menu status on search */}
              <div role="separator" className="dropdown-divider"></div>
              <button
                className={`
                            dropdown-item ${searchOption === "selectedMenu" ? "active" : ""}
                            ${selectedMenuFromRedux === null && firstSelectedMenuFromRedux === null ? "disabled" : ""}
                          `}
                onClick={() => handleSearchOptionChange({ target: { value: "selectedMenu" } })}
              >
                {selectedMenuFromRedux || firstSelectedMenuFromRedux
                  ? `Search On ${selectedMenuFromRedux?.menuName || firstSelectedMenuFromRedux?.menuName || "No menu is selected"}`
                  : "No menu is selected"}
              </button>
            </div>
          )}

          {showModalCard && (
            <FoodModal
              show={showModalCard}
              handleClose={handleClose}
              orderDetails={orderDetails}
              selectedItemImage={selectedItemImage}
              selectedItemId={selectedItemId}
              selectedIndex={selectedImageIndex}
              showUnavailableReasonModal={showUnavailableReasonModal}
              setShowUnavailableReasonModal={setShowUnavailableReasonModal}
              showOutOfHourModal={showOutOfHourModal}
              setShowOutOfHourModal={setShowOutOfHourModal}
            />
          )}

          {/* FoodItems Name Suggestions UI */}
          {showSearchSuggestions && (
            <div
              className="dropdown-menu show searchOption position-absolute"
              ref={searchSuggestion}
            >
              <div className='Search-info-container ms-4' onClick={() => selectedItemName(itemDataFromRedux)}>
                <div className='search-result-icon me-2'>
                  <img src={searchIcon} alt='search icon' />
                </div>
                <div className='search-term'>
                  Search for "{userValue}"
                </div>
              </div>

              {itemDataFromRedux?.map((item) => (
                <div key={item.id} style={{ padding: '5px 10px', cursor: 'pointer', zIndex: '4000' }}
                  onClick={() => getItemDetails(item)}
                // onClick={() => selectedItemName(item.itemName)}
                // onClick={() => selectedItemName(itemDataFromRedux)}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', padding: '0px 10px' }}>
                    {/* fooditem image */}
                    <div>
                      {item.foodItemImages && item.foodItemImages.map((img, index) => (
                        <img
                          key={index}
                          src={img.itemImagePath}
                          alt={`Image of ${item.itemName}`}
                          style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                        />
                      ))}
                    </div>

                    {/* Fooditem name and description */}
                    <div>
                      {/* Item Name */}
                      <p className="d-flex gap-2" style={{ margin: '0px', padding: ' 5px 0px', fontWeight: '600', fontSize: '14px' }}>
                        {/* {item.itemName} */}
                        <span>{highlightMatch(item.itemName, "no")}</span>
                        {/* FoodLabel for desktop only */}
                        {/* <StatusCapsule status={getFoodStatus(item.isActive, item.status)} /> */}
                      </p>

                      {/* {item.description} */}
                      <div>
                        {extractContext(item.description, searchValueFromRedux) ?
                          <p style={{ fontSize: '13px', fontWeight: '600', color: 'rgb(128, 97, 91)', margin: '0px', padding: '0px' }}>
                            {(highlightMatch(extractContext(sliceText(item.description, 23), searchValueFromRedux), "no"))}
                          </p> :
                          <p style={{ fontSize: '13px', fontWeight: '600', color: 'rgb(128, 97, 91)', margin: '0px', padding: '0px' }}>
                            {highlightMatch(sliceText(item.description, 23), "no")}
                          </p>
                        }
                      </div>
                      {/* Section match && keyword matches for lower desktop screen */}
                      <div className="sectionKeywordMatchLower">
                        { /* Check if searchValue contains at least 2 characters from sectionName */
                          searchValueFromRedux?.length >= 2 && item.sectionName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ? (
                            <div className="section-suggestion">
                              <p className="suggestedCategory">Section</p>
                              <p>{highlightMatch(extractContext(item.sectionName, searchValueFromRedux))}</p>
                            </div>
                          ) : /* Check if searchValue contains at least 2 characters from sectionName */
                            searchValueFromRedux?.length >= 2 && item.keywordDetails.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                              ? (
                                <div className="section-suggestion">
                                  <p className="suggestedCategory">Details</p>
                                  <p>{highlightMatch(extractContext(item.keywordDetails, searchValueFromRedux))}</p>
                                </div>
                              ) : /* Check if searchValue contains at least 2 characters from keywordTagName */
                              searchValueFromRedux?.length >= 2 && item.keywordTagName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ?
                                (
                                  <div className="section-suggestion">
                                    <p className="suggestedCategory">Keyword Tag</p>
                                    <p>{highlightMatch(extractContext(item.keywordTagName, searchValueFromRedux))}</p>
                                  </div>
                                )
                                : null
                        }
                      </div>
                    </div>

                    {/* Section match && keyword matches */}
                    <div className="section-keyword">
                      { /* Check if searchValue contains at least 2 characters from sectionName */
                        searchValueFromRedux?.length >= 2 && item.sectionName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ? (
                          <div className="section-suggestion">
                            <p className="suggestedCategory">Section</p>
                            <p>{highlightMatch(extractContext(item.sectionName, searchValueFromRedux))}</p>
                          </div>
                        ) : /* Check if searchValue contains at least 2 characters from sectionName */
                          searchValueFromRedux?.length >= 2 && item.keywordDetails.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                            ? (
                              <div className="section-suggestion">
                                <p className="suggestedCategory">Details</p>
                                <p>{highlightMatch(extractContext(item.keywordDetails, searchValueFromRedux))}</p>
                              </div>
                            ) : /* Check if searchValue contains at least 2 characters from keywordTagName */
                            searchValueFromRedux?.length >= 2 && item.keywordTagName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ?
                              (
                                <div className="section-suggestion">
                                  <p className="suggestedCategory">Keyword Tag</p>
                                  <p>{highlightMatch(extractContext(item.keywordTagName, searchValueFromRedux))}</p>
                                </div>
                              )
                              : null
                      }
                    </div>
                  </div>
                  <div className="divider-suggestions"></div>
                </div>
              ))}

              {itemDataFromRedux?.length === 0 ? (
                <p style={{ textAlign: 'center', color: 'gray' }}>No Food Item Found</p>
              ) : null}
            </div>
          )}

          <FaSearch className="search-icon" />

          <input
            type="text"
            className="searchBar"
            placeholder={searchPlaceholder}
            // value={searchValueFromRedux}
            value={userValue}
            onChange={handleChange}
            onClick={handleIsClick}
            style={{ paddingLeft: "40px" }}
          />
          {/* Search Cancel button */}
          {searchValueFromRedux &&
            <button
              className="btn close close-search"
              style={{
                border: "none",
                borderRadius: "50% 0px 0px 50%",
                color: "black",
                fontSize: "24px",
                position: "absolute",
                right: '25px',
                backgroundColor: 'transparent',
                lineHeight: '30px',
                // margin: '0px',
                // padding: '0px'
              }}
              type="button"
              onClick={() => {
                dispatch(setSearchValue(""));
                setUserValue("");
              }}
            >
              <IoClose title="Clean search input" />
            </button>
          }
          {/* Show search options button */}
          <button
            className="btn dropdown-toggle searchSettings"
            style={{
              border: "none",
              borderRadius: "50% 0px 0px 50%",
              color: "black",
              fontSize: "20px",
              position: "absolute",
              right: "0",
              top: '6px',
              right: '10px',
              padding: '0px'
            }}
            type="button"
          >
            <img onClick={settingsClick} src={filter} style={{ width: '25px', height: '30px' }} />
          </button>

          {isVisisbleDiv && (
            <div className="" style={{ width: "100%" }}>
              <div className="searchBottommDiv">
                {SearchedFoodList.length == 0 && (
                  <div className="d-flex justify-content-center">
                    <span>No food found</span>
                  </div>
                )}
                {SearchedFoodList.map((d, i) => {
                  return (
                    <>
                      <div
                        className="pt-4 d-flex "
                        style={{ paddingLeft: "6px" }}
                      >
                        <img
                          src={search}
                          alt="img"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginTop: "2px",
                            marginRight: "10px",
                          }}
                        />
                        {d.name}
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Menu Slider for both desktop and mobile */}
      <div className="col-12 col-md-8 ">
        {/* <MenuSlider setSearchPlaceholder={setSearchPlaceholder} /> */}
        <Slider resUrl={resname} onTimeClick={() => timeClicked()} colseScheduler={props.colseScheduler}></Slider>
      </div>

      {/* Mobile search UI */}
      <div className="col-12 col-md-3 menuSliderMobile">
        <div
          className="d-flex flex-row position-relative"
          ref={searchDialogdivRef}
          style={{ width: "100%" }}
        >
          {/* Search on option UI */}
          {showSetting && (
            <div
              className="dropdown-menu show searchOption position-absolute"
              ref={dropdownRefMobile}
              style={{ zIndex: '5000', width: '100%' }}
            >
              <button
                className={`dropdown-item ${searchOption === "allMenu" ? "active" : ""
                  }`}
                onClick={() => handleSearchOptionChange({ target: { value: "allMenu" } })}
              >
                Search on all menus
              </button>
              <div role="separator" className="dropdown-divider"></div>
              <button
                className={`
                            dropdown-item ${searchOption === "selectedMenu" ? "active" : ""}
                            ${selectedMenuFromRedux === null && firstSelectedMenuFromRedux === null ? "disabled" : ""}
                          `}
                onClick={() => handleSearchOptionChange({ target: { value: "selectedMenu" } })}
              >
                {selectedMenuFromRedux || firstSelectedMenuFromRedux
                  ? `Search On ${selectedMenuFromRedux?.menuName || firstSelectedMenuFromRedux?.menuName || "No menu is selected"}`
                  : "No menu is selected"}
              </button>
            </div>
          )}

          {/* -- search result items -- */}
          {showSearchSuggestions && (
            <div className="dropdown-menu show searchOption position-absolute" ref={searchSuggestion} >
              {/* Search For title and search icon area */}
              <div className='Search-info-container ms-4' onClick={() => selectedItemName(itemDataFromRedux)}>
                <div className='search-result-icon me-2'>
                  <img src={searchIcon} alt='search icon' />
                </div>
                <div className='search-term'>
                  Search for "{userValue}"
                </div>
              </div>

              {/* Search suggestion / live result of search */}
              {itemDataFromRedux?.map((item) => (
                <div key={item.id} onClick={() => getItemDetails(item)} style={{ padding: '5px 10px', cursor: 'pointer', zIndex: '4000' }}
                // onClick={() => selectedItemName(item.itemName)}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', padding: '0px 10px' }} >
                    {/* FoodItem Image */}
                    <div>
                      {item.foodItemImages && item.foodItemImages.map((img, index) => (
                        <img
                          key={index}
                          src={img.itemImagePath}
                          alt={`Image of ${item.itemName}`}
                          style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                        />
                      ))}
                    </div>

                    {/* Name and description */}
                    <div>
                      {/* {item.itemName} */}
                      <p style={{ margin: '0px', padding: ' 5px 0px', fontWeight: '600', fontSize: '14px', display: 'flex', gap: '10px' }}>
                        <span>
                          {highlightMatch(item.itemName, "no")}
                        </span>
                        {/* <StatusCapsule status={getFoodStatus(item.isActive, item.status)} /> */}
                      </p>

                      {/* {item.description} */}
                      {extractContext(item.description, searchValueFromRedux) ?
                        <p style={{ fontSize: '13px', fontWeight: '600', color: 'rgb(128, 97, 91)', margin: '0px', padding: '0px' }}>
                          {(highlightMatch(extractContext(sliceText(item.description, 23), searchValueFromRedux), "no"))}
                        </p> :
                        <p style={{ fontSize: '13px', fontWeight: '600', color: 'rgb(128, 97, 91)', margin: '0px', padding: '0px' }}>
                          {highlightMatch(sliceText(item.description, 23), "no")}
                        </p>
                      }

                      {/* Section or keyword matched */}
                      <div className="section-keyword-mobile">
                        {
                          /* Check if searchValue contains at least 2 characters from sectionName */
                          searchValueFromRedux?.length >= 2 && item.sectionName.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                            ? (
                              <div className="section-suggestion">
                                <p className="suggestedCategory">Section</p>
                                <p>{highlightMatch(extractContext(item.sectionName, searchValueFromRedux))}</p>
                              </div>
                            ) :
                            /* Check if searchValue contains at least 2 characters from sectionName */
                            searchValueFromRedux?.length >= 2 && item.keywordDetails.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                              ? (
                                <div className="section-suggestion">
                                  <p className="suggestedCategory">Details</p>
                                  <p>{highlightMatch(extractContext(item.keywordDetails, searchValueFromRedux))}</p>
                                </div>
                              ) :
                              /* Check if searchValue contains at least 2 characters from keywordTagName */
                              searchValueFromRedux?.length >= 2 && item.keywordTagName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ?
                                (
                                  <div className="section-suggestion">
                                    <p className="suggestedCategory">Keyword Tag</p>
                                    <p>{highlightMatch(extractContext(item.keywordTagName, searchValueFromRedux))}</p>
                                  </div>
                                ) : null
                        }
                      </div>
                    </div>

                    {/* Section or keyword matched */}
                    <div className="section-keyword-mobile-default">
                      {
                        /* Check if searchValue contains at least 2 characters from sectionName */
                        searchValueFromRedux?.length >= 2 && item.sectionName.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                          ? (
                            <div className="section-suggestion">
                              <p className="suggestedCategory">Section</p>
                              <p>{highlightMatch(extractContext(item.sectionName, searchValueFromRedux))}</p>
                            </div>
                          ) :
                          /* Check if searchValue contains at least 2 characters from sectionName */
                          searchValueFromRedux?.length >= 2 && item.keywordDetails.toLowerCase().includes(searchValueFromRedux.toLowerCase())
                            ? (
                              <div className="section-suggestion">
                                <p className="suggestedCategory">Details</p>
                                <p>{highlightMatch(extractContext(item.keywordDetails, searchValueFromRedux))}</p>
                              </div>
                            ) :
                            /* Check if searchValue contains at least 2 characters from keywordTagName */
                            searchValueFromRedux?.length >= 2 && item.keywordTagName.toLowerCase().includes(searchValueFromRedux.toLowerCase()) ?
                              (
                                <div className="section-suggestion">
                                  <p className="suggestedCategory">Keyword Tag</p>
                                  <p>{highlightMatch(extractContext(item.keywordTagName, searchValueFromRedux))}</p>
                                </div>
                              ) : null
                      }
                    </div>

                  </div>

                  <div className="divider-suggestions"></div>
                </div>
              ))}

              {/* No result found message */}
              {itemDataFromRedux?.length === 0 ? (
                <p style={{ textAlign: 'center', color: 'gray' }}>No Food Item Found</p>
              ) : null}
            </div>
          )}

          {/* Search Input field */}
          <FaSearch className="search-icon" />
          <input
            type="text"
            className="searchBar"
            placeholder={searchPlaceholder}
            value={searchValueFromRedux}
            onChange={handleChange}
            onClick={handleIsClick}
            style={{ paddingLeft: "40px" }}
          />

          {/* --> Search cancel icon <-- */}
          {searchValueFromRedux &&
            <button
              className="btn close close-search"
              style={{
                border: "none",
                borderRadius: "50% 0px 0px 50%",
                color: "black",
                fontSize: "30px",
                position: "absolute",
                right: '25px',
                backgroundColor: 'transparent',
                lineHeight: '30px',
              }}
              type="button"
              onClick={() => dispatch(setSearchValue(""))}
            >
              <IoClose title="Clean search input" />
            </button>
          }

          {/* Settings Icon on search box */}
          <button
            className="btn dropdown-toggle searchSettings"
            style={{
              border: "none",
              borderRadius: "50% 0px 0px 50%",
              color: "black",
              fontSize: "20px",
              position: "absolute",
              right: "0",
              top: '6px',
              right: '10px',
              padding: '0px'
            }}
            type="button"
          >
            <img src={filter} style={{ width: '25px', height: '30px' }}
              onClick={settingsClick} />
          </button>

          {/* No Fooditem found status*/}
          {isVisisbleDiv && (
            <div className="" style={{ width: "100%" }}>
              <div className="searchBottommDiv">
                {SearchedFoodList.length == 0 && (
                  <div className="d-flex justify-content-center">
                    <span>No food found</span>
                  </div>
                )}

                {SearchedFoodList.map((d, i) => {
                  return (
                    <>
                      <div
                        className="pt-4 d-flex "
                        style={{ paddingLeft: "6px" }}
                      >
                        <img
                          src={search}
                          alt="img"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginTop: "2px",
                            marginRight: "10px",
                          }}
                        />
                        {d.name}
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManuSelection;
