import React from 'react';
import imageGallery2 from './../../../Assets/Icons/picture1.png';
import restaurantColorful from './../../../Assets/Icons/restaurant-colorful.png';
import menuColorful from './../../../Assets/Icons/menu-colorful.png';
import foodColorful from './../../../Assets/Icons/foodItem-colorful.png';
import sectionColorful from './../../../Assets/Icons/section-colorful.png';
import dot from './../../../Assets/Icons/dots.png';
import '../Components/EditShareYourThoughtsModal.css';

export default function ModalFooter({
  step,
  setStep,
  handleFilterModal,
  fileInputRef,
  handleImageChange,
  handleSave,
  loading,
  comments,
  uploadfiles,
}) {
  return (
    <>
      <div className='d-flex justify-content-between addToYourPost'>
        <h6 className='pt-1'>Link items to post</h6>
        <div className='d-flex justify-content-between'>
          <label htmlFor='EditImageUpload'>
            <img
              src={imageGallery2}
              alt='Upload'
              style={{ width: '1.5rem', cursor: 'pointer' }}
            />
          </label>
          <input
            type='file'
            ref={fileInputRef}
            id='EditImageUpload' // Ensure this matches the `htmlFor` in the label
            accept='image/*'
            onChange={handleImageChange}
            style={{ display: 'none' }}
            multiple
          />

          <div className='px-2' onClick={() => handleFilterModal(0)}>
            <img src={restaurantColorful} alt='' style={{ width: '1.5rem' }} />
          </div>
          <div className='px-2' onClick={() => handleFilterModal(1)}>
            <img src={menuColorful} alt='' style={{ width: '1.5rem' }} />
          </div>
          <div className='px-2' onClick={() => handleFilterModal(2)}>
            <img src={sectionColorful} alt='' style={{ width: '1.5rem' }} />
          </div>
          <div className='px-2' onClick={() => handleFilterModal(3)}>
            <img src={foodColorful} alt='' style={{ width: '1.5rem' }} />
          </div>
          <div>
            <img src={dot} alt='' style={{ width: '1.5rem' }} />
          </div>
        </div>
      </div>
      <button
        className='btn-custom mt-3'
        // onClick={() => {
        //   handleSave();
        // }}
        disabled={(!comments && !uploadfiles.length) || loading}
        onClick={handleSave}
      >
        {loading ? 'Loading...' : 'Edit Post'}
      </button>
    </>
  );
}
