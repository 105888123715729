import axios from 'axios';

async function urlToFile(url, filename) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
}

export async function uploadFiles(filesData) {
  console.log('files data', filesData);
  const apiAddress = process.env.REACT_APP_SECRET;

  var data = [];

  if (typeof filesData === 'string' && filesData.startsWith('blob:')) {
    filesData = await urlToFile(filesData, 'uploadFile.jpg');
  }

  // filesData.map(async(dt, index) => {
  // console.log(dt.length);
  const formData = new FormData();
  formData.append('file', filesData);
  await axios({
    method: 'post',
    url: apiAddress + '/api/FileUpload/addfile',
    headers: {
      'Content-Type': 'multipart/form-data', // <- HERE
    },
    data: formData,
  })
    .then((response) => {
      console.log(response);
      data.push(response.data.url);
      console.log(response.data.url);
    })
    .catch((error) => {
      console.log(error);
    });
  // })

  return data;
}
