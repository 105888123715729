import { combineReducers } from 'redux';
/*Importing All the indivisuals reducers*/
import changeLoad from './changeLoad';
import testReducers from './testReducers';
import openRegisterPage from './openRegisterPage';
import changeSearchName from './changeSearchName';
import commentText from './commentText';
import searchiconClick from './searchiconClick';
import CommentImgShow from './commentImgShow';
import windowLoad from './windowLoad';
import forgetPassword from './forgetPassword';
import chatReducer from './chatReducer';
import menulist from './menulist';
import menuslected from './menuslected';
import fooditems from './fooditems';
import searchLoader from './searchLoader';
import cartReducer from './reducers';
import sectionSubsectionSelect from './sectionSubsectionSelect';
import postDataReducer from './postDataReducer';

import filterBarReducer from './FilterBar/filterBarReducer';
import filteredSectionByMenuReducer from './FilterBar/filteredSectionByMenuReducer';

/* MenuReducers */
import menuReducer from './Menu/menuReducer';
import selectedMenuReducer from './Menu/selectedMenuReducer';
import selectFirstMenuReducer from './Menu/selectFirstMenuReducer';

import searchOptionReducer from './Menu/searchOptionReducer';

/* SearchBar Reducers */
import searchBarReducer from './SerachBar/searchBarReducer';
import filteredFoodItemData from './SerachBar/filterdFoodItemForSuggestion'
import searchStart from './SerachBar/searchStartReducer';
import cartCounterReducer from './cartCounterReducer';

const rootReducers = combineReducers({
  changeLoad,
  testReducers,
  openRegisterPage,
  changeSearchName,
  commentText,
  searchiconClick,
  CommentImgShow,
  windowLoad,
  forgetPassword,
  chatReducer,
  menulist,
  menuslected,
  fooditems,
  searchLoader,
  sectionSubsectionSelect,
  cartReducer,
  postData: postDataReducer,
  
  menu: menuReducer,
  selectedMenu: selectedMenuReducer,
  firstMenu: selectFirstMenuReducer,

  filterbar: filterBarReducer,
  searchValue: searchBarReducer,
  searchOption: searchOptionReducer,
  filteredSection: filteredSectionByMenuReducer,
  itemDataForSuggestion: filteredFoodItemData,
  searchStart,
  cart: cartCounterReducer
});

export default rootReducers;
