import React from "react";
import PendingFoodCard from "./PendingFoodCard";
import "./PendingFood.css";

const PendingFood = ({ restaurant, flattenFoodItems }) => {
  // const foodItems = restaurant?.itemDetails;
  const foodItems = restaurant?.subOrders?.map(
    (subOrder) => subOrder?.itemDetails
  );

  return (
    <div>
      {/* <div className='d-flex container mt-5'>
                <p
                    className='restaurantName'
                    style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'italic',
                    }}
                >
                    {restaurant?.restaurantName}
                </p>

                {
                    restaurant?.orderStatus && (
                        restaurant?.orderStatus === "Pending" ?
                            <p className="order-status pending-status"> {restaurant?.orderStatus} </p> :

                            restaurant?.orderStatus === "Preparing" ?
                                <p className='order-status preparing-status'> {restaurant?.orderStatus} </p> :

                                restaurant?.orderStatus === "Ready" ?
                                    <p className="order-status ready-status"> {restaurant?.orderStatus} </p> :

                                    restaurant?.orderStatus === "Out for delivery" ?
                                        <p className='order-status out-for-delivery-status'> {restaurant?.orderStatus} </p> : null
                    )
                }
            </div> */}

      {/* New Design pending TAB Start */}
      <div
        className={`cards-container ${
          foodItems.length >= 3 ? "grid-center" : ""
        }`}
      >
        {foodItems.map((item) =>
          item?.map((foodItem, idx) => (
            <PendingFoodCard
              key={idx}
              restaurant={restaurant}
              foodItem={foodItem}
            />
          ))
        )}
      </div>
      {/* New Design Pending Tab END */}
    </div>
  );
};

export default PendingFood;
