import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import './ShareYourThoughts.css';
import imageGallery2 from './../../../Assets/Icons/picture1.png';
import backButton from './../../../Assets/Icons/back-button.png';
import restaurantColorful from './../../../Assets/Icons/restaurant-colorful.png';
import menuColorful from './../../../Assets/Icons/menu-colorful.png';
import foodColorful from './../../../Assets/Icons/foodItem-colorful.png';
import editIcon from './../../../Assets/Icons/icon-edit.png';
import sectionColorful from './../../../Assets/Icons/section-colorful.png';
import dot from './../../../Assets/Icons/dots.png';
import { setCommentText } from '../../../actions';
import { Autocomplete, Box, TextField } from '@mui/material';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { uploadFiles } from '../../../FileUploader/FileUploader';
import { ToastContainer, toast } from 'react-toastify';
import { setPostData } from '../../../actions/actions';
import Cropper from 'react-easy-crop';
import getCroppedImg from './ShowCroppedImage/ShowCroppedImage';
import loadingImage from "../../../Assets/Image/loading.gif";

const ShareYourthoughts = ({
  onClick,
  removeIcon,
  editImage,
  setEditImage,
  logo,
  restaurantName,
  restaurantId,
  setInitialData,
  initialData,
  setEditImageUrl,
  editImageUrl,
  setShow,
  handleOpen,
  discard,
  setDiscard,
  show,
  modalVisible,
  setModalVisible,
  uploadfiles,
  setUploadFiles,
  setImages,
  images,
  selectedImageIndex,
  setSelectedImageIndex,
  initialCroppedAreaPixels,
  setInitialCroppedAreaPixels,
  croppedImages,
  setCroppedImages,
  isImageSelected,
  setIsImageSelected,
  cropForOneByOne,
  setCropForOneByOne,
  zoomForOneByOne,
  setZoomForOneByOne,
  cropForFourByFive,
  setCropForFourByFive,
  zoomForFourByFive,
  setZoomForFourByFive,
  typeOfCrop,
  setTypeOfCrop,
  croppedAreaPixelsForOneByOne,
  setCroppedAreaPixelsForOneByOne,
  croppedAreaPixelsForFourByFive,
  setCroppedAreaPixelsForFourByFive,
  initialCroppedAreaPixelsForOneByOne,
  setInitialCroppedAreaPixelsForOneByOne,
  initialCroppedAreaPixelsForFourByFive,
  setInitialCroppedAreaPixelsForFourByFive,
  croppedImageForOnyByOne,
  setCroppedImageForOnyByOne,
  croppedImageForFourByFive,
  setCroppedImageForFourByFive,
}) => {
  /* Temporary Data Holder */
  const [restaurant, setRestaurant] = useState([]);
  const [menu, setMenu] = useState([]);
  const [section, setSection] = useState([]);
  const [foodItem, setFoodItem] = useState([]);

  const [step, setStep] = useState(0); // Tracks the current step
  const [comments, setComment] = useState('');
  const [restaurantDatas, setRestaurantDatas] = useState([]);
  const [selectedRestaurantDatas, setSelectedRestaurantDatas] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);

  const [sectionDatas, setSectionDatas] = useState([]);
  const [selectedSectionDatas, setSelectedSectionDatas] = useState([]);
  const [selectedMenuDatas, setSelectedMenuDatas] = useState([]);

  const [foodItemDatas, setFoodItemDatas] = useState([]);
  const [selectFoodItemDatas, setSelectFoodItemDatas] = useState([]);

  const apiAddress = process.env.REACT_APP_SECRET;
  const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [notification, setNotification] = useState();

  useEffect(() => {
    toast.success(notification);
  }, [notification]);

  const [allPostCropImageTypes, setAllPostCropImageTypes] = useState([]);

  const [linkActive, setLinkActive] = useState(false);

  const [isAnimating, setIsAnimating] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  console.log('buttonDisabled____', buttonDisabled);
  console.log('buttonDisabled____isAnimating', isAnimating);

  // const [selectedImageIndex, setSelectedImageIndex] = useState('');

  // const [cropForOneByOne, setCropForOneByOne] = useState({ x: 0, y: 0 });
  // const [zoomForOneByOne, setZoomForOneByOne] = useState(1);

  // const [cropForFourByFive, setCropForFourByFive] = useState({ x: 0, y: 0 });
  // const [zoomForFourByFive, setZoomForFourByFive] = useState(1);

  // const [typeOfCrop, setTypeOfCrop] = useState('1:1');

  // const [croppedAreaPixelsForOneByOne, setCroppedAreaPixelsForOneByOne] =
  //   useState(null);
  // const [croppedAreaPixelsForFourByFive, setCroppedAreaPixelsForFourByFive] =
  //   useState(null);

  // const [
  //   initialCroppedAreaPixelsForOneByOne,
  //   setInitialCroppedAreaPixelsForOneByOne,
  // ] = useState(null);

  // const [
  //   initialCroppedAreaPixelsForFourByFive,
  //   setInitialCroppedAreaPixelsForFourByFive,
  // ] = useState(null);

  // const [croppedImageForOnyByOne, setCroppedImageForOnyByOne] = useState(null);
  // const [croppedImageForFourByFive, setCroppedImageForFourByFive] =
  //   useState(null);

  // const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState(
  //   Array(initialData.length).fill({ oneByOne: null, fourByFive: null })
  // );

  const fileInputRef = useRef(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const openModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalVisible &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.classList.contains('clicked')
      ) {
        setModalVisible(false);
      }
    }

    if (modalVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisible]);

  const loadRestaurantName = () => {
    var decodedData = {};
    if (gotasteClienttoken != null) {
      try {
        decodedData = jwtDecode(gotasteClienttoken);
      } catch (error) { }
    }

    axios
      .get(
        apiAddress +
        `/api/Restaurant/getrestaurantdetailsbyrestaurantparentidanduserid/${decodedData.UserId}/${restaurantId}`
      )
      .then((response) => {
        var list = [];
        response.data.map((dt, i) => {
          var obj = {
            id: dt.id,
            title: dt.name,
          };
          list.push(obj);
        });
        setRestaurantDatas(list);
      });
  };

  const fetchFoodItemByRestaurant = async () => {
    let restaurantId = [];

    if (selectedRestaurantDatas?.length > 0) {
      restaurantId = selectedRestaurantDatas.map((d) => d.id);
    }

    if (restaurantId.length > 0) {
      try {
        const response = await axios.post(
          `${apiAddress}/api/FoodItems/GetAllFoodItemByMultipleRestaurantId`,
          restaurantId
        );
        setFoodItemDatas(response.data);
        // setRestaurantDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (
      selectedRestaurantDatas.length > 0 &&
      selectedMenuDatas.length === 0 &&
      selectedSectionDatas.length === 0
    ) {
      fetchFoodItemByRestaurant();
    }
  }, [selectedRestaurantDatas]);

  const loadMenuData = async (props) => {
    var list = [];
    var Alllist = [];
    props.map(async (d, i) => {
      const response = await axios.get(apiAddress + '/api/Menu/' + d.id);
      console.log(d.id);
      response.data.map((dt, i) => {
        var obj = {
          id: dt.menuId,
          title: dt.menuName,
          img: dt.logoUrl,
          restaurantName: dt.restaurantName,
          restaurantId: dt.restaurantId,
        };
        list.push(obj);
        dt.MenuRestaurantMapperLists = [];
        Alllist.push(dt);
      });
      // Alllist = response.data;
      // handleFilterModal(1);
    });
    setAllMenuData(Alllist);
    setMenuData(list);
  };

  const loadSections = (props) => {
    if (props != undefined && props.length > 0) {
      var list = [];
      props.map(async (d, i) => {
        axios
          .get(
            apiAddress + '/api/Section/getallsectionbasedonmenuid?id=' + d.id
          )
          .then((response) => {
            response.data.map((dt, i) => {
              var obj = {
                code: dt.id,
                icon: dt.iconUrl,
                label: dt.keywordName,
                menuName: d.title,
                restaurantId: d.restaurantId,
                menuId: d.id,
                sectionId: dt.id,
              };
              list.push(obj);
            });
          });
      });
      setSectionDatas(list);
    } else {
      setSectionDatas([]);
    }
  };

  const loadFoodItems = (props) => {
    if (props !== undefined && props.length > 0) {
      let list = [];
      props.map(async (d, i) => {
        axios
          .get(
            apiAddress +
            '/api/Section/getallfooditembysectionid?sectionId=' +
            d.code +
            '&fromtype=1'
          )
          .then((response) => {
            response.data.map((dt, i) => {
              const obj = {
                id: dt.id,
                title: dt.title,
                img: dt.imageUrl,
                restaurantName: dt.restaurantName,
                menuName: dt.menu,
                sectionName: d.label,
                restaurantId: d.restaurantId,
                menuId: d.menuId,
              };
              list.push(obj);
            });
            // Update the state outside of the loop
            setFoodItemDatas(list);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  };

  useEffect(() => {
    loadRestaurantName();
  }, [restaurantId]);

  useEffect(() => {
    loadSections(selectedMenuDatas);
    if (menu.length > 0) {
      loadSections(menu);
    }
  }, [selectedMenuDatas, menu, selectedSectionDatas, section]);

  useEffect(() => {
    loadMenuData(selectedRestaurantDatas);
    if (restaurant.length > 0) {
      loadMenuData(restaurant);
    }
  }, [selectedRestaurantDatas, restaurant, selectedMenuDatas]);

  useEffect(() => {
    loadFoodItems(selectedSectionDatas);
    if (section.length > 0) {
      loadFoodItems(section);
    }
  }, [selectedSectionDatas, section, selectFoodItemDatas]);

  const handleFilterModal = (step) => {
    setShow(true);
    setStep((prevStep) => Math.max(prevStep, step));
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newEvents = [];

    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      newEvents.push(files[i]);

      const reader = new FileReader();
      reader.onloadend = () => {
        const id = `id${Date.now()}_${i}`;
        newImages.push({ id, url: reader.result, selected: false });

        if (newImages.length === files.length) {
          setImages([...images, ...newImages]);
          setInitialData([...images, ...newImages]);
          resetFileInput();
        }
      };
      reader.readAsDataURL(files[i]);
    }

    setUploadFiles((prevUploadFiles) => [...prevUploadFiles, ...newEvents]);
  };

  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(setCommentText(event.target.value));
    setComment(event.target.value);
  };

  //here
  async function uploadDocument(data) {
    try {
      const resUrl = await uploadFiles(data);
      return resUrl[0];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const fetchPostData = async (userId) => {
    if (userId && restaurantId) {
      await axios
        .get(
          apiAddress +
          `/api/Post/GetTopPostWithCommentReactByUserIdAndResaurantId?userId=${userId}&restaurantId=${restaurantId}&skip=0&take=12`
        )
        .then((response) => {
          dispatch(setPostData(response.data));
        })
        .catch((error) => {
          console.log('my error', error);
        });
    }
  };

  const getAllPostCropImageTypes = async () => {
    ;
    try {
      const response = await axios.get(`${apiAddress}/api/Post/GetAllPostCropImageTypes`);
      setAllPostCropImageTypes(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearAll = () => {
    setShow(false);
    onClick();
    setInitialData([]);
    // Reset initial data
    dispatch(setCommentText(null));
    setComment(null);

    dispatch(setCommentText(''));
    setComment('');

    setRestaurantDatas([]);
    setSelectedRestaurantDatas([]);
    setMenuData([]);
    setAllMenuData([]);
    setSectionDatas([]);
    setSelectedSectionDatas([]);
    setSelectedMenuDatas([]);
    setFoodItemDatas([]);
    setSelectFoodItemDatas([]);

    setEditImage(null);
    setEditImageUrl('');
    setImages([]);
    setUploadFiles([]);
    setCroppedImages([]);

    setDiscard(false); // Reset discard state after handling
  };

  const [originalImages, setOriginalImages] = useState([]);

  console.log('uploadFiles_____', uploadfiles);

  const handleSave = async () => {
    setLoading(true);
    var decodedData = {};
    if (gotasteClienttoken != null) {
      try {
        decodedData = jwtDecode(gotasteClienttoken);
      } catch (error) { }
    }

    const cropData = await getAllPostCropImageTypes();

    const postObj = {
      postInfoMaster: {
        id: 'N/A',
        title: 'N/A',
        description: comments,
        postContent: 'N/A',
        restaurantId: restaurantId,
        isEdited: false,
        link: 'N/A',
        url: 'N/A',
        privacy: 0,
        creationId: decodedData.UserId,
        ModificationId: decodedData.UserId,
      },
      post: {
        id: 'N/A',
        postInfoId: 'N/A',
        restaurantId: restaurantId,
        isShared: false,
        viewed: 0,
        isPinedPost: true,
        isPostInfoDeleted: true,
        pinOrderDisplay: 0,
        postedBy: 'N/A',
      },
    };

    postObj.postInfoImageMapperList = [];

    // const list = [];
    const imageList = [];
    const originalImagesTemp = [];
    if (uploadfiles.length > 0) {
      for (let i = 0; i < uploadfiles.length; i++) {
        const fileUrl = await uploadDocument(uploadfiles[i]);
        originalImagesTemp.push(fileUrl);
        // console.log('fileUrl', fileUrl);
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          imageUploadpath: fileUrl,
          creationId: decodedData.UserId,
          ImageTitle: 'N/A',
          CropTypeName: 'N/A',
          CropImageTypeId: '4314f388-816d-4ce0-b618-b7585b7a709e',
        };
        // list.push(obj);
      }
      setOriginalImages(originalImagesTemp);
      // postObj.postInfoImageMapperList = list;
    }

    postObj.postMenuTaggedMapperList = [];
    if (selectedMenuDatas.length > 0) {
      const list = [];
      selectedMenuDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.restaurantId,
          menuId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postMenuTaggedMapperList = list;
    }

    postObj.postRestaurantTaggedMapperList = [];
    if (selectedRestaurantDatas.length > 0) {
      const list = [];
      selectedRestaurantDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postRestaurantTaggedMapperList = list;
    }

    postObj.postSectionTaggedMapperList = [];
    if (selectedSectionDatas?.length > 0) {
      const list = [];
      selectedSectionDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.restaurantId,
          menuId: d.menuId,
          sectionId: d.sectionId,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postSectionTaggedMapperList = list;
    }

    postObj.postFoodItemTaggedMapperList = [];
    if (selectFoodItemDatas?.length > 0) {
      const list = [];
      selectFoodItemDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          foodItemId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postFoodItemTaggedMapperList = list;
    }

    // postObj.postImagesViewList = [];
    if (croppedImages?.length > 0 &&
      croppedImages.some(obj => Object.values(obj).some(value => value !== null))
    ) {
      // const list = [];
      for (let i = 0; i < originalImagesTemp.length; i++) {
        // Original Image Object
        // let originalImageObj = {
        //   postImageId: 'N/A',
        //   imagePath: originalImagesTemp[0],
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === 'original'
        //   )?.id,
        //   imageTitle: 'image for original',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };
        let originalImageObj = {
          id: 'N/A',
          imageUploadpath: originalImagesTemp[0],
          cropImageTypeId: cropData.find(
            (type) => type.cropType === 'original'
          )?.id,
          imageTitle: 'image for original',
          cropTypeName: cropData.find(
            (type) => type.cropType === 'original'
          )?.cropType,
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(originalImageObj);

        const oneByOneCroppedUrl = await uploadDocument(
          croppedImages[i].oneByOne
        );
        // OneByOne Cropped Image Object
        // let oneByOneCroppedObj = {
        //   postImageId: 'N/A',
        //   imagePath: oneByOneCroppedUrl,
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === '1:1'
        //   )?.id,
        //   imageTitle: 'image for oneByone',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };
        let oneByOneCroppedObj = {
          id: 'N/A',
          imageUploadpath: oneByOneCroppedUrl,
          cropImageTypeId: cropData.find(
            (type) => type.cropType === '1:1'
          )?.id,
          imageTitle: 'image for oneByone',
          cropTypeName: cropData.find(
            (type) => type.cropType === '1:1'
          )?.cropType,
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(oneByOneCroppedObj);

        const fourByFiveCroppedUrl = await uploadDocument(
          croppedImages[i].fourByFive
        );
        // FourByFive Cropped Image Object
        // let fourByFiveCroppedObj = {
        //   postImageId: 'N/A',
        //   imagePath: fourByFiveCroppedUrl,
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === '4:5'
        //   )?.id,
        //   imageTitle: 'image for fourByfive',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };
        let fourByFiveCroppedObj = {
          id: 'N/A',
          imageUploadpath: fourByFiveCroppedUrl,
          cropImageTypeId: cropData.find(
            (type) => type.cropType === '4:5'
          )?.id,
          imageTitle: 'image for fourByfive',
          cropTypeName: cropData.find(
            (type) => type.cropType === '4:5'
          )?.cropType,
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(fourByFiveCroppedObj);
      }
      postObj.postInfoImageMapperList = imageList;
    } else {
      for (let i = 0; i < originalImagesTemp.length; i++) {
        let originalImageObj = {
          id: 'N/A',
          imageUploadpath: originalImagesTemp[0],
          cropImageTypeId: cropData.find(
            (type) => type.cropType === 'original'
          )?.id,
          imageTitle: 'image for original',
          cropTypeName: cropData.find(
            (type) => type.cropType === 'original'
          )?.cropType,
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(originalImageObj);
      }
      postObj.postInfoImageMapperList = imageList;
    }

    axios
      .post(apiAddress + '/api/Post/InsertPost', postObj)
      .then((response) => {
        fetchPostData(decodedData.UserId);

        handleClearAll();
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log('Original___Images', loading);

  const handleCancelImage = (id, index) => {
    setImages((prevImages) => prevImages.filter((item) => item.id !== id));

    setCroppedImages((prevImages) =>
      prevImages.filter((item, idx) => idx !== index)
    );

    setInitialCroppedAreaPixels((prevInitialCroppedAreaPixels) =>
      prevInitialCroppedAreaPixels.filter((item, idx) => idx !== index)
    );

    setInitialData((prevData) => prevData.filter((item) => item.id !== id));
    setUploadFiles((prevUploadFiles) =>
      prevUploadFiles.filter((item) => item.id !== id)
    );
  };

  const isRestaurantDropdownVisible = step >= 0;
  const isMenuDropdownVisible = step >= 1;
  const isSectionDropdownVisible = step >= 2;
  const isFoodItemDropdownVisible = step >= 3;

  const handleRemoveFoodItem = (title) => {
    const updatedData = selectFoodItemDatas?.filter(
      (item) => item.title !== title
    );
    // if (updatedData.length === 0) {
    //   setSelectedRestaurantDatas([]);
    //   setSelectedMenuDatas([]);
    //   setSelectedSectionDatas([]);
    // }
    setSelectFoodItemDatas(updatedData);
  };

  const handleRemoveMenuItem = (id) => {
    const updatedData = selectedMenuDatas.filter((item) => item.id !== id);
    // if (updatedData.length === 0) {
    //   setSelectedRestaurantDatas([]);
    // }
    setSelectedMenuDatas(updatedData);
  };

  const handleRemoveSectionItem = (id) => {
    const updatedData = selectedSectionDatas.filter(
      (item) => item.sectionId !== id
    );
    // if (updatedData.length === 0) {
    //   setSelectedRestaurantDatas([]);
    //   setSelectedMenuDatas([]);
    // }
    setSelectedSectionDatas(updatedData);
  };

  const handleRemoveRestaurantItem = (id) => {
    const updatedData = selectedRestaurantDatas.filter(
      (item) => item.id !== id
    );
    setSelectedRestaurantDatas(updatedData);
  };

  // const [croppedImages, setCroppedImages] = useState(
  //   Array(initialData.length).fill({ oneByOne: null, fourByFive: null })
  // );

  const handleEditImage = (image, index) => {
    if (!croppedImages[index]) {
      setCropForOneByOne({ x: 0, y: 0 });
      setZoomForOneByOne(1);
      setCropForFourByFive({ x: 0, y: 0 });
      setZoomForFourByFive(1);
      setTypeOfCrop('1:1');
      setCroppedAreaPixelsForOneByOne(null);
      setCroppedAreaPixelsForFourByFive(null);
      setInitialCroppedAreaPixelsForOneByOne(null);
      setInitialCroppedAreaPixelsForFourByFive(null);
      setCroppedImageForOnyByOne(null);
      setCroppedImageForFourByFive(null);
    } else {
      setCroppedImageForOnyByOne(croppedImages[index].oneByOne);
      setCroppedImageForFourByFive(croppedImages[index].fourByFive);
    }

    setEditImageUrl(image.url);
    setShow(true);
    setEditImage(true);
    setSelectedImageIndex(index);
  };

  const updateInitialCroppedAreaPixels = (
    croppedAreaPixelsOneByOne,
    croppedAreaPixelsFourByFive,
    index
  ) => {
    setInitialCroppedAreaPixels((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        oneByOne: croppedAreaPixelsOneByOne || prevState[index]?.oneByOne,
        fourByFive: croppedAreaPixelsFourByFive || prevState[index]?.fourByFive,
      };
      return newState;
    });
  };

  // Function to store initial cropped area pixels
  const storeInitialCroppedAreaPixels = (croppedAreaPixels) => {
    if (typeOfCrop === '1:1') {
      setInitialCroppedAreaPixelsForOneByOne(croppedAreaPixels);
    } else {
      setInitialCroppedAreaPixelsForFourByFive(croppedAreaPixels);
    }
    updateInitialCroppedAreaPixels(
      typeOfCrop === '1:1' ? croppedAreaPixels : null,
      typeOfCrop === '4:5' ? croppedAreaPixels : null,
      selectedImageIndex
    );
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    if (typeOfCrop === '1:1') {
      setCroppedAreaPixelsForOneByOne(croppedAreaPixels);
      if (!initialCroppedAreaPixelsForOneByOne) {
        storeInitialCroppedAreaPixels(croppedAreaPixels);
      }
    } else {
      setCroppedAreaPixelsForFourByFive(croppedAreaPixels);
      if (!initialCroppedAreaPixelsForFourByFive) {
        storeInitialCroppedAreaPixels(croppedAreaPixels);
      }
    }
  };

  const resetCroppedAreaPixels = () => {
    if (typeOfCrop === '1:1') {
      const initialPixels =
        initialCroppedAreaPixels[selectedImageIndex]?.oneByOne || null;
      setCroppedAreaPixelsForOneByOne(initialPixels);

      setCroppedImageForOnyByOne(null);

      setCroppedImages((prevState) => {
        const newState = [...prevState];
        newState[selectedImageIndex] = {
          ...newState[selectedImageIndex],
          oneByOne: null,
        };
        return newState;
      });
    } else {
      const initialPixels =
        initialCroppedAreaPixels[selectedImageIndex]?.fourByFive || null;
      setCroppedAreaPixelsForFourByFive(initialPixels);

      setCroppedImageForFourByFive(null);

      setCroppedImages((prevState) => {
        const newState = [...prevState];
        newState[selectedImageIndex] = {
          ...newState[selectedImageIndex],
          fourByFive: null,
        };
        return newState;
      });
    }
  };

  const handleCropSelection = (ratio) => {
    setTypeOfCrop(ratio);
  };

  const updateCroppedImages = (
    croppedImageOneByOne,
    croppedImageFourByFive,
    index
  ) => {
    setCroppedImages((prevState) => {
      const newState = [...prevState];
      const prevCroppedImage = newState[index];

      // Preserve the existing value if cropping for the other aspect ratio
      const updatedState = {
        oneByOne: croppedImageOneByOne || prevCroppedImage?.oneByOne,
        fourByFive: croppedImageFourByFive || prevCroppedImage?.fourByFive,
      };

      newState[index] = updatedState;
      return newState;
    });
  };

  const showCroppedImage = useCallback(async () => {
    setSaveLoading(true);
    try {
      if (typeOfCrop === '1:1') {
        const croppedImage = await getCroppedImg(
          editImageUrl,
          croppedAreaPixelsForOneByOne
        );
        setCroppedImageForOnyByOne(croppedImage);
        updateCroppedImages(croppedImage, null, selectedImageIndex);
        setIsImageSelected(false);
      } else {
        const croppedImage = await getCroppedImg(
          editImageUrl,
          croppedAreaPixelsForFourByFive
        );
        setCroppedImageForFourByFive(croppedImage);
        updateCroppedImages(null, croppedImage, selectedImageIndex);
        setIsImageSelected(false);
      }
      setSaveLoading(false);
    } catch (e) {
      setSaveLoading(false);
      console.error(e);
    }
  }, [croppedAreaPixelsForOneByOne, croppedAreaPixelsForFourByFive, editImageUrl]);

  // const onClose = useCallback(() => {
  //   setCroppedImage(null);
  // }, []);

  useEffect(() => {
    if (discard) {
      handleClearAll();
    }
  }, [discard]);

  const handleCloseAll = () => {
    handleOpen();
  };

  const handleRemoveData = (restaurantData) => {
    //Remove Restaurant
    const updatedRestaurants = restaurant?.filter(
      (r) => r.id !== restaurantData.id
    );
    setRestaurant(updatedRestaurants);

    // Remove associated menus
    const updatedMenus = menu.filter(
      (menu) => menu.restaurantId !== restaurantData.id
    );
    setMenu(updatedMenus);

    //Remove associated section
    const updatedSections = section.filter(
      (section) => section.restaurantId !== restaurantData.id
    );
    setSection(updatedSections);

    //Remove associated fooditem
    const updatedFoods = foodItem.filter(
      (food) => food.restaurantId !== restaurantData.id
    );
    setFoodItem(updatedFoods);
  };

  console.log('cropped____images', croppedImages);

  /* Checking for duplicate tagged item; */
  function checkforduplication(existingItems, newItem) {
    return existingItems.some((item) =>
      newItem.some((newValue) => newValue.id === item.id)
    );
  }

  /*Remove Duplication and add unique id */
  function removeDuplication(existingArray, newValues, setter, key) {
    //addition filtering for section, because it does not contain id
    if (key === 'section') {
      const result = newValues?.filter(
        (item) => !existingArray.some((data) => data.code === item.code)
      );
      setter((prevState) => [...prevState, ...result]);
    }

    const result = newValues?.filter(
      (item) => !existingArray.some((data) => data.id === item.id)
    );

    setter((prevState) => [...prevState, ...result]);
  }

  /* update selected data for saving to the database */
  function selectData() {
    if (foodItem.length > 0) {
      if (!checkforduplication(selectFoodItemDatas, foodItem)) {
        setSelectFoodItemDatas((prevState) => [...prevState, ...foodItem]);
      } else {
        removeDuplication(
          selectFoodItemDatas,
          foodItem,
          setSelectFoodItemDatas,
          'food'
        );
      }
    } else if (section.length > 0) {
      if (!checkforduplication(selectedSectionDatas, section)) {
        setSelectedSectionDatas((prevState) => [...prevState, ...section]);
      } else {
        removeDuplication(
          selectedSectionDatas,
          section,
          setSelectedSectionDatas,
          'section'
        );
      }
    } else if (menu.length > 0) {
      if (!checkforduplication(selectedMenuDatas, menu)) {
        setSelectedMenuDatas((prevState) => [...prevState, ...menu]);
      } else {
        removeDuplication(
          selectedMenuDatas,
          menu,
          setSelectedMenuDatas,
          'menu'
        );
      }
    } else if (restaurant.length > 0) {
      if (!checkforduplication(selectedRestaurantDatas, restaurant)) {
        setSelectedRestaurantDatas((prevState) => [
          ...prevState,
          ...restaurant,
        ]);
      } else {
        removeDuplication(
          selectedRestaurantDatas,
          restaurant,
          setSelectedRestaurantDatas,
          'restaurant'
        );
      }
    }

    // Reset value of temporary placeholer
    setRestaurant([]);
    setMenu([]);
    setSection([]);
    setFoodItem([]);
  }

  /* Set Notification and SetData */
  const handleLink = (parameters) => {
    selectData();
    setLinkActive(true); //showing tagged item on the post modal

    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 150);

    switch (parameters) {
      case 'restaurant':
        setNotification('Restaurant Linked');
        break;
      case 'menu':
        setNotification('Menu Linked');
        break;
      case 'section':
        setNotification('Section Linked');
        break;
      case 'fooditem':
        setNotification('FoodItem Linked');
        break;
      default:
        setNotification('');
        break;
    }

    setTimeout(() => {
      setShow(false);
    }, 450);
  };

  // Resetting Notification for button text of linking
  useEffect(() => {
    setNotification();
  }, [restaurant, section, menu, foodItem]);

  //   useEffect(() => {
  //   loadRestaurantName();
  // }, [restaurantId]);

  // useEffect(() => {
  //   loadSections(selectedMenuDatas);
  // }, [selectedMenuDatas]);

  // useEffect(() => {
  //   loadMenuData(selectedRestaurantDatas);
  // }, [selectedRestaurantDatas]);

  // useEffect(() => {
  //   loadFoodItems(selectedSectionDatas);
  // }, [selectedSectionDatas]);

  /* Filtering Auto Complete Options */
  useEffect(() => {
    if (menuData.length > 0) {
      const filteredData = menuData.filter(
        (option) =>
          !selectedMenuDatas.some((selected) => option.id === selected.id)
      );

      if (selectedMenuDatas.length > 0) {
        setMenuData(filteredData);
      }
    }
  }, [selectedMenuDatas, restaurant]);

  /* filtering Section options */
  useEffect(() => {
    if (sectionDatas.length > 0) {
      const filteredData = sectionDatas.filter(
        (option) =>
          !selectedSectionDatas.some(
            (selected) => option.code === selected.code
          )
      );
      setSectionDatas(filteredData);
    }
  }, [selectedSectionDatas, menu, restaurant]);

  /* filtering fooditem options */
  useEffect(() => {
    if (foodItemDatas.length > 0) {
      const filteredData = foodItemDatas.filter(
        (option) =>
          !selectFoodItemDatas.some((selected) => option.id === selected.id)
      );
      setFoodItemDatas(filteredData);
    }
  }, [selectFoodItemDatas, section, restaurant, menu, foodItemDatas, foodItem]);

  return (
    <div className='post-modal'>
      <ToastContainer />
      {
        loading || saveLoading
        &&
        <div className="loading-image-container">
          <img src={loadingImage} alt="loading image" />
        </div>
      }
      <div className='modal-header d-flex justify-content-between loginModalCloseBtn'>
        {!show ? (
          <>
            <span></span>
            <span className='createPostModal me-4'>Create post</span>
            <button type='button' className='close' onClick={handleCloseAll}>
              <span aria-hidden='true'>
                <img
                  src={removeIcon}
                  style={{ height: '30px', marginRight: '-10px' }}
                  alt=''
                />
              </span>
            </button>
          </>
        ) : editImage ? (
          <>
            <button
              type='button'
              className='close'
              onClick={() => {
                setShow(false);
                setEditImage(false);
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={backButton}
                  style={{ height: '30px', marginLeft: '-10px' }}
                  alt=''
                />
              </span>
            </button>
            <span className='cropText'>Crop</span>

            <div
              type='button'
              className='close'
            // onClick={() => {
            //   setShow(false);
            //   onClick();
            // }}
            >
              <button
                aria-hidden='true'
                style={{
                  color: '#371b17',
                  fontWeight: '600',
                  border: "none",
                  cursor:
                    (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                      (typeOfCrop === '4:5' && croppedImageForFourByFive) ||
                      saveLoading
                      ? 'not-allowed'
                      : 'pointer',
                  opacity:
                    (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                      (typeOfCrop === '4:5' && croppedImageForFourByFive) ||
                      saveLoading
                      ? 0.5
                      : 1,
                }}
                onClick={
                  (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                    (typeOfCrop === '4:5' && croppedImageForFourByFive)
                    ? null
                    : showCroppedImage
                }
                disabled={saveLoading}
              >
                Save
              </button>

              <span style={{ color: 'gray', cursor: 'default' }}> | </span>
              <span
                aria-hidden='true'
                style={{ color: '#371b17', fontWeight: '600' }}
                onClick={resetCroppedAreaPixels}
              >
                Reset
              </span>
            </div>
          </>
        ) : (
          <>
            <button
              type='button'
              className='close'
              onClick={() => {
                setShow(false);
                setStep(0);
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={backButton}
                  style={{ height: '30px', marginLeft: '-10px' }}
                  alt=''
                />
              </span>
            </button>
            <span className='selectRestaurantModal'>
              {step === 0 && 'Search for restaurants'}
              {step === 1 && 'Select Menu'}
              {step === 2 && 'Select Section'}
              {step === 3 && 'Select Food Item'}
            </span>
            <button
              type='button'
              className='close'
              onClick={() => {
                setShow(false);
                onClick();
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={removeIcon}
                  style={{ height: '30px', marginRight: '-10px' }}
                  alt=''
                />
              </span>
            </button>
          </>
        )}
      </div>

      <div className='post-modal'>
        {!show ? (
          <div className='d-flex flex-column pt-2'>
            <div
              className='row-gallery-postview'
              style={{ width: '100%', height: '15rem', overflow: 'auto' }}
            >
              <div className='d-flex shareYourThoughtspopUpImg'>
                <img src={logo} alt='' style={{ width: '43px' }} />
                <h6 className='pt-2 mt-1'>
                  <b>{restaurantName}</b>
                </h6>
              </div>
              {/* Tagged item Showing jsx */}

              {linkActive &&
                // selectedRestaurantDatas.length > 0 &&
                // selectedMenuDatas.length === 0 &&
                // selectedSectionDatas.length === 0 &&
                // selectFoodItemDatas.length === 0 &&
                selectedRestaurantDatas.map((item) => (
                  <div key={item.id} className='post-button-container'>
                    <p className='pill-button'>
                      {' '}
                      {item.title}{' '}
                      <span
                        className='cross-icon'
                        onClick={() => handleRemoveRestaurantItem(item.id)}
                      >
                        ×
                      </span>
                    </p>
                  </div>
                ))}
              {linkActive &&
                // selectedMenuDatas.length > 0 &&
                // selectedSectionDatas.length === 0 &&
                // selectFoodItemDatas.length === 0 &&
                selectedMenuDatas.map((item) => (
                  <div key={item.id} className='post-button-container'>
                    <p className='pill-button'>
                      {item.title}{' '}
                      <span
                        className='cross-icon'
                        onClick={() => handleRemoveMenuItem(item.id)}
                      >
                        ×
                      </span>
                    </p>
                  </div>
                ))}
              {linkActive &&
                // selectedSectionDatas.length > 0 &&
                // selectFoodItemDatas.length === 0 &&
                selectedSectionDatas.map((item) => (
                  <div key={item.id} className='post-button-container'>
                    <p className='pill-button'>
                      {item.label}{' '}
                      <span
                        className='cross-icon'
                        onClick={() => handleRemoveSectionItem(item.sectionId)}
                      >
                        ×
                      </span>
                    </p>
                  </div>
                ))}
              {linkActive &&
                // selectFoodItemDatas.length > 0 &&
                selectFoodItemDatas.map((item) => (
                  <div key={item.id} className='post-button-container'>
                    <p className='pill-button'>
                      {item.title}{' '}
                      <span
                        className='cross-icon'
                        onClick={() => handleRemoveFoodItem(item.title)}
                      >
                        ×
                      </span>
                    </p>
                  </div>
                ))}

              {/* <div class='post-button-container'>
                <p class='pill-button'>Link FoodItem</p>
              </div> */}
              <textarea
                style={{
                  width: '100%',
                  border: 'none',
                  fontSize: '1.5rem',
                  resize: 'none', // Disable textarea resize
                }}
                value={comments}
                className='form-control whatsonyourMindtextArea'
                placeholder='What’s on your mind?'
                onChange={handleChange}
              ></textarea>
              {initialData.map((imageUrl, index) => (
                <div
                  key={index}
                  style={{
                    margin: '10px 0',
                    position: 'relative',
                  }}
                >
                  <button
                    type='button'
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '5px',
                      zIndex: '1',
                      border: 'none',
                      background: `${croppedImages[index]?.oneByOne ||
                        croppedImages[index]?.fourByFive
                        ? 'lightgray'
                        : 'white'
                        }`,
                      padding: '5px 10px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => handleEditImage(imageUrl, index)}
                  >
                    <span aria-hidden='true'>
                      <img
                        src={editIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginRight: '5px',
                        }}
                        alt=''
                      />
                    </span>
                    {croppedImages[index]?.oneByOne && croppedImages[index].fourByFive
                      ? 'Edited (1:1, 4:5)' 
                      : croppedImages[index]?.oneByOne
                      ? 'Edited (1:1)'
                      : croppedImages[index]?.fourByFive
                      ? 'Edited (4:5)'
                      : 'Edit'}
                  </button>
                  {/* Cross button */}
                  <button
                    type='button'
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      zIndex: '1',
                      border: 'none',
                      background: 'border-box',
                    }}
                    onClick={() => handleCancelImage(imageUrl.id, index)}
                  >
                    <span aria-hidden='true'>
                      <img
                        src={removeIcon}
                        style={{ height: '30px', opacity: '0.5' }}
                        alt=''
                      />
                    </span>
                  </button>
                  {/* Image */}

                  {croppedImages[index]?.oneByOne ? (
                    <img
                      src={croppedImages[index].oneByOne}
                      alt='original'
                      className='cropped-image'
                      style={{
                        width: '98%',
                        height: '40%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  ) : croppedImages[index]?.oneByOne &&
                    croppedImages[index]?.fourByFive ? (
                    <img
                      src={croppedImages[index].oneByOne}
                      alt='original'
                      className='cropped-image'
                      style={{
                        width: '98%',
                        height: '40%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  ) : croppedImages[index]?.fourByFive &&
                    !croppedImages[index]?.oneByOne ? (
                    <img
                      src={croppedImages[index].fourByFive}
                      alt='original'
                      className='cropped-image'
                      style={{
                        width: '98%',
                        height: '40%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  ) : (
                    <img
                      src={imageUrl.url}
                      alt='initial'
                      className='cropped-image'
                      style={{
                        width: '98%',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-between addToYourPost'>
              <h6 className='pt-1'>Link items to post</h6>
              <div className='d-flex justify-content-between'>
                <label htmlFor='imageUpload'>
                  <img
                    src={imageGallery2}
                    alt=''
                    style={{ width: '1.5rem', cursor: 'pointer' }}
                  />
                </label>
                <input
                  type='file'
                  ref={fileInputRef}
                  id='imageUpload'
                  accept='image/*'
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  multiple
                />
                <div className='px-2' onClick={() => handleFilterModal(0)}>
                  <img
                    src={restaurantColorful}
                    alt=''
                    style={{ width: '1.5rem' }}
                  />
                </div>
                <div className='px-2' onClick={() => handleFilterModal(1)}>
                  <img src={menuColorful} alt='' style={{ width: '1.5rem' }} />
                </div>
                <div className='px-2' onClick={() => handleFilterModal(2)}>
                  <img
                    src={sectionColorful}
                    alt=''
                    style={{ width: '1.5rem' }}
                  />
                </div>
                <div className='px-2' onClick={() => handleFilterModal(3)}>
                  <img src={foodColorful} alt='' style={{ width: '1.5rem' }} />
                </div>
                <div>
                  <img src={dot} alt='' style={{ width: '1.5rem' }} />
                </div>
              </div>
            </div>

            <button
              className='btn-custom mt-3'
              // onClick={() => {
              //   handleSave();
              // }}
              disabled={(!comments && !uploadfiles.length) || loading}
              onClick={handleSave}
            >
              {loading ? 'Loading...' : 'Post'}
            </button>
          </div>
        ) : !editImage ? (
          <div>
            {isRestaurantDropdownVisible && (
              <Autocomplete
                multiple
                id='restaurant-dropdown'
                options={restaurantDatas}
                defaultValue={selectedRestaurantDatas}
                // value={combineRestaurant}
                onChange={(event, newInputValue, reason, detail) => {
                  setButtonDisabled(false);
                  setRestaurant(newInputValue);
                  // holdAutoCompleteData(newInputValue, 'restaurant');
                  // setSelectedRestaurantDatas(newInputValue);
                  if (reason === 'removeOption') {
                    handleRemoveData(detail.option);
                  }
                }}
                className='my-3'
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Select Restaurant'
                  />
                )}
              />
            )}
            {isMenuDropdownVisible && (
              <Autocomplete
                multiple
                id='tags-outlined'
                options={menuData}
                defaultValue={selectedMenuDatas}
                // value={combineMenu}
                onChange={(event, newInputValue) => {
                  setButtonDisabled(false);
                  setMenu(newInputValue);
                  // holdAutoCompleteData(newInputValue, 'menu');
                  // setSelectedMenuDatas(newInputValue);
                  handleFilterModal(2);
                }}
                className='my-3'
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Menu'
                    placeholder='Select Menu'
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading='lazy'
                      src={option.img}
                      alt=''
                      style={{ width: '2rem' }}
                    />
                    <div
                      className='d-flex '
                      style={{ flexDirection: 'column' }}
                    >
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <h5 className='mb-0'>{option.title}</h5>
                      </div>

                      <span
                        className='text-muted'
                        style={{ fontSize: '1rem', marginTop: '0' }}
                      >
                        {' '}
                        {option.restaurantName}
                      </span>
                    </div>
                  </Box>
                )}
              />
            )}
            {isSectionDropdownVisible && (
              <Autocomplete
                multiple
                id='restaurant-dropdown'
                options={sectionDatas}
                defaultValue={selectedSectionDatas}
                // value={combineSection}
                onChange={(event, newInputValue) => {
                  setButtonDisabled(false);
                  setSection(newInputValue);
                  // setSelectedSectionDatas(newInputValue);
                  handleFilterModal(3);
                }}
                className='my-3'
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Select Section'
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading='lazy'
                      src={option.icon}
                      alt=''
                      style={{ width: '2rem' }}
                    />
                    <div
                      className='d-flex '
                      style={{ flexDirection: 'column' }}
                    >
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <h5 className='mb-0'>{option.label}</h5>
                      </div>

                      <span
                        className='text-muted'
                        style={{ fontSize: '1rem', marginTop: '0' }}
                      >
                        {' '}
                        {option.menuName}
                      </span>
                    </div>
                  </Box>
                )}
              />
            )}
            {isFoodItemDropdownVisible && (
              <Autocomplete
                multiple
                id='restaurant-dropdown'
                options={foodItemDatas}
                defaultValue={selectFoodItemDatas}
                className='my-3'
                onChange={(event, newInputValue) => {
                  setButtonDisabled(false);
                  setFoodItem(newInputValue);
                  // holdAutoCompleteData(newInputValue, 'fooditem');
                  // setSelectFoodItemDatas(newInputValue);
                  handleFilterModal(4);
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Select FoodItem'
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {/* <img
                      loading="lazy"
                      src={option.img}
                      alt=""
                      style={{ width: "2rem" }}
                    /> */}
                    <div
                      className='d-flex '
                      style={{ flexDirection: 'column' }}
                    >
                      <div className='d-flex' style={{ gap: '10px' }}>
                        <h5 className='mb-0'>{option.title}</h5>
                      </div>

                      <span
                        className='text-muted'
                        style={{ fontSize: '1rem', marginTop: '0' }}
                      >
                        {' '}
                        {option.restaurantName}{' '}
                        {option.menuName && <span>({option.menuName})</span>}
                        {option.sectionName && (
                          <span>({option.sectionName})</span>
                        )}
                      </span>
                    </div>
                  </Box>
                )}
              />
            )}

            {/* Default View for Link Restaurant Button */}
            {restaurant?.length === 0 &&
              menu?.length === 0 &&
              section?.length === 0 &&
              foodItem?.length === 0 && (
                <div class='button-container'>
                  <button
                    class='pill-button'
                    disabled={true}
                    style={{
                      backgroundColor: 'lightgray',
                      color: 'black',
                      cursor: 'not-allowed',
                      borderRadius: '20px',
                      border: linkActive ? 'none' : '1px solid black',
                    }}
                  >
                    Link Restaurant
                  </button>
                </div>
              )}

            {restaurant?.length > 0 &&
              menu?.length === 0 &&
              section?.length === 0 &&
              foodItem?.length === 0 && (
                <div className='button-container'>
                  <button
                    className={`pill-button ${isAnimating ? 'animate' : ''}`}
                    style={{
                      backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
                      color: buttonDisabled ? 'black' : 'white',
                      cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                      borderRadius: '20px',
                      border: linkActive ? 'none' : '1px solid black',
                    }}
                    onClick={() => handleLink('restaurant')}
                    disabled={buttonDisabled}
                  >
                    {notification === 'Restaurant Linked'
                      ? notification
                      : 'Link Restaurant'}
                  </button>
                </div>
              )}
            {menu?.length > 0 &&
              section?.length === 0 &&
              foodItem?.length === 0 && (
                <div className='button-container'>
                  <button
                    className={`pill-button ${isAnimating ? 'animate' : ''}`}
                    style={{
                      backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
                      color: buttonDisabled ? 'black' : 'white',
                      cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                      borderRadius: '20px',
                      border: linkActive ? 'none' : '1px solid black',
                    }}
                    onClick={() => handleLink('menu')}
                    disabled={buttonDisabled}
                  >
                    {notification === 'Menu Linked'
                      ? notification
                      : 'Link Menu'}
                  </button>
                </div>
              )}
            {section?.length > 0 && foodItem?.length === 0 && (
              <div className='button-container'>
                <button
                  className={`pill-button ${isAnimating ? 'animate' : ''}`}
                  style={{
                    backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
                    color: buttonDisabled ? 'black' : 'white',
                    cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                    borderRadius: '20px',
                    border: linkActive ? 'none' : '1px solid black',
                  }}
                  onClick={() => handleLink('section')}
                  disabled={buttonDisabled}
                >
                  {notification === 'Section Linked'
                    ? notification
                    : 'Link Section'}
                </button>
              </div>
            )}
            {foodItem?.length > 0 && (
              <div className='button-container'>
                <button
                  className={`pill-button ${isAnimating ? 'animate' : ''}`}
                  style={{
                    backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
                    color: buttonDisabled ? 'black' : 'white',
                    cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                    borderRadius: '20px',
                    border: linkActive ? 'none' : '1px solid black',
                  }}
                  onClick={() => handleLink('fooditem')}
                  disabled={buttonDisabled}
                >
                  {notification === 'FoodItem Linked'
                    ? notification
                    : 'Link FoodItem'}
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className='image-crop-container'>
            {/* <div className='crop-container'>
              {typeOfCrop === '1:1' ? (
                croppedImageForOnyByOne ? (
                  <div className='original-image-container'>
                    <img
                      src={croppedImageForOnyByOne}
                      alt='original'
                      className='full-image'
                    />
                  </div>
                ) : (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForOneByOne}
                    zoom={zoomForOneByOne}
                    aspect={1 / 1}
                    onCropChange={setCropForOneByOne}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForOneByOne}
                  />
                )
              ) : typeOfCrop === '4:5' ? (
                croppedImageForFourByFive ? (
                  <div className='original-image-container'>
                    <img
                      src={croppedImageForFourByFive}
                      alt='original'
                      className='full-image'
                    />
                  </div>
                ) : (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForFourByFive}
                    zoom={zoomForFourByFive}
                    aspect={4 / 5}
                    onCropChange={setCropForFourByFive}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForFourByFive}
                  />
                )
              ) : (
                <div className='original-image-container'>
                  <img
                    src={editImageUrl}
                    alt='original'
                    className='full-image'
                  />
                </div>

                // <Cropper
                //   image={editImageUrl}
                //   crop={{ x: 0, y: 0 }}
                //   zoom={1}
                //   aspect={undefined}
                //   // onCropChange={undefined}
                //   // onZoomChange={0}
                // />
              )}
            </div> */}

            <div className='crop-container'>
              {typeOfCrop === '1:1' ? (
                croppedImageForOnyByOne && isImageSelected ? (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForOneByOne}
                    zoom={zoomForOneByOne}
                    aspect={1 / 1}
                    onCropChange={setCropForOneByOne}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForOneByOne}
                  />
                ) : croppedImageForOnyByOne ? (
                  <div className='original-image-container'>
                    <img
                      src={croppedImageForOnyByOne}
                      alt='original'
                      className='full-image'
                    />
                  </div>
                ) : (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForOneByOne}
                    zoom={zoomForOneByOne}
                    aspect={1 / 1}
                    onCropChange={setCropForOneByOne}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForOneByOne}
                  />
                )
              ) : typeOfCrop === '4:5' ? (
                croppedImageForFourByFive && isImageSelected ? (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForFourByFive}
                    zoom={zoomForFourByFive}
                    aspect={4 / 5}
                    onCropChange={setCropForFourByFive}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForFourByFive}
                  />
                ) : croppedImageForFourByFive ? (
                  <div className='original-image-container'>
                    <img
                      src={croppedImageForFourByFive}
                      alt='original'
                      className='full-image'
                    />
                  </div>
                ) : (
                  <Cropper
                    image={editImageUrl}
                    crop={cropForFourByFive}
                    zoom={zoomForFourByFive}
                    aspect={4 / 5}
                    onCropChange={setCropForFourByFive}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoomForFourByFive}
                  />
                )
              ) : (
                <div className='original-image-container'>
                  <img
                    src={editImageUrl}
                    alt='original'
                    className='full-image'
                  />
                </div>

                // <Cropper
                //   image={editImageUrl}
                //   crop={{ x: 0, y: 0 }}
                //   zoom={1}
                //   aspect={undefined}
                //   // onCropChange={undefined}
                //   // onZoomChange={0}
                // />
              )}
            </div>
            <div className='all-controls'>
              <div className='controls'>
                {typeOfCrop === '1:1' && (
                  <input
                    type='range'
                    value={zoomForOneByOne}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby='Zoom'
                    onChange={(e) => {
                      setZoomForOneByOne(e.target.value);
                    }}
                    className='zoom-range'
                  />
                )}
                {typeOfCrop === '4:5' && (
                  <input
                    type='range'
                    value={zoomForFourByFive}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby='Zoom'
                    onChange={(e) => {
                      setZoomForFourByFive(e.target.value);
                    }}
                    className='zoom-range'
                  />
                )}
              </div>
              {modalVisible && (
                <div ref={modalRef} className='crop-modal'>
                  <div
                    onClick={() => handleCropSelection('original')}
                    className={typeOfCrop === 'original' ? 'selected' : ''}
                  >
                    <span>Original</span>
                    <span>
                      <svg
                        aria-label='Photo outline icon'
                        class='x1lliihq x1n2onr6 x1roi4f4'
                        fill='currentColor'
                        height='24'
                        role='img'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <path
                          d='M6.549 5.013A1.557 1.557 0 1 0 8.106 6.57a1.557 1.557 0 0 0-1.557-1.557Z'
                          fill-rule='evenodd'
                        ></path>
                        <path
                          d='m2 18.605 3.901-3.9a.908.908 0 0 1 1.284 0l2.807 2.806a.908.908 0 0 0 1.283 0l5.534-5.534a.908.908 0 0 1 1.283 0l3.905 3.905'
                          fill='none'
                          stroke='currentColor'
                          stroke-linejoin='round'
                          stroke-width='2'
                        ></path>
                        <path
                          d='M18.44 2.004A3.56 3.56 0 0 1 22 5.564h0v12.873a3.56 3.56 0 0 1-3.56 3.56H5.568a3.56 3.56 0 0 1-3.56-3.56V5.563a3.56 3.56 0 0 1 3.56-3.56Z'
                          fill='none'
                          stroke='currentColor'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <hr />
                  <div
                    onClick={() => handleCropSelection('1:1')}
                    className={typeOfCrop === '1:1' ? 'selected' : ''}
                  >
                    <span
                      style={{
                        color: `${croppedImageForOnyByOne ? '#3572EF' : 'white'
                          }`,
                      }}
                    >
                      1:1
                    </span>
                    <span>
                      <svg
                        aria-label='Crop square icon'
                        class='x1lliihq x1n2onr6 x1roi4f4'
                        fill={`${croppedImageForOnyByOne ? '#3572EF' : 'currentColor'
                          }`}
                        height='24'
                        role='img'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <path d='M19 23H5a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h14a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM5 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h14a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z'></path>
                      </svg>
                    </span>
                  </div>
                  <hr />
                  <div
                    onClick={() => handleCropSelection('4:5')}
                    className={typeOfCrop === '4:5' ? 'selected' : ''}
                  >
                    <span
                      style={{
                        color: `${croppedImageForFourByFive ? '#3572EF' : 'white'
                          }`,
                      }}
                    >
                      4:5
                    </span>
                    <span>
                      <svg
                        aria-label='Crop portrait icon'
                        class='x1lliihq x1n2onr6 x9bdzbf'
                        fill={`${croppedImageForFourByFive ? '#3572EF' : 'currentColor'
                          }`}
                        height='24'
                        role='img'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <path d='M16 23H8a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h8a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM8 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h8a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z'></path>
                      </svg>
                    </span>
                  </div>
                </div>
              )}
              <div className='crop-button clicked' onClick={openModal}>
                <svg
                  aria-label='Select crop'
                  class='x1lliihq x1n2onr6 x9bdzbf'
                  fill='currentColor'
                  height='20'
                  role='img'
                  viewBox='0 0 24 24'
                  width='22'
                  className='clicked'
                >
                  <path d='M10 20H4v-6a1 1 0 0 0-2 0v7a1 1 0 0 0 1 1h7a1 1 0 0 0 0-2ZM20.999 2H14a1 1 0 0 0 0 2h5.999v6a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1Z'></path>
                </svg>
              </div>
            </div>
          </div>
        )}

        {/* <p style={{textAlign: "right", marginRight: "1em"}}>here</p> */}
      </div>
    </div>
  );
};

export default ShareYourthoughts;
