import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const AddressUpdateModal = ({
  showModal,
  handleClose,
  addressData,
  handleUpdate,
}) => {
  const [updatedAddress, setUpdatedAddress] = useState('');
  const [updatedTag, setUpdatedTag] = useState('');
  const [updatedNotes, setUpdatedNotes] = useState('');

  const [error, setError] = useState('');
  const [TagError, setTagError] = useState('');

  console.log('helllo');

  useEffect(() => {
    // Populate the form with the existing address data
    if (addressData) {
      setUpdatedAddress(addressData.address || ''); // Change 'address' to the actual field in your data
      setUpdatedTag(addressData.tag || '');
      var notes= addressData.noteForDeliveryMan === 'N/A' ? "" : addressData.noteForDeliveryMan
      setUpdatedNotes(notes || '');
    }
  }, [addressData]);

  const handleAddressUpdate = async () => {
    try {
      if (!updatedAddress.trim()) {
        setError('Please enter a valid address.');
        return;
      }

      // Clear previous error message
      setError('');
      setTagError('');
      // Create an updated address object with the required fields
      const updatedAddressObject = {
        id: addressData.id,
        address: updatedAddress,
        userId: addressData.userId,
        isDefault: addressData.isDefault,
        isLastDeliveryAddress: addressData.isLastDeliveryAddress,
        creationDate: addressData.creationDate,
        tag: updatedTag,
        noteForDeliveryMan: updatedNotes === "" ? 'N/A' : updatedNotes
      };

      // Make a PUT request to update the existing address
      const response = await axios.put(
        `${process.env.REACT_APP_SECRET}/api/DeliveryAddress`,
        updatedAddressObject
      );

      // Close the modal after successfully updating the address
      handleClose();

      // Handle the updated address data in the parent component
      handleUpdate(updatedAddressObject);
      setUpdatedAddress('');
      setUpdatedTag('');
      setUpdatedNotes('');
      // Log the response from the API (optional)
      console.log('Address updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating address:', error);
      // Handle errors, e.g., show an error message to the user
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='formUpdatedAddress'>
          <Form.Label>Address :</Form.Label>
          <Form.Control
          className='mb-2'
            type='text'
            placeholder='Enter updated address'
            value={updatedAddress}
            onChange={(e) => {
              setUpdatedAddress(e.target.value);
              // Clear validation error when user starts typing
              setError('');
            }}
          />
          <Form.Label>Tag(optional) :</Form.Label>
          <Form.Control
          className='mb-2'
            type='text'
            placeholder='Enter updated Tag'
            value={updatedTag}
            onChange={(e) => {
              setUpdatedTag(e.target.value);
              // Clear validation error when user starts typing
              setTagError('');
            }}
          />
          <Form.Label>Notes :</Form.Label>
          <Form.Control
            className='mb-2'
            as="textarea" 
            rows={5}
            placeholder='Enter updated Notes'
            value={updatedNotes}
            onChange={(e) => {
              setUpdatedNotes(e.target.value);
              // Clear validation error when user starts typing
              setError('');
            }}
          />
          {error && <Form.Text className='text-danger'>{error}</Form.Text>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>
          Close
        </Button>
        <Button variant='dark' onClick={handleAddressUpdate}>
          Update Address
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressUpdateModal;
