import React, { useCallback, useEffect, useState } from 'react';
import "./Welcome.css";
import Navbar from '../Navbar/Navbar';
import tableImage from "../../Assets/Image/table.jpg";
import exampleLogo from "../../Assets/Image/examplelogo.jpg";
import rightArrow from "../../Assets/Icons/right.png"
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const Welcome = () => {
    const { tableId, restaurantId } = useParams()
    const [tableData, setTableData] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [blur, setBlur] = useState(false);
    
    const searchDialogOpen = (NavbarData) => {
        setBlur(NavbarData);
    };

    const getSelectedTable = useCallback(async () => {
        try {
            setError("");
            setLoading(true);

            const response = await axios.get(`${process.env.REACT_APP_SECRET}/api/RestaurantTable/GetSelectedTableByTableIdAndRestaurantId?tableId=${tableId}&restaurantId=${restaurantId}`);

            setTableData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.response.data);
        }
    }, [tableId, restaurantId]);

    useEffect(() => {
        getSelectedTable();
        console.log("running on render");
    }, [getSelectedTable]);

    if(tableData){
        const obj = {
            tableId: tableData.tableId,
            tableName: tableData.tableName,
            restaurantId: tableData.restaurantId
        };

        sessionStorage.setItem("tableData", JSON.stringify(obj));
    }

    return (
        <div>
            <Navbar handleNavbar={searchDialogOpen} />
            <div className="welcome-container">
                {
                    loading ? <div>loading</div> :
                        <>
                            <div className="table-img-container">
                                <img src={tableData.tableImage} alt={tableData.tableName} />
                            </div>

                            <div className="welcome-info">
                                <div className="restaurant-logo">
                                    <img src={tableData.restaurantLogo} alt={tableData.restaurantName} />
                                </div>
                                <h1 className="welcome-title my-4">Welcome to {tableData.restaurantName}</h1>
                                <div className="welcome-table">You are on table no: <span className="table-number">{tableData.tableName}</span></div>
                                <div className="welcome-cta">
                                    <Link className="welcome-btn" to={`/${tableData.restaurantURL}`}>
                                        <div>Go to restaurant page</div>
                                        <div className="btn-icon">
                                            <img src={rightArrow} alt={"right arrow"} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default Welcome;