import React, { useState } from 'react'
import LeoImg from './../../../Assets/Image/Leo.png'
import './commentDisplay.css'
import ReplyComponent from '../ReplyComponent/ReplyComponent'
import CommentImages from './commentimages/CommentImages'

const CommentDisplay = (props) => {
  const [showReply, setShowReply] = useState(false)

  return (
    <div>
      <div className="d-flex comment-View mt-3 ">
        <img src={LeoImg} alt="" className="user-img" />
        <div className="d-flex flex-column comment">
          <p className="text-justify">
            <span className="userName">Leo</span>
            Leo Love the art of travel. So many places to see, people to meet,
            cul tures & foods to discover. Love connecting with people,
            exchanging stories and traveling tips. I'm a neat freak so expected
            it when I host and
          </p>
          <div className="commentImagesDiv">
            <CommentImages place={props.place} />
          </div>
          <p className="text-muted commentDate">12 Feb 2023</p>
          <div className="d-flex justify-content-between likesAndReply">
            <h5>Likes 20</h5>
            <h5
              onClick={() => {
                setShowReply(!showReply)
              }}
            >
              Reply
            </h5>
          </div>
          <div className={`${showReply ? 'w-100' : 'd-none'}`}>
            <ReplyComponent />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentDisplay
