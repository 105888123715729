import { GET_SEARCH_BAR_DATA } from "../../actions/types";

const initialState = {
    value: "",
}

const searchBarReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case GET_SEARCH_BAR_DATA:
            return {
                ...state,
                value: action.payload,
            };
        default: 
            return state;
    }
}

export default searchBarReducer;