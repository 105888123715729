import { Button, Modal } from "react-bootstrap";
import close from "../../Assets/Icons/close2.png"

const OutOfHourModal = ({setShowOutOfHourModal, foodItemName})=>{
    return (
        <div
        className="modal show"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Modal.Dialog style={{ width: "370px" }}>
            <Modal.Header className="d-flex align-items-center">
              <Modal.Title>
                <h5 style={{ fontWeight: "bold" }}>{foodItemName} Opening hour</h5>
                <div className="unavailable-text">Out of hour</div>
              </Modal.Title>
              <div className="close-btn" onClick={()=> setShowOutOfHourModal(false)}>
                <img src={close} alt="close" />
              </div>
            </Modal.Header>

            <Modal.Body>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <h6 style={{ margin: 0 }}>Day / Date</h6>
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <h6 style={{ margin: 0 }}>Time</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      Sat - Tues
                    </td>
                    <td
                      style={{
                        borderRight: "none",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      09:00 AM - 09:00 PM
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      19 Feb 24
                    </td>
                    <td
                      style={{
                        borderRight: "none",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      09:00 AM - 11:00 PM
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      19 Feb 24
                    </td>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      09:00 AM - 11:00 PM
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="success"
                style={{
                  backgroundColor: "transparent",
                  color: "green",
                  fontWeight: "bold",
                }}
                onClick={()=> setShowOutOfHourModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
    )
}

export default OutOfHourModal;