import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import './SearchBar.css';
import searchBarWhite from './../../Assets/Icons/searchIcon2.png';
import searchBar from './../../Assets/Icons/searchIcon.png';
import backArrow from './../../Assets/Icons/BackArrow.png';
import { searchClick } from '../../actions';

const SearchBar = ({ getSearchValue }) => {
  const dispatch = useDispatch();
  const searchName = useSelector((state) => state.changeSearchName);
  const [selectedOption, setSelectedOption] = useState(null);
  // const message = props.message
  const [showTextArea, setShowTextArea] = useState(false);
  const options = [
    { value: 'barger', label: 'Barger' },
    { value: 'pizza', label: 'Pizza' },
    { value: 'chicken', label: 'Chicken' },
  ];
  function handleOptionChange(selectedOption) {
    setSelectedOption(selectedOption);
  }
  const searchValue = (event) => {
    const data = event.target.value;
    // alert(data);
    getSearchValue(data);
  }
  return (
    <div className='d-flex justify-content-center'>
      <div className='circularSearch d-flex align-items-center'>
        <div
          className={`searchTextAreaDiv ${
            showTextArea
              ? ' d-flex align-items-center visibleDiv'
              : 'visibility'
          }`}
        >
          <img
            src={backArrow}
            alt=''
            className={`backIcon`}
            onClick={() => {
              setShowTextArea(!showTextArea);
              dispatch(searchClick());
            }}
          />
          <input
            type="text"
            placeholder={searchName}
            onChange={searchValue}
            style={{width: '150px'}}
            className={`form-control circularInputText`}
          />
          {/* <Select
            type='text'
            options={options}
            value={selectedOption}
            onChange={handleOptionChange}
            // className={`form-control circularInputText `}
            placeholder={searchName}
          /> */}
        </div>

        <div
          className={`circularSearchIcon ${showTextArea ? 'visibility' : ''}`}
        >
          <img
            src={searchBar}
            alt=''
            className={`searchBarIcon `}
            onClick={() => {
              setShowTextArea(!showTextArea);
              dispatch(searchClick());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
