import React, { useState, useRef } from 'react';
import './CustomSlider.css';

const CustomSlider = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef(null);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };

    const handleTouchStart = (e) => {
        const touchStartX = e.touches[0].clientX;
        slideRef.current = touchStartX;
    };

    const handleTouchMove = (e) => {
        if (!slideRef.current) return;

        const touchEndX = e.touches[0].clientX;
        const difference = slideRef.current - touchEndX;

        if (difference > 50) {
            handleNext();
            slideRef.current = null;
        } else if (difference < -50) {
            handlePrev();
            slideRef.current = null;
        }
    };

    return (
        <div
            className="slider-container"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <button onClick={handlePrev} className="slider-button prev">◀</button>
            <div className="slider">
                <div
                    className="slider-content"
                    style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                    }}
                >
                    {slides.map((slide, index) => (
                        <div className="slide" key={index}>
                            {slide}
                        </div>
                    ))}
                </div>
            </div>
            <button onClick={handleNext} className="slider-button next">▶</button>
        </div>
    );
};

export default CustomSlider;
