import { Button, Modal } from "react-bootstrap";
import "./UnavavailableReasonModal.css";
import close from "../../Assets/Icons/close2.png"

const UnavavailableReasonModal = ({setShowUnavailableReasonModal, foodItemName, foodUnavailableReason, sectionUnavailableReason, menuUnavailableReason})=>{
    return (
        <div
        className="modal show"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Modal.Dialog style={{ width: "370px" }}>
            <Modal.Header className="d-flex align-items-center">
              <Modal.Title>
                <h5 style={{ fontWeight: "bold" }}>{foodItemName}</h5>
                <div className="unavailable-text">Unavailable</div>
              </Modal.Title>
              <div className="close-btn" onClick={()=> setShowUnavailableReasonModal(false)}>
                <img src={close} alt="close" />
              </div>
            </Modal.Header>

            <Modal.Body>
              <div>
                    {
                        foodUnavailableReason ? foodUnavailableReason : sectionUnavailableReason ? sectionUnavailableReason : menuUnavailableReason ? menuUnavailableReason : "No reason was provided"
                    }
                </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="success"
                style={{
                  backgroundColor: "transparent",
                  color: "green",
                  fontWeight: "bold",
                }}
                onClick={()=> setShowUnavailableReasonModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
    )
}

export default UnavavailableReasonModal;