import React, { useEffect } from 'react';

const Refresh = () => {

    useEffect(() => {
        window.location.href = "/";
    });

    return (
        <div>

        </div>
    );
};

export default Refresh;