import React, { useEffect, useState } from 'react';
import pkblogo from './../../Assets/Image/LogoTransparentBrown.png';
import androidButton from './../../Assets/Image/androidDownload.png';
import iosButton from './../../Assets/Image/iosDownload.png';
import Mastercard from './../../Assets/Icons/card.png';
import visaCard from './../../Assets/Icons/visa.png';
import amexCard from './../../Assets/Icons/Amex.png';
import TermsAndConditions from './../../Assets/Image/TermsAndConditions.pdf';
import './sidebarlogin.css';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Avatar, Menu, MenuItem } from '@mui/material';
const SidebarLogin = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem('gotasteClienttoken');
    if (token) {
      let decoded = jwtDecode(token);
      setUserDetails(decoded);
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('gotasteClienttoken');
    setUserDetails({});
    handleMenuClose();
    window.location.reload();
    // props.loginClick();
  };

  return (
    <div className='d-flex'>
      <div
        class='offcanvas offcanvas-start'
        tabindex='-1'
        id='offcanvasExample'
        aria-labelledby='offcanvasExampleLabel'
      >
        {/* <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div> */}
        <div class='offcanvas-body offsetSignup'>
          <div
            className='d-flex justify-content-start align-items-start'
            style={{ flexDirection: 'column' }}
          >
            {/* <div className="btn w-100 SignUp my-1">Sign Up</div> */}
            {/* <div className="btn w-100 login my-1" onClick={() => { callLoginModal() }}>login</div> */}
            {/* <p className='btn p-0'>Create a business account</p> */}
            {userDetails.UserName == undefined ? (
              <>
                <p
                  className='btn p-0'
                  onClick={() => {
                    props.loginClick();
                  }}
                >
                  I am a User
                </p>
              </>
            ) : (
              <div className='mt-1 mb-1'>
                <div className='d-flex align-items-center'>
                  <Avatar
                    sx={{ bgcolor: 'skyblue', marginRight: '10px' }}
                    onClick={handleMenuOpen}
                    style={{ cursor: 'pointer' }}
                  >
                    {userDetails.UserName[0].toUpperCase()}
                  </Avatar>
                  <p
                    className='btn p-0 mt-1 mb-0 d-flex align-items-center'
                    onClick={handleMenuOpen}
                    style={{ cursor: 'pointer' }}
                  >
                    Hello, {userDetails.UserName}
                  </p>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}

            <Link to='/register' className='btn p-0'>
              Add your restaurant
            </Link>
            <p
              className='btn p-0'
              onClick={() => {
                window.location.href =
                  'https://gotasteadmin.netlify.app/loginforcourier';
              }}
            >
              Sign up to deliver
            </p>
          </div>
          <div className='bottom mb-4'>
            <div>
              <div
                className='d-flex p-3 justify-content-center align-items-center'
                style={{ gap: '10px' }}
              >
                <div>
                  <img
                    src={pkblogo}
                    className='d-flex mx-auto logoSideBar'
                    alt=''
                  />
                </div>
                <p className='p-0 m-0'>
                  <b>There's more to love in the app.</b>
                </p>
              </div>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ gap: '10px' }}
              >
                <img src={Mastercard} alt='' style={{ width: '2rem' }} />
                <img src={visaCard} alt='' style={{ width: '2rem' }} />
                <img src={amexCard} alt='' style={{ width: '2rem' }} />
              </div>
              {/* <div className='d-flex'>
                <button className='btn btn-light  w-50 m-1'>
                  <img src={androidButton} className='img-fluid' alt='' />
                </button>
                <button className='btn btn-light  w-50 m-1'>
                  <img src={iosButton} className='img-fluid' alt='' />
                </button>
              </div> */}
              <div
                className='d-flex flex-column pb-mb-3 justify-content-center align-items-center'
                style={{ gap: '10px' }}
              >
                <a
                  href='/termsandconditions'
                  style={{ textDecoration: 'none' }}
                >
                  Terms and conditions
                </a>
                <a href='/privacy' style={{ textDecoration: 'none' }}>
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarLogin;
