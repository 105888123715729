import React, { useState, useEffect } from 'react';
import LeoImg from './../../../Assets/Image/Leo.png';
import $ from 'jquery';
import './../PostDisplay.css'
import sendImg from './../../../Assets/Image/send.png'
import plusicon from './../../../Assets/Icons/picture.png'

const ReplyComponent = () => {

    const [showSend, setShowSend] = useState(false);
    // useEffect(() => {
    //     // window.addEventListener("click", () => {
    //     //     // setShowSend(false)
    //     //     if ($('.sendDiv').not('.sendDiv').length) {
    //     //         setShowSend(false);
    //     //     }
    //     // })
    //     console.log(showSend);
    // })
    // function setshosendValue() {
    //     alert("Ok");
    //     var sendDivRef = document.querySelector('.sendDiv');
    //     console.log(sendDivRef);
    //     // sendDivRef.classList.remove('d-none');
    //     // sendDivRef.classList.add('d-flex');
    //     sendDivRef.classList.add('selected');
    //     // setShowSend(true);
    // }

    return (
        <div className='d-flex'>
            <img src={LeoImg} alt="" className='user-img' />
            <div className='circularDiv w-100' onClick={() => setShowSend(true)}>
                {/* <hr></hr> */}
                {/* <textarea name="" id="" placeholder='Write a comment' className='form-control' oninput={auto_grow(this)}></textarea> */}

                <div contentEditable="true" className='editableP emoji-picker-container' data-placeholder="Make a new tag" data-emojiable="true"></div>
                <div className={`${showSend ? "d-flex justify-content-between mt-2 sendDiv" : "d-none"}`} id=''>
                    {/* // {`${showSend ? "d-flex justify-content-end mt-2 sendDiv" : "d-none"}`}> */}
                    {/* <button className='btn-custom '>Send</button> */}
                    <label htmlFor="formId">
                        <input name="" type="file" id="formId" hidden />
                        <img src={plusicon} alt='' style={{ width: "1.5rem" }} />

                    </label>
                    <img src={sendImg} alt="" style={{ width: "1.5rem" }} />
                </div>
            </div>

        </div >
    );
};

export default ReplyComponent;