import React, { useState, useEffect, useRef } from "react";
import { json, Link } from "react-router-dom";
import "./OrderCart.css";
import Sauces from "./Sauces/Sauces";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import loadingImg from "./../../../Assets/Image/LoadingSpin.gif";
import { fetchCartData } from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { incrementItemCount } from "../../../reducers/cartCounterReducer";
import { stubTrue } from "lodash";
import clock from "../../../Assets/Icons/clock.png";
import LeoImg from "../../../Assets/Image/Leo.png";
import Rating from "react-rating";
import warning from "../../../Assets/Icons/warning-sign.png";
import down from "../../../Assets/Icons/down.png";
import UnavavailableReasonModal from "../../../components/UnavailableReasonModal/UnavailableReasonModal";
import OutOfHourModal from "../../../components/OutOfHourModal/OutOfHourModal";

function OrderCart(props) {
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(props?.orderDetails?.itemBasePrice);
  const [saucestoggle, setsaucesToggle] = useState([]);
  const [saucesdis, setSaucesdis] = useState(false);

  const [options, setoptions] = useState([]);
  const [subOptions, setsubOptions] = useState([]);
  const [instruction, setInstruction] = useState("N/A");
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [userId, setUserId] = useState();
  const [sauceAdded, setSauceAdded] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedSubOptions, setSelectedSubOptions] = useState({});

  const [checkboxOptions, setCheckboxOptions] = useState({});
  const [buttonOptionId, setButtonOptionId] = useState({});
  const [apiRequestObj, setApiRequestObj] = useState({});
  const [optionQuantities, setOptionQuantities] = useState({});
  const [subOptionWarnings, setSubOptionWarnings] = useState({});
  const dispatch = useDispatch();
  // const cartDataCount = useSelector((state) => state.cartReducer.cartItems);

  const handleCheckboxChange = (addOnId, optionId, isChecked) => {
    setCheckboxOptions((prev) => {
      const currentOptions = prev[addOnId] || [];
      if (isChecked) {
        // Add the optionId if it's checked
        return {
          ...prev,
          [addOnId]: [...currentOptions, optionId],
        };
      } else {
        // Remove the optionId if it's unchecked
        return {
          ...prev,
          [addOnId]: currentOptions.filter((id) => id !== optionId),
        };
      }
    });
    var updatedSuboptions = subOptions.filter((x) => x.optionId != optionId);
    // var oldSuboptions = subOptions.filter((x) => x.optionId == optionId);

    // var oldtotalPrice = oldSuboptions.reduce(
    //   (sum, current) => sum + (current.price || 0),
    //   0
    // );

    // var updatedPrice = total - oldtotalPrice;
    // setTotal(updatedPrice);

    setsubOptions(updatedSuboptions);
  };

  // const handleRadioButtonChange = (addOnId, optionId) => {
  //   setSelectedOptions((prev) => ({
  //     ...prev,
  //     [addOnId]: optionId, // Update the selection for the specific addOnId
  //   }));
  // };

  const handleRadioButtonChange = (addOnId, optionId) => {
    setSelectedOptions((prev) => {
      const updatedOptions = { ...prev };

      if (updatedOptions[addOnId] === optionId) {
        // Deselect the option if it is already selected
        delete updatedOptions[addOnId];
      } else {
        // Select the new option
        updatedOptions[addOnId] = optionId;
      }

      return updatedOptions;
    });
  };

  const handleButtonChange = (addOnId, optionId) => {
    setButtonOptionId((prev) => ({
      ...prev,
      [addOnId]: optionId, // Update the selection for the specific addOnId
    }));
  };
  // console.log("selectedSubOptions0", selectedSubOptions);
  const handleSubOptionChange = (optionId, subOptionId) => {
    setSelectedSubOptions((prev) => {
      const currentSubOptionId = prev[optionId];

      // If the subOptionId already exists, remove it
      if (currentSubOptionId === subOptionId) {
        const { [optionId]: _, ...rest } = prev;
        return rest;
      }

      // Otherwise, add or update the subOptionId
      return {
        ...prev,
        [optionId]: subOptionId,
      };
    });
  };

  // const combineSelections = () => {
  //   const combined = { ...selectedOptions };

  //   // Merge checkboxOptions, appending them to the combined structure
  //   for (const addOnId in checkboxOptions) {
  //     if (checkboxOptions.hasOwnProperty(addOnId)) {
  //       combined[addOnId] = combined[addOnId]
  //         ? [combined[addOnId], ...checkboxOptions[addOnId]] // Combine with radio options
  //         : checkboxOptions[addOnId]; // Only checkboxes, no radio option for this addOn
  //     }
  //   }

  //   // Merge buttonOptionId similarly
  //   for (const addOnId in buttonOptionId) {
  //     if (buttonOptionId.hasOwnProperty(addOnId)) {
  //       combined[addOnId] = buttonOptionId[addOnId]; // Add the button option if available
  //     }
  //   }

  //   // Include selected subOptions
  //   for (const optionId in selectedSubOptions) {
  //     if (selectedSubOptions.hasOwnProperty(optionId)) {
  //       combined[optionId] = {
  //         ...combined[optionId],
  //         subOptionId: selectedSubOptions[optionId], // Add the selected subOption
  //       };
  //     }
  //   }

  //   return combined;
  // };

  const combineSelections = () => {
    const combined = { ...selectedOptions };

    // Add checkbox options
    for (const addOnId in checkboxOptions) {
      if (checkboxOptions.hasOwnProperty(addOnId)) {
        combined[addOnId] = combined[addOnId]
          ? [combined[addOnId], ...checkboxOptions[addOnId]]
          : checkboxOptions[addOnId];
      }
    }

    // Add button options
    for (const addOnId in buttonOptionId) {
      if (buttonOptionId.hasOwnProperty(addOnId)) {
        combined[addOnId] = buttonOptionId[addOnId];
      }
    }

    // Include preselected add-ons
    for (const addOnId in checkedItems) {
      if (checkedItems[addOnId] && !combined[addOnId]) {
        combined[addOnId] = []; // Mark as selected with no options or sub-options
      }
    }

    // Include sub-options
    for (const optionId in selectedSubOptions) {
      if (selectedSubOptions.hasOwnProperty(optionId)) {
        combined[optionId] = {
          ...combined[optionId],
          subOptionId: selectedSubOptions[optionId],
        };
      }
    }

    return combined;
  };

  // const generateOrderPayload = (apiResponse, selectedOptions) => {
  //   debugger;
  //   const userId = localStorage.getItem("gotasteClienttoken");
  //   const decoded = jwtDecode(userId);

  //   const orderPayload = {
  //     userId: decoded.UserId,
  //     restaurantId: apiResponse.restaurantId,
  //     foodItemId: apiResponse.id,
  //     foodItemQuantity: quantity,
  //     addOns: [],
  //   };

  //   setSubOptionWarnings({});

  //   apiResponse.addOns.forEach((addOn) => {
  //     const selectedOptionIds = selectedOptions[addOn.id];

  //     if (selectedOptionIds) {
  //       const matchedOptions = addOn.options
  //         .filter((option) => {
  //           if (Array.isArray(selectedOptionIds)) {
  //             return selectedOptionIds.includes(option.optionId);
  //           }
  //           return option.optionId === selectedOptionIds;
  //         })
  //         .map((option) => {
  //           const subOptionId = selectedSubOptions[option.optionId];
  //           const optionQuantity = optionQuantities[option.optionId] || 1; // Default to 1 if no quantity set

  //           if (!subOptionId) {
  //             setSubOptionWarnings((prevWarnings) => ({
  //               ...prevWarnings,
  //               [option.optionId]: `SubOption must be selected for option: ${option.optionName}`,
  //             }));
  //             return null;
  //           }
  //           // Clear the warning for this option
  //           setSubOptionWarnings((prevWarnings) => ({
  //             ...prevWarnings,
  //             [option.optionId]: "", // Clear warning for this specific option
  //           }));

  //           return {
  //             optionId: option.optionId,
  //             optionQuantity, // Add optionQuantity to the payload
  //             subOptionIds: [{ subOptionId }],
  //           };
  //         });

  //       if (matchedOptions.length > 0) {
  //         orderPayload.addOns.push({
  //           addOnId: addOn.id,
  //           addOnName: addOn.addOnName,
  //           options: matchedOptions,
  //         });
  //       }
  //     }
  //   });

  //   return orderPayload;
  // };

  // const generateOrderPayload = (apiResponse, selectedOptions) => {
  //   const userId = localStorage.getItem("gotasteClienttoken");
  //   const decoded = jwtDecode(userId);

  //   const orderPayload = {
  //     userId: decoded.UserId,
  //     restaurantId: apiResponse.restaurantId,
  //     foodItemId: apiResponse.id,
  //     foodItemQuantity: quantity,
  //     addOns: [],
  //   };

  //   setSubOptionWarnings({});

  //   apiResponse.addOns.forEach((addOn) => {
  //     const selectedOptionIds = selectedOptions[addOn.id];

  //     if (addOn.options.length === 0) {
  //       // If the add-on has no options, add it directly to the payload
  //       orderPayload.addOns.push({
  //         addOnId: addOn.id,
  //         addOnName: addOn.addOnName,
  //         options: [], // No options for this add-on
  //       });
  //     } else if (selectedOptionIds) {
  //       const matchedOptions = addOn.options
  //         .filter((option) => {
  //           if (Array.isArray(selectedOptionIds)) {
  //             return selectedOptionIds.includes(option.optionId);
  //           }
  //           return option.optionId === selectedOptionIds;
  //         })
  //         .map((option) => {
  //           const subOptionId = selectedSubOptions[option.optionId];
  //           const optionQuantity = optionQuantities[option.optionId] || 1; // Default to 1 if no quantity set

  //           if (!subOptionId) {
  //             setSubOptionWarnings((prevWarnings) => ({
  //               ...prevWarnings,
  //               [option.optionId]: `SubOption must be selected for option: ${option.optionName}`,
  //             }));
  //             return null;
  //           }
  //           // Clear the warning for this option
  //           setSubOptionWarnings((prevWarnings) => ({
  //             ...prevWarnings,
  //             [option.optionId]: "", // Clear warning for this specific option
  //           }));

  //           return {
  //             optionId: option.optionId,
  //             optionQuantity, // Add optionQuantity to the payload
  //             subOptionIds: [{ subOptionId }],
  //           };
  //         })
  //         .filter((option) => option !== null); // Filter out null values

  //       if (matchedOptions.length > 0) {
  //         orderPayload.addOns.push({
  //           addOnId: addOn.id,
  //           addOnName: addOn.addOnName,
  //           options: matchedOptions,
  //         });
  //       }
  //     }
  //   });

  //   return orderPayload;
  // };

  const generateOrderPayload = (apiResponse, selectedOptions) => {
    const userId = localStorage.getItem("gotasteClienttoken");
    const decoded = jwtDecode(userId);

    const orderPayload = {
      userId: decoded.UserId,
      restaurantId: apiResponse.restaurantId,
      foodItemId: apiResponse.id,
      foodItemQuantity: quantity,
      instruction: instruction,
      addOns: [],
    };

    setSubOptionWarnings({});

    apiResponse.addOns.forEach((addOn) => {
      const selectedOptionIds = selectedOptions[addOn.id];
      const isSelectedAddon =
        checkedItems[addOn.id] ||
        checkboxOptions[addOn.id]?.length > 0 ||
        buttonOptionId[addOn.id];

      if (isSelectedAddon) {
        // Add add-on even if no options or sub-options are selected
        const matchedOptions = addOn.options
          .filter((option) => {
            if (Array.isArray(selectedOptionIds)) {
              return selectedOptionIds.includes(option.optionId);
            }
            return option.optionId === selectedOptionIds;
          })
          .map((option) => {
            const subOptionId = selectedSubOptions[option.optionId];
            const optionQuantity = optionQuantities[option.optionId] || 1;

            // Handle warnings for missing sub-options
            if (!subOptionId && option.requiresSubOption) {
              setSubOptionWarnings((prevWarnings) => ({
                ...prevWarnings,
                [option.optionId]: `SubOption must be selected for option: ${option.optionName}`,
              }));
              return null;
            }

            setSubOptionWarnings((prevWarnings) => ({
              ...prevWarnings,
              [option.optionId]: "", // Clear warning
            }));

            return {
              optionId: option.optionId,
              optionQuantity,
              subOptionIds: subOptionId ? [{ subOptionId }] : [],
            };
          })
          .filter((option) => option !== null);

        orderPayload.addOns.push({
          addOnId: addOn.id,
          addOnName: addOn.addOnName,
          options: matchedOptions,
        });
      }
    });

    return orderPayload;
  };

  useEffect(() => {
    if (props.orderDetails) {
      try {
        // Generate payload even if no options are selected yet
        const combinedSelections = combineSelections();
        const requestObj = generateOrderPayload(
          props.orderDetails,
          combinedSelections
        );
        setApiRequestObj(requestObj);
      } catch (error) {
        console.error(error.message);
        // toast.warning(error.message);
      }
    }
  }, [
    props.orderDetails,
    selectedOptions,
    checkboxOptions,
    buttonOptionId,
    selectedSubOptions,
    quantity,
    optionQuantities,
    instruction,
  ]);

  // console.log("response from food card 2: ", apiRequestObj);

  useEffect(() => {
    setTotal(props.orderDetails.itemBasePrice);
    setQuantity(1);
    setsaucesToggle([]);
  }, []);

  const addQuantity = () => {
    setQuantity(quantity + 1);
    // setTotal(
    //   (value) =>
    //     props.orderDetails.itemBasePrice * (quantity + 1) +
    //     (value - props.orderDetails.itemBasePrice)
    // );
    setTotal((value) => value + props.orderDetails.itemBasePrice);
  };
  // const removeQuantity = () => {
  //   // if (quantity > 0) {
  //   //   setQuantity((prevQuantity) => prevQuantity - 1);
  //   //   // setTotal((value) => props.orderDetails.itemBasePrice * (quantity - 1));
  //   //   setTotal(
  //   //     (value) =>
  //   //       props.orderDetails.itemBasePrice * (quantity - 1) +
  //   //       (value - props.orderDetails.itemBasePrice)
  //   //   );
  //   // }
  //   if (quantity > 0) {
  //     setQuantity((prevQuantity) => prevQuantity - 1);
  //     setTotal((value) => value - props.orderDetails.itemBasePrice);

  //   }
  // };

  const removeQuantity = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;

        if (newQuantity === 0) {
          // Reset all add-ons to unchecked
          const resetCheckedItems = {};
          props.orderDetails.addOns.forEach((dt) => {
            resetCheckedItems[dt.id] = false;
          });
          setCheckedItems(resetCheckedItems);
        }

        return newQuantity;
      });

      setTotal((value) => {
        const newTotal = value - props.orderDetails.itemBasePrice;

        if (quantity === 1) {
          return 0;
        }

        return newTotal;
      });
    }
  };
  const saucestoggleHandler = (props) => {
    var list = [...saucestoggle];
    var index = list.findIndex((x) => x === props);

    if (index !== -1) {
      list.splice(index, 1);
    } else {
      list.push(props);
    }
    console.log(list);
    setsaucesToggle(list);
  };

  // useEffect(() => {
  //   const initialCheckedState = {};
  //   let initialTotalPrice = total;

  //   props.orderDetails.addOns.forEach((dt) => {
  //     if (dt.isCollapsibleOption === true) {
  //       initialCheckedState[dt.id] = false;
  //     } else if (dt.isCollapsibleOption === false) {
  //       const isChecked = dt.options.length < 4;
  //       initialCheckedState[dt.id] = isChecked;

  //       if (isChecked) {
  //         initialTotalPrice += dt.basePrice;
  //       }
  //     } else {
  //       initialCheckedState[dt.id] = false;
  //     }
  //   });

  //   setCheckedItems(initialCheckedState);
  //   setTotal(initialTotalPrice);
  // }, [props.orderDetails.addOns]);

  const [lessOptions, setLessOptions] = useState(false);

  useEffect(() => {
    const initialCheckedState = {};
    const preselectedAddOns = {};

    let initialTotalPrice = total;

    props.orderDetails.addOns.forEach((addOn) => {
      if (addOn.isCollapsibleOption === false) {
        // Preselect add-on when isCollapsibleOption is false
        initialCheckedState[addOn.id] = true;

        preselectedAddOns[addOn.id] = []; // Initialize as selected with no options or sub-options

        // Add the base price of preselected add-ons to the total
        if (addOn.options.length > 3) {
          initialTotalPrice += addOn.basePrice;
        } else {
          setLessOptions(true);
        }
      } else {
        // Default unchecked state for collapsible add-ons
        initialCheckedState[addOn.id] = false;
      }
    });

    // Update state with preselected add-ons and checked items
    setCheckedItems(initialCheckedState);
    setTotal(initialTotalPrice);

    // If there are preselected add-ons, add them to selectedOptions
    if (Object.keys(preselectedAddOns).length > 0) {
      setSelectedOptions((prev) => ({ ...prev, ...preselectedAddOns }));
    }
  }, [props.orderDetails.addOns]);

  useEffect(() => {
    if (options.length > 0 && subOptions.length > 0) {
      const newSubOptions = subOptions.filter((subOption) => {
        const isValid = options.some(
          (option) =>
            option.id === subOption.optionId &&
            option.addonId === subOption.addOnId
        );
        if (!isValid) {
          setTotal((prevValue) => prevValue - subOption.price);
        }
        return isValid;
      });

      setsubOptions(newSubOptions);
    }
  }, [options, subOptions]);

  const [optionPrice, setOptionPrice] = useState([]);
  const saucesCallback = (id, amount, optionquantity, addonId) => {
    if (options.length === 0) {
      setTotal((prevValue) => prevValue + amount);
      setOptionPrice((prev) => [...prev, { id: id, amount: amount }]);
      setoptions((prevState) => [...prevState, { id, price: amount, addonId }]);
    } else {
      const optionData = options.find(
        (x) => x.addonId == addonId && x.id == id
      );
      if (optionData) {
        console.log("optionPrice", optionPrice);
        const newOptions = options.filter((option) => option.id === id);
        const oprionP = optionPrice.find((x) => x.id == id);

        // setoptions([...newOptions, { id, price: amount, addonId }]);
        setoptions(options.filter((x) => x.id != id));
        setOptionPrice(optionPrice.filter((s) => s.id != id));
        var subOptionList = subOptions.filter((x) => x.optionId == id);
        var totalSum = subOptionList.reduce((accumulator, current) => {
          return accumulator + current.price;
        }, 0);

        var updatedSuboptions = subOptions.filter((x) => x.optionId != id);

        setsubOptions(updatedSuboptions);
        setTotal((prevValue) => prevValue - oprionP.amount - totalSum);
      } else {
        setoptions((prevState) => [
          ...prevState,
          { id, price: amount, addonId },
        ]);
        setTotal((prevValue) => prevValue + amount);
        setOptionPrice((prev) => [...prev, { id: id, amount: amount }]);
      }
    }

    // if (options.length > 0) {
    //   const prevsum = options.reduce((accumulator, object) => {
    //     return accumulator + object.amount * object.quantity;
    //   }, 0);
    //   var obj = options.find((x) => x.id == id && x.addonId == addonId);
    //   if (obj != undefined) {
    //     if (optionquantity == 0) {
    //       var index = options.findIndex(
    //         (x) => x.id === id && x.addonId == addonId
    //       );
    //       var latestList = subOptions.filter(
    //         (x) => x.optionId != id && x.addOnId != addonId
    //       );
    //       var prevtotalPrice = subOptions.reduce(
    //         (sum, item) => sum + item.price,
    //         0
    //       );
    //       var totalPrice = latestList.reduce(
    //         (sum, item) => sum + item.price,
    //         0
    //       );
    //       setsubOptions(latestList);
    //       var totalPriceData = total + totalPrice - prevtotalPrice;
    //       setTotal(totalPriceData);

    //       // Check if the object is found
    //       if (index !== -1) {
    //         // Remove the object using splice
    //         options.splice(index, 1);
    //       }
    //     } else {
    //       obj.quantity = optionquantity;
    //     }
    //   } else {
    //     options.push({
    //       id: id,
    //       amount: amount,
    //       quantity: optionquantity,
    //       addonId: addonId,
    //     });
    //   }
    //   const sum = options.reduce((accumulator, object) => {
    //     return accumulator + object.amount * object.quantity;
    //   }, 0);
    //   setTotal((value) => value + sum - prevsum);
    // } else {
    //   options.push({
    //     id: id,
    //     amount: amount,
    //     quantity: optionquantity,
    //     addonId: addonId,
    //   });
    //   setTotal((value) => value + amount);
    // }
  };
  const subOptionCallBack = (data) => {
    var subOptionsList = subOptions;
    console.log(data);
    console.log(subOptionsList);
    var objList = subOptionsList.filter(
      (x) => x.optionId === data.optionId && x.addOnId === data.addOnId
    );

    if (objList.length > 0) {
      var prevtotalPrice = objList.reduce((sum, item) => sum + item.price, 0);
      var latestList = subOptions.filter(
        (x) => x.optionId != data.optionId && x.addOnId != data.addOnId
      );

      var checkList = subOptionsList.filter(
        (x) =>
          x.optionId === data.optionId &&
          x.addOnId === data.addOnId &&
          x.subOptionId === data.subOptionId
      );

      if (checkList.length == 0) {
        latestList.push(data);
      }

      setsubOptions(latestList);

      var totalPrice = latestList.reduce((sum, item) => sum + item.price, 0);

      var totalPriceData = total + totalPrice - prevtotalPrice;
      setTotal(totalPriceData);
    } else {
      var prevtotalPrice = subOptionsList.reduce(
        (sum, item) => sum + item.price,
        0
      );
      subOptionsList.push(data);

      var totalPrice = subOptionsList.reduce(
        (sum, item) => sum + item.price,
        0
      );
      setsubOptions(subOptionsList);
      var totalPriceData = total + totalPrice - prevtotalPrice;
      setTotal(totalPriceData);
    }
  };

  let gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

  useEffect(() => {
    let decoded = {};

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
        // console.log(decoded);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      setUserId(decoded.UserId);
      // getUserChatPreview(decoded.UserId);
    }
  }, [gotasteClienttoken]);

  const onSubmit = async () => {
    setLoading(true);
    var gotasteClienttokenData = localStorage.getItem("gotasteClienttoken");
    if (gotasteClienttokenData != null) {
      var decodedData = jwtDecode(gotasteClienttokenData);
      console.log(decodedData);

      var itemViewd = {
        id: "string",
        cartRestaurantMapperId: "string",
        itemId: props.itemId,
        qunatity: quantity,
        portionSize: 0,
        instruction: instruction,
        unitPrice: props.orderDetails.itemBasePrice,
        creationDate: new Date().toISOString(),
      };
      var optionSubOptionList = [];
      console.log("options", options);
      options.map((option) => {
        var optionViews = {
          id: option.id,
          cartRestaurantItemMapperId: "",
          optionId: option.id,
          quantity: option.quantity,
          unitPrice: option.price,
          addonId: option.addonId,
          creationDate: new Date().toISOString(), // You can set the creationDate as needed
        };
        var subOptionList = subOptions.filter(
          (x) => x.addOnId === option.addonId && x.optionId === option.id
        );

        var subOptionListViews = [];

        subOptionList.map((dt) => {
          var obj = {
            id: "string",
            cartRestaurantItemOptionmapperId: "string",
            subOptionId: dt.subOptionId,
            quantity: 1,
            unitPrice: dt.price,
            creationDate: new Date().toISOString(),
          };
          subOptionListViews.push(obj);
        });

        var optionSubOption = {
          optionView: optionViews,
          subOptionListView: subOptionListViews,
        };
        optionSubOptionList.push(optionSubOption);
      });
      console.log(optionSubOptionList);
      console.log(optionSubOptionList);
      var itemOptionSuboptionList = [];
      var obj = {
        itemView: itemViewd,
        OptionSubOptionList: optionSubOptionList,
      };
      itemOptionSuboptionList.push(obj);

      var cartInsertView = {
        Cart: {
          id: "string",
          userId: decodedData.UserId,
          isActive: true,
          creationDate: new Date().toISOString(),
        },
        cartRestaurantMapperList: [
          {
            cartRestaurantView: {
              id: "string",
              cartId: "string",
              restaurantId: props.selectedItem.restaurantId,
              creationDate: new Date().toISOString(),
            },
            itemOptionSuboptionList: itemOptionSuboptionList,
          },
        ],
      };

      console.log("cart Insert view", cartInsertView);

      const fetchDataAndAddToCart = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
          );

          const data = response.data;

          const itemIdToCheck = props.itemId;
          console.log(itemIdToCheck);

          const isExist = data?.some((restaurant) =>
            restaurant.itemDetails.some((item) => item.itemId === itemIdToCheck)
          );

          console.log("exists here", isExist);

          if (!isExist) {
            await axios.post(
              process.env.REACT_APP_SECRET + "/api/Cart",
              cartInsertView
            );
            toast.success("Successfully Added to cart");
            // dispatch(fetchCartData(userId));
            setTimeout(() => {
              props.handleClose();
            }, 1000);
          } else {
            toast.warning("Item already exists in the cart");
          }
        } catch (error) {
          console.error("Error fetching or adding to cart:", error);
          toast.error("Something went wrong");
        } finally {
          setLoading(false);
        }
      };

      const AddToCart = async (reqBody) => {
        try {
          setLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_SECRET}/api/Cart/InsertIntoCart`,
            reqBody
          );
          console.log("response from food card 5: ", response);
          if (response.data) {
            toast.success(response.data);
            dispatch(incrementItemCount(1));
          }
        } catch (error) {
          console.log("response from food card 4: ", error);
          if (
            error.response.data.status === 400 &&
            error.response.status === 400
          ) {
            toast.warning("all data is not available");
          } else {
            toast.warning(error.response.data);
          }
        } finally {
          setLoading(false);
        }
      };

      if (apiRequestObj) {
        /* Addon, option and sub-options validation */
        // Object.entries(checkedItems).forEach(([addOnId, value]) => {
        //   if (value === true) {
        //     alert("addon is selected");
        //     const addon = props.orderDetails.addOns.find(addon => addon.id === addOnId); //selected-addon
        //     if (addon && addon.options && addon.options.length > 0) {
        //       alert("Selected addon has options");
        //       if (Object.keys(combineSelections()).length !== 0) {
        //         Object.entries(combineSelections()).forEach(([AddOnId, OptionId]) => {
        //           // alert("AddOnId : " + AddOnId + ": OptionId : "  + OptionId);
        //           // alert(JSON.stringify(addon.options));
        //           addon.options.forEach(option => {
        //             if (option.optionId === OptionId) {
        //               alert("Option is selecteed");
        //             }
        //           });
        //         });
        //       } else {
        //         alert("You have to select an option");
        //       }

        //       /* checking for --- suboption -- start */
        //       // let hasSubOption = false;
        //       // addon.options.forEach(option => {
        //       //   if (option.subOptions && option.subOptions.length > 0) {
        //       //     hasSubOption = true;
        //       //   }
        //       // });
        //       // if (hasSubOption) {
        //       //   alert("This option has sub-options.");
        //       // } else {
        //       //   AddToCart(apiRequestObj);
        //       //   alert("This option has no sub-options...adding to cart");
        //       // }
        //       /* checking for --- suboption -- ends */
        //     } else {
        //       /* Addon has no option..directly adding to the cart */
        //       AddToCart(apiRequestObj);
        //     }
        //   }
        //   // else {
        //   //   alert("No addon is selected");
        //   //   // AddToCart(apiRequestObj);
        //   // }
        // });
        AddToCart(apiRequestObj);
      }
      // fetchDataAndAddToCart();
    } else {
      toast.warning("Please login !!");
      setLoading(false);
    }
  };

  // const handleToggle = (dt) => {
  //   setCheckedItems((prevState) => {
  //     const isChecked = !prevState[dt.id];
  //     const updatedCheckedItems = {
  //       ...prevState,
  //       [dt.id]: isChecked,
  //     };

  //     // Adjust total price based on toggle
  //     setTotal(
  //       (prevPrice) =>
  //         isChecked
  //           ? prevPrice + dt.basePrice // Add basePrice if checked
  //           : prevPrice - dt.basePrice // Subtract basePrice if unchecked
  //     );

  //     return updatedCheckedItems;
  //   });
  // };

  const [showAddonMinus, setShowAddonMinus] = useState(false);

  useEffect(() => {
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };

      props.orderDetails.addOns.forEach((dt) => {
        // Force toggle off for items with options > 3
        if (dt.options.length > 3) {
          updatedCheckedItems[dt.id] = false;
        }

        // Handle visibility for countable items
        if (dt.isCountable) {
          if (dt.options.length < 3) {
            setShowAddonMinus(true);
            updatedCheckedItems[dt.id] = true; // Options not visible
          } else {
            updatedCheckedItems[dt.id] = false; // Options visible
          }
        }
      });

      return updatedCheckedItems;
    });
  }, [props, setCheckedItems]);

  const handleToggle = (dt) => {
    // If lessOptions is true, allow toggling but don't change the price or options
    if (lessOptions) {
      setCheckedItems((prevState) => ({
        ...prevState,
        [dt.id]: !prevState[dt.id], // Simply toggle the checked state
      }));
      setLessOptions(false);
      return;
    }

    // Proceed with normal behavior if lessOptions is false
    setCheckedItems((prevState) => {
      const isChecked = !prevState[dt.id];

      const updatedCheckedItems = {
        ...prevState,
        [dt.id]: isChecked,
      };

      // Adjust total price based on toggle
      setTotal(
        (prevPrice) =>
          isChecked
            ? prevPrice + dt.basePrice // Add basePrice if checked
            : prevPrice - dt.basePrice // Subtract basePrice if unchecked
      );

      // Update selectedOptions to add or remove the add-on
      if (isChecked) {
        setSelectedOptions((prevOptions) => ({
          ...prevOptions,
          [dt.id]: [], // Add the add-on with no options or sub-options
        }));
      } else {
        setSelectedOptions((prevOptions) => {
          const updatedOptions = { ...prevOptions };
          delete updatedOptions[dt.id]; // Remove the add-on entry
          return updatedOptions;
        });
      }

      return updatedCheckedItems;
    });
  };

  // console.log(sauceAdded);

  // const parentRef = useRef(null);
  const parentRef = useRef(null);

  const [buttonWidth, setButtonWidth] = useState("auto");

  useEffect(() => {
    const updateButtonWidth = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        setButtonWidth(`${parentWidth}px`);
      }
    };

    // Update on resize
    window.addEventListener("resize", updateButtonWidth);

    // Initial update
    updateButtonWidth();

    return () => {
      window.removeEventListener("resize", updateButtonWidth);
    };
  }, [parentRef]);

  // Showing preselected addon price into total
  useEffect(() => {
    if (props.orderDetails.addOns) {
      props.orderDetails.addOns.forEach((dt) => {
        if (dt.isCollapsibleOption) {
          setTotal((prevValue) => prevValue + dt.basePrice);
        }
      });
    }
  }, [props.orderDetails.addOns]);

  
  const getFoodStatus = (activeStatus, outOfHourStatus) => {
    if (activeStatus === 0 && outOfHourStatus === 0) {
      return "Unavailable";
    } else if (activeStatus === 0 && outOfHourStatus === 1) {
      return "Unavailable";
    } else if (activeStatus === 1 && outOfHourStatus === 1) {
      return "Out of hour";
    } else if (activeStatus === 1 && outOfHourStatus === 0) {
      return "Available";
    } else {
      return "Unknown status";
    }
  };

  // addOn counter function and states

  const [addonQuantities, setAddonQuantities] = useState({});

  const handleAddonQuantityChange = (type, addonId) => {
    setAddonQuantities((prevQuantities) => {
      const currentQuantity =
        prevQuantities[addonId] ||
        props.orderDetails.addOns.find((x) => x.id === addonId)?.minQuantity ||
        1;
      const addon = props.orderDetails.addOns.find((x) => x.id === addonId);

      if (!addon) return prevQuantities; // Ensure addon exists to avoid errors

      let newQuantity = currentQuantity;

      if (type === "plus") {
        if (newQuantity < addon.maxQuantity) {
          if (!showAddonMinus) {
            newQuantity = addon.minQuantity;
            setShowAddonMinus(true);

            setTotal((prevPrice) => prevPrice + newQuantity * addon.basePrice);
          } else {
            newQuantity += addon.increamentalQuantity || 1;
            const quantityDifference = newQuantity - currentQuantity;
            const priceDifference = addon.basePrice * quantityDifference;

            setTotal((prevPrice) => prevPrice + priceDifference);
          }
        }
      } else if (type === "minus") {
        if (newQuantity > 0) {
          const decrement = addon.increamentalQuantity || 1;
          newQuantity = Math.max(0, newQuantity - decrement);

          if (newQuantity === 0) {
            setShowAddonMinus(false);
          }
        }

        const quantityDifference = newQuantity - currentQuantity;
        const priceDifference = addon.basePrice * quantityDifference;

        setTotal((prevPrice) => prevPrice + priceDifference);
      }

      // Update the total price based on the change in quantity

      // Manage add-on visibility based on quantity
      setCheckedItems((prevState) => {
        const updatedCheckedItems = { ...prevState };
        updatedCheckedItems[addonId] = newQuantity > 0;
        return updatedCheckedItems;
      });

      return {
        ...prevQuantities,
        [addonId]: newQuantity,
      };
    });
  };

  useEffect(() => {
    // Include the total from isCountable addOns
    const countableAddOnTotal = props.orderDetails.addOns
      .filter((addOn) => addOn.isCountable)
      .reduce((sum, addOn) => {
        const minQuantity = addOn.minQuantity || 0;
        const basePrice = addOn.basePrice || 0;
        return sum + minQuantity * basePrice;
      }, 0);

    // Set the state with the calculated values
    setTotal((prev) => prev + countableAddOnTotal);
  }, [props.orderDetails]);

  console.log("total", total, addonQuantities);

  return (
    <>
      {/* <dialog open></dialog> */}
      {/* {JSON.stringify(selectedSubOptions)} */}
      <style>
        {`

.fixedBtn{
 width: 100%;
    // position: fixed;
    // bottom: 4rem;
   padding-left:3rem
   padding-right:3rem
}






  @media only screen and (max-width: 688px) {
       .NewborderLinezero {
              margin-bottom: 4rem;
              // display:none;
         }
          
    .fixedBtn {
    position: fixed;
    bottom: 1rem;
    width: 90%;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
              }
  }


   

          
        `}
      </style>
      <ToastContainer />
      <div
        className={`card ${props.from === "modal"
            ? "borderLinezero"
            : "OrderCartDetails Orderfoodcard"
          }  col-12  NewborderLinezero`}
      >
        <div className="card-body  pt-0 pb-0">
          <div>
            {props.from === "modal" ? (
              ""
            ) : (
              <h4 className="textColor card-title">
                Build your orders ({props.orderDetails.itemName})
              </h4>
            )}
            {/* Radio buttons start */}
            <div className="row toggleSection">
              <div className="col-12 p-0" id="accordionExample">
                {props.orderDetails.addOns !== undefined &&
                  props.orderDetails.addOns.map((dt, i) => (
                    <div key={dt.id + "_" + i}>
                      {/* {dt.options.length > 0 && ( */}
                      <div className="form-check form-switch d-flex justify-content-between toggleTittle ">
                        <label
                          className="form-check-label ordercartTitle"
                          htmlFor={dt.id}
                        >
                          <div>
                            {dt.addOnName ? dt.addOnName : <Skeleton />}
                          </div>
                        </label>
                        {/* Increment addons section */}

                        {/* {dt.options.length > 0 && ( */}
                        <div
                          className="d-flex align-content-center"
                          style={{
                            // justifyContent: 'space-between',
                            // flexDirection: 'row',
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {dt.isCountable && (
                            <div
                              className={`d-flex align-items-center`}
                              style={{
                                gap: "10px",
                              }}
                            >
                              {showAddonMinus && (
                                <>
                                  <div
                                    onClick={() =>
                                      handleAddonQuantityChange("minus", dt.id)
                                    }
                                    className=" plusCircle"
                                  >
                                    <p>-</p>
                                  </div>
                                  <p>
                                    {addonQuantities[dt.id] || dt.minQuantity}
                                  </p>
                                </>
                              )}

                              <div
                                onClick={() =>
                                  handleAddonQuantityChange("plus", dt.id)
                                }
                                className=" plusCircle"
                              >
                                <p>+</p>
                              </div>
                            </div>
                          )}
                          <p
                            style={{
                              fontSize: ".9rem",
                              marginRight: dt.isCountable ? "20px" : "40px",
                            }}
                          >
                            {dt.basePrice ? (
                              `£${(
                                dt.basePrice * (addonQuantities[dt.id] || 1)
                              ).toFixed(2)}`
                            ) : (
                              <Skeleton />
                            )}
                          </p>
                          {!dt.isCountable && (
                            <input
                              className="form-check-input toggleSwitch checkbox-gray"
                              type="checkbox"
                              id={dt.id}
                              disabled={
                                options.find((x) => x.addonId == dt.id) ==
                                  undefined
                                  ? false
                                  : true
                              }
                              // checked={dt.isCollapsibleOption}
                              checked={checkedItems[dt.id] || false}
                              // onClick={() => {
                              //   setCheckedItems((prevState) => ({
                              //     ...prevState,
                              //     [dt.id]: !prevState[dt.id], // Toggle the checked state for this specific checkbox
                              //   }));
                              // }}
                              onClick={() => handleToggle(dt)}
                            />
                          )}
                        </div>

                        {/* )} */}
                      </div>
                      {/* )} */}
                      {/* Option and suboption mapping */}
                      <div
                        className={`${checkedItems[dt.id] ? "mt-2 wholeTable" : "d-none"
                          }`}
                      >
                        <table className="table table-borderless orderCart">
                          <thead>
                            {dt.options.length > 0 && (
                              <tr>
                                <th className="thTitle">Select any</th>
                              </tr>
                            )}
                          </thead>
                          <tbody
                            className={`${dt.optionButtonId === 5 ? "table-buttons" : ""
                              }`}
                          >
                            {dt.options.map((sauce, index) => (
                              <tr key={index}>
                                <Sauces
                                  options={options}
                                  setoptions={setoptions}
                                  setsubOptions={setsubOptions}
                                  name={sauce.optionName}
                                  price={sauce.basePrice}
                                  amount={sauce.basePrice}
                                  id={sauce.optionId}
                                  addonId={sauce.addOnId}
                                  isCountable={dt.isCountable}
                                  sauceIsCountable={sauce?.isCountable}
                                  optionButtonId={dt.optionButtonId}
                                  addOnName={dt.addOnName}
                                  setSauceAdded={setSauceAdded}
                                  saucesCallback={(
                                    id,
                                    amount,
                                    quantity,
                                    addonId
                                  ) => {
                                    saucesCallback(
                                      id,
                                      amount,
                                      quantity,
                                      addonId
                                    );
                                  }}
                                  subOptionCallBack={(data) => {
                                    subOptionCallBack(data);
                                  }}
                                  subOptions={sauce.subOptions}
                                  handleCheckboxChange={handleCheckboxChange}
                                  handleRadioButtonChange={
                                    handleRadioButtonChange
                                  }
                                  selectedOptions={selectedOptions}
                                  handleButtonChange={handleButtonChange}
                                  handleSubOptionChange={handleSubOptionChange}
                                  setOptionQuantities={setOptionQuantities}
                                  subOptionWarnings={
                                    subOptionWarnings[sauce.optionId]
                                  }
                                  checkboxOptions={checkboxOptions}
                                />
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* End */}
                    </div>
                  ))}
              </div>
            </div>

            {/* Radio buttons end */}

            {/* Quantity Increment / decrement buttons */}
            <div className="pt-3 ">
              <strong className="ordercartTitle">Quantity</strong>
              <div className="card border-0 ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12  d-flex justify-content-center">
                      <button
                        disabled={quantity === 1}
                        className=" quantityBtn rounded-2 "
                        onClick={() => {
                          removeQuantity();
                        }}
                      >
                        -
                      </button>

                      <strong className="pt-3 ml-3">{quantity}</strong>
                      <button
                        className=" quantityBtn  rounded-2 "
                        onClick={() => {
                          addQuantity();
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* quantity end */}

            {props.showUnavailableReasonModal && (
              <UnavavailableReasonModal
                setShowUnavailableReasonModal={
                  props.setShowUnavailableReasonModal
                }
                foodItemName={props.orderDetails.itemName}
                foodUnavailableReason={props.orderDetails.foodUnavailableReason}
                sectionUnavailableReason={
                  props.orderDetails.sectionUnavailableReason
                }
                menuUnavailableReason={props.orderDetails.menuUnavailableReason}
              />
            )}

            {props.showOutOfHourModal && (
              <OutOfHourModal
                setShowOutOfHourModal={props.setShowOutOfHourModal}
                foodItemName={props.orderDetails.itemName}
              />
            )}

            <div className="pt-3">
              <div className="col-12 mb-3" ref={parentRef}>
                <div className="pb-5">
                  <h5 className=" titleColor">Special instruction</h5>
                  <div className="">
                    <input
                      type="text"
                      className="form-control inputField"
                      onChange={(e) => {
                        setInstruction(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* Add to card button */}
                <div
                  className=" d-flex justify-content-evenly mt-5 fixedBtn"
                  style={{
                    width: buttonWidth,
                    position: "fixed",
                    bottom: "8%",
                  }}
                >
                  {loading ? (
                    <button
                      className=" btn btn-checkout"
                      type="button"
                      style={{ pointerEvents: "none", cursor: "no-drop" }}
                    >
                      <img
                        src={loadingImg}
                        className="img-fluid"
                        style={{ height: "1rem" }}
                        alt="loading"
                      />
                    </button>
                  ) : getFoodStatus(
                    props.orderDetails.isActive,
                    props.orderDetails.status
                  ) === "Unavailable" ? (
                    <button
                      className={`btn btn-checkout d-flex justify-content-center align-items-center ${getFoodStatus(
                        props.orderDetails.isActive,
                        props.orderDetails.status
                      ) === "Unavailable"
                          ? "add-btn-disabled"
                          : ""
                        }`}
                      type="button"
                      onClick={() => props.setShowUnavailableReasonModal(true)}
                    >
                      <div className="icon-container icon-no-background">
                        <img src={warning} alt="clock" />
                      </div>
                      <div className="mx-2">Unavailable</div>
                      <div className="icon-container icon-no-background">
                        <img src={down} alt="down" />
                      </div>
                    </button>
                  ) : getFoodStatus(
                    props.orderDetails.isActive,
                    props.orderDetails.status
                  ) === "Out of hour" ? (
                    <button
                      className={`btn btn-checkout d-flex justify-content-center align-items-center ${getFoodStatus(
                        props.orderDetails.isActive,
                        props.orderDetails.status
                      ) === "Out of hour"
                          ? "add-btn-disabled"
                          : ""
                        }`}
                      type="button"
                      onClick={() => props.setShowOutOfHourModal(true)}
                    >
                      <div className="icon-container">
                        <img src={clock} alt="warning" />
                      </div>
                      <div className="mx-2">Out of hour</div>
                      <div className="icon-container icon-no-background">
                        <img src={down} alt="down" />
                      </div>
                    </button>
                  ) : getFoodStatus(
                    props.orderDetails.isActive,
                    props.orderDetails.status
                  ) !== "Unavailable" ||
                    props?.orderDetails?.addOns.find(
                      (item) => item.isCountable === true
                    ) ? (
                    <button
                      className={`btn btn-checkout`}
                      type="button"
                      onClick={onSubmit}
                    >
                      Add for <strong>£ {total.toFixed(2)}</strong>
                    </button>
                  ) : (
                    " "
                  )}

                  {/* <Link to="/Offers">
                  <button className=" AddTocart rounded-2" type="button ">
                    Offers
                  </button>
                </Link> */}
                </div>
                {/* End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderCart;
