import { SEARCHING_START } from "../../actions/types";

const initialState = {
    searching: false,
}

const searchStart = (state = initialState, action) => {
    switch (action.type) {
        case SEARCHING_START:
            return {
                ...state,
                searching: action.payload,
            };
        default:
            return state;
    }
}

export default searchStart;