const initialState = {
  selectedData: {},
};

const sectionSubsectionSelect = (state = initialState, action) => {
  switch (action.type) {
    case "setSelectedSectionOrSubsection":
      return {
        selectedData: action.payload,
      };
    default:
      return state;
  }
};
export default sectionSubsectionSelect;
