import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./QRCodeModal.css";
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import scanner from "../../Assets/Icons/qrcode.png";
import logo from "../../Assets/Image/LogoTransparentBrown.png";

const QRCodeModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    QR Code
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="qr-container">
                    <div className="qr-info-container">
                        <div>
                            <div className="qr-icon">
                                <img src={scanner} alt="scan icon" />
                            </div>
                            {/* <h1 className="qr-title">Scan to order</h1> */}
                            <div className="qr-subtitle">This QR code will be required by your Restaurant. Please show this QR code to {props?.restaurant?.restaurantName} to receive your order</div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center logo-container">
                            <div className="gotaste-logo">
                                <img src={logo} alt="gotaste logo" />
                            </div>
                            <div className="logo-title">GOTASTE.UK</div>
                        </div>

                        <div className="Qr-code">
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                            <div className="line4"></div>
                            <div className="Qr-container">
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`https://gotasteadmin.netlify.app/order-details/${props?.restaurant?.orderId}`}
                                    viewBox={`0 0 256 256`}
                                    level='L'
                                />
                                {/* <div className={`qr-table-number`}>
                                    <div className="qr-logo">
                                        <img src={"GoTaste"} alt="logo" />
                                    </div>
                                    <span className="qr-num">{"statix"}</span>
                                    <Link className="restaurant-url" to={`https://gotaste.uk/`} target='_blank'>gotaste.uk/</Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="qr-footer">
                            <div className="restaurant-info-container">
                                <div className="restaurant-logo">
                                    <img src={props?.restaurant?.restaurantLogo} alt={"restaurant logo"} crossOrigin="anonymous" />
                                </div>
                                <h2 className='qr-restaurant'>{props?.restaurant?.restaurantName}</h2>
                            </div>
                            {/* <div className="qr-created">Created by GoTaste: <span className="qr-link">gotaste.uk</span></div> */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QRCodeModal;