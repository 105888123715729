import React,{useCallback, useEffect, useState } from 'react';
import ShowOrderStatus from '../../CheckOut/ShowOrderStatus';
import { Link } from 'react-router-dom';
import calculateTotalPrice from'./ItemDetails.js'
import axios from 'axios';

const PendingCartOrder = ({ 
 restaurant,
 item,
}) => {
  console.log("pending order data 2: ", restaurant)
  console.log(item)

  // const [quantity, setQuantity] = useState(item.foodItemQuantity);
  const [total, setTotal] = useState(0)



  useEffect(()=>{
    const calculateTotalPrice = ()=>{
      let totalValue = 0;
    for (let subOrder = 0; subOrder < restaurant.subOrders.length; subOrder++) {
      const res = restaurant.subOrders[subOrder];
      for (let a = 0; a <res.itemDetails.length; a++) {
        const item = res.itemDetails[a];
        
        totalValue = (item.basePrice + item.vatInAmount + item.sdInAmount) * item.foodItemQuantity
        for(let i = 0; i < item.addOns.length; i++){
          const addOnPrice = item.addOns[i].addOnBasePrice * item.addOns[i].addOnQuantity;
          totalValue += addOnPrice;
  
          for(let j = 0; j < item.addOns[i].options.length; j++){
            const optionPrice = item.addOns[i].options[j].optionBasePrice * item.addOns[i].options[j].optionQuantity
            totalValue += optionPrice;
  
            for(let k = 0; k < item.addOns[i].options[j].subOptions.length; k++){
              const subOptionPrice = item.addOns[i].options[j].subOptions[k].subOptionBasePrice * 1;
              totalValue += subOptionPrice;
            }
          }
        }
      }
         }
      setTotal(totalValue.toFixed(2))
      console.log(totalValue)
    }


    calculateTotalPrice();
  }, [restaurant]);



  return (
    <div className='p-2'>
      <div
        className={`d-flex ${restaurant?.orderStatus !== null
          ? 'justify-content-between'
          : 'justify-content-center'
          }`}
      >
        <p
          className='restaurantName'
          style={{
            fontFamily: 'Poppins',
            // fontStyle: 'italic',
            margin: '0 0.8em',
          }}
        >
          {restaurant?.restaurantName}
        </p>

        {restaurant?.orderStatus && (
          <ShowOrderStatus orderStatus={restaurant?.orderStatus} />
        )}
      </div>

      {restaurant?.subOrders?.map(
        (item) =>
          item?.itemDetails.map(foodItem => <div>
            <div
              className='d-flex mt-3 cartdetailsGrid justify-content-between align-items-center pendingcartdetailsGrid '
              style={{ cursor: 'pointer', padding: '0 14px' }}
            >
              <Link
                to={{
                  pathname: '/checkout',
                }}
                className='d-flex align-items-center text-decoration-none itemDetailsAndImage'
              >
                <div className="cart-item-image">
                  <img
                    src={foodItem?.foodItemImage}
                    alt=''
                     style={{ height: '4.5rem' }}
                  />
                </div>
                <div className='d-flex productDetails justify-content-center ps-2 pb-2'>
                  <h5>{foodItem?.foodItemName}</h5>
                  <div className='options addOnCart text-secondary-emphasis' >
                  {foodItem?.addOns?.map((addOn) =>{
                  return(
                    addOn?.addOnQuantity > 0 && (
                      <h6 key={addOn.addOnId} >
                        {addOn.addOnName} x{addOn.addOnQuantity}
                        <span> £{addOn.addOnBasePrice } </span>
                        <br />
                      </h6>
                    )
                  )                                        
                  })}
                    {foodItem?.addOns?.map((addOn) => addOn.options.map(option => {
                      return (
                        option?.optionQuantity > 0 && (
                          <h6 key={option.optionId} style={{marginLeft:"1rem"}}>
                            {option.optionName} x{option.optionQuantity}
                            <span> £{option.optionBasePrice} </span>
                            {/* Display other option details as needed */}
                            {/* Suboptions */}
                            {option?.subOptions?.map((suboption) => {
                              return (
                                  <span
                                    className='span-text'
                                    key={suboption.orderSubOptionId}
                                  >
                                    ({suboption.subOptionName} x
                                    {1} £
                                    {suboption.subOptionBasePrice})
                                  </span>
                              );
                            })}
                            <br />
                          </h6>
                        )
                      );
                    }))}
                  </div>
                </div>
              </Link>
              <div>
                <p className='price d-flex justify-content-end pendingPrice'>
                {/* £{foodItem?.totalPrice} */}
                  £{total}
                </p>
              </div>
            </div>
          </div>)
      )}
    </div>
  );
};

export default PendingCartOrder;
