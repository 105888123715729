import React, { useEffect, useState, useRef } from 'react';
import './chat.css';
import chatImg from './../../Assets/Image/Leo.png';
import messageSend from './../../Assets/Icons/Send.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'axios';
import { set } from 'lodash';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import loadingGif from './../../Assets/Image/loading.gif';
const Chat = ({ chat }) => {
  const [userId, setUserId] = useState();
  const [newMessage, setNewMessage] = useState();
  const [friends, setFriends] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeChatUser, setActiveChatUser] = useState();
  const [newMessageResponse, setNewMessageResponse] = useState();
  const [loading, setLoading] = useState(true);

  const messageRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    var decoded = {};
    const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
        console.log(decoded);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      console.log(decoded.UserId);
      setUserId(decoded.UserId);
      // joinChat(decoded.UserId);
      getUserChatPreview(decoded.UserId);
    }
  }, []);
  // chat
  useEffect(() => {
    setNewMessageResponse(chat.newResponse);
  }, [chat.newResponse]);

  const getUserChatPreview = (props) => {
    axios
      .get(
        `${process.env.REACT_APP_SECRET}/api/Chatting/chatpreview?id=${props}`
      )
      .then((res) => {
        setFriends(res.data);
        setLoading(false);
      })
      .catch();
  };

  //active chat handler
  useEffect(() => {
    if (activeChatUser) {
      // get the chats
      axios
        .get(
          `${process.env.REACT_APP_SECRET}/api/Chatting/getallchattinglistbyuserid?toUserId=${activeChatUser.toUserId}&fromUserId=${activeChatUser.fromUserId}`
        )
        .then((res) => {
          setMessages(res.data);
        })
        .catch();
    }
  }, [activeChatUser]);

  //   friendHandler
  const activeFriendHandler = (friend) => {
    // check and unread all message

    if (friend.ureadCount > 0) {
      let userIdData =
        userId === friend.fromUserId ? friend.toUserId : friend.fromUserId;
      axios
        .put(
          `${process.env.REACT_APP_SECRET}/api/Chatting/updateunreadmessage?fromUserId=${friend.fromUserId}&toUserId=${friend.toUserId}&userid=${userIdData}`
        )
        .then((res) => {
          let newFriends = friends.map((fnd) => {
            if (fnd.friendUserId === friend.friendUserId) {
              fnd.ureadCount = 0;
              return fnd;
            } else {
              return fnd;
            }
          });
        })
        .catch();
    }
    navigate(`/chat/${friend.toUserId}/${friend.fromUserId}`);
  };
  // send message handler
  const sendMessaageHandler = () => {
    axios
      .post(`${process.env.REACT_APP_SECRET}/api/Chatting`, {
        id: 'string',
        message: newMessage,
        creationTimestamp: '2023-09-03T04:55:56.412Z',
        lastUpdated: '2023-09-03T04:55:56.412Z',
        fromUserId: activeChatUser.fromUserId,
        toUserId: activeChatUser.toUserId,
        userId: userId,
        edited: true,
        unread: true,
        reportChatFromUserId: 'string',
        reportChatReportId: 'string',
        reportChatToUserId: 'string',
        deleted: true,
      })
      .then((res) => {
        // add the new message to list
        setMessages((oldArray) => [
          ...oldArray,
          {
            id: 'string',
            message: newMessage,
            creationTimestamp: '2023-09-03T04:55:56.412Z',
            lastUpdated: '2023-09-03T04:55:56.412Z',
            fromUserId: activeChatUser.fromUserId,
            toUserId: activeChatUser.toUserId,
            userId: userId,
            edited: true,
            unread: true,
            reportChatFromUserId: 'string',
            reportChatReportId: 'string',
            reportChatToUserId: 'string',
            deleted: true,
          },
        ]);
        // empty message box
        setNewMessage('');
        getUserChatPreview(userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // new response
  useEffect(() => {
    if (newMessageResponse && activeChatUser) {
      if (newMessageResponse.userId === activeChatUser.friendUserId) {
        setMessages((oldArray) => [...oldArray, newMessageResponse]);
        getUserChatPreview(userId);
      } else {
        // let indx;
        let newFriendsArray = friends.map((fnd, index) => {
          if (fnd.friendUserId === newMessageResponse.userId) {
            fnd.lastMessage = newMessageResponse.message;
            fnd.ureadCount = fnd.ureadCount + 1;
            // indx = index;
            return fnd;
          } else {
            return fnd;
          }
        });
        getUserChatPreview(userId);
        setFriends(newFriendsArray);
      }
    }
  }, [newMessageResponse]);
  // scroll
  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  return (
    <>
      <div className='dextopChat'>
        <div className='row'>
          <div className='col-3 cahtSidebar'>
            <div className='p-3'>
              <div className='p-3 fixedSearch'>
                <h2 className='ChatHeader'>Messages</h2>

                <div className='pb-4'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Search'
                  />
                </div>
              </div>

              {loading === true ? (
                <div>
                  <img
                    src={loadingGif}
                    alt=''
                    className='img-fluid'
                    style={{
                      width: '8rem',
                      height: '8rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'inherit',
                    }}
                  />
                </div>
              ) : (
                <>
                  {friends.length > 0 &&
                    friends.map((friend, index) => (
                      <div
                        onClick={() => activeFriendHandler(friend)}
                        key={index}
                        role={'button'}
                        className={`d-flex  p-3 my-2 align-items-center ${
                          activeChatUser &&
                          friend.friendUserId === activeChatUser.friendUserId
                            ? 'selecedCht'
                            : ''
                        }`}
                      >
                        <div className='me-2'>
                          {' '}
                          <img src={chatImg} alt='' />
                        </div>
                        <div className='w-100'>
                          <h4 className='m-0 p-0 d-flex justify-content-between'>
                            <span>{friend.friendName}</span>

                            {friend.ureadCount > 0 ? (
                              <span className='unread-count'>
                                {friend.ureadCount}
                              </span>
                            ) : (
                              ''
                            )}
                          </h4>
                          <p className='lastMessage'>
                            {friend.lastMessage.substring(0, 20)}{' '}
                            <span className='fw-bolder'>.</span>
                            <span className='ms-1'>{friend.creationTime}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          <div className='col-9 p-0'>
            {!activeChatUser ? (
              <div className='no-chat'>
                Select A Chat Or Start A New Conversation.
              </div>
            ) : (
              <div ref={messageRef} className='chat'>
                <div className='ChatName p-2 mb-3 d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <img src={chatImg} className=' me-3' alt='' />
                    <div className=''>
                      <h4 className='p-0 m-0'>
                        {activeChatUser && activeChatUser.friendName}
                        <span>sdflkjsdkflsjdlkf</span>
                      </h4>
                      <p className='p-0 m-0'>Active</p>
                    </div>
                  </div>
                </div>
                <div className='px-4'>
                  {messages.length > 0 &&
                    messages.map((message, index) => {
                      return (
                        <>
                          {message.userId === userId ? (
                            <div className='d-flex  m-2 align-items-center justify-content-end'>
                              <div>
                                <h5 className='sendMessage p-2 m-0 ps-3 '>
                                  {message.message}
                                </h5>
                              </div>
                            </div>
                          ) : (
                            <div className='d-flex  m-2 align-items-center'>
                              <div className='me-2'>
                                <img src={chatImg} alt='' />
                              </div>
                              <div>
                                <h5 className='reciveMessage p-2 m-0 ps-3'>
                                  {message.message}
                                </h5>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>

                <div className='messageSend'>
                  <div className='input-group'>
                    <textarea
                      type='text'
                      className='form-control p-1'
                      placeholder='Message'
                      aria-label="Recipient's username"
                      aria-describedby='button-addon2'
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                      onClick={sendMessaageHandler}
                      className='btn  sendbtn'
                      type='button'
                      id='button-addon2'
                    >
                      <i className='fa-solid fa-paper-plane'></i>
                      {/* <img className='img-fluid ' src={messageSend} alt="" /> */}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mobileChat'>
        <div className='row'>
          <div className='col-12 cahtSidebar'>
            <div className='p-3'>
              <div className='p-3 fixedSearch'>
                <h2 className='ChatHeader'>Messages</h2>

                <div className='pb-4'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Search'
                  />
                </div>
              </div>

              {loading == true ? (
                <div>
                  <img
                    src={loadingGif}
                    alt=''
                    className='img-fluid'
                    style={{
                      width: '8rem',
                      height: '8rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      display: 'inherit',
                    }}
                  />
                </div>
              ) : (
                <>
                  {' '}
                  {friends.length > 0 &&
                    friends.map((friend, index) => (
                      <div
                        onClick={() => activeFriendHandler(friend)}
                        role='button'
                        className='text-decoration-none'
                      >
                        <div
                          className={`d-flex  p-3 my-2 align-items-center ${
                            activeChatUser &&
                            friend.friendUserId === activeChatUser.friendUserId
                              ? 'selecedCht'
                              : ''
                          }`}
                        >
                          <div className='me-2'>
                            {' '}
                            <img src={chatImg} alt='' />
                          </div>
                          <div className='w-100'>
                            <h4 className='m-0 p-0 d-flex justify-content-between'>
                              <span>{friend.friendName}</span>
                              {friend.ureadCount > 0 ? (
                                <span className='unread-count'>
                                  {friend.ureadCount}
                                </span>
                              ) : (
                                ''
                              )}
                            </h4>
                            <p className='lastMessage'>
                              {friend.lastMessage.substring(0, 20)}
                              <span className='fw-bolder'> .</span>
                              <span className='ms-1'>
                                {friend.creationTime}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  chat: state.chatReducer,
});
export default connect(mapStateToProps)(Chat);
