import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination } from 'swiper';


import CustomSlider from './TestSlider/CustomSlider';

const Test6 = () => {
    const slides = [
        <div style={{ backgroundColor: 'red', height: '200px' }}>Slide 1</div>,
        <div style={{ backgroundColor: 'blue', height: '200px' }}>Slide 2</div>,
        <div style={{ backgroundColor: 'green', height: '200px' }}>Slide 3</div>,
        <div style={{ backgroundColor: 'green', height: '200px' }}>Slide 3</div>,
    ];

    return (
        <div className="App">
            <h1>Custom React Slider</h1>
            <CustomSlider slides={slides} />
        </div>
    );
};

export default Test6;
