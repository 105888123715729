import React, { useState } from "react";
import './FilterDialogue.css';

function FilterDialogue({ filters, setFilters }) {

  const [sortOption, setSortOption] = useState("relevance");
  const [priceRange, setPriceRange] = useState([10.5, 1000]);
  const [offers, setOffers] = useState({
    freeDelivery: false,
    offer: false,
    onlinePayment: false
  });
  
  const handlePriceRangeChange = (event) => {
    const { value, dataset: { index } } = event.target;
    const newIndex = parseInt(index);
    const newPriceRange = [...priceRange];
    newPriceRange[newIndex] = parseFloat(value);
    if (newPriceRange[0] > newPriceRange[1]) {
      newPriceRange[newIndex === 0 ? 1 : 0] = newPriceRange[newIndex];
    }
    setPriceRange(newPriceRange);
    setFilters(prevFilters => ({
      ...prevFilters,
      priceRange: newPriceRange
    }));
  };
  
  const handleSortOptionChange = (event) => {
    setSortOption(event.target.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      sortOption: event.target.value
    }));
  };
  
  const toggleOffer = (offerType) => {
    setOffers(prevOffers => ({
      ...prevOffers,
      [offerType]: !prevOffers[offerType]
    }));

    setFilters(prevFilters => ({
      ...prevFilters,
      offers: {
        ...prevFilters.offers,
        [offerType]: !prevFilters.offers[offerType]
      }
    }));
  };

  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div className="mb-3">
              <div className="fw-bold">Price Range</div>
              <div className="d-flex justify-content-between">
                <div className="small">{priceRange[0]}</div>
                <div className="small">{priceRange[1]}</div>
              </div>
              <div className="d-flex justify-content-between">
                <input
                  type="range"
                  className="form-range"
                  min="10.5"
                  max="1000"
                  step="1"
                  value={priceRange[0]}
                  data-index="0"
                  onChange={handlePriceRangeChange}
                />
                <input
                  type="range"
                  className="form-range"
                  min="10.5"
                  max="1000"
                  step="1"
                  value={priceRange[1]}
                  data-index="1"
                  onChange={handlePriceRangeChange}
                />
              </div>
            </div>
            <div class="mb-3">
              <div class="fw-bold" style={{ marginBottom: '0.7rem' }}>Offers</div>
              <div class="d-flex justify-content-center">
                <button
                  type="button"
                  className={`btn me-3 btn-outline-secondary filterDialogueBtn ${offers.freeDelivery ? 'active' : ''}`}
                  onClick={() => toggleOffer('freeDelivery')}
                >Free Delivery</button>

                <button
                  type="button"
                  className={"btn me-3 btn-outline-secondary filterDialogueBtn ${offers.offer ? 'active' : ''}"}
                  onClick={() => toggleOffer('offer')}
                >Offer</button>

                <button
                  type="button"
                  className={`btn btn-outline-secondary filterDialogueBtn ${offers.onlinePayment ? 'active' : ''}`}
                  onClick={() => toggleOffer('onlinePayment')}
                >Online Payment Available</button>
              </div>
            </div>


            <div class="mb-3 filterDialogueRadio">
              <div class="fw-bold" style={{ marginBottom: '0.7rem' }}>Sort By</div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="sortOption"
                  id="relevance"
                  value="relevance"
                  checked={sortOption === 'relevance'}
                  onChange={handleSortOptionChange}
                  style={{ marginLeft: 'auto' }}
                />
                <label
                  class="form-check-label ms-2"
                  htmlFor="relevance"
                >Relevance (Default)</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="sortOption"
                  id="rating"
                  value="rating"
                  checked={sortOption === 'rating'}
                  onChange={handleSortOptionChange}
                  style={{ marginLeft: 'auto' }}
                />
                <label class="form-check-label ms-2" htmlFor="rating">Rating (High to Low)</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="sortOption"
                  id="delivery"
                  value="delivery"
                  checked={sortOption === 'delivery'}
                  onChange={handleSortOptionChange}
                  style={{ marginLeft: 'auto' }}
                />
                <label
                  class="form-check-label ms-2"
                  htmlFor="delivery"
                >Delivery Time</label>
              </div>
              <div class="form-check d-flex flex-row-reverse" style={{ padding: '0' }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="sortOption"
                  id="distance"
                  value="distance"
                  checked={sortOption === 'distance'}
                  onChange={handleSortOptionChange}
                  style={{ marginLeft: 'auto' }}
                />
                <label
                  class="form-check-label ms-2"
                  htmlFor="distance"
                >Distance</label>
              </div>
            </div>


          </div>
        </div>
      </div>


    </div >
  );
}

export default FilterDialogue;