import React from 'react';
import './BottomPopUP.css'
import Subscribe from '../Assets/Icons/Subscribe.png'
import save from '../Assets/Icons/save.png'
import offers from '../Assets/Icons/offer.png'
const BottomPopus = () => {
    return (
        <div className='popus'>
            <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Toggle bottom offcanvas</button>

            <div class="offcanvas offcanvas-bottom mx-2  BottomPopUP" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div className=' bottomsheetSlideBtn d-flex align-items-center h-100'>
                    {/* <div class="offcanvas-header">


                    </div> 
                    {/* <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                </div>
                <div class="offcanvas-body small">
                    {/* <ul >
                        <li>
                            <a class="dropdown-item" href="#">Save</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">Offers</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">Subscribe</a>
                        </li>
                    </ul> */}
                    <button className=' p-2 border-0 m-1' ><img src={save} className='img-fluid ' alt="" />Save</button>
                    <br />
                    <button className=' p-2 border-0 m-1' ><img src={offers} alt="" className='img-fluid' />Offers</button>
                    <br />
                    <button className=' p-2 border-0 m-1'><img src={Subscribe} alt="" className='img-fluid' />Subscribe</button>
                </div>
            </div>
        </div>
    );
};

export default BottomPopus;