import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import RestaurantSelectionFooter from './RestaurantSelectionFooter';
import '../Components/EditShareYourThoughtsModal.css';
import restaurantColorful from './../../../Assets/Icons/restaurant-colorful.png';
import menuColorful from './../../../Assets/Icons/menu-colorful.png';
import foodColorful from './../../../Assets/Icons/foodItem-colorful.png';
import sectionColorful from './../../../Assets/Icons/section-colorful.png';

export default function RestaurantSelection({
  step,
  setStep,
  handleFilterModal,
  restaurant,
  setRestaurant,
  menu,
  setMenu,
  section,
  setSection,
  foodItem,
  setFoodItem,
  restaurantDatas,
  selectedRestaurantDatas,
  setSelectedRestaurantDatas,
  menuData,
  selectedMenuDatas,
  setSelectedMenuDatas,
  sectionDatas,
  selectedSectionDatas,
  setSelectedSectionDatas,
  foodItemDatas,
  selectFoodItemDatas,
  setSelectFoodItemDatas,
  isRestaurantDropdownVisible,
  setButtonDisabled,
  handleRemoveData,
  isMenuDropdownVisible,
  isSectionDropdownVisible,
  isFoodItemDropdownVisible,
  linkActive,
  setLinkActive,
}) {
  function checkforduplication(existingItems, newItem) {
    return existingItems.some((item) =>
      newItem.some((newValue) => newValue.id === item.id)
    );
  }

  function removeDuplication(existingArray, newValues, setter, key) {
    //addition filtering for section, because it does not contain id
    if (key === 'section') {
      const result = newValues?.filter(
        (item) => !existingArray.some((data) => data.code === item.code)
      );
      setter((prevState) => [...prevState, ...result]);
    }

    const result = newValues?.filter(
      (item) => !existingArray.some((data) => data.id === item.id)
    );

    setter((prevState) => [...prevState, ...result]);
  }

  function selectData() {
    if (foodItem.length > 0) {
      if (!checkforduplication(selectFoodItemDatas, foodItem)) {
        setSelectFoodItemDatas((prevState) => [...prevState, ...foodItem]);
      } else {
        removeDuplication(
          selectFoodItemDatas,
          foodItem,
          setSelectFoodItemDatas,
          'food'
        );
      }
    } else if (section.length > 0) {
      if (!checkforduplication(selectedSectionDatas, section)) {
        setSelectedSectionDatas((prevState) => [...prevState, ...section]);
      } else {
        removeDuplication(
          selectedSectionDatas,
          section,
          setSelectedSectionDatas,
          'section'
        );
      }
    } else if (menu.length > 0) {
      if (!checkforduplication(selectedMenuDatas, menu)) {
        setSelectedMenuDatas((prevState) => [...prevState, ...menu]);
      } else {
        removeDuplication(
          selectedMenuDatas,
          menu,
          setSelectedMenuDatas,
          'menu'
        );
      }
    } else if (restaurant.length > 0) {
      if (!checkforduplication(selectedRestaurantDatas, restaurant)) {
        setSelectedRestaurantDatas((prevState) => [
          ...prevState,
          ...restaurant,
        ]);
      } else {
        removeDuplication(
          selectedRestaurantDatas,
          restaurant,
          setSelectedRestaurantDatas,
          'restaurant'
        );
      }
    }

    // Reset value of temporary placeholer
    setRestaurant([]);
    setMenu([]);
    setSection([]);
    setFoodItem([]);
  }

  return (
    <div className='w-100 mt-3'>
      {/* Restaurant Dropdown */}
      {isRestaurantDropdownVisible && (
        <div
          className='d-flex align-items-center gap-2 mb-3'
          style={{ width: '100%' }}
        >
          <img
            src={restaurantColorful}
            alt='Restaurant Logo'
            style={{ width: '2rem', flexShrink: 0 }}
          />
          <div style={{ flexGrow: 1 }}>
            <Autocomplete
              multiple
              id='restaurant-dropdown'
              options={restaurantDatas}
              defaultValue={selectedRestaurantDatas}
              onChange={(event, newInputValue, reason, detail) => {
                setButtonDisabled(false);
                setRestaurant(newInputValue);
                if (reason === 'removeOption') {
                  handleRemoveData(detail.option);
                }
              }}
              getOptionLabel={(option) =>
                option.title ? option.title : option?.restaurantName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Restaurant'
                  fullWidth
                />
              )}
            />
          </div>
        </div>
      )}

      {/* Menu Dropdown */}
      {isMenuDropdownVisible && (
        <div
          className='d-flex align-items-center gap-2 mb-3'
          style={{ width: '100%' }}
        >
          <img
            src={menuColorful}
            alt='Menu Logo'
            style={{ width: '2rem', flexShrink: 0 }}
          />
          <div style={{ flexGrow: 1 }}>
            <Autocomplete
              multiple
              id='menu-dropdown'
              options={menuData}
              defaultValue={selectedMenuDatas}
              onChange={(event, newInputValue) => {
                setButtonDisabled(false);
                setMenu(newInputValue);
                handleFilterModal(2);
              }}
              getOptionLabel={(option) =>
                option.title ? option.title : option?.menuName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Select Menu'
                  placeholder='Select Menu'
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading='lazy'
                    src={option.img}
                    alt=''
                    style={{ width: '2rem' }}
                  />
                  <div className='d-flex flex-column'>
                    <h5 className='mb-0'>{option.title}</h5>
                    <span className='text-muted' style={{ fontSize: '1rem' }}>
                      {option.restaurantName}
                    </span>
                  </div>
                </Box>
              )}
            />
          </div>
        </div>
      )}

      {/* Section Dropdown */}
      {isSectionDropdownVisible && (
        <div
          className='d-flex align-items-center gap-2 mb-3'
          style={{ width: '100%' }}
        >
          <img
            src={sectionColorful}
            alt='Section Logo'
            style={{ width: '2rem', flexShrink: 0 }}
          />
          <div style={{ flexGrow: 1 }}>
            <Autocomplete
              multiple
              id='section-dropdown'
              options={sectionDatas}
              defaultValue={selectedSectionDatas}
              onChange={(event, newInputValue) => {
                setButtonDisabled(false);
                setSection(newInputValue);
                handleFilterModal(3);
              }}
              getOptionLabel={(option) =>
                option.label ? option.label : option?.sectionName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Section'
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading='lazy'
                    src={option.icon}
                    alt=''
                    style={{ width: '2rem' }}
                  />
                  <div className='d-flex flex-column'>
                    <h5 className='mb-0'>{option.label}</h5>
                    <span className='text-muted' style={{ fontSize: '1rem' }}>
                      {option.menuName}
                    </span>
                  </div>
                </Box>
              )}
            />
          </div>
        </div>
      )}

      {/* Food Item Dropdown */}
      {isFoodItemDropdownVisible && (
        <div
          className='d-flex align-items-center gap-2 mb-3'
          style={{ width: '100%' }}
        >
          <img
            src={foodColorful}
            alt='Food Item Logo'
            style={{ width: '2rem', flexShrink: 0 }}
          />
          <div style={{ flexGrow: 1 }}>
            <Autocomplete
              multiple
              id='food-item-dropdown'
              options={foodItemDatas}
              defaultValue={selectFoodItemDatas}
              onChange={(event, newInputValue) => {
                setButtonDisabled(false);
                setFoodItem(newInputValue);
                handleFilterModal(4);
              }}
              getOptionLabel={(option) =>
                option.title ? option.title : option?.foodItemName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Select Food Item'
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <div className='d-flex flex-column'>
                    <h5 className='mb-0'>{option.title}</h5>
                    <span className='text-muted' style={{ fontSize: '1rem' }}>
                      {option.restaurantName}
                      {option.menuName && <span> ({option.menuName})</span>}
                      {option.sectionName && (
                        <span> ({option.sectionName})</span>
                      )}
                    </span>
                  </div>
                </Box>
              )}
            />
          </div>
        </div>
      )}

      {/* Footer */}
      <RestaurantSelectionFooter
        restaurant={restaurant}
        selectData={selectData}
        menu={menu}
        section={section}
        foodItem={foodItem}
        linkActive={linkActive}
        setLinkActive={setLinkActive}
      />
    </div>
  );
}
