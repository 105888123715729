import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import '../Components/EditShareYourThoughtsModal.css';

export default function ImageCropComponents({
  typeOfCrop,
  croppedImageForOnyByOne,
  croppedImageForFourByFive,
  initialCroppedAreaPixels,
  setInitialCroppedAreaPixels,
  croppedAreaPixelsForOneByOne,
  setCroppedAreaPixelsForOneByOne,
  initialCroppedAreaPixelsForOneByOne,
  setInitialCroppedAreaPixelsForOneByOne,
  initialCroppedAreaPixelsForFourByFive,
  setInitialCroppedAreaPixelsForFourByFive,
  croppedAreaPixelsForFourByFive,
  setCroppedAreaPixelsForFourByFive,
  selectedImageIndex,
  setSelectedImageIndex,
  isImageSelected,
  editImageUrl,
  cropForOneByOne,
  setCropForOneByOne,
  zoomForOneByOne,
  setZoomForOneByOne,
  cropForFourByFive,
  setCropForFourByFive,
  zoomForFourByFive,
  setZoomForFourByFive,
}) {
  const updateInitialCroppedAreaPixels = (
    croppedAreaPixelsOneByOne,
    croppedAreaPixelsFourByFive,
    index
  ) => {
    setInitialCroppedAreaPixels((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        oneByOne: croppedAreaPixelsOneByOne || prevState[index]?.oneByOne,
        fourByFive: croppedAreaPixelsFourByFive || prevState[index]?.fourByFive,
      };
      return newState;
    });
  };

  const storeInitialCroppedAreaPixels = (croppedAreaPixels) => {
    if (typeOfCrop === '1:1') {
      setInitialCroppedAreaPixelsForOneByOne(croppedAreaPixels);
    } else {
      setInitialCroppedAreaPixelsForFourByFive(croppedAreaPixels);
    }
    updateInitialCroppedAreaPixels(
      typeOfCrop === '1:1' ? croppedAreaPixels : null,
      typeOfCrop === '4:5' ? croppedAreaPixels : null,
      selectedImageIndex
    );
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    if (typeOfCrop === '1:1') {
      setCroppedAreaPixelsForOneByOne(croppedAreaPixels);
      if (!initialCroppedAreaPixelsForOneByOne) {
        storeInitialCroppedAreaPixels(croppedAreaPixels);
      }
    } else {
      setCroppedAreaPixelsForFourByFive(croppedAreaPixels);
      if (!initialCroppedAreaPixelsForFourByFive) {
        storeInitialCroppedAreaPixels(croppedAreaPixels);
      }
    }
  };

  return (
    <div className='crop-container'>
      {typeOfCrop === '1:1' ? (
        croppedImageForOnyByOne && isImageSelected ? (
          <Cropper
            image={editImageUrl}
            crop={cropForOneByOne}
            zoom={zoomForOneByOne}
            aspect={1 / 1}
            onCropChange={setCropForOneByOne}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomForOneByOne}
          />
        ) : croppedImageForOnyByOne ? (
          <div className='original-image-container'>
            <img
              src={croppedImageForOnyByOne}
              alt='original'
              className='full-image'
            />
          </div>
        ) : (
          <Cropper
            image={editImageUrl}
            crop={cropForOneByOne}
            zoom={zoomForOneByOne}
            aspect={1 / 1}
            onCropChange={setCropForOneByOne}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomForOneByOne}
          />
        )
      ) : typeOfCrop === '4:5' ? (
        croppedImageForFourByFive && isImageSelected ? (
          <Cropper
            image={editImageUrl}
            crop={cropForFourByFive}
            zoom={zoomForFourByFive}
            aspect={4 / 5}
            onCropChange={setCropForFourByFive}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomForFourByFive}
          />
        ) : croppedImageForFourByFive ? (
          <div className='original-image-container'>
            <img
              src={croppedImageForFourByFive}
              alt='original'
              className='full-image'
            />
          </div>
        ) : (
          <Cropper
            image={editImageUrl}
            crop={cropForFourByFive}
            zoom={zoomForFourByFive}
            aspect={4 / 5}
            onCropChange={setCropForFourByFive}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomForFourByFive}
          />
        )
      ) : (
        <div className='original-image-container'>
          <img src={editImageUrl} alt='original' className='full-image' />
        </div>

        // <Cropper
        //   image={editImageUrl}
        //   crop={{ x: 0, y: 0 }}
        //   zoom={1}
        //   aspect={undefined}
        //   // onCropChange={undefined}
        //   // onZoomChange={0}
        // />
      )}
    </div>
  );
}
