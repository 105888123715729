import React, { useState } from 'react';
import './SharePostDisplay.css';
import pkbImg from './../../../Assets/Image/PKB.png';
import leoImg from './../../../Assets/Image/Leo.png';
import { Button, Modal } from 'react-bootstrap';
import CommentDisplay from '../CommentDisplay/CommentDisplay';
import ReplyComponent from '../ReplyComponent/ReplyComponent';
import bannerImg from './../../../Assets/Image/banner.jpg';
import p1 from './../../../Assets/Image/1 44.png';
import p2 from './../../../Assets/Image/1 51.png';
import p3 from './../../../Assets/Image/1 52.png';
import p4 from './../../../Assets/Image/1 54 (1).png';
import p5 from './../../../Assets/Image/1 54.png';
import p6 from './../../../Assets/Image/1 56.png';
import p7 from './../../../Assets/Image/1 57 (1).png';
import p8 from './../../../Assets/Image/1 57.png';
import p9 from './../../../Assets/Image/1 89.png';
import p10 from './../../../Assets/Image/1 44 (1).png';

import commentIcon from './../../../Assets/Icons/speech-bubble.png';
import shareIcon from './../../../Assets/Icons/share (1).png';
import likeIcon from './../../../Assets/Icons/like.png';
import heart from './../../../Assets/Icons/heart.png';
import instBookmark from './../../../Assets/Icons/bookmark-white.png';
import more from './../../../Assets/Icons/more2.png';

const SharePostDisplay = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [show, setShow] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModalClose = () => {
    if (showModal) {
      closeModal();
    }
  };
  const hideModalInfo = () => {
    window.$('#postModal').modal('hide');
    setShow(false);
  };
  const showModalInfo = (props) => {
    console.log('PostDisplay: ' + props);
    setSelectedImage(props);
    window.$('#postModal').modal('show');
    setShow(true);
  };
  const foodItems = [
    { name: 'Burger', image: p1 },
    { name: 'Pizza', image: p2 },
    { name: 'Coffee', image: p3 },
    { name: 'Chocolate', image: p4 },
    { name: 'Shawarma', image: p5 },
    { name: 'Sushi', image: p6 },
    { name: 'Pasta', image: p7 },
    { name: 'Ice cream', image: p8 },
    { name: 'Sandwich', image: p9 },
    { name: 'Fruit Salad', image: p10 },
  ];
  const visibleItems = 2;
  const remainingItems = foodItems.length - visibleItems;

  return (
    <div>
      <div className='card PostCard'>
        <div className='card-body' onClick={handleModalClose}>
          <div className='d-flex align-items-center postHeader'>
            <img src={pkbImg} alt='' className='postOwnerImg' />
            <div className='col'>
              <div className='d-flex  post-share'>
                <h6>Pot Kettle Black</h6>
                <h6>shared Leo's posts</h6>
              </div>

              {/* <div className=" d-flex" style={{ gap: '5px' }}>
                                {foodItems.slice(0, visibleItems).map((item, index) => (
                                    <div key={index} className="foodItem">
                                        
                                        <span> {item.name}, </span>
                                    </div>
                                ))}
                                {remainingItems > 0 && (
                                    <span>
                                        and
                                        <span className="moreItemsLink" onClick={openModal}>
                                            {`  ${remainingItems} more`}
                                        </span>
                                    </span>

                                )}
                            </div> */}
            </div>
            <Modal
              className='moreItemModal'
              show={showModal}
              onHide={closeModal}
              centered
              backdrop='static'
              keyboard={false}
            >
              <Modal.Header closeButton style={{ borderBottom: '0' }}>
                <Modal.Title>{foodItems.length} items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  {foodItems.map((item, index) => (
                    <li
                      key={index}
                      className='itemLi d-flex align-items-center'
                      style={{ margin: '0 0 15px' }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        className='foodImage rounded-circle me-3'
                        style={{ width: '40px', height: '40px' }}
                      />
                      <span style={{ fontSize: '20px' }}>{item.name}</span>
                      <Button
                        variant=''
                        className='itemButton ms-auto fw-bold'
                        onClick={closeModal}
                      >
                        View Item
                      </Button>
                    </li>
                  ))}
                </ul>
              </Modal.Body>
              {/* <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer> */}
            </Modal>
          </div>
          <div className='post '>
            <h4 className='ml-5'>
              Hey, which movie you will watch with this dish?
            </h4>
          </div>
          <div className='divPost'>
            <div className='postImg sharepostImg d-flex justify-content-center'>
              <img
                src={bannerImg}
                alt=''
                onClick={() => showModalInfo(bannerImg)}
              />
              {/* <Postimages /> */}
            </div>
            <div className='d-flex align-items-center postHeader'>
              <img src={leoImg} alt='' className='postOwnerImg' />
              <div className='col'>
                <div className='d-flex  post-share'>
                  <h6>Leo </h6>
                  <h7></h7>
                </div>
              </div>
            </div>

            <div className='post '>
              <h4 className='ml-5'>What’s the best movie for this dish?</h4>
            </div>

            {/* <div className='d-flex align-items-center ms-3 mb-3' style={{ gap: "8px" }}>
                            <div>
                                <img src={likeIcon} className='postRectImage' />
                            </div>

                            <h5 style={{ marginBottom: "0" }}> <b>Likes 20</b></h5>
                        </div> */}
          </div>
          <div className='postLikeDislike'>
            {/* <hr></hr> */}
            {/* <div className='d-flex justify-content-between shared-MainPostLike'>

                            <div className='d-flex justify-content-between align-items-center ms-1'>
                                <div>
                                    <img src={likeIcon} className='postRectImage' />
                                </div>
                                <h5> Likes 20</h5>
                            </div>
                            <div className='d-flex justify-content-between align-items-center ms-1'>
                                <div>
                                    <img src={commentIcon} className='postRectImage' />
                                </div>
                                <h5> Reply</h5>
                            </div>
                            <div className='d-flex justify-content-between align-items-center ms-1'>
                                <div>
                                    <img src={shareIcon} className='postRectImage' />
                                </div>
                                <h5> Share</h5>
                            </div>
                        </div> */}

            <div className='d-flex justify-content-between shared-MainPostLike'>
              <div className='d-flex flex-row'>
                <div style={{ marginRight: '1rem' }}>
                  <img src={heart} className='postRectImage' />
                </div>

                <div style={{ marginRight: '1rem' }}>
                  <img src={commentIcon} className='postRectImage' />
                </div>

                <div style={{ marginRight: '1rem' }}>
                  <img src={shareIcon} className='postRectImage' />
                </div>
              </div>

              <img
                src={instBookmark}
                alt='bookmark'
                style={{ width: '1.1rem', height: '1.3rem', marginTop: '3px' }}
              />
            </div>

            <hr style={{ color: '#a39d9d' }}></hr>
            <hr></hr>
          </div>

          <div className='postImg postComment'>
            <CommentDisplay></CommentDisplay>
          </div>
          <div className='postReply'>
            <ReplyComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePostDisplay;
