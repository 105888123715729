import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog } from '@mui/material';
import axios from 'axios';
import CustomImageSlider from './CustomImageSlider';
import { Button } from 'react-bootstrap';
import './OrderTableDialog.css';
import jwtDecode from 'jwt-decode';
import { ToastContainer, toast } from "react-toastify";

export default function OrderTableDialog({ show, onHide, restaurantId, preSelectedTable, setParentSelectedTable ,SetselecteOk,selectOk}) {
  const [tableInfos, setTableInfos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTable, setSelectedTable] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);

  const [hideAvailableTables, setHideAvailableTables] = useState(false);

  const infoContainerRef = useRef(null);
  const topSectionRef = useRef(null);

  const bottomContainerRef = useRef(null);

  const apiAddress = process.env.REACT_APP_SECRET;

  // useEffect(() => {
  //   if (show) {
  //     setLoading(true);
  //     axios
  //       .get(
  //         `${apiAddress}/api/RestaurantTable/GetAllRestaurantTableInfosByRestaurantId?restaurantId=${restaurantId}`
  //       )
  //       .then((response) => {
  //         console.log('delivery address: ', response.data);
  //         const tables = response.data;
  //         setTableInfos(tables);
  //         const selected = tables.find(table => table.tableId === preSelectedTable.tableId);
  //         setSelectedTable(selected);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching table info:', error);
  //         setLoading(false);
  //       });
  //   }
  // }, [show, restaurantId]);
  // useEffect(() => {
  //   if (show) {
  //     setLoading(true);
  //     axios
  //       .get(
  //         `${apiAddress}/api/RestaurantTable/GetAllRestaurantTableInfosByRestaurantId?restaurantId=${restaurantId}`
  //       )
  //       .then((response) => {
  //         const tables = response.data;
  //         setTableInfos(tables);
  
  //         // Highlight the `SetselecteOk` table when dialog is reopened
  //         const selected = tables.find((table) => table.tableId === SetselecteOk?.tableId);
  //         setSelectedTable(selected); // Set the highlighted table
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching table info:', error);
  //         setLoading(false);
  //       });
  //   }
  // }, [show, restaurantId, SetselecteOk]); // Depend on `SetselecteOk` to reset on dialog open
  
useEffect(() => {
  if (show) {
    setLoading(true);
    axios
      .get(
        `${apiAddress}/api/RestaurantTable/GetAllRestaurantTableInfosByRestaurantId?restaurantId=${restaurantId}`
      )
      .then((response) => {
        const tables = response.data;
        setTableInfos(tables);

        // Highlight the `selectOk` table when the dialog is opened
        const selected = tables.find((table) => table.tableId === selectOk?.tableId);
        setSelectedTable(selected); // Set the highlighted table
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching table info:', error);
        setLoading(false);
      });
  }
}, [show, restaurantId, selectOk]); // Depend on `SetselecteOk` to reset on dialog open

console.log(selectOk)
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = useCallback(() => {
    if (bottomContainerRef.current) {
      const currentScrollPos = bottomContainerRef.current.scrollTop;

      // Logic to hide/show available tables based on scrolling direction
      if (prevScrollPos > currentScrollPos && currentScrollPos < 50) {
        // Scrolling up - show available tables
        setHideAvailableTables(false);
      } else if (prevScrollPos < currentScrollPos && currentScrollPos > 50) {
        // Scrolling down and scrolled more than 50px - hide available tables
        setHideAvailableTables(true);
      }

      // Update the previous scroll position
      setPrevScrollPos(currentScrollPos);
    }
  }, [prevScrollPos]);

  useEffect(() => {
    const bottomContainer = bottomContainerRef.current;

    if (bottomContainer) {
      bottomContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (bottomContainer) {
        bottomContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  // const handleScroll = useCallback(() => {
  //   if (bottomContainerRef.current) {
  //     const scrollTop = bottomContainerRef.current.scrollTop;
  //     setHideAvailableTables(scrollTop > 50); // Hide when scroll position exceeds 50px
  //   }
  // }, []);

  // useEffect(() => {
  //   const bottomContainer = bottomContainerRef.current;

  //   if (bottomContainer) {
  //     bottomContainer.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     if (bottomContainer) {
  //       bottomContainer.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [handleScroll]);

  console.log('bottomContainerRefCurrent__', hideAvailableTables);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTableInfos = tableInfos.filter((table) =>
    table.tableName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handletable=(table)=>{
    setSelectedTable(table);
    setParentSelectedTable(table);
  }
  const handleCardClick = async (table) => {
    // setSelectedTable(Table);
    // setParentSelectedTable(Table);
    SetselecteOk(table);
    const token = localStorage.getItem("gotasteClienttoken");
    const decoded = jwtDecode(token);

    try {
      const response = await axios.patch(`${process.env.REACT_APP_SECRET}/api/Cart/UpdateCartTableDetails?customerId=${decoded.UserId}&TableId=${table.tableId}&TableName=${table.tableName}`);

      if(response.data){
        toast.success(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTouchStart = (e) => {
    const touchY = e.touches[0].clientY;
    setStartY(touchY);
    // Check if the initial touch is on the gray-bar-container
    const target = e.target;
    if (target.closest('.gray-bar-container')) {
      setIsDraggingAllowed(true);
      setIsDragging(true);
    }
  };

  const handleTouchMove = (e) => {
    if (isDragging && isDraggingAllowed) {
      const touchY = e.touches[0].clientY;
      const deltaY = touchY - startY;

      const containerHeight = infoContainerRef.current
        ? infoContainerRef.current.offsetHeight
        : 0;
      const screenHeight = window.innerHeight;

      // Set a max drag up limit to prevent dragging out of bounds
      const maxDragUp = screenHeight - containerHeight;

      // Clamp deltaY between 0 (no dragging) and the max allowed drag up
      const clampedDeltaY = Math.max(maxDragUp, Math.min(0, deltaY));

      if (infoContainerRef.current) {
        // Disable transitions while dragging for real-time responsiveness
        infoContainerRef.current.style.transition = 'none';
        infoContainerRef.current.style.transform = `translateY(${clampedDeltaY}px)`;
      }

      if (topSectionRef.current) {
        // Smooth the top section movement
        topSectionRef.current.style.transition = 'none';
        topSectionRef.current.style.opacity = 1 + clampedDeltaY / 100;
        topSectionRef.current.style.transform = `translateY(${
          clampedDeltaY / 2
        }px)`;
      }

      // Update currentY for use in handleTouchEnd
      setCurrentY(touchY);
    }
  };

  const handleTouchEnd = () => {
    if (infoContainerRef.current && isDraggingAllowed) {
      const deltaY = currentY - startY;
      const containerHeight = infoContainerRef.current
        ? infoContainerRef.current.offsetHeight
        : 0;
      const screenHeight = window.innerHeight;
      const maxDragUp = screenHeight - containerHeight;
      const threshold = containerHeight / 2;

      // Add back the smooth scrolling effect after the touch event ends
      infoContainerRef.current.style.transition = 'transform 0.5s ease-out';

      if (deltaY < -threshold) {
        // Snap to the top, but within limits
        infoContainerRef.current.style.transform = `translateY(${Math.max(
          maxDragUp,
          -screenHeight + containerHeight
        )}px)`;

        if (topSectionRef.current) {
          topSectionRef.current.style.transition =
            'opacity 0.3s ease-out, transform 0.2s ease-out';
          topSectionRef.current.style.opacity = '0';
          topSectionRef.current.style.transform = 'translateY(-50%)';
        }
      } else {
        // Snap back to original position
        infoContainerRef.current.style.transform = 'translateY(0)';

        if (topSectionRef.current) {
          topSectionRef.current.style.transition =
            'opacity 0.3s ease-out, transform 0.5s ease-out';
          topSectionRef.current.style.opacity = '1';
          topSectionRef.current.style.transform = 'translateY(0)';
        }
      }
    }

    setIsDragging(false);
    setIsDraggingAllowed(false);
  };

  // const sortedTableInfos = filteredTableInfos.sort((a, b) => {
  //   if (SetselecteOk && a.tableId === SetselecteOk.tableId) return -1; // Previously selected table goes first
  //   if (SetselecteOk && b.tableId === SetselecteOk.tableId) return 1;
  //   if (selectedTable && a.tableId === selectedTable.tableId) return -1; // Selected table goes first
  //   if (selectedTable && b.tableId === selectedTable.tableId) return 1;
  //   return 0; // Keep the order for other tables
  // });
  
  const sortedTableInfos = filteredTableInfos.sort((a, b) => {
    if (selectOk && a.tableId === selectOk.tableId) return -1; // Highlight `selectOk` first
    if (selectOk && b.tableId === selectOk.tableId) return 1;
    if (selectedTable && a.tableId === selectedTable.tableId) return -1; // Then highlight the `selectedTable`
    if (selectedTable && b.tableId === selectedTable.tableId) return 1;
    return 0; // Default order for others
  });
  

  return (
    <Dialog
      open={show}
      onClose={onHide}
      maxWidth='lg'
      className='custom-table-dialog'
      fullWidth
    >
      <ToastContainer />
      <div className='modal-content-wrapper'>
        <Button variant='light' className='close-button' onClick={() =>{ setParentSelectedTable(null);onHide();}}>
          &times;
        </Button>
        <div
          className='top-section table-slider-container position-relative'
          ref={topSectionRef}
        >
          <CustomImageSlider
            images={
              selectedTable
                ? [
                    selectedTable.tableImagePath,
                    'https://gotaste.uk/static/media/image%2035.3916b0ba52de526d139a.png',
                    'https://img.freepik.com/premium-photo/delicious-steak-photo-food-photography_929907-237.jpg?w=740',
                  ]
                : [
                    'https://images.unsplash.com/photo-1504674900247-0877df9cc836?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                  ]
            }
          />
        </div>
        <div
          className='info-container'
          ref={infoContainerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {selectedTable && (
                <div className='gray-bar-container'>
                  <div className='gray-bar'></div>
                  <div>
                    <h4 className='bold-text'>{selectedTable.tableName}</h4>
                    <p className='mb-2'>{selectedTable.description}</p>
                    <p>
                      Available seat: {selectedTable.totalChairUnderThisTable}
                    </p>
                  </div>
                  <hr />
                </div>
              )}
              <div
                className={`bottom-container ${
                  hideAvailableTables ? 'hidden' : ''
                }`}
                ref={bottomContainerRef}
                onScroll={handleScroll}
              >
                <div className='d-flex available-tables flex-column justify-content-between mb-3'>
                  <h6 className='bold-text'>Available Tables</h6>
                  <input
                    type='text'
                    placeholder='Search Table'
                    className='table-search-box rounded-4 ps-2 py-1 w-100'
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className='table-card-container'>
                  {/* {filteredTableInfos.length > 0 ? (
                    filteredTableInfos.map((table) => (
                      <div key={table.tableId}>
                        <div
                          className={`table-card text-cursor-pointer d-flex ${
                            selectedTable?.tableId === table.tableId
                              ? 'selected-table-card'
                              : ''
                          }`}
                          onClick={() => handletable(table)}
                        >
                          <div className='table-card-body w-75'>
                            <h5 className='table-card-title'>
                              {table.tableName}
                            </h5>
                            <p className='table-card-text mb-0'>
                              {table.description}
                            </p>
                            <p className='table-card-text mb-0'>
                              Available seat: {table.totalChairUnderThisTable}
                            </p>
                          </div>
                          <div className='table-card-img-container'>
                            <img
                              src={table.tableImagePath}
                              className='table-card-img'
                              alt={table.tableName}
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <h6 className='text-center'>No Table Data Available</h6>
                  )} */}

                  {sortedTableInfos.length > 0 ? (
                    sortedTableInfos.map((table) => (
                      <div key={table.tableId}>
                        {/* <div
                          className={`table-card text-cursor-pointer d-flex ${
                            selectedTable?.tableId === table.tableId ? 'selected-table-card' : ''
                          }`}
                          onClick={() => handletable(table)}
                        > */}
                        <div
                            className={`table-card text-cursor-pointer d-flex ${
                              selectedTable?.tableId === table.tableId
                                ? 'selected-table-card' // Highlight class
                                : ''
                            }`}
                            onClick={() => handletable(table)}
                          >

                          <div className='table-card-body w-75'>
                            <h5 className='table-card-title'>{table.tableName}</h5>
                            <p className='table-card-text mb-0'>{table.description}</p>
                            <p className='table-card-text mb-0'>
                              Available seat: {table.totalChairUnderThisTable}
                            </p>
                          </div>
                          <div className='table-card-img-container'>
                            <img
                              src={table.tableImagePath}
                              className='table-card-img'
                              alt={table.tableName}
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <h6 className='text-center'>No Table Data Available</h6>
                  )}


                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                    <button className='btn btn-dark Okbtn-placeOrder'
                    onClick={() =>{
                      handleCardClick(selectedTable);
                      }}
                    >
                      Ok
                    </button>

                    <button
                    className='btn btn-dark Okbtn-placeOrder'
                    onClick={() => {
                      setParentSelectedTable(null); // Reset the selectedTable state
                      onHide(); // Call the existing onHide function
                    }}
                  >
                    Cancel
                  </button>

                  </div>

                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
}
