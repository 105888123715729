import React from 'react';

const StatusCapsule = ({ status }) => {
  return (
    <div className="searchLabel" style={styles.container}>
      <span style={styles.text}>{status}</span>
    </div>
  );
};

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#2c3e50',
    borderRadius: '20px',
    padding: '0.3rem 0.4rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: 'fit-content'
  },
  text: {
    color: 'white',
    fontSize: '.6rem',
    fontWeight: '600',
  },
};

export default StatusCapsule;
