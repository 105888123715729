import { SEARCH_ON_MENU } from "../../actions/types";

const initialState = {
    option: "globall",
}

const searchOptionReducer = (state = initialState, action) => 
{
    switch(action.type) {
        case SEARCH_ON_MENU:
            return {
                ...state,
                option: action.payload,
            }
        default:
            return state;
    }
}

export default searchOptionReducer;