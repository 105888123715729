import React, { useCallback, useEffect, useRef, useState } from 'react';
import './EditShareYourThoughtsModal.css';
import ModalHeader from '../ModalComponents/ModalHeader';
import ModalContent from '../ModalComponents/ModalContent';
import ModalFooter from '../ModalComponents/ModalFooter';
import { ToastContainer } from 'react-toastify';
import getCroppedImg from '../../../Pages/Restaurant/ShareYourThoughts/ShowCroppedImage/ShowCroppedImage';
import ConfirmationModal from '../../../Pages/Restaurant/components/ConfirmationModal';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setPostData } from '../../../actions/actions';
import { uploadFiles } from '../../../FileUploader/FileUploader';
import loadingImage from '../../../Assets/Image/loading.gif';

const EditShareYourThoughtsModal = ({
  show,
  setShow,
  showEditShareModalInfo,
  hideEditShareModalInfo,
  data,
}) => {
  const apiAddress = process.env.REACT_APP_SECRET;
  const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');
  const [step, setStep] = useState(0);
  const restaurantId = data?.postRestaurantId;

  // Caption field and functions
  const [comments, setComments] = useState('');

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  console.log('data___', data);

  // Modal states & Functions
  const [showFirstModal, setShowFirstModal] = useState(true);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);

  const handleFilterModal = (step) => {
    console.log('step____', step);
    setShowFirstModal(false);
    setShowSecondModal(false);
    setShowThirdModal(true);
    setStep((prevStep) => Math.max(prevStep, step));
  };

  // Image states & Functions
  const [editImage, setEditImage] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState('');
  const [images, setImages] = useState([]);
  const [uploadfiles, setUploadFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [typeOfCrop, setTypeOfCrop] = useState('1:1');
  const [croppedImageForOnyByOne, setCroppedImageForOnyByOne] = useState(null);
  const [croppedImageForFourByFive, setCroppedImageForFourByFive] =
    useState(null);
  const [croppedImages, setCroppedImages] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [cropForOneByOne, setCropForOneByOne] = useState({ x: 0, y: 0 });
  const [zoomForOneByOne, setZoomForOneByOne] = useState(1);
  const [cropForFourByFive, setCropForFourByFive] = useState({ x: 0, y: 0 });
  const [zoomForFourByFive, setZoomForFourByFive] = useState(1);
  const [croppedAreaPixelsForOneByOne, setCroppedAreaPixelsForOneByOne] =
    useState(null);
  const [croppedAreaPixelsForFourByFive, setCroppedAreaPixelsForFourByFive] =
    useState(null);
  const [
    initialCroppedAreaPixelsForOneByOne,
    setInitialCroppedAreaPixelsForOneByOne,
  ] = useState(null);
  const [
    initialCroppedAreaPixelsForFourByFive,
    setInitialCroppedAreaPixelsForFourByFive,
  ] = useState(null);
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [editImageUrl, setEditImageUrl] = useState('');

  const [open, setOpen] = useState(false);
  const [discard, setDiscard] = useState(false);

  useEffect(() => {
    window.$('#editShareYourThoughtsModal').modal('show');
  }, [show]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateCroppedImages = (
    croppedImageOneByOne,
    croppedImageFourByFive,
    index
  ) => {
    setCroppedImages((prevState) => {
      const newState = [...prevState];
      const prevCroppedImage = newState[index];

      // Preserve the existing value if cropping for the other aspect ratio
      const updatedState = {
        oneByOne: croppedImageOneByOne || prevCroppedImage?.oneByOne,
        fourByFive: croppedImageFourByFive || prevCroppedImage?.fourByFive,
      };

      newState[index] = updatedState;
      return newState;
    });
  };

  const showCroppedImage = useCallback(async () => {
    setEditLoading(true);
    try {
      if (typeOfCrop === '1:1') {
        const croppedImage = await getCroppedImg(
          editImageUrl,
          croppedAreaPixelsForOneByOne
        );
        setCroppedImageForOnyByOne(croppedImage);
        updateCroppedImages(croppedImage, null, selectedImageIndex);
        setIsImageSelected(false);
      } else {
        const croppedImage = await getCroppedImg(
          editImageUrl,
          croppedAreaPixelsForFourByFive
        );
        setCroppedImageForFourByFive(croppedImage);
        updateCroppedImages(null, croppedImage, selectedImageIndex);
        setIsImageSelected(false);
      }
      setEditLoading(false);
    } catch (e) {
      setEditLoading(false);
      console.error(e);
    }
  }, [croppedAreaPixelsForOneByOne, croppedAreaPixelsForFourByFive]);

  const resetCroppedAreaPixels = () => {
    if (typeOfCrop === '1:1') {
      const initialPixels =
        initialCroppedAreaPixels[selectedImageIndex]?.oneByOne || null;
      setCroppedAreaPixelsForOneByOne(initialPixels);

      setCroppedImageForOnyByOne(null);

      setCroppedImages((prevState) => {
        const newState = [...prevState];
        newState[selectedImageIndex] = {
          ...newState[selectedImageIndex],
          oneByOne: null,
        };
        return newState;
      });
    } else {
      const initialPixels =
        initialCroppedAreaPixels[selectedImageIndex]?.fourByFive || null;
      setCroppedAreaPixelsForFourByFive(initialPixels);

      setCroppedImageForFourByFive(null);

      setCroppedImages((prevState) => {
        const newState = [...prevState];
        newState[selectedImageIndex] = {
          ...newState[selectedImageIndex],
          fourByFive: null,
        };
        return newState;
      });
    }
  };

  const handleEditImage = (image, index) => {
    console.log('edit modal____');
    if (!croppedImages[index]) {
      setCropForOneByOne({ x: 0, y: 0 });
      setZoomForOneByOne(1);
      setCropForFourByFive({ x: 0, y: 0 });
      setZoomForFourByFive(1);
      setTypeOfCrop('1:1');
      setCroppedAreaPixelsForOneByOne(null);
      setCroppedAreaPixelsForFourByFive(null);
      setInitialCroppedAreaPixelsForOneByOne(null);
      setInitialCroppedAreaPixelsForFourByFive(null);
      setCroppedImageForOnyByOne(null);
      setCroppedImageForFourByFive(null);
    } else {
      setCroppedImageForOnyByOne(croppedImages[index].oneByOne);
      setCroppedImageForFourByFive(croppedImages[index].fourByFive);
    }

    setEditImageUrl(image.url);
    setShowFirstModal(false);
    setShowSecondModal(true);
    setShowThirdModal(false);
    setEditImage(true);
    setSelectedImageIndex(index);
  };

  const handleCancelImage = (id, index) => {
    setImages((prevImages) => prevImages.filter((item) => item.id !== id));

    setCroppedImages((prevImages) =>
      prevImages.filter((item, idx) => idx !== index)
    );

    setInitialCroppedAreaPixels((prevInitialCroppedAreaPixels) =>
      prevInitialCroppedAreaPixels.filter((item, idx) => idx !== index)
    );

    setInitialData((prevData) => prevData.filter((item) => item.id !== id));
    setUploadFiles((prevUploadFiles) =>
      prevUploadFiles.filter((item) => item.id !== id)
    );
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newEvents = [];

    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      newEvents.push(files[i]);

      const reader = new FileReader();
      reader.onloadend = () => {
        const id = `id${Date.now()}_${i}`;
        newImages.push({ id, url: reader.result, selected: false });

        if (newImages.length === files.length) {
          setImages([...images, ...newImages]);
          setInitialData([...images, ...newImages]);
          resetFileInput();
        }
      };
      reader.readAsDataURL(files[i]);
    }

    setUploadFiles((prevUploadFiles) => [...prevUploadFiles, ...newEvents]);
  };

  // Restaurant Inforamtions

  const [restaurant, setRestaurant] = useState([]);
  const [menu, setMenu] = useState([]);
  const [section, setSection] = useState([]);
  const [foodItem, setFoodItem] = useState([]);

  const [restaurantDatas, setRestaurantDatas] = useState([]);
  const [selectedRestaurantDatas, setSelectedRestaurantDatas] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);

  const [sectionDatas, setSectionDatas] = useState([]);
  const [selectedSectionDatas, setSelectedSectionDatas] = useState([]);
  const [selectedMenuDatas, setSelectedMenuDatas] = useState([]);

  const [foodItemDatas, setFoodItemDatas] = useState([]);
  const [selectFoodItemDatas, setSelectFoodItemDatas] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const isRestaurantDropdownVisible = step >= 0;
  const isMenuDropdownVisible = step >= 1;
  const isSectionDropdownVisible = step >= 2;
  const isFoodItemDropdownVisible = step >= 3;

  const [linkActive, setLinkActive] = useState(true);

  const loadRestaurantName = () => {
    var decodedData = {};
    if (gotasteClienttoken != null) {
      try {
        decodedData = jwtDecode(gotasteClienttoken);
      } catch (error) {}
    }

    axios
      .get(
        apiAddress +
          `/api/Restaurant/getrestaurantdetailsbyrestaurantparentidanduserid/${decodedData.UserId}/${restaurantId}`
      )
      .then((response) => {
        var list = [];
        response.data.map((dt, i) => {
          var obj = {
            id: dt.id,
            title: dt.name,
          };
          list.push(obj);
        });
        setRestaurantDatas(list);
      });
  };

  const loadMenuData = async (props) => {
    var list = [];
    var Alllist = [];
    props.map(async (d, i) => {
      const response = await axios.get(apiAddress + '/api/Menu/' + d.id);
      console.log(d.id);
      response.data.map((dt, i) => {
        var obj = {
          id: dt.menuId,
          title: dt.menuName,
          img: dt.logoUrl,
          restaurantName: dt.restaurantName,
          restaurantId: dt.restaurantId,
        };
        list.push(obj);
        dt.MenuRestaurantMapperLists = [];
        Alllist.push(dt);
      });
      // Alllist = response.data;
      // handleFilterModal(1);
    });
    setAllMenuData(Alllist);
    setMenuData(list);
  };

  const loadSections = (props) => {
    if (props != undefined && props.length > 0) {
      var list = [];
      props.map(async (d, i) => {
        axios
          .get(
            apiAddress + '/api/Section/getallsectionbasedonmenuid?id=' + d.id
          )
          .then((response) => {
            response.data.map((dt, i) => {
              var obj = {
                code: dt.id,
                icon: dt.iconUrl,
                label: dt.keywordName,
                menuName: d.title,
                restaurantId: d.restaurantId,
                menuId: d.id,
                sectionId: dt.id,
              };
              list.push(obj);
            });
          });
      });
      setSectionDatas(list);
    } else {
      setSectionDatas([]);
    }
  };

  const loadFoodItems = (props) => {
    if (props !== undefined && props.length > 0) {
      let list = [];
      props.map(async (d, i) => {
        axios
          .get(
            apiAddress +
              '/api/Section/getallfooditembysectionid?sectionId=' +
              d.code +
              '&fromtype=1'
          )
          .then((response) => {
            response.data.map((dt, i) => {
              const obj = {
                id: dt.id,
                title: dt.title,
                img: dt.imageUrl,
                restaurantName: dt.restaurantName,
                menuName: dt.menu,
                sectionName: d.label,
                restaurantId: d.restaurantId,
                menuId: d.menuId,
              };
              list.push(obj);
            });
            // Update the state outside of the loop
            setFoodItemDatas(list);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  };

  useEffect(() => {
    loadRestaurantName();
  }, [restaurantId]);

  useEffect(() => {
    loadMenuData(selectedRestaurantDatas);
    if (restaurant.length > 0) {
      loadMenuData(restaurant);
    }
  }, [selectedRestaurantDatas, restaurant, selectedMenuDatas]);

  useEffect(() => {
    loadSections(selectedMenuDatas);
    if (menu.length > 0) {
      loadSections(menu);
    }
  }, [selectedMenuDatas, menu, selectedSectionDatas, section]);

  useEffect(() => {
    loadFoodItems(selectedSectionDatas);
    if (section.length > 0) {
      loadFoodItems(section);
    }
  }, [selectedSectionDatas, section, selectFoodItemDatas]);

  const handleRemoveData = (restaurantData) => {
    //Remove Restaurant
    const updatedRestaurants = restaurant?.filter(
      (r) => r.id !== restaurantData.id
    );
    setRestaurant(updatedRestaurants);

    // Remove associated menus
    const updatedMenus = menu.filter(
      (menu) => menu.restaurantId !== restaurantData.id
    );
    setMenu(updatedMenus);

    //Remove associated section
    const updatedSections = section.filter(
      (section) => section.restaurantId !== restaurantData.id
    );
    setSection(updatedSections);

    //Remove associated fooditem
    const updatedFoods = foodItem.filter(
      (food) => food.restaurantId !== restaurantData.id
    );
    setFoodItem(updatedFoods);
  };

  const handleRemoveFoodItem = (id) => {
    const updatedData = selectFoodItemDatas?.filter(
      (item) => item.foodItemId !== id
    );
    setSelectFoodItemDatas(updatedData);
  };

  const handleRemoveMenuItem = (id) => {
    const updatedData = selectedMenuDatas.filter((item) => item.id !== id);
    setSelectedMenuDatas(updatedData);
  };

  const handleRemoveSectionItem = (id) => {
    const updatedData = selectedSectionDatas.filter(
      (item) => item.sectionId !== id
    );
    setSelectedSectionDatas(updatedData);
  };

  const handleRemoveRestaurantItem = (id) => {
    const updatedData = selectedRestaurantDatas.filter(
      (item) => item.id !== id
    );
    setSelectedRestaurantDatas(updatedData);
  };

  const handleClearAll = () => {
    setShow(false);
    setShowFirstModal(false);
    setShowSecondModal(false);
    setShowThirdModal(false);

    setInitialData([]);
    setComments('');
    // Reset initial data

    setRestaurantDatas([]);
    setSelectedRestaurantDatas([]);
    setMenuData([]);
    setAllMenuData([]);
    setSectionDatas([]);
    setSelectedSectionDatas([]);
    setSelectedMenuDatas([]);
    setFoodItemDatas([]);
    setSelectFoodItemDatas([]);

    setEditImage(null);
    setEditImageUrl('');
    setImages([]);
    setUploadFiles([]);
    setCroppedImages([]);

    setDiscard(false);
  };

  const handleCloseAll = () => {
    handleOpen();
  };

  const handleConfirm = () => {
    // Add logic to discard changes here
    setDiscard(true);
    handleClearAll();
    handleClose();
    hideEditShareModalInfo();
  };

  useEffect(() => {
    setShowFirstModal(true);
    if (show && data) {
      setComments(data?.description);

      const images =
        data?.postInfoImageMapperList?.map((item, index) => ({
          id: `id${Date.now()}_${index}`,
          url: item.imageUploadpath,
          selected: false,
        })) || [];

      setImages(images);
      setInitialData(images);

      // Check and set the tagged data based on hierarchy
      if (data?.postFoodItemTaggedMapperList) {
        setSelectFoodItemDatas(data.postFoodItemTaggedMapperList);
        setLinkActive(true);
      } else if (data?.postTaggedSectionWithDetailsList) {
        setSelectedSectionDatas(data.postTaggedSectionWithDetailsList);
        setLinkActive(true);
      } else if (data?.postTaggedMenuWithDetailsList) {
        setSelectedMenuDatas(data.postTaggedMenuWithDetailsList);
        setLinkActive(true);
      } else if (data?.postTaggedRestaurantWithDetailsList) {
        setSelectedRestaurantDatas(data.postTaggedRestaurantWithDetailsList);
        setLinkActive(true);
      }
    }
  }, [show, data]);

  // Edit handle save related functions and states
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [allPostCropImageTypes, setAllPostCropImageTypes] = useState([]);
  const dispatch = useDispatch();
  const [originalImages, setOriginalImages] = useState([]);

  const fetchPostData = async (userId) => {
    if (userId && restaurantId) {
      await axios
        .get(
          apiAddress +
            `/api/Post/GetTopPostWithCommentReactByUserIdAndResaurantId?userId=${userId}&restaurantId=${restaurantId}&skip=0&take=12`
        )
        .then((response) => {
          dispatch(setPostData(response.data));
        })
        .catch((error) => {
          console.log('my error', error);
        });
    }
  };

  const getAllPostCropImageTypes = async () => {
    try {
      const response = await axios.get(
        `${apiAddress}/api/Post/GetAllPostCropImageTypes`
      );
      setAllPostCropImageTypes(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadDocument(data) {
    try {
      const resUrl = await uploadFiles(data);
      return resUrl[0];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const handleSave = async () => {
    setLoading(true);
    var decodedData = {};
    if (gotasteClienttoken != null) {
      try {
        decodedData = jwtDecode(gotasteClienttoken);
      } catch (error) {}
    }

    const cropData = await getAllPostCropImageTypes();
    console.log('post image obj: ', data);

    const postObj = {
      postInfoId: data.postInfoId,
      postInfoMaster: {
        id: data.postInfoId || 'N/A',
        title: comments || 'N/A',
        description: comments || 'N/A',
        postContent: comments || 'N/A',
        restaurantId: data.postInfoRestaurantId || 'N/A',
        isEdited: true,
        link: data.link || 'N/A',
        url: data.url || 'N/A',
        privacy: 0,
        creationId: data.creationId || 'N/A',
        modificationId: decodedData.UserId || 'N/A',
      },
      post: {
        id: data.postId || 'N/A',
        postInfoId: data.postInfoId || 'N/A',
        restaurantId: data.postRestaurantId || 'N/A',
        isShared: data.isShared || false,
        viewed: data.viewed || 0,
        isPinedPost: data.isPinedPost || true,
        isPostInfoDeleted: data.isPostInfoDeleted || true,
        pinOrderDisplay: data.pinOrderDisplay || 0,
        postedBy: data.postedBy || 'N/A',
      },
    };

    postObj.postInfoImageMapperList = [];

    const imageList = [];
    const originalImagesTemp = [];
    if (uploadfiles.length > 0) {
      // const list = [];
      for (let i = 0; i < uploadfiles.length; i++) {
        const fileUrl = await uploadDocument(uploadfiles[i]);
        originalImagesTemp.push(fileUrl);
        // console.log('fileUrl', fileUrl);
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          imageUploadpath: fileUrl,
          creationId: decodedData.UserId,
          ImageTitle: 'N/A',
          CropTypeName: 'N/A',
          CropImageTypeId: '4314f388-816d-4ce0-b618-b7585b7a709e',
        };
        // list.push(obj);
      }
      setOriginalImages(originalImagesTemp);
      // postObj.postInfoImageMapperList = list;
    } else {
      const imageLinks = data.postInfoImageMapperList.map(
        (image) => image.imageUploadpath
      );
      for (let i = 0; i < imageLinks.length; i++) {
        originalImagesTemp.push(imageLinks[i]);
      }
    }

    postObj.postMenuTaggedMapperList = [];
    if (selectedMenuDatas.length > 0) {
      const list = [];
      selectedMenuDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.restaurantId,
          menuId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postMenuTaggedMapperList = list;
    }

    postObj.postRestaurantTaggedMapperList = [];
    if (selectedRestaurantDatas.length > 0) {
      const list = [];
      selectedRestaurantDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postRestaurantTaggedMapperList = list;
    }

    postObj.postSectionTaggedMapperList = [];
    if (selectedSectionDatas?.length > 0) {
      const list = [];
      selectedSectionDatas.map((d) => {
        var obj = {
          id: 'N/A',
          postInfoId: 'N/A',
          restaurantId: d.restaurantId,
          menuId: d.menuId,
          sectionId: d.sectionId,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postSectionTaggedMapperList = list;
    }

    postObj.postFoodItemTaggedMapperList = [];
    if (selectFoodItemDatas?.length > 0) {
      const list = [];
      selectFoodItemDatas.map((d) => {
        var obj = {
          id: d.id,
          postInfoId: data.postInfoId,
          foodItemId: d.id,
          creationId: decodedData.UserId,
        };
        list.push(obj);
      });
      postObj.postFoodItemTaggedMapperList = list;
    }

    // postObj.postImagesViewList = [];
    console.log('post image obj 2: ', data.postInfoImageMapperList);

    const originalId = data.postInfoImageMapperList.find(
      (postImage) =>
        postImage.cropImageTypeId ===
        cropData.find((type) => type.cropType === 'original').id
    )?.id
      ? data.postInfoImageMapperList.find(
          (postImage) =>
            postImage.cropImageTypeId ===
            cropData.find((type) => type.cropType === 'original').id
        )?.id
      : 'N/A';

    const fourByFiveId = data.postInfoImageMapperList.find(
      (postImage) =>
        postImage.cropImageTypeId ===
        cropData.find((type) => type.cropType === '4:5').id
    )?.id
      ? data.postInfoImageMapperList.find(
          (postImage) =>
            postImage.cropImageTypeId ===
            cropData.find((type) => type.cropType === '4:5').id
        )?.id
      : 'N/A';

    const oneByOneId = data.postInfoImageMapperList.find(
      (postImage) =>
        postImage.cropImageTypeId ===
        cropData.find((type) => type.cropType === '1:1').id
    )?.id
      ? data.postInfoImageMapperList.find(
          (postImage) =>
            postImage.cropImageTypeId ===
            cropData.find((type) => type.cropType === '1:1').id
        )?.id
      : 'N/A';
    console.log('post image obj: ', originalId);

    if (croppedImages?.length > 0) {
      console.log('post image obj 3: ', croppedImages, originalImagesTemp);
      // const list = [];
      for (let i = 0; i < originalImagesTemp.length; i++) {
        // Original Image Object
        // let originalImageObj = {
        //   postImageId: 'N/A',
        //   imagePath: originalImages[0],
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === 'original'
        //   )?.id,
        //   imageTitle: 'image for original',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };
        let originalImageObj = {
          id: originalId,
          imageUploadpath: originalImagesTemp[0],
          cropImageTypeId: cropData.find((type) => type.cropType === 'original')
            ?.id,
          cropTypeName: cropData.find((type) => type.cropType === 'original')
            ?.cropType,
          imageTitle: 'image for original',
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(originalImageObj);

        const oneByOneCroppedUrl = await uploadDocument(
          croppedImages[i].oneByOne
        );
        // OneByOne Cropped Image Object
        // let oneByOneCroppedObj = {
        //   postImageId: 'N/A',
        //   imagePath: oneByOneCroppedUrl,
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === '1:1'
        //   )?.id,
        //   imageTitle: 'image for oneByone',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };

        let oneByOneCroppedObj = {
          id: oneByOneId,
          imageUploadpath: oneByOneCroppedUrl,
          cropImageTypeId: cropData.find((type) => type.cropType === '1:1')?.id,
          cropTypeName: cropData.find((type) => type.cropType === '1:1')
            ?.cropType,
          imageTitle: 'image for oneByone',
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(oneByOneCroppedObj);

        const fourByFiveCroppedUrl = await uploadDocument(
          croppedImages[i].fourByFive
        );
        // FourByFive Cropped Image Object
        // let fourByFiveCroppedObj = {
        //   postImageId: 'N/A',
        //   imagePath: fourByFiveCroppedUrl,
        //   cropImageTypeId: allPostCropImageTypes.find(
        //     (type) => type.cropType === '4:5'
        //   )?.id,
        //   imageTitle: 'image for fourByfive',
        //   postId: 'N/A',
        //   restaurantId: restaurantId,
        //   creationId: decodedData?.UserId,
        // };

        let fourByFiveCroppedObj = {
          id: fourByFiveId,
          imageUploadpath: fourByFiveCroppedUrl,
          cropImageTypeId: cropData.find((type) => type.cropType === '4:5')?.id,
          cropTypeName: cropData.find((type) => type.cropType === '4:5')
            ?.cropType,
          imageTitle: 'image for fourByfive',
          postInfoId: data.postInfoId,
          creationId: decodedData?.UserId,
        };
        imageList.push(fourByFiveCroppedObj);
      }

      // postObj.postImagesViewList = list;
      postObj.postInfoImageMapperList = imageList;
    } else if (images.length > 0) {
      for (let i = 0; i < originalImagesTemp.length; i++) {
        let originalImageObj = {
          id: data.postInfoImageMapperList.find(
            (postImage) =>
              postImage.cropImageTypeId ===
              cropData.find((type) => type.cropType === 'original').id
          ).id
            ? data.postInfoImageMapperList.find(
                (postImage) =>
                  postImage.cropImageTypeId ===
                  cropData.find((type) => type.cropType === 'original').id
              ).id
            : 'N/A',
          imageUploadpath: originalImagesTemp[0],
          cropImageTypeId: cropData.find((type) => type.cropType === 'original')
            ?.id,
          cropTypeName: cropData.find((type) => type.cropType === 'original')
            ?.cropType,
          imageTitle: 'image for original',
          postInfoId: 'N/A',
          creationId: decodedData?.UserId,
        };
        imageList.push(originalImageObj);
      }
      postObj.postInfoImageMapperList = imageList;
    }

    axios
      .put(
        apiAddress + `/api/Post/EditPostAsync?postInfoId=${data.postInfoId}`,
        postObj
      )
      .then((response) => {
        fetchPostData(decodedData.UserId);

        handleConfirm();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className='modal fade'
      id='editShareYourThoughtsModal'
      data-bs-backdrop='static'
      tabIndex='-1'
    >
      <div className='modal-dialog modal-dialog-centered galleryModal'>
        <div className='modal-content'>
          <div className={`modal-body ${editImage ? 'edit-mode' : ''}`}>
            {/* Modal Starting Point */}
            <div className='post-modal'>
              <ToastContainer />
              {(loading || editLoading) && (
                <div className='loading-image-container'>
                  <img src={loadingImage} alt='loading image' />
                </div>
              )}
              <ModalHeader
                step={step}
                setStep={setStep}
                show={show}
                setShow={setShow}
                showFirstModal={showFirstModal}
                showSecondModal={showSecondModal}
                showThirdModal={showThirdModal}
                setShowFirstModal={setShowFirstModal}
                setShowSecondModal={setShowSecondModal}
                setShowThirdModal={setShowThirdModal}
                hideEditShareModalInfo={hideEditShareModalInfo}
                showEditShareModalInfo={showEditShareModalInfo}
                showCroppedImage={showCroppedImage}
                typeOfCrop={typeOfCrop}
                croppedImageForOnyByOne={croppedImageForOnyByOne}
                croppedImageForFourByFive={croppedImageForFourByFive}
                resetCroppedAreaPixels={resetCroppedAreaPixels}
                handleCloseAll={handleCloseAll}
                setEditImage={setEditImage}
                editLoading={editLoading}
              />
              <ModalContent
                step={step}
                setStep={setStep}
                showFirstModal={showFirstModal}
                showSecondModal={showSecondModal}
                showThirdModal={showThirdModal}
                handleFilterModal={handleFilterModal}
                typeOfCrop={typeOfCrop}
                setTypeOfCrop={setTypeOfCrop}
                croppedImageForOnyByOne={croppedImageForOnyByOne}
                croppedImageForFourByFive={croppedImageForFourByFive}
                croppedImages={croppedImages}
                setCroppedImages={setCroppedImages}
                initialData={initialData}
                setInitialData={setInitialData}
                handleEditImage={handleEditImage}
                handleCancelImage={handleCancelImage}
                fileInputRef={fileInputRef}
                handleImageChange={handleImageChange}
                comments={comments}
                handleCommentsChange={handleCommentsChange}
                initialCroppedAreaPixels={initialCroppedAreaPixels}
                setInitialCroppedAreaPixels={setInitialCroppedAreaPixels}
                croppedAreaPixelsForOneByOne={croppedAreaPixelsForOneByOne}
                setCroppedAreaPixelsForOneByOne={
                  setCroppedAreaPixelsForOneByOne
                }
                initialCroppedAreaPixelsForOneByOne={
                  initialCroppedAreaPixelsForOneByOne
                }
                setInitialCroppedAreaPixelsForOneByOne={
                  setInitialCroppedAreaPixelsForOneByOne
                }
                initialCroppedAreaPixelsForFourByFive={
                  initialCroppedAreaPixelsForFourByFive
                }
                setInitialCroppedAreaPixelsForFourByFive={
                  setInitialCroppedAreaPixelsForFourByFive
                }
                croppedAreaPixelsForFourByFive={croppedAreaPixelsForFourByFive}
                setCroppedAreaPixelsForFourByFive={
                  setCroppedAreaPixelsForFourByFive
                }
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                isImageSelected={isImageSelected}
                editImageUrl={editImageUrl}
                cropForOneByOne={cropForOneByOne}
                setCropForOneByOne={setCropForOneByOne}
                zoomForOneByOne={zoomForOneByOne}
                setZoomForOneByOne={setZoomForOneByOne}
                cropForFourByFive={cropForFourByFive}
                setCropForFourByFive={setCropForFourByFive}
                zoomForFourByFive={zoomForFourByFive}
                setZoomForFourByFive={setZoomForFourByFive}
                handleRemoveData={handleRemoveData}
                restaurantDatas={restaurantDatas}
                setRestaurantDatas={setRestaurantDatas}
                // Add the missing states here
                restaurant={restaurant}
                setRestaurant={setRestaurant}
                menu={menu}
                setMenu={setMenu}
                section={section}
                setSection={setSection}
                foodItem={foodItem}
                setFoodItem={setFoodItem}
                selectedRestaurantDatas={selectedRestaurantDatas}
                setSelectedRestaurantDatas={setSelectedRestaurantDatas}
                menuData={menuData}
                setMenuData={setMenuData}
                allMenuData={allMenuData}
                setAllMenuData={setAllMenuData}
                sectionDatas={sectionDatas}
                setSectionDatas={setSectionDatas}
                selectedSectionDatas={selectedSectionDatas}
                setSelectedSectionDatas={setSelectedSectionDatas}
                selectedMenuDatas={selectedMenuDatas}
                setSelectedMenuDatas={setSelectedMenuDatas}
                foodItemDatas={foodItemDatas}
                setFoodItemDatas={setFoodItemDatas}
                selectFoodItemDatas={selectFoodItemDatas}
                setSelectFoodItemDatas={setSelectFoodItemDatas}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                isRestaurantDropdownVisible={isRestaurantDropdownVisible}
                isMenuDropdownVisible={isMenuDropdownVisible}
                isSectionDropdownVisible={isSectionDropdownVisible}
                isFoodItemDropdownVisible={isFoodItemDropdownVisible}
                data={data}
                linkActive={linkActive}
                setLinkActive={setLinkActive}
                handleRemoveRestaurantItem={handleRemoveRestaurantItem}
                handleRemoveMenuItem={handleRemoveMenuItem}
                handleRemoveSectionItem={handleRemoveSectionItem}
                handleRemoveFoodItem={handleRemoveFoodItem}
                handleSave={handleSave}
                loading={loading}
                uploadfiles={uploadfiles}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default EditShareYourThoughtsModal;
