import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Cart.css";
import bag from "./../../Assets/Icons/bag.png";
import shoppingCart from "./../../Assets/Icons/shopping-bag.png";
import remove from "./../../Assets/Icons/close2.png";
import CardDetails from "./Components/CardDetails/CardDetails";
import cancelIcon from "./../../Assets/Icons/close.png";
import Notification from "../../components/Navbar/Notification";
import jwtDecode from "jwt-decode";
import axios from "axios";
import CartDetails from "./Components/CartDetails";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartData } from "../../actions/actions";
import { Tab, Tabs } from "react-bootstrap";
import PendingCartOrder from "./Components/PendingCartOrder";
import { setCartItems } from "../../reducers/cartCounterReducer"
import { getPercentageOrAmount } from "../../utils/getPercentageOrAmount";
import { Typography } from "antd";
import infoIcon from '../../Assets/Icons/info.png';


const Cart = ({ usedIn }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [userId, setUserId] = useState();
  const [cartData, setCartData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [count, setCount] = useState(0);
  const cardDetails = useRef(null);
  const cart = useRef(null);
  const navbarTopRef = useRef(null);
  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(true);
  const [totalVatAmount, setTotalVatAmount] = useState(0);
  const [totalSdAmount, setTotalSdAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);

  const dispatch = useDispatch()
  const totalCount = useSelector((state) => state.cart.totalCount);
  console.log(typeof usedIn);
  // console.log(usedIn);

  useEffect(() => {
    const calculateServiceCharge = () => {
      let totalService = 0;

      if (cartData.length > 0) {
        for (let i = 0; i < cartData.length; i++) {
          for (let j = 0; j < cartData[i].subOrders.length; j++) {
            for (let k = 0; k < cartData[i].subOrders[j].itemDetails.length; k++) {
              const item = cartData[i].subOrders[j].itemDetails[k]
              let individualSubTotal = 0;
              if (cartData[i].subOrders[j].orderTypeName === "In-Store") {
                individualSubTotal += item.basePrice * item.foodItemQuantity;
              }

              for (let l = 0; l < item.addOns.length; l++) {
                if (cartData[i].subOrders[j].orderTypeName === "In-Store") {
                  individualSubTotal += item.addOns[l].addOnBasePrice * item.addOns[l].addOnQuantity
                }

                for (let m = 0; m < item.addOns[l].options.length; m++) {
                  const option = item.addOns[l].options[m];
                  if (cartData[i].subOrders[j].orderTypeName === "In-Store") {
                    individualSubTotal += option.optionBasePrice * option.optionQuantity
                  }

                  for (let n = 0; n < option.subOptions.length; n++) {
                    if (cartData[i].subOrders[j].orderTypeName === "In-Store") {
                      individualSubTotal += option.subOptions[n].subOptionBasePrice * 1;
                    }
                  }
                }
              }
              
              const service = getPercentageOrAmount(individualSubTotal, cartData[i].serviceCharge, "amount")
              totalService += service
            }
          }
        }
      }

      setServiceCharge(totalService);
    }

    calculateServiceCharge();
  }, [cartData])

  console.log("cart data: ", cartData);
  // const dispatch = useDispatch();
  // const cartDataCount = useSelector((state) => state.cartReducer.cartItems);

  // const count = cartDataCount ? cartData?.length : 0;

  const showCartDetails = () => {
    if (!showDetails) {
      setShowDetails(true);
      fetchData();
    } else {
      setShowDetails(false);
    }
  };

  let gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

  useEffect(() => {
    let decoded = {};

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
        // console.log(decoded);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      setUserId(decoded.UserId);
      // getUserChatPreview(decoded.UserId);
    }
  }, [gotasteClienttoken]);

  // useEffect(() => {
  //   if (userId) {
  //     dispatch(fetchCartData(userId));
  //   }
  // }, [dispatch, userId]);

  // Check if userId is available before making the request
  const [reloadapiCalling, setReloadapi] = useState(true);
  const reloadapi = () => {
    // setReloadapi(!reloadapiCalling);
    return Math.random();
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
        `${process.env.REACT_APP_SECRET}/api/Cart/GetCartDataByUserId?userId=${userId}`
      );
      dispatch(setCartItems(response.data));
      setCartData(response.data);
    } catch (error) {
      console.error("MKMKError fetching cart data:", error);
    }
  };

  const fetchPendingOrders = useCallback(async (userId) => {
    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
        `${process.env.REACT_APP_SECRET}/api/Order/GetPendingOrderDataByUserId?userId=${userId}`
      );
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  }, []);
  console.log("pending order data: ", orderData)

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const token = localStorage.getItem("gotasteClienttoken");

    if (token) {
      const userId = jwtDecode(token).UserId;
      fetchPendingOrders(userId)
    }
  }, [fetchPendingOrders]);

  // useEffect(() => {
  //   // Check if cartItems in local storage have changed
  //   const storedCartData = localStorage.getItem('cartItems');
  //   console.log('storedCartData', storedCartData);

  //   try {
  //     if (storedCartData) {
  //       const parsedCartData = JSON.parse(storedCartData);
  //       console.log('parsedCartData', parsedCartData);

  //       // Compare parsedCartData with your current cartData
  //       if (JSON.stringify(cartData) !== JSON.stringify(parsedCartData)) {
  //         // Update the state with the data from the server
  //         setCartData(parsedCartData);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error parsing cart data from localStorage:', error);
  //     // Handle the error as needed
  //   }
  // }, [cartData, userId]);

  // console.log('cart data', cartData);

  //Comment out by mk

  const calculateTotalVat = useCallback(() => {
    let totalVat = 0;
    let totalSd = 0;

    if (cartData) {
      for (let i = 0; i < cartData.length; i++) {
        for (let j = 0; j < cartData[i].subOrders.length; j++) {
          for (let k = 0; k < cartData[i].subOrders[j].itemDetails.length; k++) {
            const item = cartData[i].subOrders[j].itemDetails[k];
            totalVat += item.vatInAmount * item.foodItemQuantity;
            totalSd += item.sdInAmount * item.foodItemQuantity;

            for (let l = 0; l < item.addOns.length; l++) {
              totalVat += item.addOns[l].addOnVatAmount * item.addOns[l].addOnQuantity;
              totalSd += item.addOns[l].addOnSdAmount * item.addOns[l].addOnQuantity;

              for (let m = 0; m < item.addOns[l].options.length; m++) {
                const option = item.addOns[l].options[m];
                totalVat += option.optionVatAmount * option.optionQuantity;
                totalSd += option.optionSdAmount * option.optionQuantity;

                for (let n = 0; n < option.subOptions.length; n++) {
                  totalVat += option.subOptions[n].subOptionVatAmount;
                  totalSd += option.subOptions[n].subOptionSdAmount;
                }
              }
            }
          }
        }
      }
    }

    setTotalVatAmount(totalVat);
    setTotalSdAmount(totalSd);
  }, [cartData]);

  useEffect(() => {
    calculateTotalVat();
  }, [calculateTotalVat])

  useEffect(() => {
    const calculateSubtotal = () => {
      let newSubtotal = 0;

      if (cartData) {
        cartData.forEach((restaurant) => {
          restaurant.subOrders.forEach((subOrder) => {
            subOrder.itemDetails.forEach((item) => {
              if (item.foodItemQuantity > 0) {
                newSubtotal += item.basePrice * item.foodItemQuantity;

                item.addOns.forEach((addOn) => {
                  if (addOn.addOnQuantity > 0) {
                    newSubtotal += addOn.addOnBasePrice * addOn.addOnQuantity;

                    addOn.options.forEach((option) => {
                      if (option.optionQuantity > 0) {
                        newSubtotal += option.optionBasePrice * option.optionQuantity;

                        option.subOptions.forEach((subOption) => {
                          newSubtotal += subOption.subOptionBasePrice;
                        });
                      }
                    });
                  }
                });
              }
            });
          });
        });
      }
      setSubtotal(newSubtotal);
    };

    calculateSubtotal();
  }, [cartData]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDetails &&
        cardDetails.current &&
        !cardDetails.current.contains(event.target) &&
        !cart.current.contains(event.target)
      ) {
        setShowDetails(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const filterBarRect = navbarTopRef?.current?.getBoundingClientRect();
      const isAboveViewport = filterBarRect?.top === 0 ? true : false;
      setNavbarOnTop(isAboveViewport);
      var top = filterBarRect?.top * -1;
      var scrollval = scrollValue * -1;
      if (top > scrollval) {
        setSlideUp(true);
        setShowDetails(false);
      } else if (top <= 0) {
        setSlideUp(true);
      } else {
        setSlideUp(false);
        setShowDetails(false);
      }

      var s = filterBarRect?.top;
      setscrollValue(s);
    });
  });

  // console.log(showDetails)

  // Counting total food items
  useEffect(() => {
    let totalItems = 0;
    cartData?.forEach(restaurant => {
      restaurant.subOrders.forEach(subOrder => {
        totalItems += subOrder.itemDetails.length;
      });
    });
    setCount(totalItems);
  }, [cartData]);


  return (
    <div
      className={`cart d-flex ${gotasteClienttoken != null
        ? "justify-content-around"
        : "justify-content-center"
        }`}
    >
      {gotasteClienttoken != null && <Notification usedIn={usedIn} />}
      <div ref={navbarTopRef}></div>

      <div
        className={`${showDetails ? "cart-details" : "d-none"} ${slideUp ? "" : " d-none"
          } `}
        ref={cardDetails}
      >
        <div onClick={() => setShowDetails(false)} style={{  position: 'absolute', right: '25px', top: '16px'}}>
          <img src={remove} style={{ width: '20px', height: '20px' }} />
        </div>
        <Tabs
          id="controlled-tab-example"
          className="mb-3"
          defaultActiveKey="active"
        >
          <Tab eventKey="active" title="Active" className="active-tab" >
            <div className="tab-content-container">
            <div className="d-flex justify-content-center mt-1">
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "0",
                  fontSize: "24px",
                }}
              >
                Your Order
              </h4>
            </div>
           
            {cartData?.length === 0 ? (
              <div className="d-flex justify-content-center card-item-details text-black-50">
                <h4>Please add items to your cart.</h4>
              </div>
            ) : (
              <div className="card-item-details">
                <CartDetails
                  // cartData={cartData?.filter(
                  //   (item) => item?.isOrdered === false
                  // )}
                  cartDatas={cartData}
                  userId={userId}
                  reloadApiCalling={() => reloadapi()}
                />
              </div>
            )}

            <div className="p-2">
              <hr />
              <div className="d-flex justify-content-between mb-2 px-2">
                <h6>Subtotal</h6>
                <h6>£{subtotal.toFixed(2)}</h6>
              </div>
              <div className="d-flex justify-content-between mb-2 px-2">
                <h6>Vat</h6>
                <h6>£{(totalVatAmount+serviceCharge*0.2).toFixed(2)}</h6>
              </div>
              <div className="d-flex justify-content-between mb-2 px-2" >
                <h6>SD
                <span className={"large-tooltipsInfo"}  data-title={"Supplementary Duty"}>
              <img
                src={infoIcon}
                alt='infoIcon'
                style={{ width: '1rem', height: '1rem' , marginLeft: '0.5rem'}}
               
              />
            </span>
            </h6>
                <h6>£{totalSdAmount.toFixed(2)}</h6>
              </div>
              <div className="d-flex justify-content-between mb-2 px-2">
                <h6>Service charge</h6>
                <h6>£{serviceCharge.toFixed(2)}</h6>
              </div>
              <div className="d-flex justify-content-between px-2">
                <h6>
                  <b>Total</b>
                </h6>
                <h6>£{(subtotal + (totalVatAmount+serviceCharge*0.2) + totalSdAmount + serviceCharge).toFixed(2)}</h6>
              </div>
              {/* <div className="d-flex justify-content-between mt-3  staticcheckout-btn">
                {cartData?.length > 0 ? (
                  <Link
                    to={{
                      pathname: "/checkout",
                    }}
                    state={{ subtotal: subtotal.toFixed(2) }}
                    className="btn btn-checkout">
                    Checkout
                  </Link>
                ) : (
                  <button className="btn btn-checkout btn-disabled" disabled>
                    Checkout
                  </button>
                )}
              </div> */}
              <div className="d-flex justify-content-between mt-3  staticcheckout-btn">
                {cartData?.length > 0 ? (
                  <Link
                    to={{
                      pathname: "/checkout",
                    }}
                    state={{ subtotal: subtotal.toFixed(2) }}
                    className="btn btn-checkout">
                    Checkout
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/checkout",
                    }}
                    className="btn btn-checkout">
                    Checkout
                  </Link>
                )}
              </div>
            </div>
            </div>
          </Tab>

          <Tab eventKey="pending" title="Pending" className="pb-2">
            <div className="d-flex justify-content-center mt-1">
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "8px 0",
                  fontSize: "24px",
                }}
              >
                Your Order
              </h4>
            </div>
            {orderData?.length === 0 ? (
              <div className="d-flex justify-content-center card-item-details text-black-50">
                <h4>Please add items to your cart.</h4>
              </div>
            ) : (
              <div className="card-item-details card-item-details-pending">
                {/* {cartData
                  ?.filter((item) => item?.isOrdered === true)
                  .map((item) => (
                    <PendingCartOrder key={item.id} restaurant={item} />
                  ))} */}
                {
                  orderData.map(order => <PendingCartOrder key={order.id} restaurant={order} />)
                }
                {/* <CartDetails cartData={cartData?.filter(item => item?.isOrdered === true)} userId={userId} /> */}
              </div>
            )}

            <Link to={"/checkout?for=pending"} className="btn btn-checkout mt-4">
              View All
            </Link>

            {/* <div className='p-2'>
              <hr />
              <div className='d-flex justify-content-between mb-2 px-2'>
                <h6>Subtotal</h6>
                <h6>{subtotal.toFixed(2)}£</h6>
              </div>
              <div className='d-flex justify-content-between mb-2 px-2'>
                <h6>Vat</h6>
                <h6>10.5£</h6>
              </div>
              <div className='d-flex justify-content-between px-2'>
                <h6>
                  <b>Total</b>
                </h6>
                <h6>21.0£</h6>
              </div>
              <div className='d-flex justify-content-between'>
                {cartData?.length > 0 ? (
                  <Link
                    to={{
                      pathname: '/checkout',
                    }}
                    state={{ subtotal: subtotal.toFixed(2) }}
                    className='btn btn-checkout'
                  >
                    Checkout
                  </Link>
                ) : (
                  <button className='btn btn-checkout btn-disabled' disabled>
                    Checkout
                  </button>
                )}
              </div>
            </div> */}
          </Tab>
        </Tabs>

        {/* <div className='d-flex justify-content-center mt-1'>
          <h4
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              padding: '8px 0',
              fontSize: '24px',
            }}
          >
            Your Order
          </h4>
        </div>
        {cartData?.length === 0 ? (
          <div className='d-flex justify-content-center card-item-details text-black-50'>
            <h4>Please add items to your cart.</h4>
          </div>
        ) : (
          <div className='card-item-details'>
            <CartDetails cartData={cartData} userId={userId} />
          </div>
        )}

        <div className='p-2'>
          <hr />
          <div className='d-flex justify-content-between mb-2 px-2'>
            <h6>Subtotal</h6>
            <h6>{subtotal.toFixed(2)}£</h6>
          </div>
          <div className='d-flex justify-content-between mb-2 px-2'>
            <h6>Vat</h6>
            <h6>10.5£</h6>
          </div>
          <div className='d-flex justify-content-between px-2'>
            <h6>
              <b>Total</b>
            </h6>
            <h6>21.0£</h6>
          </div>
          <div className='d-flex justify-content-between'>
            {cartData?.length > 0 ? (
              <Link
                to={{
                  pathname: '/checkout',
                }}
                state={{ subtotal: subtotal.toFixed(2) }}
                className='btn btn-checkout'
              >
                Checkout
              </Link>
            ) : (
              <button className='btn btn-checkout btn-disabled' disabled>
                Checkout
              </button>
            )}
          </div>
        </div> */}
      </div>

      <div ref={cart}>
        <div className="mobileCart">
          <div
            className={`d-flex explore align-items-center`}
            onClick={() => {
              showCartDetails();
            }}
          >
            <img src={shoppingCart} alt="" className="searchIconcoffee" />
            <span>Cart</span>
          </div>
        </div>
        <div className="desktopCart">
          <div
            className="popup"
            onClick={() => {
              showCartDetails();
            }}
          >
            <img src={bag} alt="" />
            {/* <span className="count">{count}</span> */}
            <span className="count">{totalCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
