import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  unstable_strictMode as StrictMode,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import jwt_decode from "jwt-decode";
import { Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setMenuList, FoodItemList, SearchLoader } from "../../actions";
import { selectFirstMenuItem } from "../../actions/Menu/menuActions";
import { setSelectedMenu } from "../../actions/Menu/selectedMenuActions";
import { setSearchValue } from "../../actions/SearchBar/searchAtion";
import { searchingOption } from "../../actions/Menu/searchingActions";
import { fetchMenuSuccess } from "../../actions/Menu/menuActions";
import { filteringSectionByMenu } from "../../actions/FilterBar/filteringSectionByMenu";
import { setFilteredItemData } from "../../actions/SearchBar/SuggestedItemData";
import { setSearchingStart } from "../../actions/SearchBar/SearchingStart";

/***  Components Importing ***/
import BannerSection from "./../Home/BannerSection/BannerSection";
import RestaurantDetailView from "./RestaurantDetailView/RestaurantDetailView";
import BottomBar from "./MenuSelection/BottomBar/BottomBar";
import FilterBar from "./MenuSelection/FilterBar/FilterBar";
import Navbar from "../Navbar/Navbar";
import GalleryView from "../GalleryView/GalleryView";
import FoodCard from "../Home/FoodSelection/FoodCard";
import CustomOutlinedTab from "./components/CustomeOutlinedtTab";
import Shimmer from "./components/Shimmer";
import debounce from "lodash/debounce";
import "./MenuSelection/BottomBar/BottomBar";
import { GetFoodListByRestaurantId } from "./RestaurantApi";
import Cart from "../Cart/Cart";

/** Style and Icon Importing */
import "./MenuSelection/FilterBar/FilterBar.css";
import { CiDeliveryTruck } from "react-icons/ci";
import menuIcon from "./../../Assets/Icons/menu2.png";
import gridIcon from "./../../Assets/Icons/Viewmenu.png";
import loadingGif from "./../../Assets/Image/loading.gif";

import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

const Restaurant = () => {
  const apiAddress = process.env.REACT_APP_SECRET;
  const { resname } = useParams();
  const navigate = useNavigate();

  const myRef = useRef(null);
  const categoryRef = useRef(null);

  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menuslected?.menuId);
  const loaderState = useSelector((state) => state.searchLoader);
  const firstMenu = useSelector(
    (state) => state.firstMenu.selectedFirstMenuItem
  );
  const selectedMenuFromRedux = useSelector(
    (state) => state.selectedMenu.selectedMenu
  );
  const searchValueFromRedux = useSelector((state) => state.searchValue.value);
  const searchOption = useSelector((state) => state.searchOption.option);
  const dataCollections = useSelector((state) => state.fooditems);
  const menuListFromRedux = useSelector((state) => state.menu.menu);
  const filteredSectionByMenuFromRedux = useSelector(
    (state) => state.filteredSection.filteredSectionAndSubSection
  );
  const ifSearchingFromRedux = useSelector(
    (state) => state.searchStart.searching
  );

  console.log("from redux: ", searchOption)

  const [isMenuSelected, setMenuSelected] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFilterbarSelected, setIsFilterbarSelected] = useState(false);
  const [SelectedFiterData, setSelectedFilterData] = useState({
    Data: {},
    Type: "",
  });

  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filterSelection, setFilterSelection] = useState({
    data: "",
    type: "",
    isload: false,
  });

  // Set Food Items here...
  const [foodItems, setfoodItems] = useState([]);
  const [foodItemAfterMenuSelection, setFoodItemAfterMenuSelection] =
    useState();
  const [FoodItems, setFoodItems] = useState({
    foodItemList: [],
    totalCount: 0,
  });
  /* Getting Food Items Here using API */
  const GetAllFoodByRestaurantIdPaged = async (
    restaurantId,
    pageNo,
    pageSize,
    initial = false,
    from
  ) => {
    setIsFilterbarSelected(false);
    await axios
      .get(
        apiAddress +
          "/api/FoodItems/GetFoodItemForClientendByRestaurantIdPagedV2N?urlname=" +
          restaurantId
      )
      .then((response) => {
        if (from == "useEffect") {
          setfoodItems(response.data.categories);
          // setfoodItems(response.data.categories);
          // setFilteredFoodItems(response.data.categories);
          // setFoodItems(() => ({
          //   foodItemList: response.data.categories.fooditems,
          //   totalCount: 10,
          // }));
        } else {
          setFoodItems((prev) => ({
            foodItemList: [
              ...prev.foodItemList,
              ...response.data.fooditemsList,
            ],
            // foodItemList: response.data.fooditemsList,
            totalCount: response.data.totalCount,
          }));
        }
      });
    if (!initial) {
      setPageNumber(pageNumber + 1);
    }
  };

  const GetFoodListByRestaurantIdAndMenuid = async (
    restaurantId,
    menuId,
    pageNo,
    pageSize,
    initial = false,
    from
  ) => {
    if (menuId !== null) {
      setIsFilterbarSelected(false);
      await axios
        .get(
          apiAddress +
            "/api/FoodItems/GetFoodItemForClientendByRestaurantUrlAndMenuIdPaged?urlname=" +
            restaurantId +
            "&menuId=" +
            menuId +
            "&pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize
        )
        .then((response) => {
          if (from == "useEffect") {
            setFoodItems(() => ({
              foodItemList: response.data.fooditemsList,
              totalCount: response.data.totalCount,
            }));
          } else {
            setFoodItems((prev) => ({
              foodItemList: [
                ...prev.foodItemList,
                ...response.data.fooditemsList,
              ],
              totalCount: response.data.totalCount,
            }));
          }
        });
      if (!initial) {
        setPageNumber(pageNumber + 1);
      }
    }
  };

  // FoodItems Filtering by Price Range
  const [filteredFoodItems, setFilteredFoodItems] = useState([]);
  const applyFilters = (filters) => {
    const updatedItems = foodItems.map((category) => {
      // Filtered by Price
      const selectedFoodItems = category.foodItems.filter(
        (foodItem) =>
          foodItem.itemBasePrice >= filters?.priceRange[0] &&
          foodItem.itemBasePrice <= filters?.priceRange[1]
      );

      // // Filtered by Offers
      // const filteredByOffers = selectedFoodItems.filter(foodItem =>
      //   (!filters.offers.freeDeliver || foodItem.freeDelivery) &&
      //   (!filters.offers.offer || foodItem.hasOffer) &&
      //   (!filters.offers.onlinePayment || foodItem.acceptsOnlinePayment)
      // );

      // // Sorting by Selected sort Options
      // let sortedItems = filteredByOffers;
      // if (filters.sortOption === "priceHighToLow") {
      //   sortedItems = filteredByOffers.slice().sort((a, b) => b.itemBasePrice - a.itemBasePrice);
      // }

      return {
        ...category,
        foodItems: selectedFoodItems,
      };
    });

    setFilteredFoodItems(updatedItems);
  };

  // Check if user is authenticated
  const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");
  let decoded = null;
  try {
    decoded = jwt_decode(gotasteClienttoken);
  } catch (error) {
    // Invalid gotasteClienttoken
  }

  // Define logout function
  const handleLogout = () => {
    localStorage.removeItem("gotasteClienttoken");
    navigate("/");
  };
  const isAuthenticated = decoded && decoded.UserName === "testT";

  // FiterBar PopUp at the top of screen functionality
  const [isFilterBarFixed, setIsFilterBarFixed] = useState(false);
  const filterBarRef = useRef(null);
  const filterBarOffsetRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (filterBarRef.current) {
        filterBarOffsetRef.current = filterBarRef.current.offsetTop;
      }
      if (filterBarRef.current && filterBarOffsetRef.current !== null) {
        setIsFilterBarFixed(window.pageYOffset > filterBarOffsetRef.current);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const handleScrollingUpValueChange = (value) => {
    setIsScrollingUp(value);
  };

  const [restaurantData, setRestaurantData] = useState({});
  const [loading, setLoading] = useState(true);

  const onMenuiconClick = () => {
    setMenuSelected(!isMenuSelected);
  };

  // Scroll to Menu onclick View Menu from RestaurantDetailView
  const test3Ref = useRef(null);
  const [blur, setBlur] = useState(false);
  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  const GetFoodListPagination = async (pageNo, pageSize) => {
    if (menuState != undefined || menuState != null) {
      GetFoodListByRestaurantIdAndMenuid(
        resname,
        menuState?.menuId,
        pageNo,
        pageSize
      );
    } else if (isFilterbarSelected) {
      GetFoodListByFilterbarClick(
        SelectedFiterData.Data.id,
        SelectedFiterData.Data.restaurantId,
        SelectedFiterData.Type,
        pageNo,
        pageSize
      );
    } else {
      GetAllFoodByRestaurantIdPaged(resname, pageNo, pageSize);
    }
  };

  const GetAllMenuByRestaurantUrl = async (resurl) => {
    dispatch(SearchLoader(true));
    await axios
      .get(
        apiAddress +
          "/api/Menu/GetAllMenuByRestaurantUrlAsync?restaurantId=" +
          resurl
      )
      .then((response) => {
        // setMenuArray(response.data);
        dispatch(setMenuList(response.data));
        dispatch(SearchLoader(false));
      });
  };

  const reloadAllFood = async (restaurantId, isload) => {
    // console.log('reload');
    setSelectedFilterData(() => ({
      Data: {},
      Type: "",
    }));
    setPageNumber(1);
    GetAllFoodByRestaurantIdPaged(resname, 1, 4, true, "useEffect");

    // setFilterSelection({
    //   data: "",
    //   type: "",
    //   isload: false,
    // });
    // var res = GetFoodListByRestaurantId(resname, 1, 12);

    // res.then((res) => {

    //   dispatch(FoodItemList(res));
    // });
  };

  const GetFoodListByFilterbarClick = async (
    typeId,
    restaurantId,
    type,
    pageNo,
    pageSize,
    initial = false,
    from
  ) => {
    await axios
      .get(
        apiAddress +
          "/api/FoodItems/GetRestaurantFoodItemPagedBysectionOrSubsection?sectionOrSubsectionId=" +
          typeId +
          "&restaurantId=" +
          restaurantId +
          "&type=" +
          type +
          "&pageNo=" +
          pageNo +
          "&pageSize=" +
          pageSize
      )
      .then((response) => {
        if (from == "useEffect") {
          setFoodItems(() => ({
            foodItemList: response.data.fooditemsList,
            totalCount: response.data.totalCount,
          }));
        } else {
          setFoodItems((prev) => ({
            foodItemList: [
              ...prev.foodItemList,
              ...response.data.fooditemsList,
            ],
            totalCount: response.data.totalCount,
          }));
        }
      });
    if (!initial) {
      setPageNumber(pageNumber + 1);
    }
  };

  const getSectionSubsectionId = (data, type, isselect) => {
    setIsFilterbarSelected(isselect);

    setPageNumber(1);

    setSelectedFilterData(() => ({
      Data: data,
      Type: type,
    }));
    // dispatch(SearchLoader(true));
    // setFilterSelection({
    //   data: data,
    //   type: type,
    //   isload: isload,
    // });
    // var res = GetFoodListByFilterbarClick(
    //   data.id,
    //   data.restaurantId,
    //   type,
    //   1,
    //   4
    // );
    // res.then((res) => {
    //   setLoader(false);
    //   dispatch(FoodItemList(res));
    //   dispatch(SearchLoader(false));
    // });
  };

  useEffect(() => {
    if (SelectedFiterData.Type != "") {
      GetFoodListByFilterbarClick(
        SelectedFiterData.Data.id,
        SelectedFiterData.Data.restaurantId,
        SelectedFiterData.Type,
        1,
        4,
        true,
        "useEffect"
      );
    }
  }, [SelectedFiterData]);

  //pagination test start

  // const itemsPerPage = 12;

  // const pageCount = Math.ceil(dataCollections.totalCount / itemsPerPage);

  // const handlePageClick = (event) => {
  //   if (filterSelection.type) {
  //     dispatch(SearchLoader(true));
  //     var res = GetFoodListByFilterbarClick(
  //       filterSelection.data.id,
  //       filterSelection.data.restaurantId,
  //       filterSelection.type,
  //       event.selected + 1,
  //       12
  //     );

  //     res.then((res) => {
  //       dispatch(FoodItemList(res));
  //       dispatch(SearchLoader(false));
  //     });
  //   } else {
  //     dispatch(SearchLoader(true));
  //     var res = GetFoodListByRestaurantId(resname, event.selected + 1, 12);
  //     res.then((res) => {
  //       dispatch(FoodItemList(res));
  //       dispatch(SearchLoader(false));
  //     });
  //   }
  // };
  //pagination test end

  /* Fetching Restaurant Details */
  useEffect(() => {
    GetAllMenuByRestaurantUrl(resname);
    // myRef.current.scrollIntoView();
    if (resname != null) {
      console.log("empty called");
      GetAllFoodByRestaurantIdPaged(resname, 1, 4, true, "useEffect");
    }

    axios
      .get(
        apiAddress +
          "/api/Restaurant/getrestaurantdetailsForRestaurantbyName?restaurantName=" +
          resname
      )
      .then((res) => {
        console.log("MKMK-res details", res.data);
        setRestaurantData(res.data);
        setLoading(false);
      });
    // window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!!menuState == true) {
      setSelectedFilterData(() => ({
        Data: {},
        Type: "",
      }));
      setPageNumber(1);
      GetFoodListByRestaurantIdAndMenuid(
        resname,
        menuState,
        1,
        4,
        true,
        "useEffect"
      );
    } else if (SelectedFiterData.Type == "") {
      // console.log(SelectedFiterData.Type);
      // console.log('menu called');
      setSelectedFilterData(() => ({
        Data: {},
        Type: "",
      }));
      setPageNumber(1);
      GetAllFoodByRestaurantIdPaged(resname, 1, 4, true, "useEffect");
    }
  }, [menuState]);

  /* Get and Select current category based on scroll positions */
  const [currentCategory, setCurrentCategory] = useState("");
  const getCurrentCategory = () => {
    const getAllCategories = document.querySelectorAll(
      ".categoryContainerForSelection"
    );
    let currentCategory = "";

    getAllCategories.forEach((category) => {
      const categoryTop = category.offsetTop - 90;
      const categoryBottom = categoryTop + category.clientHeight;

      if (window.scrollY >= categoryTop && window.scrollY < categoryBottom) {
        currentCategory = category.textContent;
      }
    });
    setCurrentCategory(currentCategory);
  };

  useEffect(() => {
    const categorySelection = () => {
      getCurrentCategory();
    };

    window.addEventListener("scroll", categorySelection);
    return () => {
      window.removeEventListener("scroll", categorySelection);
    };
  }, []);

  /* Filtering foodItems based on filteredSection */
  const [filteredSection, setFilteredSection] = useState({
    sectionList: [],
    subSectionList: [],
  });

  /* Getting filtered sections*/
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    setSearchLoading(true);
    setFilteredSection(filteredSectionByMenuFromRedux);
    setSearchLoading(false);
  }, [filteredSectionByMenuFromRedux]);

  const FilteringFoodItemsbySectionId = (sectionList, foodList) => {
    setSearchLoading(true);

    setTimeout(() => {
      setSearchLoading(false);
    }, 200);

    if (sectionList.length > 0) {
      return foodList.map((category) => {
        const selectedFoodItems = category.foodItems.filter((foodItem) =>
          sectionList.some((section) => foodItem.sectionId === section.id)
        );
        return {
          ...category,
          foodItems: selectedFoodItems,
        };
      });
    } else {
      return [];
    }
  };

  /* Filtering foodItems based on sectionId */
  useEffect(() => {
    const filteredItems = FilteringFoodItemsbySectionId(
      filteredSection.sectionList,
      foodItems
    );
    if (filteredItems.length > 0) {
      setFilteredFoodItems(filteredItems);
      setFoodItemAfterMenuSelection(filteredItems);
    } else {
      setFilteredFoodItems([]);
      setFoodItemAfterMenuSelection([]);
    }
  }, [filteredSection, foodItems]);

  // Filtering Food Items based on filterbar search input
  const searchData = (data) => {
    if(data !== null || data !== ""){
      const updatedItems = foodItems.map((category) => {
        const selectedFoodItems = category.foodItems.filter((foodItem) => {
          if (typeof foodItem === "object" && foodItem !== null) {
            return Object.values(foodItem).some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(data)
            );
          }
          return false;
        });

        return {
          ...category,
          foodItems: selectedFoodItems,
        };
      });

      setFilteredFoodItems(updatedItems);
    }
  }

  /* Searching on the FoodItems based on Logic */
  // const filterFoodItems = (
  //   searchValue,
  //   searchOption,
  //   foodItems,
  //   foodItemAfterMenuSelection
  // ) => {
  //   const data = searchValue.trim().toLowerCase();
  //   let updatedItems;

  //   if (data !== "") {
  //     if (searchOption === "globall") {
  //       dispatch(selectFirstMenuItem(null)); // deselect menu
  //       dispatch(setSelectedMenu(null));

  //       updatedItems = foodItems.map((category) => {
  //         const selectedFoodItems = category.foodItems.filter((foodItem) => {
  //           if (typeof foodItem === "object" && foodItem !== null) {
  //             return Object.values(foodItem).some(
  //               (value) =>
  //                 typeof value === "string" &&
  //                 value.toLowerCase().includes(data)
  //             );
  //           }
  //           return false;
  //         });

  //         return {
  //           ...category,
  //           foodItems: selectedFoodItems,
  //         };
  //       });
  //     } else if (searchOption === "onSelectedMenu") {
  //       updatedItems = foodItemAfterMenuSelection.map((category) => {
  //         const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
  //           if (typeof foodItem === "object" && foodItem !== null) {
  //             return Object.values(foodItem).some(
  //               (value) =>
  //                 typeof value === "string" &&
  //                 value.toLowerCase().includes(data)
  //             );
  //           }
  //           return false;
  //         });

  //         return {
  //           ...category,
  //           foodItems: selectedFoodItems,
  //         };
  //       });
  //     }
  //   }
  //   return updatedItems;
  // };

  const filterFoodItems = (
    searchValues, // Now expecting an array of food item names
    searchOption,
    foodItems,
    foodItemAfterMenuSelection
  ) => {
    // Ensure the input is a valid array of strings and convert all to lowercase
    const data = Array.isArray(searchValues)
      ? searchValues.map((value) => value.trim().toLowerCase())
      : [];
  
    let updatedItems;
  
    if (data.length > 0) {
      if (searchOption === "globall") {
        dispatch(selectFirstMenuItem(null)); // deselect menu
        dispatch(setSelectedMenu(null));
  
        updatedItems = foodItems.map((category) => {
          const selectedFoodItems = category.foodItems.filter((foodItem) => {
            if (typeof foodItem === "object" && foodItem !== null) {
              return Object.values(foodItem).some((value) => {
                if (typeof value === "string") {
                  // Check if any of the searchValues matches
                  return data.some((searchValue) =>
                    value.toLowerCase().includes(searchValue)
                  );
                }
                return false;
              });
            }
            return false;
          });
  
          return {
            ...category,
            foodItems: selectedFoodItems,
          };
        });
      } else if (searchOption === "onSelectedMenu") {
        updatedItems = foodItemAfterMenuSelection.map((category) => {
          const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
            if (typeof foodItem === "object" && foodItem !== null) {
              return Object.values(foodItem).some((value) => {
                if (typeof value === "string") {
                  // Check if any of the searchValues matches
                  return data.some((searchValue) =>
                    value.toLowerCase().includes(searchValue)
                  );
                }
                return false;
              });
            }
            return false;
          });
  
          return {
            ...category,
            foodItems: selectedFoodItems,
          };
        });
      }
    }
  
    return updatedItems;
  };

  /* if the search value become empty, select first menu */
  useEffect(() => {
    if (searchValueFromRedux === "" && searchOption === "globall") {
      dispatch(selectFirstMenuItem(null));

      if (selectedMenuFromRedux === null) {
        dispatch(setSelectedMenu(menuListFromRedux[0]));
      } else {
        dispatch(setSelectedMenu(selectedMenuFromRedux));
      }
    } else if ( searchValueFromRedux === "" && searchOption === "onSelectedMenu") {
      // dispatch(setSelectedMenu(null));
      setFilteredFoodItems(foodItemAfterMenuSelection);
    }
  }, [searchValueFromRedux, selectedMenuFromRedux, searchOption]);

  /* Food items are being filtered based on a name selection that was suggested. */
  useEffect(() => {
    if (ifSearchingFromRedux === true) {
      const updatedItems = filterFoodItems(
        searchValueFromRedux,
        searchOption,
        foodItems,
        foodItemAfterMenuSelection
      );
      setFilteredFoodItems(updatedItems);

      setTimeout(() => {
        dispatch(setSearchingStart(false));
      }, 2000);
    }
  }, [
    ifSearchingFromRedux,
    searchValueFromRedux,
    firstMenu,
    selectedMenuFromRedux,
    foodItemAfterMenuSelection,
  ]);

  /* Searching Suggestions Functionality */
  // const filterFoodItemNameBySearchValue = (searchValue) => {
  //   console.log("order details from search: ", searchValue)
  //   if (searchOption === "globall") {
  //     const lowerCaseData = searchValue.toLowerCase();

  //     const updatedItems = foodItems?.map((category) => {
  //       const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
  //         if (typeof foodItem === "object" && foodItem !== null) {
  //           return (
  //             foodItem.itemName.toLowerCase().includes(lowerCaseData) ||
  //             foodItem.description.toLowerCase().includes(lowerCaseData) ||
  //             foodItem.keywordTagName.toLowerCase().includes(lowerCaseData) ||
  //             foodItem.keywordDetails.toLowerCase().includes(lowerCaseData) ||
  //             foodItem.sectionName.toLowerCase().includes(lowerCaseData)
  //           );
  //         }
  //         return false;
  //       });
  //       return {
  //         ...category,
  //         foodItems: selectedFoodItems,
  //       };
  //     });

  //     const itemsData = updatedItems?.flatMap((category) => category.foodItems);
  //     dispatch(setFilteredItemData(itemsData));
  //   } else {
  //     const lowerCaseData = searchValue.toLowerCase();

  //     const updatedItems = filteredFoodItems.map((category) => {
  //       const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
  //         if (typeof foodItem === "object" && foodItem !== null) {
  //           return (
  //             foodItem.itemName &&
  //             typeof foodItem.itemName === "string" &&
  //             foodItem.itemName.toLowerCase().includes(lowerCaseData)
  //           );
  //         }
  //         return false;
  //       });
  //       return {
  //         ...category,
  //         foodItems: selectedFoodItems,
  //       };
  //     });

  //     const itemsData = updatedItems?.flatMap((category) => category.foodItems);
  //     dispatch(setFilteredItemData(itemsData));
  //   }
  // };

  const filterFoodItemNameBySearchValue = (searchValue) => {
    console.log("order details from search: ", searchValue);
  
    // Handle both single string and array of strings
    const lowerCaseData = Array.isArray(searchValue)
      ? searchValue.map((value) => value.toLowerCase()) // Convert array of strings to lowercase
      : [searchValue.toLowerCase()]; // Convert single string to array for consistent processing
  
    if (searchOption === "globall") {
      const updatedItems = foodItems?.map((category) => {
        const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
          if (typeof foodItem === "object" && foodItem !== null) {
            // Check if any of the searchValues matches food item properties
            return lowerCaseData.some((searchValue) =>
              foodItem.itemName?.toLowerCase().includes(searchValue) ||
              foodItem.description?.toLowerCase().includes(searchValue) ||
              foodItem.keywordTagName?.toLowerCase().includes(searchValue) ||
              foodItem.keywordDetails?.toLowerCase().includes(searchValue) ||
              foodItem.sectionName?.toLowerCase().includes(searchValue)
            );
          }
          return false;
        });
  
        return {
          ...category,
          foodItems: selectedFoodItems,
        };
      });
  
      const itemsData = updatedItems?.flatMap((category) => category.foodItems);
      dispatch(setFilteredItemData(itemsData));
    } else {
      const updatedItems = filteredFoodItems?.map((category) => {
        const selectedFoodItems = category?.foodItems?.filter((foodItem) => {
          if (typeof foodItem === "object" && foodItem !== null) {
            // Check if any of the searchValues matches the itemName
            return lowerCaseData.some((searchValue) =>
              foodItem.itemName?.toLowerCase().includes(searchValue)
            );
          }
          return false;
        });
  
        return {
          ...category,
          foodItems: selectedFoodItems,
        };
      });
  
      const itemsData = updatedItems?.flatMap((category) => category.foodItems);
      dispatch(setFilteredItemData(itemsData));
    }
  };  

  /* Getting Search Value from Redux */
  useEffect(() => {
    filterFoodItemNameBySearchValue(searchValueFromRedux);
  }, [searchOption, searchValueFromRedux, selectedMenuFromRedux]);

  /* Setting Menu on Section Click */
  const setMenuOnSectionClick = useCallback(
    (menuId) => {
      const matchingMenu = menuListFromRedux.find((menu) => menu.id === menuId);
      if (matchingMenu) {
        dispatch(setSelectedMenu(matchingMenu));
      }
    },
    [dispatch, menuListFromRedux]
  );

  /* Checking if the restaurant page has any food items */
  const [hasFoodItem, setHasFoodItem] = useState(false);
  const hasFoodItems = () => {
    return (filteredFoodItems ?? []).some((item) => item.foodItems?.length > 0);
  };
  useEffect(() => {
    if (hasFoodItems()) {
      setHasFoodItem(true);
    } else {
      setHasFoodItem(false);
    }
  }, [filteredFoodItems]);

  // Time Schedular popup Modal showing, event is coming from slider.js
  const [showSchedule, setShowSchedule] = useState(false);
  const timeSchedule = useRef(null);

  const openScheduleModal = () => {
    setShowSchedule(true);
  };

  const [colseScheduler, setCloseScheduler] = useState(false);

  const closeScheduleModal = () => {
    setShowSchedule(false);
    setCloseScheduler(true);

    setTimeout(() => {
      setCloseScheduler(false);
    }, 2000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timeSchedule.current &&
        !timeSchedule.current.contains(event.target)
      ) {
        closeScheduleModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* sub-section onclick functionality */

  const [subSectionClicked, setSubSectionClicked] = useState(false); //for the conditional rendering of the UI
  const [selectedSubSectionId, setSelectedSubSectionId] = useState(null);
  const [newFood, setNewFood] = useState([]); // fooditems after filter by subSectionId;
  useEffect(() => {
    setSearchLoading(true);

    const updatedItems = foodItems.map((category) => {
      const selectedFoodItems = category.foodItems.filter((foodItem) => {
        return foodItem.subSectionId === selectedSubSectionId;
      });
      return { ...category, foodItems: selectedFoodItems };
    });
    setNewFood(updatedItems);

    setTimeout(() => {
      setSearchLoading(false);
    }, 200);
  }, [selectedSubSectionId]);

  const [allButtonClicked, setAllButtonClicked] = useState(false);

  /* reset the conditional rendering */
  useEffect(() => {
    setSubSectionClicked(false);
  }, [
    selectedMenuFromRedux,
    firstMenu,
    searchValueFromRedux,
    allButtonClicked,
  ]);

  /*filterbar all button click functionality */
  const [allFoodItem, setAllFoodItem] = useState();

  useEffect(() => {
    setSearchLoading(true);
    setAllFoodItem(foodItemAfterMenuSelection);
    setTimeout(() => {
      setSearchLoading(false);
    }, 200);
  }, [allButtonClicked]);

  //Disabling all button filtering.
  useEffect(() => {
    setAllButtonClicked(false);
  }, [filteredFoodItems, selectedMenuFromRedux, searchOption]);

  // Filter Bar-Section Button Functionality;
  const [sectionClicked, setSectionClicked] = useState(false);
  const [clickedSectionId, setClickedSectionId] = useState();

  const [filteredFoodItemsBySectionId, setFilteredFoodItemsBySectionId] =
    useState([]);

  useEffect(() => {
    if (sectionClicked) {
      setSubSectionClicked(false);
      setSelectedSubSectionId();
      setAllButtonClicked(false);

      const updatedItemsAfterFilter = foodItems.map((category) => {
        const filteredItemsBySectionId = category.foodItems.filter((item) => {
          return item.sectionId === clickedSectionId;
        });

        return { ...category, foodItems: filteredItemsBySectionId };
      });

      setFilteredFoodItemsBySectionId(updatedItemsAfterFilter);
    }
  }, [sectionClicked]);

  // Resetting Sectionclick checker
  useEffect(() => {
    setSectionClicked(false);
    setClickedSectionId();
  }, [
    allButtonClicked,
    selectedMenuFromRedux,
    searchValueFromRedux,
    subSectionClicked,
  ]);

  console.log("filter bar data 2: ",subSectionClicked, selectedSubSectionId, allFoodItem)

  return (
    <div className="overFlowhidden">
      <Navbar handleNavbar={searchDialogOpen} />
      {loading ? (
        <div>
          <Shimmer />
        </div>
      ) : (
        <>
          {/* <Cart /> */}
          <div className={`${blur ? "BackBlur" : ""}`} ref={myRef}>
            {restaurantData.backgroundImageUrl != undefined ? (
              <BannerSection
                backgroundImage={restaurantData}
                from={"restaurant"}
              ></BannerSection>
            ) : (
              <div style={{ marginTop: "10rem" }}></div>
            )}
            {/* Scroll to Menu onclick View Menu from RestaurantDetailView  */}

            <RestaurantDetailView
              test3Ref={test3Ref}
              restaurantDetails={restaurantData}
              onScheduleClick={openScheduleModal}
              openModalofTimeSchedul={openScheduleModal}
              colseScheduler={colseScheduler}
            ></RestaurantDetailView>
            <div
              ref={test3Ref}
              className={`customContainer me-2 row-filterbar ${
                isFilterBarFixed
                  ? "fixed bg-white position-fixed start-0 w-100"
                  : ""
              }`}
              style={{
                top: isFilterBarFixed && isScrollingUp ? "106px" : "0px",
                minHeight: "56.7px",
              }}
            >
              <FilterBar
                isFilterBarFixed={isFilterBarFixed}
                resUrl={resname}
                getSectionSubsectionId={getSectionSubsectionId}
                reloadAllFood={reloadAllFood}
                onApplyFilters={applyFilters} // for getting the filters conditional data
                currentCategory={currentCategory}
                onValueChange={handleScrollingUpValueChange}
                showHide={hasFoodItem}
                setSubSectionClicked={setSubSectionClicked}
                subSectionClicked={subSectionClicked}
                setSelectedSubSectionId={setSelectedSubSectionId}
                setAllButtonClicked={setAllButtonClicked}
                setClickedSectionId={setClickedSectionId}
                setSectionClicked={setSectionClicked}
                clickedSectionId={clickedSectionId}
                searchData = {searchData}
              />
            </div>
            <div ref={filterBarRef}></div>
            <div className="d-none"></div>
            <div
              className="customContainer  d-flex justify-content-end "
              onClick={onMenuiconClick}
            >
              <img
                src={isMenuSelected ? menuIcon : gridIcon}
                alt="menu"
                className="mobileDisplay"
                style={{
                  width: isMenuSelected ? "2rem" : "1.5rem",
                  height: isMenuSelected ? "2rem" : "1.5rem",
                }}
              />
            </div>
            {/* {isMenuSelected ?
                  <Test3 /> : <GalleryView type={'under'} />
                } */}

            {loaderState && (
              <div className="d-flex justify-content-center ">
                <img src={loadingGif} alt="" srcset="" />
              </div>
            )}
            {/* <span>{FoodItems.totalCount}</span>
            <span>{FoodItems.foodItemList.length}</span> */}

            {isMenuSelected ? (
              <div
                className="foodSelectionContainer newFeedback mx-auto"
                style={{ minHeight: "300px" }}
              >
                {/* <InfiniteScroll
                  className=''
                  dataLength={FoodItems.foodItemList.length}
                  next={() =>
                    // GetAllFoodByRestaurantIdPaged(resname, pageNumber + 1, 4)
                    GetFoodListPagination(pageNumber + 1, 4)
                  }
                  hasMore={FoodItems.foodItemList.length < FoodItems.totalCount}
                  loader={
                    <div className='d-flex justify-content-center '>
                      <img src={loadingGif} alt='' srcset='' />
                    </div>
                  }
                > */}

                {searchLoading ? (
                  <div className="d-flex justify-content-center">
                    <img src={loadingGif} alt="loader-gif" />
                  </div>
                ) : selectedMenuFromRedux === null &&
                  firstMenu === null &&
                  searchValueFromRedux === "" ? (
                  <h4 style={{ textAlign: "center", paddingTop: "100px" }}>
                    Please select a menu to view FoodItems.
                  </h4>
                ) : filteredFoodItems?.length <= 0 ? (
                  <h4 style={{ textAlign: "center", paddingTop: "100px" }}>
                    Currently, there are no food items available.
                  </h4>
                ) : (
                  <div className="testContainer mx-auto">
                    {/* Showing fooditem for all button click from filterbar */}
                    <div
                      ref={categoryRef}
                      style={{
                        display:
                          allButtonClicked && !subSectionClicked
                            ? "block"
                            : "none",
                      }}
                    >
                      {allFoodItem
                        ?.filter((category) => category?.foodItems?.length > 0)
                        .sort((a, b) => a.section.localeCompare(b.section))
                        .map((foodItem, index) => (
                          <div
                            key={foodItem.section}
                            id={foodItem.sectionId}
                            className="categoryContainerForSelection"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                                width: "fit-content",
                                 padding: "10px 0px",
                                 
                              }}
                            >
                              <button
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  outline: "none",
                                }}
                                onClick={() =>
                                  setMenuOnSectionClick(foodItem.menuId)
                                }
                              >
                                <CustomOutlinedTab>
                                  {foodItem.section}
                                </CustomOutlinedTab>
                              </button>

                              {searchValueFromRedux &&
                                searchOption === "globall" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: "15px",
                                      // border: '1px solid black',
                                      borderRadius: "5px",
                                      width: "fit-content",
                                      padding: "0px 10px",
                                      border: "1px solid transparent",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setMenuOnSectionClick(foodItem.menuId)
                                      }
                                    >
                                      <CiDeliveryTruck
                                        style={{ fontSize: "35px" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "left",
                                        }}
                                      >
                                        <p style={{ margin: "0px" }}>
                                          {foodItem.menuName
                                            ? foodItem.menuName
                                            : "NoMenu"}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0px",
                                            fontSize: "14px",
                                            color: "#8f8585",
                                          }}
                                        >
                                          {foodItem.menuType
                                            ? foodItem.menuType
                                            : "NoType"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="testContainer">
                              {foodItem.foodItems.map((foodItem) => (
                                <FoodCard
                                  key={foodItem.id}
                                  foodItemData={foodItem}
                                ></FoodCard>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>

                    {/* Showing fooditem for specific SubSection */}
                    <div
                      ref={categoryRef}
                      style={{
                        display:
                          !allButtonClicked && subSectionClicked ? "" : "none",
                      }}
                    >
                      {/* {foodItems.length < 0 ? (
                        newFood.map((foodItem, index) => (
                          <div
                            key={foodItem.section}
                            id={foodItem.sectionId}
                            className="categoryContainerForSelection"
                          >
                            <div className="testContainer">
                              {foodItem.foodItems.map((food) => (
                                <FoodCard key={food.id} foodItemData={food} />
                              ))}
                            </div>
                          </div>
                        ))
                      ) : (
                        <h3 style={{ margin: "100px auto" }}>
                          No Food Item Found
                        </h3>
                      )} */}
                      {newFood.every((foodItem) => foodItem.foodItems.length === 0) ? (
                          <h3 style={{ margin: "100px auto" }}>No Food Item Found</h3>
                        ) : (
                          newFood.map((foodItem, index) => (
                            <div
                              key={foodItem.section}
                              id={foodItem.sectionId}
                              className="categoryContainerForSelection"
                            >
                              <div className="testContainer">
                                {foodItem.foodItems.map((food) => (
                                  <FoodCard key={food.id} foodItemData={food} />
                                ))}
                              </div>
                            </div>
                          ))
                        )}
                    </div>

                    {/* Showing fooditem for specific Section */}
                    <div
                      ref={categoryRef}
                      style={{
                        display:
                          sectionClicked &&
                          !subSectionClicked &&
                          !!allButtonClicked
                            ? ""
                            : "none",
                      }}
                    >
                      {foodItems.length > 0 ? (
                        filteredFoodItemsBySectionId.map((foodItem, index) => (
                          <div
                            key={foodItem.section}
                            id={foodItem.sectionId}
                            className="categoryContainerForSelection"
                          >
                            <button
                              style={{
                                margin: "0px 0px 10px 0px",
                                padding: "0px",
                                border: "0px",
                                outline: "none",
                              }}
                              onClick={() =>
                                setMenuOnSectionClick(foodItem.menuId)
                              }
                            >
                              <CustomOutlinedTab>
                                {foodItem.section}
                              </CustomOutlinedTab>
                            </button>
                            <div className="testContainer">
                              {foodItem.foodItems.map((food) => (
                                <FoodCard key={food.id} foodItemData={food} />
                              ))}
                            </div>
                          </div>
                        ))
                      ) : (
                        <h3 style={{ margin: "100px auto" }}>
                          No Food Item Found
                        </h3>
                      )}
                    </div>

                    {/* Showing fooditem by menu selection / defaultview */}
                    <div
                      ref={categoryRef}
                      style={{
                        display:
                          subSectionClicked ||
                          allButtonClicked ||
                          sectionClicked
                            ? "none"
                            : "",
                      }}
                    >
                      {filteredFoodItems
                        ?.filter((category) => category?.foodItems?.length > 0)
                        .sort((a, b) => a.section.localeCompare(b.section))
                        .map((foodItem, index) => (
                          <div
                            key={foodItem.section}
                            id={foodItem.sectionId}
                            className="categoryContainerForSelection"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                                width: "fit-content",
                                padding: "10px 0px",
                              }}
                            >
                              <button
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  outline: "none",
                                }}
                                onClick={() =>
                                  setMenuOnSectionClick(foodItem.menuId)
                                }
                              >
                                <CustomOutlinedTab>
                                  {foodItem.section}
                                </CustomOutlinedTab>
                              </button>

                              {searchValueFromRedux &&
                                searchOption === "globall" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: "15px",
                                      // border: '1px solid black',
                                      borderRadius: "5px",
                                      width: "fit-content",
                                      padding: "0px 10px",
                                      border: "1px solid transparent",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setMenuOnSectionClick(foodItem.menuId)
                                      }
                                    >
                                      <CiDeliveryTruck
                                        style={{ fontSize: "35px" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "left",
                                        }}
                                      >
                                        <p style={{ margin: "0px" }}>
                                          {foodItem.menuName
                                            ? foodItem.menuName
                                            : "NoMenu"}
                                        </p>
                                        <p
                                          style={{
                                            margin: "0px",
                                            fontSize: "14px",
                                            color: "#8f8585",
                                          }}
                                        >
                                          {foodItem.menuType
                                            ? foodItem.menuType
                                            : "NoType"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div className="testContainer">
                              {foodItem.foodItems.map((foodItem) => (
                                <FoodCard
                                  key={foodItem.id}
                                  foodItemData={foodItem}
                                  restaurantId={restaurantData.id}
                                ></FoodCard>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>

                    {/* End */}

                    {/* {loaderState == false && <FoodCard></FoodCard>} */}
                    {/* {FoodItems.foodItemList.map((dt, i) => {
                        return <FoodCard key={i} foodItemData={dt}></FoodCard>;
                      })} */}

                    {/* {FoodItems.foodItemList.slice(0, 4).map((dt, i) => (
                        <FoodCard key={i} foodItemData={dt}></FoodCard>
                      ))} */}
                  </div>
                )}

                {/* </InfiniteScroll> */}
              </div>
            ) : (
              <GalleryView type={"under"} />
            )}
            <BottomBar />
          </div>
          {/* <div id="container">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div> */}
        </>
      )}
      {showSchedule && (
        <div
          className="modal show"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Modal.Dialog style={{ width: "370px" }} ref={timeSchedule}>
            <Modal.Header closeButton onClick={() => closeScheduleModal()}>
              <Modal.Title>
                <h5 style={{ fontWeight: "bold" }}>{resname} Opening Hours</h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <h6 style={{ margin: 0 }}>Day / Date</h6>
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <h6 style={{ margin: 0 }}>Time</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      Sat - Tues
                    </td>
                    <td
                      style={{
                        borderRight: "none",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      09:00 AM - 09:00 PM
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      19 Feb 24
                    </td>
                    <td
                      style={{
                        borderRight: "none",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      09:00 AM - 11:00 PM
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      19 Feb 24
                    </td>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      09:00 AM - 11:00 PM
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="success"
                style={{
                  backgroundColor: "transparent",
                  color: "green",
                  fontWeight: "bold",
                }}
                onClick={() => closeScheduleModal()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </div>
  );
};

export default Restaurant;
