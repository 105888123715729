import React from "react";
import axios from 'axios';



const apiAddress = process.env.REACT_APP_SECRET;


export const GetFoodListByRestaurantId = async (restaurantId, pageNo, pageSize) => {
    var res = {
      foodItemList: [],
      totalCount:0
  }
    await axios
    .get(apiAddress + "/api/FoodItems/GetFoodItemForClientendByRestaurantIdPaged?urlname="+restaurantId+"&pageNo="+pageNo+"&pageSize="+pageSize)
    .then((response) => {
      res.totalCount=response.data.totalCount
      res.foodItemList= response.data.fooditemsList  
    });
    return res
}
  

export const GetFoodListByFilterbarClick = async (typeId, restaurantId, type, pageNo, pageSize) => {
 
    var res = {
      foodItemList: [],
      totalCount:0
  }
    await axios
    .get(apiAddress + "/api/FoodItems/GetRestaurantFoodItemPagedBysectionOrSubsection?sectionOrSubsectionId="+typeId+"&restaurantId="+restaurantId+"&type="+type+"&pageNo="+pageNo+"&pageSize="+pageSize)
    .then((response) => {
      res.totalCount=response.data.totalCount
      res.foodItemList= response.data.fooditemsList
     
    });
    return res
  }