const initialState = 'Search'

const changeSearchName = (state = initialState, action) => {
  switch (action.type) {
    case 'searchValue':
      return action.payload

    default:
      return state
  }
}
export default changeSearchName
