import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { RxCross1 } from "react-icons/rx";

function ReceiptModal({ open, handleClose, timeLineData }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* Header */}
      <DialogTitle sx={{ textAlign: "center", pb: 0 }}>
        <Typography variant="h6" fontWeight="bold">
          Receipt
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <RxCross1 />
        </IconButton>
      </DialogTitle>

      {/* Subtitle */}
      <DialogContent>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          textAlign="center"
          mb={3}
        >
          Monitor your order with no delay
        </Typography>

        {/* Timeline */}
        <Box sx={{ position: "relative", px: 4 }}>
          {/* Line */}
          <Box
            sx={{
              position: "absolute",
              top: "16px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "2px",
              height: `calc(100% - 32px)`,
              backgroundColor: "#ccc",
            }}
          />

          {/* Timeline Items */}
          {timeLineData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                alignItems: "center",
                mb: index === timeLineData.length - 1 ? 0 : 6,
              }}
            >
              {/* Dot */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "16px",
                  height: "16px",
                  backgroundColor: "#000",
                  borderRadius: "50%",
                  border: "2px solid white",
                  zIndex: 1,
                }}
              />
              {/* Content */}
              <Box
                sx={{
                  textAlign: index % 2 === 0 ? "right" : "left",
                  width: "50%",
                  px: 2,
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  sx={{ color: "#333" }}
                >
                  {item.title}
                </Typography>
                {item.details.map((detail, i) => (
                  <Typography
                    key={i}
                    variant="body2"
                    color="textSecondary"
                    sx={{ lineHeight: 1.5 }}
                  >
                    {detail}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ReceiptModal;
