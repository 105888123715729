import React, { useState, useEffect } from "react";
import "./PostDisplay.css";
import pkbImg from "./../../Assets/Image/PKB.png";
import MoviePoster from "./../../Assets/Image/MoviePoster 2.png";
import CommentDisplay from "./CommentDisplay/CommentDisplay";
import bannerImg from "./../../Assets/Image/banner.jpg";
import product from "./../../Assets/Image/product (1).jpg";
import LeoImg from "./../../Assets/Image/Leo.png";
import $ from "jquery";
import ReplyComponent from "./ReplyComponent/ReplyComponent";
import GalleryModal from "../GalleryModal/GalleryModal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import removeIcon from "./../../Assets/Icons/removeBlack.png";
import removeBlack from "./../../Assets/Icons/remove.png";
import Postimages from "./postimages/Postimages";
import CommentComponent from "./CommentComponent/CommentComponent";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import p1 from "../../Assets/Image/1 44.png";
import p2 from "../../Assets/Image/1 51.png";
import p3 from "../../Assets/Image/1 52.png";
import p4 from "../../Assets/Image/1 54 (1).png";
import p5 from "../../Assets/Image/1 54.png";
import p6 from "../../Assets/Image/1 56.png";
import p7 from "../../Assets/Image/1 57 (1).png";
import p8 from "../../Assets/Image/1 57.png";
import p9 from "../../Assets/Image/1 89.png";
import p10 from "../../Assets/Image/1 44 (1).png";

import commentIcon from "../../Assets/Icons/chat-bubble.png";
import shareIcon from "../../Assets/Icons/share.png";
import likeIcon from "../../Assets/Icons/like.png";
import heart from "./../../Assets/Icons/heart.png";
import info from "./../../Assets/Icons/info.png";
import plusWhite from "./../../Assets/Icons/plusWhite.png";
import plus from "./../../Assets/Icons/add.png";
import bookmark from "./../../Assets/Icons/bookmark.png";
import bookmarks from "./../../Assets/Icons/bookmarks.png";
import instBookmark from "./../../Assets/Icons/bookmark-white.png";
import axios, { Axios } from "axios";
import jwtDecode from "jwt-decode";

import Swal from "sweetalert2";
import EditShareYourThoughtsModal from "../../components/EditShareYourThoughtsComponents/Components/EditShareYourThoughtsModal";
import loadingImage from "../../Assets/Image/loading.gif";

function auto_grow(element) {
  element.style.height = "5px";
  element.style.height = element.scrollHeight + "px";
}

const PostDisplay = ({ postData, isDeleted }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("unamed data: ", postData?.postTaggedSectionWithDetailsList, postData?.postTaggedRestaurantWithDetailsList, postData?.postFoodItemTaggedMapperList);

  const postImages = postData?.postInfoImageMapperList;
  const restaurantName = postData?.restaurantName;
  const restaurantLogo = postData?.restaurantLogoUrl;

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const hideModalInfo = () => {
    window.$("#postModal").modal("hide");
    setShow(false);
  };
  const showModalInfo = (props) => {
    console.log("PostDisplay: " + props);
    setSelectedImage(props);
    window.$("#postModal").modal("show");
    setShow(true);
  };
  //item show under Pot Kettle Black functionality start
  const foodItems = [
    { name: "Burger", image: p1, type: "Food" },
    { name: "Pizza", image: p2, type: "Food" },
    { name: "Coffee", image: p3, type: "Food" },
    { name: "Chocolate", image: p4, type: "Food" },
    { name: "Shawarma", image: p5, type: "Food" },
    { name: "Sushi", image: p6 },
    { name: "Pasta", image: p7 },
    { name: "Ice cream", image: p8 },
    { name: "Sandwich", image: p9 },
    { name: "Fruit Salad", image: p10 },
    { name: "Pasta", image: p7 },
    { name: "Ice cream", image: p8 },
    { name: "Sandwich", image: p9 },
    { name: "Fruit Salad", image: p10 },
  ];
  const visibleItems = 1;
  const remainingItems = foodItems.length - visibleItems;

  const [showModal, setShowModal] = useState(false);

  const [filled, setFilled] = useState(false);
  const [animate, setAnimate] = useState(false);

  const apiAddress = process.env.REACT_APP_SECRET;

  const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");
  const [editPostData, setEditPostData] = useState({});

  let decodedData = {};
  let userId = "";
  if (gotasteClienttoken != null) {
    try {
      decodedData = jwtDecode(gotasteClienttoken);
      userId = decodedData.UserId;
    } catch (error) { }
  }

  const toggleFill = () => {
    if (userId && postData) {
      setFilled(!filled);
      setAnimate(true);
      axios
        .post(apiAddress + "/api/Post/InsertPostReact", {
          id: "N/A",
          postId: postData.postId,
          creationId: userId,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      setTimeout(() => {
        setAnimate(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (postData && postData.isReactedThisPost) {
      setFilled(true);
    }
  }, [postData]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModalClose = () => {
    if (showModal) {
      closeModal();
    }
  };

  const [showHoverModal, setShowHoverModal] = useState(false);
  const handleHoverEnter = () => {
    setShowHoverModal(true);
  };

  const handleHoverLeave = () => {
    setShowHoverModal(false);
  };

  const removePost = async (id, postInfoId) => {
    const result = await Swal.fire({
      title: "Do you want to delete this post?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${apiAddress}/api/Post/DeletePostByPostId?postId=${id}&postInfoId=${postInfoId}`);
        isDeleted();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire("Error!", "There was a problem deleting your post.", "error");
      }
    }
  };

  const showEditShareModalInfo = (postData) => {
    setEditPostData(postData);
    setShowEditModal(true);
    console.log("editPostData_____", postData);

    // window.$("#editShareYourThoughtsModal").modal("show");
  };

  const hideEditShareModalInfo = () => {
    setShowEditModal(false);
    setEditPostData({});
    window.$("#editShareYourThoughtsModal").modal("hide");
  };

  return (
    <>
      <div>
        {
          loading
          &&
          <div className="loading-image-container">
            <img src={loadingImage} alt="loading image" />
          </div>
        }
        <Modal
          show={show}
          onHide={hideModalInfo}
          backdrop="static"
          keyboard={false}
          fullscreen={true}
          className="postDisplayModal"
        >
          <Modal.Body className="postModal">
            <div class=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
              <span></span>
              <span></span>
              <button type="button " class={"close "} onClick={hideModalInfo}>
                <span aria-hidden="true">
                  <img
                    src={removeIcon}
                    className="removeImg desktopDisplay"
                    style={{ height: '30px", width: "1rem' }}
                    alt=""
                  />
                </span>
              </button>
            </div>
            <button
              type="button "
              class={"close gallaryModelCloseButton"}
              onClick={hideModalInfo}
              style={{
                position: "absolute",
                right: "0.8rem",
                top: "1rem",
                width: "44px",
                background: "transparent",
                border: "none",
              }}
            >
              <span aria-hidden="true">
                <img
                  src={removeBlack}
                  className="removeImg mobileDisplay "
                  style={{ height: '30px", width: "1rem' }}
                  alt=""
                />
              </span>
            </button>
            {/* <div className='w-100 CommentComponentDiv fixed-bottom'>
            <CommentComponent />
          </div> */}
            <Swiper
              className="gallerySwiper"
              spaceBetween={30}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={1}
              hashNavigation={{
                watchState: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
            >
              <SwiperSlide>
                {/* <PostModal selectedImage={bannerImg} /> */}
                <GalleryModal
                  selectedImage={postImages}
                  postData={postData}
                  info={info}
                />
              </SwiperSlide>
            </Swiper>
          </Modal.Body>
        </Modal>
        {/* onClick={() => showModalInfo(bannerImg)} */}
        <div className="card PostCard">
          <div className="card-body" onClick={handleModalClose}>
            <div className=" postHeader">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={restaurantLogo}
                    alt="restaurantLogo"
                    className="postOwnerImg me-2"
                  />
                  <h6>{restaurantName}</h6>
                </div>

                <div
                  className="d-flex flex-row justify-content-between"
                // style={{ height: "1.9rem" }}
                >
                  {/* three dot start */}
                  <div class="dropdown">
                    <span
                      class="dropdown-toggle three-dot"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </span>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <button
                          class="dropdown-item"
                          onClick={() => showEditShareModalInfo(postData)}
                        >
                          Edit post
                        </button>
                      </li>
                      <li>
                        <button
                          class="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => removePost(postData.postId, postData.postInfoId)}
                        >
                          Delete
                        </button>
                      </li>
                      {/* <li><a class="dropdown-item" href="#">Subscribe</a></li> */}
                    </ul>
                  </div>
                  {/* <img src={instBookmark} alt='bookmark' style={{width:'1.3rem', height:'1.5rem'}}/> */}
                  {/* three dot end */}
                </div>
              </div>
              {/* Linked Item showing block */}
              <div className="col mt-2">
                <div
                  className=" d-flex align-items-center "
                  style={{ gap: "5px" }}
                >
                  {/* plus icon logic*/}
                  {postData.postTaggedMenuWithDetailsList?.length > 0 ||
                    postData.postFoodItemTaggedMapperList?.length > 0 ||
                    postData.postTaggedRestaurantWithDetailsList?.length > 0 ||
                    postData.postTaggedSectionWithDetailsList?.length > 0 ? (
                    <div className="buy-butoon" onClick={openModal}>
                      <img
                        src={plusWhite}
                        alt="plus"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginRight: "2px",
                          cursor: "pointer",
                          borderRadius: "30px 30px",
                          color: "lightgray",
                        }}
                      />{" "}
                      <span> Buy</span>
                    </div>
                  ) : (
                    <span></span>
                  )}

                  {/* Menu count */}
                  {
                    // postData?.linkedItemType === 'Menu' &&
                    postData?.postTaggedMenuWithDetailsList?.length > 0 &&
                    postData?.postTaggedMenuWithDetailsList
                      ?.slice(0, visibleItems)
                      .map((item, index) => (
                        <div
                          key={index}
                          className="foodItem"
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          <span>{item.menuName} and </span>
                        </div>
                      ))
                  }

                  {/* {
                  // postData?.linkedItemType === 'Menu' &&
                  postData?.postTaggedMenuWithDetailsList?.length > 1 && (
                    <span>
                      and
                      <span
                        className='moreItemsLink'
                        onClick={openModal}
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        {`  ${postData?.postTaggedMenuWithDetailsList?.length - 1
                          } more Menu and`}
                      </span>
                    </span>
                  )} */}

                  {/* Section Hints */}

                  {/* {postData?.linkedItemType === 'Section' &&
                  postData?.postTaggedSectionWithDetailsList.length > 0 && (
                    <img
                      src={plus}
                      alt='plus'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={openModal}
                    />
                  )} */}
                  {/* Section Count */}

                  {postData?.postTaggedMenuWithDetailsList?.length > 0 ? (
                    <p
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        marginBottom: "0",
                      }}
                      onClick={openModal}
                      onMouseEnter={handleHoverEnter}
                      onMouseLeave={handleHoverLeave}
                    >
                      {(postData?.postTaggedSectionWithDetailsList?.length ||
                        0) +
                        (postData?.postTaggedRestaurantWithDetailsList
                          ?.length || 0) +
                        (postData?.postFoodItemTaggedMapperList?.length ||
                          0)}{" "}
                      more items
                    </p>
                  ) : (
                    <>
                      {/* Check if there's a section list and render items if available */}
                      {postData?.postTaggedSectionWithDetailsList?.length >
                        0 && (
                          <>
                            {postData.postTaggedSectionWithDetailsList
                              .slice(0, visibleItems)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="foodItem"
                                  onMouseEnter={handleHoverEnter}
                                  onMouseLeave={handleHoverLeave}
                                >
                                  <span>{item.sectionName}</span>
                                </div>
                              ))}

                            {/* Check if there are more items to show */}
                            {postData.postTaggedSectionWithDetailsList.length >
                              visibleItems && (
                                <span>
                                  and
                                  <span
                                    className="moreItemsLink"
                                    onClick={openModal}
                                    onMouseEnter={handleHoverEnter}
                                    onMouseLeave={handleHoverLeave}
                                  >
                                    {` ${postData.postTaggedSectionWithDetailsList
                                        .length - visibleItems
                                      } more Section${postData.postTaggedSectionWithDetailsList
                                        .length -
                                        visibleItems >
                                        1
                                        ? "s"
                                        : ""
                                      }`}
                                  </span>
                                </span>
                              )}
                          </>
                        )}
                    </>
                  )}

                  {/* Restaurant Hint */}

                  {/* {postData?.linkedItemType === 'Restaurant' &&
                  postData?.postTaggedRestaurantWithDetailsList.length > 0 && (
                    <img
                      src={plus}
                      alt='plus'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={openModal}
                    />
                  )} */}
                  {/* Restaurnat Count */}
                  {postData?.postTaggedSectionWithDetailsList?.length > 0 ||
                    postData?.postTaggedRestaurantWithDetailsList?.length > 0 ? (
                    <p></p>
                  ) : (
                    <>
                      {
                        // postData?.linkedItemType === 'Restaurant' &&
                        postData?.postTaggedRestaurantWithDetailsList?.length >
                        0 &&
                        postData?.postTaggedRestaurantWithDetailsList
                          ?.slice(0, visibleItems)
                          .map((item, index) => (
                            <div
                              key={index}
                              className="foodItem"
                              onMouseEnter={handleHoverEnter}
                              onMouseLeave={handleHoverLeave}
                            >
                              <span>{item.restaurantName}</span>
                            </div>
                          ))
                      }

                      {
                        // postData?.linkedItemType === 'Restaurant' &&
                        postData?.postTaggedRestaurantWithDetailsList?.length >
                        1 && (
                          <span>
                            and
                            <span
                              className="moreItemsLink"
                              onClick={openModal}
                              onMouseEnter={handleHoverEnter}
                              onMouseLeave={handleHoverLeave}
                            >
                              {`  ${postData?.postTaggedRestaurantWithDetailsList
                                  ?.length - 1
                                } more`}
                            </span>
                          </span>
                        )
                      }
                    </>
                  )}

                  {/* foodItems hint */}

                  {/* {postData?.linkedItemType === 'FoodItem' &&
                  postData?.postFoodItemTaggedMapperList.length > 0 && (
                    <img
                      src={plus}
                      alt='plus'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={openModal}
                    />
                  )} */}
                  {/* Food Items count */}
                  {postData?.postTaggedRestaurantWithDetailsList?.length > 0 ||
                    postData?.postTaggedSectionWithDetailsList?.length > 0 ||
                    postData?.postTaggedMenuWithDetailsList?.length > 0 ? (
                    <p></p>
                  ) : (
                    <>
                      {
                        // postData?.linkedItemType === 'FoodItem' &&
                        postData?.postFoodItemTaggedMapperList?.length > 0 &&
                        postData?.postFoodItemTaggedMapperList
                          ?.slice(0, visibleItems)
                          .map((item, index) => (
                            <div
                              key={index}
                              className="foodItem"
                              onMouseEnter={handleHoverEnter}
                              onMouseLeave={handleHoverLeave}
                            >
                              <span>{item.itemName}</span>
                            </div>
                          ))
                      }

                      {
                        // postData?.linkedItemType === 'FoodItem' &&
                        postData?.postFoodItemTaggedMapperList?.length > 1 && (
                          <span>
                            and
                            <span
                              className="moreItemsLink"
                              onClick={openModal}
                              onMouseEnter={handleHoverEnter}
                              onMouseLeave={handleHoverLeave}
                            >
                              {`  ${postData?.postFoodItemTaggedMapperList?.length -
                                1
                                } more`}
                            </span>
                          </span>
                        )
                      }
                    </>
                  )}

                  {/* Hover Model Start */}

                  {showHoverModal &&
                    // postData?.linkedItemType === 'Section' &&
                    postData?.postTaggedSectionWithDetailsList?.length > 0 && (
                      <div
                        className="hoverModal"
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        <ul style={{ listStyleType: "none", padding: "0" }}>
                          {postData?.postTaggedSectionWithDetailsList
                            .slice(0, 10)
                            .map((item, index) => (
                              <li
                                key={index}
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {item.sectionName}
                                </span>
                              </li>
                            ))}
                          {postData?.postTaggedSectionWithDetailsList?.length >
                            10 && (
                              <li
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  and{" "}
                                  {postData?.postTaggedSectionWithDetailsList
                                    .length - 10}{" "}
                                  more
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    )}

                  {/* Restaurnat hints section */}
                  {showHoverModal &&
                    // postData?.linkedItemType === 'Restaurant' &&
                    postData?.postTaggedRestaurantWithDetailsList?.length >
                    0 && (
                      <div
                        className="hoverModal"
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        <ul style={{ listStyleType: "none", padding: "0" }}>
                          {postData?.postTaggedRestaurantWithDetailsList
                            .slice(0, 10)
                            .map((item, index) => (
                              <li
                                key={index}
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {item.restaurantName}
                                </span>
                              </li>
                            ))}
                          {postData?.postTaggedRestaurantWithDetailsList
                            ?.length > 10 && (
                              <li
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  and{" "}
                                  {postData?.postTaggedRestaurantWithDetailsList
                                    .length - 10}{" "}
                                  more
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    )}

                  {/* Menu Hints */}
                  {showHoverModal &&
                    // postData?.linkedItemType === 'Menu' &&
                    postData?.postTaggedMenuWithDetailsList?.length > 0 && (
                      <div
                        className="hoverModal"
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        <ul style={{ listStyleType: "none", padding: "0" }}>
                          {postData?.postTaggedMenuWithDetailsList
                            .slice(0, 10)
                            .map((item, index) => (
                              <li
                                key={index}
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {item.menuName}
                                </span>
                              </li>
                            ))}
                          {postData?.postTaggedMenuWithDetailsList?.length >
                            10 && (
                              <li
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  and{" "}
                                  {postData?.postTaggedMenuWithDetailsList
                                    .length - 10}{" "}
                                  more
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    )}

                  {/* Food Items Hits */}
                  {showHoverModal &&
                    // postData?.linkedItemType === 'FoodItem' &&
                    postData?.postFoodItemTaggedMapperList?.length > 0 && (
                      <div
                        className="hoverModal"
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        <ul style={{ listStyleType: "none", padding: "0" }}>
                          {postData?.postFoodItemTaggedMapperList
                            .slice(0, 10)
                            .map((item, index) => (
                              <li
                                key={index}
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {item.itemName}
                                  {/* Showing the Restaurnat Items */}
                                  {postData?.postTaggedRestaurantWithDetailsList?.map(
                                    (filteredItem, idx) => (
                                      <p
                                        key={idx}
                                        style={{
                                          margin: "0",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {filteredItem.restaurantName}
                                      </p>
                                    )
                                  )}
                                  {/* Showing Manu */}
                                  {/* {postData.postTaggedMenuWithDetailsList.map((filteredItem, idx) => (
                                  <p key={idx} style={{ margin: '0', fontSize: '10px' }}>
                                    {filteredItem.menuName}
                                  </p>
                                ))} */}
                                  {/* Showing Section */}
                                  {postData?.postTaggedSectionWithDetailsList?.map(
                                    (filteredItem, idx) => (
                                      <p
                                        key={idx}
                                        style={{
                                          margin: "0",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {filteredItem.sectionName}
                                      </p>
                                    )
                                  )}
                                </span>
                              </li>
                            ))}
                          {postData?.postFoodItemTaggedMapperList?.length >
                            10 && (
                              <li
                                className="d-flex align-items-center"
                                style={{ margin: "0 0 5px" }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  and{" "}
                                  {postData?.postFoodItemTaggedMapperList
                                    ?.length - 10}{" "}
                                  more
                                </span>
                              </li>
                            )}
                        </ul>
                      </div>
                    )}
                </div>
              </div>

              <Modal // change here
                className="moreItemModal"
                show={showModal}
                onHide={closeModal}
                centered
                backdrop="static"
                keyboard={false}
              >
                {/* {
              // postData?.linkedItemType === 'Menu' &&
                postData?.postTaggedMenuWithDetailsList.length > 0 && (
                  <Modal.Header closeButton style={{ borderBottom: '0' }}>
                    <Modal.Title>
                      {postData?.postTaggedMenuWithDetailsList.length}
                      {postData?.postTaggedMenuWithDetailsList.length > 1
                        ? ' menus'
                        : ' menu'}
                    </Modal.Title>
                  </Modal.Header>
                )}

              {
              // postData?.linkedItemType === 'Restaurant' &&
                postData?.postTaggedRestaurantWithDetailsList.length > 0 && (
                  <Modal.Header closeButton style={{ borderBottom: '0' }}>
                    <Modal.Title>
                      {postData?.postTaggedRestaurantWithDetailsList.length}
                      {postData?.postTaggedRestaurantWithDetailsList.length > 1
                        ? ' restaurants'
                        : ' restaurant'}
                    </Modal.Title>
                  </Modal.Header>
                )}

              {
              // postData?.linkedItemType === 'Section' &&
                postData?.postTaggedSectionWithDetailsList.length > 0 && (
                  <Modal.Header closeButton style={{ borderBottom: '0' }}>
                    <Modal.Title>
                      {postData?.postTaggedSectionWithDetailsList.length}
                      {postData?.postTaggedSectionWithDetailsList.length > 1
                        ? ' sections'
                        : ' section'}
                    </Modal.Title>
                  </Modal.Header>
                )}

              {
              // postData?.linkedItemType === 'FoodItem' &&
                postData?.postFoodItemTaggedMapperList.length > 0 && (
                  <Modal.Header closeButton style={{ borderBottom: '0' }}>
                    <Modal.Title>
                      {postData?.postFoodItemTaggedMapperList.length}
                      {postData?.postFoodItemTaggedMapperList.length > 1
                        ? ' food items'
                        : ' food item'}
                    </Modal.Title>
                  </Modal.Header>
                )} */}
                <Modal.Header
                  closeButton
                  style={{
                    borderBottom: "0",
                    padding: "8px 25px",
                    marginTop: "5px",
                  }}
                >
                  <Modal.Title>
                    <h3>
                      {(postData.postTaggedMenuWithDetailsList?.length || 0) +
                        (postData.postFoodItemTaggedMapperList?.length || 0) +
                        (postData.postTaggedRestaurantWithDetailsList?.length ||
                          0) +
                        (postData.postTaggedSectionWithDetailsList?.length ||
                          0)}{" "}
                      Items
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginTop: "0px", paddingTop: "0px" }}>
                  <ul
                    style={{ listStyleType: "none", padding: "0" }}
                    className="modal-list-ul"
                  >
                    {
                      // postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedMenuWithDetailsList?.map(
                        (item, index) => (
                          <li
                            key={index}
                            className="itemLi d-flex justify-content-between"
                            style={{
                              // margin: '0 0 15px',
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div>
                              <img
                                src={item.logoUrl}
                                alt={item.logoUrl}
                                className="rounded-circle me-3 align-items-center justify-content-center align-content-center mt-2"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  position: "relative",
                                }}
                              />

                              <span style={{ fontSize: "17x" }}>
                                {item.menuName}
                              </span>
                              <span
                                className="searchText searchratingText"
                                style={{
                                  backgroundColor: "#80615b",
                                  borderRadius: "20px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  marginLeft: "20px",
                                  color: "white",
                                }}
                              >
                                Menu
                              </span>
                              <div
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                }}
                              >
                                <img
                                  src={info}
                                  alt="info"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginRight: "5px",
                                  }}
                                />
                                <img
                                  src={plus}
                                  alt="plus"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        )
                      )
                    }

                    {
                      // postData?.linkedItemType === 'Restaurant' &&
                      postData?.postTaggedRestaurantWithDetailsList?.map(
                        (item, index) => (
                          <li key={index} className="my-2">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <img
                                src={item.logoUrl}
                                alt={item.logoUrl}
                                className="rounded-circle me-3"
                                style={{ width: "30px", height: "30px" }}
                              />

                              <span
                                style={{
                                  fontSize: "17px",
                                  marginRight: "20px",
                                }}
                              >
                                {item.restaurantName}
                              </span>

                              <span
                                className="searchText searchratingText"
                                style={{
                                  backgroundColor: "#7390e5",
                                  borderRadius: "20px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  color: "white",
                                  marginRight: "auto", // Push "FoodItem" to the left as much as possible
                                }}
                              >
                                Restaurant
                              </span>

                              <div
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                }}
                              >
                                <img
                                  src={info}
                                  alt="info"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginRight: "5px",
                                  }}
                                />
                                <img
                                  src={plus}
                                  alt="plus"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              </div>
                            </div>
                          </li>

                          // <li
                          //   key={index}
                          //   className='itemLi d-flex justify-content-between'
                          //   style={{ margin: '0 0 15px' }}
                          // >
                          //   <div>
                          //     <img
                          //       src={item.logoUrl}
                          //       alt={item.logoUrl}
                          //       className='rounded-circle me-3 align-items-center justify-content-center align-content-center mt-2'
                          //       style={{ width: '40px', height: '40px' }}
                          //     />

                          //     <span style={{ fontSize: '20px' }}>
                          //       {item.restaurantName}
                          //     </span>
                          //     <span
                          //       className='searchText searchratingText'
                          //       style={{
                          //         backgroundColor: '#7390e5',
                          //         borderRadius: '20px',
                          //         fontSize: '14px',
                          //         padding: '4px 10px',
                          //         marginLeft: '20px',
                          //         color: 'white',
                          //       }}
                          //     >
                          //       Restaurant
                          //     </span>
                          //   </div>
                          // </li>
                        )
                      )
                    }

                    {
                      // postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedSectionWithDetailsList?.map(
                        (item, index) => (
                          <li
                            key={index}
                            className="itemLi d-flex justify-content-between"
                            style={{
                              // margin: '0 0 15px',
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <div>
                              <img
                                src={item.logoUrl}
                                alt={item.logoUrl}
                                className="rounded-circle me-3 align-items-center justify-content-center align-content-center mt-2"
                                style={{ width: "30px", height: "30px" }}
                              />

                              <span style={{ fontSize: "17px" }}>
                                {item.sectionName}
                              </span>
                              <span
                                className="searchText searchratingText"
                                style={{
                                  backgroundColor: "#7390e5",
                                  borderRadius: "20px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  marginLeft: "20px",
                                  color: "white",
                                  marginRight: "auto",
                                }}
                              >
                                Section
                              </span>
                              <div
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                }}
                              >
                                <img
                                  src={info}
                                  alt="info"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginRight: "5px",
                                  }}
                                />
                                <img
                                  src={plus}
                                  alt="plus"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        )
                      )
                    }

                    {
                      // postData?.linkedItemType === 'FoodItem' &&
                      postData?.postFoodItemTaggedMapperList?.map(
                        (item, index) => (
                          <li key={index} className="my-2">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                margin: "0px",
                              }}
                            >
                              <img
                                src={item.itemImage}
                                alt={item.logoUrl}
                                className="rounded-circle me-3"
                                style={{ width: "30px", height: "30px" }}
                              />

                              <span
                                style={{
                                  fontSize: "17px",
                                  marginRight: "20px",
                                }}
                              >
                                {item.itemName}
                              </span>

                              <span
                                className="searchText searchratingText"
                                style={{
                                  backgroundColor: "#7390e5",
                                  borderRadius: "20px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  color: "white",
                                  marginRight: "auto", // Push "FoodItem" to the left as much as possible
                                }}
                              >
                                FoodItem
                              </span>

                              <div style={{ position: "absolute", right: 0 }}>
                                <img
                                  src={info}
                                  alt="info"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginRight: "5px",
                                  }}
                                />
                                <img
                                  src={plus}
                                  alt="plus"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        )
                      )
                    }
                  </ul>
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer> */}
              </Modal>
            </div>
            <div className="post ">
              <p className="ml-5" style={{ fontSize: "large" }}>
                {postData?.description}
              </p>
            </div>
            <div className="postSlider">
              {/* changeImages */}
              <Swiper
                spaceBetween={5}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={1}
                hashNavigation={{
                  watchState: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                className="postSwiper"
              >
                {postImages?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image.imageUploadpath}
                      alt=""
                      onClick={() => showModalInfo(image.imageUploadpath)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <Swiper
              className=""
              spaceBetween={30}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={1}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
            >
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>

              </SwiperSlide>
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='discountImage'>
                  <img src={bannerImg} alt="" onClick={() => showModalInfo(bannerImg)} className='image-Discount' />
                </div>
              </SwiperSlide>
            </Swiper> */}

              {/* <Postimages /> */}
            </div>
            <div className="postLikeDislike">
              {/* <hr style={{color:'#a39d9d'}}></hr> */}
              <div className="d-flex justify-content-between shared-MainPostLike">
                <div className="d-flex flex-row">
                  {/* <div className='d-flex justify-content-between align-items-center ms-1'> */}
                  <div style={{ marginRight: "1rem" }}>
                    {/* my heart goes on */}
                    {/* <img src={heart} alt='heart' className='postRectImage' /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill={filled ? "red" : "none"}
                      stroke="currentColor"
                      strokeWidth={filled ? "0" : "2"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={`postRectImage ${animate ? "animate" : ""}`}
                      onClick={toggleFill}
                      onAnimationEnd={() => setAnimate(false)}
                    >
                      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                    </svg>
                  </div>
                  {/* <h5> Likes 20</h5> */}
                  {/* </div> */}
                  {/* <div className='d-flex justify-content-between align-items-center ms-1'> */}
                  {/* <div style={{ marginRight: '1rem' }}>
                  <img src={commentIcon} className='postRectImage' />
                </div> */}
                  {/* <h5> Reply</h5>
              </div>
              <div className='d-flex justify-content-between align-items-center ms-1'> */}
                  {/* <div style={{ marginRight: '1rem' }}>
                  <img src={shareIcon} className='postRectImage' />
                </div>
              </div> */}
                  {/* <div>
                <img src={instBookmark} alt='bookmark' style={{width:'1.5rem', height:'1.5rem'}}/>
                </div> */}
                  {/* <h5> Share</h5> */}
                  {/* <img
                src={instBookmark}
                alt='bookmark'
                style={{ width: '1.1rem', height: '1.3rem', marginTop: '3px' }}
              />*/}
                </div>
              </div>
              {/* <hr style={{ color: '#a39d9d' }}></hr> */}
            </div>
            {/* <div className='postImg postComment'>
            <CommentDisplay />
          </div>
          <div className='postReply'>
            <ReplyComponent />
          </div> */}
          </div>
        </div>
      </div>
      {showEditModal && (
        <EditShareYourThoughtsModal
          show={showEditModal}
          setShow={setShowEditModal}
          showEditShareModalInfo={showEditShareModalInfo}
          hideEditShareModalInfo={hideEditShareModalInfo}
          data={editPostData}
        />
      )}
    </>
  );
};

export default PostDisplay;
