import { SELECT_FIRST_MENU_ITEM } from '../../actions/types';

const initialState = {
  selectedFirstMenuItem: null,
};

const selectFirstMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_FIRST_MENU_ITEM:
      return {
        ...state,
        selectedFirstMenuItem: action.payload
      };
    default:
      return state;
  }
};

export default selectFirstMenuReducer;
