import React, { useState, useEffect } from 'react';
import './GalleryModal.css';
import CommentDisplay from './../PostDisplay/CommentDisplay/CommentDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {
  Autoplay,
  Navigation,
  EffectCoverflow,
  Pagination,
  Zoom,
} from 'swiper';
import 'swiper/css/navigation';
import previewIcon from './../../Assets/Icons/previous1.png';
import removeIcon from './../../Assets/Icons/remove.png';
import removeWhiteIcon from './../../Assets/Icons/removeBlack.png';
import product1 from './../../Assets/Image/product (1).jpg';
import moviePoster from './../../Assets/Image/MoviePoster 2.png';
import plus from './../../Assets/Icons/plus.png';
import info from './../../Assets/Icons/info.png';
import LeoImg from './../../Assets/Image/Leo.png';
import profileImage from './../../Assets/Image/product (1).jpg';
import $ from 'jquery';
import sendIcon from './../../Assets/Image/send.png';
import ReplyComponent from './../PostDisplay/ReplyComponent/ReplyComponent';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CommentComponent from './../PostDisplay/CommentComponent/CommentComponent';
import PhotoGridGalleryComponent from '../Restaurant/PhotoGridGallery/Components/PhotoGridGalleryComponent';
import GalleryTestSwipper from './../Test5';
import Modal from 'react-bootstrap/Modal';

const GalleryModal = (props) => {
  console.log('GalleryModal: ', props.selectedImage);

  const postData = props?.postData;

  console.log('post data from gallery modal', postData);

  const visibleItems = 2;

  const restaurantName = postData.restaurantName;
  const restaurantLogo = postData?.restaurantLogoUrl;

  const hideModalInfo = () => {
    window.$('#galleryModal').modal('hide');
  };
  const [showSend, setShowSend] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const [showPage, setshowPage] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleModalClose = () => {
    if (showModal) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('click', () => {
      // setShowSend(false)
      if ($('.sendDiv').not('.sendDiv').length) {
        setShowSend(false);
      }
    });
  });

  const hideCollageModalInfo = (props) => {
    setshowPage(props);
  };
  const showCollageModalInfo = (props) => {
    setshowPage(props);
  };

  const [showHoverModal, setShowHoverModal] = useState(false);
  const handleHoverEnter = () => {
    setShowHoverModal(true);
  };

  const handleHoverLeave = () => {
    setShowHoverModal(false);
  };

  return (
    <div>
      <div
        className={` galleryModalDiv ${showPage === 0 ? 'd-flex' : 'd-none'}`}
      >
        <div className='d-flex align-items-center justify-content-center galleryModalImgDiv'>
          <button
            type='button '
            class={'close gallaryModelCloseButton'}
            onClick={hideModalInfo}
            style={{
              position: 'absolute',
              right: '0.8rem',
              top: '1rem',
              width: '44px',
              background: 'transparent',
              border: 'none',
            }}
          >
            <span aria-hidden='true'>
              <img
                src={removeWhiteIcon}
                className='removeImg desktopDisplay'
                style={{ height: '30px", width: "1rem' }}
                alt=''
              />
            </span>
          </button>

          <Swiper
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            navigation={true}
            spaceBetween={10}
            slidesPerView={1}
            modules={[Navigation]}
            className='galleryModalSwipper'
          >
            {props.selectedImage.map((image, index) => {
              return (
                <SwiperSlide>
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        src={image.imageUploadpath}
                        alt='test'
                        className='img-fluid selectedImage'
                        onClick={() => {
                          setSelectedImage(props.selectedImage);
                          setshowPage(1);
                        }}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className='position-relative galleryModalDivCommentScroll'>
          {/* here */}

          <div>
            <div className='card-body' onClick={handleModalClose}>
              <div className='d-flex align-items-center postHeader'>
                <img
                  src={restaurantLogo}
                  alt='restaurantLogo'
                  className='postOwnerImg'
                />
                <div className='col'>
                  <div
                    className='d-flex flex-row justify-content-between'
                    style={{ height: '1.9rem' }}
                  >
                    <h6>{restaurantName}</h6>
                    {/* three dot start */}
                    <div class='dropdown'>
                      <span
                        class='dropdown-toggle'
                        id='dropdownMenuButton1'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                        style={{ cursor: 'pointer' }}
                      >
                        <i class='fas fa-ellipsis-v'></i>
                      </span>
                      <ul
                        class='dropdown-menu'
                        aria-labelledby='dropdownMenuButton1'
                      >
                        <li>
                          <a class='dropdown-item' href='#'>
                            Edit post
                          </a>
                        </li>
                        <li>
                          <a class='dropdown-item' href='#'>
                            Delete
                          </a>
                        </li>
                        {/* <li><a class="dropdown-item" href="#">Subscribe</a></li> */}
                      </ul>
                    </div>
                    {/* <img src={instBookmark} alt='bookmark' style={{width:'1.3rem', height:'1.5rem'}}/> */}
                    {/* three dot end */}
                  </div>
                  <div className=' d-flex' style={{ gap: '5px' }}>
                    {/* <img
                      src={plus}
                      alt='plus'
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={openModal}
                    /> */}
                    {/* here */}
                    {/* {foodItems.slice(0, visibleItems).map((item, index) => (
                  <div
                    key={index}
                    className='foodItem'
                    onMouseEnter={handleHoverEnter}
                    onMouseLeave={handleHoverLeave}
                  > */}
                    {/* <img src={item.image} alt={item.name} className="foodImage" /> */}
                    {/* <span> {item.name}, </span>
                  </div> */}
                    {/* ))} */}
                    {postData?.linkedItemType === 'Menu' &&
                      postData?.postTaggedMenuWithDetailsList.length > 0 && (
                        <img
                          src={plus}
                          alt='plus'
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={openModal}
                        />
                      )}
                    {postData?.linkedItemType === 'Menu' &&
                      postData?.postTaggedMenuWithDetailsList
                        ?.slice(0, visibleItems)
                        .map((item, index) => (
                          <div
                            key={index}
                            className='foodItem'
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            <span>{item.menuName}</span>
                          </div>
                        ))}
                    {postData?.linkedItemType === 'Menu' &&
                      postData?.postTaggedMenuWithDetailsList.length > 2 && (
                        <span>
                          and
                          <span
                            className='moreItemsLink'
                            onClick={openModal}
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {`  ${
                              postData?.postTaggedMenuWithDetailsList.length - 2
                            } more`}
                          </span>
                        </span>
                      )}
                    {postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedSectionWithDetailsList.length > 0 && (
                        <img
                          src={plus}
                          alt='plus'
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={openModal}
                        />
                      )}
                    {postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedSectionWithDetailsList
                        ?.slice(0, visibleItems)
                        .map((item, index) => (
                          <div
                            key={index}
                            className='foodItem'
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            <span>{item.sectionName}</span>
                          </div>
                        ))}
                    {postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedSectionWithDetailsList.length > 2 && (
                        <span>
                          and
                          <span
                            className='moreItemsLink'
                            onClick={openModal}
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {`  ${
                              postData?.postTaggedSectionWithDetailsList
                                .length - 2
                            } more`}
                          </span>
                        </span>
                      )}
                    {postData?.linkedItemType === 'Restaurant' &&
                      postData?.postTaggedRestaurantWithDetailsList.length >
                        0 && (
                        <img
                          src={plus}
                          alt='plus'
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={openModal}
                        />
                      )}
                    {postData?.linkedItemType === 'Restaurant' &&
                      postData?.postTaggedRestaurantWithDetailsList
                        ?.slice(0, visibleItems)
                        .map((item, index) => (
                          <div
                            key={index}
                            className='foodItem'
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {/* You can directly access item.menuName */}
                            <span>{item.restaurantName}</span>
                          </div>
                        ))}
                    {postData?.linkedItemType === 'Restaurant' &&
                      postData?.postTaggedRestaurantWithDetailsList.length >
                        2 && (
                        <span>
                          and
                          <span
                            className='moreItemsLink'
                            onClick={openModal}
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {`  ${
                              postData?.postTaggedRestaurantWithDetailsList
                                .length - 2
                            } more`}
                          </span>
                        </span>
                      )}

                    {postData?.linkedItemType === 'FoodItem' &&
                      postData?.postFoodItemTaggedMapperList.length > 0 && (
                        <img
                          src={plus}
                          alt='plus'
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={openModal}
                        />
                      )}

                    {postData?.linkedItemType === 'FoodItem' &&
                      postData?.postFoodItemTaggedMapperList
                        ?.slice(0, visibleItems)
                        .map((item, index) => (
                          <div
                            key={index}
                            className='foodItem'
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {/* You can directly access item.menuName */}
                            <span>{item.itemName}</span>
                          </div>
                        ))}
                    {postData?.linkedItemType === 'FoodItem' &&
                      postData?.postFoodItemTaggedMapperList.length > 2 && (
                        <span>
                          and
                          <span
                            className='moreItemsLink'
                            onClick={openModal}
                            onMouseEnter={handleHoverEnter}
                            onMouseLeave={handleHoverLeave}
                          >
                            {`  ${
                              postData?.postFoodItemTaggedMapperList.length - 2
                            } more`}
                          </span>
                        </span>
                      )}

                    {showHoverModal &&
                      postData?.linkedItemType === 'Section' &&
                      postData?.postTaggedSectionWithDetailsList.length > 0 && (
                        <div
                          className='hoverModal'
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {postData?.postTaggedSectionWithDetailsList
                              .slice(0, 10)
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className='d-flex align-items-center'
                                  style={{ margin: '0 0 5px' }}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    {item.sectionName}
                                  </span>
                                </li>
                              ))}
                            {postData?.postTaggedSectionWithDetailsList.length >
                              10 && (
                              <li
                                className='d-flex align-items-center'
                                style={{ margin: '0 0 5px' }}
                              >
                                <span style={{ fontSize: '12px' }}>
                                  and{' '}
                                  {postData?.postTaggedSectionWithDetailsList
                                    .length - 10}{' '}
                                  more
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}

                    {showHoverModal &&
                      postData?.linkedItemType === 'Restaurant' &&
                      postData?.postTaggedRestaurantWithDetailsList.length >
                        0 && (
                        <div
                          className='hoverModal'
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {postData?.postTaggedRestaurantWithDetailsList
                              .slice(0, 10)
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className='d-flex align-items-center'
                                  style={{ margin: '0 0 5px' }}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    {item.restaurantName}
                                  </span>
                                </li>
                              ))}
                            {postData?.postTaggedRestaurantWithDetailsList
                              .length > 10 && (
                              <li
                                className='d-flex align-items-center'
                                style={{ margin: '0 0 5px' }}
                              >
                                <span style={{ fontSize: '12px' }}>
                                  and{' '}
                                  {postData?.postTaggedRestaurantWithDetailsList
                                    .length - 10}
                                  more
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}

                    {showHoverModal &&
                      postData?.linkedItemType === 'Menu' &&
                      postData?.postTaggedMenuWithDetailsList.length > 0 && (
                        <div
                          className='hoverModal'
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {postData?.postTaggedMenuWithDetailsList
                              .slice(0, 10)
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className='d-flex align-items-center'
                                  style={{ margin: '0 0 5px' }}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    {item.menuName}
                                  </span>
                                </li>
                              ))}
                            {postData?.postTaggedMenuWithDetailsList.length >
                              10 && (
                              <li
                                className='d-flex align-items-center'
                                style={{ margin: '0 0 5px' }}
                              >
                                <span style={{ fontSize: '12px' }}>
                                  and{' '}
                                  {postData?.postTaggedMenuWithDetailsList
                                    .length - 10}{' '}
                                  more
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}

                    {showHoverModal &&
                      postData?.linkedItemType === 'FoodItem' &&
                      postData?.postFoodItemTaggedMapperList.length > 0 && (
                        <div
                          className='hoverModal'
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          <ul style={{ listStyleType: 'none', padding: '0' }}>
                            {postData?.postFoodItemTaggedMapperList
                              .slice(0, 10)
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className='d-flex align-items-center'
                                  style={{ margin: '0 0 5px' }}
                                >
                                  <span style={{ fontSize: '12px' }}>
                                    {item.itemName}
                                  </span>
                                </li>
                              ))}
                            {postData?.postFoodItemTaggedMapperList.length >
                              10 && (
                              <li
                                className='d-flex align-items-center'
                                style={{ margin: '0 0 5px' }}
                              >
                                <span style={{ fontSize: '12px' }}>
                                  and{' '}
                                  {postData?.postFoodItemTaggedMapperList
                                    .length - 10}{' '}
                                  more
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    {/* {remainingItems > 0 && (
                      <span>
                        and
                        <span
                          className='moreItemsLink'
                          onClick={openModal}
                          onMouseEnter={handleHoverEnter}
                          onMouseLeave={handleHoverLeave}
                        >
                          {`  ${remainingItems} more`}
                        </span>
                      </span>
                    )}
                    {showHoverModal && (
                      <div
                        className='hoverModal'
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                      >
                        <ul style={{ listStyleType: 'none', padding: '0' }}>
                          {foodItems.slice(0, 10).map((item, index) => (
                            <li
                              key={index}
                              className='d-flex align-items-center'
                              style={{ margin: '0 0 5px' }}
                            >
                              <span style={{ fontSize: '12px' }}>
                                {item.name}
                              </span>
                            </li>
                          ))}
                          {foodItems.length > 10 && (
                            <li
                              className='d-flex align-items-center'
                              style={{ margin: '0 0 5px' }}
                            >
                              <span style={{ fontSize: '12px' }}>
                                and {foodItems.length - 10} more
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    )} */}
                  </div>
                </div>
                <Modal // change here
                  className='moreItemModal'
                  show={showModal}
                  onHide={closeModal}
                  centered
                  backdrop='static'
                  keyboard={false}
                >
                  {postData?.linkedItemType === 'Menu' &&
                    postData?.postTaggedMenuWithDetailsList.length > 0 && (
                      <Modal.Header closeButton style={{ borderBottom: '0' }}>
                        <Modal.Title>
                          {postData?.postTaggedMenuWithDetailsList.length}
                          {postData?.postTaggedMenuWithDetailsList.length > 1
                            ? ' menus'
                            : ' menu'}
                        </Modal.Title>
                      </Modal.Header>
                    )}

                  {postData?.linkedItemType === 'Restaurant' &&
                    postData?.postTaggedRestaurantWithDetailsList.length >
                      0 && (
                      <Modal.Header closeButton style={{ borderBottom: '0' }}>
                        <Modal.Title>
                          {postData?.postTaggedRestaurantWithDetailsList.length}
                          {postData?.postTaggedRestaurantWithDetailsList
                            .length > 1
                            ? ' restaurants'
                            : ' restaurant'}
                        </Modal.Title>
                      </Modal.Header>
                    )}

                  {postData?.linkedItemType === 'Section' &&
                    postData?.postTaggedSectionWithDetailsList.length > 0 && (
                      <Modal.Header closeButton style={{ borderBottom: '0' }}>
                        <Modal.Title>
                          {postData?.postTaggedSectionWithDetailsList.length}
                          {postData?.postTaggedSectionWithDetailsList.length > 1
                            ? ' sections'
                            : ' section'}
                        </Modal.Title>
                      </Modal.Header>
                    )}

                  {postData?.linkedItemType === 'FoodItem' &&
                    postData?.postFoodItemTaggedMapperList.length > 0 && (
                      <Modal.Header closeButton style={{ borderBottom: '0' }}>
                        <Modal.Title>
                          {postData?.postFoodItemTaggedMapperList.length}
                          {postData?.postFoodItemTaggedMapperList.length > 1
                            ? ' food items'
                            : ' food item'}
                        </Modal.Title>
                      </Modal.Header>
                    )}
                  <Modal.Body>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                      {postData?.linkedItemType === 'Menu' &&
                        postData?.postTaggedMenuWithDetailsList?.map(
                          (item, index) => (
                            <li
                              key={index}
                              className='itemLi d-flex justify-content-between'
                              style={{ margin: '0 0 15px' }}
                            >
                              <div>
                                <img
                                  src={item.logoUrl}
                                  alt={item.logoUrl}
                                  className='rounded-circle me-3 align-items-center justify-content-center align-content-center mt-2'
                                  style={{ width: '40px', height: '40px' }}
                                />

                                <span style={{ fontSize: '20px' }}>
                                  {item.menuName}
                                </span>
                                <span
                                  className='searchText searchratingText'
                                  style={{
                                    backgroundColor: '#80615b',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    padding: '4px 10px',
                                    marginLeft: '20px',
                                    color: 'white',
                                  }}
                                >
                                  Menu
                                </span>
                              </div>
                            </li>
                          )
                        )}
                      {postData?.linkedItemType === 'Restaurant' &&
                        postData?.postTaggedRestaurantWithDetailsList?.map(
                          (item, index) => (
                            <li key={index} className='my-2'>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'relative',
                                }}
                              >
                                <img
                                  src={item.logoUrl}
                                  alt={item.logoUrl}
                                  className='rounded-circle me-3'
                                  style={{ width: '40px', height: '40px' }}
                                />

                                <span
                                  style={{
                                    fontSize: '20px',
                                    marginRight: '20px',
                                  }}
                                >
                                  {item.restaurantName}
                                </span>

                                <span
                                  className='searchText searchratingText'
                                  style={{
                                    backgroundColor: '#7390e5',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    padding: '4px 10px',
                                    color: 'white',
                                    marginRight: 'auto', // Push "FoodItem" to the left as much as possible
                                  }}
                                >
                                  Restaurant
                                </span>

                                <div style={{ position: 'absolute', right: 0 }}>
                                  <img
                                    src={info}
                                    alt='info'
                                    style={{
                                      width: '1.5rem',
                                      height: '1.5rem',
                                      marginRight: '5px',
                                    }}
                                  />
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      {postData?.linkedItemType === 'Section' &&
                        postData?.postTaggedSectionWithDetailsList?.map(
                          (item, index) => (
                            <li
                              key={index}
                              className='itemLi d-flex justify-content-between'
                              style={{ margin: '0 0 15px' }}
                            >
                              <div>
                                <img
                                  src={item.logoUrl}
                                  alt={item.logoUrl}
                                  className='rounded-circle me-3 align-items-center justify-content-center align-content-center mt-2'
                                  style={{ width: '40px', height: '40px' }}
                                />

                                <span style={{ fontSize: '20px' }}>
                                  {item.sectionName}
                                </span>
                                <span
                                  className='searchText searchratingText'
                                  style={{
                                    backgroundColor: '#7390e5',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    padding: '4px 10px',
                                    marginLeft: '20px',
                                    color: 'white',
                                  }}
                                >
                                  Section
                                </span>
                              </div>
                            </li>
                          )
                        )}
                      {postData?.linkedItemType === 'FoodItem' &&
                        postData?.postFoodItemTaggedMapperList?.map(
                          (item, index) => (
                            <li key={index} className='my-2'>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'relative',
                                }}
                              >
                                <img
                                  src={item.logoUrl}
                                  alt={item.logoUrl}
                                  className='rounded-circle me-3'
                                  style={{ width: '40px', height: '40px' }}
                                />

                                <span
                                  style={{
                                    fontSize: '20px',
                                    marginRight: '20px',
                                  }}
                                >
                                  {item.itemName}
                                </span>

                                <span
                                  className='searchText searchratingText'
                                  style={{
                                    backgroundColor: '#7390e5',
                                    borderRadius: '20px',
                                    fontSize: '14px',
                                    padding: '4px 10px',
                                    color: 'white',
                                    marginRight: 'auto', // Push "FoodItem" to the left as much as possible
                                  }}
                                >
                                  FoodItem
                                </span>

                                <div style={{ position: 'absolute', right: 0 }}>
                                  <img
                                    src={info}
                                    alt='info'
                                    style={{
                                      width: '1.5rem',
                                      height: '1.5rem',
                                      marginRight: '5px',
                                    }}
                                  />
                                  <img
                                    src={plus}
                                    alt='plus'
                                    style={{
                                      width: '1.5rem',
                                      height: '1.5rem',
                                    }}
                                  />
                                </div>
                              </div>
                            </li>
                          )
                        )}
                    </ul>
                  </Modal.Body>
                  {/* <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer> */}
                </Modal>
              </div>
              <div className='post '>
                <h4 className='ml-5'>{postData?.description}</h4>
              </div>
            </div>
          </div>
          <hr></hr>

          {/* here */}
          {/* <h3>Chicken</h3>

          <hr></hr>
          <div className='commentScroll'>
            <CommentDisplay></CommentDisplay>
            <div className='reply'>
              <CommentDisplay></CommentDisplay>
            </div>
            <CommentDisplay></CommentDisplay>
            <div className='reply'>
              <CommentDisplay></CommentDisplay>
            </div>
            <CommentDisplay></CommentDisplay>
            <div className='reply'>
              <CommentDisplay></CommentDisplay>
            </div>
            <div className='reply'>
              <CommentDisplay></CommentDisplay>
            </div>
            <CommentDisplay></CommentDisplay>
            <div className='reply'>
              <CommentDisplay></CommentDisplay>
            </div>
          </div>
           */}
          {/* <div className='w-100 CommentComponentDivDesktopView'>
            <CommentComponent />
          </div> */}
        </div>
      </div>
      <div
        className={` galleryModalDiv ${showPage === 1 ? 'd-flex' : 'd-none'}`}
      >
        <button className='previewBtn' onClick={() => setshowPage(0)}>
          <img src={previewIcon} className='previewBtn' />
        </button>

        <PhotoGridGalleryComponent selectedImage={selectedImage} />
      </div>
      {/* <Swiper
                className='galleryModalSwiper'
                spaceBetween={30}
                 grabCursor={true}
                centeredSlides={false}
                slidesPerView={1}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2200,
                //     disableOnInteraction: true,
                // }}

                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,

                }}
            >

                <SwiperSlide>
                    <div className='d-flex galleryModalDiv'>
                        <div className='d-flex align-items-center galleryModalImgDiv'>
                            <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                <span aria-hidden="true"><img src={removeIcon} style={{ height: "30px" }} /></span>
                            </button>
                            <img src={props.selectedImage} alt="" className='img-fluid selectedImage' />
                        </div>
                        <div className='position-relative galleryModalDivCommentScroll'>
                            <h3>Chicken</h3>
                            <hr></hr>
                            <div className='commentScroll'>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                            </div>
                            <div>
                                <textarea name="" id="" placeholder='Write a comment' className='form-control'></textarea>
                                <div className='d-flex justify-content-end mt-2'>
                                    <button className='btn-custom '>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='d-flex galleryModalDiv '>
                        <div className='d-flex align-items-center galleryModalImgDiv'>
                            <button type="button " class={"close gallaryModelCloseButton"} onClick={hideModalInfo} style={{ position: "absolute", right: "0.8rem", top: "1rem", width: "44px", background: "transparent", border: "none" }}>
                                <span aria-hidden="true"><img src={removeIcon} style={{ height: "30px" }} /></span>
                            </button>
                            <img src={moviePoster} alt="" />
                        </div>
                        <div className='position-relative galleryModalDivCommentScroll'>
                            <h3>Red Valvet Cake 2</h3>
                            <hr></hr>
                            <div className='commentScroll'>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                                <CommentDisplay></CommentDisplay>
                                <div className='reply'>
                                    <CommentDisplay></CommentDisplay>
                                </div>
                            </div>
                            <div>
                                <textarea name="" id="" placeholder='Write a comment' className='form-control'></textarea>
                                <div className='d-flex justify-content-end mt-2'>
                                    <button className='btn-custom '>Send</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>
            </Swiper> */}
    </div>
  );
};

export default GalleryModal;
