import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import "./OrderDetails.css";
import OrderDetailsModal from "../OrderDetailsModal/OrderDetailsModal";
import OrderTableDialog from "../components/OrderTableDialog";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { getPercentageOrAmount } from "../../../utils/getPercentageOrAmount.js";

const OrderDetails = ({
  title,
  restaurant,
  // onUpdateServiceCharge,
  selectedTable,
  setSelectedTable,
  selectedOkTable,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [tableModalShow, setTableModalShow] = useState(false);
  const [clickedRestaurant, setClickedRestaurant] = useState("");
  const [orderType, setOrderType] = useState("");
  const [cartData, setCartData] = useState([]);
  const [selectOk,setselecteOk]=useState([]);
  const handleShowTableModal = (restaurantId) => {
    setClickedRestaurant(restaurantId);
    setTableModalShow(true);
  };

  console.log("restaurant__", restaurant);
  console.log(selectOk)
  // const handleShowModal = (restaurantName, order) =>{
  //     setModalShow(true);
  //     setClickedRestaurant(restaurantName);
  //     setOrderType(order);
  // }

  useEffect(() => {
    selectedOkTable(selectOk);
  }, [selectOk]);

  const [totalSd, setTotalSd] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);

  const array = [];

  useEffect(() => {
    const calculateTotals = () => {
      let totalBasePrice = 0;
      let fooditemVat = 0;
      let foodItemSd = 0;

      let totalAddonPrice = 0;
      let totalAddonSD = 0;
      let totalAddonVat = 0;

      let totalOptionPrice = 0;
      let totalOptionVat = 0;
      let totalOptionSD = 0;

      let totalSubOptionPrice = 0;
      let totalSubOptionSd = 0;
      let totalSubOptionVat = 0;

      restaurant.subOrders
        .filter((subOrder) => subOrder.orderTypeName === title)
        .forEach((subOrder) => {
          // Calculating Total price, vat and sd of foodItem
          subOrder.itemDetails.forEach((item) => {
            totalBasePrice =
              totalBasePrice + item.basePrice * item.foodItemQuantity;
            fooditemVat += item.vatInAmount * item.foodItemQuantity;
            foodItemSd += item.sdInAmount * item.foodItemQuantity;

            // Calculating Total price, vat and sd of AddOn
            item.addOns.forEach((addOn) => {
              totalAddonPrice += addOn.addOnBasePrice * addOn.addOnQuantity;
              totalAddonVat += addOn.addOnVatAmount * addOn.addOnQuantity;
              totalAddonSD += addOn.addOnSdAmount * addOn.addOnQuantity;

              // Calculating Total price, vat and sd of Option
              addOn.options.forEach((option) => {
                totalOptionPrice +=
                  option.optionBasePrice * option.optionQuantity;
                totalOptionVat +=
                  option.optionVatAmount * option.optionQuantity;
                totalOptionSD += option.optionSdAmount * option.optionQuantity;

                // Calculating Total price, vat and sd of SubOption
                option.subOptions.forEach((subOption) => {
                  totalSubOptionPrice += subOption.subOptionBasePrice;
                  totalSubOptionSd += subOption.subOptionSdAmount;
                  totalSubOptionVat += subOption.subOptionVatAmount;
                });
              });
            });
          });
        });

      const totalVatAmount =
        fooditemVat + totalAddonVat + totalOptionVat + totalSubOptionVat;
      const totalSdAmount =
        foodItemSd + totalAddonSD + totalOptionSD + totalSubOptionSd;

      setTotalVat(totalVatAmount);
      setTotalSd(totalSdAmount);
      setSubTotalAmount(
        totalBasePrice +
          totalAddonPrice +
          totalOptionPrice +
          totalSubOptionPrice
      );
    };

    calculateTotals();
  }, [restaurant, title]);

  useEffect(() => {
    if (title === "In-Store") {
      const vatAmount = getPercentageOrAmount(
        subTotalAmount,
        restaurant?.serviceCharge,
        "amount"
      );
      setServiceCharge(vatAmount);
      // onUpdateServiceCharge(vatAmount);
    } else {
      setServiceCharge(0);
      // onUpdateServiceCharge(0);
    }
  }, [restaurant, subTotalAmount]);

  const Total = () => {
    return subTotalAmount + totalSd + totalVat + 0 + serviceCharge;
  };

  useEffect(() => {
    const userId = localStorage.getItem("gotasteClienttoken");
    const decoded = jwtDecode(userId);

    const getCartData = async (userId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET}/api/Cart/GetCartDataByUserId?userId=${userId}`
        );
        setCartData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCartData(decoded.UserId);
  }, []);

  return (
    <Accordion defaultActiveKey={null} className="parent-body-shadow">
      <Accordion.Item eventKey={`${1}`}>
        <Accordion.Header className="order-shadow">
          <div className="d-flex align-items-center">
            <div className="header-weight-bold">{title}</div>
            {title === "In-Store" && (
              <div
                className="ms-2 table-selection"
                // onClick={() => handleShowTableModal(restaurant?.restaurantId)}
                onClick={() =>
                  handleShowTableModal(restaurant?.cartRestaurantId)
                }
              >
                {selectOk?.tableName 
                  ? selectOk.tableName 
                  : selectedTable?.tableName 
                    ? selectedTable.tableName 
                    : "Select Table"}
              </div>
            )}
          </div>
        </Accordion.Header>

        <Accordion.Body className="d-block px-2">
          <div>
            <div className="order-details-wrapper">
              <div></div>
              {/* Header */}
              <div className="d-flex">
                <div className="text-bold text-center margin-r">Base price</div>
                <div className="text-bold text-center">Total</div>
              </div>
            </div>
            {/* Header end */}
            {restaurant.subOrders
              .filter((subOrder) => subOrder.orderTypeName === title)
              .map((subOrder) =>
                subOrder.itemDetails.map((item) => (
                  <div className="order-details-wrapper mb-4">
                    <div>
                      <div className="food-name text-small">
                        {item.foodItemName}
                      </div>{" "}
                      {/* FoodItem name */}
                      {/*Option Name*/}
                      {item.addOns.map((addon) => (
                        <div
                          key={addon.addOnId}
                          className="sub-options sub-options-color my-1 text-small"
                        >
                          {addon.addOnName}
                          {addon.options.map((option) => (
                            <div
                              key={option.optionId}
                              className="sub-options sub-options-color my-1 text-small"
                            >
                              {option.optionName}
                              {/* Sub-Option Name */}
                              {option.subOptions.map((subOption) => (
                                <div
                                  key={subOption.subOptionId}
                                  className="sub-options sub-options-margin text-small"
                                >
                                  <span>{subOption.subOptionName}</span>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    <div className="d-flex">
                      <div className="base-price-container text-end">
                        <div className="text-black text-small">
                          {item.foodItemQuantity} * &#163;{item.basePrice}
                        </div>{" "}
                        {/* quantit and item base price */}
                        {/*Option BasePrice and Quantity*/}
                        <div className="my-1 text-black text-small">
                          {item.addOns.map((addon) => (
                            <div className="sub-options sub-options-color ms-2 text-small">
                              <span>
                                {addon.addOnQuantity} * &#163;
                                {addon.addOnBasePrice}
                              </span>
                              {addon.options.map((option) => (
                                <div className="sub-options sub-options-color my-1 text-small">
                                  <span>
                                    {option.optionQuantity} * &#163;
                                    {option.optionBasePrice}
                                  </span>
                                  {/*Sub-Option BasePrice and Quantity*/}

                                  {option.subOptions.map((subOption) => (
                                    <div className="text-black text-small">
                                      {" "}
                                      {/*No quantity found*/}
                                      <span>
                                        1 * &#163;{subOption.subOptionBasePrice}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="text-end">
                        <div className="text-black text-small">
                          £{(item.foodItemQuantity * item.basePrice).toFixed(2)}
                        </div>{" "}
                        {/* item total price */}
                        <div className="my-1 text-black text-small">
                          {/* Option Total Price */}
                          {item.addOns.map((addon) => (
                            <div className="sub-options sub-options-color ms-2 text-small">
                              <span>
                                £
                                {(
                                  addon.addOnQuantity * addon.addOnBasePrice
                                ).toFixed(2)}
                              </span>
                              {addon.options.map((option) => (
                                <div className="sub-options sub-options-color my-1 text-small">
                                  <span>
                                    £
                                    {(
                                      option.optionQuantity *
                                      option.optionBasePrice
                                    ).toFixed(2)}
                                  </span>
                                  {/*Sub-Option Total Price*/}
                                  <div className="text-black text-small">
                                    {option.subOptions.map((subOption) => (
                                      <span>
                                        £
                                        {subOption.subOptionTotalPrice.toFixed(
                                          2
                                        )}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
          </div>
          <hr />
          <div className="total-price">
            <div>
              <div className="my-1 text-small">Sub total</div>
              <div className="my-1 text-small">Total SD</div>
              <div className="my-1 text-small">
                Service charge ({restaurant.serviceCharge}%)
              </div>
              <div className="my-1 text-small">Total VAT</div>
              <div className="my-1 text-small">Total</div>
            </div>

            <div className="align-right">
              <div className="my-1 text-small">
                &#163;{subTotalAmount.toFixed(2)}
              </div>
              <div
                className="my-1 text-small text-primary text-decoration-underline text-cursor-pointer"
                onClick={() => setModalShow(true)}
              >
                &#163;{totalSd.toFixed(2)}
              </div>
              <div className="my-1 text-small">£{serviceCharge.toFixed(2)}</div>
              <div
                className="my-1 text-small text-primary text-decoration-underline text-cursor-pointer"
                onClick={() => setModalShow(true)}
              >
                &#163;{totalVat.toFixed(2)}
              </div>
              <div className="my-1 text-small">&#163;{Total().toFixed(2)}</div>
            </div>
          </div>

          <OrderTableDialog
            show={tableModalShow}
            onHide={() => setTableModalShow(false)}
            restaurantId={clickedRestaurant}
            preSelectedTable={selectedTable}
            setParentSelectedTable={setSelectedTable}
            SetselecteOk={setselecteOk}
            selectOk={selectOk}
          />

          <OrderDetailsModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            userCartData={cartData}
            setParentSelectedTable={setSelectedTable}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default OrderDetails;
