import React, { useEffect,useState } from "react";
import { Button, Card } from "react-bootstrap";
import chefCooking from "../../Assets/Image/chefCooking.jpg";
import deliveryMan from "../../Assets/Image/deliveryMan.png";
import ShowOrderStatus from "./ShowOrderStatus";

const PendingFoodCard = ({ restaurant, foodItem }) => {
  const [activeTab, setActiveTab] = useState(restaurant?.orderStatus);
  const [hover, setHover] = useState(false);
  const orderNumberStyles = {
    padding: '5px 11px 0px',
    backgroundColor: 'rgb(14 4 1 / 0%)',
    color: '#4c271b',
    fontFamily: "'Arial', sans-serif",
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    cursor: 'copy',
  };

  const [total, setTotal] = useState(0)

  const calculateFoodItemPrice = (item) => {
    if (!item) return 0;

    let totalPrice =
      (item.basePrice + item.vatInAmount + item.sdInAmount) *
      item.foodItemQuantity;

    item.addOns.forEach((addOn) => {
      const addOnTotal = addOn.addOnBasePrice * addOn.addOnQuantity;
      totalPrice += addOnTotal;

      addOn.options.forEach((option) => {
        const optionTotal = option.optionBasePrice * option.optionQuantity;
        totalPrice += optionTotal;

        option.subOptions.forEach((subOption) => {
          const subOptionTotal = subOption.subOptionBasePrice * 1; 
          totalPrice += subOptionTotal;
        });
      });
    });

    return totalPrice.toFixed(2);
  };

  useEffect(() => {
    if (foodItem) {
      const itemTotal = calculateFoodItemPrice(foodItem);
      setTotal(itemTotal);
    }
  }, [foodItem]);

  return (
    <Card className='food-card'>
      <span
        style={orderNumberStyles}
      >
        <span style={{ textTransform: 'none'}}>OrderNo:</span> #{restaurant.orderId.slice(-6)}
      </span>
      <div className='d-flex justify-content-between container mt-1 mb-1'>
        <p
          className="restaurantName"
          style={{
            fontFamily: "Poppins",
          }}
        >
          {restaurant?.restaurantName
            ? restaurant.restaurantName
            : "restaurant name"}
        </p>

        {foodItem?.foodStatus && (
          <ShowOrderStatus
            orderStatus={foodItem?.foodStatus}
            menuType={foodItem?.foodItemMenuType}
            restaurant={restaurant}
          />
        )}
      </div>

      <div className="col-12 align-items-center justify-content-center text-center">
        {/* New section: Order Status */}
        <div className="checkOutCard pt-4 shadow-none">
          <h5>Estimated delivery time</h5>
          <h3 className="fw-bold">10 min</h3>
          <img
            className="chefCooking"
            src={foodItem?.foodItemImage}
            alt="chef cooking"
          />
          <div className="status pb-4 pt-3">
            <span
              className={`status-tab ${
                activeTab === "Pending" ? "active" : ""
              }`}
            ></span>
            <span
              className={`status-tab tab-2 ${
                activeTab === "Preparing" ? "active" : ""
              }`}
            ></span>
            <span
              className={`status-tab ${
                activeTab === "Out for delivery" ? "active" : ""
              }`}
            ></span>
            <span
              className={`status-tab ${
                activeTab === "delivered" ? "active" : ""
              }`}
            ></span>
          </div>
          {/* Render content based on activeTab */}
          {activeTab === "Pending" && (
            <p>Your order is pending. We will prepare once it's accepted.</p>
          )}

          {activeTab === "Preparing" && (
            <p>
              Preparing your food. Your rider will pick it up once it's ready.
            </p>
          )}

          {activeTab === "Out for delivery" && (
            <p>Your food has been picked up and is on the way.</p>
          )}

          {activeTab === "delivered" && (
            <p>Your order has been delivered. Enjoy your meal!</p>
          )}
        </div>
      </div>
      {/* <Card.Img variant="top" src={foodItem.itemImage} alt={foodItem.itemName} /> */}
      <Card.Body>
        <Card.Title>{foodItem.foodItemName}</Card.Title>
        <Card.Text>
          {/* {{foodItem?.addOns?.map((addOn) =>{
                  return(
                    addOn?.addOnQuantity > 0 && (
                      <h6 key={addOn.addOnId}>
                        {addOn.addOnName} x{addOn.addOnQuantity}
                         <span> £{addOn.addOnBasePrice } </span> 
                       {" . "}
                      </h6>
                    )
                  )                                        
            })} 
            
          {foodItem?.addOns?.map((addOn) => addOn?.options?.map(option => {
            return (
              option?.optionQuantity > 0 && (
                <h6 key={option.optionId}>
                  {option.optionName} x{option.optionQuantity}
                  <span> £{option.optionBasePrice} </span>

                  {option?.subOptions?.map((suboption) => {
                    return (
                        <span className='span-text' key={suboption.subOptionId}>
                          ({suboption.subOptionName} x {1} £
                          {suboption.subOptionBasePrice})
                        </span>
                    );
                  })}
                  {" . "}
                </h6>
              )
            );
          }))} */}

          {foodItem?.addOns?.map(
            (addOn) =>
              addOn?.addOnQuantity > 0 && (
                <div key={addOn.addOnId}>
                  {/* Display AddOn */}
                  <h6>
                    {addOn.addOnName} x{addOn.addOnQuantity}
                    <span> £{addOn.addOnBasePrice}</span>
                  </h6>
                  {/* Display Options */}
                  {addOn?.options?.map(
                    (option) =>
                      option?.optionQuantity > 0 && (
                        <div
                          key={option.optionId}
                          style={{ marginLeft: "1rem" }}
                        >
                          <h6>
                            {option.optionName} x{option.optionQuantity}
                            <span> £{option.optionBasePrice}</span>
                          </h6>
                          {/* Display SubOptions */}
                          {option?.subOptions?.map((subOption) => (
                            <div
                              key={subOption.subOptionId}
                              style={{ marginLeft: "2rem" }}
                            >
                              <span>
                                {subOption.subOptionName} x1
                                <span> £{subOption.subOptionBasePrice}</span>
                              </span>
                            </div>
                          ))}
                        </div>
                      )
                  )}
                </div>
              )
          )}

          {/* 
          <div>
            {foodItem?.addOns?.map((addOn) => (
              <div key={addOn.addOnId}>
                {addOn?.options?.map((option) => (
                  option?.optionQuantity > 0 && (
                    <h6 key={option.optionId} >
                      {addOn.addOnName} x{addOn.addOnQuantity} <span style={{ fontSize: "1" ,fontWeight: "bold" }}> . </span> 
                      {option.optionName} x{option.optionQuantity} £{option.optionBasePrice}
                      {option?.subOptions?.map((subOption) => (
                        <span key={subOption.subOptionId}>
                          <span style={{ fontSize: "1" ,fontWeight: "bold" }}> . </span>
                          {subOption.subOptionName} x 1 £{subOption.subOptionBasePrice}
                        </span>
                      ))}
                    </h6>
                  )
                ))}
              </div>
            ))}
          </div> */}
        </Card.Text>

        <div className="d-flex justify-content-between">
          <Card.Text className="bold-text">
            Price: {total}£
          </Card.Text>
          <Card.Text>Quantity: {foodItem.foodItemQuantity}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PendingFoodCard;
