import { FILTERED_ITEM_DATA } from "../../actions/types";

const initialState = {
    foodItems: [],
}

const filteredFoodItemData = (state = initialState, action) => {
    switch (action.type) {
        case FILTERED_ITEM_DATA:
            return {
                ...state,
                foodItems: action.payload,
            };
        default:
            return state;
    }
}

export default filteredFoodItemData;