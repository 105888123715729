// FoodModal.jsx
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import OrderCart from "../../OrderPage/OrderCart/OrderCart";
import loadingImg from "./../../../Assets/Image/LoadingSpin.gif";
import { Skeleton } from "antd";
import "./FoodModal.css";
import dineIn from "../../../Assets/Icons/DineIn.png";
import recommendations from "../../../Assets/Icons/customer.png";
import delivery from "../../../Assets/Icons/delivery-bike.png";
import clock from "../../../Assets/Icons/clock.png";
import LeoImg from '../../../Assets/Image/Leo.png';
import Rating from "react-rating";
import OutOfHourModal from "../../../components/OutOfHourModal/OutOfHourModal";
const FoodModal = ({
  show,
  handleClose,
  orderDetails,
  selectedItemImage,
  selectedItemId,
  selectedIndex,
  foodDatarestaurant,
  showUnavailableReasonModal,
  setShowUnavailableReasonModal,
  showOutOfHourModal,
  setShowOutOfHourModal,
  review = "null",
}) => {
  console.log("selectedItemImage", selectedItemImage);

  const [tabView, setTabView] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const text =
    "Ground beef, string cheese, ground pork, parmesan, olive oilLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an";
  const [showMoreText2, setShowMoreText2] = useState(false);
  const text2 =
    "Ground beef, string cheese, ground pork, parmesan, olive oilLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an";

  // See more and See Less functionality
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedStory, setIsExpandedStory] = useState(false);
  const [isExpandedDetails, setIsExpandedDetails] = useState(false);
  const [isExpandedInstruction, setIsExpandedInstruction] = useState(false);

  const toggleDescription = (tabsName) => {
    switch (tabsName) {
      case "description":
        setIsExpanded(!isExpanded);
        break;
      case "details":
        setIsExpandedDetails(!isExpandedDetails);
        break;
      case "story":
        setIsExpandedStory(!isExpandedStory);
        break;
      case "instruction":
        setIsExpandedInstruction(!isExpandedInstruction);
        break;
    }
  };

  const [detailsId, setDetailsId] = useState(null);
  const seeFullDetails = (id) => {
    setDetailsId(id);
  }

  const [instructionId, setInstructionId] = useState(null);
  const seeFullInstruction = (id) => {
    setInstructionId(id);
  }

  const handleShowScheduleModal = () => {
    setShowOutOfHourModal(true);
  }

  // See More and See Less functionality -- end
  return (
    <>
      <style>
        {`
          .seeMoreButton{
            color: rgb(61, 30, 25);
            font-weight: bold;
            text-decoration: none;
            font-size: .875em;
          }
          .seeMoreButton:hover{
            color: hsl(22, 94%, 14%);
          }
          .seeMoreButton:active{
            color: yellow;
          }
        `}
      </style>
      <Modal show={show} onHide={handleClose} className="modal-xl ">
        <Modal.Body style={{ padding: "0px", borderRadius: "10px" }}>
          {orderDetails != null ? (
            <>
              <button
                type="button"
                className="btn-close btn-modal"
                onClick={handleClose}
              ></button>
              <div className="row align-items-center bg-black foodModalParent">
                {/* Image Section */}
                <div className="col-12 col-md-6 d-flex justify-content-center galleryModalImgDiv  ">
                  <Swiper
                    spaceBetween={2}
                    grabCursor={false}
                    centeredSlides={false}
                    slidesPerView={"auto"}
                    cssMode={true}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="foodCart h-100 "
                    initialSlide={selectedIndex}
                  >
                    {selectedItemImage.foodItemImages?.length > 0 ? (
                      selectedItemImage.foodItemImages?.map((d, index) => {
                        return (
                          <SwiperSlide key={d.itemImagePath}>
                            <img
                              src={d.itemImagePath}
                              className="img-fluid "
                              alt={d.description}

                            // onClick={props.onClickInsideTest3}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : selectedItemImage.foodImageList?.length > 0 ?
                      (
                        selectedItemImage.foodImageList?.map((d, index) => {
                          return (
                            <SwiperSlide key={d.itemImagePath}>
                              <img
                                src={d.itemImagePath}
                                className="img-fluid "
                                alt={""}

                              // onClick={props.onClickInsideTest3}
                              />
                            </SwiperSlide>
                          );
                        })
                      )
                      :
                      selectedItemImage ? (
                        <SwiperSlide key={"selectedItemImage"}>
                          <img
                            src={selectedItemImage}
                            className="img-fluid"
                            alt="..."
                          // onClick={props.onClickInsideTest3}
                          />
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide key="loading">
                          <img
                            src={loadingImg}
                            className="img-fluid h-10"
                            alt="..."
                            style={{
                              height: "5rem !important",
                              objectFit: "contain",
                            }}
                          // onClick={props.onClickInsideTest3}
                          />
                        </SwiperSlide>
                      )}
                  </Swiper>
                </div>
                {/* Foodidte Info Section */}
                <div className="col-12 col-md-6 bg-white foodModalRight">
                  {/* FoodItem Title / Header Section */}
                  <div className="card" style={{ border: "0px" }}>
                    <div className="card-body  ">
                      <h3 className="itemNameFoodModal" style={{ color: "#3D1E19" }}>
                        <strong>{orderDetails?.itemName || <Skeleton />}</strong>
                        {
                          foodDatarestaurant &&
                          <span className="ratingspan">
                            <Rating
                              className='ratingStar'
                              initialRating={Number(4.5)}
                              emptySymbol='far fa-star icon-color'
                              fullSymbol='fas fa-star icon-color'
                              readonly
                            ></Rating>
                            4.5
                          </span>
                        }
                      </h3>

                      {foodDatarestaurant && (
                        <>
                          <div
                            className='d-flex resrating'
                            data-bs-toggle='modal'
                            data-bs-target='#staticBackdrop'
                          >
                            <span >
                              <h6 className="resnamerating m-0" style={{ color: "#3D1E19" }}>
                                {orderDetails?.name}
                              </h6>
                            </span>
                            <span className="ratingspan">
                              <Rating
                                className='ratingStar'
                                initialRating={Number(4.5)}
                                emptySymbol='far fa-star icon-color'
                                fullSymbol='fas fa-star icon-color'
                                readonly
                                start={0}
                                stop={1}
                              ></Rating>
                              4.5
                            </span>
                          </div>
                        </>
                      )
                      }

                      {/* Description with see more button  */}
                      <div>
                        <p
                          className={"my-1 text-muted small"}
                        // style={{
                        //   display: '-webkit-box',
                        //   WebkitBoxOrient: 'vertical',
                        //   overflow: 'hidden',
                        //   WebkitLineClamp: isExpanded ? 'unset' : 2,
                        // }}
                        >
                          {selectedItemImage?.description}
                        </p>
                        {/* {selectedItemImage?.description?.length > 100 && (
                          <button className="seeMoreButton btn p-0" onClick={() => toggleDescription("description")}>
                            {isExpanded ? 'See less' : 'See more'}
                          </button>
                        )} */}
                      </div>

                      <div className='d-flex align-items-center'>
                        <img src={LeoImg} className='me-1 userImg' alt='' />
                        <p className="my-1 text-muted small">
                          Leo Love the art of travel. So many plac So many foods...
                        </p>
                      </div>

                      {/* <div className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={selectedItemImage?.restaurantLogo}
                          className='me-1 userImg'
                          alt=''
                        />
                        <h6>{selectedItemImage?.name}</h6>
                      </div>

                      <button className="follow-BUtton btn ms-1">Follow</button>
                    </div> */}
                      <hr className="mb-0" />
                    </div>
                  </div>

                  {
                    showOutOfHourModal && <OutOfHourModal
                      setShowOutOfHourModal={setShowOutOfHourModal}
                      foodItemName={orderDetails.itemName}
                    />
                  }

                  <div className="d-flex mx-3">

                    <div
                      className="cart-modal-icon"
                      title="Schedule"
                      onClick={handleShowScheduleModal}
                    >
                      <img src={clock} alt="schedule icon" />
                    </div>

                    <hr className="horizontal-line" />

                    <div className="cart-modal-icon" title="Dine in">
                      <img src={dineIn} alt="dine in icon" />
                    </div>

                    <hr className="horizontal-line" />

                    <div className="cart-modal-icon deliveryiconimg" title="Delivery">
                      <img src={delivery} alt="delivery icon" />
                    </div>

                    <hr className="horizontal-line" />

                    <div className="cart-modal-icon" title="Recommendation">
                      <img src={recommendations} alt="recommendation icon" />
                    </div>
                  </div>

                  {orderDetails != null ? (
                    <>
                      <div className="col-12 ">
                        {/* Details, Story, Instruction */}
                        <div className="">
                          <div className="col-12 p-3 d-flex justify-content-between distanceBottom">
                            <button
                              data-mdb-ripple-color="dark"
                              type="button"
                              className={` dsrButton ${tabView === 1 ? "onselecteddsrButton" : ""
                                }`}
                              onClick={() => {
                                setTabView(1);
                              }}
                            >
                              Details
                            </button>
                            <button
                              data-mdb-ripple-color="dark"
                              type="button"
                              className={` dsrButton ${tabView === 2 ? "onselecteddsrButton" : ""
                                }`}
                              onClick={() => {
                                setTabView(2);
                              }}
                            >
                              Story
                            </button>
                            <button
                              data-mdb-ripple-color="dark"
                              type="button"
                              className={` dsrButton ${tabView === 3 ? "onselecteddsrButton" : ""
                                }`}
                              onClick={() => {
                                setTabView(3);
                              }}
                            >
                              Instructions
                            </button>
                          </div>
                        </div>
                        <div className=" orderCartRow">
                          <div className="col-12 d-flex justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                              <div className="card OrderfoodcardDetailsParagraph my-1 p-2  paragraph card-design">
                                {orderDetails != null && (
                                  <>
                                    {tabView === 1 ? (
                                      // Details with see more button
                                      <>
                                        <div style={{
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          WebkitLineClamp: isExpandedDetails ? 'unset' : 4,
                                        }}>
                                          {orderDetails.foodItemDetailList.map(
                                            (dt, i) => (
                                              <>
                                                <h6 style={{ fontWeight: 'bold', marginBottom: '0px' }}>{dt.details}</h6>
                                                {dt?.description?.length > 100 && detailsId !== i ? (

                                                  <p className="small m-0" key={i}>{dt.description.substring(0, 50)}{' . . . '}

                                                    <button onClick={() => seeFullDetails(i)} className="seeMoreButton btn p-1">See more</button>

                                                  </p>

                                                ) : detailsId === i ? (
                                                  <p className="small" key={i}>{dt.description}
                                                    <button onClick={() => seeFullDetails(null)} className="seeMoreButton btn p-1">See less</button>

                                                  </p>
                                                ) : (
                                                  <p className="small" key={i}>{dt.description}</p>
                                                )
                                                }
                                              </>
                                            )
                                          )}
                                        </div>
                                        {orderDetails?.foodItemDetailList?.length > 1 &&
                                          <button className="seeMoreButton btn p-0" onClick={() => toggleDescription("details")}>
                                            {isExpandedDetails ? 'See less' : 'See more'}
                                          </button>}
                                      </>
                                    ) : tabView === 2 ? (
                                      // Story with see more button
                                      <>
                                        <p className="small m-0" style={{
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          WebkitLineClamp: isExpandedStory ? 'unset' : 3,
                                        }}>{orderDetails.foodItemStory.story}</p>
                                        {orderDetails?.foodItemStory?.story?.length > 150 && (
                                          <button className="seeMoreButton btn p-0" onClick={() => toggleDescription("story")}>
                                            {isExpandedStory ? 'See less' : 'See more'}
                                          </button>
                                        )}
                                      </>
                                    ) : (
                                      // Instruction with see more button
                                      <>
                                        <div style={{
                                          display: '-webkit-box',
                                          WebkitBoxOrient: 'vertical',
                                          overflow: 'hidden',
                                          WebkitLineClamp: isExpandedInstruction ? 'unset' : 6,
                                        }}>
                                          {orderDetails.foodItemInstructionList.map(
                                            (dt, i) => (
                                              <>
                                                <div key={i} >
                                                  <h5>{dt.name}</h5>
                                                  {dt?.description?.length > 80 && instructionId !== i ? (

                                                    <p className="small" key={i}>{dt.description.substring(0, 70)}{'...'}

                                                      <button onClick={() => seeFullInstruction(i)} className="seeMoreButton btn p-1">See more</button>

                                                    </p>

                                                  ) : instructionId === i ? (
                                                    <p className="small" key={i}>{dt.description}
                                                      <button onClick={() => seeFullInstruction(null)} className="seeMoreButton btn p-1">See less</button>

                                                    </p>
                                                  ) : (
                                                    <p className="small" key={i}>{dt.description}</p>
                                                  )
                                                  }
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                        {orderDetails?.foodItemInstructionList?.length > 1 &&
                                          <button className="seeMoreButton btn p-0" onClick={() => toggleDescription("instruction")}>
                                            {isExpandedInstruction ? 'See less' : 'See more'}
                                          </button>
                                        }
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <br></br>
                        </div>
                        {/* mobileDisplay end */}
                      </div>

                      <OrderCart
                        handleClose={handleClose}
                        orderDetails={orderDetails}
                        from={"modal"}
                        itemId={selectedItemId}
                        selectedItem={selectedItemImage}
                        showUnavailableReasonModal={showUnavailableReasonModal}
                        setShowUnavailableReasonModal={setShowUnavailableReasonModal}
                        showOutOfHourModal={showOutOfHourModal}
                        setShowOutOfHourModal={setShowOutOfHourModal}
                      />
                    </>
                  ) : (
                    <img
                      src={loadingImg}
                      className="img-fluid h-10"
                      alt="..."
                      style={{
                        height: "5rem !important",
                        objectFit: "contain",
                      }}
                    // onClick={props.onClickInsideTest3}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            // <div className='d-flex justify-content-center'>
            //   <div>
            //     <img
            //       src={loadingImg}
            //       className='img-fluid h-10'
            //       alt='...'
            //       style={{
            //         height: '5rem !important',
            //         objectFit: 'contain',
            //       }}
            //       // onClick={props.onClickInsideTest3}
            //     />
            //   </div>
            // </div>
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <Skeleton.Image
                  className="img-fluid w-100"
                  style={{
                    height: "300px",
                  }}
                />

                <div className="card" style={{ border: "0px" }}>
                  <div className="card-body">
                    <h3 style={{ color: "#3D1E19" }}>
                      <Skeleton active />
                    </h3>
                    <p className="my-1 text-muted">
                      <Skeleton active />
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <Skeleton.Avatar className="me-1" active />
                        <h6>
                          <Skeleton active />
                        </h6>
                      </div>
                    </div>
                    <hr className="mb-0" />
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Skeleton active paragraph={{ rows: 3 }} />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FoodModal;
