import React, { useRef, useState, useEffect } from 'react';
import PostDisplay from '../PostDisplay';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const AllPostDisplayComponent = (props) => {
  const [postData, setPostData] = useState([]);
  const apiAddress = process.env.REACT_APP_SECRET;
  const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');

  console.log('post props', props);

  let decodedData = {};
  let userId = '';
  if (gotasteClienttoken != null) {
    try {
      decodedData = jwtDecode(gotasteClienttoken);
      userId = decodedData.UserId;
    } catch (error) {}
  }

  // Infinite scroll to fetch more posts

  const fetchPostData = async (skip, take) => {
    if (userId) {
      axios
        .get(
          // apiAddress +
          //   `/api/Post/GetTopPostWithCommentReactByUserIdAndResaurantId?userId=${userId}&restaurantId=${}&skip=${skip}&take=${take}`
          apiAddress +
            `/api/Post/GetTopPostWithCommentReact?userId=${userId}&skip=${skip}&take=${take}`
        )
        .then((response) => {
          console.log("post response data: ", response.data);
          setPostData((prevData) => [...prevData, ...response.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchPostData(0, 12); // Fetch the first 12 posts initially
  }, []);

  const ref = useRef();
  const [seeMore, setSeeMore] = useState(0); // Number of posts displayed

  return (
    <div>
      {postData.slice(seeMore, seeMore + 10).map((post, index) => (
        <div key={index}>
          <PostDisplay postData={post} />
          <br />
        </div>
      ))}

      <div
        className='row loadingSpan'
        ref={ref}
        onClick={() => {
          setSeeMore((prevSeeMore) => prevSeeMore + 10);
          fetchPostData(seeMore + 12, 10); // Fetch additional 10 posts when "Loading more" is clicked
        }}
      >
        <div className='col-12 d-flex justify-content-center'>
          <span className='loadingSpan'>Loading more</span>
        </div>
      </div>
    </div>
  );
};

export default AllPostDisplayComponent;

// import React, { useRef, useState, useEffect } from 'react';
// import PostDisplay from '../PostDisplay';
// import jwtDecode from 'jwt-decode';
// import axios from 'axios';
// const AllPostDisplayComponent = (props) => {
//   const [postData, setPostData] = useState([]);
//   const apiAddress = process.env.REACT_APP_SECRET;
//   const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');

//   let decodedData = {};
//   let userId = '';
//   if (gotasteClienttoken != null) {
//     try {
//       decodedData = jwtDecode(gotasteClienttoken);
//       userId = decodedData.UserId;
//     } catch (error) {}
//   }

//   const fetchPostData = async () => {
//     if (userId) {
//       axios
//         .get(
//           apiAddress +
//             `/api/Post/GetTopPostWithCommentReact?userId=${userId}&skip=1&take=12`
//         )
//         .then((response) => {
//           setPostData(response.data);
//           // setRestaurantDetails(response.data);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   };

//   useEffect(() => {
//     fetchPostData();
//   }, []);

//   const ref = useRef();
//   const [currentScrollPosition, setCurrentScrollPosition] = useState(3500);
//   useEffect(() => {
//     if (props.lazyLoad != '1') {
//       window.addEventListener('scroll', (event) => {
//         if (window.scrollY > currentScrollPosition) {
//           setSeeMore(seeMore + 5);
//           var sum = currentScrollPosition + 3500;
//           setCurrentScrollPosition(sum);
//         }
//       });
//     }
//   });
//   const [seeMore, setSeeMore] = useState(5);
//   return (
//     <div>
//       <div className='row'>
//         <div className='col-12'>{getValue(seeMore)}</div>
//       </div>
//       <div
//         className='row loadingSpan'
//         ref={ref}
//         onClick={() => {
//           setSeeMore(seeMore + 5);
//         }}
//       >
//         <div className='col-12 d-flex justify-content-center'>
//           <span className='loadingSpan'>Loading more </span>
//         </div>
//       </div>
//       <div className='row loadingSpan'>
//         <div className='col-12'>
//           <span className=''></span>
//         </div>
//       </div>
//     </div>
//   );
// };
// const getValue = (props) => {
//   let count = props;
//   let cards = [];
//   for (let index = 0,; index < count; index++) {
//     cards.push(
//       <div>
//         <PostDisplay></PostDisplay>
//         <br></br>
//       </div>
//     );
//   }
//   return cards;
// };
// export default AllPostDisplayComponent;
