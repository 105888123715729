import React from "react";
import img1 from "./../../../Assets/Image/image 30.png";
import img2 from "./../../../Assets/Image/image 33.png";
import img3 from "./../../../Assets/Image/QRCODEHOMe.jpg";

import "./ProvidedServices.css";

const ProvidedServices = () => {
  return (
    <div>
      {/* sevices  */}
      <div class="row row-cols-1 row-cols-md-3 g-4 customContainer my-5 LandingSevices">
        <div class="col ">
          <div class="card border-0 ">
            <img src={img3} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">
                Best menu presentation and tableside ordering platform
              </h5>
              <p class="card-text custom-card-details">
                Do away with paper menus, and showcase your items and menu
                changes in a flexible rich social-media-like AI-driven
                interactive interface. Customers can order themselves through QR
                codes, saving you on waiter costs
              </p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card border-0">
            <img src={img1} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">
                Best digital kitchen order management system
              </h5>
              <p class="card-text custom-card-details">
                No more need for expensive paper printers at each food counter
                that need regular servicing for paper/ink. Our AI-driven digital
                kitchen manages order in a natural flow that maximises kitchen
                efficiencies
              </p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card border-0">
            <img src={img2} class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title ">
                Delivery/Collection, subscriptions, offers, loyalty schemes, at
                just 0.2%
              </h5>
              <p class="card-text custom-card-details">
                Handle all types of orders, Dine-in/Delivery/Collection, create
                food subscriptions for the first time in the world for new
                predictable revenue streams, and offers/loyalty schemes, for
                just 0.2% per transaction on our world's lowest cost EPOs
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvidedServices;
