import React, { useState, useEffect } from "react";
import "../../../../src/responsive.css";
import "./Banner.css";
import Login from "./../../LoginRegister/Login";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import backBtn from "./../../../Assets/Icons/back.png";
import logo from "./../../../Assets/Image/LogoTransparent.png";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLoadFalse,
  changeLoadTrue,
  openRegisterPageFalse,
} from "../../../actions/index";
import Navbar from "../../Navbar/Navbar";
import TypewriterComponent from "typewriter-effect";

const BannerSection = (props) => {
  const [mybg, setMybg] = useState({});
  const [typewriterTexts, setTypeWriterText] = useState([]);

  useEffect(() => {
    if (props.from !== "home") {
      var obj = {
        backgroundImage:
          "url('" + props.backgroundImage.backgroundImageUrl + "')",
        height: "100vh",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
      setMybg(obj);
      var texts = props.backgroundImage.typeWriterTexts
        ? props.backgroundImage.typeWriterTexts.map(
            (textObj) =>
              textObj.text.charAt(0).toUpperCase() + textObj.text.slice(1)
          )
        : [
            "Order with a QR code from the comfort of your seat",
            "The world's best menu presentation and tableside ordering and kitchen management platform",
            "World's cheapest EPOs: handle subscriptions, offers, loyalty schemes, Dine-in/Delivery/Collection all for 0.2%",
          ];
      setTypeWriterText(texts);
    } else {
      setTypeWriterText(
        [
          "Order with a QR code from the comfort of your seat",
          "The world's best menu presentation and tableside ordering and kitchen management platform",
          "World's cheapest EPOs: handle subscriptions, offers, loyalty schemes, Dine-in/Delivery/Collection all for 0.2%",
        ].map((text) => text.charAt(0).toUpperCase() + text.slice(1))
      );
    }
  }, []);

  // useEffect(() => {
  //   console.log(props);
  //   if (props.from != 'home') {
  //     var obj = {
  //       backgroundImage:
  //         "url('" + props.backgroundImage.backgroundImageUrl + "')",
  //       height: '100vh',
  //       marginTop: '-70px',
  //       fontSize: '50px',
  //       backgroundSize: 'cover',
  //       backgroundRepeat: 'no-repeat',
  //     };
  //     setMybg(obj);
  //     var texts = [];
  //     if (props.backgroundImage.typeWriterTexts != undefined) {
  //       console.log(props.backgroundImage.typeWriterTexts.length);
  //       for (var i = 0; i < props.backgroundImage.typeWriterTexts.length; i++) {
  //         texts.push(props.backgroundImage.typeWriterTexts[i].text);
  //       }

  //       // props.backgroundImage.typeWriterTexts.map((dt, i) => {
  //       //     texts.push(dt.text)
  //       // })

  //       setTypeWriterText(texts);
  //     } else {
  //       setTypeWriterText([
  //         'Deliver food to your door step',
  //         'Know your favorite food history',
  //         'Fast delivery',
  //         'Quick service',
  //         'Authentic food',
  //       ]);
  //     }
  //   } else {
  //     setTypeWriterText([
  //       'Deliver food to your door step',
  //       'Know your favorite food history',
  //       'Fast delivery',
  //       'Quick service',
  //       'Authentic food',
  //     ]);
  //   }
  // }, []);

  return (
    <div>
      {/* <Navbar /> */}
      {props.from == "home" ? (
        <section
          className="bannerHome banner"
          // style={mybg}
        >
          <div className="d-flex typewriterDiv fs-1 ">
            {typewriterTexts.length > 0 ? (
              <TypewriterComponent
                options={{
                  strings: typewriterTexts,
                  autoStart: true,
                  loop: true,
                  delay: 200,
                }}
              />
            ) : (
              ""
            )}
          </div>
        </section>
      ) : (
        <div>
          {props.backgroundImage.backgroundImageUrl != undefined && (
            <section className="banner" style={mybg}>
              <div className="d-flex typewriterDiv">
                <TypewriterComponent
                  options={{
                    strings: typewriterTexts,
                    autoStart: true,
                    loop: true,
                    delay: 100,
                  }}
                />
              </div>
            </section>
          )}
        </div>
      )}
      {/* <section
                className="banner"
                style={mybg}
            >
                <div className='d-flex typewriterDiv'>
                    <TypewriterComponent
                        options={{
                            strings: [
                                'Deliver food to your door step',
                                'Know your favorite food history',
                                'Fast delivery',
                                'Quick service',
                                'Authentic food',
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 100,
                        }}
                    />
                </div>

            </section> */}
    </div>
  );
};

export default BannerSection;
