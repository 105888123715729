import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Navbar from "../Navbar/Navbar";
import chefCooking from "../../Assets/Image/chefCooking.jpg";
import deliveryMan from "../../Assets/Image/deliveryMan.png";
import "./OrderStatus.css";

import { ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import axios from "axios";

export default function OrderStatus() {
  const [blur, setBlur] = useState(false);

  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(false);
  const [userId, setUserId] = useState();
  const [orderDetails, setOrderDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("preparing");

  // Function to handle tab click
  const handleTabClick = (status) => {
    setActiveTab(status);
  };

  const checkOutTopRef = useRef(null);

  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const filterBarRect = checkOutTopRef?.current?.getBoundingClientRect();
      const isAboveViewport = filterBarRect?.top === 0 ? true : false;
      setNavbarOnTop(isAboveViewport);
      var top = filterBarRect?.top * -1;
      var scrollval = scrollValue * -1;
      if (top > scrollval) {
        setSlideUp(true);
      } else if (top <= 0) {
        setSlideUp(false);
      } else {
        setSlideUp(false);
      }

      var s = filterBarRect.top;
      setscrollValue(s);
    });
  });

  let gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

  useEffect(() => {
    let decoded = {};

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
        // console.log(decoded);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      setUserId(decoded.UserId);
      // getUserChatPreview(decoded.UserId);
    }
  }, [gotasteClienttoken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET}/api/Order/GetOngoingOrdersByUserId?userId=${userId}`
        );
        console.log("my response", response.data);
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
        // Handle errors, e.g., show an error message to the user
      }
    };
    // Check if userId is available before making the request
    if (userId) {
      fetchData();
    }
  }, [userId]);

  // will be removed later
  console.log("orderDetails", orderDetails);

  return (
    <div>
      <ToastContainer />
      <Navbar handleNavbar={searchDialogOpen} />
      <div ref={checkOutTopRef}></div>
      <div className={`${blur ? "BackBlur" : ""}`}>
        <div className="container checkout-container">
          <div className="row">
            <div className="col-md-7">
              <div className="col-12 align-items-center justify-content-center text-center">
                {/* New section: Order Status */}
                <div className="checkOutCard pt-4">
                  <h5>Estimated delivery time</h5>
                  <h3 className="fw-bold">10 min</h3>
                  <img
                    className="chefCooking"
                    src={chefCooking}
                    alt="chef cooking"
                  />
                  <div className="status pb-4 pt-3">
                    <span
                      className={`status-tab ${
                        activeTab === "preparing" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("preparing")}
                    ></span>
                    <span
                      className={`status-tab tab-2 ${
                        activeTab === "foodPicked" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("foodPicked")}
                    ></span>
                    <span
                      className={`status-tab ${
                        activeTab === "onTheWay" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("onTheWay")}
                    ></span>
                    <span
                      className={`status-tab ${
                        activeTab === "delivered" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("delivered")}
                    ></span>
                  </div>
                  {/* Render content based on activeTab */}
                  {activeTab === "preparing" && (
                    <p>
                      Preparing your food. Your rider will pick it up once it's
                      ready.
                    </p>
                  )}
                  {activeTab === "foodPicked" && (
                    <p>Your food has been picked up and is on the way.</p>
                  )}
                  {activeTab === "onTheWay" && (
                    <p>Your order is on the way. It will be delivered soon.</p>
                  )}
                  {activeTab === "delivered" && (
                    <p>Your order has been delivered. Enjoy your meal!</p>
                  )}
                </div>
              </div>
              <div className="col-12 mt-4">
                {/* New section: Rider Contact */}
                <div className="checkOutCard">
                  <div className="row">
                    {/* Image */}
                    <div className="col-2">
                      <img
                        className="deliveryMan"
                        src={deliveryMan}
                        alt="delivery man"
                      />
                    </div>

                    {/* Text */}
                    <div className="col-6 justify-contend-center align-align-items-center mt-3">
                      <h6>Rider Contact</h6>
                      <p>Ask your rider for contactless delivery.</p>
                    </div>

                    {/* Chat Icon */}
                    <div className="col-4 d-flex justify-content-end align-content-center align-items-center">
                      <div className="chatIcon">
                        <svg
                          fill="#000000"
                          height="40px"
                          width="40px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 60.02 60.02"
                          xmlSpace="preserve"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              <path d="M59.889,47.515L56.05,35.997c2.056-3.615,3.14-7.717,3.14-11.893C59.19,10.819,48.38,0.01,35.095,0.01 c-8.242,0-15.823,4.223-20.219,11.007c3.089-1.291,6.477-2.007,10.029-2.007C39.294,9.01,51,20.716,51,35.105 c0,3.996-0.905,7.783-2.518,11.172l10.263,2.701C58.83,48.999,58.915,49.01,59,49.01c0.004,0,0.007,0,0.011,0 c0.542-0.01,1.009-0.444,1.009-1C60.02,47.83,59.973,47.661,59.889,47.515z"></path>{" "}
                              <path d="M24.905,11.01c-13.286,0-24.095,10.809-24.095,24.095c0,4.176,1.084,8.277,3.14,11.893L0.051,58.694 c-0.116,0.349-0.032,0.732,0.219,1C0.462,59.899,0.728,60.01,1,60.01c0.085,0,0.17-0.011,0.254-0.033l12.788-3.365 c3.349,1.694,7.096,2.588,10.863,2.588C38.191,59.199,49,48.391,49,35.105S38.191,11.01,24.905,11.01z M20,27.01h11 c0.552,0,1,0.447,1,1s-0.448,1-1,1H20c-0.552,0-1-0.447-1-1S19.448,27.01,20,27.01z M36,43.01H15c-0.552,0-1-0.447-1-1s0.448-1,1-1 h21c0.552,0,1,0.447,1,1S36.552,43.01,36,43.01z M36,36.01H15c-0.552,0-1-0.447-1-1s0.448-1,1-1h21c0.552,0,1,0.447,1,1 S36.552,36.01,36,36.01z"></path>{" "}
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Your rider contact content here */}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="sticky-position">
                <div className="col-12">
                  <div className="checkOutCard sticky-position">
                    <h4 className="DeliveryHeader">Order details</h4>
                    {orderDetails?.map((restaurant) => {
                      return (
                        <div key={restaurant.restaurantId}>
                          <div className="orderDetails ms-3">
                            <h5>
                              <b>{restaurant.restaurantName}</b>
                            </h5>
                          </div>
                          {restaurant?.itemDetails?.map((item) => {
                            return (
                              <div className="pb-2">
                                <div key={item?.itemId}>
                                  <div className="d-flex justify-content-between ms-3 orderDetails">
                                    <h5>
                                      {item.qunatity} x {item.itemName}
                                    </h5>
                                    <h5>£{item.itemBasePrice}</h5>
                                  </div>
                                </div>
                                <div className="ps-5 text-black-50">
                                  {item?.options.map((option) => {
                                    return (
                                      option.quantity > 0 && (
                                        <h6 key={option.id}>
                                          +{option.optionName} x
                                          {option.quantity}
                                          <span> £{option.unitPrice} </span>
                                          {option?.suboptions.map(
                                            (suboption) => {
                                              return (
                                                suboption.quantity > 0 && (
                                                  <span key={suboption.id}>
                                                    ({suboption.suboptionName} x
                                                    {suboption.quantity} £
                                                    {suboption.unitPrice})
                                                  </span>
                                                )
                                              );
                                            }
                                          )}
                                          <br />
                                        </h6>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                          <hr></hr>
                        </div>
                      );
                    })}

                    {/* {orderDetails?.map((order) => (
                      <div key={order.cartRestaurantMapperId}>
                        <div className='OrderDetails pb-1'>
                          <p>
                            Your order from: <b>Burger King ttt</b>
                          </p>
                          <p>
                            Order id: <b>{order.orderId}</b>
                          </p>

                          <p>
                            Delivery address: <b>127, Leo street, Liverpool</b>
                          </p>
                        </div>
                        <hr />
                        <div key={order?.itemId}>
                          <div className='d-flex justify-content-between ms-3 orderDetails'>
                            <h5>
                              {order.quantity} x {order.itemName}
                            </h5>
                            <h5>£{order.itemPrice}</h5>
                          </div>
                        </div>
                        <div className='ps-5 text-black-50'>
                          {order?.options?.map((option) => {
                            return (
                              option.quantity > 0 && (
                                <h6 key={option.id}>
                                  +{option.optionName} x{option.quantity}
                                  <span> £{option.unitPrice} </span>
                                  {option?.suboptions?.map((suboption) => {
                                    return (
                                      suboption.quantity > 0 && (
                                        <span key={suboption.id}>
                                          ({suboption.suboptionName} x
                                          {suboption.quantity} £
                                          {suboption.unitPrice})
                                        </span>
                                      )
                                    );
                                  })}
                                  <br />
                                </h6>
                              )
                            );
                          })}
                        </div>
                        <hr />
                      </div>
                    ))} */}
                  </div>
                </div>

                <div className="col-12 mt-4">
                  {/* New section: Need Support */}
                  <div className="checkOutCard">
                    <h4 className="DeliveryHeader">Need Support?</h4>
                    <p className="OrderDetails">
                      Questions regarding your order? Check our
                    </p>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                      <Button variant="outline-dark">
                        <b>HELP CENTER</b>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
