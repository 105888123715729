import React, { useEffect, useState } from 'react';
import RestaurantOrder from './RestaurantOrder';
import FoodModalUpdate from './FoodModalUpdate/FoodModalUpdate';

import axios from 'axios';
import OrderCart from '../../OrderPage/OrderCart/OrderCart';
import { Modal } from 'antd';

const CartDetails = ({ cartData, cartDatas, userId, reloadApiCalling }) => {
  const [cart, setCart] = useState([]);
  // console.log("data from cart details: ", cart);

  // const [orderDetails, setOrderDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setIsLoading] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  // const [selectedItemImage, setSelectedItemImage] = useState([]);
  const [selectedItemImage, setSelectedItemImage] = useState("");
  const [show, setShow] = useState(false);

  const apiAddress = process.env.REACT_APP_SECRET;

  const closeModal = () => {
    setShow(false);
  };

  // useEffect(() => {}, [selectedItemImage]);

  // const getItemDetails = (data) => {
  //   setIsLoading(true);
  //   setShow(true);
  //   setOrderDetails(null);
  //   // setIsLoading(false);
  //   setSelectedItemId(null);
  //   setSelectedItemImage([]);
  //   axios
  //     .get(
  //       apiAddress +
  //       '/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=' +
  //       data.foodItemId
  //     )
  //     .then((response) => {
  //       setOrderDetails(response.data);

  //       setSelectedItemId(data.itemId);
  //       setSelectedItemImage(data.itemImage);

  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  console.log("food item clicked data 2: ", cartData, cartDatas)

  const getItemDetails = async (data) => {
    try {
      setShow(true)
      setIsLoading(true);
      const response = await axios.get(`${apiAddress}/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=${data.foodItemId}`);
      setOrderDetails(response.data);
      setSelectedItemId(data.foodItemId);
      setSelectedItemImage(data.foodItemImage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    // Update the cart state when cartData changes
    setCart(cartData);
  }, [cartData]);

  let cartId;

  if (cart && cart[0]?.id) {
    cartId = cart[0]['id'];
  }

  const handleUpdateCart = (updatedCart) => {
    if (cartId) {
      axios
        .put(`${apiAddress}/api/Cart/UpdateCart/${cartId}`, updatedCart)
        .then((response) => {
          console.log('Posted data', response.data);
        })
        .catch((error) => {
          console.error('Error updating cart:', error);
        });
    }
  };

  const handleDeleteItem = (itemId) => {
    console.log('Item to delete:', itemId);
    if (cartId) {
      axios
        .delete(
          `${apiAddress}/api/Cart/DeleteCart?CartId=${cartId}&ItemId=${itemId}`
        )
        .then((response) => {
          console.log('Deleted data', response.data);
        })
        .catch((error) => {
          console.error('Error deleting cart:', error);
        });
    }
  };

  const reloadingApiCall = () => {
    reloadApiCalling()
  }

  return (
    <>
      {cartDatas?.map((restaurant) => (

        
        <RestaurantOrder
          key={restaurant.restaurantId}
          restaurant={restaurant}
          onUpdateCart={handleUpdateCart}
          onDeleteCart={handleDeleteItem}
          cart={cartDatas} // Pass the cart and setCart as props to RestaurantOrder
          setCart={setCart}
          getItemDetails={getItemDetails}
          refetchCartData={() => reloadingApiCall()}
        />
      ))}
      {(selectedItemImage && show) && (
        <FoodModalUpdate
          show={show}
          handleClose={closeModal}
          orderDetails={orderDetails}
          selectedItemImage={selectedItemImage}
          selectedItemId={selectedItemId}
          cart={cartDatas} // Pass the cart and setCart as props to RestaurantOrder
        // setCart={setCart}
          loading={loading}
        />
      )}
    </>
  );
};

export default CartDetails;

// // CartDetails.jsx

// import React from 'react';
// import RestaurantOrder from './RestaurantOrder';

// const CartDetails = ({ cartData }) => {
//   return (
//     <div>
//       {cartData?.map((restaurant) => (
//         <RestaurantOrder
//           key={restaurant.restaurantId}
//           restaurant={restaurant}
//         />
//       ))}
//     </div>
//   );
// };

// export default CartDetails;
