import React from 'react';
import { Button } from '@mui/material';
import clock from "./../../../Assets/Icons/clock.png";

const CustomOutlinedTab = ({ children }) => {
  const buttonStyles = {
    color: 'black',
    fontWeight: 600,
    
    // borderColor: 'black',
    // margin: '1rem 0',
    cursor: 'default',
    // marginTop: '1.5rem',
    backgroundColor:'white',
    display: 'flex',
    flexDirection: 'column', // Arrange content in a column
    alignItems: 'left', // Center align children and clock icon
    justifyContent: 'left', // Center vertically
    gap: '0rem', // Add space between children and clock icon
    
  };

  return (
    <Button  style={buttonStyles} disabled>
      {children} 
      <span style={{alignItems:"center"}}>
      <img
                    src={clock}
                    alt=""
                    style={{
                      width: "1.1rem",
                      height: "1.1rem",
                      marginLeft: "0.4rem",
                      marginRight:"0.4rem",
                      verticalAlign:"center"
                    }}
                  />
                    <span style={{fontWeight: 100,fontSize:"13px"}}>9:00 AM-10:00 PM</span>
                    </span>
      
    </Button>
  );
};

export default CustomOutlinedTab;
