import React from "react";
import img1 from "./../Assets/Image/airbnb.webp";
import img2 from "./../Assets/Image/aibnb2.webp";
import img3 from "./../Assets/Image/1 (2).jpg";
import cakeImg from "./../Assets/Image/cake1 1.png";
import chickenImg from "./../Assets/Image/delicious-chicken-table 3.png";
import kababImg from "./../Assets/Image/Kabab 1.png";
import latteCoffeeImg from "./../Assets/Image/latte-coffee-cup 1.png";
import pastaImg from "./../Assets/Image/Pasta 1.png";
import soupImg from "./../Assets/Image/Soup 3.png";
import bannerImg from "./../Assets/Image/banner.jpg";
import plusIcon from "./../Assets/Icons/plus.png";
import LeoImg from "./../Assets/Image/Leo.png";
import profile from "./../Assets/Image/PKB.png";
import chatImg from "./../Assets/Image/Leo.png";
import dots from "./../Assets/Icons/dots.png";
import "swiper/css/navigation";
import ImageGallery from "react-image-gallery";

import threeDot from "./../Assets/Icons/more.png";
import bookmarkIcon from "./../Assets/Icons/bookmarkDone.png";
import bookmarkDoneIcon from "./../Assets/Icons/bookmark1.0.png";
import bookmark from "./../Assets/Icons/bookmark-white.png";
import more from "./../Assets/Icons/more2.png";
import airbnb1 from "./../Assets/Image/airbnb.webp";
import airbnb2 from "./../Assets/Image/aibnb2.webp";
import GalleryModal from "./GalleryModal/GalleryModal";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, FreeMode } from "swiper";
import Rating from "react-rating";
import { useEffect, useState, useRef } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Thumbs } from "swiper";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import axios from "axios";

import "./test.css";
const Test3 = (props) => {
  const [changeBookMark, setChangeBookMark] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

    
    const [selectedImage, setSelectedImage] = useState("");
    const hideModalInfo = () => {
        window.$("#galleryModal").modal("hide");
    };
    const showModalInfo = (props) => {
        setSelectedImage(props);
        console.log("selectedImage: " + selectedImage);
        window.$("#galleryModal").modal("show");
    };
    const images = [
        {
            original: airbnb2,
            thumbnail: airbnb2,
        },
        {
            original: airbnb1,
            thumbnail: airbnb1,
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
        {
            original: airbnb2,
            thumbnail: airbnb2,
        },
        {
            original: airbnb1,
            thumbnail: airbnb1,
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ]

  const imageGalleryCLick = (e) => {
    showModalInfo(e.target.currentSrc);
  };


    const apiAddress = process.env.REACT_APP_SECRET;
    const [dataCollections, setDataCollections] = useState([]);
    useEffect(() => {
        axios.get(apiAddress + "/api/FoodItems/GetAllFoodItemListForClientEnd")
            .then((response) => {
                
                setDataCollections(response.data);
            }).catch((error) => {
                console.log(error);
            })
    }, [])

  return (
    <div className="foodSelectionContainer mx-auto">
      <div className="testContainer">
        <div className="box">
          <div className=" foodcart">
            <div className="d-flex justify-content-end cartIcon">
              {/* <img src={plusIcon} alt="" style={{ width: "20px" }} /> */}
              <img src={plusIcon} alt="" style={{ width: "20px" }} />
            </div>

            <div className=" restaurantPageGalleryView">
              <ImageGallery
                items={images}
                onClick={(e) => {
                  imageGalleryCLick(e);
                }}
                showFullscreenButton={false}
                fullscreen={true}
              />
            </div>

            <div className="foodCartText mt-2">
              <div className="d-flex justify-content-between commentLove">
                <div className="d-flex align-items-center justify-content-between commentLove">
                  <i className="fa-regular fa-heart "></i>
                  <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  <button className="btn btn-Buy" style={{ width: "3.5rem" }}>
                    {" "}
                    <img src={plusIcon} alt="" style={{ width: "1rem" }} /> Buy
                  </button>
                  {/* three dot start */}
                  <div class="dropdown">
                    <span
                      class="dropdown-toggle"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={more}
                        alt="dot"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: "2px",
                        }}
                      />
                    </span>
                    {/* <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" href="#">
                          Save
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Offers
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Subscribe
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  {/* three dot end */}

                  <i class="fa-regular fa-bookmark"></i>
                </div>
                {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                <div
                  className="rating-Font mb-1 d-flex align-items-center starRating"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {" "}
                  <Rating
                    className="ratingStar"
                    initialRating={Number(4.5)}
                    emptySymbol="far fa-star icon-color"
                    fullSymbol="fas fa-star icon-color"
                    readonly
                  ></Rating>
                  4.5
                </div>
              </div>
              <div className="d-flex justify-content-between ">
                <h3 className="m-0 p-0">Bread Toast</h3>
                <h4 className="m-0 p-0">10.5$</h4>
              </div>
              <p className="my-1">Topped with tomato, bocconcini... </p>
              {props.restaurantName && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>
              )}

              <div className="d-flex align-items-center">
                <img src={bannerImg} className="me-1 userImg" alt="" />
                <p>
                  Leo Love the art of travel. So many plac So many foods...{" "}
                </p>
              </div>
            </div>
          </div>

          <hr className="mx-5  m-0 p-0 cartHR" />
        </div>

        <div className="box ">
          <div className="foodcart cardcolor mb-3">
            <div className="d-flex justify-content-start ">
              <h3 class="badge pill">Chef Special</h3>
            </div>
            <div className="d-flex justify-content-end cartIcon">
              <img src={plusIcon} alt="" style={{ width: "20px" }} />
            </div>

            <Swiper
              spaceBetween={2}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={"auto"}
              cssMode={true}
              pagination={{
                dynamicBullets: true,
              }}
              // slidesPerView={1}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="foodcartimg"
            >
              <SwiperSlide onClick={props.onClickInsideTest3}>
                <img
                  src={img1}
                  className="img-fluid "
                  alt="..."
                  onClick={props.onClickInsideTest3}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img1}
                  className="img-fluid"
                  alt="..."
                  onClick={props.onClickInsideTest3}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img2}
                  className="img-fluid"
                  alt="..."
                  onClick={props.onClickInsideTest3}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img3}
                  className="img-fluid"
                  alt="..."
                  onClick={props.onClickInsideTest3}
                />
              </SwiperSlide>
            </Swiper>

            <div className="foodCartText mt-2">
              <div className="d-flex justify-content-between commentLove">
                <div className="d-flex commentLove">
                  <i className="fa-regular fa-heart "></i>
                  <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  {/* three dot start */}
                  <div class="dropdown">
                    <span
                      class="dropdown-toggle"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      {/* <i class="fas fa-ellipsis-v"></i> */}
                      <img
                        src={more}
                        alt="dot"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: "2px",
                        }}
                      />
                    </span>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" href="#">
                          Save
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Offers
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Subscribe
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* three dot end */}
                </div>
                {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                <div
                  className="rating-Font mb-1 d-flex align-items-center starRating"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  {" "}
                  <Rating
                    className="ratingStar"
                    initialRating={Number(4.5)}
                    emptySymbol="far fa-star icon-color"
                    fullSymbol="fas fa-star icon-color"
                    readonly
                  ></Rating>
                  4.5
                </div>
              </div>
              <div className="d-flex justify-content-between ">
                <h3 className="m-0 p-0">Bread Toast</h3>
                <h4 className="m-0 p-0">10.5$</h4>
              </div>
              <p className="my-1">Topped with tomato, bocconcini... </p>
              {props.restaurantName && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>
              )}

              <div className="d-flex align-items-center">
                <img src={LeoImg} className="me-1 userImg" alt="" />
                <p>
                  Leo Love the art of travel. So many plac So many foods...{" "}
                </p>
              </div>
            </div>
          </div>
          <hr className="mx-5  m-0 p-0 cartHR" />
        </div>
        {dataCollections.map((dt, i) => {
          return (
            <div className="box">
              <div className=" foodcart">
                <div className="d-flex justify-content-end cartIcon">
                  <img src={plusIcon} alt="" style={{ width: "20px" }} />
                </div>

                <Swiper
                  spaceBetween={2}
                  grabCursor={false}
                  centeredSlides={false}
                  slidesPerView={"auto"}
                  cssMode={true}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="foodcartimg"
                >
                  {dt.foodItemImages.length > 0 ? (
                    dt.foodItemImages.map((d, index) => {
                      return (
                        <SwiperSlide>
                          <img
                            src={d.itemImagePath}
                            className="img-fluid "
                            alt="..."
                            onClick={props.onClickInsideTest3}
                          />
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <SwiperSlide>
                      <img
                        src={img1}
                        className="img-fluid"
                        alt="..."
                        onClick={props.onClickInsideTest3}
                      />
                    </SwiperSlide>
                  )}

                  {/* <SwiperSlide><img src={img1} className="img-fluid" alt="..." onClick={props.onClickInsideTest3} /></SwiperSlide>
                                        <SwiperSlide><img src={img2} className="img-fluid" alt="..." onClick={props.onClickInsideTest3} /></SwiperSlide>
                                        <SwiperSlide><img src={img3} className="img-fluid" alt="..." onClick={props.onClickInsideTest3} /></SwiperSlide> */}
                </Swiper>

                <div className="foodCartText mt-2">
                  <div className="d-flex justify-content-between commentLove">
                    <div className="d-flex commentLove">
                      <i className="fa-regular fa-heart "></i>
                      <i className="fa-sharp fa-regular fa-comment-dots"></i>
                      {/* three dot start */}
                      <div class="dropdown">
                        <span
                          class="dropdown-toggle"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <i class="fas fa-ellipsis-v"></i> */}
                          <img
                            src={more}
                            alt="dot"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "2px",
                            }}
                          />
                        </span>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a class="dropdown-item" href="#">
                              Save
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Offers
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Subscribe
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* three dot end */}
                    </div>
                    {/* <div className='d-flex align-items-center starRating'>

                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <h4 className='m-0 p-0'>4.5</h4>

                                </div> */}
                    <div
                      className="rating-Font mb-1 d-flex align-items-center starRating"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      {" "}
                      <Rating
                        className="ratingStar"
                        initialRating={Number(4.5)}
                        emptySymbol="far fa-star icon-color"
                        fullSymbol="fas fa-star icon-color"
                        readonly
                      ></Rating>
                      4.5
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <h3 className="m-0 p-0">{dt.itemName}</h3>
                    <h4 className="m-0 p-0">{dt.itemBasePrice}</h4>
                  </div>
                  <p className="my-1">{dt.description} </p>
                  {props.restaurantName && (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <h6>{dt.name}</h6>
                        <div className="d-flex align-items-center starRating">
                          <i className="fa-sharp fa-solid fa-star"></i>
                          <h4 className="m-0 p-0">4.5</h4>
                        </div>
                      </div>

                      <button className="follow-BUtton btn ms-1">Follow</button>
                    </div>
                  )}

                  <div className="d-flex align-items-center">
                    <img src={LeoImg} className="me-1 userImg" alt="" />
                    <p>
                      Leo Love the art of travel. So many plac So many foods...{" "}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="mx-5  m-0 p-0 cartHR" />
            </div>
          );
        })}
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Reviews
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="container">
                <div className="d-flex align-items-center">
                  <img src={profile} className="profileImg" alt="" />
                  <div className="reviewTitle">
                    <h3>Pot Kettle Black</h3>
                    <div className="d-flex align-items-center">
                      <h5 className=" p-0 m-0 me-2">4.5</h5>
                      <Rating
                        className=""
                        initialRating={Number(4.5)}
                        emptySymbol="far fa-star icon-color"
                        fullSymbol="fas fa-star icon-color"
                        readonly
                      ></Rating>
                    </div>
                  </div>
                </div>

                <div className="mt-5 ">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex   align-items-center  ">
                      <div className="me-2">
                        {" "}
                        <img src={chatImg} alt="" />
                      </div>
                      <div>
                        <h6 className="m-0 p-0">Jeremy Renner</h6>
                      </div>
                    </div>
                    {/* <img src={dots} className='dots' alt="" /> */}
                  </div>
                  <div>
                    <div className="mt-3">
                      <div className="">
                        4.5{" "}
                        <Rating
                          className=""
                          initialRating={Number(4.5)}
                          emptySymbol="far fa-star icon-color"
                          fullSymbol="fas fa-star icon-color"
                          readonly
                        ></Rating>
                      </div>
                      <p className="mt-3 reviewModalText">May/8/2023</p>
                    </div>
                    <h6 className="reviewModalText">
                      {" "}
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </h6>
                  </div>
                </div>
                <div className="mt-5 ">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex   align-items-center  ">
                      <div className="me-2">
                        {" "}
                        <img src={chatImg} alt="" />
                      </div>
                      <div>
                        <h6 className="m-0 p-0">Jeremy Renner</h6>
                      </div>
                    </div>
                    {/* <img src={dots} className='dots' alt="" /> */}
                  </div>
                  <div>
                    <div className="mt-3">
                      <div className="">
                        4.5{" "}
                        <Rating
                          className=""
                          initialRating={Number(4.5)}
                          emptySymbol="far fa-star icon-color"
                          fullSymbol="fas fa-star icon-color"
                          readonly
                        ></Rating>
                      </div>
                      <p className="mt-3 reviewModalText">May/8/2023</p>
                    </div>
                    <h6 className="reviewModalText">
                      {" "}
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test3;
