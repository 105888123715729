import React, { useEffect, useState } from 'react';
import './Sauces.css';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

function Sauces(props) {
  const [amount, setAmount] = useState(0);
  const [selectedItem, setSelectedItem] = useState({ name: '', amount: 0 });
  const [id, setId] = useState(props.id);
  //sauces quantity start

  const [sacesQunatity, setsacesQunatity] = useState(0);
  const { setOptionQuantities } = props;
  const { setSauceAdded } = props;
  const [optionText, setOptionText] = useState('');
  const [optionsIds, setOptionsIds] = useState([]);

  // const props.optionButtonId = 4

  const changeQuantity = (types, optionId) => {
    var prices = props.price;
    var quantity = sacesQunatity;

    if (types == 'plus') {
      setsacesQunatity(sacesQunatity + 1);
      setOptionsIds((prev) => [prev, optionId]);
      setOptionQuantities((prevQuantities) => {
        const currentQuantity = prevQuantities[optionId] || 0; // Get current quantity for the option, default to 0
        let newQuantity = currentQuantity;

        if (types === 'plus') {
          newQuantity = currentQuantity + 1;
        } else if (types === 'minus' && currentQuantity > 0) {
          newQuantity = currentQuantity - 1;
        }

        return {
          ...prevQuantities,
          [optionId]: newQuantity, // Update the quantity for this specific option
        };
      });

      quantity = quantity + 1;
      prices = /*(sacesQunatity + 1) */ prices;
    } else if (types == 'minus') {
      var updatedQuantity = sacesQunatity - 1;
      if (updatedQuantity < 1) {
        setsacesQunatity(0);
        setOptionsIds((prevOptionsIds) =>
          prevOptionsIds.filter((x) => x !== optionId)
        );
        quantity = 0;
      } else {
        setsacesQunatity(sacesQunatity - 1);
        prices = (sacesQunatity - 1) * prices;
        quantity = quantity - 1;
      }
    }
    props.saucesCallback(id, prices, quantity, props.addonId);
  };

  // const changeQuantity = (types, optionId) => {
  //   let newQuantity = 0
  //   setOptionQuantities((prevQuantities) => {
  //     const currentQuantity = prevQuantities[optionId] || 1; // Get current quantity for the option, default to 0
  //     newQuantity = currentQuantity;

  //     if (types === "plus") {
  //       newQuantity = currentQuantity + 1;
  //     } else if (types === "minus" && currentQuantity > 0) {
  //       newQuantity = currentQuantity - 1;
  //     }

  //     return {
  //       ...prevQuantities,
  //       [optionId]: newQuantity, // Update the quantity for this specific option
  //     };
  //   });

  //   // Trigger the callback with updated values (if needed)
  //   const prices = newQuantity * props.price;
  //   props.saucesCallback(optionId, prices, newQuantity, props.addonId);
  // };
  //sauces quantity end
  useEffect(() => {
    if (sacesQunatity > 0) {
      setSauceAdded(true);
    } else if (sacesQunatity === 0) {
      setSauceAdded(false);
    }

    setAmount(sacesQunatity * props.price);
    setSelectedItem({ name: props.name, amount: amount });
  }, [sacesQunatity]);
  // useEffect(() => {
  //   setSelectedItem({ name: props.name, amount: amount });
  //   props.saucesCallback(id, amount, sacesQunatity);
  // }, [amount]);
  // props.subOptions.map((subop) =>
  //   console.log("added sub option", props.subOptions.includes(subop.id))
  // );

  const [subOptionEnable, setSubOptionEnable] = useState('none');
  const [selectedSubOptions, setSelectedSubOptions] = useState({});
  // const subOptionOnclick = (subOption) => {
  //   // alert(subOption);
  //   if (
  //     selectedSubOptions ||
  //     (selectedSubOptions?.subOptionId != subOption.subOptionId &&
  //       selectedSubOptions?.optionId == subOption.optionId)
  //   ) {
  //     alert("if");
  //     setSubOptionEnable(subOption.optionId + subOption.subOptionId);

  //     props.setsubOptions((prevState) => [...prevState, subOption]);

  //     setSelectedSubOptions(subOption);

  //     props.subOptionCallBack(subOption);

  //     props.handleSubOptionChange(props.id, subOption.subOptionId);
  //   } else {
  //     alert("else");
  //     setSubOptionEnable("none");
  //     props.setsubOptions((prevSubOptions) => {
  //       const subOptionExists = prevSubOptions.some(
  //         (x) =>
  //           x.optionId === subOption.optionId &&
  //           x.subOptionId === subOption.subOptionId
  //       );

  //       if (subOptionExists) {
  //         return prevSubOptions.filter(
  //           (x) =>
  //             !(
  //               x.optionId === subOption.optionId &&
  //               x.subOptionId === subOption.subOptionId
  //             )
  //         );
  //       } else {
  //         return [...prevSubOptions, [...prevSubOptions, subOption]];
  //       }
  //     });
  //     props.subOptionCallBack(subOption);
  //     props.handleSubOptionChange(props.id, subOption.subOptionId);

  //     setSelectedSubOptions({});
  //   }
  // };

  const subOptionOnclick = (subOption) => {
    // Check if selectedSubOptions is not null and if its optionId and subOptionId match the current subOption
    var combinedId = subOption.optionId + subOption.subOptionId;
    if (combinedId != subOptionEnable) {
      // This is the "if" block

      setSubOptionEnable(subOption.optionId + subOption.subOptionId);

      props.setsubOptions((prevState) => [...prevState, subOption]);

      setSelectedSubOptions(subOption);

      props.subOptionCallBack(subOption);

      props.handleSubOptionChange(props.id, subOption.subOptionId);
    } else {
      // This is the "else" block

      setSubOptionEnable('none');
      props.setsubOptions((prevSubOptions) => {
        const subOptionExists = prevSubOptions.some(
          (x) =>
            x.optionId === subOption.optionId &&
            x.subOptionId === subOption.subOptionId
        );

        if (subOptionExists) {
          // Remove the existing subOption
          return prevSubOptions.filter(
            (x) =>
              !(
                x.optionId === subOption.optionId &&
                x.subOptionId === subOption.subOptionId
              )
          );
        } else {
          // Add the new subOption
          return [...prevSubOptions, subOption];
        }
      });
      props.subOptionCallBack(subOption);
      props.handleSubOptionChange(props.id, subOption.subOptionId);

      setSelectedSubOptions({});
    }
  };

  const [optionEnable, setOptionEnable] = useState(false);
  // const optionOnclick = () => {
  //   if (!optionEnable) {
  //     setOptionEnable(!optionEnable);
  //     // props.setoptions((prevState) => [...prevState, props.id, props.addonId]);
  //     props.handleRadioButtonChange(props.addonId, props.id);
  //     changeQuantity("plus", props.id);
  //   } else {
  //     setOptionEnable(!optionEnable);
  //     setSubOptionEnable(false);
  //     changeQuantity("minus", props.id);
  //   }
  // };

  const optionOnclick = () => {
    if (!optionEnable) {
      setOptionEnable(true);
      props.handleRadioButtonChange(props.addonId, props.id); // Add the option to parent state
      changeQuantity('plus', props.id); // Increment quantity
    } else {
      setOptionEnable(false);
      props.handleRadioButtonChange(props.addonId, props.id); // Remove the option from parent state
      setSubOptionEnable(false); // Disable sub-option if any
      changeQuantity('minus', props.id); // Decrement quantity
    }
  };

  return (
    <>
      {/* Option displaying */}
      {/* {JSON.stringify(selectedSubOptions)} */}

      <div className='d-flex justify-content-between'>
        <div
          className={`d-flex flex-column ${
            props.optionButtonId === 5 || props.optionButtonId === 6
              ? 'w-100'
              : ''
          }`}
        >
          <h6
            className={`${
              props.optionButtonId === 5 || props.optionButtonId === 6
                ? 'w-100'
                : ''
            }`}
          >
            {props.optionButtonId === 3 ? (
              <>
                <input
                  checked={optionEnable}
                  onClick={() => optionOnclick()}
                  type='radio'
                  className='me-1'
                  name={props.addOnName}
                  // checked={props.selectedOptions[props.addonId] === props.id}
                />
              </>
            ) : props.optionButtonId === 4 ? (
              <>
                <input
                  type='checkbox'
                  className='me-1 checkbox-gray'
                  name='options'
                  onChange={(e) => {
                    props.handleCheckboxChange(
                      props.addonId,
                      props.id,
                      e.target.checked
                    );
                    changeQuantity(
                      optionsIds.find((x) => x == props.id) ? 'minus' : 'plus',
                      props.id
                    );
                    setSubOptionEnable('None');
                  }}
                />
              </>
            ) : (
              ''
            )}

            {props.optionButtonId === 5 ? (
              <button
                className='option-btn'
                onClick={() =>
                  props.handleButtonChange(props.addonId, props.id)
                }
              >
                {props.name}
              </button>
            ) : props.optionButtonId === 6 ? (
              <textarea cols={12} rows={4} className='w-100 option-details'>
                {props.name}
              </textarea>
            ) : (
              <>
                <span>{props.name}</span>
                {props.subOptionWarnings && (
                  <div className='warning-text text-warning'>
                    {props.subOptionWarnings}
                  </div>
                )}
              </>
            )}
          </h6>
          <div
            className={`${
              props.optionButtonId === 5 || props.optionButtonId === 6
                ? 'd-none'
                : 'd-flex'
            }`}
          >
            {amount == 0 ? <p>£ {props.price}</p> : <p>£ {amount}</p>}
          </div>
        </div>

       {!props?.isCountable && props?.sauceIsCountable && (
          <div
            className={`${
              !props.isCountable ||
              props.optionButtonId === 5 ||
              props.optionButtonId === 6
                ? 'd-none'
                : 'd-flex'
            }`}
            style={{ gap: '10px' }}
          >
            {sacesQunatity > 0 && (
              <>
                <div
                  onClick={() => {
                    changeQuantity('minus', props.id);
                  }}
                  className=' plusCircle'
                >
                  <p>-</p>
                </div>

                {sacesQunatity}
              </>
            )}
            <div
              onClick={() => {
                changeQuantity('plus', props.id);
              }}
              className=' plusCircle'
            >
              <p>+</p>
            </div>
          </div>
      )}
      </div>
      {/* SubOption displaying */}
      {/* {subOptionEnable} */}
      {sacesQunatity > 0 && (
        <div style={{ marginLeft: '1.5rem' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='female'
              name='radio-buttons-group'
              value={subOptionEnable}
            >
              {props.subOptions.map((subOption, i) => {
                return (
                  <>
                    {/* <div className="form-check p-0 ms-4 checkBoxOrderPage">
                      <input
                        style={{ float: "left", margin: "5px 5px 0px 0px" }}
                        type="radio"
                        name={subOption.optionId + props.addonId}
                        id={
                          subOption.subOptionId +
                          subOption.optionId +
                          props.addonId
                        }
                        value={subOption.price}
                        checked={subOptionEnable}
                        onClick={() => subOptionOnclick(subOption)}
                      />

                      <div className="d-flex justify-content-between">
                        <label
                          className="form-check-label "
                          for={subOption.optionId + props.addonId}
                        >
                          {subOption.subOptionName}
                        </label>
                        <p>£ {subOption.price}</p>
                      </div>
                    </div> */}

                    <FormControlLabel
                      value={subOption.optionId + subOption.subOptionId}
                      control={<Radio />}
                      label={subOption.subOptionName + ' £ ' + subOption.price}
                      onClick={() => subOptionOnclick(subOption)}
                    />
                  </>
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </>
  );
}

export default Sauces;
