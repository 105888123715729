import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function RestaurantSelectionFooter({
  restaurant,
  menu,
  section,
  foodItem,
  selectData,
  linkActive,
  setLinkActive,
}) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  /* Checking for duplicate tagged item; */

  const handleLink = (parameters) => {
    selectData();
    setLinkActive(true); //showing tagged item on the post modal

    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 150);

    switch (parameters) {
      case 'restaurant':
        setNotification('Restaurant Linked');
        break;
      case 'menu':
        setNotification('Menu Linked');
        break;
      case 'section':
        setNotification('Section Linked');
        break;
      case 'fooditem':
        setNotification('FoodItem Linked');
        break;
      default:
        setNotification('');
        break;
    }

    setTimeout(() => {
      // setShow(false);
    }, 450);
  };

  const [notification, setNotification] = useState();

  useEffect(() => {
    toast.success(notification);
  }, [notification]);
  return (
    <>
      {' '}
      {restaurant?.length === 0 &&
        menu?.length === 0 &&
        section?.length === 0 &&
        foodItem?.length === 0 && (
          <div class='button-container'>
            <button
              class='pill-button'
              disabled={true}
              style={{
                backgroundColor: 'lightgray',
                color: 'black',
                cursor: 'not-allowed',
                borderRadius: '20px',
                border: linkActive ? 'none' : '1px solid black',
              }}
            >
              Link Restaurant
            </button>
          </div>
        )}
      {restaurant?.length > 0 &&
        menu?.length === 0 &&
        section?.length === 0 &&
        foodItem?.length === 0 && (
          <div className='button-container'>
            <button
              className={`pill-button ${isAnimating ? 'animate' : ''}`}
              style={{
                backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
                color: buttonDisabled ? 'black' : 'white',
                cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                borderRadius: '20px',
                border: linkActive ? 'none' : '1px solid black',
              }}
              onClick={() => handleLink('restaurant')}
              disabled={buttonDisabled}
            >
              {notification === 'Restaurant Linked'
                ? notification
                : 'Link Restaurant'}
            </button>
          </div>
        )}
      {menu?.length > 0 && section?.length === 0 && foodItem?.length === 0 && (
        <div className='button-container'>
          <button
            className={`pill-button ${isAnimating ? 'animate' : ''}`}
            style={{
              backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
              color: buttonDisabled ? 'black' : 'white',
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              borderRadius: '20px',
              border: linkActive ? 'none' : '1px solid black',
            }}
            onClick={() => handleLink('menu')}
            disabled={buttonDisabled}
          >
            {notification === 'Menu Linked' ? notification : 'Link Menu'}
          </button>
        </div>
      )}
      {section?.length > 0 && foodItem?.length === 0 && (
        <div className='button-container'>
          <button
            className={`pill-button ${isAnimating ? 'animate' : ''}`}
            style={{
              backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
              color: buttonDisabled ? 'black' : 'white',
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              borderRadius: '20px',
              border: linkActive ? 'none' : '1px solid black',
            }}
            onClick={() => handleLink('section')}
            disabled={buttonDisabled}
          >
            {notification === 'Section Linked' ? notification : 'Link Section'}
          </button>
        </div>
      )}
      {foodItem?.length > 0 && (
        <div className='button-container'>
          <button
            className={`pill-button ${isAnimating ? 'animate' : ''}`}
            style={{
              backgroundColor: buttonDisabled ? 'lightgray' : 'gray',
              color: buttonDisabled ? 'black' : 'white',
              cursor: buttonDisabled ? 'not-allowed' : 'pointer',
              borderRadius: '20px',
              border: linkActive ? 'none' : '1px solid black',
            }}
            onClick={() => handleLink('fooditem')}
            disabled={buttonDisabled}
          >
            {notification === 'FoodItem Linked'
              ? notification
              : 'Link FoodItem'}
          </button>
        </div>
      )}
    </>
  );
}
