import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import 'swiper/css'
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/navigation'

import burger from './../../../../Assets/Image/Burger22.jpeg'
import bannerImg from './../../../../Assets/Image/PastaOffer.jpg'
import airBnbImage from './../../../../Assets/Image/1 (2).jpg'
import chickenImg from './../../../../Assets/Image/ChickenFry.jpg'
import res from './../../../../Assets/Icons/res.png'
import down from './../../../../Assets/Icons/chevron.png'
import up from './../../../../Assets/Icons/chevron2.png'


import './SubscriptionSlider.css'
import { Carousel } from 'react-responsive-carousel';

const SubscriptionSlider = (props) => {
    const [fromHome, SetFromHome] = useState(true);
    const [isDownSelected, setDownSelected] = useState(false)

    const handleDownClick = () => {
        setDownSelected(!isDownSelected)
    }
    useEffect(() => {
        console.log(props);
        if (props.from === 2) {
            SetFromHome(false);
        }
    })
    return (
        // <div className='sub'>
        //     <Swiper
        //         spaceBetween={30}
        //         grabCursor={false}
        //         centeredSlides={false}
        //         slidesPerView={4}
        //         hashNavigation={{
        //             watchState: true,
        //         }}
        //         // autoplay={{
        //         //   delay: 2000,
        //         //   disableOnInteraction: true,
        //         // }}
        //         breakpoints={{
        //             300: {
        //                 slidesPerView: 1,
        //                 spaceBetween: 5,
        //             },
        //             640: {
        //                 slidesPerView: 2,
        //                 spaceBetween: 5,
        //             },
        //             768: {
        //                 slidesPerView: 3,
        //                 spaceBetween: 10,
        //             },
        //         }}
        //         navigation={true}
        //         modules={[Autoplay, Navigation, Pagination]}
        //         pagination={{
        //             dynamicBullets: true,
        //             clickable: true,
        //         }}

        //     >
        //         <SwiperSlide>


        //             <div className="subscriptionImage ">

        //                 <img src={airBnbImage} alt="" className="img-fluid image-Discount" />
        //                 <div className="subscriptionDiv ">
        //                     <div className="subscriptionDivDetails ">
        //                         <div className=' p-2 d-flex  justify-content-between'>
        //                             <div className='d-flex flex-column'>
        //                             <h3 >Platinum</h3>
        //                             <p style={{marginTop:'0.5rem', }}>Ratings 4.5/5</p>

        //                             </div>
        //                         <div className='d-flex flex-column '>
        //                         {/* <p>Ratings 4.5/5</p> */}
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                             <Link to="/horizontalpage" style={{textDecoration:'none'}}>
        //                             <p style={{marginTop:'0.5rem', textDecoration:'none', color:'white'}}>View more</p>
        //                             </Link>
        //                         </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='p-2 subscriptionDetails' >
        //                  <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                     <h5 style={{marginBottom:'0px'}}>Burger +2 more</h5>


        //                     {/* <div onClick={handleDownClick}>
        //                     <img src={isDownSelected? up : down} alt='down' style={{width:'1rem', height:'1rem'}}/>
        //                     </div> */}

        //                  </div>
        //                  <div style={{color:'gray'}}>
        //                     <h6 >Chilli sauce, Garlic sauce</h6>
        //                  </div>
        //             </div>
        //             {/* {isDownSelected && 
        //             <>
        //             <div className='p-2 subscriptionDetails' >
        //             <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                <h5 style={{marginBottom:'0px'}}>Pizza</h5>

        //                <p>Ratings 5/5</p>


        //             </div>
        //             <div style={{color:'gray'}}>
        //                <h6 >Chilli sauce, Garlic sauce</h6>
        //             </div>
        //        </div>
        //         <div className='p-2 subscriptionDetails' >
        //         <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //            <h5 style={{marginBottom:'0px'}}>Chicken</h5>

        //            <p>Ratings 4/5</p>


        //         </div>
        //         <div style={{color:'gray'}}>
        //            <h6 >Chilli sauce, Garlic sauce</h6>
        //         </div>
        //    </div>
        //             </> } */}
        //         </SwiperSlide>
        //         <SwiperSlide>
        //             <div className="subscriptionImage ">

        //                 <img src={bannerImg} alt="" className="img-fluid image-Discount" />
        //                 <div className="subscriptionDiv ">
        //                     <div className="subscriptionDivDetails ">
        //                         <div className=' p-2'>
        //                             <div>
        //                             <h3 >Exclusive</h3>

        //                             </div>
        //                         <div className='d-flex justify-content-between OrderNowBtn align-items-end'>
        //                         <p>Ratings 4.5/5</p>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='p-2 subscriptionDetails' >
        //                  <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                     <h5 style={{marginBottom:'0px'}}>Pizza +3 more</h5>


        //                  </div>
        //                  <div style={{color:'gray'}}>
        //                     <h6 >Chilli sauce, Garlic sauce</h6>
        //                  </div>
        //             </div>
        //         </SwiperSlide>
        //         <SwiperSlide>
        //             <div className="subscriptionImage ">
        //                 <img src={chickenImg} alt="" className="img-fluid image-Discount" />
        //                 <div className="subscriptionDiv ">
        //                     <div className="subscriptionDivDetails ">
        //                         <div className=' p-2'>
        //                             <div>
        //                             <h3 >Signature</h3>

        //                             </div>
        //                         <div className='d-flex justify-content-between OrderNowBtn align-items-end'>
        //                         <p>Ratings 4.5/5</p>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='p-2 subscriptionDetails' >
        //                  <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                     <h5 style={{marginBottom:'0px'}}>Chicken +1 more</h5>


        //                  </div>
        //                  <div style={{color:'gray'}}>
        //                  <h6 >Chilli sauce, Garlic sauce</h6>
        //                  </div>
        //             </div>
        //         </SwiperSlide>
        //         <SwiperSlide>
        //             <div className="subscriptionImage ">
        //                 <img src={chickenImg} alt="" className="img-fluid image-Discount" />
        //                 <div className="subscriptionDiv ">
        //                     <div className="subscriptionDivDetails ">
        //                         <div className=' p-2'>
        //                             <div>
        //                             <h3 >Premium plus</h3>

        //                             </div>
        //                         <div className='d-flex justify-content-between OrderNowBtn align-items-end'>
        //                         <p>Ratings 4.5/5</p>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='p-2 subscriptionDetails' >
        //                  <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                     <h5 style={{marginBottom:'0px'}}>Noodles</h5>


        //                  </div>
        //                  <div style={{color:'gray'}}>
        //                  <h6 >Chilli sauce, Garlic sauce</h6>
        //                  </div>
        //             </div>
        //         </SwiperSlide>
        //         {/* <SwiperSlide>
        //             <div className="subscriptionImage ">

        //                 <img src={bannerImg} alt="" className="img-fluid image-Discount" />
        //                 <div className="subscriptionDiv ">
        //                     <div className="subscriptionDivDetails ">
        //                         <div className='d-flex justify-content-between p-2'>
        //                             <div>
        //                             <h3 style={{color:'white'}}>Exclusive plus</h3>
        //                             <p>Ratings 4.5/5</p>
        //                             </div>
        //                         <div className='OrderNowBtn'>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='p-2 subscriptionDetails' >
        //                  <div className='d-flex justify-content-between' style={{height:'2rem'}}>
        //                     <h5 style={{marginBottom:'0px'}}>London Cafe</h5>


        //                  </div>
        //                  <div style={{color:'gray'}}>
        //                  <h6 >Chilli sauce, Garlic sauce</h6>
        //                  </div>
        //             </div>
        //         </SwiperSlide> */}
        //         {/* <SwiperSlide>
        //             <div className="subscriptionImage-offerCard2 color-Discount-green">
        //                 <div className='row'>
        //                     <div className='col-7'>
        //                         <div className='offer-Text'>
        //                             <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
        //                         </div>
        //                         <div className='BuyNow'>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                     </div>
        //                     <div className='col'>
        //                         <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
        //                     </div>
        //                 </div>
        //             </div>
        //         </SwiperSlide>
        //         <SwiperSlide>
        //             <div className="subscriptionImage-offerCard2 color-Discount-coffee">
        //                 <div className='row'>
        //                     <div className='col-7'>
        //                         <div className='offer-Text'>
        //                             <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
        //                         </div>
        //                         <div className='BuyNow'>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                     </div>
        //                     <div className='col'>
        //                         <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
        //                     </div>
        //                 </div>
        //             </div>
        //         </SwiperSlide>
        //         <SwiperSlide>
        //             <div className="subscriptionImage-offerCard2 color-Discount-yellow">
        //                 <div className='row'>
        //                     <div className='col-7'>
        //                         <div className='offer-Text '>
        //                             <h2 className='yellowColor'><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
        //                         </div>
        //                         <div className='BuyNow'>
        //                             <button className='btn btn-buyNow'>Order Now </button>
        //                         </div>
        //                     </div>
        //                     <div className='col'>
        //                         <img src={burger} alt="" className="img-fluid image-Discount-offerCard2" />
        //                     </div>
        //                 </div>
        //             </div>
        //         </SwiperSlide> */}

        //     </Swiper>
        // </div>

        <div className="d-flex justify-content-center flex-wrap Recommendation-carason">
            <Swiper
                className=""
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={'auto'}

                // cssMode={true}
                hashNavigation={{
                    watchState: true,
                }}
                // autoplay={{
                //     delay: 2000,
                //     disableOnInteraction: true,
                // }}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}
            >
                <SwiperSlide>
                    <div className=' h-100 rounded'>
                        <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                        <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />

                        <div className="subscriptionDiv">
                            <div className="subscriptionDivDetails ">
                                <div className=' p-2 d-flex  justify-content-between'>
                                    <div className='d-flex flex-column'>
                                        <h3 >Platinum</h3>
                                        <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                    </div>
                                    <div className='d-flex flex-column '>
                                        {/* <p>Ratings 4.5/5</p> */}
                                        <button className='btn btn-buyNow'>Order Now </button>
                                        <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                            <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className='d-flex h-100 '>
                        <div className='sliderCart'>

                            <img src={chickenImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{ "borderRadius": ".5rem 0 0 .5rem" }} />
                            <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                            {/* <div className="subscriptionDiv ">
                                <div className="subscriptionDivDetails ">
                                    <div className=' p-2 '>
                                        <div className=''>
                                            <h3 >Platinum</h3>
                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                        </div>
                                        <div className=''>
                                          
                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='sliderCart'>
                            <img src={burger} alt="" className="img-fluid sliderCart w-100 h-100" style={{ "borderRadius": " 0  .5rem  .5rem 0" }} />
                            <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                            {/* <div className="subscriptionDiv ">
                                <div className="subscriptionDivDetails ">
                                    <div className=' p-2 '>
                                        <div className=''>
                                            <h3 >Platinum</h3>
                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                        </div>
                                        <div className=''>
                                 
                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="subscriptionDiv ">
                            <div className="subscriptionDivDetails ">
                                <div className=' p-2 d-flex  justify-content-between'>
                                    <div className='d-flex flex-column'>
                                        <h3 >Platinum</h3>
                                        <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                    </div>
                                    <div className='d-flex flex-column '>
                                        {/* <p>Ratings 4.5/5</p> */}
                                        <button className='btn btn-buyNow'>Order Now </button>
                                        <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                            <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


                <SwiperSlide>
                    <div className='d-flex h-100 '>
                        <div className='sliderCart'>
                            <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                "borderRadius": ".5rem 0 0 .5rem"
                            }} />

                            <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                            {/* <div className="subscriptionDiv ">
                                <div className="subscriptionDivDetails ">
                                    <div className=' p-2 '>
                                        <div className=''>
                                            <h3 >Platinum</h3>
                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                        </div>
                                        <div className=''>
                                   
                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='h-100 threecardSIdecartDiv'>
                            <div className='sliderCart threecardSIdecart'>
                                <img src={chickenImg} alt="" className="img-fluid sliderCart h-100 w-100" style={{
                                    "borderRadius": "0 .5rem 0 0"
                                }} />
                                <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                                {/* <div className="subscriptionDiv ">
                                    <div className="subscriptionDivDetails ">
                                        <div className=' p-2 '>
                                            <div className=''>
                                                <h3 >Platinum</h3>
                                                <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                            </div>
                                            <div className=''>
                                          
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                    <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='sliderCart threecardSIdecart'>
                                <img src={burger} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                    "borderRadius": "0 0 .5rem 0"
                                }} />
                                <h2 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h2>
                                {/* <div className="subscriptionDiv ">
                                    <div className="subscriptionDivDetails ">
                                        <div className=' p-2 '>
                                            <div className=''>
                                                <h3 >Platinum</h3>
                                                <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                            </div>
                                            <div className=''>
                                           
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                    <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                        </div>
                        <div className="subscriptionDiv ">
                            <div className="subscriptionDivDetails ">
                                <div className=' p-2 d-flex  justify-content-between'>
                                    <div className='d-flex flex-column'>
                                        <h3 >Platinum</h3>
                                        <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                    </div>
                                    <div className='d-flex flex-column '>
                                        {/* <p>Ratings 4.5/5</p> */}
                                        <button className='btn btn-buyNow'>Order Now </button>
                                        <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                            <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className='h-100 '>
                        <div className="thumbslider">
                            <Carousel interval="500" transitionTime="500">
                                <div className='sliderCart h-100'>
                                    <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                        "borderRadius": ".5rem "
                                    }} />

                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={chickenImg} alt="" className="img-fluid sliderCart h-100 w-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={burger} alt="" className="img-fluid sliderCart h-100 w-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sliderCart'>
                                    <img src={bannerImg} alt="" className="img-fluid sliderCart w-100 h-100" style={{
                                        "borderRadius": ".5rem"
                                    }} />
                                    <div className="subscriptionDiv ">
                                        <div className="subscriptionDivDetails ">
                                            <div className=' p-2 d-flex  justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                    <h3 >Platinum</h3>
                                                    <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                                </div>
                                                <div className='d-flex flex-column '>
                                                    {/* <p>Ratings 4.5/5</p> */}
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                        <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Carousel>
                        </div>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>

                    < div className="newThumb bg-dark h-100">

                        <div className='rounded thumbCart thumbCartmainDiv'>
                            <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                            <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />
                            <div className="subscriptionDiv">
                                <div className="subscriptionDivDetails">
                                    <div className=' p-2 d-flex  justify-content-between'>
                                        <div className='d-flex flex-column'>
                                            <h3 >Platinum</h3>
                                            <p style={{ marginTop: '0.5rem', }}>Ratings 4.5/5</p>

                                        </div>
                                        <div className='d-flex flex-column '>

                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <Link to="/horizontalpage" style={{ textDecoration: 'none' }}>
                                                <p style={{ marginTop: '0.5rem', textDecoration: 'none', color: 'white' }}>View more</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            className='m-2  px-3'
                            spaceBetween={30}
                            grabCursor={false}
                            centeredSlides={false}
                            slidesPerView={"auto"}
                            hashNavigation={{
                                watchState: true,
                            }}
                            // autoplay={{
                            //     delay: 2200,
                            //     disableOnInteraction: true,
                            // }}
                            breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,

                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,

                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 5,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 5,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 5,
                                },
                                1400: {
                                    slidesPerView: 3,
                                    spaceBetween: 5,
                                },

                            }}
                            navigation={true}
                            modules={[Autoplay, Navigation]}
                        >

                            <SwiperSlide>
                                <div className=' rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>

                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />

                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />

                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='  rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={chickenImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rounded thumbCart'>
                                    <h6 style={{ "position": "absolute", "top": ".5rem", "left": ".5rem", "color": "white", "font-weight": "700" }}>Burger</h6>
                                    <img src={bannerImg} alt="" className="img-fluid w-100 h-100 " style={{ "borderRadius": ".5rem" }} />


                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </SwiperSlide> */}
                {/* 
                        <SwiperSlide>
                            <div className="discountImage ">
                                <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                <div className="discountOfferDiv ">
                                    <div className="discountOfferDivDetails ">
                                        <div className='offer-Text'>
                                            <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                            <h6 style={{ marginBottom: "0px" }}>
                                                <div className='d-flex' style={{ gap: "10px" }}>
                                                    <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                </div>
                                            </h6>
                                        </div>
                                        <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                            <button className='btn btn-buyNow'>Order Now </button>
                                            <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex'>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='d-flex divThreeImg'>
                                <div className="discountImage ">

                                    <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                    <div className="discountOfferDiv ">
                                        <div className="discountOfferDivDetails ">
                                            <div className='offer-Text'>
                                                <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                <h6 style={{ marginBottom: "0px" }}>
                                                    <div className='d-flex' style={{ gap: "10px" }}>
                                                        <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                    </div>
                                                </h6>
                                            </div>
                                            <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                <button className='btn btn-buyNow'>Order Now </button>
                                                <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='div2Img'>

                                    <div className="discountImage sideDiv">

                                        <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="discountImage sideDiv mt-3">

                                        <img src={sixthImg} alt="" className="img-fluid image-Discount" />
                                        <div className="discountOfferDiv ">
                                            <div className="discountOfferDivDetails ">
                                                <div className='offer-Text'>
                                                    <h2><b>$10 off at Cheese-Stuffed Meatballs</b></h2>
                                                    <h6 style={{ marginBottom: "0px" }}>
                                                        <div className='d-flex' style={{ gap: "10px" }}>
                                                            <img src={sixthImg} alt="" style={{ width: "1rem", borderRadius: "0px" }} /> Pot kettle black
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='OrderNowBtn d-flex align-items-center justify-content-between'>
                                                    <button className='btn btn-buyNow'>Order Now </button>
                                                    <h6 style={{ paddingBottom: "0", marginBottom: "0", marginRight: "10px" }}>View More</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <TestThumb></TestThumb>
                        </SwiperSlide> */}
            </Swiper>
        </div>


    );
};

export default SubscriptionSlider;