import React, { useRef, useEffect, useState } from 'react';
import img1 from './.././../../Assets/Image/airbnb.webp';
import plusIcon from './../../../Assets/Icons/plus.png';
import clock from './../../../Assets/Icons/clock.png';
import LeoImg from './../../../Assets/Image/Leo.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/css/navigation';
import './FoodCart.css';
import edit from '../../../Assets/Icons/pencil.png';

import more from '../../../Assets/Icons/more2.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper/core';
import 'swiper/swiper-bundle.min.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';

import Rating from "react-rating";
import OrderCart from "../../OrderPage/OrderCart/OrderCart";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import loadingImg from "./../../../Assets/Image/LoadingSpin.gif";
import { Modal } from "react-bootstrap";
import FoodModal from "./FoodModal";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import FoodModalUpdate from "../../Cart/Components/FoodModalUpdate/FoodModalUpdate";
import logo from "../../../Assets/Image/LogoTransparentBrown.png";
import star from "../../../Assets/Icons/star.png";
import gift from "../../../Assets/Icons/giftbox.png";
import { sliceText } from "../../../utils/sliceText";
import warning from "../../../Assets/Icons/warning-sign.png";
import down from "../../../Assets/Icons/down.png"

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const FoodCard = (props) => {
  const swiperRef = useRef(null);
  const foodData = props.foodItemData;
  const [additionalImages, setAdditionalImages] = useState([]);
  const [isFoodItemExists, setIsFoodItemExits] = useState({});
  const [cartData, setCartData] = useState([]);
  const [orderDetailsEdit, setOrderDetailsEdit] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [selectedItemIdEdit, setSelectedItemIdEdit] = useState(false);
  const [selectedItemImageEdit, setSelectedItemImageEdit] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedImageIndexEdit, setSelectedImageIndexEdit] = useState(0);
  const [showUnavailableReasonModal, setShowUnavailableReasonModal] = useState(false);
  const [showOutOfHourModal, setShowOutOfHourModal] = useState(false)
  const [userId, setUserId] = useState('');

  useEffect(() => {
    try {
      const token = localStorage.getItem('gotasteClienttoken');
      const decoded = jwtDecode(token);
      setUserId(decoded.UserId);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const fetchAndAddImage = async () => {
    try {
      // Replace 'yourApiEndpoint' with your actual API endpoint
      const response = await fetch('yourApiEndpoint');
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const data = await response.json(
        'https://localhost:7175/api/FoodItems/GetFoodItemImagesById?skip=1&take=5&foodItemId=' +
        foodData.id
      );

      // Assuming your API response provides the image URL
      const imageUrl = data.imageUrl;

      // Add the fetched image URL to additionalImages state
      setAdditionalImages([...additionalImages, imageUrl]);
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  // const handleNextButtonClick = () => {
  //   if (swiperRef.current) {
  //     swiperRef.current.slideNext();
  //     fetchAndAddImage();
  //   }
  // };

  console.log('food itme restaurant id : ', foodData);
  // console.log('props', props);
  const dataCollections = useSelector((state) => state.fooditems);
  // const dataCollections = props.dataCollections;
  const apiAddress = process.env.REACT_APP_SECRET;
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(false);
  const [selectedItemImage, setSelectedItemImage] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setSelectedImageIndex(swiper.activeIndex);
  };

  const closeModal = () => {
    setShow(false);
  };

  const getItemDetailsForEdit = async (data) => {
    try {
      setShowEdit(true);
      setEditLoading(true);
      const response = await axios.get(
        `${apiAddress}/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=${data.id}`
      );
      console.log('food item card: ', response.data);
      setOrderDetailsEdit(response.data);
      setSelectedItemIdEdit(response.data.id);
      setSelectedItemImageEdit(response.data.foodItemImages);
      setEditLoading(false);
    } catch (error) {
      setEditLoading(false);
      console.log(error);
    }
  };

  const getItemDetails = (data, isUnavailable=false, isOuOfHour=false) => {
    setIsLoading(true);
    setShow(true);

    if (isUnavailable) {
      setShowUnavailableReasonModal(true);
    }

    if(isOuOfHour){
      setShowOutOfHourModal(true);
    }

    setOrderDetails(null);
    // setIsLoading(false);
    setSelectedItemId(null);
    setSelectedItemImage([]);
    console.log('response from food card 2:', data);
    axios
      .get(
        apiAddress +
        `/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=${data.id ? data.id : data.foodItemId
        }`
      )
      .then((response) => {
        console.log('response from food card', response.data, data.id, data);
        setOrderDetails(response.data);

        setSelectedItemId(data.id);
        data.restaurantId = response.data.restaurantId;
        setSelectedItemImage(data);
        console.log(orderDetails);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = () => setShow(false);

  /* Slider for FoodItem Image */
  const [images, setImages] = useState([]);
  const [Skip, setSkip] = useState(1);
  const [swiperInstance, setSwiperInstance] = useState(null);

  console.log('images____', images);

  useEffect(() => {
    const idToFetch = foodData.id || foodData.foodItemId;

    if (
      idToFetch &&
      images?.some(
        (img) => img.id === idToFetch || img.foodItemId === idToFetch
      )
    ) {
      return;
    }

    const getOneImage = async (id) => {
      try {
        const response = await axios.get(
          `${apiAddress}/api/FoodItems/GetFoodItemImagesByFoodItemId?Id=${id}&take=100&skip=0`
        );
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching initial image:', error);
      }
    };

    if (idToFetch) {
      getOneImage(idToFetch);
    }
  }, [foodData.id, foodData.foodItemId]);

  const getMoreImages = async () => {
    try {
      const response = await axios.get(
        apiAddress +
        `/api/FoodItems/GetFoodItemImagesByFoodItemId?Id=` +
        foodData.id +
        `&take=1&skip=` +
        Skip
      );
      const newImages = response.data;
      setImages((prevImages) => [...prevImages, ...newImages]);
      setSkip(Skip + 1);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleNextButtonClick = async () => {
  //   try {
  //     if (images.length < foodData.foodItemImages[0].totalNumbersOfImage) {
  //       await getMoreImages();
  //       swiperInstance.update();
  //     }
  //     swiperInstance.slideNext();
  //   } catch (error) {
  //     console.error('Error advancing to next slide:', error);
  //   }
  // };

  // const handlePrevButtonClick = () => {
  //   if (swiperInstance) {
  //     swiperInstance.slidePrev();
  //   }
  // };
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSwiper = (swiper) => {
    setSwiperInstance(swiper);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET}/api/Cart/GetCartDataByUserId?userId=${userId}`
          );
          setCartData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const getIsFoodItemExistsInCart = async () => {
      try {
        const foodItemId =
          props.foodItemData.foodItemId || props.foodItemData.id;

        if (userId) {
          if (props.foodItemData.restaurantId !== null) {
            const response = await axios.get(
              `${apiAddress}/api/Cart/GetFoodItemFromCartByUserIdAndRestaurantIdAndFoodItemId?userId=${userId}&restaurantId=${props.foodItemData.restaurantId}&foodItemId=${foodItemId}`
            );
            console.log('food items response: ', response.data, foodItemId);
            setIsFoodItemExits(response.data);
          } else {
            const response = await axios.get(
              `${apiAddress}/api/Cart/GetFoodItemFromCartByUserIdAndRestaurantIdAndFoodItemId?userId=${userId}&restaurantId=${props.restaurantId}&foodItemId=${foodItemId}`
            );
            console.log('food items response 3: ', response.data, foodItemId);
            setIsFoodItemExits(response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getIsFoodItemExistsInCart();
  }, [userId]);

  const foodItemDataId =
    props?.foodItemData?.id || props?.foodItemData?.foodItemId;

  const AvailibilityStatusEnum = {
    Available: 1,
    Out_Of_Hour: 2,
    Unavailable: 3,
    Unavailable_And_Out_Of_Hour: 4,
    Stock_Out: 5,
    Available_And_Out_Of_Hour: 6,
    No_Status: 7,
  };

  const foodCardColorMap = {
    "Special": {
      "bg": "special",
      "icon": star
    },
    "Shop": {
      "bg": "shop",
      "icon": gift
    }
  }

  const getAvailibilityStatusName = (status) => {
    switch (status) {
      case AvailibilityStatusEnum.Available:
        return 'Available';
      case AvailibilityStatusEnum.Out_Of_Hour:
        return 'Out Of Hour';
      case AvailibilityStatusEnum.Unavailable:
        return 'Unavailable';
      case AvailibilityStatusEnum.Unavailable_And_Out_Of_Hour:
        return 'Unavailable';
      case AvailibilityStatusEnum.Stock_Out:
        return 'Stock Out';
      case AvailibilityStatusEnum.Available_And_Out_Of_Hour:
        return 'Out of Hour';
      case AvailibilityStatusEnum.No_Status:
        return 'No Status';
      default:
        return 'Unknown Status';
    }
  };

  const getFoodStatus = (activeStatus, outOfHourStatus) => {
    if (activeStatus === 0 && outOfHourStatus === 0) {
      return "Unavailable";
    } else if (activeStatus === 0 && outOfHourStatus === 1) {
      return "Unavailable";
    } else if (activeStatus === 1 && outOfHourStatus === 1) {
      return "Out of hour";
    } else if (activeStatus === 1 && outOfHourStatus === 0) {
      return "Available";
    } else {
      return "Unknown status";
    }
  };

  return (
    <>
      {/* {dataCollections?.length !== 0 ? (
        dataCollections?.map((dt, i) => {
          return (
            <>
              <div className="box mb-2">
                <div className=" foodcart">
                  <div className="d-flex justify-content-end cartIcon">
                    <img
                      src={plusIcon}
                      alt=""
                      style={{ width: "20px", cursor: "pointer" }}
                      ype="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => {
                        getItemDetails(dt);
                      }}
                    />
                  </div>

                  <div className="box">
                    <div className=" foodcart foodCardWidth">
                      <div className="d-flex justify-content-end cartIcon">
                        <img
                          src={plusIcon}
                          alt=""
                          style={{ width: "20px" }}
                          ype="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        />
                      </div>

                      <Swiper
                        spaceBetween={2}
                        grabCursor={false}
                        centeredSlides={false}
                        slidesPerView={"auto"}
                        cssMode={true}
                        pagination={{
                          dynamicBullets: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="foodcartimg"
                      >
                        {dt.foodItemImages.length > 0 ? (
                          dt.foodItemImages.map((d, index) => {
                            return (
                              <SwiperSlide>
                                <img
                                  src={d.itemImagePath}
                                  className="img-fluid "
                                  alt="..."
                                  onClick={props.onClickInsideTest3}
                                />
                              </SwiperSlide>
                            );
                          })
                        ) : (
                          <SwiperSlide>
                            <img
                              src={img1}
                              className="img-fluid"
                              alt="..."
                              onClick={props.onClickInsideTest3}
                            />
                          </SwiperSlide>
                        )}
                      </Swiper>

                      <div className="foodCartText mt-2">
                        <div className="d-flex justify-content-between commentLove">
                          <div className="d-flex commentLove">
                            <i className="fa-regular fa-heart "></i>
                            <i className="fa-sharp fa-regular fa-comment-dots"></i>
                            <div class="dropdown">
                              <span
                                class="dropdown-toggle"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={more}
                                  alt="dot"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginLeft: "2px",
                                  }}
                                />
                              </span>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Save
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Offers
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Subscribe
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="rating-Font mb-1 d-flex align-items-center starRating"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            {" "}
                            <Rating
                              className="ratingStar"
                              initialRating={Number(4.5)}
                              emptySymbol="far fa-star icon-color"
                              fullSymbol="fas fa-star icon-color"
                              readonly
                            ></Rating>
                            4.5
                          </div>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <h3 className="m-0 p-0">{dt.itemName}</h3>
                          <h4 className="m-0 p-0">{dt.itemBasePrice}</h4>
                        </div>
                        <p className="my-1">{dt.description} </p>
                        {props.restaurantName && (
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <h6>{dt.name}</h6>
                              <div className="d-flex align-items-center starRating">
                                <i className="fa-sharp fa-solid fa-star"></i>
                                <h4 className="m-0 p-0">4.5</h4>
                              </div>
                            </div>

                            <button className="follow-BUtton btn ms-1">
                              Follow
                            </button>
                          </div>
                        )}

                        <div className="d-flex align-items-center">
                          <img src={LeoImg} className="me-1 userImg" alt="" />
                          <p>
                            Leo Love the art of travel. So many plac So many
                            foods...{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="mx-5  m-0 p-0 cartHR" />
                  </div>

                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header border-0">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body p-0">
                          {orderDetails != null ? (
                            <>
                              <button
                                type="button"
                                className="btn-close btn-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                              <Swiper
                                spaceBetween={2}
                                grabCursor={false}
                                centeredSlides={false}
                                slidesPerView={"auto"}
                                cssMode={true}
                                pagination={{
                                  dynamicBullets: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="foodcartimg"
                              >
                                {selectedItemImage.foodItemImages?.length >
                                0 ? (
                                  selectedItemImage.foodItemImages.map(
                                    (d, index) => {
                                      return (
                                        <SwiperSlide>
                                          <img
                                            src={d.itemImagePath}
                                            className="img-fluid "
                                            alt="..."
                                          />
                                        </SwiperSlide>
                                      );
                                    }
                                  )
                                ) : (
                                  <SwiperSlide>
                                    <img
                                      src={loadingImg}
                                      className="img-fluid h-10"
                                      alt="..."
                                      style={{
                                        height: "5rem !important",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </SwiperSlide>
                                )}
                              </Swiper>
                              <div className="card" style={{ border: "0px" }}>
                                <div className="card-body  ">
                                  <h3 style={{ color: "#3D1E19" }}>
                                    <strong>
                                      {orderDetails?.itemName || <Skeleton />}
                                    </strong>
                                  </h3>
                                  <p className="my-1 text-muted">
                                    {selectedItemImage?.description}{" "}
                                  </p>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={selectedItemImage?.restaurantLogo}
                                        className="me-1 userImg"
                                        alt=""
                                      />
                                      <h6>{selectedItemImage?.name}</h6>
                                    </div>

                                  </div>
                                  <hr className="mb-0" />
                                </div>
                              </div>
                              {orderDetails != null ? (
                                <OrderCart
                                  orderDetails={orderDetails}
                                  from={"modal"}
                                  itemId={selectedItemId}
                                  selectedItem={selectedItemImage}
                                />
                              ) : (
                                <img
                                  src={loadingImg}
                                  className="img-fluid h-10"
                                  alt="..."
                                  style={{
                                    height: "5rem !important",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div>
                                <img
                                  src={loadingImg}
                                  className="img-fluid h-10"
                                  alt="..."
                                  style={{
                                    height: "5rem !important",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <>
          <h1>No Food Found</h1>
        </>
      )} */}

      <>
        {selectedItemImage && (
          <FoodModalUpdate
            show={showEdit}
            handleClose={closeEditModal}
            orderDetails={orderDetailsEdit}
            selectedItemImage={selectedItemImageEdit}
            selectedItemId={selectedItemIdEdit}
            cart={cartData}
            loading={editLoading}
          />
        )}

        <div className={`box mb-2`}>
          <div className=" foodcart">
            <div className={`foodcart foodCardWidth radius ${foodCardColorMap[props?.foodItemData.sectionType] ? foodCardColorMap[props?.foodItemData.sectionType].bg : ""}`}>
              <div
                className={`section-type-tag ${foodCardColorMap[props?.foodItemData.sectionType] ? foodCardColorMap[props?.foodItemData.sectionType].bg : "d-none"}`}
              >
                <div className="section-type-img-container">
                  <img src={foodCardColorMap[props?.foodItemData.sectionType] ? foodCardColorMap[props?.foodItemData.sectionType].icon : ""} alt="section type" />
                </div>
                <div>{foodCardColorMap[props?.foodItemData.sectionType] ? props?.foodItemData.sectionType : ""}</div>
              </div>

              <div
                className={`d-flex justify-content-end ${isFoodItemExists?.foodItemId === foodItemDataId ? "foodItemCart" : "cartIcon"}`}
              // style={{background: getAvailibilityStatusName(foodData.menuStatus) === "Available" ? "white" : "gray"}}
              >
                {
                  isFoodItemExists?.foodItemId === foodItemDataId
                    ?
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="food-quantity me-1">
                        <div>{isFoodItemExists?.quantity}</div>
                      </div>
                      <div className="edit-icon ms-1" title="Edit" onClick={() => getItemDetailsForEdit(foodData)}>
                        <img src={edit} alt="edit icon" />
                      </div>
                    </div>
                    :
                    <>
                      {
                        getFoodStatus(foodData.isActive, foodData.status) !== "Unavailable" && getFoodStatus(foodData.isActive, foodData.status) !== "Out of hour" ?
                          (<img
                            src={plusIcon}
                            alt=""
                            style={{ width: "20px" }}
                            type="button"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal"
                            onClick={() => {
                              getItemDetails(foodData);
                            }}
                          />) : (
                            <div style={{ background: 'lightgray', borderRadius: '100%', width: '20px', height: '20px' }}>
                              <img
                                title={getFoodStatus(foodData.isActive, foodData.status)}
                                src={
                                  getFoodStatus(foodData.isActive, foodData.status) === "Out of hour"
                                    ?
                                    clock
                                    :
                                    getFoodStatus(foodData.isActive, foodData.status) === "Unavailable"
                                      ?
                                      warning
                                      :
                                      ""
                                }
                                alt=""
                                style={{ width: "20px", display: 'flex', alignItems: 'center' }}
                                className="clock-icon"
                                type="button"
                                onClick={() => {
                                  getItemDetails(foodData, getFoodStatus(foodData.isActive, foodData.status) === "Unavailable", getFoodStatus(foodData.isActive, foodData.status) === "Out of hour");
                                }}
                              />
                            </div>
                          )
                      }
                    </>
                }
              </div>

              {images.length > 0 ? (
                <Swiper
                  // ref={swiperRef}
                  spaceBetween={2}
                  slidesPerView={'auto'}
                  pagination={{ clickable: true, dynamicBullets: true }}
                  mousewheel={false}
                  keyboard={false}
                  grabCursor={true}
                  className='foodcartimg'
                  onSwiper={handleSwiper}
                  navigation
                // onSlideChange={handleSlideChange}
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={image.id}>
                      <div className='swiper-image-container'>
                        <img
                          src={image.itemImagePath}
                          className='img-fluid'
                          loading={
                            index === 0 || images.length === 1
                              ? 'eager'
                              : 'lazy'
                          }
                        />
                        {/* <button
                        className="swiper-button-prev"
                        onClick={handlePrevButtonClick}
                        style={{ border: 'none', background: 'transparent' }}
                      ></button>
                      <button
                        className="swiper-button-next"
                        onClick={handleNextButtonClick}
                        style={{ border: 'none', background: 'transparent' }}
                      ></button> */}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div
                  className='no-image-container'
                  title='No image found for this food item'
                >
                  <div className='no-image-wrapper'>
                    <img src={logo} alt='no image' />
                  </div>
                </div>
              )}

              {/* Render additional images
                {additionalImages.map((imageUrl) => (
                  <SwiperSlide key={imageUrl}>
                    <img
                      src={imageUrl}
                      className="img-fluid"
                      alt="..."
                    />
                  </SwiperSlide>
                ))} */}

              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                    {/* <div onClick={() => { getItemDetails(foodData) }}  className={`d-flex buy-btn-container ${foodCardColorMap[props?.foodItemData.sectionType] ? props?.foodItemData.sectionType : ""}`}>
                      <div className="buy-button-icon">
                        <img
                          src={plusIcon}
                          alt="buy icon"
                        />
                      </div>
                      <div className="buy-text">Buy</div>
                    </div> */}
                    <div
                      onClick={() => { getItemDetails(foodData) }}
                      className={`d-flex buy-btn-container 
                        ${props?.foodItemData.sectionType === "Special" ? "btn-special" : ""}
                      `}
                    >
                      {(getFoodStatus(foodData.isActive, foodData.status) !== "Unavailable" && getFoodStatus(foodData.isActive, foodData.status) !== "Out of hour") &&
                        <div className="buy-button-icon">
                          <img src={plusIcon} alt="buy icon" />
                        </div>
                      }
                      {getFoodStatus(foodData.isActive, foodData.status) === "Unavailable" &&
                        <div className="buy-button-icon">
                          <img src={warning} alt="buy icon" />
                        </div>
                      }
                      {getFoodStatus(foodData.isActive, foodData.status) === "Out of hour" &&
                        <div className="buy-button-icon">
                          <img src={clock} alt="buy icon" />
                        </div>
                      }
                      <div className="buy-text">
                        {
                          // (getFoodStatus(foodData.isActive, foodData.status) !== "Unavailable" && getFoodStatus(foodData.isActive, foodData.status) !== "Out of hour")
                          //   ?
                          //   "Buy"
                          //   :
                          //   "Unavailable"
                        }
                        {
                          getFoodStatus(foodData.isActive, foodData.status) === "Unavailable"
                            ?
                            "Unavailable"
                            :
                            getFoodStatus(foodData.isActive, foodData.status) === "Out of hour"
                              ?
                              "Out of hour"
                              :
                              "Buy"
                        }
                      </div>
                    </div>

                    {/* three dot start */}
                    {/* <div class='dropdown'>
                      <span
                        class='dropdown-toggle'
                        id='dropdownMenuButton1'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={more}
                          alt='dot'
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginLeft: '2px',
                          }}
                        />
                      </span>
                      <ul
                        class='dropdown-menu'
                        aria-labelledby='dropdownMenuButton1'
                      >
                        <li>
                          <a class='dropdown-item' href='#'>
                            Save
                          </a>
                        </li>
                        <li>
                          <a class='dropdown-item' href='#'>
                            Offers
                          </a>
                        </li>
                        <li>
                          <a class='dropdown-item' href='#'>
                            Subscribe
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {/* <div className='d-flex align-items-center starRating'>
                    <i className='fa-sharp fa-solid fa-star'></i>
                    <h4 className='m-0 p-0'>4.5</h4>
                  </div> */}
                  <div
                    className='rating-Font mb-1 d-flex align-items-center starRating'
                    data-bs-toggle='modal'
                    data-bs-target='#staticBackdrop'
                  >
                    <Rating
                      className='ratingStar'
                      initialRating={Number(4.5)}
                      emptySymbol='far fa-star icon-color'
                      fullSymbol='fas fa-star icon-color'
                      readonly
                    // start={0}
                    // stop={getFoodStatus(foodData.isActive, foodData.status) === "Available" ? 5 : 1}
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <Link to='/orderpage' state={{ foodItem: foodData }}>
                    <div
                      className='text-img large-tooltips'
                      data-title={foodData.itemName || foodData.foodItemName}
                    >
                      <h3
                        className='m-0 p-0'
                        style={{
                          color: '#3d1e19',
                          fontFamily: 'Poppins',
                          fontSize: '1rem',
                          fontWeight: 800,
                        }}
                      >
                        {
                          foodData.itemName ? sliceText(foodData?.itemName, 26) : sliceText(foodData.foodItemName, 26)
                        }
                      </h3>
                    </div>
                  </Link>

                  <h4 className='m-0 p-0'>
                    £{foodData.itemBasePrice || foodData.price}
                  </h4>
                </div>
                {/* <p className="py-1">{foodData.description}</p> */}
                <p className='py-1' style={{ minHeight: '50px' }}>
                  {foodData.description.length > 100 ? (
                    <>
                      {isExpanded
                        ? foodData.description
                        : `${foodData.description.substring(0, 70)}...`}{' '}
                      <span
                        onClick={toggleDescription}
                        style={{
                          cursor: 'pointer',
                          color: '#3d1e19',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        {isExpanded ? ' Show less' : 'See more'}{' '}
                      </span>{' '}
                    </>
                  ) : (
                    foodData.description
                  )}
                </p>
                {foodData.restaurantUrl && (
                  <Link
                    to={foodData.restaurantUrl}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='d-flex align-items-center pb-2 pt-1'>
                        <img
                          src={foodData.restaurantLogo}
                          style={{ width: '2rem' }}
                          alt={foodData.name}
                        />
                        <h6 className="ps-2">{foodData.restaurantName}</h6>
                        <div className="d-flex align-items-center starRating">
                          <i className="fa-sharp fa-solid fa-star"></i>
                          <h4 className="m-0 p-0">4.5</h4>
                        </div>
                      </div>
                       <button className="follow-BUtton btn ms-1">Follow</button>
                    </div>
                  </Link>
                )}

                <div className='d-flex align-items-center'>
                  <img src={LeoImg} className='me-1 userImg' alt='' />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{' '}
                  </p>
                </div>

                {/* {props.restaurantName && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <h6>{foodData.restaurantName}</h6>
                      <div className="d-flex align-items-center starRating">
                          <i className="fa-sharp fa-solid fa-star"></i>
                          <h4 className="m-0 p-0">4.5</h4>
                        </div>
                    </div>

                    <button className="follow-BUtton btn ms-1">Follow</button>
                  </div>
                )} */}
              </div>
            </div>
            <hr className='mx-5  m-0 p-0 cartHR' />

            {/* Modal */}
            {show && (
              <FoodModal
                foodDatarestaurant={foodData.restaurantUrl}
                show={show}
                handleClose={handleClose}
                orderDetails={orderDetails}
                selectedItemImage={selectedItemImage}
                selectedItemId={selectedItemId}
                selectedIndex={selectedImageIndex}
                showUnavailableReasonModal={showUnavailableReasonModal}
                setShowUnavailableReasonModal={setShowUnavailableReasonModal}
                showOutOfHourModal={showOutOfHourModal}
                setShowOutOfHourModal={setShowOutOfHourModal}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default FoodCard;
