import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Link, Route, useNavigate } from 'react-router-dom';
import '../../../OrderPage/OrderPage.css';
import '../../../OrderPage/OrderCart/Sauces/Sauces';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Sauces from '../../../OrderPage/OrderCart/Sauces/Sauces';
import SaucesUpdate from '../SaucesUpdate/SaucesUpdate';
import loadingImg from '../../../../Assets/Image/LoadingSpin.gif';
import './OrderCartUpdate.css';
// import loadingImg from './../../../Assets/Image/LoadingSpin.gif';

function OrderCartUpdate(props) {
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [saucestoggle, setsaucesToggle] = useState([]);
  const [saucesdis, setSaucesdis] = useState(false);
  const [options, setoptions] = useState([]);
  const [subOptions, setsubOptions] = useState([]);
  const [instruction, setInstruction] = useState('N/A');
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  // const [checkedItems, setCheckedItems] = useState({});
  const [sauceAdded, setSauceAdded] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [buttonOptionId, setButtonOptionId] = useState({});
  const [selectedSubOptions, setSelectedSubOptions] = useState({});
  const [optionQuantities, setOptionQuantities] = useState({});
  const [subOptionWarnings, setSubOptionWarnings] = useState({});
  const [updatedCartData, setUpdatedCartData] = useState([]);
  const [sacesQunatity, setsacesQunatity] = useState(0);

  const parentRef = useRef(null);

  const [buttonWidth, setButtonWidth] = useState('auto');
  useEffect(() => {
    setQuantity(props?.selectedItemInCart.foodItemQuantity);
  }, [props?.selectedItemInCart]);

  useEffect(() => {
    const updateButtonWidth = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        setButtonWidth(`${parentWidth}px`);
      }
    };

    // Update on resize
    window.addEventListener('resize', updateButtonWidth);

    // Initial update
    updateButtonWidth();

    return () => {
      window.removeEventListener('resize', updateButtonWidth);
    };
  }, [parentRef]);

  const getInitialCheckedItems = () => {
    const initialCheckedItems = {};
    props.selectedItemInCart?.addOns?.forEach((addOn) => {
      if (addOn.options && addOn.options.length > 0) {
        initialCheckedItems[addOn.addOnId] = true;
      }
    });
    return initialCheckedItems;
  };

  const [checkedItems, setCheckedItems] = useState(getInitialCheckedItems);

  useEffect(() => {
    setCheckedItems(getInitialCheckedItems());
  }, [props.selectedItemInCart]);

  useEffect(() => {
    if (props.selectedItemInCart?.addOns) {
      const initialCartData = props.selectedItemInCart.addOns.map((addOn) => ({
        addOnId: addOn.addOnId,
        addOnName: addOn.addOnName,
        addOnBasePrice: addOn.addOnBasePrice,
        addOnQuantity: addOn.addOnQuantity,
        options: addOn.options.map((option) => ({
          optionId: option.optionId,
          optionName: option.optionName,
          optionBasePrice: option.optionBasePrice,
          optionQuantity: option.optionQuantity,
          subOptions: option.subOptions.map((subOption) => ({
            subOptionId: subOption.subOptionId,
            subOptionName: subOption.subOptionName,
            subOptionBasePrice: subOption.subOptionBasePrice,
            subOptionQuantity: subOption.subOptionQuantity
              ? subOption.subOptionQuantity
              : 1,
          })),
        })),
      }));
      setUpdatedCartData(initialCartData);
    }
  }, [props.selectedItemInCart]);

  const calculateInitialTotal = () => {
    let addonTotal = 0;
    for (let i = 0; i < updatedCartData.length; i++) {
      const addOnPrice =
        updatedCartData[i].addOnBasePrice * updatedCartData[i].addOnQuantity;
      addonTotal += addOnPrice;

      for (let j = 0; j < updatedCartData[i].options.length; j++) {
        const optionPrice =
          updatedCartData[i].options[j].optionBasePrice *
          updatedCartData[i].options[j].optionQuantity;
        addonTotal += optionPrice;

        for (
          let k = 0;
          k < updatedCartData[i].options[j].subOptions.length;
          k++
        ) {
          const subOptionPrice =
            updatedCartData[i].options[j].subOptions[k].subOptionBasePrice * 1; // subOption doesn't have any quantity so 1 is default
          addonTotal += subOptionPrice;
        }
      }
    }

    return addonTotal;
  };

  const updateCartState = (
    addOnId,
    optionId = null,
    subOptionId = null,
    addOnBasePrice,
    optionBasePrice = 0,
    subOptionBasePrice = 0,
    addOnQuantity,
    optionQuantity = 0,
    subOptionQuantity = 0,
    addOnName,
    optionName = null,
    subOptionName
  ) => {
    setUpdatedCartData((prevData) => {
      let updatedData = [...prevData];

      // Find AddOn
      const addOnIndex = updatedData.findIndex((a) => a.addOnId === addOnId);
      let addOn = updatedData[addOnIndex];

      if (!addOn && addOnQuantity > 0) {
        // AddOn doesn't exist, create a new one
        addOn = {
          addOnId,
          addOnName,
          addOnBasePrice,
          addOnQuantity: addOnQuantity,
          options: [],
        };
        updatedData.push(addOn);
      }

      if (addOn) {
        if (optionId) {
          // Find or create the Option
          const optionIndex = addOn.options.findIndex(
            (o) => o.optionId === optionId
          );
          let option = addOn.options[optionIndex];

          if (!option && optionQuantity > 0) {
            // Option doesn't exist, create a new one
            option = {
              optionId,
              optionName,
              optionBasePrice,
              optionQuantity: optionQuantity, // Use optionQuantity
              subOptions: [],
            };
            addOn.options.push(option);
          }

          if (option) {
            if (subOptionId) {
              // Radio button behavior: clear all SubOptions
              option.subOptions = [];

              if (subOptionQuantity > 0) {
                // Add selected SubOption
                option.subOptions.push({
                  subOptionId,
                  subOptionName,
                  subOptionBasePrice,
                  subOptionQuantity: subOptionQuantity, // Use subOptionQuantity
                });
              }
            } else {
              // Update or remove the Option
              if (optionQuantity > 0) {
                // Update Option quantity
                option.optionQuantity = optionQuantity;
              } else {
                // Remove Option if quantity is 0
                addOn.options = addOn.options.filter(
                  (o) => o.optionId !== optionId
                );
              }
            }

            // Remove AddOn if it has no Options
            if (addOn.options.length === 0) {
              updatedData = updatedData.filter((a) => a.addOnId !== addOnId);
            }
          }
        }
      }

      return updatedData;
    });
  };

  useEffect(() => {
    if (Object.keys(optionQuantities).length === 0) return; // Skip if no quantities to process

    setUpdatedCartData((prevCartData) => {
      // Create a deep copy of the previous cart data to modify
      const newCartData = prevCartData.map((addOn) => ({
        ...addOn,
        options: addOn.options.map((option) => {
          // Check if this option's ID exists in `optionQuantities`
          if (optionQuantities.hasOwnProperty(option.optionId)) {
            return {
              ...option,
              optionQuantity: optionQuantities[option.optionId], // Update quantity
            };
          }
          return option; // Return unchanged option if no match
        }),
      }));

      return newCartData; // Update state with new cart data
    });
  }, [optionQuantities]);

  const removeAddOnById = (addOnIdToRemove) => {
    setUpdatedCartData((prevCartData) =>
      prevCartData.filter((addOn) => addOn.addOnId !== addOnIdToRemove)
    );
    setsacesQunatity(0);
  };

  const removeOptionById = (optionIdToRemove) => {
    setUpdatedCartData((prevCartData) =>
      prevCartData.map((addOn) => ({
        ...addOn,
        options: addOn.options.filter((option) => {
          if (option.optionId === optionIdToRemove) {
            return false; // Exclude the option with the given optionId
          }
          return true; // Keep the option if it doesn't match
        }),
      }))
    );
  };

  const handleAddonChange = (addOnId) => {
    const addOnQuantity = 1;
    for (const addon of props.orderDetails.addOns) {
      if (addon.id === addOnId) {
        updateCartState(
          addOnId,
          null,
          null,
          addon.basePrice,
          0,
          0,
          addOnQuantity,
          0, // optionQuantity
          0, // subOptionQuantity
          addon.addOnName,
          null
        );
      }
    }
  };

  const handleAddOnToggle = (addOnId, isChecked) => {
    if (isChecked) {
      handleAddonChange(addOnId);
    } else {
      removeAddOnById(addOnId);
    }
  };

  const handleCheckboxChange = (addOnId, optionId, isChecked) => {
    const optionQuantity = isChecked ? 1 : 0;

    for (const addon of props.orderDetails.addOns) {
      for (const option of addon.options) {
        if (option.optionId === optionId) {
          updateCartState(
            addOnId,
            optionId,
            null,
            addon.basePrice,
            option.basePrice,
            0,
            1, // addOnQuantity
            optionQuantity,
            0, // subOptionQuantity
            addon.addOnName,
            option.optionName
          );
        }
      }
    }
  };

  const handleRadioButtonChange = (addOnId, optionId, isChecked) => {
    const optionQuantity = 1; // Radio buttons always set quantity to 1
    if (isChecked) {
      for (const addon of props.orderDetails.addOns) {
        for (const option of addon.options) {
          if (option.optionId === optionId) {
            updateCartState(
              addOnId,
              optionId,
              null,
              addon.basePrice,
              option.basePrice,
              0,
              1, // addOnQuantity
              optionQuantity,
              0, // subOptionQuantity
              addon.addOnName,
              option.optionName
            );
          }
        }
      }
    } else {
      removeOptionById(optionId);
    }
  };

  const handleButtonChange = (addOnId, optionId) => {
    const optionQuantity = 1;

    for (const addon of props.orderDetails.addOns) {
      for (const option of addon.options) {
        if (option.optionId === optionId) {
          updateCartState(
            addOnId,
            optionId,
            null,
            addon.basePrice,
            option.optionBasePrice,
            0,
            1, // addOnQuantity
            optionQuantity,
            0, // subOptionQuantity
            addon.addOnName,
            option.optionName
          );
        }
      }
    }
  };

  const handleSubOptionChange = (
    addOnId,
    optionId,
    subOptionId,
    subOptionName
  ) => {
    let subOptionPrice = 0; // Default price adjustment
    const subOptionQuantity = 1; // Set sub-option quantity dynamically

    for (const addon of props.orderDetails.addOns) {
      for (const option of addon.options) {
        for (const subOption of option.subOptions) {
          if (subOption.subOptionId === subOptionId) {
            const isSelected = updatedCartData.some((addonData) =>
              addonData.options.some((optionData) =>
                optionData.subOptions.some(
                  (sub) => sub.subOptionId === subOptionId
                )
              )
            );

            // If already selected, remove the price; otherwise, add the price
            subOptionPrice = isSelected ? -subOption.price : subOption.price;

            updateCartState(
              addOnId,
              optionId,
              subOptionId,
              addon.basePrice,
              option.basePrice,
              subOption.price,
              1, // addOnQuantity
              1, // optionQuantity
              isSelected ? 0 : subOptionQuantity, // Use subOptionQuantity
              addon.addOnName,
              option.optionName,
              subOption.subOptionName
            );
          }
        }
      }
    }

    // Update total price
    setTotal((prevTotal) => prevTotal + subOptionPrice);
  };

  // useEffect(() => {
  //   setTotal(props.orderDetails.itemBasePrice);
  //   setQuantity(1);
  //   setsaucesToggle([]);
  // }, []);

  // const selectedItem = props?.selectedItemInCart;

  // const itemDetails = selectedItem?.itemDetails || [];
  // const itemAddOns =
  //   itemDetails?.length > 0 ? itemDetails[0]?.options || [] : [];

  const selectedItem = useMemo(() => props?.selectedItemInCart, [props]);

  // const itemDetails = useMemo(
  //   () => selectedItem?.itemDetails || [],
  //   [selectedItem]
  // );

  // const itemAddOns = useMemo(() => {
  //   return itemDetails?.length > 0 ? itemDetails[0]?.options || [] : [];
  // }, [itemDetails]);

  // useEffect(() => {
  //   // Reset state when orderDetails change
  //   if (selectedItem) {
  //     setQuantity(selectedItem?.foodItemQuantity);
  //     const selectedSaucesToggle = itemAddOns?.map((addOn) => addOn.addOnId);
  //     setsaucesToggle(selectedSaucesToggle);
  //     setoptions(selectedItem?.addOns[0].options);
  //     setsubOptions(
  //       selectedItem?.addOns[0].options?.map((option) => option.subOptions)
  //     );
  //   }
  //   setTotal(props.orderDetails.itemBasePrice);

  //   // setsaucesToggle([]);
  //   // setoptions([]); // Reset options state
  //   // setsubOptions([]); // Reset suboptions state
  //   // setInstruction('N/A');
  // }, [props.orderDetails, selectedItem, itemAddOns]);

  // useEffect(() => {
  //   if (selectedItem) {
  //     // Set quantity from selectedItemInCart
  //     setQuantity(selectedItem.itemDetails[0]?.quantity || 1);

  //     // Calculate total based on itemBasePrice and quantity
  //     setTotal(
  //       props.orderDetails.itemBasePrice *
  //         (selectedItem.itemDetails[0]?.quantity || 1)
  //     );

  //     // Set selected sauces toggle from selectedItemInCart
  //     const selectedSaucesToggle = itemAddOns.map((addOn) => addOn.addOnId);
  //     setsaucesToggle(selectedSaucesToggle);
  //   }
  // }, [props.orderDetails, selectedItem, itemAddOns]);

  const addQuantity = () => {
    // setIsUpdated(true);
    setQuantity((prev) => prev + 1);
    // console.log("const EHEH: ", quantity)
    // setTotal(prev => prev * quantity);
  };

  const removeQuantity = () => {
    // setIsUpdated(true);
    // if (quantity > 0) {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
    // setTotal((value) => props.orderDetails.itemBasePrice * (quantity - 1));
    // setTotal(
    //   (value) =>
    //     props.orderDetails.itemBasePrice * (quantity - 1) +
    //     (value - props.orderDetails.itemBasePrice)
    // );
    // }
  };

  useEffect(() => {
    setTotal(
      (
        props.orderDetails.itemBasePrice * quantity +
        calculateInitialTotal()
      ).toFixed(2)
    );
  }, [updatedCartData, quantity]);
  console.log('const EHEH: ', updatedCartData, optionQuantities);

  const saucestoggleHandler = (props) => {
    console.log('props', props);
    var list = [...saucestoggle];
    var index = list.findIndex((x) => x === props);

    console.log('list', list);

    if (index !== -1) {
      list.splice(index, 1);
    } else {
      list.push(props);
    }
    setsaucesToggle(list);
  };

  const saucesCallback = (id, amount, optionquantity, addonId) => {
    if (options.length > 0) {
      const prevsum = options.reduce((accumulator, object) => {
        return accumulator + object.amount * object.quantity;
      }, 0);
      var obj = options.find((x) => x.id == id && x.addonId == addonId);
      if (obj != undefined) {
        if (optionquantity == 0) {
          var index = options.findIndex(
            (x) => x.id === id && x.addonId == addonId
          );
          var latestList = subOptions?.filter(
            (x) => x.optionId != id && x.addOnId != addonId
          );
          var prevtotalPrice = subOptions.reduce(
            (sum, item) => sum + item.price,
            0
          );
          var totalPrice = latestList.reduce(
            (sum, item) => sum + item.price,
            0
          );
          setsubOptions(latestList);
          var totalPriceData = total + totalPrice - prevtotalPrice;
          setTotal(totalPriceData);

          // Check if the object is found
          if (index !== -1) {
            // Remove the object using splice
            options.splice(index, 1);
          }
        } else {
          obj.quantity = optionquantity;
        }
      } else {
        // options.push({
        //   id: id,
        //   amount: amount,
        //   quantity: optionquantity,
        //   addonId: addonId,
        // });
      }
      const sum = options.reduce((accumulator, object) => {
        return accumulator + object.amount * object.quantity;
      }, 0);
      setTotal((value) => value + sum - prevsum);
    } else {
      options.push({
        id: id,
        amount: amount,
        quantity: optionquantity,
        addonId: addonId,
      });
      setTotal((value) => value + amount);
    }
  };

  const subOptionCallBack = (data) => {
    var subOptionsList = subOptions;
    console.log(data);
    console.log(subOptionsList);
    var objList = subOptionsList?.filter(
      (x) => x.optionId === data.optionId && x.addOnId === data.addOnId
    );

    if (objList.length > 0) {
      var prevtotalPrice = subOptionsList.reduce(
        (sum, item) => sum + item.price,
        0
      );
      var latestList = subOptions?.filter(
        (x) => x.optionId != data.optionId && x.addOnId != data.addOnId
      );
      latestList.push(data);
      setsubOptions(latestList);

      var totalPrice = latestList.reduce((sum, item) => sum + item.price, 0);

      var totalPriceData = total + totalPrice - prevtotalPrice;
      setTotal(totalPriceData);
    } else {
      var prevtotalPrice = subOptionsList.reduce(
        (sum, item) => sum + item.price,
        0
      );
      subOptionsList.push(data);

      var totalPrice = subOptionsList.reduce(
        (sum, item) => sum + item.price,
        0
      );
      console.log(subOptionsList);
      setsubOptions(subOptionsList);
      var totalPriceData = total + totalPrice - prevtotalPrice;
      setTotal(totalPriceData);
    }
  };

  // useEffect(() => {
  //   // Set isUpdated to true when any relevant state changes
  //   setIsUpdated(true);
  // }, [quantity, total, options, subOptions, instruction]);
  const onSubmit = async () => {
    updateFoodQuantity();
    setIsUpdated(false);
    setLoading(true);
    var gotasteClienttokenData = localStorage.getItem('gotasteClienttoken');
    if (gotasteClienttokenData != null) {
      console.log(props.selectedItem);
      var decodedData = jwtDecode(gotasteClienttokenData);
      console.log(decodedData);
      var itemViewd = {
        id: 'string',
        cartRestaurantMapperId: 'string',
        itemId: props.itemId,
        qunatity: quantity,
        portionSize: 0,
        specialInstruction: instruction,
        unitPrice: props.orderDetails.itemBasePrice,
        creationDate: new Date().toISOString(),
      };
      var optionSubOptionList = [];
      props?.selectedItemInCart.addOns.map((addon) =>
        addon.options?.map((option) => {
          // check here
          // let optionQuantity =
          //   selectedItem?.itemDetails?.options?.find(
          //     (item) => item.optionId === option.id
          //   )?.quantity || 0;

          // var optionViews = {
          //         id: option.id,
          //         cartRestaurantItemMapperId: '',
          //         optionId: option.id,
          //         quantity: option.quantity,
          //         unitPrice: option.amount,
          //         creationDate: new Date().toISOString(), // You can set the creationDate as needed
          //       };

          var optionViews = {
            // problem here
            id: option.optionId,
            cartRestaurantItemMapperId: option.cartRestaurantItemMapperId,
            // cartRestaurantItemMapperId: option.cartOptionId,
            optionId: option.optionId,
            // quantity: optionQuantity,
            addOnId: addon.addOnId,
            quantity: quantity, // need to change here
            unitPrice: option.amount ? option.amount : option.unitPrice,
            creationDate: new Date().toISOString(), // You can set the creationDate as needed
          };
          var subOptionList = subOptions?.filter(
            (x) => x.addOnId === option.addonId && x.optionId === option.id
          );

          var subOptionListViews = []; // and here

          option.subOptions
            ? option.subOptions?.map((dt) => {
                var obj = {
                  id: dt.subOptionId,
                  cartRestaurantItemOptionmapperId:
                    dt.cartRestaurantItemOptionmapperId,
                  // cartRestaurantItemOptionmapperId: dt.cartSubOptionId,
                  subOptionId: dt.subOptionId,
                  quantity: dt.quantity,
                  unitPrice: dt.unitPrice,
                  creationDate: new Date().toISOString(),
                };
                subOptionListViews.push(obj);
              })
            : subOptionList?.map((dt) => {
                var obj = {
                  id: 'string',
                  cartRestaurantItemOptionmapperId: 'string',
                  subOptionId: dt.subOptionId,
                  quantity: 1,
                  unitPrice: dt.price,
                  creationDate: new Date().toISOString(),
                };
                subOptionListViews.push(obj);
              });

          var optionSubOption = {
            optionView: optionViews,
            subOptionListView: subOptionListViews,
          };
          optionSubOptionList.push(optionSubOption);
        })
      );

      var itemOptionSuboptionList = [];
      var obj = {
        itemView: itemViewd,
        OptionSubOptionList: optionSubOptionList, // here is the problem
      };
      itemOptionSuboptionList.push(obj);

      var cartInsertView = {
        Cart: {
          id: 'string',
          userId: decodedData.UserId,
          isActive: true,
          creationDate: new Date().toISOString(),
        },
        cartRestaurantMapperList: [
          {
            cartRestaurantView: {
              id: 'string',
              cartId: 'string',
              restaurantId: selectedItem?.restaurantId,
              creationDate: new Date().toISOString(),
            },
            itemOptionSuboptionList: itemOptionSuboptionList,
          },
        ],
      };

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SECRET}/api/Cart/UpdateCartItems?cartFoodItemId=${props.selectedItemInCart.cartItemId}&subOrderId=${props.selectedItemInCart.subOrderId}`,
          updatedCartData
        );

        if (response.data) {
          toast.success('Successfully updated the cart');
          setLoading(false);
          setTimeout(() => {
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
        setLoading(false);
      }

      // await axios
      //   .put(
      //     process.env.REACT_APP_SECRET +
      //     `/api/Cart/UpdateCartForSpecificItem?CartId=${props?.selectedItemInCart.cartItemId}&ItemId=${props?.itemId}`,
      //     cartInsertView
      //   )
      //   .then(async (res) => {
      //     toast.success("Successfully updated the cart");
      //     setLoading(false);
      //     setTimeout(() => {
      //       props.handleClose();
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     toast.error("Something went wrong");
      //     setLoading(false);
      //   });
    } else {
      toast.warning('Please login !!');
      setLoading(false);
    }
  };

  // Extract the add-ons from the orderDetails object
  const orderDetails = props.orderDetails;
  const orderAddOns = orderDetails.addOns || [];

  // Filter add-ons where addOnId === addon.id and quantity > 0
  // const filteredAddOns = itemAddOns.filter((itemAddOn) =>
  //   orderAddOns.some(
  //     (orderAddOn) =>
  //       itemAddOn.addOnId === orderAddOn.id && itemAddOn.quantity > 0
  //   )
  // );

  // console.log('filteredAddOns', filteredAddOns);

  const updateFoodQuantity = useCallback(async () => {
    if (props?.selectedItemInCart.cartItemId && quantity) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_SECRET}/api/Cart/UpdateFoodItemQuantity/${props?.selectedItemInCart.cartItemId}?quantity=${quantity}`
        );

        if (response.data) {
          console.log(
            'data for item details: ',
            response.data,
            props?.selectedItemInCart.cartItemId
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [props?.selectedItemInCart.cartItemId, quantity]);

  return (
    <>
      <ToastContainer />
      <div
        className={`card ${
          props.from === 'modal'
            ? 'borderLinezero'
            : 'OrderCartDetails Orderfoodcard'
        }  col-12  NewborderLinezero`}
      >
        <div className='card-body  pt-0 pb-0'>
          <div>
            {props.from === 'modal' ? (
              ''
            ) : (
              <h4 className='textColor card-title'>
                Build your order ({props.orderDetails.itemName})
              </h4>
            )}
            <div className='row toggleSection'>
              <div className='col-12 p-0' id='accordionExample'>
                {props.orderDetails.addOns !== undefined &&
                  props.orderDetails.addOns.map((dt, i) => (
                    <div key={dt.id + '_' + i}>
                      {/* {dt.options.length > 0 && ( */}
                      <div className='form-check form-switch d-flex justify-content-between toggleTittle '>
                        <label
                          className='form-check-label ordercartTitle'
                          htmlFor={dt.id}
                        >
                          {dt.addOnName || <Skeleton />}
                        </label>
                        {/* {dt.options.length > 0 && ( */}
                        <input
                          className='form-check-input toggleSwitch '
                          type='checkbox'
                          id={dt.id}
                          // disabled={
                          //   options.find((x) => x.addonId == dt.id) ==
                          //     undefined
                          //     ? false
                          //     : true
                          // }
                          // disabled={saucesdis}
                          // onClick={() => {
                          //   saucestoggleHandler(dt.id);
                          // }}
                          checked={updatedCartData.some(
                            (addon) => addon.addOnId === dt.id
                          )}
                          onChange={(e) =>
                            handleAddOnToggle(dt.id, e.target.checked)
                          }
                          // checked={checkedItems[dt.id] || false} // Get checked state from the object
                          // onClick={() => {
                          //   setCheckedItems((prevState) => ({
                          //     ...prevState,
                          //     [dt.id]: !prevState[dt.id], // Toggle the checked state for this specific checkbox
                          //   }));
                          // }}
                        />
                        {/* )} */}
                      </div>
                      {/* )} */}
                      {/* <div className={`${checkedItems[dt.id] ? "mt-2 wholeTable" : "d-none"}`}> */}
                      <div
                        className={`${
                          updatedCartData.some(
                            (addon) => addon.addOnId === dt.id
                          )
                            ? 'mt-2 wholeTable'
                            : 'd-none'
                        }`}
                      >
                        <table className='table table-borderless orderCart'>
                          {dt.options.length > 0 && (
                            <thead>
                              <tr>
                                <th className='thTitle'>Select any</th>
                              </tr>
                            </thead>
                          )}
                          <tbody
                            className={`${
                              dt.optionButtonId === 5 ? 'table-buttons' : ''
                            }`}
                          >
                            {dt.options.map((sauce, index) => (
                              <tr key={index}>
                                <SaucesUpdate
                                  options={options}
                                  setoptions={setoptions}
                                  setsubOptions={setsubOptions}
                                  name={sauce.optionName}
                                  price={sauce.basePrice}
                                  itemAddOns={props?.selectedItemInCart.addOns}
                                  amount={sauce.basePrice}
                                  id={sauce.optionId}
                                  addonId={sauce.addOnId}
                                  isCountable={dt.isCountable}
                                  optionButtonId={dt.optionButtonId}
                                  addOnName={dt.addOnName}
                                  setSauceAdded={setSauceAdded}
                                  saucesCallback={(
                                    id,
                                    amount,
                                    quantity,
                                    addonId
                                  ) => {
                                    saucesCallback(
                                      id,
                                      amount,
                                      quantity,
                                      addonId
                                    );
                                  }}
                                  subOptionCallBack={(data) => {
                                    subOptionCallBack(data);
                                  }}
                                  subOptions={sauce.subOptions}
                                  handleCheckboxChange={handleCheckboxChange}
                                  handleRadioButtonChange={
                                    handleRadioButtonChange
                                  }
                                  selectedOptions={selectedOptions}
                                  handleButtonChange={handleButtonChange}
                                  handleSubOptionChange={handleSubOptionChange}
                                  setOptionQuantities={setOptionQuantities}
                                  subOptionWarnings={
                                    subOptionWarnings[sauce.optionId]
                                  }
                                  checkboxOptions={checkboxOptions}
                                  setIsUpdated={setIsUpdated}
                                  isChecked={updatedCartData.some((addon) =>
                                    addon.options.some(
                                      (option) =>
                                        option.optionId === sauce.optionId
                                    )
                                  )}
                                  optionQuantity={updatedCartData
                                    ?.find((addon) =>
                                      addon.options.some(
                                        (option) =>
                                          option.optionId === sauce.optionId
                                      )
                                    )
                                    ?.options.find(
                                      (option) =>
                                        option.optionId === sauce.optionId
                                    )}
                                  updatedCartData={updatedCartData}
                                  setSelectedSubOptions={setSelectedSubOptions}
                                  selectedSubOptions={selectedSubOptions}
                                  setsacesQunatity={setsacesQunatity}
                                  sacesQunatity={sacesQunatity}
                                />
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Quantity */}
            <div className='pt-3 '>
              <strong className='ordercartTitle'>Quantity</strong>
              <div className='card border-0 '>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12  d-flex justify-content-center'>
                      <button
                        className=' quantityBtn rounded-2 '
                        onClick={() => {
                          setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
                        }}
                      >
                        -
                      </button>

                      <strong className='pt-3 ml-3'>{quantity}</strong>
                      <button
                        className=' quantityBtn  rounded-2 '
                        onClick={() => {
                          setQuantity((prev) => prev + 1);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* quantity end */}

            <div className='pt-3'>
              <div className='col-12 mb-3' ref={parentRef}>
                <h5 className=' titleColor'>Special instruction</h5>
                <div className='pb-3'>
                  <input
                    type='text'
                    className='form-control inputField mb-5'
                    onChange={(e) => {
                      setInstruction(e.target.value);
                    }}
                  />
                </div>
                <div
                  className=' d-flex justify-content-evenly mt-5 update-fixed-btn'
                  style={{
                    width: buttonWidth,
                    position: 'fixed',
                    bottom: '7%',
                  }}
                >
                  {loading ? (
                    <button
                      className=' btn btn-checkout'
                      type='button'
                      style={{ pointerEvents: 'none', cursor: 'no-drop' }}
                    >
                      <img
                        src={loadingImg}
                        className='img-fluid'
                        style={{ height: '1rem' }}
                        alt='loading'
                      />
                    </button>
                  ) : (
                    <button
                      className=' btn btn-checkout'
                      type='button'
                      onClick={onSubmit}
                    >
                      Update &#163;{total}
                    </button>
                  )}

                  {/* <Link to="/Offers">
                  <button className=" AddTocart rounded-2" type="button ">
                    Offers
                  </button>
                </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`card ${props.from === "modal"
            ? "borderLinezero"
            : "OrderCartDetails Orderfoodcard"
          }  col-12 `}
      >
        <div className="card-body  pt-0 pb-0">
          <div className="">
            {props.from === "modal" ? (
              ""
            ) : (
              <h4 className="textColor card-title">
                Build your order ({props.orderDetails.itemName})
              </h4>
            )}

            <div className="row toggleSection">
              <div className=" col-12 p-0 " id="accordionExample">
                {orderAddOns?.map((dt, i) => {
                  return (
                    <div key={dt.id}>
                      {
                        <div>
                          <div className="form-check form-switch d-flex justify-content-between toggleTittle ">
                            <p
                              className="form-check-label ordercartTitle "
                              for={dt.id}
                            >
                              {dt.addOnName || <Skeleton />}
                            </p>
                            <input
                              className="form-check-input toggleSwitch"
                              type="checkbox"
                              id={dt.id}
                              disabled={saucesdis}
                              checked={saucestoggle.includes(dt.id)}
                              onChange={() => saucestoggleHandler(dt.id)}
                            />
                          </div>
                          <div
                            className={`${saucestoggle.includes(dt.id)
                                ? " mt-2 wholeTable "
                                : "d-none"
                              }`}
                          >
                            <table className="table table-borderless orderCart">
                              <thead>
                                <tr>
                                  <th className="thTitle">Select any</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dt.options?.map((sauce, index) => (
                                  <tr key={index}>
                                    <SaucesUpdate
                                      name={sauce.optionName}
                                      price={sauce.basePrice}
                                      amount={sauce.basePrice}
                                      id={sauce.optionId}
                                      addonId={sauce.addOnId}
                                      itemAddOns={props?.selectedItemInCart.addOns}
                                      setIsUpdated={setIsUpdated}
                                      saucesCallback={(
                                        id,
                                        amount,
                                        quantity,
                                        addonId
                                      ) => {
                                        saucesCallback(
                                          id,
                                          amount,
                                          quantity,
                                          addonId
                                        );
                                      }}
                                      subOptionCallBack={(data) => {
                                        subOptionCallBack(data);
                                      }}
                                      subOptions={sauce.subOptions}
                                    />
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="pt-3 ">
              <strong className="ordercartTitle">Quantity</strong>
              <div className="card border-0 ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12  d-flex justify-content-center">
                      <button
                        className=" quantityBtn rounded-2 "
                        onClick={() => {
                          removeQuantity();
                        }}
                      >
                        -
                      </button>

                      <strong className="pt-3 ml-3">{quantity}</strong>
                      <button
                        className=" quantityBtn  rounded-2 "
                        onClick={() => {
                          addQuantity();
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <div className="col-12 mb-3">
                <h5 className=" titleColor">Special instruction</h5>
                <div className="">
                  <input
                    type="text"
                    className="form-control inputField"
                    onChange={(e) => {
                      setInstruction(e.target.value);
                    }}
                    defaultValue={instruction}
                  />
                </div>
                <div className=" d-flex justify-content-evenly mt-2">
                  <button
                    className="btn btn-checkout"
                    type="button"
                    onClick={onSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default OrderCartUpdate;
