import React, { useState } from 'react'

import FrstImg from './../../../../Assets/Image/PastaOffer.jpg'
import twoImg from './../../../../Assets/Image/1 (2).jpg'

import airImg from './../../../../Assets/Image/aibnb2.webp'
import airImg2 from './../../../../Assets/Image/airbnb.webp'
import cookingImage from './../../../../Assets/Image/image 35.png'
import p1 from './../../../../Assets/Image/product (1).jpg'
import './../../../GalleryView/GalleryView.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import GalleryModal from '../../../GalleryModal/GalleryModal'
import removeIcon from './../../../../Assets/Icons/removeBlack.png'
import removeBlack from './../../../../Assets/Icons/remove.png'

import CommentComponent from '../../../PostDisplay/CommentComponent/CommentComponent'
import './PhotoGridGalleryView.css'

import Modal from 'react-bootstrap/Modal';
const PhotoGridgalleryView = (props) => {
  const [images, setImages] = useState([
    {
      src: FrstImg,
      width: 500,
    },
    {
      src: twoImg,
      width: 500,
    },
    {
      src: p1,
      width: 500,
    },
    {
      src: p1,
      width: 500,
    },
    {
      src: twoImg,
      width: 500,
    },
    {
      src: FrstImg,
      width: 500,
    },
    {
      src: cookingImage,
      width: 500,
    },
    {
      src: airImg2,
      width: 500,
    },
    {
      src: airImg,
      width: 500,
    },
    {
      src: cookingImage,
      width: 500,
    },
    {
      src: airImg2,
      width: 500,
    },
    {
      src: airImg,
      width: 500,
    },
    {
      src: FrstImg,
      width: 500,
    },
    {
      src: twoImg,
      width: 500,
    },
    {
      src: p1,
      width: 500,
    },
  ])
  // Modal view functionality start
  const [selectedImage, setSelectedImage] = useState('')
  const [showGalleryView, setShowGalleryView] = useState(false);
  const hideGalleryModalInfo = () => {
    window.$('#galleryViewModal').modal('hide')
    setShowGalleryView(false)
  }
  const showGalleryModalInfo = (props) => {
    console.log('PostDisplay: ' + props)
    setSelectedImage(props)
    window.$('#galleryViewModal').modal('show')
    setShowGalleryView(true);
  }
 
  return (
    <div style={{ overflow: 'none' }}>

      <Modal
        show={showGalleryView}
        onHide={hideGalleryModalInfo}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        className='postDisplayModal'
      >
        <Modal.Body className='galleryViewModal'>
          <div class=" gallaryViewModelHeader d-flex justify-content-between loginModalCloseBtn">
            <span></span>
            <span></span>
            <button type="button " class={'close '} onClick={hideGalleryModalInfo}>
              <span aria-hidden="true">
                <img
                  src={removeIcon}
                  className="removeImg desktopDisplay"
                  style={{ height: '30px", width: "1rem' }}
                  alt=""
                />
              </span>
            </button>
          </div>
          <button
            type="button "
            class={'close gallaryModelCloseButton'}
            onClick={hideGalleryModalInfo}
            style={{
              position: 'absolute',
              right: '0.8rem',
              top: '1rem',
              width: '44px',
              background: 'transparent',
              border: 'none',
            }}
          >
            <span aria-hidden="true">
              <img
                src={removeBlack}
                className="removeImg mobileDisplay "
                style={{ height: '30px", width: "1rem' }}
                alt=""
              />
            </span>
          </button>
          <div className='w-100 CommentComponentDiv fixed-bottom'>
            <CommentComponent />
          </div>
          <Swiper
            className="gallerySwiper swiperGallery"
            spaceBetween={30}
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={1}
            hashNavigation={{
              watchState: true,
            }}
            // cssMode={true}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
          >
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>
            <SwiperSlide>
              <GalleryModal selectedImage={selectedImage} />
            </SwiperSlide>

          </Swiper>
        </Modal.Body>

      </Modal>
      {/* previous gallery view */}

      <div className="mobileDisplay ">
        <div className="">
          <div className="row galleryRow">
            {images.map((image, i) => (
              <div className={props.type=='under'? 'col-6':'col-4'}>
                <img
                  key={i}
                  src={image.src}
                  alt=""
                  // onClick={() => selectedImg(image.src)}
                  onClick={() => showGalleryModalInfo(image.src)}
                  className="imgsize"
                />
              </div>
            ))}
            <div className="d-flex justify-content-center ">
              <a className="moreBtn" href="/gallerygrid">
                See more
              </a>
            </div>
          </div>
        </div>

        {/* <PhotoGridGallery from={1} /> */}
        <div className="d-flex justify-content-center ">
          <a className="seeMoreBtn" href="/gallerygrid">
            See more
          </a>
        </div>
      </div>
      
        <div className='desktopDisplay '>
        <div className="">
          <div className="row galleryRow">
            {images.map((image, i) => (
              <div className='col-4'>
                <img
                  key={i}
                  src={image.src}
                  alt=""
                  // onClick={() => selectedImg(image.src)}
                  onClick={() => showGalleryModalInfo(image.src)}
                  className="photogridGalleryViewImgSize"
                />
              </div>
            ))}
            <div className="d-flex justify-content-center ">
              <a className="moreBtn" href="/gallerygrid">
                See more
              </a>
            </div>
          </div>
        </div>

        {/* <PhotoGridGallery from={1} /> */}
        {/* <div className="d-flex justify-content-center ">
          <a className="seeMoreBtn" href="/gallerygrid">
            See more
          </a>
        </div> */}
        </div>

     
    </div>
  )
}

export default PhotoGridgalleryView