import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./ConfirmationPage.css";
import SuccessIcon from "./../../Assets/Icons/success.png";
import { useNavigate } from "react-router-dom";
// import { BsCheckCircle } from 'react-icons/bs';

const ConfirmationPage = () => {
  const [blur, setBlur] = useState(false);
  const navigate = useNavigate();

  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  return (
    <div>
      <div>
        <Navbar handleNavbar={searchDialogOpen} />
      </div>
      <Container className="mt-lg-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Card className="text-center p-4 cardContainer">
              <img className="w-25 mx-auto" src={SuccessIcon} alt="" />
              <Card.Title className="mt-3 text-success">
                Order Successful
              </Card.Title>
              <Card.Text>Thank you so much for your order.</Card.Text>
              <Button
                variant="success"
                className="w-50 mx-auto"
                onClick={() => {
                  navigate("/checkout?for=pending");
                }}
              >
                Check Status
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmationPage;
