import React, { useEffect, useRef, useState } from 'react';
import '../Components/EditShareYourThoughtsModal.css';

export default function ImageCropControls({
  typeOfCrop,
  setTypeOfCrop,
  zoomForOneByOne,
  setZoomForOneByOne,
  zoomForFourByFive,
  setZoomForFourByFive,
  croppedImageForOnyByOne,
  croppedImageForFourByFive,
}) {
  const [modalVisible, setModalVisible] = useState(false);

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalVisible &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.classList.contains('clicked')
      ) {
        setModalVisible(false);
      }
    }

    if (modalVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalVisible]);

  const handleCropSelection = (ratio) => {
    setTypeOfCrop(ratio);
  };

  const openModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <div className='all-controls'>
      <div className='controls'>
        {typeOfCrop === '1:1' && (
          <input
            type='range'
            value={zoomForOneByOne}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby='Zoom'
            onChange={(e) => {
              setZoomForOneByOne(e.target.value);
            }}
            className='zoom-range'
          />
        )}
        {typeOfCrop === '4:5' && (
          <input
            type='range'
            value={zoomForFourByFive}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby='Zoom'
            onChange={(e) => {
              setZoomForFourByFive(e.target.value);
            }}
            className='zoom-range'
          />
        )}
      </div>
      {modalVisible && (
        <div ref={modalRef} className='crop-modal'>
          <div
            onClick={() => handleCropSelection('original')}
            className={typeOfCrop === 'original' ? 'selected' : ''}
          >
            <span>Original</span>
            <span>
              <svg
                aria-label='Photo outline icon'
                class='x1lliihq x1n2onr6 x1roi4f4'
                fill='currentColor'
                height='24'
                role='img'
                viewBox='0 0 24 24'
                width='24'
              >
                <path
                  d='M6.549 5.013A1.557 1.557 0 1 0 8.106 6.57a1.557 1.557 0 0 0-1.557-1.557Z'
                  fillRule='evenodd'
                ></path>
                <path
                  d='m2 18.605 3.901-3.9a.908.908 0 0 1 1.284 0l2.807 2.806a.908.908 0 0 0 1.283 0l5.534-5.534a.908.908 0 0 1 1.283 0l3.905 3.905'
                  fill='none'
                  stroke='currentColor'
                  stroke-linejoin='round'
                  stroke-width='2'
                ></path>
                <path
                  d='M18.44 2.004A3.56 3.56 0 0 1 22 5.564h0v12.873a3.56 3.56 0 0 1-3.56 3.56H5.568a3.56 3.56 0 0 1-3.56-3.56V5.563a3.56 3.56 0 0 1 3.56-3.56Z'
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                ></path>
              </svg>
            </span>
          </div>
          <hr />
          <div
            onClick={() => handleCropSelection('1:1')}
            className={typeOfCrop === '1:1' ? 'selected' : ''}
          >
            <span
              style={{
                color: `${croppedImageForOnyByOne ? '#3572EF' : 'white'}`,
              }}
            >
              1:1
            </span>
            <span>
              <svg
                aria-label='Crop square icon'
                class='x1lliihq x1n2onr6 x1roi4f4'
                fill={`${croppedImageForOnyByOne ? '#3572EF' : 'currentColor'}`}
                height='24'
                role='img'
                viewBox='0 0 24 24'
                width='24'
              >
                <path d='M19 23H5a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h14a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM5 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h14a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z'></path>
              </svg>
            </span>
          </div>
          <hr />
          <div
            onClick={() => handleCropSelection('4:5')}
            className={typeOfCrop === '4:5' ? 'selected' : ''}
          >
            <span
              style={{
                color: `${croppedImageForFourByFive ? '#3572EF' : 'white'}`,
              }}
            >
              4:5
            </span>
            <span>
              <svg
                aria-label='Crop portrait icon'
                class='x1lliihq x1n2onr6 x9bdzbf'
                fill={`${
                  croppedImageForFourByFive ? '#3572EF' : 'currentColor'
                }`}
                height='24'
                role='img'
                viewBox='0 0 24 24'
                width='24'
              >
                <path d='M16 23H8a4.004 4.004 0 0 1-4-4V5a4.004 4.004 0 0 1 4-4h8a4.004 4.004 0 0 1 4 4v14a4.004 4.004 0 0 1-4 4ZM8 3a2.002 2.002 0 0 0-2 2v14a2.002 2.002 0 0 0 2 2h8a2.002 2.002 0 0 0 2-2V5a2.002 2.002 0 0 0-2-2Z'></path>
              </svg>
            </span>
          </div>
        </div>
      )}
      <div className='crop-button clicked' onClick={openModal}>
        <svg
          aria-label='Select crop'
          class='x1lliihq x1n2onr6 x9bdzbf'
          fill='currentColor'
          height='20'
          role='img'
          viewBox='0 0 24 24'
          width='22'
          className='clicked'
        >
          <path d='M10 20H4v-6a1 1 0 0 0-2 0v7a1 1 0 0 0 1 1h7a1 1 0 0 0 0-2ZM20.999 2H14a1 1 0 0 0 0 2h5.999v6a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1Z'></path>
        </svg>
      </div>
    </div>
  );
}
