import React, { useEffect, useState } from 'react';
import '../../../OrderPage/OrderCart/Sauces/Sauces.css';

function SaucesUpdate(props) {
  const [amount, setAmount] = useState(0);
  const [selectedItem, setSelectedItem] = useState({ name: '', amount: 0 });
  const [id, setId] = useState(props.id);
  const [optionsIds, setOptionsIds] = useState([]);
  //sauces quantity start

  // const [sacesQunatity, setsacesQunatity] = useState(0);
  const { setOptionQuantities, setsacesQunatity, sacesQunatity } = props;

  const itemAddOns = props.itemAddOns;

  // console.log('props in sauces', props.updatedCartData[0].addOns[0].options[0].subOptions);

  useEffect(() => {
    if (props.optionQuantity && props.optionQuantity.optionQuantity !== undefined) {
      setsacesQunatity(props.optionQuantity.optionQuantity); // Sync state with prop
    }
  }, [props.optionQuantity]);

  const itemQuantity = itemAddOns.find(
    (item) => item.addOnId === props.addonId && props.name === item.optionName
  )?.quantity;

  console.log('itemQuantity', sacesQunatity);

  // useEffect(() => {
  //   if (itemQuantity > 0) {
  //     setsacesQunatity(itemQuantity);
  //   }
  // }, [itemQuantity]);


  const changeQuantity = (types, optionId) => {
    console.log("types: ", types)
    setsacesQunatity((prevQuantity) => {
      let updatedQuantity = prevQuantity; // Start with the current quantity

      if (types === "plus") {
        updatedQuantity += 1; // Increment
        setOptionsIds((prev) => [...prev, optionId]); // Add the optionId
      } else if (types === "minus") {
        if (prevQuantity > 1) {
          updatedQuantity -= 1; // Decrement if greater than 1
        } else {
          updatedQuantity = 0; // Set to 0 if decremented below 1
          setOptionsIds((prevOptionsIds) =>
            prevOptionsIds.filter((x) => x !== optionId) // Remove optionId
          );
        }
      }

      // Update option quantities
      setOptionQuantities((prevQuantities) => ({
        ...prevQuantities,
        [optionId]: updatedQuantity, // Update quantity for the option
      }));

      // Call the callback with the updated quantity
      props.saucesCallback(id, props.price, updatedQuantity, props.addonId);

      return updatedQuantity; // Return the new state for `sacesQunatity`
    });
  };

  //sauces quantity end
  useEffect(() => {
    setAmount(sacesQunatity * props.price);
    setSelectedItem({ name: props.name, amount: amount });
  }, [sacesQunatity, props.price, props.name, amount]);
  // useEffect(() => {
  //   setSelectedItem({ name: props.name, amount: amount });
  //   props.saucesCallback(id, amount, sacesQunatity);
  // }, [amount]);
  const [subOptionCheckedStatus, setSubOptionCheckedStatus] = useState([]);
  const [optionEnable, setOptionEnable] = useState(props.isChecked);

  useEffect(() => {
    if (optionEnable) {
      setSubOptionCheckedStatus(
        props.subOptions.map(subOption => ({
          id: subOption.subOptionId,
          checked: props.updatedCartData.some(addon =>
            addon.options.some(option =>
              option.subOptions.some(sub => sub.subOptionId === subOption.subOptionId)
            )
          )
        }))
      );
    }
  }, [props.subOptions, props.updatedCartData, optionEnable]);

  const handleRadioClick = (subOption) => {
    setSubOptionCheckedStatus(prevStates =>
      prevStates.map(state =>
        state.id === subOption.subOptionId
          ? { ...state, checked: !state.checked } // Toggle the clicked option
          : { ...state, checked: false } // Uncheck all others
      )
    );

    // Call handleSubOptionChange to update state and recalculate the total price
    const isCurrentlyChecked = subOptionCheckedStatus.find(state => state.id === subOption.subOptionId)?.checked;
    const newCheckedState = !isCurrentlyChecked;

    // Assuming you have addOnId and optionId available in the scope
    const { addOnId, optionId } = subOption; // Adjust this line based on how you pass data

    props.handleSubOptionChange(
      addOnId,
      optionId,
      subOption.subOptionId,
      subOption.subOptionName,
      newCheckedState
    );
  };

  useEffect(() => {
    setOptionEnable(props.isChecked);
  }, [props.isChecked]);

  const optionOnclick = () => {
    if (!optionEnable) {
      setOptionEnable(true); // Enable the option
      props.handleRadioButtonChange(props.addonId, props.id, true); // Notify parent
      changeQuantity("plus", props.id); // Increase quantity
    } else {
      setOptionEnable(false); // Disable the option
      props.handleRadioButtonChange(props.addonId, props.id, false);
      // Deselect all subOptions when the option is deselected
      setSubOptionCheckedStatus(prevStates =>
        prevStates.map(state => ({ ...state, checked: false }))
      );
      changeQuantity("minus", props.id); // Decrease quantity
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          className={`d-flex flex-column ${props.optionButtonId === 5 || props.optionButtonId === 6
            ? "w-100"
            : ""
            }`}
        >
          <h6
            className={`${props.optionButtonId === 5 || props.optionButtonId === 6
              ? "w-100"
              : ""
              }`}
          >
            {props.optionButtonId === 3 ? (
              <input
                type="radio"
                className="me-1"
                name={props.addOnName}
                checked={optionEnable}
                onClick={optionOnclick}
              />
            ) : props.optionButtonId === 4 ? (
              <input
                type="checkbox"
                className="me-1"
                name="options"
                checked={props.isChecked}
                style={{ accentColor: 'gray' }}
                onChange={(e) => {
                  const isCurrentlyChecked = e.target.checked;
                  const actionType = isCurrentlyChecked ? "plus" : "minus";

                  props.handleCheckboxChange(
                    props.addonId,
                    props.id,
                    isCurrentlyChecked
                  );
                  changeQuantity(
                    actionType,
                    props.id
                  );
                  // changeQuantity(
                  //   optionsIds.find((x) => x == props.id) ? "minus" : "plus",
                  //   props.id
                  // );
                }}
              />
            ) : (
              ""
            )}

            {props.optionButtonId === 5 ? (
              <button
                className="option-btn"
                onClick={() =>
                  props.handleButtonChange(props.addonId, props.id)
                }
              >
                {props.name}
              </button>
            ) : props.optionButtonId === 6 ? (
              <textarea cols={12} rows={4} className="w-100 option-details">
                {props.name}
              </textarea>
            ) : (
              <>
                <span>{props.name}</span>
                {props.subOptionWarnings && (
                  <div className="warning-text text-warning">
                    {props.subOptionWarnings}
                  </div>
                )}
              </>
            )}
          </h6>
          <div
            className={`${props.optionButtonId === 5 || props.optionButtonId === 6
              ? "d-none"
              : "d-flex"
              }`}
          >
            {amount == 0 ? <p>£ {props.price}</p> : <p>£ {amount}</p>}
          </div>
        </div>

        <div
          className={`${!props.isCountable ||
            props.optionButtonId === 5 ||
            props.optionButtonId === 6
            ? "d-none"
            : "d-flex"
            }`}
          style={{ gap: "10px" }}
        >
          {sacesQunatity > 0 && (
            <>
              <div
                onClick={(e) => {
                  changeQuantity("minus", props.id);
                }}
                className=" plusCircle"
              >
                <p>-</p>
              </div>

              {sacesQunatity}
            </>
          )}
          <div
            onClick={(e) => {
              changeQuantity("plus", props.id);
            }}
            className=" plusCircle"
          >
            <p>+</p>
          </div>
        </div>
      </div>
      {sacesQunatity > 0 && (
        <div style={{ marginLeft: "1.5rem" }}>
          {props.subOptions.map((subOption, i) => <div key={i} className="form-check p-0 ms-4 checkBoxOrderPage d-flex justify-content-between align-items-start">
            <div>
              <input
                // className="form-check-input "
                type="radio"
                name={subOption.optionId + props.addonId}
                id={
                  subOption.subOptionId + subOption.optionId + props.addonId
                }
                value={subOption.price}
                checked={subOptionCheckedStatus.find(state => state.id === subOption.subOptionId)
                  ?.checked || false}
                onClick={() => handleRadioClick(subOption)}
              // disabled = {subOptionEnable}
              // checked={subOptionEnable}
              // onChange={() => {
              //   props.setsubOptions((prevState) => [
              //     ...prevState,
              //     subOption,
              //   ]);
              //   props.subOptionCallBack(subOption);
              //   props.handleSubOptionChange(
              //     props.addonId,
              //     props.id,
              //     subOption.subOptionId
              //   );
              // }}
              />

              <label
                className="form-check-label ms-2"
                htmlFor={subOption.optionId + props.addonId}
              >
                {subOption.subOptionName}
              </label>
            </div>

            <div className="d-flex justify-content-between">
              <p>£ {subOption.price}</p>
            </div>
          </div>
          )}
        </div>
      )}
    </>
  );
}

export default SaucesUpdate;
