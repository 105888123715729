import React, { useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import { commentImgClick } from "../../../actions";
import ManuSelection from "../MenuSelection/MenuSelection";
import chickenImg from "./../../../Assets/Image/1 (1).jpg";
import starImg from "./../../../Assets/Image/Star 14.png";
import info from "./../../../Assets/Icons/info_black.png";
import "./RestaurantDetailView.css";
// import { ReactPhotoCollage } from 'react-photo-collage'
import "react-image-gallery/styles/css/image-gallery.css";
import viewMenu from "./../../../Assets/Icons/Viewmenu.png";
import chat from "./../../../Assets/Icons/chat.png";
import clock from "./../../../Assets/Icons/clock.png";
import dog from "./../../../Assets/Icons/dog.png";
import locationPin from "./../../../Assets/Icons/location-pin.png";
import phone from "./../../../Assets/Icons/phone.png";
import removeIcon from "./../../../Assets/Icons/remove.png";
import gridImg from "./../../../Assets/Icons/visualization.png";
import product2 from "./../../../Assets/Image/product (2).jpg";
import product3 from "./../../../Assets/Image/product (3).jpg";
import product4 from "./../../../Assets/Image/product (4).jpg";
import ShareYourthoughts from "./../ShareYourThoughts/ShareYourThoughts";

import airBnbImage from "./../../../Assets/Image/airbnb.webp";

import axios from "axios";
import jwtDecode from "jwt-decode";
import { setPostData } from "../../../actions/actions";
import DragModal from "../../PostDisplay/Components/PostModal/DragModal";
import UploadedPostSection from "../UploadedPostSection/UploadedPostSection";
import ConfirmationModal from "../components/ConfirmationModal";
import galleryIcon from "./../../../Assets/Icons/image-gallery.png";
import featured from "./../../../Assets/Image/feature.png";
import post from "./../../../Assets/Image/social-media.png";
import petFriend from "./../../../Assets/Icons/pet.png";
import { json } from "react-router-dom";
import { LuAlarmClock } from "react-icons/lu";
import time from "../../../Assets/Icons/time.png";
import down from "../../../Assets/Icons/down.png";
import friends from "../../../Assets/Icons/customer.png";

const RestaurantDetailView = ({
  test3Ref,
  restaurantDetails,
  onScheduleClick,
  openModalofTimeSchedul,
  colseScheduler,
}) => {
  const dispatch = useDispatch();
  const commentimageClicked = useSelector((state) => state.CommentImgShow);
  const [isShowMore, setIsShowMore] = useState(false);
  const [viewSelector, setViewSelector] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showMore, setShowMore] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [follower, setFollower] = useState(1.1);
  const [following, setFollowing] = useState(586);
  const MenuList = useSelector((state) => state.menulist);
  const numMenus = MenuList.length;

  console.log(MenuList);

  const [editImage, setEditImage] = useState(false);
  const [showDraggableModal, setShowDraggableModal] = useState(false);
  const [editImageUrl, setEditImageUrl] = useState("");
  const [show, setShow] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadfiles, setUploadFiles] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [images, setImages] = useState([]);
  // console.log("after/before change: ", initialData);
  const [selectedImageIndex, setSelectedImageIndex] = useState("");
  // const [postData, setPostData] = useState([]);
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState([]);

  const [croppedImages, setCroppedImages] = useState([]);

  // console.log(
  //   "draggable 3:",
  //   "\n",
  //   initialData,
  //   "\n",
  //   initialCroppedAreaPixels,
  //   "\n",
  //   croppedImages
  // );

  const [cropForOneByOne, setCropForOneByOne] = useState({ x: 0, y: 0 });
  const [zoomForOneByOne, setZoomForOneByOne] = useState(1);

  const [cropForFourByFive, setCropForFourByFive] = useState({ x: 0, y: 0 });
  const [zoomForFourByFive, setZoomForFourByFive] = useState(1);

  const [typeOfCrop, setTypeOfCrop] = useState("1:1");

  const [croppedAreaPixelsForOneByOne, setCroppedAreaPixelsForOneByOne] =
    useState(null);
  const [croppedAreaPixelsForFourByFive, setCroppedAreaPixelsForFourByFive] =
    useState(null);

  const [
    initialCroppedAreaPixelsForOneByOne,
    setInitialCroppedAreaPixelsForOneByOne,
  ] = useState(null);

  const [
    initialCroppedAreaPixelsForFourByFive,
    setInitialCroppedAreaPixelsForFourByFive,
  ] = useState(null);

  const [croppedImageForOnyByOne, setCroppedImageForOnyByOne] = useState(null);
  const [croppedImageForFourByFive, setCroppedImageForFourByFive] =
    useState(null);

  const [isImageSelected, setIsImageSelected] = useState(false);

  const [address, setAddress] = useState();

  const apiAddress = process.env.REACT_APP_SECRET;

  // console.log("restaurant details", restaurantDetails);

  const restaurantId = restaurantDetails?.id;

  // console.log("restaurant id", restaurantId);

  const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

  let decodedData = {};
  let userId = "";
  if (gotasteClienttoken != null) {
    try {
      decodedData = jwtDecode(gotasteClienttoken);
      userId = decodedData.UserId;
    } catch (error) {}
  }

  useEffect(() => {
    setInitialCroppedAreaPixels((prevState) => {
      const newState = [...prevState];
      while (newState.length < initialData.length) {
        newState.push({ oneByOne: null, fourByFive: null });
      }
      return newState;
    });

    setCroppedImages((prevState) => {
      const newState = [...prevState];
      while (newState.length < initialData.length) {
        newState.push({ oneByOne: null, fourByFive: null });
      }
      return newState;
    });
  }, [initialData]);

  const fetchPostData = async () => {
    // console.log("userId: ", userId);
    if (restaurantId) {
      await axios
        .get(
          apiAddress +
            `/api/Post/GetTopPostWithCommentReactByUserIdAndResaurantId?userId=${userId}&restaurantId=${restaurantId}&skip=0&take=12`
        )
        .then((response) => {
          // console.log("post data in useEffect", response.data);
          // setPostData(response.data);
          // setRestaurantDetails(response.data);
          dispatch(setPostData(response.data));
        })
        .catch((error) => {
          console.log("my error", error);
        });
    }
  };

  const [deleting, setDeleting] = useState(false);

  const deletingPostSignal = () => {
    setDeleting(true);
  };

  setTimeout(() => {
    setDeleting(false);
  }, 1000);

  useEffect(() => {
    fetchPostData();
  }, [dispatch, userId, restaurantId, deleting]);

  const postData = useSelector((state) => state.postData);

  // console.log("post data", postData);

  useEffect(() => {
    if (date.getHours() > 9 && date.getHours() < 21) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    setAddress(restaurantDetails.location);
    // fetchPostData();
  }, [postData, dispatch]);

  const imagesObject2 = [
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product3,
      thumbnail: product3,
    },
    {
      original: product4,
      thumbnail: product4,
    },
    {
      original: product4,
      thumbnail: product4,
    },
  ];

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const imagesObject = [
    { image: airBnbImage },
    // { image: seventhImg },
    // { image: eighthImg },
    // { image: ningthImg },
    // { image: sixthImg },
  ];
  // console.log(Object.keys(imagesObject).length);
  // const [arrayCount, setArrayCount] = useState(Object.keys(imagesObject).length);
  // const setting = {
  //   width: '800px',
  //   height: ['20rem', '170px'],
  //   layout: [1, 3],
  const photos = [
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  // showNumOfRemainingPhotos: true

  const showModalInfo = () => {
    window.$("#shareYourThoughtsModal").modal("show");
  };
  const hideModalInfo = () => {
    window.$("#shareYourThoughtsModal").modal("hide");
  };
  // Scroll to the Test3 component
  const ScrollToMenu = () => {
    test3Ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const longText = "About: " + restaurantDetails.details;
  const maxLength = 200;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? longText : longText.slice(0, maxLength);

  const myRef = useRef();
  const filterBottomRef = useRef(null);
  const [intersecting, setIntersecting] = useState(false);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(false);
  useEffect(() => {
    // console.log(restaurantDetails);
    window.addEventListener("scroll", (event) => {
      if (filterBottomRef.current !== null) {
        const filterBarRect = filterBottomRef.current.getBoundingClientRect();
        const isAboveViewport =
          filterBarRect.top < 0
            ? true
            : filterBarRect.top === 0
            ? false
            : false;
        setIntersecting(isAboveViewport);
        var top = filterBarRect.top * -1;
        var scrollval = scrollValue * -1;
        if (top > scrollval) {
          setSlideUp(true);
        } else {
          setSlideUp(false);
        }
        var s = filterBarRect.top;
        setscrollValue(s);
      }
    });
  });

  const filterPostsWithImage = (postData) => {
    return postData?.filter((post) => {
      return (
        post.postInfoImageMapperList &&
        post.postInfoImageMapperList.some(
          (imageMapper) => imageMapper.imageUploadpath !== "N/A"
        )
      );
    });
  };

  const galleryPostData = filterPostsWithImage(postData);

  // console.log("gallery post data", galleryPostData);

  const [open, setOpen] = useState(false);
  const [discard, setDiscard] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // Add logic to discard changes here
    setDiscard(true);
    console.log("Changes discarded");
    handleClose();
  };

  const showScheduleModal = () => {
    onScheduleClick();
  };

  const [expandedCategory, setExpandedCategory] = useState(null);

  const getNiceCategory = (data) => {
    if (restaurantDetails !== null) {
      const splitCategory = data.split("|");

      var added = [...splitCategory, "Italiano Food", "chinesse Food"];

      return added.map((category, index) => {
        const isLast = index === splitCategory.length - 1;
        const truncatedCategory =
          category.length > 9 ? category.slice(0, 8) + "..." : category;

        const isExpanded = expandedCategory === category;
        const handleCategoryClick = () => {
          if (isExpanded) {
            setExpandedCategory(null);
          } else {
            setExpandedCategory(category);
          }
        };
        if (!isLast) {
          return (
            <h6
              key={index}
              style={{ fontSize: "13px", cursor: "pointer" }}
              title={category}
              className={`res-category-name ${isExpanded ? "expanded" : ""}`}
              onClick={handleCategoryClick}
            >
              {isExpanded ? category : truncatedCategory} {!isLast && " | "}
            </h6>
          );
        } else {
          return (
            <h6
              key={index}
              style={{ fontSize: "13px", cursor: "pointer" }}
              title={category}
              className={`res-category-name ${isExpanded ? "expanded" : ""}`}
              onClick={handleCategoryClick}
            >
              {isExpanded ? category : truncatedCategory} {!isLast && " | "}
            </h6>
          );
        }
      });
    }
  };

  return (
    <div className="customContainer ">
      <div
        className={`d-flex justify-content-center  ${
          intersecting ? (slideUp ? "d-none" : "d-block") : "d-none"
        }`}
        ref={myRef}
      >
        <div
          className={`d-flex justify-content-center  viewMenuMessage
           ${intersecting ? (slideUp ? "" : "slide-up-nav") : "slide-up"}`}
        >
          <div className="buttonDiv">
            <button className="btn" onClick={ScrollToMenu}>
              <img src={viewMenu} alt="viewMenu" style={{ width: "14px" }} />
              <span> View Menu's</span>
            </button>
          </div>
          {/* <div className="buttonDiv">
            <button className="btn ">
              <img src={chat} alt="viewMenu" style={{ width: "18px" }} />
              <span> Message</span>
            </button>
          </div> */}
        </div>
      </div>
      <div
        class="modal fade "
        id="shareYourThoughtsModal"
        // tabindex="-1"
        // role="dialog"
        // aria-labelledby="exampleModalCenterTitle"
        // aria-hidden="true"

        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog  modal-dialog-centered galleryModal"
          role="document"
        >
          <div class="modal-content">
            <div class={`modal-body ${editImage ? "edit-mode" : ""}`}>
              {/* <Login loaded="false" /> */}
              {/* <GalleryModal selectedImage={selectedImage} /> */}
              <ShareYourthoughts
                editImage={editImage}
                setEditImage={setEditImage}
                onClick={hideModalInfo}
                removeIcon={removeIcon}
                logo={restaurantDetails.logoUrl}
                restaurantName={restaurantDetails.name}
                restaurantId={restaurantDetails.id}
                setInitialData={setInitialData}
                initialData={initialData}
                setEditImageUrl={setEditImageUrl}
                editImageUrl={editImageUrl}
                setShow={setShow}
                handleOpen={handleOpen}
                discard={discard}
                show={show}
                setDiscard={setDiscard}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                uploadfiles={uploadfiles}
                setUploadFiles={setUploadFiles}
                setImages={setImages}
                images={images}
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                initialCroppedAreaPixels={initialCroppedAreaPixels}
                setInitialCroppedAreaPixels={setInitialCroppedAreaPixels}
                croppedImages={croppedImages}
                setCroppedImages={setCroppedImages}
                isImageSelected={isImageSelected}
                setIsImageSelected={setIsImageSelected}
                cropForOneByOne={cropForOneByOne}
                setCropForOneByOne={setCropForOneByOne}
                zoomForOneByOne={zoomForOneByOne}
                setZoomForOneByOne={setZoomForOneByOne}
                cropForFourByFive={cropForFourByFive}
                setCropForFourByFive={setCropForFourByFive}
                zoomForFourByFive={zoomForFourByFive}
                setZoomForFourByFive={setZoomForFourByFive}
                typeOfCrop={typeOfCrop}
                setTypeOfCrop={setTypeOfCrop}
                croppedAreaPixelsForOneByOne={croppedAreaPixelsForOneByOne}
                setCroppedAreaPixelsForOneByOne={
                  setCroppedAreaPixelsForOneByOne
                }
                croppedAreaPixelsForFourByFive={croppedAreaPixelsForFourByFive}
                setCroppedAreaPixelsForFourByFive={
                  setCroppedAreaPixelsForFourByFive
                }
                initialCroppedAreaPixelsForOneByOne={
                  initialCroppedAreaPixelsForOneByOne
                }
                setInitialCroppedAreaPixelsForOneByOne={
                  setInitialCroppedAreaPixelsForOneByOne
                }
                initialCroppedAreaPixelsForFourByFive={
                  initialCroppedAreaPixelsForFourByFive
                }
                setInitialCroppedAreaPixelsForFourByFive={
                  setInitialCroppedAreaPixelsForFourByFive
                }
                croppedImageForOnyByOne={croppedImageForOnyByOne}
                setCroppedImageForOnyByOne={setCroppedImageForOnyByOne}
                croppedImageForFourByFive={croppedImageForFourByFive}
                setCroppedImageForFourByFive={setCroppedImageForFourByFive}
              />
              {editImage ? (
                <div className="image-drag-container">
                  <DragModal
                    showDraggableModal={showDraggableModal}
                    setShowDraggableModal={setShowDraggableModal}
                    initialData={initialData}
                    setInitialData={setInitialData}
                    setEditImage={setEditImage}
                    setEditImageUrl={setEditImageUrl}
                    setShow={setShow}
                    uploadfiles={uploadfiles}
                    setUploadFiles={setUploadFiles}
                    setImages={setImages}
                    images={images}
                    setSelectedImageIndex={setSelectedImageIndex}
                    initialCroppedAreaPixels={initialCroppedAreaPixels}
                    setInitialCroppedAreaPixels={setInitialCroppedAreaPixels}
                    croppedImages={croppedImages}
                    setCroppedImages={setCroppedImages}
                    setIsImageSelected={setIsImageSelected}
                    setCropForOneByOne={setCropForOneByOne}
                    setZoomForOneByOne={setZoomForOneByOne}
                    setCropForFourByFive={setCropForFourByFive}
                    setZoomForFourByFive={setZoomForFourByFive}
                    setTypeOfCrop={setTypeOfCrop}
                    setCroppedAreaPixelsForOneByOne={
                      setCroppedAreaPixelsForOneByOne
                    }
                    setCroppedAreaPixelsForFourByFive={
                      setCroppedAreaPixelsForFourByFive
                    }
                    setInitialCroppedAreaPixelsForOneByOne={
                      setInitialCroppedAreaPixelsForOneByOne
                    }
                    setInitialCroppedAreaPixelsForFourByFive={
                      setInitialCroppedAreaPixelsForFourByFive
                    }
                    setCroppedImageForOnyByOne={setCroppedImageForOnyByOne}
                    setCroppedImageForFourByFive={setCroppedImageForFourByFive}
                  ></DragModal>
                  {/* <div style={{ width: "100%" }}>
                    <div className="layer-icon opened" onClick={() => setShowDraggableModal(!showDraggableModal)}>
                      <img src={imageIcon} alt="icon" />
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {restaurantDetails != undefined ? (
        <div className="card RestaurantDetailCard">
          <div className="card-body">
            <div className="d-flex gap-3 resLOgoparentDiv">
              <div className="parentDivWidth">
                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="d-md-none d-flex align-items-center">
                    <img
                      // width={100}
                      // height={100}
                      src={restaurantDetails.logoUrl}
                      alt="img"
                      className="me-3 restaurant-details-logo"
                    />
                    <div className="d-flex flex-column">
                      <h3 className="brandName">{restaurantDetails.name}</h3>
                      <div className="d-flex d-md-none flex-wrap ResturentCategory mb-1">
                        {getNiceCategory(restaurantDetails.foodCategory)}
                      </div>
                      <div className="follower d-flex flex-row justify-content-between mobileViewForFollow">
                        <p className="d-flex align-items-center ">
                          <span
                            className="mobileDisplay resfollower"
                            style={{ marginRight: "8px" }}
                          >
                            {follower}M followers{" "}
                          </span>
                          <span className="mobileDisplay resfollower">
                            {following}k following
                          </span>
                        </p>
                      </div>
                      <div
                        className="d-flex flex-column followBtnDesktop"
                        style={{
                          marginTop: "10px",
                          fontSize: "0.8rem",
                        }}
                      >
                        <p className="d-flex align-items-center mb-0">
                          <span style={{ marginRight: "8px" }}>
                            {follower}M followers
                          </span>
                          <span>{following}k following</span>
                        </p>
                        <button
                          className="followBtn-ResturantPage followBtnDesktop d-none d-md-inline-block mt-2"
                          style={{ fontSize: "12px" }}
                        >
                          Follow
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-md-flex flex-grow-1 justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <h3 className="brandName mobileViewForFollow">
                        {restaurantDetails.name}
                      </h3>
                      <div
                        className="d-flex followBtnDesktop"
                        style={{
                          marginTop: "10px",
                          fontSize: "0.8rem",
                        }}
                      >
                        <h3 className="brandName me-2">
                          {restaurantDetails.name}
                        </h3>
                        <p className="d-flex align-items-center mb-0">
                          <span style={{ marginRight: "8px" }}>
                            {follower}M followers
                          </span>
                          <span>{following}k following</span>
                        </p>
                        <button
                          className="followBtn-ResturantPage followBtnDesktop d-none d-md-inline-block mt-2"
                          style={{ fontSize: "12px" }}
                        >
                          Follow
                        </button>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={starImg} alt="" className="starImage me-2" />
                      <h3 className="ratingNumber">4.5</h3>
                    </div>
                  </div>
                </div>

                <div className="d-none d-md-flex ResturentCategory">
                  <h6>{restaurantDetails.foodCategory}</h6>
                </div>
                {/* <div className='follower d-flex flex-row justify-content-between mobileViewForFollow'>
                  <p className='d-flex align-items-center '>
                    <span
                      className='mobileDisplay resfollower'
                      style={{ marginRight: '8px' }}
                    >
                      {follower}M followers{' '}
                    </span>
                    <span className='mobileDisplay resfollower'>
                      {following}k following
                    </span>
                  </p>
                  <button
                    className='followBtn-ResturantPage mobileDisplay'
                    style={{ fontSize: '12px' }}
                  >
                    {' '}
                    Follow
                  </button>
                </div> */}

                <div className="d-flex location justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <img
                      src={locationPin}
                      alt=""
                      style={{ width: "1rem", height: "1rem" }}
                    />
                    {restaurantDetails.location !== undefined ? (
                      screenWidth < 688 ? (
                        <p
                          className="ms-2"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore
                            ? restaurantDetails.location
                            : `${restaurantDetails.location
                                .split(/\s+/)
                                .slice(0, 2)
                                .join(" ")}`}
                          <a
                            className="text-decoration-none ms-1"
                            style={{
                              color: "#3D1F1B",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            {showMore ? "" : "..."}
                          </a>
                        </p>
                      ) : (
                        <p className="ms-2">{restaurantDetails.location}</p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex d-md-none align-items-center">
                    <img src={starImg} alt="" className="starImage" />
                    <h3 className="ratingNumber mt-2">4.5</h3>
                    <button
                      className="followBtn-ResturantPage mobileDisplay"
                      style={{ fontSize: "12px" }}
                    >
                      {" "}
                      Follow
                    </button>
                  </div>
                </div>

                <div className="d-flex align-items-center opening">
                  <div className="d-flex align-items-center opening-hour">
                    <div>
                      <img src={clock} alt="" style={{ width: "1rem" }} />
                    </div>
                    <div
                      className="operningHourText"
                      onClick={() => openModalofTimeSchedul()}
                    >
                      <p style={{ marginBottom: "0px" }}>
                        {" "}
                        <span className="desktopDisplay-Opening">
                          Opening hour:{" "}
                        </span>
                        {/* {window.innerWidth < 688 ? (<br />) : (<b></b>)} */}
                        <b style={{ textDecoration: "underline" }}>
                          9:00 AM-10:00 PM
                        </b>
                      </p>
                    </div>
                  </div>

                  <div>
                    <span
                      className={`${isOpen ? "open-close open" : "d-none"} `}
                    >
                      <div
                        className="cancel-button-group"
                        onClick={() => openModalofTimeSchedul()}
                      >
                        <div className="time-icon-container">
                          <img src={time} alt="time icon" />
                        </div>
                        <div className="cancel-button-close">Open</div>
                        <div className="cancel-button-i">
                          <img src={down} alt="down arrow" />
                        </div>
                      </div>
                    </span>
                    <span
                      className={`${isOpen ? "d-none" : "open-close close"} `}
                    >
                      <div
                        className="cancel-button-group"
                        onClick={() => openModalofTimeSchedul()}
                      >
                        <div className="time-icon-container">
                          <img src={time} alt="time icon" />
                        </div>
                        <div className="cancel-button-close">Closed</div>
                        <div className="cancel-button-i">
                          <img src={down} alt="down arrow" />
                        </div>
                      </div>
                    </span>
                  </div>
                </div>

                <div>
                  <LuAlarmClock style={{ fontSize: "19px" }} />{" "}
                  <span style={{ marginLeft: "5px", fontSize: "16px" }}>
                    Last Order Time:{" "}
                    <span style={{ fontWeight: "bold" }}>10:00 PM</span>
                  </span>
                </div>

                <div className="d-flex align-items-center opening">
                  <div className="d-flex align-items-center opening-hour">
                    <div>
                      <img src={phone} alt="" style={{ width: "1rem" }} />
                    </div>
                    <div className="operningHourText">
                      <p style={{ marginBottom: "0px" }}>
                        Phone:{" "}
                        <b>
                          {restaurantDetails?.phoneNumber
                            ? restaurantDetails?.phoneNumber
                            : "Not Available"}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>

                {restaurantDetails?.isPetFriendly && (
                  <div className="d-flex align-items-center opening">
                    <div className="d-flex align-items-center opening-hour">
                      <div>
                        <img src={petFriend} alt="" style={{ width: "1rem" }} />
                      </div>
                      <div className="operningHourText">
                        <p style={{ marginBottom: "0px" }}>Pet Friendly</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex ResturentDetails">
                  <p onClick={toggleExpand} style={{ fontSize: "14px" }}>
                    {/* Details: Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's standard
                  dummy text ever since the 1500s, when an unkown printer took a
                  galley of type */}
                    {displayText}
                    {!isExpanded && longText.length > maxLength && "..."}
                  </p>
                </div>
                <div className="restaurant-btn-group">
                  <div className="d-flex mobileMarginTop">
                    <div className="buttonDiv d-flex align-items-center">
                      <button className="btn m-1" onClick={ScrollToMenu}>
                        <img
                          src={viewMenu}
                          alt="vi ewMenu"
                          style={{ width: "14px" }}
                        />
                        <span> View Menu</span>
                      </button>
                    </div>
                    <div className="buttonDiv">
                      <button className="btn m-1">
                        <img
                          src={chat}
                          alt="viewMenu"
                          style={{ width: "18px" }}
                        />
                        <span> Message</span>
                      </button>
                    </div>
                  </div>

                  <div className="buttonDiv friends-btn">
                    <button className="btn m-1">
                      <img
                        src={friends}
                        alt="viewMenu"
                        style={{ width: "18px" }}
                      />
                      <span className="ms-1"> Ask/Give Recommendation</span>
                    </button>
                  </div>
                </div>

                <div ref={filterBottomRef}></div>
              </div>
              <div className="pb-3 desktopDisplay infoLogo">
                <img src={restaurantDetails.logoUrl} alt="img" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        "Loading"
      )}

      {userId && (
        <div className=" d-flex justify-content-center align-items-center shareYourthoughtsDiv">
          {" "}
          <img
            src={restaurantDetails.logoUrl}
            alt=""
            className="rounded-img"
            style={{ width: "43px" }}
          />
          <div className="">
            <button
              className="btn-custom shareYourthoughts resturentPageWhatsOnYourMindBtn"
              onClick={() => {
                showModalInfo();
              }}
            >
              What’s on your mind?
            </button>
          </div>
        </div>
      )}
      {/* featured */}
      <div className="d-flex justify-content-around mb-5 mt-5 viewSelector FeatureDivCategory mobileDisplay-featured">
        <img
          src={gridImg}
          alt=""
          className={`${viewSelector === 1 ? "" : "selectedCategoryFeature"}`}
          onClick={() => {
            setViewSelector(1);
          }}
        />
        <img
          src={post}
          alt=""
          className={`${viewSelector === 2 ? "" : "selectedCategoryFeature"}`}
          onClick={() => {
            setViewSelector(2);
          }}
        />
        {/* <img
          src={galleryIcon}
          alt=''
          className={`${viewSelector === 3 ? '' : 'selectedCategoryFeature'}`}
          onClick={() => {
            setViewSelector(3);
          }}
        /> */}
      </div>

      {/* grid */}

      <UploadedPostSection
        postData={postData}
        galleryPostData={galleryPostData}
        viewSelector={viewSelector}
        featured={featured}
        restaurantId={restaurantId}
        onDeletedPost={() => deletingPostSignal()}
      />

      {/* Not sure what is this */}
      <div
        className={`row row-gallery-postview ${
          viewSelector === 3 ? "" : "d-none"
        }`}
      >
        <div className="d-flex justify-content-around CategoryFeatureDiv desktopDisplay">
          <div className="d-flex align-items-center FeatureDiv mb-2">
            <div>
              <img src={featured} alt="" className="FeaturedImg" />
            </div>
            <div>
              <h6 className="FeaturedText">Featured</h6>
            </div>
          </div>
          <div></div>
        </div>

        <div
          className="d-flex flex-wrap"
          style={{ gap: "1rem", justifyContent: "center", padding: "0" }}
        >
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className="albumHr2" />
              <hr className="albumHr1" />
              <div className="aspect-ratio-container">
                <img
                  src={product2}
                  className="card-img-top albumnCardImg"
                  alt="Album Image"
                />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: "12px" }}>
                Mobile Uploads
              </p>
              <p className="card-text" style={{ fontSize: "10px" }}>
                25 items
              </p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className="albumHr2" />
              <hr className="albumHr1" />
              <div className="aspect-ratio-container">
                <img
                  src={product3}
                  className="card-img-top albumnCardImg"
                  alt="Album Image"
                />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: "12px" }}>
                Timeline Photos
              </p>
              <p className="card-text" style={{ fontSize: "10px" }}>
                15 items
              </p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className="albumHr2" />
              <hr className="albumHr1" />
              <div className="aspect-ratio-container">
                <img
                  src={product4}
                  className="card-img-top albumnCardImg"
                  alt="Album Image"
                />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: "12px" }}>
                Profile Pictures
              </p>
              <p className="card-text" style={{ fontSize: "10px" }}>
                10 items
              </p>
            </div>
          </div>
          <div className="card albumCard">
            <a href="" className="card-link">
              <hr className="albumHr2" />
              <hr className="albumHr1" />
              <div className="aspect-ratio-container">
                <img
                  src={chickenImg}
                  className="card-img-top albumnCardImg"
                  alt="Album Image"
                />
              </div>
            </a>
            <div className="card-body albumnCardBody">
              <p className="card-title" style={{ fontSize: "12px" }}>
                Chicken Album
              </p>
              <p className="card-text" style={{ fontSize: "10px" }}>
                5 items
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pt-4 subscribtionContainer">
        <SubscriptionOffer />
      </div>

      <div className="discountDiv">
        <h3 className="brandName">Discount Offer </h3>

        <DiscountOffer from={2} />
      </div>
      <div className="recommendationDiv">
        <h3 className="brandName">Recommendation</h3>
        <div className="">
          <Recommendation from={2} />
        </div>
      </div> */}
      <div className="mb-2">
        <ManuSelection
          restaurantDetails={restaurantDetails}
          oneScheduleClick={() => showScheduleModal()}
          colseScheduler={colseScheduler}
        />
      </div>

      {/* comment image show */}
      {commentimageClicked && (
        <div className="image-gallery-container ">
          <button
            className="commentImgShowButton"
            onClick={() => dispatch(commentImgClick())}
          >
            <img src={removeIcon} alt="Close" />
          </button>
          <div className="commentImg">
            <ImageGallery
              items={imagesObject2}
              // startIndex={selectedImage}
              showFullscreenButton={false}
              showPlayButton={false}
            />
          </div>
        </div>
      )}

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default RestaurantDetailView;
