const initialState = false

const CommentImgShow = (state = initialState, action) => {
  switch (action.type) {
    case 'commentImgClicked':
      return !state

    default:
      return state
  }
}
export default CommentImgShow
