import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./slider.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchMenu } from "../../../actions/Menu/menuActions";
import { setSelectedMenu } from "../../../actions/Menu/selectedMenuActions";
import { selectFirstMenuItem } from "../../../actions/Menu/menuActions";
import { setSearchValue } from "../../../actions/SearchBar/searchAtion";

import biker from "../../../Assets/Icons/biker.png";
import table from "../../../Assets/Icons/table.png";
import Title from "antd/es/skeleton/Title";
import clock from "./../../../Assets/Icons/clock.png";
import dineIn from "./../../../Assets/Icons/DineIn.png";
import shipping from "./../../../Assets/Icons/delivery-bike-circle2.png";

const Slider = ({ resUrl, onTimeClick, colseScheduler }) => {
  const [showArrows, setShowArrows] = useState(true);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu.menu);
  const firstMenu = useSelector(
    (state) => state.firstMenu.selectedFirstMenuItem
  );
  const selectedMenuFromRedux = useSelector(
    (state) => state.selectedMenu.selectedMenu
  ); //also getting by section click.

  /* Getting Menu Data using redux */
  useEffect(() => {
    dispatch(fetchMenu(resUrl));
  }, [dispatch, resUrl]);

  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth > 767);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectMark, setSelectMark] = useState();

  useEffect(() => {
    if (selectedMenuFromRedux === null) {
      setSelectMark();
    }
  }, [selectedMenuFromRedux]);

  useEffect(() => {
    if (selectedMenuFromRedux !== null) {
      setSelectMark(selectedMenuFromRedux);
    } else {
      setSelectMark(firstMenu);
    }
  }, [selectedMenuFromRedux]);

  /* Select specific menu onClick Function */
  const menuClick = (data) => {
    /* setting search value empty */
    // dispatch(setSearchValue(""));
    if (data === firstMenu) {
      dispatch(setSelectedMenu(null));
      dispatch(selectFirstMenuItem(null));
      setSelectMark(null);
    } else {
      if (selectedMenuFromRedux === data) {
        dispatch(setSelectedMenu(null));
        setSelectMark(null);
      } else {
        dispatch(setSelectedMenu(data));
        setSelectMark(data);
        swiperInstance.slideTo(data.index);
      }
    }
  };

  /* first Menu Selected by Default */
  useEffect(() => {
    if (firstMenu) {
      setSelectMark(firstMenu);
    } else {
      setSelectMark(selectedMenuFromRedux);
    }
  }, [firstMenu]);

  /* Handle Slide Change */
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const activeMenu = menu[activeIndex];
    // dispatch(setSelectedMenu(activeMenu));
    // setSelectMark(activeMenu);
  };

  const findMenuIndexById = (id) => {
    return menu.findIndex((menu) => menu.id === id);
  };

  // console.log("selectedMenu", selectedMenuFromRedux);

  useEffect(() => {
    if (selectedMenuFromRedux) {
      const index = findMenuIndexById(selectedMenuFromRedux);
      swiperInstance.slideTo(index + 1);
      console.log("index", index + 1);
    }
  }, []);

  const [scheduleClick, setScheduleClick] = useState(false);
  const [clickedId, setClickedId] = useState();

  useEffect(() => {
    if (colseScheduler === true) {
      setScheduleClick(false);
    }
  }, [colseScheduler]);

  const handleTimeScheduleClick = (Id) => {
    setClickedId(Id);
    setScheduleClick(true);
    onTimeClick();
    return;
  };

  console.log("menud data: ", menu);

  const AvailibilityStatusEnum = {
    Available: 1,
    Out_Of_Hour: 2,
    Unavailable: 3,
    Unavailable_And_Out_Of_Hour: 4,
    Stock_Out: 5,
    Available_And_Out_Of_Hour: 6,
    No_Status: 7,
  };
  const getAvailibilityStatusName = (status) => {
    switch (status) {
      case AvailibilityStatusEnum.Available:
        return "Available";
      case AvailibilityStatusEnum.Out_Of_Hour:
        return "Out Of Hours";
      case AvailibilityStatusEnum.Unavailable:
        return "Unavailable";
      case AvailibilityStatusEnum.Unavailable_And_Out_Of_Hour:
        return "Unavailable";
      case AvailibilityStatusEnum.Stock_Out:
        return "Stock Out";
      case AvailibilityStatusEnum.Available_And_Out_Of_Hour:
        return "Out of Hour";
      case AvailibilityStatusEnum.No_Status:
        return "No Status";
      default:
        return "Unknown Status";
    }
  };

  // menu status
  const getMenuStatus = (activeStatus, outOfHourStatus) => {
    if (activeStatus === false && outOfHourStatus === false) {
      return "Unavailable";
    } else if (activeStatus === false && outOfHourStatus === true) {
      return "Unavailable";
    } else if (activeStatus === true && outOfHourStatus === true) {
      return "Available and Out of Hours";
    } else if (activeStatus === true && outOfHourStatus === false) {
      return "Available";
    } else {
      return "Unknown status";
    }
  };

  return (
    <div>
      {/* <button
        onClick={() => {
          swiperInstance.slideTo(6);
        }}
      >
        Slide to
      </button>
      <button
        onClick={() => {
          swiperInstance.slideTo(0);
        }}
      >
        Slide back
      </button> */}
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        grabCursor={false}
        centeredSlides={false}
        navigation={showArrows}
        onSwiper={setSwiperInstance}
        onSlideChange={handleSlideChange}
        modules={[Navigation]}
        className="slider-containerSL"
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          530: {
            slidesPerView: 1.5,
          },
          710: {
            slidesPerView: 1.5,
          },
          950: {
            slidesPerView: 1.9,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {menu.map((data, index) => {
          return (
            <SwiperSlide
              key={index}
              className="d-flex cardShadow cardMargin align-items-center justify-content-around selectionSingleButton slider menu-slider-card"
              style={{
                cursor: "pointer",
                background:
                  selectMark?.id === data.id ? "rgb(208, 225, 253)" : "",
                paddingBottom: "15px",
              }}
              onClick={() => menuClick({ ...data, index })}
              title={
                selectMark?.menuName === data.menuName
                  ? "click to deselect this menu"
                  : "click to select a menu"
              }
            >
              {/* DeliveryType */}
              {/* <div className="bike-img">
                <img src={dineIn} alt="menu image" /> 
              </div> */}

              {data?.menuType === "In-Store" ? (
                <div className="bike-img margin-small">
                  <div className="menu-image-container menu-image-smaller">
                    <img src={shipping} alt="menu icon" />
                  </div>
                  <div className="menu-image-container menu-image-smaller">
                    <img src={dineIn} alt="menu icon" />
                  </div>
                </div>
              ) : (
                <div className="bike-img margin-small">
                  <div className="menu-image-container menu-image-smaller">
                    <img src={dineIn} alt="menu icon" />
                  </div>
                  <div className="menu-image-container menu-image-smaller">
                    <img src={shipping} alt="menu icon" />
                  </div>
                </div>
              )}

              {/* {data?.menuType === "In-Store" || data?.menuType === "Dine-In" ? (
                <div className="bike-img">
                  <div className="menu-image-container">
                    <img src={dineIn} alt="menu icon" />
                  </div>
                </div>
              ) : data?.menuType === "Online" ||
                data?.menuType === "Delivery" ? (
                <div className="bike-img">
                  <div className="menu-image-container">
                    <img src={shipping} alt="menu icon" />
                  </div>
                </div>
              ) : (
                <div className="bike-img margin-small">
                  <div className="menu-image-container menu-image-smaller">
                    <img src={dineIn} alt="menu icon" />
                  </div>
                  <div className="menu-image-container menu-image-smaller">
                    <img src={shipping} alt="menu icon" />
                  </div>
                </div>
              )} */}

              <div
                className={`menu-name ${
                  data.menuName.length > 11 ? "large-tooltip" : ""
                } tooltip-Desktop`}
                title={data.menuName}
              >
                {data.menuName.length > 11
                  ? `${data.menuName.substring(0, 11)}...`
                  : data.menuName}

                <div
                  style={{
                    backgroundColor:
                      scheduleClick && clickedId === data.id ? "#00acab" : "",
                  }}
                  className="d-flex w-100 h-100 align-items-center justify-content-center time-schadule"
                  onClick={() => handleTimeScheduleClick(data.id)}
                >
                  <img
                    src={clock}
                    alt=""
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginRight: "0.8rem",
                    }}
                  />
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    {getMenuStatus(data.isActive, data.isOutOfHour) ===
                    "Unavailable" ? (
                      <p className="m-0 p-0 sliderScheduleTime">Unavailable</p>
                    ) : (
                      <p className="m-0 p-0 sliderScheduleTime">
                        9:00 AM-10:00 PM{" "}
                      </p>
                    )}
                    {/* {data.availibilityStatus != 1 ? (
                      <p className="m-0 p-0 sliderScheduleTime">
                        {getAvailibilityStatusName(data.availibilityStatus)}
                      </p>
                    ) : (
                      <p className="m-0 p-0 sliderScheduleTime">
                        9:00 AM-10:00 PM{" "}
                      </p>
                    )} */}
                  </div>
                </div>
              </div>

              <div
                // className="menu-name large-tooltip tooltip-Mobile"
                className={`menu-name tooltip-Mobile ${
                  data.menuName.length > 8 ? "large-tooltip" : ""
                } `}
                title={data.menuName}
              >
                {data.menuName.length > 8
                  ? `${data.menuName.substring(0, 7)}...`
                  : data.menuName}

                <div
                  style={{
                    backgroundColor:
                      scheduleClick && clickedId === data.id ? "#00acab" : "",
                  }}
                  className="d-flex w-100 h-100 justify-content-center time-schadule"
                  onClick={() => handleTimeScheduleClick(data.id)}
                >
                  <img
                    src={clock}
                    alt=""
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  <p className="m-0 p-0 sliderScheduleTime">09:00-10:00 PM</p>
                </div>
              </div>

              <div className="menu-image">
                <img src={data.iconId} alt="menu image" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slider;
