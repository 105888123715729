import React from 'react'
import img1 from "./../../../Assets/Image/1 (1).jpg"
import img2 from "./../../../Assets/Image/1 (2).jpg"
import img3 from "./../../../Assets/Image/1 44.png"
import star from "./../../../Assets/Image/Star 14.png"
import './Recommendation.css'

function Recommendation() {
  return (
    // <div className='row'>
        <div className="">
                    <div className="row  ">
                        <div className="recommendedSideBar">
                            <div className="col-12 d-flex justify-content-center">
                                <h3 className=" recomended-text ">Recommended</h3>
                            </div>
                            <div className="col-12 d-flex justify-content-center">

                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title " style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0 card-test2-font-size' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text card-test2-font-size-p" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 className="card-test2-font-size" style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center" >
                                <div className="card card-Test2" >
                                    <img src={img1} className="card-img-top card-img-test2" alt="..." />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title" style={{ fontSize: "1rem" }}>Cheese-Stuffed Meatballs</h5>
                                            <div className="d-flex align-items-center">
                                                <img src={star} style={{ width: "25px" }} className=" " />
                                                <h4 className='m-0 p-0' style={{ fontSize: "1rem" }}>4.5</h4>
                                            </div>
                                        </div>

                                        <p className="card-text" style={{ fontSize: ".8rem" }}>Ground beef, string cheese, ground
                                            pork, parmesan, olive oil....</p>
                                        <h4 style={{ fontSize: "1rem" }}>10.5$</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

    // </div>
  )
}

export default Recommendation