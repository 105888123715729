import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import checkImg from "./../../../Assets/Image/check.png";
import loadingGid from "./../../../Assets/Image/LoadingSpin.gif";
import "./Register.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [dob, setDob] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const apiAddress = process.env.REACT_APP_SECRET;

  const [userNameFound, setUserNameFound] = useState(false);
  const [emailFound, setEmailFound] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (event) => {
    const inputPhoneNumber = event.target.value;
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, "");
    if (inputPhoneNumber !== numericPhoneNumber) {
      setError("Phone number must contain only numbers.");
    } else {
      setError("");
      if (numericPhoneNumber.length > 11) {
        setError("Please enter a valid 10 or 11 digit phone number.");
      } else {
        setPhoneNumber(numericPhoneNumber);
      }
    }
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    if (confirmPassword) {
      if (event.target.value !== confirmPassword) {
        setError("Passwords do not match.");
      } else {
        setError("");
      }
    }
  };
  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value !== password) {
      setError("Passwords do not match.");
    } else {
      setError("");
    }
  };

  const register = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isValidPhoneNumber(phoneNumber)) {
      if (password === confirmPassword) {
        if (password.length < 6) {
          setLoading(false);
          setError("Password must be at least 6 characters.");
        } else {
          onSubmit();
        }
      } else {
        setLoading(false);
        setError("Passwords do not match.");
      }
    } else {
      setLoading(false);
      setError("Please enter a valid 10 or 11 digit phone number.");
    }
  };

  const onSubmit = () => {
    var url = apiAddress + "/api/GoTasteUser?password=" + password;
    var data = {
      id: "demo",
      firstName: firstname,
      lastName: lastname,
      dateOfBirth: dob,
      currentBalance: 0,
      heldBalance: 0,
      userName: username,
      email: email,
      emailConfirmed: false,
      phoneNumber: phoneNumber,
      isActive: false,
      creationTimestamp: new Date(),
    };
    axios({
      method: "post",
      url: url,
      data: data,
    })
      .then(function (response) {
        setLoading(false);
        console.log("res: " + response.data);
        if (response.data == "UserName Found") {
          setUserNameFound(true);
        } else if (response.data == "Email Found") {
          setEmailFound(true);
        } else if (response.data == "OK") {
          toast.success("Please check your email and verify", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setSuccess(true);
        } else {
          toast.error(response.data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        // window.location.href = "/";
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10,11}$/;
    return phoneRegex.test(phoneNumber);
  };
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <ToastContainer />
      <div className={`${success ? "d-none" : ""}`}>
        <form onSubmit={register}>
          <div className="container ">
            <h3 className="goTasteH3">Welcome To GoTaste</h3>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>First Name</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control login-info"
                    placeholder="First Name"
                    value={firstname}
                    required
                    onChange={(event) => {
                      setFirstname(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>Last Name</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control login-info"
                    placeholder="Last Name"
                    value={lastname}
                    required
                    onChange={(event) => {
                      setLastname(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>User Name</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control login-info"
                    placeholder="User Name"
                    value={username}
                    required
                    onChange={(event) => {
                      setUsername(event.target.value);
                      setUserNameFound(false);
                    }}
                  />
                  <span
                    className={`${userNameFound ? "" : "d-none"}`}
                    style={{ color: "red", fontSize: ".8rem" }}
                  >
                    {" "}
                    User Name Already exists *
                  </span>
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>Date Of Birth</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="date"
                    className="form-control login-info"
                    placeholder="User Name"
                    value={dob}
                    required
                    onChange={(event) => {
                      setDob(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>Phone Number</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="tel"
                    className="form-control login-info"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    required
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>Email</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="email"
                    className="form-control login-info"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setEmailFound(false);
                    }}
                  />
                  <span
                    className={`${emailFound ? "" : "d-none"}`}
                    style={{ color: "red", fontSize: ".8rem" }}
                  >
                    {" "}
                    Email Already exists *
                  </span>
                </div>
              </div>
            </div>

            <div className={"row mt-2 "}>
              {/* <div className='col-12'>
                                <span>Password</span>
                            </div> */}
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="password"
                    className="form-control login-info"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => {
                      handleChangePassword(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"row mt-2 "}>
              <div className="col-12">
                <div className="form-group ">
                  <input
                    type="password"
                    className="form-control login-info"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                    required
                  />
                </div>
              </div>
            </div>
            {error && <p className="error">{error}</p>}
            <div className={"row mt-2 "}>
              <div className="col-12">
                <span className="text-muted span-text">
                  We’ll call or text you to confirm your number. Standard
                  message and data rates apply. <b>Privacy Policy</b>
                </span>
              </div>
            </div>
            <div className={"row mt-4 "}>
              <div className="col-12 d-flex justify-content-center">
                {loading ? (
                  <button
                    className="btn btn-success font-weight-bold w-100 "
                    style={{
                      width: "    width: 100%;",
                      borderRadius: "8px",
                      backgroundColor: "#3D1E19",
                      pointerEvents: "none",
                    }}
                    type="submit"
                  >
                    <img src={loadingGid} alt="" style={{ width: "2rem" }} />
                  </button>
                ) : (
                  <button
                    className="btn btn-success font-weight-bold w-100 "
                    style={{
                      width: "    width: 100%;",
                      borderRadius: "8px",
                      backgroundColor: "#3D1E19",
                    }}
                    type="submit"
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={`${success ? "" : "d-none"}`}>
        <div
          className="d-flex align-items-center"
          style={{ flexDirection: "column", gap: "10px" }}
        >
          <img src={checkImg} alt="" style={{ width: "5rem" }} />
          <h2>Successfully registered </h2>
          <p>Please check your email to verify yourself</p>
        </div>
      </div>
    </div>
  );
};

export default Register;
