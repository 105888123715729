
import React, { useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import movieImg from './../../../../Assets/Image/aibnb2.webp'
import removeIcon from './../../../../Assets/Icons/remove.png'
import GalleryModal from './../../../GalleryModal/GalleryModal'
import aibnd from './../../../../Assets/Image/airbnb.webp'
import aibnd2 from './../../../../Assets/Image/aibnb2.webp'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import toastImg from './../../../../Assets/Image/1 (2).jpg'
import p1 from './../../../../Assets/Image/product (1).jpg'
import removeWhiteIcon from './../../../../Assets/Icons/removeBlack.png'
import './../PhotoGridGallery.css'
import ImageGallery from 'react-image-gallery';

const PhotoGridGalleryComponent = (props) => {

    const [selectedImage, setSelectedImage] = useState("");

    const [showPage, setshowPage] = useState(0);

    const images = [
        {
            src: props.selectedImage,
            width: 500,
        },
        {
            src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
            width: 500,
        },

        {
            src: movieImg,
            width: 500,
        },
        {
            src: 'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg',
            width: 500,
        },
        {
            src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
            width: 500,
        },

        {
            src: p1,
            width: 500,
        },
        {
            src: aibnd,
            width: 500,
        },
        {
            src: aibnd2,
            width: 500,
        },

        {
            src: toastImg,
            width: 500,
        },
    ]
    // const imagess = [
    //     {
    //         original: props.selectedImage,
    //         thumbnail: props.selectedImage,
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1015/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1015/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1019/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1019/250/150/',
    //     },
    // ];
    // const imageGalleryCLick = (e) => {
    //     console.log(e);
    // }
    return (
        <div>
            <div className={`${showPage == 0 ? "" : "d-none"}`}>
                <Masonry columnsCount={1} gutter="5px" >
                    <div className="masonry">
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image.src}
                                alt=""
                                onClick={() => {
                                    setSelectedImage(image.src);
                                    setshowPage(0);
                                }}
                                className="imgsize"
                            />
                        ))}
                    </div>
                </Masonry>
            </div>
            {/* <div className={`${showPage == 1 ? "" : "d-none"}`}>
                <button onClick={() => {
                    setshowPage(0);
                }}>Back</button>
                <ImageGallery items={imagess} onClick={(e) => { imageGalleryCLick(e) }} />
            </div> */}

        </div>
    );
};

export default PhotoGridGalleryComponent;