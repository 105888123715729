import { useState, useEffect } from 'react';
import uploadImg from './Assets/Image/1 (1).jpg'
const Test9 = () => {
    const [isSticky, setSticky] = useState(false);

    const scrollHandler = () => {
        let lastScroll = 0;
        const currentScroll = document.documentElement || document.body;

        if (currentScroll > 0 && lastScroll <= currentScroll) {
            lastScroll = currentScroll + 1;

            console.log("last", lastScroll);
            console.log("currentt", currentScroll);
            setSticky(false);
        }
        //  else {
        //     lastScroll = currentScroll;
        //     console.log('up');
        //     setSticky(true);
        // }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []); // Empty dependency array to ensure the event listener is added/removed only once during component mount/unmount.

    console.log(isSticky);
    return (
        <div>

            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div className='row row-cols-2  row-cols-md-6 g-2'>
                                <div className='d-flex align-items-center justify-content-center border rounded btn'>
                                    <i class="fa-solid fa-upload " style={{ "color": "#371b17", "font-size": "5rem" }}></i>
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div>
                                    <img src={uploadImg} className='img-fluid rounded' alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>





            {/* You can render your sticky component or content here based on the `isSticky` state */}
        </div>
    );
};

export default Test9;