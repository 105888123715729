// actions.js
import axios from 'axios';

export const fetchCartDataSuccess = (cartData) => ({
  type: 'FETCH_CART_DATA_SUCCESS',
  payload: cartData,
});

export const fetchCartDataFailure = (error) => ({
  type: 'FETCH_CART_DATA_FAILURE',
  payload: error,
});

export const fetchCartData = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
      );
      dispatch(fetchCartDataSuccess(response.data));
    } catch (error) {
      dispatch(fetchCartDataFailure(error.message));
    }
  };
};

export const addToCartSuccess = (cartData) => ({
  type: 'ADD_TO_CART_SUCCESS',
  payload: cartData,
});

export const addToCartFailure = (error) => ({
  type: 'ADD_TO_CART_FAILURE',
  payload: error,
});

export const addToCart = (userId, newItem) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET}/api/Cart/AddItem`,
        { userId, newItem }
      );
      dispatch(addToCartSuccess(response.data));
    } catch (error) {
      dispatch(addToCartFailure(error.message));
    }
  };
};

// actions.js
export const removeFromCartSuccess = (cartData) => ({
  type: 'REMOVE_FROM_CART_SUCCESS',
  payload: cartData,
});

export const removeFromCartFailure = (error) => ({
  type: 'REMOVE_FROM_CART_FAILURE',
  payload: error,
});

export const removeFromCart = (userId, itemId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET}/api/Cart/RemoveItem`,
        { userId, itemId }
      );
      dispatch(removeFromCartSuccess(response.data));
    } catch (error) {
      dispatch(removeFromCartFailure(error.message));
    }
  };
};

export const FETCH_POST_DATA = 'FETCH_POST_DATA';
export const SET_POST_DATA = 'SET_POST_DATA';

export const fetchPostData = (userId, restaurantId) => ({
  type: FETCH_POST_DATA,
  payload: { userId, restaurantId },
});

export const setPostData = (postData) => ({
  type: SET_POST_DATA,
  payload: postData,
});
