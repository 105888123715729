// // RestaurantOrder.jsx

// import React from 'react';
// import ItemDetails from './ItemDetails';
// import './ItemDetails.css';
// import ShowOrderStatus from '../../CheckOut/ShowOrderStatus';
// import { rest } from 'lodash';

// const RestaurantOrder = ({
//   restaurant,
//   onUpdateCart,
//   onDeleteCart,
//   cart,
//   setCart,
//   getItemDetails,
//   refetchCartData
// }) => {
//   // console.log("data from cart resturent: ", cart);
//   // console.log("data for resutrent details: ", cart?.orderStatus);

//   const reloadingCartData = () => {
//     refetchCartData();
//   }

//   return (
//     <div className='p-2'>
//       {/* {JSON.stringify(cart)} */}

//       <div className={`d-flex ${restaurant?.orderStatus !== null ? "justify-content-between" : "justify-content-center"}`}>
//         <p
//           className='restaurantName'
//           style={{
//             fontFamily: 'Poppins',
//             // fontStyle: 'italic',
//             margin: '0px auto'
//           }}
//         >
//           {restaurant?.restaurantName}
//         </p>

//         {
//           restaurant?.orderStatus && (
//             <ShowOrderStatus orderStatus={restaurant?.orderStatus} />
//           )
//         }
//       </div>
//       {restaurant.subOrders.map((subOrder) => (
//         subOrder.itemDetails.map((item) => (
//           <ItemDetails
//             key={item.id}
//             item={item}
//             onUpdateCart={onUpdateCart}
//             onDeleteCart={onDeleteCart}
//             cart={cart} // Pass the cart and setCart as props to ItemDetails
//             setCart={setCart}
//             getItemDetails={getItemDetails}
//             reloadingCart = {() => reloadingCartData()}
//           />

          
//         ))
//       ))}

//       {/* {cart?.itemDetails?.map(
//         (item) => item?.qunatity > 0 && (
//           <ItemDetails
//             key={item.id}
//             item={item}
//             onUpdateCart={onUpdateCart}
//             onDeleteCart={onDeleteCart}
//             cart={cart} // Pass the cart and setCart as props to ItemDetails
//             setCart={setCart}
//             getItemDetails={getItemDetails}
//           />
//         )
//       )} */}

//       {/* {restaurant?.itemDetails?.map(
//         (item) =>
//             <ItemDetails
//               key={item.id}
//               item={item}
//               onUpdateCart={onUpdateCart}
//               onDeleteCart={onDeleteCart}
//               cart={cart} // Pass the cart and setCart as props to ItemDetails
//               setCart={setCart}
//               getItemDetails={getItemDetails}
//             />
//       )} */}
//     </div>
//   );
// };

// // const RestaurantOrder = ({ restaurant }) => {
// //   return (
// //     <div>
// //       <p
// //         className='restaurantName'
// //         style={{
// //           fontFamily: 'Poppins',
// //           fontStyle: 'italic',
// //         }}
// //       >
// //         {restaurant?.restaurantName}
// //       </p>
// //       <hr className='restaurantHr' />
// //       <div className='item-details'>
// //         {restaurant?.itemDetails.map(
// //           (item) =>
// //             item.qunatity > 0 && <ItemDetails key={item.id} item={item} />
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// export default RestaurantOrder;



import React from 'react';
import ItemDetails from './ItemDetails';
import './ItemDetails.css';
import ShowOrderStatus from '../../CheckOut/ShowOrderStatus';

const RestaurantOrder = ({
  restaurant,
  onUpdateCart,
  onDeleteCart,
  cart,
  setCart,
  getItemDetails,
  refetchCartData,
}) => {
  const reloadingCartData = () => {
    refetchCartData();
  };

  return (
    <div className="p-2">
      
      <div
        className={`d-flex ${
          restaurant?.orderStatus !== null
            ? 'justify-content-between'
            : 'justify-content-center'
        }`}
      >
        <p
          className="restaurantName"
          style={{
            width: "100%",
            fontFamily: 'Poppins',
            margin: '0px auto',
          }}
        >
          <hr style={{ margin: "0.5rem auto" }} />
          {restaurant?.restaurantName}
          <hr style={{ margin: "0.5rem auto" }} />
        </p>
        {restaurant?.orderStatus && (
          <ShowOrderStatus orderStatus={restaurant?.orderStatus} />
        )}
      </div>
      

      {/* Loop through subOrders and itemDetails */}
      {restaurant.subOrders.map((subOrder) =>
        subOrder.itemDetails.map((item) => (
          <div key={item.foodItemId} className="item-container">
            <ItemDetails
              item={item}
              onUpdateCart={onUpdateCart}
              onDeleteCart={onDeleteCart}
              cart={cart} // Pass the cart and setCart as props to ItemDetails
              setCart={setCart}
              getItemDetails={getItemDetails}
              reloadingCart={() => reloadingCartData()}
            />
          
          </div>
        ))
      )}
    </div>
  );
};

export default RestaurantOrder;
