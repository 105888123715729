import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const AddressChangeModal = ({
  showModal,
  handleClose,
  changeAddress,
  userId,
}) => {
  const [newAddress, setNewAddress] = useState('');
  const [AddressTag, setAddressTag] = useState('');
  const [DeliveryNotes, setDeliveryNotes] = useState('');
  const [TagError, setTagError] = useState('');
  const [NotesError, setNotesError] = useState('');
  const [addressError, setAddressError] = useState('');

  const handleAddressChange = async () => {
    try {
      if (!newAddress.trim()) {
        setAddressError('Please enter a valid address.');
        return;
      }

      // Clear previous error message
      setAddressError('');
      setTagError('');
      // Create a new address object with the required fields
      const newAddressObject = {
        id: '',
        address: newAddress,
        userId: userId,
        isDefault: false, // Set to true if this is the default address
        isLastDeliveryAddress: true, // Set to true if this is the last delivery address
        creationDate: new Date().toISOString(),
        tag: AddressTag,
        noteForDeliveryMan: DeliveryNotes === "" ? 'N/A' : DeliveryNotes
      };

      // Make a POST request to save the new address to the database
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET}/api/DeliveryAddress`,
        newAddressObject
      );
      console.log('response data', response);
      // Update the address in the parent component

      if (response?.data) {
        changeAddress(response.data);
      }

      // Close the modal after successfully saving the address
      handleClose();
      setNewAddress('');
      setAddressTag('');
      setDeliveryNotes('');

      // Log the response from the API (optional)
      console.log('Address saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving address:', error);
      // Handle errors, e.g., show an error message to the user
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='formNewAddress'>
          <Form.Label>Address :</Form.Label>
          <Form.Control
            className='mb-2'
            type='text'
            required
            placeholder='Enter new address'
            value={newAddress}
            onChange={(e) => {
              setNewAddress(e.target.value);
              // Clear validation error when user starts typing
              setAddressError('');
            }}
          />
          <Form.Label>Tag :</Form.Label>
            <Form.Control
            className='mb-2'
            type='text'
            required
            placeholder='Enter Tag'
            value={AddressTag}
            onChange={(e) => {
              setAddressTag(e.target.value);
              // Clear validation error when user starts typing
              setTagError('');
            }}
          />
          
          <Form.Label>Notes : </Form.Label>
            <Form.Control
            className='mb-2'
            as="textarea" 
            rows={5}
            required
            placeholder='Enter Notes'
            value={DeliveryNotes}
            onChange={(e) => {
              setDeliveryNotes(e.target.value);
              // Clear validation error when user starts typing
              setNotesError('');
            }}       
          />
          {addressError && (
            <Form.Text className='text-danger'>{addressError}</Form.Text>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleClose}>
          Close
        </Button>
        <Button variant='dark' onClick={handleAddressChange}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressChangeModal;
