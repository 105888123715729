import React, { useRef, useState } from 'react';
import PostDisplay from '../PostDisplay';
import { useEffect } from 'react';
import { useMemo } from 'react';
import './AllpostDisplay.css';
import $ from 'jquery';
import AllPostDisplayComponent from '../Components/AllPostDisplayComponent';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
const AllPostDisplay = (props) => {
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const ref = useRef();
  const [currentScrollPosition, setCurrentScrollPosition] = useState(3500);
  useEffect(() => {
    if (props.lazyLoad != '1') {
      window.addEventListener('scroll', (event) => {
        timeout(10000);
        if (window.scrollY > currentScrollPosition) {
          setSeeMore(seeMore + 5);
          var sum = currentScrollPosition + 3500;
          setCurrentScrollPosition(sum);
        }
      });
    }
  });

  const [seeMore, setSeeMore] = useState(5);
  return (
    <div className='customContainer mt-2'>
      <div className='row'>
        <div className='col-0 col-md-3'></div>
        <div className='col-12 col-md-6'>
          <AllPostDisplayComponent />
        </div>
        <div className='col-0 col-md-3'></div>
      </div>
      {/* <div className='row loadingSpan' ref={ref} onClick={() => { setSeeMore(seeMore + 5) }}>
                <div className='col-12 d-flex justify-content-center'>
                    <span className='loadingSpan'>Loading more </span>
                </div>
            </div>
            <div className='row loadingSpan'>
                <div className='col-12'>
                    <span className=''></span>
                </div>
            </div> */}
    </div>
  );
};

// const getValue = (props) => {
//   let count = props;
//   let cards = [];
//   for (let index = 0; index < count; index++) {
//     cards.push(
//       <div>
//         <PostDisplay></PostDisplay>
//         <br></br>
//       </div>
//     );
//   }
//   return cards;
// };
// function timeout(delay) {
//   return new Promise((res) => setTimeout(res, delay));
// }
export default AllPostDisplay;
