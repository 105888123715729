import React, { useCallback, useRef, useState } from "react";
import "./CheckOut.css";
import Navbar from "../Navbar/Navbar";
import pkbImg from "./../../Assets/Image/aibnb2.webp";
import locationIcon from "./../../Assets/Icons/location-pin.png";
import standardShipping from "./../../Assets/Icons/standard-shipping.png";
import priorityShipping from "./../../Assets/Icons/delivered.png";
import onlinePayment from "./../../Assets/Icons/credit-cards-payment.png";
import CardDetails from "../Cart/Components/CardDetails/CardDetails";
import instruction from "./../../Assets/Icons/instructions.png";
import { useEffect } from "react";
import { Accordion, Card, Dropdown, ListGroup } from "react-bootstrap";
import PaymentOptions from "./PaymentOptions";
import AddressChangeModal from "./AddressChangeModal";
import EditIcon from "./../../Assets/Icons/icon-edit.png";
import DeleteIcon from "./../../Assets/Icons/icon-delete.png";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { router, useLocation, useNavigate, useParams } from "react-router-dom";
import AddressUpdateModal from "./AddressUpdateModal";
import PersonalDetails from "./PersonalDetails";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { ToastContainer, toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import { Tabs, Tab } from "react-bootstrap";
import PendingFood from "./PendingFood";
import ShowOrderStatus from "./ShowOrderStatus";
import RestaurantOrder from "../Cart/Components/RestaurantOrder";
import RestaurantOrderDetails from "./RestaurantOrderDetails/RestaurantOrderDetails";
import OrderDetailsModal from "./OrderDetailsModal/OrderDetailsModal";
import PendingFoodCard from "./PendingFoodCard";
import { getPercentageOrAmount } from "../../utils/getPercentageOrAmount";
import PendingOrderAccordion from "./components/PendingOrderAccordion"

const CheckOut = () => {
  const test3Ref = useRef(null);
  const [blur, setBlur] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [address, setAddress] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);

  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(false);
  const checkOutTopRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState(null);

  const [userId, setUserId] = useState();
  const [cartData, setCartData] = useState(null);

  const [deliveredItem, setDeliveredItem] = useState([]);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedAddressForUpdate, setSelectedAddressForUpdate] =
    useState(null);

  const [selectedAddressForDeletion, setSelectedAddressForDeletion] =
    useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [addressSelected, setAddressSelected] = useState(false);
  const [showAddressError, setShowAddressError] = useState(false);
  const [showCartDataError, setShowCartDataError] = useState(false);
  const [userCartData, setUserCartData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [flattenFoodItems, setFlattenFoodItems] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  console.log("restaurant pending: ", flattenFoodItems);
  const [isPlaceOrderButtonHide, setIsPlaceOrderButtonHide] = useState(false);

  // Place order button validtion for table selection
  useEffect(() => {
    setIsPlaceOrderButtonHide(false);
    const hasInStore = userCartData.some(item => 
      item.subOrders && item.subOrders.some(subOrder => subOrder.orderTypeName === 'In-Store')
    );

    if(hasInStore){
      setIsPlaceOrderButtonHide(true);
    }
  }, [selectedTable, userCartData]);  
  

  // Tips getting from child
  const [key, setKey] = useState("active");

  /* showing the pending tab automatically */
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const forPending = params.get("for");
  useEffect(() => {
    if (forPending === "pending") {
      setKey("pending");
    }
  }, [forPending]);

  const navigate = useNavigate();

  const { state } = useLocation();

  // const subtotalValue = parseFloat(subtotal);

  const handleShowAddressModal = () => setShowAddressModal(true);
  const handleCloseAddressModal = () => setShowAddressModal(false);

  const handleAddressSelect = (addressId) => {
    setSelectedAddressId(addressId);
    setAddressSelected(true);
    setShowAddressError(false);
  };

  const changeDeliveryAddress = async (address) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SECRET}/api/Cart/UpdateDeliveryAddress?customerId=${address.userId}&deliveryAddressId=${address.id}&deliveryAddressDetails=${address.address}`
      );

      if (response.data) {
        toast.success(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseUpdateModal = (address) => {
    setSelectedAddressForUpdate(address);
    setShowUpdateModal(true);
  };

  const handleButtonClick = (amount) => {
    setSelectedButton(amount);
  };

  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  const changeAddress = (newAddress) => {
    // Update the address in the parent component
    setAddress((prevAddresses) => [...prevAddresses, newAddress]);
  };

  const handleDeleteAddress = (id) => {
    const selectedAddress = address.find((a) => a.id === id);
    setSelectedAddressForDeletion(selectedAddress);
    setShowDeleteConfirmation(true);
  };

  // const handleDeleteAddress = async (id) => {
  //   try {
  //     // Replace 'your_api_endpoint' with the actual endpoint for deleting an address
  //     await axios.delete(
  //       `${process.env.REACT_APP_SECRET}/api/deliveryAddress/deleteDeliveryAddress/${id}`
  //     );

  //     // Update the state after successful deletion
  //     const updatedAddresses = address.filter((a) => a.id !== id);
  //     setAddress(updatedAddresses);
  //   } catch (error) {
  //     console.error('Error deleting address:', error);
  //     // Handle errors, e.g., show an error message to the user
  //   }
  // };

  const transformApiResponse = (apiResponse) => {
    // Extract the userId and restaurantId from the first subOrder
    const userId = apiResponse[0].customerId;

    // Initialize an empty array for restaurantIds
    const restaurantIds = [];

    // Loop through each subOrder to get the restaurantId and foodItemIds
    apiResponse.forEach((cart) => {
      const restaurantId = cart.cartRestaurantId;
      const foodItemIds = [];

      // Loop through subOrders to extract the foodItemId
      cart.subOrders.forEach((subOrder) => {
        subOrder.itemDetails.forEach((itemDetail) => {
          foodItemIds.push({ foodItemId: itemDetail.foodItemId });
        });
      });

      // Push the restaurantId and foodItemIds into the result array
      restaurantIds.push({
        restaurantId: restaurantId,
        foodItemIds: foodItemIds,
      });
    });

    // Return the final transformed data
    return {
      userId: userId,
      restaurantIds: restaurantIds,
    };
  };

  useEffect(() => {
    const userId = localStorage.getItem("gotasteClienttoken");
    const decoded = jwtDecode(userId);

    const getCartData = async (userId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET}/api/Cart/GetCartDataByUserId?userId=${userId}`
        );
        setUserCartData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCartData(decoded.UserId);
  }, []);

  useEffect(() => {
    const createDeliveryAndTableData = async (reqObj) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET}/api/Cart/InsertIntoDeliverAddressAndTable`,
          reqObj
        );
      } catch (error) {
        console.log(error);
      }
    };

    if (userCartData.length > 0) {
      const transformedData = transformApiResponse(userCartData);
      createDeliveryAndTableData(transformedData);
    }

    // setTotalServiceCharge(calculateTotalServiceChargeWithVAT(userCartData))
  }, [userCartData]);

  const handleConfirmDelete = async () => {
    // Handle the actual delete logic here
    await axios.delete(
      `${process.env.REACT_APP_SECRET}/api/deliveryAddress/deleteDeliveryAddress/${selectedAddressForDeletion.id}`
    );

    // Update the state after successful deletion
    const updatedAddresses = address.filter(
      (a) => a.id !== selectedAddressForDeletion.id
    );
    setAddress(updatedAddresses);

    // Close the confirmation modal
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const filterBarRect = checkOutTopRef?.current?.getBoundingClientRect();
      const isAboveViewport = filterBarRect?.top === 0 ? true : false;
      setNavbarOnTop(isAboveViewport);
      var top = filterBarRect?.top * -1;
      var scrollval = scrollValue * -1;
      if (top > scrollval) {
        setSlideUp(true);
      } else if (top <= 0) {
        setSlideUp(false);
      } else {
        setSlideUp(false);
      }

      var s = filterBarRect?.top;
      setscrollValue(s);
    });
  });

  let gotasteClienttoken = localStorage.getItem("gotasteClienttoken");

  useEffect(() => {
    let decoded = {};

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
        // console.log(decoded);
      } catch (error) {
        console.log(error);
      }
    }

    if (decoded != null) {
      setUserId(decoded.UserId);
      // getUserChatPreview(decoded.UserId);
    }
  }, [gotasteClienttoken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET}/api/Cart/GetCartDataByUserId?userId=${userId}`
          // `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
        );
        setCartData(response.data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
        // Handle errors, e.g., show an error message to the user
      }
    };

    // Check if userId is available before making the request
    if (userId) {
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const deliveredItemData = async () => {
      try {
        if (userId) {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET}/api/CArt/${userId}`
          );
          setDeliveredItem(response.data);
        }
      } catch (error) {
        console.log("error fetching delivered data: ", error);
      }
    };

    deliveredItemData();
  }, [userId]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET}/api/deliveryAddress/getDeliveryAddressByUserId/${userId}`
        );

        // Assuming the response.data is an array of addresses
        // Update your state with the fetched addresses
        setAddress(response.data);
        const defaultAddress = response.data.find(
          (add) => add.isDefault === true
        );
        setSelectedAddressId(defaultAddress.id);
      } catch (error) {
        console.error("Error fetching addresses:", error);
        // Handle errors, e.g., show an error message to the user
      }
    };

    // Call the fetchAddresses function when the component mounts
    fetchAddresses();
  }, [userId]); // Add userId to the dependency array to ensure the effect runs when userId changes

  const RefetchAddresses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET}/api/deliveryAddress/getDeliveryAddressByUserId/${userId}`
      );

      setAddress(response.data);
      const defaultAddress = response.data.find(
        (add) => add.isDefault === true
      );
      setSelectedAddressId(defaultAddress.id);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      // Handle errors, e.g., show an error message to the user
    }
  };

  // ...
  const handleUpdateDefaultAddress = async (id, userId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SECRET}/api/deliveryAddress/${id}?userId=${userId}`
      );

      if (response.data > 0) {
        RefetchAddresses();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [tips, setTips] = useState(0);

  const [totalServiceCharge, setTotalServiceCharge] = useState(0);
  console.log("total service charge 3", userCartData);

  // const updateServiceCharge = (newCharge) => {
  //   setTotalServiceCharge((prevTotal) => prevTotal + newCharge);
  // };

  useEffect(() => {
    const calculateServiceCharge = () => {
      let totalService = 0;

      if (userCartData.length > 0) {
        for (let i = 0; i < userCartData.length; i++) {
          for (let j = 0; j < userCartData[i].subOrders.length; j++) {
            for (let k = 0; k < userCartData[i].subOrders[j].itemDetails.length; k++) {
              const item = userCartData[i].subOrders[j].itemDetails[k]
              let individualSubTotal = 0;
              if (userCartData[i].subOrders[j].orderTypeName === "In-Store") {
                individualSubTotal += item.basePrice * item.foodItemQuantity;
              }

              for (let l = 0; l < item.addOns.length; l++) {
                if (userCartData[i].subOrders[j].orderTypeName === "In-Store") {
                  individualSubTotal += item.addOns[l].addOnBasePrice * item.addOns[l].addOnQuantity
                }

                for (let m = 0; m < item.addOns[l].options.length; m++) {
                  const option = item.addOns[l].options[m];
                  if (userCartData[i].subOrders[j].orderTypeName === "In-Store") {
                    individualSubTotal += option.optionBasePrice * option.optionQuantity
                  }

                  for (let n = 0; n < option.subOptions.length; n++) {
                    if (userCartData[i].subOrders[j].orderTypeName === "In-Store") {
                      individualSubTotal += option.subOptions[n].subOptionBasePrice * 1;
                    }
                  }
                }
              }
              
              const service = getPercentageOrAmount(individualSubTotal, userCartData[i].serviceCharge, "amount")
              totalService += service
            }
          }
        }
      }
      setTotalServiceCharge(totalService);
    }

    calculateServiceCharge();
  }, [userCartData])

  const [restaurantTips, setRestaurantTips] = useState({}); // Store tips for each restaurant
  const [totalTips, setTotalTips] = useState(0);

  const calculateTotalTips = (tips) => {
    const total = Object.values(tips).reduce((acc, curr) => acc + curr, 0);
    setTotalTips(total);
  };

  const handleSetTip = (restaurantId, tip, type) => {
    if (type === "amount") {
      setRestaurantTips((prevTips) => {
        const updatedTips = { ...prevTips, [restaurantId]: tip };
        calculateTotalTips(updatedTips); // Recalculate total tips whenever a tip is updated
        return updatedTips;
      });
    } else if (type === "percentage") {
      let total =
        subTotal + totalSD + totalVAT + totalServiceCharge + deliveryCharge;
      total = total.toFixed(2);
      const tipAmount = getPercentageOrAmount(total, tip, "amount");
      console.log("percentage total", total, tipAmount);

      setRestaurantTips((prevTips) => {
        const updatedTips = { ...prevTips, [restaurantId]: tipAmount };
        calculateTotalTips(updatedTips); // Recalculate total tips whenever a tip is updated
        return updatedTips;
      });
    } else {
      throw new Error(
        "Invalid type for Tip! Type can be either amount or percentage"
      );
    }
  };

  const onSubmit = async () => {
    // Validation for table selection
    if(isPlaceOrderButtonHide){
      if(selectedTable === null || selectedTable.length === 0){
        toast.info("You have to select a table for instore order");
        return;
      }
    }

    if (userCartData.length > 0) {
      const reqBody = userCartData.map((restaurant) => ({
        cartId: restaurant.cartId,
        restaurantId: restaurant.cartRestaurantId,
        tip: restaurantTips[restaurant.cartRestaurantId] || 0, // Use the tip for each restaurant, default to 0 if not set
      }));
      console.log("order details 2: ", reqBody);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET}/api/Order/ConfirmOrders`,
          reqBody
        );
        console.log("order details 3: ", response);
        if (response.data) {
          toast.success(response.data);
          navigate("/confirmationpage");
        }
      } catch (error) {
        console.log("order details 4: ", error);
        toast.error("Error placing order. Please try again.");
      }
    }
    // if (addressSelected && cartData?.length > 0) {
    //   // Step 3
    //   setShowAddressError(false);
    //   const finalData = cartData.map((item) => ({
    //     id: '',
    //     cartRestaurantMapperId: item.resturantMapperId,
    //     userId: userId,
    //     statusId: 1,
    //     isActive: true,
    //     deliveryAddressId: selectedAddressId,
    //   }));

    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_SECRET}/api/Order`,
    //       finalData
    //     );

    //     // Handle success
    //     if (response) {
    //       toast.success('Order placed successfully');
    //       navigate('/confirmationpage');
    //     }
    //   } catch (error) {
    //     // Handle error
    //     console.error('Error placing order:', error);
    //     toast.error('Error placing order. Please try again.');
    //   }
    // } else {
    //   // Display error message
    //   if (!addressSelected) {
    //     setShowAddressError(true);
    //   }
    //   if (cartData.length === 0) {
    //     setShowCartDataError(true);
    //   }
    //   toast.error('Something went wrong!');
    // }
  };

  const filteredCartData = cartData?.filter((item) => item?.isOrdered === true);

  const CalculateItemTotalPrice = (item) => {
    let totalPrice = item.itemBasePrice * item.qunatity;

    // check if there is any options
    if (item.options && item.options.length > 0) {
      item.options.forEach((option) => {
        totalPrice += option.basePrice * option.quantity;

        // check if there is any subOptions
        if (option.suboptions && option.suboptions.length > 0) {
          option.suboptions.forEach((suboption) => {
            totalPrice += suboption.price * suboption.quantity;
          });
        }
      });
    }

    return totalPrice.toFixed(2);
  };

  const AddAdditionalCost = (totalCost, item) => {
    var vat, deliveryCharge, platformCharge;
    vat = deliveryCharge = platformCharge = 1.5;

    totalCost += vat * item.qunatity;
    totalCost += deliveryCharge;
    totalCost += platformCharge;

    return totalCost;
  };

  const CalculateTotalPrice = (restaurantData) => {
    const { itemDetails } = restaurantData;

    let subTotal = 0;
    let totalPrice = 0;

    itemDetails.forEach((item) => {
      subTotal += CalculateItemTotalPrice(item);
      totalPrice += AddAdditionalCost(subTotal, item);
    });

    return { subTotal, totalPrice };
  };
  const [subTotal, setSubTotal] = useState(0);
  const [totalSD, setTotalSD] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const baseDeliveryCharge = 20;

  const countOnlineRestaurants = userCartData?.reduce((count, restaurant) => {
    const hasOnlineOrder = restaurant.subOrders.some(
      (subOrder) => subOrder.orderTypeName === "Online"
    );
    return hasOnlineOrder ? count + 1 : count;
  }, 0);

  const deliveryCharge = baseDeliveryCharge * countOnlineRestaurants;

  /* Calculating Total of SubOrders */

  useEffect(() => {
    const calculateTotalBasePrice = () => {
      let subTotal = 0;
      let totalVat = 0;
      let totalSd = 0;
      cartData?.forEach((data) => {
        data.subOrders?.forEach((subOrder) => {
          subOrder.itemDetails.forEach((item) => {
            subTotal += item.basePrice * item.foodItemQuantity;
            totalVat += item.vatInAmount * item.foodItemQuantity;
            totalSd += item.sdInAmount * item.foodItemQuantity;
            // Calculate add-ons
            (item.addOns || []).forEach((addOn) => {
              subTotal += addOn.addOnBasePrice * addOn.addOnQuantity;
              totalVat += addOn.addOnVatAmount * addOn.addOnQuantity;
              totalSd += addOn.addOnSdAmount * addOn.addOnQuantity;

              // Calculate options for each add-on
              (addOn.options || []).forEach((option) => {
                subTotal += option.optionBasePrice * option.optionQuantity;
                totalVat += option.optionVatAmount * option.optionQuantity;
                totalSd += option.optionSdAmount * option.optionQuantity;

                // Calculate sub-options for each option
                (option.subOptions || []).forEach((subOption) => {
                  subTotal += subOption.subOptionBasePrice;
                  totalVat += subOption.subOptionVatAmount;
                  totalSd += subOption.subOptionSdAmount;
                });
              });
            });
          });
        });
      });

      setTotalSD(totalSd);
      setTotalVAT(totalVat);
      setSubTotal(subTotal);
    };
    calculateTotalBasePrice();
  }, [cartData]);

  const fetchPendingOrders = useCallback(async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SECRET}/api/Order/GetPendingOrderDataByUserId?userId=${userId}`
      );
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("gotasteClienttoken");

    if (token) {
      const userId = jwtDecode(token).UserId;
      fetchPendingOrders(userId);
    }
  }, [fetchPendingOrders]);

  useEffect(() => {
    const flattenArray = (data) => {
      const itemDetails = data.flatMap((order) =>
        order.subOrders.flatMap((subOrder) => subOrder.itemDetails)
      );

      setFlattenFoodItems(itemDetails);
    };

    if (orderData.length > 0) {
      flattenArray(orderData);
    }
  }, [orderData]);

  return (
    <div>
      <ToastContainer />
      <Navbar handleNavbar={searchDialogOpen} />
      <div ref={checkOutTopRef}></div>
      <div className={`${blur ? "BackBlur" : ""}`}>
        <div className="container checkout-container">
          <div>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="active" title="ACTIVE">
                <div className="row">
                  <div className="col-md-6">
                    {/* <div className='col-12'>
                                <div className='d-flex align-items-center restaurantNameDisplay'>
                                    <img src={pkbImg} className='checkOutRestaurantImage' alt='res' />
                                    <h4 className='restaurantName'>Pot kettle black</h4>
                                </div>
                            </div> */}
                    <div className="col-12">
                      <div>
                        <div className="checkOutCard">
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <h4 className="fw-bold pb-4">
                                    Delivery Address
                                  </h4>
                                </div>
                                <div className="d-flex align-items-start justify-content-end">
                                  <button
                                    className="btn text-danger"
                                    onClick={handleShowAddressModal}
                                  >
                                    + Add address
                                  </button>
                                </div>
                              </div>

                              <div className="deliveryDetails">
                                <div className="d-flex locaionDetails">
                                  {/* <div className='d-flex align-items-start'>
                              <img
                                src={locationIcon}
                                alt=''
                                style={{ width: '1.5rem' }}
                              />
                            </div> */}
                                  <div className="d-flex flex-column addressCheckOut">
                                    {address.length !== 0 ? (
                                      <h5 className="text-black-50">
                                        Saved Addresses
                                      </h5>
                                    ) : (
                                      <h5 className="text-black-50">
                                        Enter New Address
                                      </h5>
                                    )}
                                    <div className="AddressCard">
                                      <div className="scrollable-addresses">
                                        <div
                                          className="scrollable-container"
                                          style={{
                                            maxHeight: "300px",

                                            // overflowY: 'auto',
                                          }}
                                        >
                                          {address?.map((address, index) => (
                                            <div
                                              key={index}
                                              style={{
                                                margin: "0",
                                                border:
                                                  selectedAddressId ===
                                                  address.id
                                                    ? // address.isDefault
                                                      "2px solid black"
                                                    : "none",
                                              }}
                                              className="checkOutCard m-2 d-flex"
                                              onClick={() => {
                                                handleAddressSelect(address.id);
                                                changeDeliveryAddress(address);
                                              }}
                                            >
                                              <div>
                                                <img
                                                  src={locationIcon}
                                                  alt=""
                                                  style={{ width: "1.5rem" }}
                                                />
                                              </div>
                                              <div className="ps-2">
                                                {/* <div>
                                                    <h5>Work</h5>
                                                  </div> */}
                                                <div>
                                                  <h6>{address.address}</h6>
                                                </div>
                                                <div>
                                                  <h5>
                                                    <Badge pill bg="info">
                                                      {address.tag}
                                                    </Badge>
                                                  </h5>
                                                </div>
                                                <div className="mt-2">
                                                  <p>
                                                    Note to rider:{" "}
                                                    {address.noteForDeliveryMan}
                                                  </p>
                                                </div>
                                              </div>
                                              <div
                                                className="d-flex flex-row gap-2"
                                                style={{ marginLeft: "auto" }}
                                              >
                                                <div
                                                  className="flex-grow-1"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseUpdateModal(
                                                      address
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={EditIcon}
                                                    alt=""
                                                    style={{ width: "1.4rem" }}
                                                  />
                                                </div>
                                                <div
                                                  className="flex-grow-1"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteAddress(
                                                      address.id
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={DeleteIcon}
                                                    alt=""
                                                    style={{ width: "1.5rem" }}
                                                  />
                                                </div>
                                                {!address.isDefault && (
                                                  <Dropdown className="flex-grow-1 ">
                                                    <Dropdown.Toggle
                                                      variant="success"
                                                      id="dropdown-basic"
                                                      className="d-flex bg-transparent border-0"
                                                    >
                                                      <div className="dot"></div>
                                                      <div className="dot"></div>
                                                      <div className="dot"></div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleUpdateDefaultAddress(
                                                            address.id,
                                                            address.userId
                                                          )
                                                        }
                                                      >
                                                        Set as default
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                          {showAddressError && (
                                            <p className="text-danger ps-2 pt-2">
                                              Please select or provide an
                                              address
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {/* <h5 style={{ padding: '0', margin: '0' }}>
                                 
                                </h5>
                                <h6
                                  className="city"
                                  style={{ padding: "0", margin: "0" }}
                                >
                                  UK
                                </h6> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <DeleteConfirmationModal
                          showModal={showDeleteConfirmation}
                          handleClose={() => setShowDeleteConfirmation(false)}
                          handleConfirmDelete={handleConfirmDelete}
                        />
                        <AddressChangeModal
                          showModal={showAddressModal}
                          handleClose={handleCloseAddressModal}
                          changeAddress={changeAddress}
                          userId={userId}
                        />
                        <AddressUpdateModal
                          showModal={showUpdateModal}
                          handleClose={() => setShowUpdateModal(false)}
                          // setAddress={setAddress}
                          addressData={selectedAddressForUpdate}
                          handleUpdate={(updatedAddress) => {
                            // Handle the updated address data here, e.g., update the state
                            const updatedAddresses = address.map((a) =>
                              a.id === updatedAddress.id ? updatedAddress : a
                            );
                            setAddress(updatedAddresses);
                          }}
                        />
                      </div>

                      <br />
                      <div className="checkOutCard">
                        <PersonalDetails />
                      </div>

                      <br />

                      {/* <div className='checkOutCard'>
                      <h4 className='DeliveryHeader'>Coupon</h4>
                      <div className='row'>
                        <div className='col-8'>
                          <div className='deliveryDetails'>
                            <div className='d-flex locaionDetails'>
                              <div className='d-flex flex-column addressCheckOut'>
                                <div>
                                  <input
                                    type='text'
                                    placeholder='Enter voucher code'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-4 d-flex align-items-start justify-content-end'>
                          <button className='btn btn-primary'>Apply</button>
                        </div>
                      </div>
                    </div> */}

                      {/* <br /> */}
                      <div className="checkOutCard">
                        <h4 className="DeliveryHeader">Payment</h4>
                        <PaymentOptions />
                        {/* <h4 className='DeliveryHeader'>Payment</h4>

                  <div className='deliveryDetails  '>
                    <div className='d-flex locaionDetails'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={onlinePayment}
                          alt=''
                          style={{ width: '1.5rem' }}
                        />
                      </div>
                      <div className='d-flex addressCheckOut justify-content-center'>
                        <h5>Online</h5>
                      </div>
                    </div>
                    <div className='d-flex'> 
                      <button className='btn'> Edit</button>
                    </div>
                  </div> */}
                      </div>

                      {/* <br /> */}

                      {/* <div className='checkOutCard'>
                  <h4 className='DeliveryHeader'>Tip your rider</h4>
                  <div className='deliveryDetails'>
                    <p>Your rider receives 100% of the tip</p>
                  </div>
                  <div className='d-flex'>
                    <button
                      className={`btn ${
                        selectedButton === '£5.00' ? 'btn-primary' : 'btn-light'
                      } rounded me-2`}
                      onClick={() => handleButtonClick('£5.00')}
                    >
                      £5.00
                    </button>
                    <button
                      className={`btn ${
                        selectedButton === '£15.00'
                          ? 'btn-primary'
                          : 'btn-light'
                      } rounded me-2`}
                      onClick={() => handleButtonClick('£15.00')}
                    >
                      £15.00
                    </button>
                    <button
                      className={`btn ${
                        selectedButton === '£10.00'
                          ? 'btn-primary'
                          : 'btn-light'
                      } rounded me-2`}
                      onClick={() => handleButtonClick('£10.00')}
                    >
                      £10.00
                    </button>
                    <button
                      className={`btn ${
                        selectedButton === '£20.00'
                          ? 'btn-primary'
                          : 'btn-light'
                      } rounded me-2`}
                      onClick={() => handleButtonClick('£20.00')}
                    >
                      £20.00
                    </button>
                    <button
                      className={`btn ${
                        selectedButton === '£25.00'
                          ? 'btn-primary'
                          : 'btn-light'
                      } rounded`}
                      onClick={() => handleButtonClick('£25.00')}
                    >
                      £25.00
                    </button>
                  </div>
                  </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sticky-position">
                      <div className="col-12">
                        <div className="checkOutCard shadow-none px-2 sticky-position">
                          <h4 className="DeliveryHeader">Your order from</h4>
                          {/* {
                            cartData?.map((restaurant) => <RestaurantOrderDetails key={restaurant.restaurantId} restaurant={restaurant} />)
                          } */}
                          {userCartData?.map((restaurant) => (
                            <div>
                              <RestaurantOrderDetails
                                key={restaurant.cartRestaurantId}
                                restaurant={restaurant}
                                handleSetTip={handleSetTip}
                                slectedTableHistroyForValidation={setSelectedTable}
                                tips={
                                  restaurantTips[restaurant.cartRestaurantId] ||
                                  0
                                }
                                // onUpdateServiceCharge={updateServiceCharge}
                              />
                            </div>
                          ))}
                          <div>
                            {/* <div className='order-container'>
                            {cartData?.map((restaurant) => {
                              return (
                                <div key={restaurant.restaurantId}>
                                  <div className='orderDetails ms-3'>
                                    <h5>
                                      <b>{restaurant.restaurantName}</b>
                                    </h5>
                                  </div>
                                  {restaurant?.itemDetails?.map((item) => {
                                    return (
                                      <div className='pb-2 me-3'>
                                        <div key={item?.itemId}>
                                          <div className='d-flex justify-content-between ms-3 orderDetails'>
                                            <h5>
                                              {item.qunatity} x {item.itemName}
                                            </h5>
                                            <h5>£{item.itemBasePrice}</h5>
                                          </div>
                                        </div>
                                        <div className='ps-5 text-black-50'>
                                          {item?.options.map((option) => {
                                            return (
                                              option.quantity > 0 && (
                                                <h6 key={option.id}>
                                                  +{option.optionName} x
                                                  {option.quantity}
                                                  <span>
                                                    {' '}
                                                    £{option.unitPrice}{' '}
                                                  </span>
                                                  {option?.suboptions.map(
                                                    (suboption) => {
                                                      return (
                                                        suboption.quantity >
                                                          0 && (
                                                          <span
                                                            key={suboption.id}
                                                          >
                                                            (
                                                            {
                                                              suboption.suboptionName
                                                            }{' '}
                                                            x
                                                            {suboption.quantity}{' '}
                                                            £
                                                            {
                                                              suboption.unitPrice
                                                            }
                                                            )
                                                          </span>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                  <br />
                                                </h6>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <hr />
                                  <div className='me-3 extra-charges'>
                                    <div className='d-flex justify-content-between ms-3 orderDetails'>
                                      <h5>SD</h5>
                                      <h5>£4</h5>
                                    </div>

                                    <div className='d-flex justify-content-between ms-3 orderDetails'>
                                      <h5>VAT</h5>
                                      <h5>£4</h5>
                                    </div>

                                    <div className='d-flex justify-content-between ms-3 orderDetails'>
                                      <h5>TOTAL</h5>
                                      <h5>£4</h5>
                                    </div>
                                  </div>

                                  <hr></hr>
                                </div>
                              );
                            })}
                          </div> */}

                            {/* <div className='d-flex justify-content-between ms-3 orderDetails'>
                            <h5>Subtotal</h5>
                            <h5>£{state?.subtotal}</h5>
                          </div> */}
                          </div>

                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>SubTotal</h5>
                            <h5>£{subTotal.toFixed(2)}</h5>
                          </div>
                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Total VAT Inc. SD</h5>
                            <h5
                              className="text-primary text-decoration-underline text-cursor-pointer"
                              onClick={() => setModalShow(true)}
                            >
                              £{((totalSD + totalVAT)+totalServiceCharge*0.2).toFixed(2)}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Total Service Charge Exc. VAT</h5>
                            <h5
                              className="text-primary text-decoration-underline text-cursor-pointer"
                              onClick={() => setModalShow(true)}
                            >
                              £{totalServiceCharge.toFixed(2)}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Total Tips</h5>
                            <h5>£{totalTips}</h5>
                          </div>
                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Delivery Fee Inc. VAT.(20%)</h5>
                            <h5>£{deliveryCharge.toFixed(2)}</h5>
                          </div>

                          <OrderDetailsModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            userCartData={userCartData}
                            totalServiceCharge={totalServiceCharge}
                            deliveryCharge={deliveryCharge}
                          />
                          {/* <div className="d-flex justify-content-between ms-3 orderDetails">
                      <h5>SC</h5>
                      <h5>£1.50</h5>
                    </div> */}
                          {/* <div className='d-flex justify-content-between ms-3 orderDetails'>
                            <h5>VAT</h5>
                            <h5>£1.50</h5>
                          </div> */}
                          {/* <div className='d-flex justify-content-between ms-3 orderDetails'>
                            <h5>Delivery Fee</h5>
                            <h5>£1.50</h5>
                          </div> */}
                          {/* <div className='d-flex justify-content-between ms-3 orderDetails'>
                            <h5>Platform Fee</h5>
                            <h5>£1.50</h5>
                          </div> */}
                          {showCartDataError && (
                            <p className="text-danger ps-2">
                              Please add items to your cart
                            </p>
                          )}
                          {/* <hr></hr> */}
                          <div className="d-flex justify-content-between ms-3">
                            <h5 className="fs-4 fw-bold">Total Price</h5>
                            <h5 className="fs-4 fw-bold">
                              £
                              {(
                                subTotal +
                                totalSD +
                                totalVAT+ 
                                totalServiceCharge*0.2+
                                totalServiceCharge +
                                totalTips +
                                deliveryCharge
                              ).toFixed(2)}
                            </h5>
                          </div>
                          <div className="d-flex ms-3 pb-2">
                            <span className="text-muted">(Incl. VAT)</span>
                          </div>
                          <div
                            className="d-flex justify-content-between ms-3 orderDetails warning-allergies"
                            style={{ textAlign: "justify" }}
                          >
                            <p>
                              ALLERGIES: If you or someone you’re ordering for
                              has an allergy, please contact the merchant
                              directly to let them know. If you’re not around
                              when the delivery person arrives, they’ll leave
                              your order at the door. By placing your order, you
                              agree to take full responsibility for it once it’s
                              delivered. Orders containing alcohol or other
                              restricted items may not be eligible for leave at
                              door and will be returned to the store if you are
                              not available. Whilst we, and our restaurant
                              partners, have safety measures to mitigate food
                              safety risk, couriers may be delivering more than
                              one order so we cannot eliminate the risk of
                              cross-contamination from allergens.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={`d-flex align-items-center restaurantNameDisplay btnCheckOut ${
                            slideUp
                              ? "btnCheckOutMarginTopZero "
                              : "btnCheckOutMarginTop"
                          }`}
                            onClick={onSubmit}
                          >
                          <button
                            className="btn btn-dark w-100 btn-placeOrder"
                            data-mdb-ripple-init
                          >
                            Place Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="pending" title="PENDING" className="mb-5">
                <div className="grid-container">
                  {filteredCartData?.map((item) => (
                    <PendingFood key={item.restaurantId} restaurant={item} />
                  ))}

                  {
                    // orderData?.map((order, idx) => <PendingFood key={idx} restaurant={order} flattenFoodItems={flattenFoodItems} />)
                  }

                  {flattenFoodItems?.map((item, idx) => {
                    // Find the corresponding restaurant in orderData
                    const restaurant = orderData.find(
                      (order) => order.cartRestaurantId === item.restaurantId
                    );

                    return (
                      <PendingFoodCard
                        key={idx}
                        foodItem={item}
                        restaurant={restaurant}
                      />
                    );
                  })}
                </div>
               
             
                {/* <PendingOrderAccordion /> */}
      
              </Tab>

              <Tab eventKey="history" title="HISTORY">
                {deliveredItem?.map((restaurant, index) => (
                  <Accordion
                    className="my-3"
                    key={restaurant.restaurantId}
                    defaultActiveKey={null}
                  >
                    <Accordion.Item eventKey={`${index}`}>
                      <Accordion.Header>
                        <div className="d-flex align-items-center">
                          <div className="restaurant-logo-container">
                            <img
                              src={restaurant?.logoUrl}
                              alt={restaurant?.restaurantName}
                            />
                          </div>
                          <div>{restaurant?.restaurantName}</div>
                        </div>
                        <div>-{CalculateTotalPrice(restaurant).totalPrice}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {restaurant?.itemDetails?.map((item) => (
                            <div
                              key={item.itemId}
                              className="pending-food-card"
                            >
                              <div className="restaurant-img-container">
                                <img
                                  src={item?.itemImage}
                                  alt={item?.itemName}
                                />
                              </div>

                              <div className="ms-2">
                                <div>
                                  <div className="fs-4 fw-bold">
                                    {item?.itemName}
                                  </div>

                                  {item?.options.map((option) => {
                                    return (
                                      option.quantity > 0 && (
                                        <h6
                                          key={option.id}
                                          className="sub-options my-2"
                                        >
                                          +{option.optionName} x
                                          {option.quantity}
                                          <span> £{option.unitPrice} </span>
                                          {option?.suboptions.map(
                                            (suboption) => {
                                              return (
                                                suboption.quantity > 0 && (
                                                  <span key={suboption.id}>
                                                    ({suboption.suboptionName} x
                                                    {suboption.quantity} £
                                                    {suboption.unitPrice})
                                                  </span>
                                                )
                                              );
                                            }
                                          )}
                                          <br />
                                        </h6>
                                      )
                                    );
                                  })}
                                </div>

                                <div className="history-quantity my-2">
                                  Qty: {item.qunatity}
                                </div>

                                <div>
                                  {restaurant?.orderStatus && (
                                    <ShowOrderStatus
                                      orderStatus={restaurant?.orderStatus}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="price-container">
                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Subtotal</h5>
                            <h5>£{CalculateTotalPrice(restaurant).subTotal}</h5>
                          </div>

                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>VAT</h5>
                            <h5>£1.50</h5>
                          </div>

                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Delivery Fee</h5>
                            <h5>£1.50</h5>
                          </div>

                          <div className="d-flex justify-content-between ms-3 orderDetails">
                            <h5>Platform Fee</h5>
                            <h5>£1.50</h5>
                          </div>

                          <hr></hr>
                          <div className="d-flex justify-content-between ms-3">
                            <h5 className="fs-4 fw-bold">Total</h5>
                            <h5 className="fs-4 fw-bold">
                              £{CalculateTotalPrice(restaurant).totalPrice}
                            </h5>
                          </div>

                          <div className="d-flex ms-3 pb-2">
                            <span className="text-muted">(Incl. VAT)</span>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
