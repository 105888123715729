import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import FoodModalUpdate from './FoodModalUpdate/FoodModalUpdate';
import './CardDetails/CardDetails.css';
import bin from '../../../Assets/Icons/recycle-bin.png';
//import edit from '../../../Assets/Icons/editPencil.png';
import edit from '../../../Assets/Icons/edit.png';
import './ItemDetails.css';
import { Skeleton } from 'antd';
import { getPercentageOrAmount } from '../../../utils/getPercentageOrAmount';
import { sliceText } from '../../../utils/sliceText';
import { useDispatch } from 'react-redux';
import { decreaseItemCount } from '../../../reducers/cartCounterReducer';


const ItemDetails = ({
  item,
  onUpdateCart,
  onDeleteCart,
  cart,
  setCart,
  getItemDetails,
  reloadingCart
}) => {
  const [quantity, setQuantity] = useState(item.foodItemQuantity);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const calculateTotalPrice = () => {
      let totalValue = (item.basePrice + item.vatInAmount + item.sdInAmount) * item.foodItemQuantity
      let totalWithoutVat = item.basePrice * item.foodItemQuantity;
      for (let i = 0; i < item.addOns.length; i++) {
        const addOnPrice = item.addOns[i].addOnBasePrice * item.addOns[i].addOnQuantity;
        totalValue += addOnPrice;
        totalWithoutVat += addOnPrice

        for (let j = 0; j < item.addOns[i].options.length; j++) {
          const optionPrice = item.addOns[i].options[j].optionBasePrice * item.addOns[i].options[j].optionQuantity
          totalValue += optionPrice;
          totalWithoutVat += optionPrice

          for (let k = 0; k < item.addOns[i].options[j].subOptions.length; k++) {
            const subOptionPrice = item.addOns[i].options[j].subOptions[k].subOptionBasePrice * 1;
            totalValue += subOptionPrice;
            totalWithoutVat += subOptionPrice
          }
        }
      }

      setSubTotal(totalWithoutVat);
      setTotal(totalValue.toFixed(2))
    }

    calculateTotalPrice();
  }, [item]);

  // useEffect(() => {
  //   const calculateServiceCharge = () => {
  //     if (cart.length > 0) {
  //       for (let i = 0; i < cart.length; i++) {
  //         for (let j = 0; j < cart[i].subOrders.length; j++) {
  //           if (cart[i].subOrders[j].orderTypeName === "In-Store") {
  //             const service = getPercentageOrAmount(subTotal, cart[i].serviceCharge, "amount");
  //             // console.log("new cart fadsa: ", subTotal, cart[i].serviceCharge, service);
  //             updateServiceCharge(service);
  //           } else {
  //             updateServiceCharge(0)
  //           }
  //         }
  //       }
  //     }
  //   }

  //   calculateServiceCharge();
  // }, [cart, subTotal]);

  // const handleDecrease = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1);
  //   }
  // };

  // const handleIncrease = () => {
  //   setQuantity(quantity + 1);
  // };
  // console.log("data from cart item details: ", cart)
  // console.log("data for item details: ", item)

  // const handleQuantityChange = (qunatity) => {
  //   // Update the item quantity in the cart

  //   const updatedCart = cart.map((restaurant) => {
  //     if (restaurant.resturantMapperId === item.cartRestaurantMapperId) {
  //       const updatedItems = restaurant.itemDetails.map((cartItem) => {
  //         if (cartItem.id === item.id) {
  //           return { ...cartItem, qunatity };
  //         }
  //         return cartItem;
  //       });
  //       return { ...restaurant, itemDetails: updatedItems };
  //     }
  //     return restaurant;
  //   });

  //   // Update the cart state
  //   setCart(updatedCart);

  //   // Call the parent component's function to update the cart in the API
  //   onUpdateCart(updatedCart);
  // };

  const updateFoodQuantity = useCallback(async () => {
    if (item.cartItemId && quantity) {
      try {
        const response = await axios.patch(`${process.env.REACT_APP_SECRET}/api/Cart/UpdateFoodItemQuantity/${item.cartItemId}?quantity=${quantity}`);

      } catch (error) {
        console.log(error);
      }
    }
  }, [item.cartItemId, quantity]);

  useEffect(() => {
    const updatedItem = setTimeout(updateFoodQuantity, 300);

    return () => clearTimeout(updatedItem);
  }, [updateFoodQuantity])

  const handleDeleteItem = async (item) => {
    const url = `${process.env.REACT_APP_SECRET}/api/Cart/DeleteFoodItem`;
    const params = {
      foodItemId: item.foodItemId,
      subOrderId: item.subOrderId,
      restaurantId: item.restaurantId,
      cartFoodItemId: item.cartItemId,
      orderTypeId: item.orderTypeId,
      orderId: item.orderId
    };

    // Show SweetAlert confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(url, { params });
        if(response.data){
          dispatch(decreaseItemCount(1));
        }
      } catch (error) {
        Swal.fire(
          'Error!',
          'There was a problem deleting your item.',
          'error'
        );
      }
      reloadingCart();
    }
  };



  //   const handleDeleteItem = async (item) => {
  //   // // Remove the item from the cart
  //   // let id = itemId;
  //   // const updatedCart = cart.map((restaurant) => {
  //   //   if (restaurant.resturantMapperId === item.cartRestaurantMapperId) {
  //   //     const updatedItems = restaurant.itemDetails.filter(
  //   //       (cartItem) => cartItem.id !== item.id
  //   //     );
  //   //     return { ...restaurant, itemDetails: updatedItems };
  //   //   }
  //   //   return restaurant;
  //   // });

  //   // // Update the cart state
  //   // setCart(updatedCart);

  //   // // Call the parent component's function to update the cart in the API
  //   // onDeleteCart(id);
  // };

  return item !== null ? (
    <div>
      <div
        className='d-flex mt-3 cartdetailsGrid justify-content-between'
        style={{ cursor: 'pointer', padding: '0 14px' }}
      >
        <div
          className='d-flex itemDetailsAndImage'
          onClick={() => getItemDetails(item)}
        >
          <div className="cart-item-image">
            <img src={item?.foodItemImage} alt='cart food item' />
          </div>
          <div className='d-flex productDetails justify-content-center ps-2 pb-2'>
            <h5 className="large-tooltips" data-title={item?.foodItemName}>{sliceText(item?.foodItemName, 17)} x{item?.foodItemQuantity} {item.addOns.length > 0 ? <span className="fooditem-price">£{item.basePrice * item?.foodItemQuantity}</span> : ""}</h5>
            {item?.addOns?.map((addOn) => {
              return (
                addOn?.addOnQuantity > 0 && (
                  <h6 key={addOn.addOnId} className='addOn'>
                    {addOn.addOnName} x{addOn.addOnQuantity}
                    <span> £{addOn.addOnBasePrice} </span>
                    <br />
                  </h6>
                )
              )
            })}
            {item?.addOns?.map((addOn) => addOn.options.map(option => {
              return (
                option?.optionQuantity > 0 && (
                  <h6 key={option.optionId} style={{ marginLeft: "1rem" }} className='option'>
                    {option.optionName} x{option.optionQuantity}
                    <span> £{option.optionBasePrice} </span>
                    {/* Display other option details as needed */}
                    {/* Suboptions */}
                    {option?.subOptions?.map((suboption) => {
                      return (
                        <span
                          className='span-text suboption'
                          key={suboption.orderSubOptionId}
                        >
                          ({suboption.subOptionName} x
                          {1} £
                          {suboption.subOptionBasePrice})
                        </span>
                      );
                    })}
                    <br />
                  </h6>
                )
              );
            }))}

            {/* <div className='addOnCart'>

                </div> */}
            {/* <div className='options addOnCart'>
            {item?.options?.map((option) => {
              return (
                option?.quantity > 0 && (
                  <h6 key={option.id} >
                    {option.optionName} x{option.quantity}
                    <span> £{option.unitPrice} </span>
                    
                    {option?.suboptions?.map((suboption) => {
                      return (
                        suboption.quantity > 0 && (
                          <span className='span-text' key={suboption.id}>
                            ({suboption.suboptionName} x{suboption.quantity} £
                            {suboption.unitPrice})
                          </span>
                        )
                      );
                    })}
                    <br />
                  </h6>
                )
              );
            })}
          </div> */}
          </div>
        </div>
        <div className='d-flex flex-column itemIcon'>
          <div className='d-flex  price-Div'>
            <p className='price d-flex justify-content-end'>
              £{total}
            </p>
            {/* <div className='d-flex align-items-center quantity'>
            <span
              className='plus-icon'
              onClick={() => setQuantity((prev) => prev > 1 ? prev - 1 : 1)}
            >
              -
            </span>
            {quantity}
            <span
              className='plus-icon'
              onClick={() => setQuantity((prev) => prev + 1)}
            >
              +
            </span>
          </div> */}
          </div>
          <div className='d-flex justify-content-end'>
            <span style={{ marginRight: '1rem' }}>
              <img
                src={edit}
                alt='edit'
                style={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() => getItemDetails(item)}
              />
            </span>

            <span>
              <img
                src={bin}
                alt=''
                style={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() => handleDeleteItem(item)}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='d-flex mt-3 cartdetailsGrid justify-content-between'>
      <div className='d-flex itemDetailsAndImage'>
        <Skeleton.Avatar
          size={120}
          shape='square'
          style={{
            marginRight: '16px',
            marginLeft: '4px',
            borderRadius: '10px',
          }}
        />
        <div className='d-flex productDetails justify-content-center ps-4 pb-2'>
          <h5>
            <Skeleton.Input style={{ width: '150px' }} active />
          </h5>
          <div className='options addOnCart'>
            <h6>
              <Skeleton.Input style={{ width: '100px' }} active />
            </h6>
          </div>
        </div>
      </div>
      <div className='d-flex itemDetailsAndImage'>
        <div className='d-flex productDetails justify-content-center ps-4 pb-2'>
          <h5>
            <Skeleton.Input style={{ width: '150px' }} active />
          </h5>
          <div className='options addOnCart'>
            <h6>
              <Skeleton.Input style={{ width: '100px' }} active />
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;