import React, { useEffect } from 'react';
import GalleryView from '../../GalleryView/GalleryView';
import PostDisplay from '../../PostDisplay/PostDisplay';
import { Link } from 'react-router-dom';

export default function UploadedPostSection({
  postData,
  galleryPostData,
  viewSelector,
  restaurantId,
  featured,
  onDeletedPost
}) {
  useEffect(() => {
    console.log('postData from uploaded section', postData);
  }, [postData]);

  const onDeletePost = () => {
    onDeletedPost();
  }

  return (
    <div>
      {postData?.length > 0 && (
        <div
          className={`row row-gallery-postview ${viewSelector === 1 ? '' : 'd-none'
            }`}
        >
          <div className='d-flex justify-content-around CategoryFeatureDiv desktopDisplay'>
            <div className='d-flex align-items-center FeatureDiv mb-2 pt-1'>
              <div>
                <img src={featured} alt='' className='FeaturedImg' />
              </div>
              <div>
                <h6 className='FeaturedText'>Featured</h6>
              </div>
            </div>
            <div></div>
          </div>
          <div className='col-12 col-md-6 padding-zero'>
            {/* left modal */}
            <GalleryView postData={galleryPostData}></GalleryView>
          </div>

          <div className='col-12 col-md-6 '>
            <div className='row'>
              <div className='col-12'>
                {/* ChangePost */}
                {/* {postData.map((post, index) => {
                return <SharePostDisplay post={post} key={index} />;
              })} */}
                {postData.length > 0 && (
                  <>
                    <PostDisplay 
                    postData={postData[0]} 
                    isDeleted = {() => onDeletePost()}
                    ></PostDisplay>

                    <br className='desktopDisplay' />

                    <div className='desktopDisplay'>
                      {/* <SharePostDisplay></SharePostDisplay> */}
                      <PostDisplay postData={postData[1]} isDeleted = {() => onDeletePost()} ></PostDisplay>
                    </div>
                    {/* <Link
                      to={{
                        pathname: '/allpost',
                        search: `?restaurantId=${restaurantId}`,
                      }}
                      className='allPostATag mt-2'
                    >
                      See More
                    </Link> */}
                    {/* <a href='allpost' className='allPostATag mt-2'>
                    See More
                  </a> */}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='col-12'></div>
        </div>
      )}
      {/* post mobile view */}
      {postData?.length > 0 && (
        <div
          className={`row row-gallery-postview  ${viewSelector === 2 ? '' : 'd-none'
            }`}
        >
          <div className='col-12 '>
            <PostDisplay postData={postData[0]}></PostDisplay>

            <br />

            {/* <SharePostDisplay></SharePostDisplay> */}
            <PostDisplay postData={postData[1]}></PostDisplay>
            <a href='allpost' className='allPostATag'>
              See More
            </a>

            {/* <PostDisplay></PostDisplay>
          <br />
          <SharePostDisplay></SharePostDisplay>
          <a href='allpost' className='allPostATag'>
            See More
          </a> */}
          </div>
        </div>
      )}
    </div>
  );
}
