import React, { useState } from 'react';
import { Form, Card } from 'react-bootstrap';

const PaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const paymentOptions = [
    { label: 'Credit Card', value: 'creditCard' },
    { label: 'Debit Card', value: 'debitCard' },
    { label: 'PayPal', value: 'paypal' },
  ];

  return (
    <Form>
      {paymentOptions.map((option) => (
        <Card
          key={option.value}
          className={`mb-3 ${
            selectedOption === option.value ? 'border-primary' : ''
          }`}
          onClick={() =>
            handleOptionChange({ target: { value: option.value } })
          }
        >
          <Card.Body>
            <Form.Check
              type='radio'
              label={option.label}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleOptionChange}
            />
            {/* Additional details or customization for each payment option can be added here */}
          </Card.Body>
        </Card>
      ))}
    </Form>
  );
};

export default PaymentOptions;
