import React, { useState } from 'react';
import ModalFooter from './ModalFooter';
import imageUrl from '.././../../Assets/Image/1 (1).jpg';
import ModalImages from './ModalImages';
import RestaurantSelection from './RestaurantSelection';
import ImageCropComponents from './ImageCropComponents';
import ImageCropControls from './ImageCropControls';
import TaggedItems from './TaggedItems';

// Import other components needed for the content, such as image cropping or text areas

const ModalContent = ({
  step,
  setStep,
  showFirstModal,
  showSecondModal,
  showThirdModal,
  handleFilterModal,
  typeOfCrop,
  setTypeOfCrop,
  croppedImageForOnyByOne,
  croppedImageForFourByFive,
  croppedImages,
  setCroppedImages,
  initialData,
  setInitialData,
  handleCancelImage,
  fileInputRef,
  handleImageChange,
  comments,
  handleCommentsChange,
  handleEditImage,
  initialCroppedAreaPixels,
  setInitialCroppedAreaPixels,
  croppedAreaPixelsForOneByOne,
  setCroppedAreaPixelsForOneByOne,
  initialCroppedAreaPixelsForOneByOne,
  setInitialCroppedAreaPixelsForOneByOne,
  initialCroppedAreaPixelsForFourByFive,
  setInitialCroppedAreaPixelsForFourByFive,
  croppedAreaPixelsForFourByFive,
  setCroppedAreaPixelsForFourByFive,
  selectedImageIndex,
  setSelectedImageIndex,
  isImageSelected,
  editImageUrl,
  cropForOneByOne,
  setCropForOneByOne,
  zoomForOneByOne,
  setZoomForOneByOne,
  cropForFourByFive,
  setCropForFourByFive,
  zoomForFourByFive,
  setZoomForFourByFive,

  // Add the missing states here
  restaurant,
  setRestaurant,
  menu,
  setMenu,
  section,
  setSection,
  foodItem,
  setFoodItem,
  restaurantDatas,
  setRestaurantDatas,
  selectedRestaurantDatas,
  setSelectedRestaurantDatas,
  menuData,
  setMenuData,
  allMenuData,
  setAllMenuData,
  sectionDatas,
  setSectionDatas,
  selectedSectionDatas,
  setSelectedSectionDatas,
  selectedMenuDatas,
  setSelectedMenuDatas,
  foodItemDatas,
  setFoodItemDatas,
  selectFoodItemDatas,
  setSelectFoodItemDatas,
  buttonDisabled,
  setButtonDisabled,
  isRestaurantDropdownVisible,
  isMenuDropdownVisible,
  isSectionDropdownVisible,
  isFoodItemDropdownVisible,
  handleRemoveData,
  data,
  linkActive,
  setLinkActive,
  handleRemoveRestaurantItem,
  handleRemoveMenuItem,
  handleRemoveSectionItem,
  handleRemoveFoodItem,
  handleSave,
  loading,
  uploadfiles,
}) => {
  return (
    <div className='post-modal'>
      {showFirstModal && (
        <div className='d-flex flex-column pt-2'>
          <div
            className='row-gallery-postview'
            style={{ width: '100%', height: '15rem', overflow: 'auto' }}
          >
            <div className='d-flex shareYourThoughtspopUpImg'>
              <img
                src={data?.restaurantLogoUrl}
                alt={data?.restaurantLogoUrl}
                style={{ width: '43px' }}
              />
              <h6 className='pt-2 mt-1'>
                <b>{data?.restaurantName}</b>
              </h6>
            </div>
            <TaggedItems
              linkActive={linkActive}
              selectedRestaurantDatas={selectedRestaurantDatas}
              selectedMenuDatas={selectedMenuDatas}
              selectedSectionDatas={selectedSectionDatas}
              selectFoodItemDatas={selectFoodItemDatas}
              handleRemoveRestaurantItem={handleRemoveRestaurantItem}
              handleRemoveMenuItem={handleRemoveMenuItem}
              handleRemoveSectionItem={handleRemoveSectionItem}
              handleRemoveFoodItem={handleRemoveFoodItem}
            />
            <textarea
              style={{
                width: '100%',
                border: 'none',
                fontSize: '1.5rem',
                resize: 'none',
              }}
              value={comments}
              className='form-control whatsonyourMindtextArea'
              placeholder='What’s on your mind?'
              onChange={handleCommentsChange}
            ></textarea>
            {/* Added Images On Modal */}
            <ModalImages
              croppedImages={croppedImages}
              setCroppedImages={setCroppedImages}
              initialData={initialData}
              setInitialData={setInitialData}
              handleCancelImage={handleCancelImage}
              handleEditImage={handleEditImage}
            />
          </div>
          {/* Modal Footer Icons */}
          <ModalFooter
            step={step}
            setStep={setStep}
            handleFilterModal={handleFilterModal}
            fileInputRef={fileInputRef}
            handleImageChange={handleImageChange}
            handleSave={handleSave}
            loading={loading}
            comments={comments}
            uploadfiles={uploadfiles}
          />
        </div>
      )}
      {!showFirstModal && !showSecondModal && showThirdModal && (
        <RestaurantSelection
          step={step}
          setStep={setStep}
          handleFilterModal={handleFilterModal}
          restaurant={restaurant}
          setRestaurant={setRestaurant}
          menu={menu}
          setMenu={setMenu}
          section={section}
          setSection={setSection}
          foodItem={foodItem}
          setFoodItem={setFoodItem}
          restaurantDatas={restaurantDatas}
          selectedRestaurantDatas={selectedRestaurantDatas}
          setSelectedRestaurantDatas={setSelectedRestaurantDatas}
          menuData={menuData}
          selectedMenuDatas={selectedMenuDatas}
          setSelectedMenuDatas={setSelectedMenuDatas}
          sectionDatas={sectionDatas}
          selectedSectionDatas={selectedSectionDatas}
          setSelectedSectionDatas={setSelectedSectionDatas}
          foodItemDatas={foodItemDatas}
          selectFoodItemDatas={selectFoodItemDatas}
          setSelectFoodItemDatas={setSelectFoodItemDatas}
          isRestaurantDropdownVisible={isRestaurantDropdownVisible}
          setButtonDisabled={setButtonDisabled}
          handleRemoveData={handleRemoveData}
          isMenuDropdownVisible={isMenuDropdownVisible}
          isSectionDropdownVisible={isSectionDropdownVisible}
          isFoodItemDropdownVisible={isFoodItemDropdownVisible}
          linkActive={linkActive}
          setLinkActive={setLinkActive}
        />
      )}
      {!showFirstModal && !showThirdModal && showSecondModal && (
        <div className='image-crop-container'>
          <ImageCropComponents
            typeOfCrop={typeOfCrop}
            croppedImageForOnyByOne={croppedImageForOnyByOne}
            croppedImageForFourByFive={croppedImageForFourByFive}
            initialCroppedAreaPixels={initialCroppedAreaPixels}
            setInitialCroppedAreaPixels={setInitialCroppedAreaPixels}
            croppedAreaPixelsForOneByOne={croppedAreaPixelsForOneByOne}
            setCroppedAreaPixelsForOneByOne={setCroppedAreaPixelsForOneByOne}
            initialCroppedAreaPixelsForOneByOne={
              initialCroppedAreaPixelsForOneByOne
            }
            setInitialCroppedAreaPixelsForOneByOne={
              setInitialCroppedAreaPixelsForOneByOne
            }
            initialCroppedAreaPixelsForFourByFive={
              initialCroppedAreaPixelsForFourByFive
            }
            setInitialCroppedAreaPixelsForFourByFive={
              setInitialCroppedAreaPixelsForFourByFive
            }
            croppedAreaPixelsForFourByFive={croppedAreaPixelsForFourByFive}
            setCroppedAreaPixelsForFourByFive={
              setCroppedAreaPixelsForFourByFive
            }
            selectedImageIndex={selectedImageIndex}
            setSelectedImageIndex={setSelectedImageIndex}
            isImageSelected={isImageSelected}
            editImageUrl={editImageUrl}
            cropForOneByOne={cropForOneByOne}
            setCropForOneByOne={setCropForOneByOne}
            zoomForOneByOne={zoomForOneByOne}
            setZoomForOneByOne={setZoomForOneByOne}
            cropForFourByFive={cropForFourByFive}
            setCropForFourByFive={setCropForFourByFive}
            zoomForFourByFive={zoomForFourByFive}
            setZoomForFourByFive={setZoomForFourByFive}
          />
          <ImageCropControls
            typeOfCrop={typeOfCrop}
            setTypeOfCrop={setTypeOfCrop}
            zoomForOneByOne={zoomForOneByOne}
            setZoomForOneByOne={setZoomForOneByOne}
            zoomForFourByFive={zoomForFourByFive}
            setZoomForFourByFive={setZoomForFourByFive}
            croppedImageForOnyByOne={croppedImageForOnyByOne}
            croppedImageForFourByFive={croppedImageForFourByFive}
          />
        </div>
      )}
    </div>
  );
};

export default ModalContent;
