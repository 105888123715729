const initialState = false;

const changeLoad = (state = initialState, action) => {
    switch (action.type) {
        case "changeLoadTrue": return true;
        case "changeLoadFalse": return false;
        case "getChangeLog": return state;
        default: return state;
    }
}
export default changeLoad;