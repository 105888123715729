import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode } from 'swiper';
import Rating from 'react-rating';
import "./RestaurantCard.css";

// Import Swiper styles
import 'swiper/css';

import '../ProductComponents/ProductCard.css';

function RestaurantCard({ restaurant, img1, img2, img3, img4, text }) {
  const [categories, setCategories] = useState("");

  useEffect(() => {
    const category = restaurant.categories.map((data) => data.categoryName)
    setCategories(category.join(" | "))
  }, [restaurant])
  return (
    <div className='restaurantSwipper'>
      {/* <div className='box searchProductWidth'> */}
      <div className='restaurant-search-container'>
        <div className='foodcart'>
          {/* <div className="d-flex justify-content-start ">
                            <h3 class="badge pill">Chef Special</h3>
                        </div> */}
          {/* <div className='d-flex justify-content-end cartIcon'>
            <img src={plusIcon} alt='' style={{ width: '20px' }} />
          </div> */}
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            // spaceBetween={2}
            // grabCursor={false}
            // centeredSlides={false}
            // slidesPerView={'auto'}
            // speed={10000}
            // cssMode={true}
            // pagination={{
            //   dynamicBullets: true,
            // }}
            // // slidesPerView={1}
            // navigation={true}
            // modules={[Pagination, Navigation]}
            className='Productfoodcartimg'
            breakpoints={{
              200: {
                slidesPerView: 1
              },
              776: {
                slidesPerView: 2,
              },
              980: {
                slidesPerView: 3,
              },
            }}
          >
            {/* {details?.foodImageList.map((d, i) => {
              return ( */}
            {
              restaurant?.imagePaths?.length > 0
                ?
                restaurant.imagePaths?.map((image, idx) => <SwiperSlide key={idx} className='MinHeightOfSwiper'>
                  <img src={image} alt='...' />
                </SwiperSlide>)
                :
                <>
                  <SwiperSlide className='MinHeightOfSwiper'>
                    <img src={img1} alt='...' />
                  </SwiperSlide>
                  <SwiperSlide className='MinHeightOfSwiper'>
                    <img src={img2} alt='...' />
                  </SwiperSlide>
                  <SwiperSlide className='MinHeightOfSwiper'>
                    <img src={img3} alt='...' />
                  </SwiperSlide>
                  <SwiperSlide className='MinHeightOfSwiper'>
                    <img src={img4} alt='...' />
                  </SwiperSlide>
                </>
            }
          </Swiper>

          <div className='restaurantDetails restaurant-info'>
            <div className="d-flex align-items-center">
              <h4 className='me-3'>
                <b>{restaurant?.restaurantName}</b>
              </h4>
              <div>
                <Rating
                  className='landingStar pe-1'
                  initialRating={Number(4.5)}
                  emptySymbol='far fa-star icon-color'
                  fullSymbol='fas fa-star icon-color'
                  readonly
                ></Rating>
                <span className='ratingNumber text-lg'>4.5</span>{' '}
                {/* Add this line */}
              </div>
            </div>
            <p>{categories}</p>
            <p>{restaurant?.restaurantDetails}</p>
          </div>
        </div>
        {/* <hr className='mx-5  m-0 p-0 cartHR' /> */}
      </div>
    </div>
  );
}

export default RestaurantCard;
