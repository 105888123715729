import './Navbar.css';
import './../Home/BannerSection/Banner.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import Login from './../LoginRegister/Login';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Modal, Tab, Nav, Button } from 'react-bootstrap';
import $ from 'jquery';
import backBtn from './../../Assets/Icons/back.png';
import logo from './../../Assets/Image/LogoTransparentBrown.png';
import logowhite from './../../Assets/Image/LogoTransparent.png';
import { useSelector, useDispatch } from 'react-redux';
import {
changeLoadFalse,
changeLoadTrue,
openRegisterPageFalse,
closeForgetPassword,
openForgetPassword,
} from '../../actions/index';

import searchIcont from './../../Assets/Icons/searchIcon2.png';
import searchIcontCoffee from './../../Assets/Icons/searchIcon.png';
import searchair from './../../Assets/Image/SearchAir.png';

import loginImg from './../../Assets/Image/login.png';
import loginDesktopImg from './../../Assets/Image/login-Desktop.png';
import homeImg from './../../Assets/Image/home.png';
import menuImg from './../../Assets/Image/menu.png';
import shoppingCart from './../../Assets/Icons/shopping-bag.png';
import userDefaultImg from './../../Assets/Icons/user-default -propic.png';

import leo from './../../Assets/Image/Leo.png';

import test from './../../Assets/Image/PastaOffer.jpg';
import fuchka from './../../Assets/Image/1 51.png';
import star from './../../Assets/Icons/starCoffe.png';
import PKB from './../../Assets/Image/PKB.png';
import pasta from './../../Assets/Image/Pasta 1.png';
import search from './../../Assets/Icons/searchIcon.png';
import Cart from '../Cart/Cart';
import Test9 from '../../Test9';
import SidebarLogin from './SidebarLogin';
import Notification from '../../components/Navbar/Notification';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FoodModal from '../Home/FoodSelection/FoodModal';
import searchIcon from '../../Assets/Icons/searchIcon.png';
import { Menu, MenuItem } from '@mui/material';
import { sliceText } from '../../utils/sliceText';
import { getFoodStatus } from '../../utils/getFoodStatus';
import StarRating from '../../components/Navbar/Rating';

const Navbar = (props) => {
const apiAddress = process.env.REACT_APP_SECRET;
const currentState = useSelector((state) => state.changeLoad);
const isRegisterPage = useSelector((state) => state.openRegisterPage);
const isReloadPage = useSelector((state) => state.testReducers);
const forgetPasswordToggle = useSelector((state) => state.forgetPassword);
// console.log("currentState: " + currentState.changeLoad)
const dispatch = useDispatch();
const [userVal, setUserValue] = useState('');
const [profileImage, setProfileImage] = useState('');
const navigate = useNavigate();

const [navbarOnTop, setNavbarOnTop] = useState(true);
const [scrollValue, setscrollValue] = useState(0);
const [slideUp, setSlideUp] = useState(false);
const navbarTopRef = useRef(null);
const [showModal, setShowModal] = useState(false);
const [allSelected, setAllSelected] = useState(false);
const [foodSelected, setFoodSelected] = useState(false);
const [resturantSelected, setRestaurantSelected] = useState(false);
const [show, setShow] = useState(false);
const [isVisible, setIsVisible] = useState(false);
const searchDialogdivRef = useRef(null);
const [searchInput, setSearchInput] = useState('');
const [category, setCategory] = useState('');
const [defaultColor, setDefaultColor] = useState(false);
const [Flag, setFlag] = useState(false);
const [userId, setUserId] = useState();

//search section
const [SearchData, setSearchData] = useState({
  searchFoodView: [],
  searchRestaurantView: [],
});
const [isDataFound, setIsdataFound] = useState('');
const [TyepedData, setTypedData] = useState(false);
const [SearchString, setSearchString] = useState();

console.log("Search data: ", SearchData)

// Modal Card states

const [orderDetails, setOrderDetails] = useState(null);
const [loading, setIsLoading] = useState(false);
const [selectedItemId, setSelectedItemId] = useState(false);
const [selectedItemImage, setSelectedItemImage] = useState([]);
const [showModalCard, setShowModalCard] = useState(false);
const [selectedImageIndex, setSelectedImageIndex] = useState(0);

const inputRef = useRef(null);

const [passwordView, setPasswordView] = useState(false);
const [isForgetPasswordSubmited, setisForgetPasswordSubmited] =
  useState(false);

const [loginModalOpen, setLoginModalOpen] = useState(false);

const [forgetPassword, setforgetPassword] = useState(false);
const [loadingSearch, setLoadingSearch] = useState(false);

const [showOutOfHourModal, setShowOutOfHourModal] = useState(false);
const [showUnavailableReasonModal, setShowUnavailableReasonModal] = useState(false);

const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 200);
  };
};

const handleInputChange = (event) => {
  const { value } = event.target;
  if (!!value === true) {
    setTypedData(true);
    GetSearchData(value);
    setSearchString(value);
  } else {
    setSearchData({
      searchFoodView: [],
      searchRestaurantView: [],
    });
    // setTypedData(false);

    // setShowModal(false);
    setSearchString('');
  }
};

const optimizedVersion = useCallback(debounce(handleInputChange), []);

//  test start
const handleClickOutside = (event) => {
  if (
    searchDialogdivRef.current &&
    !searchDialogdivRef.current.contains(event.target)
  ) {
    setShowModal(false);
  }
};

useEffect(() => {
  if (props.from !== undefined) {
    setDefaultColor(true);
  }
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

const GetSearchDataNoStr = async () => {
  setLoadingSearch(true);
  await axios
    .get(apiAddress + '/api/Search/GetSearchDataByNoString')
    .then((response) => {
      console.log(response.data);
      if (
        response.data.searchFoodView.length === 0 &&
        response.data.searchRestaurantView.length === 0
      ) {
        setIsdataFound('No Data Found');
      }

      setTypedData(true);
      setShowModal(true);
      setAllSelected(true);
      setFoodSelected(false);
      setRestaurantSelected(false);

      setSearchData({
        searchFoodView: response.data.searchFoodView,
        searchRestaurantView: response.data.searchRestaurantView,
      });

      setFlag(!Flag);
      setIsdataFound('');
      setLoadingSearch(false);
    });
};

// test end

function handleSearchIcon() {
  setShowModal(!showModal);
  // GetSearchDataNoStr();
  // GetSearchDataNoStrForFilters();
  setAllSelected(true);
  setFoodSelected(false);
  setRestaurantSelected(false);
}

useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    setIsVisible(scrollPosition === 0);
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

function handleShow() {
  GetSearchDataNoStrForFilters();
  setShow(true);
  setAllSelected(true);
}
useEffect(() => {
  // Check if user is authenticated
  const gotasteClienttoken = localStorage.getItem('gotasteClienttoken');

  let decoded = null;
  if (gotasteClienttoken != null) {
    try {
      decoded = jwt_decode(gotasteClienttoken);
    } catch (error) { }
    if (decoded != null || decoded != undefined) {
      setUserValue(decoded.UserName);
      setProfileImage(decoded.ProfileImage);
      setUserId(decoded.UserId);
    }
  }

  window.addEventListener('scroll', (event) => {
    const filterBarRect = navbarTopRef?.current?.getBoundingClientRect();

    if (filterBarRect != undefined) {
      const isAboveViewport = filterBarRect.top === 0 ? true : false;
      setNavbarOnTop(isAboveViewport);
      var top = filterBarRect.top * -1;
      var scrollval = scrollValue * -1;
      if (top > scrollval) {
        setSlideUp(true);
      } else if (top <= 0) {
        setSlideUp(false);
        setShowModal(false);
        setSearchData({
          searchFoodView: [],
          searchRestaurantView: [],
        });
      } else {
        setSlideUp(false);
        setShowModal(false);
        setSearchData({
          searchFoodView: [],
          searchRestaurantView: [],
        });
      }

      var s = filterBarRect.top;
      setscrollValue(s);
    }
  });
});

// const fetchData = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_SECRET}/api/Cart?userId=${userId}`
//     );
//     console.log('response here', response);
//     setCount(response.data.length);
//   } catch (error) {
//     console.error('Error fetching cart data:', error);
//     // Handle errors, e.g., show an error message to the user
//   }
// };

// useEffect(() => {
//   if (userVal) {
//     fetchData();
//   }
// }, [userVal]);

const goToHome = () => {
  window.location.href = `/`;
};

const hideModalInfo = () => {
  setLoginModalOpen(false);
  dispatch(changeLoadFalse());
  dispatch(openRegisterPageFalse());
  window.$('#exampleModalCenter').modal('hide');
};
const showModalInfo = () => {
  setLoginModalOpen(true);
  dispatch(changeLoadFalse());
  dispatch(openRegisterPageFalse());
  window.$('#exampleModalCenter').modal('show');
};

const handleCloseModal = () => {
  setShowModal(false);
};
// function handleSearchIcon(){
//     setShowModal(!showModal)
// }

const GetSearchDataNoStrForFilters = async () => {
  setLoadingSearch(true)
  await axios
    .get(apiAddress + '/api/Search/GetSearchDataByNoString')
    .then((response) => {
      console.log(response.data);
      if (
        response.data.searchFoodView.length === 0 &&
        response.data.searchRestaurantView.length === 0
      ) {
        setIsdataFound('No Data Found');
      }

      setTypedData(true);
      setShowModal(true);

      setSearchData({
        searchFoodView: response.data.searchFoodView,
        searchRestaurantView: response.data.searchRestaurantView,
      });

      setFlag(!Flag);
      setIsdataFound('');
      setLoadingSearch(false);
    });
};

function handleAllButtonClick() {
  GetSearchDataNoStrForFilters();
  setShowModal(!showModal);
  setAllSelected(true);
  setFoodSelected(false);
  setRestaurantSelected(false);
  // window.$("#root").css("filter", "brightness(0.5)");
}
function handleFoodButtonClick() {
  GetSearchDataNoStrForFilters();
  setShowModal(!showModal);
  setAllSelected(false);
  setFoodSelected(true);
  setRestaurantSelected(false);
}
function handleRestaurantButtonClick() {
  GetSearchDataNoStrForFilters();
  setShowModal(!showModal);
  setAllSelected(false);
  setFoodSelected(false);
  setRestaurantSelected(true);
}

const GetSearchData = async (searchStr) => {
  setLoadingSearch(true)
  await axios
    .get(
      apiAddress + '/api/Search/GetSearchDataByString?searchStr=' + searchStr
    )
    .then((response) => {
      if (
        response.data.searchFoodView.length === 0 &&
        response.data.searchRestaurantView.length === 0
      ) {
        setIsdataFound('No Data Found');
      }

      setSearchData({
        searchFoodView: response.data.searchFoodView,
        searchRestaurantView: response.data.searchRestaurantView,
      });
      setFlag(!Flag);
      setIsdataFound("");
      setLoadingSearch(false);
    });
};

const handleUserFound = (props) => {
  setPasswordView(props);
};

props.handleNavbar(showModal);

const handleSeeMoreClick = () => {
  setShowModal(false);
  setShow(false);
};

const getItemDetails = (data) => {
  setIsLoading(true);
  setShowModalCard(true);
  setOrderDetails(null);
  // setIsLoading(false);
  setSelectedItemId(null);
  setSelectedItemImage([]);
  axios
    .get(
      apiAddress +
      '/api/FoodItems/GetFoodItemDetailsForOrderDetailsByItemId?ItemId=' +
      data.foodItemId
    )
    .then((response) => {
      if (getFoodStatus(response?.data?.isActive, response?.data?.status) === "Out of hour") {
        setShowOutOfHourModal(true);
      }

      if (getFoodStatus(response?.data?.isActive, response?.data?.status) === "Unavailable") {
        setShowUnavailableReasonModal(true);
      }

      const updatedResponseData = {
        ...response.data,
        restaurantId: data.restaurantId,
      };

      setOrderDetails(updatedResponseData);

      setSelectedItemId(data.foodItemId);
      setSelectedItemImage(updatedResponseData);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

const handleClose = () => setShowModalCard(false);

useEffect(() => {
  if (showModal && inputRef.current) {
    inputRef.current.focus();
  }
}, [showModal]);

const [anchorEl, setAnchorEl] = useState(null);

const handleMenuOpen = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleMenuClose = () => {
  setAnchorEl(null);
};

const handleLogout = () => {
  localStorage.removeItem('gotasteClienttoken');
  handleMenuClose();
  window.location.href = `/`;
};

const getMatchedColumn = (object, searchString) => {
  if (!searchString) return []; // If searchString is undefined, return an empty array

  const keysMap = {
    // "keyword": "Section",
    "keywordtag": "Keyword Tag",
    "foodDetailsDescription": "Details",
    "foodStory": "Story",
    "instructionDescription": "Instruction"
  };

  const matchedValues = [];

  // Loop through the keysMap and check if the object contains any matching key
  Object.keys(keysMap).forEach((key) => {
    const text = object[key];
    if (text && typeof text === 'string') {
      const regex = new RegExp(`(${searchString})`, 'gi'); // Case-insensitive match
      if (regex.test(text)) { // If match is found
        // Create an object with the dynamic key from keysMap
        const matchedObject = {};
        matchedObject[keysMap[key]] = text;

        // Push the matched object into the array
        matchedValues.push(matchedObject);
      }
    }
  });

  return matchedValues; // Return the matched values from keysMap
};

const extractContext = (description, searchString) => {
  if (!searchString || !description) return ''; // If no search string or description, return empty string

  const regex = new RegExp(`(\\S+\\s+){0,2}(\\S*${searchString}\\S*)(\\s+\\S+){0,1}`, 'gi'); // Match the searchString and up to 2 words before and after
  const match = description.match(regex);

  if (match) {
    // Return the matched context with '...' before and after
    return `...${match[0].trim()}...`;
  }

  return ''; // If no match found, return an empty string
};

const highlightMatch = (text, searchString) => {
  if (!searchString) return text; // If searchString is undefined, return the original text
  const regex = new RegExp(`(${searchString})`, 'gi'); // Case-insensitive match
  return text?.split(regex).map((part, index) =>
    part.toLowerCase() === searchString.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: '#ffff00', color: "#000000" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const setSearchConditionOnClick = (value) => {
  // Resetting all state
  setAllSelected(false);
  setFoodSelected(false);
  setRestaurantSelected(false);
  GetSearchDataNoStrForFilters();

  switch (value) {
    case "All":
      setAllSelected(true);
      break;
    case "Food":
      setFoodSelected(true);
      break;
    case "Restaurant":
      setRestaurantSelected(true);
      break;
    case "Post":
      return;
    default:
      setAllSelected(true);
      break;
  }

  setShow(true);
}

const moibleSearchBarClicked = (event) => {
  const isSearchCriteria = event.target.closest('.search-criteria') || event.target.closest('.searchForFood');

  if (!isSearchCriteria) {
    setSearchConditionOnClick('All');
  }
}

return (
  <div className='Navbar-custom'>
    <div ref={navbarTopRef}></div>
    {/* Navbar for desktop device */}
    <div className='desktopDisplay'>
      <div
        className={`navbarGoTaste  ${navbarOnTop
          ? defaultColor
            ? 'backgroundWhite'
            : 'backgroundTranparent'
          : 'backgroundWhite'
          } ${slideUp ? 'slideDown' : ' slideUp '}`}
      >

        {/* GoTaste logo--navbar left hamburger */}
        <div className='brand d-flex align-items-center'>
          <button
            class='btn me-4'
            type='button'
            data-bs-toggle='offcanvas'
            data-bs-target='#offcanvasExample'
            aria-controls='offcanvasExample'
          >
            {defaultColor ? (
              <div
                className='btn btn-buyNow w-100'
                style={{ color: 'white' }}
              >
                Sign Up
              </div>
            ) : (
              <i
                class={`fa-solid fa-bars text-light   ${navbarOnTop
                  ? defaultColor
                    ? 'text-dark'
                    : 'text-light'
                  : 'text-dark'
                  }`}
              ></i>
            )}
          </button>

          {defaultColor ? (
            <button
              className={` brandname ${navbarOnTop
                ? defaultColor
                  ? 'brandname-color-coffee'
                  : 'brandname-color-white'
                : 'brandname-color-coffee'
                }`}
              style={{ width: '150px' }}
            >
              <img src={logo} alt='' style={{ width: '3rem' }} /> Go{' '}
              <b>Taste</b>
            </button>
          ) : (
            <>
              <a
                className={` brandname ${navbarOnTop
                  ? defaultColor
                    ? 'brandname-color-coffee'
                    : 'brandname-color-white'
                  : 'brandname-color-coffee'
                  }`}
                href='/'
              >
                {navbarOnTop ? (
                  <div style={{ width: '150px' }}>
                    <img src={logowhite} alt='' style={{ width: '3rem' }} />{' '}
                    Go <b>Taste</b>
                  </div>
                ) : (
                  <div style={{ width: '150px' }}>
                    <img src={logo} alt='' style={{ width: '3rem' }} /> Go{' '}
                    <b>Taste</b>
                  </div>
                )}
              </a>
            </>
          )}
        </div>

        {/* ---Search UI--- */}
        <div
          style={{ position: 'relative', width: '42rem' }}
          ref={searchDialogdivRef}
        >
          <div className='searchInput '>
            {showModal == false && !defaultColor && (
              <div
                className={` ${navbarOnTop
                  ? defaultColor
                    ? 'search-field-color-coffee '
                    : 'search-field-color-white '
                  : 'search-field-color-coffee '
                  }search-field d-flex align-items-center`}
              >
                <>
                  <button
                    className='btn btn-Search-Prop'
                    onClick={handleAllButtonClick}
                  >
                    <b>All</b>
                  </button>
                  <div class='vl'></div>
                  <button
                    className='btn btn-Search-Prop'
                    onClick={handleFoodButtonClick}
                  >
                    <b>Food</b>
                  </button>
                  <div class='vl'></div>
                  <button
                    className='btn btn-Search-Prop'
                    onClick={handleRestaurantButtonClick}
                  >
                    <b>Restaurant</b>
                  </button>
                  <div class='vl'></div>
                  <button className='btn btn-Search-Prop'>
                    <b>Post</b>
                  </button>
                </>

                <button
                  className='btn btn-search'
                  // onClick={handleSearchIcon}
                  onClick={handleAllButtonClick}
                >
                  <img src={searchIcont} alt='' className='searchIcon' />
                </button>
              </div>
            )}
            {showModal && (
              <div className='search-field-color-white search-field d-flex align-items-center searchInputFieldParent'>
                <input
                  type='text'
                  placeholder='Search all'
                  className='searchInputField'
                  onChange={optimizedVersion}
                  ref={inputRef}
                />
                <button className='btn btn-search' onClick={handleSearchIcon}>
                  <img src={searchIcont} alt='' className='searchIcon' />
                </button>
              </div>
            )}
          </div>
          {showModal && !slideUp && TyepedData ? (
            <div id='searchDialogId'>
              <div className='container searchDialog '>
                <ul class='nav nav-tabs pt-3' id='myTab' role='tablist'>
                  <li class='nav-item' role='presentation'>
                    <button
                      class={`nav-link ${allSelected ? ' active' : ''}`}
                      id='home-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#all'
                      type='button'
                      role='tab'
                      aria-controls='home'
                      aria-selected='true'
                    >
                      All
                    </button>
                  </li>
                  <li class='nav-item' role='presentation'>
                    <button
                      class={`nav-link ${foodSelected ? ' active' : ''}`}
                      id='profile-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#food'
                      type='button'
                      role='tab'
                      aria-controls='profile'
                      aria-selected='false'
                    >
                      Food
                    </button>
                  </li>
                  <li class='nav-item' role='presentation'>
                    <button
                      class={`nav-link ${resturantSelected ? ' active' : ''}`}
                      id='contact-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#restaurant'
                      type='button'
                      role='tab'
                      aria-controls='contact'
                      aria-selected='false'
                    >
                      Restaurant
                    </button>
                  </li>
                </ul>
                <div class='tab-content' id='myTabContent'>
                  <div
                    class={`tab-pane fade ${allSelected ? 'show active' : ''
                      }  `}
                    id='all'
                    role='tabpanel'
                    aria-labelledby='home-tab'
                  >
                    {SearchData?.searchFoodView?.slice(0, 3)?.map((d, i) => {
                      // Here you need to update
                      return (
                        <>
                          <div
                            className='d-flex pt-2'
                            style={{ minHeight: '2.5rem', cursor: 'pointer' }}
                            onClick={() => {
                              getItemDetails(d);
                            }}
                          >
                            <div className='searchContentMargin'>
                              <img
                                src={d.itemImagePath}
                                alt='img'
                                className='searchDialogImg'
                              ></img>
                            </div>
                            <div className='d-flex flex-column searchContentMargin'>
                              <p className='searchText searchTitile'>
                                {
                                  highlightMatch(d.foodItemName, SearchString)
                                }
                              </p>

                              {
                                extractContext(d.description, SearchString) ?
                                  <div className='d-flex flex-row matched-description'>
                                    <p
                                      className='searchText searchratingText'
                                      style={{
                                        marginBottom: '0px',
                                        color: '#80615b',
                                      }}
                                    >
                                      {
                                        highlightMatch(extractContext(d.description, SearchString), SearchString)
                                      }
                                    </p>
                                  </div>
                                  :
                                  ""
                                  // <div className='d-flex flex-row matched-description'>
                                  //   <p className='searchText searchratingText'
                                  //     style={{ marginBottom: '0px', color: '#80615b', }}
                                  //   >
                                  //     {d.description.length > 100 ? d.description.slice(0, 36) + '...' : d.description}
                                  //   </p>
                                  // </div>
                              }
                              {/* Restaurant Name for all tab */}
                              <div>
                                <p
                                  className='searchText searchratingText d-flex flex-row gap-2'
                                  style={{
                                    marginBottom: '0px',
                                    color: '#80615b',
                                  }}
                                >
                                  <span>{d.restaurantName}</span>
                                  <div style={{ marginTop: '-2px' }}>
                                    <StarRating starImage={star} rating={4.5} />
                                  </div>
                                </p>
                              </div>
                            </div>
                            <div className='searchLabel'>
                              <p className='searchText'>Food</p>
                            </div>
                            {
                              // d?.matchedText &&
                              // <div className='searchLabel matched-text'>
                              //   <p className='searchText'>
                              //     {highlightMatch(sliceText(d?.matchedText?.split(",")[0], 15), SearchString)}
                              //   </p>
                              // </div>
                            }
                            {
                              highlightMatch(d.foodItemName, SearchString).length <= 1 && !extractContext(d.description, SearchString) &&
                              getMatchedColumn(d, SearchString).map((matchedItem, index) => {
                                const [key, value] = Object.entries(matchedItem)[0];
                                return (
                                  <div className='ms-auto matched-text' key={index}>
                                    <p className='mb-0'>{key}</p>  {/* Render the key */}
                                    <p className='mb-0'>{highlightMatch(extractContext(value, SearchString), SearchString)}</p> {/* Render the value */}
                                  </div>
                                );
                              })
                            }
                          </div>
                          <hr />
                        </>
                      );
                    })}

                    {SearchData?.searchRestaurantView
                      ?.slice(0, 2)
                      ?.map((d, i) => {
                        return (
                          <>
                            <div
                              className='d-flex pt-2'
                              style={{ height: '2.5rem' }}
                            >
                              <div className='searchContentMargin'>
                                <img
                                  src={d.restaurantLogo}
                                  alt='img'
                                  className='searchDialogImg'
                                ></img>
                              </div>
                              <div className='d-flex flex-column searchContentMargin'>
                                <a
                                  href={d.urlName}
                                  className='searchText searchTitile'
                                  style={{ textDecoration: 'none' }}
                                >
                                  {
                                    highlightMatch(d.name, SearchString)
                                  }
                                </a>

                                <div className='d-flex flex-row'>
                                  <p
                                    className='searchText searchratingText'
                                    style={{
                                      marginBottom: '0px',
                                      color: '#80615b',
                                    }}
                                  >
                                    {
                                      highlightMatch(d.restaurantCategories, SearchString)
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className='searchLabelRestaurant'>
                                <p className='searchText'>Restaurant</p>
                              </div>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    {
                      loadingSearch ?
                        <div className="loading-text">Loading...</div>
                        :
                        SearchData.searchFoodView.length == 0 &&
                          SearchData.searchRestaurantView.length == 0 ? (
                          <div className="d-flex justify-content-center">
                            <span>No Data Found</span>
                          </div>
                        ) : (
                          ''
                        )}
                    {/* {category === "" && (
                      <div className="pt-2">
                        <h5 style={{ fontWeight: "700" }}>Top Categories</h5>
                      </div>
                    )}
                    <div className="pt-2" style={{ marginLeft: "10px" }}>
                      <div className="d-flex flex-row ">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />

                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? category : "Chinese"}
                        </p>
                      </div>
                      <hr />

                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Cheese " + category : "Pizza"}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Normal " + category : "Pasta"}
                        </p>
                      </div>
                    </div> */}
                  </div>
                  <div
                    class={`tab-pane fade ${foodSelected ? 'show active' : ''
                      } `}
                    id='food'
                    role='tabpanel'
                    aria-labelledby='profile-tab'
                  >
                    {SearchData?.searchFoodView?.slice(0, 5)?.map((d, i) => {
                      return (
                        <>
                          <div
                            className='d-flex pt-2'
                            style={{ minHeight: '2.5rem', cursor: 'pointer' }}
                            onClick={() => {
                              getItemDetails(d);
                            }}
                          >
                            <div className='searchContentMargin'>
                              <img
                                src={d.itemImagePath}
                                alt='img'
                                className='searchDialogImg'
                              ></img>
                            </div>
                            <div className='d-flex flex-column searchContentMargin'>
                              <p className='searchText searchTitile'>
                                {
                                  highlightMatch(d.foodItemName, SearchString)
                                }
                              </p>

                              {
                                extractContext(d.description, SearchString) ?
                                  <div className='d-flex flex-row matched-description'>
                                    <p
                                      className='searchText searchratingText'
                                      style={{
                                        marginBottom: '0px',
                                        color: '#80615b',
                                      }}
                                    >
                                      {
                                        highlightMatch(extractContext(d.description, SearchString), SearchString)
                                      }
                                    </p>
                                  </div>
                                  :
                                  ""
                                  // <div className='d-flex flex-row matched-description'>
                                  //   <p className='searchText searchratingText'
                                  //     style={{ marginBottom: '0px', color: '#80615b', }}
                                  //   >
                                  //     {d.description.length > 100 ? d.description.slice(0, 36) + '...' : d.description}
                                  //   </p>
                                  // </div>
                              }

                              {/* Restaurant Name for all Food */}
                              <div>
                                <p
                                  className='searchText searchratingText d-flex flex-row gap-2'
                                  style={{
                                    marginBottom: '0px',
                                    color: '#80615b',
                                  }}
                                >
                                  <span>{d.restaurantName}</span>
                                  <div style={{ marginTop: '-2px' }}>
                                    <StarRating starImage={star} rating={4.5} />
                                  </div>
                                </p>
                              </div>
                            </div>
                            {/* <div className="searchLabel">
                          <p className="searchText">Food</p>
                        </div> */}
                            {
                              // d?.matchedText && <div className='searchLabel matched-text'>
                              //   <p className='searchText'>
                              //     {highlightMatch(sliceText(d?.matchedText?.split(",")[0], 15), SearchString)}
                              //   </p>
                              // </div>
                            }
                            {
                              highlightMatch(d.foodItemName, SearchString).length <= 1 && !extractContext(d.description, SearchString) &&
                              getMatchedColumn(d, SearchString).map((matchedItem, index) => {
                                const [key, value] = Object.entries(matchedItem)[0];
                                return (
                                  <div className='ms-auto matched-text' key={index}>
                                    <p className='mb-0'>{key}</p>  {/* Render the key */}
                                    <p className='mb-0'>{highlightMatch(extractContext(value, SearchString), SearchString)}</p> {/* Render the value */}
                                  </div>
                                );
                              })
                            }
                          </div>
                          <hr />
                        </>
                      );
                    })}
                    {
                      loadingSearch ?
                        <div className="loading-text">Loading...</div>
                        :
                        SearchData.searchFoodView.length == 0 ? (
                          <div className="d-flex justify-content-center">
                            <span>No Data Foundd</span>
                          </div>
                        ) : SearchData.searchFoodView.length > 5 ? (
                          // &&
                          //   props.showSeeMore != false ?
                          <div
                            className='d-flex justify-content-start ms-3'
                            onClick={handleSeeMoreClick}
                          >
                            <Link
                              to='/food-search'
                              state={SearchString}
                              style={{ textDecoration: 'none' }}
                            >
                              <div className='Search-info-container'>
                                <div className='search-result-icon me-2'>
                                  <img src={searchIcon} alt='search icon' />
                                </div>
                                <div className='search-term'>
                                  Search for "{SearchString}"
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          ''
                        )}
                    {/* {category === "" && (
                      <div className="pt-2">
                        <h5 style={{ fontWeight: "700" }}>Top Categories</h5>
                      </div>
                    )}
                    <div className="pt-2" style={{ marginLeft: "10px" }}>
                      <div className="d-flex flex-row ">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />

                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? category : "Chinese"}
                        </p>
                      </div>
                      <hr />

                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Cheese " + category : "Pizza"}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Normal " + category : "Pasta"}
                        </p>
                      </div>
                    </div> */}
                  </div>
                  <div
                    class={`tab-pane fade ${resturantSelected ? 'show active' : ''
                      } `}
                    id='restaurant'
                    role='tabpanel'
                    aria-labelledby='contact-tab'
                  >
                    {SearchData?.searchRestaurantView
                      ?.slice(0, 5)
                      ?.map((d, i) => {
                        return (
                          <>
                            <div
                              className='d-flex pt-2'
                              style={{ height: '2.5rem' }}
                            >
                              <div className='searchContentMargin'>
                                <img
                                  src={d.restaurantLogo}
                                  alt='img'
                                  className='searchDialogImg'
                                ></img>
                              </div>
                              <div className='d-flex flex-column searchContentMargin'>
                                <a
                                  href={d.urlName}
                                  className='searchText searchTitile d-flex gap-2'
                                  style={{ textDecoration: 'none' }}
                                >
                                  {
                                    highlightMatch(d.name, SearchString)
                                  }

                                  {/* Restaurant Name for restaurant tab */}
                                  <div style={{ marginTop: '0px' }}>
                                    <StarRating starImage={star} rating={4.5} />
                                  </div>
                                </a>

                                <div className='d-flex flex-row'>
                                  <p
                                    className='searchText searchratingText'
                                    style={{
                                      marginBottom: '0px',
                                      color: '#80615b',
                                    }}
                                  >
                                    {
                                      highlightMatch(d.restaurantCategories, SearchString)
                                    }
                                  </p>
                                </div>
                              </div>
                              {/* <div className="searchLabelRestaurant">
                            <p className="searchText">Restaurant</p>
                          </div> */}
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    {/* {category === "" && (
                      <div className="pt-2">
                        <h5 style={{ fontWeight: "700" }}>Top Categories</h5>
                      </div>
                    )}
                    <div className="pt-2" style={{ marginLeft: "10px" }}>
                      <div className="d-flex flex-row ">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />

                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? category : "Chinese"}
                        </p>
                      </div>
                      <hr />

                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Cheese " + category : "Pizza"}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex flex-row">
                        <img
                          src={search}
                          alt=""
                          style={{
                            width: "0.9rem",
                            height: "0.9rem",
                            marginRight: "1rem",
                            marginTop: "3px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                          {category ? "Normal " + category : "Pasta"}
                        </p>
                      </div>
                    </div> */}
                    {
                      loadingSearch ?
                        <div className="loading-text">Loading...</div>
                        :
                        SearchData.searchRestaurantView.length == 0 ? ( // changing here
                          <div className="d-flex justify-content-center">
                            <span>No Data Found</span>
                          </div>
                        ) : SearchData.searchRestaurantView.length > 3 ? (
                          // &&
                          //   props.showSeeMore != false ?
                          <div
                            className='d-flex justify-content-start ms-3'
                            onClick={handleSeeMoreClick}
                          >
                            <Link
                              to='/restaurant-search'
                              state={SearchString}
                              style={{ textDecoration: 'none' }}
                            >
                              <div className='Search-info-container'>
                                <div className='search-result-icon me-2'>
                                  <img src={searchIcon} alt='search icon' />
                                </div>
                                <div className='search-term'>
                                  Search for "{SearchString}"
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          ''
                        )}
                  </div>
                  <div
                    class='tab-pane fade'
                    id='posts'
                    role='tabpanel'
                    aria-labelledby='contact-tab'
                  >
                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                      <div className='searchContentMargin'>
                        <img
                          src={pasta}
                          alt='img'
                          className='searchDialogImg'
                        ></img>
                      </div>
                      <div className='d-flex flex-column searchContentMargin'>
                        <p className='searchText searchTitile'>
                          Chicken cheese burger
                        </p>

                        <div className='d-flex flex-row'>
                          <p
                            className='searchText searchratingText'
                            style={{ marginBottom: '0px', color: '#80615b' }}
                          >
                            Pot kettle black
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                      <div className='searchContentMargin'>
                        <img
                          src={pasta}
                          alt='img'
                          className='searchDialogImg'
                        ></img>
                      </div>
                      <div className='d-flex flex-column searchContentMargin'>
                        <p className='searchText searchTitile'>
                          Chicken cheese burger
                        </p>

                        <div className='d-flex flex-row'>
                          <p
                            className='searchText searchratingText'
                            style={{ marginBottom: '0px', color: '#80615b' }}
                          >
                            Pot kettle black
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className='d-flex pt-2' style={{ height: '2.5rem' }}>
                      <div className='searchContentMargin'>
                        <img
                          src={pasta}
                          alt='img'
                          className='searchDialogImg'
                        ></img>
                      </div>
                      <div className='d-flex flex-column searchContentMargin'>
                        <p className='searchText searchTitile'>
                          Chicken cheese burger
                        </p>

                        <div className='d-flex flex-row'>
                          <p
                            className='searchText searchratingText'
                            style={{ marginBottom: '0px', color: '#80615b' }}
                          >
                            Pot kettle black
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {category === '' && (
                      <div className='pt-2'>
                        <h5 style={{ fontWeight: '700' }}>Top Categories</h5>
                      </div>
                    )}
                    <div className='pt-2' style={{ marginLeft: '10px' }}>
                      <div className='d-flex flex-row '>
                        <img
                          src={search}
                          alt=''
                          style={{
                            width: '0.9rem',
                            height: '0.9rem',
                            marginRight: '1rem',
                            marginTop: '3px',
                          }}
                        />

                        <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                          {category ? category : 'Chinese'}
                        </p>
                      </div>
                      <hr />

                      <div className='d-flex flex-row'>
                        <img
                          src={search}
                          alt=''
                          style={{
                            width: '0.9rem',
                            height: '0.9rem',
                            marginRight: '1rem',
                            marginTop: '3px',
                          }}
                        />
                        <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                          {category ? 'Cheese ' + category : 'Pizza'}
                        </p>
                      </div>
                      <hr />
                      <div className='d-flex flex-row'>
                        <img
                          src={search}
                          alt=''
                          style={{
                            width: '0.9rem',
                            height: '0.9rem',
                            marginRight: '1rem',
                            marginTop: '3px',
                          }}
                        />
                        <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                          {category ? 'Normal ' + category : 'Pasta'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {/* notification */}
        {/* <Notification /> */}
        {/* end notification */}
        <div className='d-flex cart-design-with-login align-items-center'>
          <div className={`${defaultColor ? 'd-none' : ''}`}>
            <Cart />
          </div>
          {/* <Cart /> */}
          <div className='login-Registration  d-flex align-items-center'>
            {/* <div className={` dropdown dropdownBtn ${userVal !== "" ? "changeBtnDisplay" : ""} ${navbarOnTop ? "dropdown-color-white" : ""}`}>
                              <button className={`btn  dropdown-toggle btnLogin `} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <div className='d-flex dropdown-Icons'>
                                      <img src={menuImg} alt="" style={{ width: "1.5rem", height: "1.5rem" }} />
                                      <img src={loginDesktopImg} alt="" style={{ width: "2rem" }} />
                                  </div>

                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a className="dropdown-item dropdownItem" onClick={showModalInfo}>Login</a>
                                  <a className="dropdown-item dropdownItem" href='/register' >Add Your Restaurant</a>
                              </div>

                          </div> */}

            <span
              className={
                '  banner-signUp ' +
                (userVal !== '' ? 'helloUserName' : 'changeBtnDisplay ')
              }
            >
              <span style={{ color: navbarOnTop ? 'white' : 'black' }}>
                Hello, {userVal}
              </span>
            </span>
            <button
              type='button'
              className={
                ' btn btn-primary banner-signUp ' +
                (userVal !== '' ? '' : 'changeBtnDisplay')
              }
              onClick={handleLogout}
            >
              <span style={{ color: navbarOnTop ? 'white' : 'black' }}>
                Log Out
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    {/* Navbar for mobile device */}
    <div className='mobileDisplay'>
      <div
        className={`navbarGoTaste ${navbarOnTop ? 'backgroundTranparent' : 'backgroundWhite'
          } ${slideUp ? 'slideDown' : ' slideUp '}`}
      >
        <button
          class='btn me-2'
          type='button'
          data-bs-toggle='offcanvas'
          data-bs-target='#offcanvasExample'
          aria-controls='offcanvasExample'
        >
          <i
            class={`fa-solid fa-bars text-light   ${navbarOnTop ? 'text-light' : 'text-dark'
              }`}
          ></i>
        </button>

        <div className='searchInput mobileDisplay' onClick={moibleSearchBarClicked}>
          <div
            className={` ${navbarOnTop
              ? 'search-field-color-white '
              : 'search-field-color-coffee '
              }search-field d-flex align-items-center`}
          >
            <button className='btn btn-search-Mobile' >
              <div className='d-flex'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div>
                    <img
                      src={searchIcontCoffee}
                      alt=''
                      className='searchIconcoffee'
                      onClick={handleShow}
                    />
                  </div>
                </div>
                <div className='d-flex searchBtnText'>
                  <b className='text-muted searchForFood' onClick={() => setSearchConditionOnClick('All')}>Search For Food</b>
                  <span className='d-flex gap-1 search-criteria'>
                    <span onClick={() => setSearchConditionOnClick('All')}>All</span>|
                    <span onClick={() => setSearchConditionOnClick('Food')}>Food</span>|
                    <span onClick={() => setSearchConditionOnClick('Restaurant')}>Restaurant</span>|
                    <span onClick={() => setSearchConditionOnClick('Post')}>Post</span>
                  </span>
                </div>
              </div>
            </button>
            {/* <button className='btn btn-search'>
                          <img src={searchIcont} alt="" className='searchIcon' />
                      </button> */}
          </div>
        </div>
      </div>
    </div>

    {/* login window */}
    <div
      className='modal fade'
      id='exampleModalCenter'
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalCenterTitle'
      aria-hidden='true'
      data-bs-backdrop='static'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header d-flex justify-content-between loginModalCloseBtn'>
            {passwordView ? (
              <button
                type='button '
                className={'close-btn ' + (isRegisterPage ? 'd-none' : '')}
                onClick={() => {
                  setPasswordView(false);
                  setisForgetPasswordSubmited(false);
                  dispatch(openRegisterPageFalse());
                }}
              >
                {isRegisterPage}
                <span aria-hidden='true'>
                  <img src={backBtn} alt='' style={{ width: '1.2rem' }} />
                </span>
              </button>
            ) : forgetPassword ? (
              forgetPasswordToggle ? (
                ''
              ) : (
                <button
                  type='button '
                  className={'close-btn ' + (isRegisterPage ? 'd-none' : '')}
                  onClick={() => {
                    setforgetPassword(false);
                    setPasswordView(true);
                    setisForgetPasswordSubmited(false);
                    dispatch(closeForgetPassword());
                    dispatch(openRegisterPageFalse());
                  }}
                >
                  {isRegisterPage}
                  <span aria-hidden='true'>
                    <img src={backBtn} alt='' style={{ width: '1.2rem' }} />
                  </span>
                </button>
              )
            ) : (
              <button
                type='button '
                className={'close-btn ' + (isRegisterPage ? 'd-none' : '')}
                onClick={hideModalInfo}
              >
                {isRegisterPage}
                <span aria-hidden='true'>&times;</span>
              </button>
            )}

            {/* <button type="button " className={"close " + (isRegisterPage ? "" : "d-none")} onClick={() => dispatch(openRegisterPageFalse())}>
                              {isRegisterPage}
                              <span aria-hidden="true"><img src={backBtn} alt="" style={{ width: "1.2rem" }} /></span>
                          </button> */}
            <button
              type='button '
              className={'close ' + (isRegisterPage ? '' : 'd-none')}
              onClick={() => {
                dispatch(openRegisterPageFalse());
              }}
            >
              {isRegisterPage}
              <span aria-hidden='true'>
                <img src={backBtn} alt='' style={{ width: '1.2rem' }} />
              </span>
            </button>
            <button
              type='button '
              className={
                'close ' +
                (forgetPassword && forgetPasswordToggle ? '' : 'd-none')
              }
              onClick={() => {
                dispatch(closeForgetPassword());
                setforgetPassword(false);
              }}
            >
              {isRegisterPage}
              <span aria-hidden='true'>
                <img src={backBtn} alt='' style={{ width: '1.2rem' }} />
              </span>
            </button>
            {forgetPassword && forgetPasswordToggle ? (
              <h5
                className={'modal-title ' + (isRegisterPage ? 'd-none' : '')}
                id='exampleModalLongTitle'
              >
                Forget Password
              </h5>
            ) : (
              <>
                <h5
                  className={
                    'modal-title ' + (isRegisterPage ? 'd-none' : '')
                  }
                  id='exampleModalLongTitle'
                >
                  Log in or sign up
                </h5>
                <h5
                  className={
                    'modal-title ' + (isRegisterPage ? '' : 'd-none')
                  }
                  id='exampleModalLongTitle'
                >
                  Finish signing up
                </h5>
              </>
            )}

            <span></span>
          </div>
          <div className='modal-body'>
            {loginModalOpen ? (
              <Login
                loaded='false'
                userFound={handleUserFound}
                userFoundFlag={passwordView}
                forgetEmail={(props) => {
                  setforgetPassword(props);
                  setPasswordView(false);
                }}
                isRegisterPage={isRegisterPage}
                isForgetPasswordSubmited={isForgetPasswordSubmited}
              />
            ) : (
              'loading'
            )}
          </div>
        </div>
      </div>
    </div>

    {/* BottomNavigationBar */}
    <div className='mobileDisplay'>
      <div
        className={`bottomBar ${slideUp ? 'slideDownBottom' : ' slideUpBottom '
          }`}
      >
        <div
          // className="d-flex justify-content-center bottomBar-Item"
          className='row align-items-center '
        >
          {/* <div
            className="d-flex explore align-items-center"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <img src={searchair} alt="" className="searchIconcoffee" />
            <span>Explore</span>
          </div> */}
          <div
            className='col-3   d-flex explore align-items-center'
            onClick={() => {
              goToHome();
            }}
          >
            <img src={logo} alt='' className='searchIconcoffee' />
            <span>Home</span>
          </div>
          <div className='col-6 d-flex explore align-items-center '>
            {/* sdf */}
            <Cart usedIn={'bottomBar'} />
          </div>
          <div className='col-3 d-flex explore align-items-center'>
            <div
              onClick={showModalInfo}
              className={`d-flex explore align-items-center ${userVal !== '' ? 'd-none' : ' changeBtnDisplay'
                }`}
            >
              <img src={loginImg} alt='' className='searchIconcoffee' />
              <span>Login</span>
            </div>
            <div
              className={`d-flex explore align-items-center ${userVal !== '' ? '' : 'd-none'
                }`}
              onClick={handleMenuOpen}
            >
              <img
                src={profileImage ? profileImage : userDefaultImg}
                alt=''
                className='profileImage'
              />
              <span>{userVal}</span>



            </div>
            {/* <button type="button" className={` ${navbarOnTop ? "login-Registration-white " : "login-Registration-coffee  "} btn btn-primary banner-signUp " + ${userVal !== "" ? "changeBtnDisplay" : ""}`} 
                          >
                              <b>Log in or sign up</b>
                          </button> */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'top',
              }}
              transformOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              MenuListProps={{
                sx: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              <MenuItem
                onClick={handleLogout}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent', // Removes hover effect
                  },
                }}
              >
                Logout
              </MenuItem>
            </Menu>

          </div>
        </div>
      </div>
    </div>

    {/* Search Modal */}
    <Modal
      show={show}
      fullscreen='xxl-down'
      onHide={() => setShow(false)}
      className='modalParentDiv'
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <>
          <Link to='/'>
            <div className='d-flex gap-1 align-items-center'>
              <img src={logo} style={{ width: '60px', height: '60px' }} />
              {/* <h3>Go Taste</h3> */}
            </div>
          </Link>
          <div className='d-flex mobileSearchBar'>
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <img
                  src={searchIcontCoffee}
                  alt=''
                  className='searchIconcoffee'
                  onClick={handleShow}
                />
              </div>
            </div>

            <input
              type='text'
              placeholder='Search all'
              className='mobileSearchInput'
              onChange={handleInputChange}
            />
          </div>
          <div>
            <ul class='nav nav-tabs pt-2' id='myTab' role='tablist'>
              <li class='nav-item' role='presentation'>
                <button
                  class={`nav-link ${allSelected ? ' active' : ''}`}
                  id='home-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#all'
                  type='button'
                  role='tab'
                  aria-controls='home'
                  aria-selected='true'
                >
                  All
                </button>
              </li>
              <li class='nav-item' role='presentation'>
                <button
                  class={`nav-link ${foodSelected ? ' active' : ''}`}
                  id='profile-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#food'
                  type='button'
                  role='tab'
                  aria-controls='profile'
                  aria-selected='false'
                >
                  Food
                </button>
              </li>
              <li class='nav-item' role='presentation'>
                <button
                  class={`nav-link ${resturantSelected ? ' active' : ''}`}
                  id='contact-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#restaurant'
                  type='button'
                  role='tab'
                  aria-controls='contact'
                  aria-selected='false'
                >
                  Restaurant
                </button>
              </li>
              {/* <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#posts"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Posts
                </button>
              </li> */}
            </ul>
            <div className='tab-content scroll-bar' id='myTabContent'>
              <div
                className={`tab-pane fade ${allSelected ? 'show active' : ''}  `}
                id='all'
                role='tabpanel'
                aria-labelledby='home-tab'
              >
                {SearchData?.searchFoodView?.slice(0, 3)?.map((d, i) => {
                  return (
                    <>
                      <div
                        className='d-flex pt-2'
                        style={{ minHeight: '2.5rem' }}
                        onClick={() => {
                          getItemDetails(d)
                        }}
                      >
                        <div className='searchContentMargin'>
                          <img
                            src={d.itemImagePath}
                            alt='img'
                            className='searchDialogImg'
                          ></img>
                        </div>
                        <div className='d-flex flex-column searchContentMargin'>
                          <p className='searchText searchTitile'>
                            {highlightMatch(d.foodItemName, SearchString)}
                          </p>
                          {
                            extractContext(d.description, SearchString) ?
                              <div className='d-flex flex-row matched-description'>
                                <p
                                  className='searchText searchratingText'
                                  style={{
                                    marginBottom: '0px',
                                    color: '#80615b',
                                  }}
                                >
                                  {
                                    highlightMatch(extractContext(d.description, SearchString), SearchString)
                                  }
                                </p>
                              </div> :
                              ""
                              // <div className='d-flex flex-row matched-description'>
                              //   <p className='searchText searchratingText'
                              //     style={{ marginBottom: '0px', color: '#80615b', }}
                              //   >
                              //     {d.description.length > 100 ? d.description.slice(0, 36) + '...' : d.description}
                              //   </p>
                              // </div>
                          }

                          {/* Restaurant Name for all tab */}
                          <div>
                            <p
                              className='searchText searchratingText d-flex flex-row gap-2'
                              style={{
                                marginBottom: '0px',
                                color: '#80615b',
                              }}
                            >
                              <span>{d.restaurantName}</span>
                              <div style={{ marginTop: '-2px' }}>
                                <StarRating starImage={star} rating={4.5} />
                              </div>
                            </p>
                          </div>
                        </div>
                        <div className='searchLabel'>
                          <p className='searchText'>Food</p>
                        </div>
                      </div>
                      {
                        highlightMatch(d.foodItemName, SearchString).length <= 1 && !extractContext(d.description, SearchString) &&
                        getMatchedColumn(d, SearchString).map((matchedItem, index) => {
                          const [key, value] = Object.entries(matchedItem)[0];
                          return (
                            <div className='matched-text mt-3 ms-2' key={index}>
                              <p className='mb-0'>{key}</p>  {/* Render the key */}
                              <p className='mb-0'>{highlightMatch(extractContext(value, SearchString), SearchString)}</p> {/* Render the value */}
                            </div>
                          );
                        })
                      }
                      <hr style={{ marginBottom: '0px' }} />
                    </>
                  );
                })}
                {SearchData?.searchRestaurantView
                  ?.slice(0, 2)
                  ?.map((d, i) => {
                    return (
                      <>
                        <div
                          className='d-flex pt-2'
                          style={{ minHeight: '2.5rem' }}
                        >
                          <div className='searchContentMargin'>
                            <img
                              src={d.restaurantLogo}
                              alt='img'
                              className='searchDialogImg'
                            ></img>
                          </div>
                          <div className='d-flex flex-column searchContentMargin'>
                            <p className='searchText searchTitile'>
                              {highlightMatch(d.name, SearchString)}
                            </p>

                            <div className='d-flex flex-row'>
                              <p
                                className='searchText searchratingText'
                                style={{
                                  marginBottom: '0px',
                                  color: '#80615b',
                                }}
                              >
                                {highlightMatch(d.restaurantCategories, SearchString)}
                              </p>
                            </div>
                          </div>
                          <div className='searchLabelRestaurant'>
                            <p className='searchText'>Restaurant</p>
                          </div>
                        </div>
                        <hr style={{ marginBottom: '0px' }} />
                      </>
                    );
                  })}
                {
                  loadingSearch ?
                    <div className="loading-text">Loading...</div>
                    :
                    SearchData.searchFoodView.length == 0 &&
                      SearchData.searchRestaurantView.length == 0 ? (
                      <div className="d-flex justify-content-center">
                        <span>No Data Found</span>
                      </div>
                    ) : (
                      ''
                    )}
              </div>

              <div
                class={`tab-pane fade ${foodSelected ? 'show active' : ''} `}
                id='food'
                role='tabpanel'
                aria-labelledby='profile-tab'
              >
                {SearchData?.searchFoodView?.slice(0, 5)?.map((d, i) => {
                  return (
                    <>
                      <div
                        className='d-flex pt-2'
                        style={{ minHeight: '2.5rem' }}
                        onClick={() => {
                          getItemDetails(d)
                        }}
                      >
                        <div className='searchContentMargin'>
                          <img
                            src={d.itemImagePath}
                            alt='img'
                            className='searchDialogImg'
                          ></img>
                        </div>
                        <div className='d-flex flex-column searchContentMargin'>
                          <p className='searchText searchTitile'>
                            {highlightMatch(d.foodItemName, SearchString)}
                          </p>

                          {
                            extractContext(d.description, SearchString) ?
                              <div className='d-flex flex-row matched-description'>
                                <p
                                  className='searchText searchratingText'
                                  style={{
                                    marginBottom: '0px',
                                    color: '#80615b',
                                  }}
                                >
                                  {
                                    highlightMatch(extractContext(d.description, SearchString), SearchString)
                                  }
                                </p>
                              </div> :
                              ""
                              // <div className='d-flex flex-row matched-description'>
                              //   <p className='searchText searchratingText'
                              //     style={{ marginBottom: '0px', color: '#80615b', }}
                              //   >
                              //     {d.description.length > 100 ? d.description.slice(0, 36) + '...' : d.description}
                              //   </p>
                              // </div>
                          }

                          {/* Restaurant Name for mobile view food tab */}
                          <div>
                            <p
                              className='searchText searchratingText d-flex flex-row gap-2'
                              style={{
                                marginBottom: '0px',
                                color: '#80615b',
                              }}
                            >
                              <span>{d.restaurantName}</span>
                              <div style={{ marginTop: '-2px' }}>
                                <StarRating starImage={star} rating={4.5} />
                              </div>
                            </p>
                          </div>
                        </div>
                        {/* <div className="searchLabel">
                            <p className="searchText">Food</p>
                          </div> */}
                      </div>
                      {
                        highlightMatch(d.foodItemName, SearchString).length <= 1 && !extractContext(d.description, SearchString) &&
                        getMatchedColumn(d, SearchString).map((matchedItem, index) => {
                          const [key, value] = Object.entries(matchedItem)[0];
                          return (
                            <div className='matched-text mt-3 ms-2' key={index}>
                              <p className='mb-0'>{key}</p>  {/* Render the key */}
                              <p className='mb-0'>{highlightMatch(extractContext(value, SearchString), SearchString)}</p> {/* Render the value */}
                            </div>
                          );
                        })
                      }
                      <hr style={{ marginBottom: '0px' }} />
                    </>
                  );
                })}
                {
                  loadingSearch ?
                    <div className="loading-text">Loading...</div>
                    :
                    SearchData.searchFoodView.length == 0 ? (
                      <div className="d-flex justify-content-center">
                        <span>No Data Found</span>
                      </div>
                    ) : SearchData.searchFoodView.length > 5 ? (
                      <div
                        className='d-flex justify-content-start ms-3'
                        onClick={handleSeeMoreClick}
                      >
                        <Link
                          to='/food-search'
                          state={SearchString}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className='Search-info-container'>
                            <div className='search-result-icon me-2'>
                              <img src={searchIcon} alt='search icon' />
                            </div>
                            <div className='search-term'>
                              Search for "{SearchString}"
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
              </div>
              <div
                class={`tab-pane fade ${resturantSelected ? 'show active' : ''
                  } `}
                id='restaurant'
                role='tabpanel'
                aria-labelledby='contact-tab'
              >
                {SearchData?.searchRestaurantView
                  ?.slice(0, 5)
                  ?.map((d, i) => {
                    return (
                      <>
                        <div
                          className='d-flex pt-2'
                          style={{ minHeight: '2.5rem' }}
                        >
                          <div className='searchContentMargin'>
                            <img
                              src={d.restaurantLogo}
                              alt='img'
                              className='searchDialogImg'
                            ></img>
                          </div>
                          <div className='d-flex flex-column searchContentMargin'>
                            <a
                              href={d.urlName}
                              className='searchText searchTitile d-flex gap-2'
                            >
                              {highlightMatch(d.name, SearchString)}

                              {/* Restaurant Name for all tab */}
                              <div style={{ marginTop: '-1px' }}>
                                <StarRating starImage={star} rating={4.5} />
                              </div>
                            </a>


                            <div className='d-flex flex-row'>
                              <p
                                className='searchText searchratingText'
                                style={{
                                  marginBottom: '0px',
                                  color: '#80615b',
                                }}
                              >
                                {highlightMatch(d.restaurantCategories, SearchString)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr style={{ marginBottom: '0px' }} />
                      </>
                    );
                  })}

                {
                  loadingSearch ?
                    <div className="loading-text">Loading...</div>
                    :
                    SearchData.searchFoodView.length == 0 ? (
                      <div className="d-flex justify-content-center">
                        <span>No Data Found</span>
                      </div>
                    ) : SearchData.searchFoodView.length > 5 ? (
                      <div
                        className='d-flex justify-content-start ms-3'
                        onClick={handleSeeMoreClick}
                      >
                        <Link
                          to='/restaurant-search'
                          state={SearchString}
                          style={{ textDecoration: 'none' }}
                        >
                          <div className='Search-info-container'>
                            <div className='search-result-icon me-2'>
                              <img src={searchIcon} alt='search icon' />
                            </div>
                            <div className='search-term'>
                              Search for "{SearchString}"
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
              </div>
              {/* <div
                class="tab-pane fade"
                id="posts"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="d-flex pt-2" style={{ height: "2.5rem" }}>
                  <div className="searchContentMargin">
                    <img
                      src={pasta}
                      alt="img"
                      className="searchDialogImg"
                    ></img>
                  </div>
                  <div className="d-flex flex-column searchContentMargin">
                    <p className="searchText searchTitile">
                      Chicken cheese burger
                    </p>

                    <div className="d-flex flex-row">
                      <p
                        className="searchText searchratingText"
                        style={{ marginBottom: "0px", color: "#80615b" }}
                      >
                        Pot kettle black
                      </p>
                    </div>
                  </div>
                </div>
                <hr style={{ marginBottom: "0px" }} />
                <div className="d-flex pt-2" style={{ height: "2.5rem" }}>
                  <div className="searchContentMargin">
                    <img
                      src={pasta}
                      alt="img"
                      className="searchDialogImg"
                    ></img>
                  </div>
                  <div className="d-flex flex-column searchContentMargin">
                    <p className="searchText searchTitile">
                      Chicken cheese burger
                    </p>

                    <div className="d-flex flex-row">
                      <p
                        className="searchText searchratingText"
                        style={{ marginBottom: "0px", color: "#80615b" }}
                      >
                        Pot kettle black
                      </p>
                    </div>
                  </div>
                </div>
                <hr style={{ marginBottom: "0px" }} />
                <div className="d-flex pt-2" style={{ height: "2.5rem" }}>
                  <div className="searchContentMargin">
                    <img
                      src={pasta}
                      alt="img"
                      className="searchDialogImg"
                    ></img>
                  </div>
                  <div className="d-flex flex-column searchContentMargin">
                    <p className="searchText searchTitile">
                      Chicken cheese burger
                    </p>

                    <div className="d-flex flex-row">
                      <p
                        className="searchText searchratingText"
                        style={{ marginBottom: "0px", color: "#80615b" }}
                      >
                        Pot kettle black
                      </p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="pt-2">
                  <h5 style={{ fontWeight: "700" }}>Top Categories</h5>
                </div>
                <div className="pt-2" style={{ marginLeft: "10px" }}>
                  <div className="d-flex flex-row ">
                    <img
                      src={search}
                      alt=""
                      style={{
                        width: "0.9rem",
                        height: "0.9rem",
                        marginRight: "1rem",
                        marginTop: "3px",
                      }}
                    />

                    <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                      Chinese
                    </p>
                  </div>
                  <hr />

                  <div className="d-flex flex-row">
                    <img
                      src={search}
                      alt=""
                      style={{
                        width: "0.9rem",
                        height: "0.9rem",
                        marginRight: "1rem",
                        marginTop: "3px",
                      }}
                    />
                    <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                      Pizza
                    </p>
                  </div>
                  <hr />
                  <div className="d-flex flex-row">
                    <img
                      src={search}
                      alt=""
                      style={{
                        width: "0.9rem",
                        height: "0.9rem",
                        marginRight: "1rem",
                        marginTop: "3px",
                      }}
                    />
                    <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                      Indian
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>

    <SidebarLogin loginClick={showModalInfo}></SidebarLogin>

    {showModalCard && (
      <FoodModal
        show={showModalCard}
        handleClose={handleClose}
        orderDetails={orderDetails}
        selectedItemImage={selectedItemImage}
        selectedItemId={selectedItemId}
        selectedIndex={selectedImageIndex}
        showUnavailableReasonModal={showUnavailableReasonModal}
        setShowUnavailableReasonModal={setShowUnavailableReasonModal}
        showOutOfHourModal={showOutOfHourModal}
        setShowOutOfHourModal={setShowOutOfHourModal}
        foodDatarestaurant={true}
      />
    )}
  </div>
);
};

export default Navbar;
