import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./OrderDetailsModal.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const OrderDetailsModal = (props) => {
  const [restaurantName, setRestaurantName] = useState([]);
  const { userCartData, totalServiceCharge, deliveryCharge } = props;
  const [transformedData, setTransformedData] = useState([]);
  const [exclusivePrice, setExclusivePrice] = useState(0);
  const [reloadIndication, setReloadIndication] = useState("");

  useEffect(() => {
    if (userCartData && userCartData.length > 0) {
      userCartData.forEach(item => {
        setRestaurantName(prevValue => [...prevValue, item.restaurantName]);
      });
    }
  }, [userCartData]);

  const calculateExclusivePrice = useCallback(() => {
    let totalPrice = 0;

    for (let i = 0; i < userCartData.length; i++) {
      for (let j = 0; j < userCartData[i].subOrders.length; j++) {
        for (
          let k = 0;
          k < userCartData[i].subOrders[j].itemDetails.length;
          k++
        ) {
          const item = userCartData[i].subOrders[j].itemDetails[k];
          totalPrice += item.basePrice * item.foodItemQuantity;

          for (let l = 0; l < item.addOns.length; l++) {
            totalPrice +=
              item.addOns[l].addOnBasePrice * item.addOns[l].addOnQuantity;

            for (let m = 0; m < item.addOns[l].options.length; m++) {
              const option = item.addOns[l].options[m];
              totalPrice += option.optionBasePrice * option.optionQuantity;

              for (let n = 0; n < option.subOptions.length; n++) {
                totalPrice += option.subOptions[n].subOptionBasePrice;
              }
            }
          }
        }
      }
    }

    setExclusivePrice(totalPrice);
    console.log(userCartData);
  }, [userCartData]);

  useEffect(() => {
    calculateExclusivePrice();
  }, [calculateExclusivePrice]);

  const transformCartData = (data) => {
    const transformed = [];

    data.forEach((cart) => {
      cart.subOrders.forEach((subOrder) => {
        subOrder.itemDetails?.forEach((item) => {
          const transformedItem = {
            cartItemId: item.cartItemId,
            foodItemId: item.foodItemId,
            foodItemName: item.foodItemName,
            foodItemImage: item.foodItemImage,
            basePrice: item.basePrice,
            vatInAmount: item.vatInAmount,
            sdInAmount: item.sdInAmount,
            discountInAmount: item.discountInAmount,
            totalPrice: item.totalPrice,
            kitchenId: item.kitchenId,
            kitchenName: item.kitchenName,
            foodItemQuantity: item.foodItemQuantity,
            vatPercentOnSd: 0,
            vatOnSd: 0,
            inclusiveSD: 0,
            addOns: item.addOns?.map((addOn) => ({
              cartAddOnId: addOn.cartAddOnId,
              addOnId: addOn.addOnId,
              addOnName: addOn.addOnName,
              addOnBasePrice: addOn.addOnBasePrice,
              addOnVatAmount: addOn.addOnVatAmount,
              addOnSdAmount: addOn.addOnSdAmount,
              addOnDiscountAmount: addOn.addOnDiscountAmount,
              addOnTotalPrice: addOn.addOnTotalPrice,
              addOnQuantity: addOn.addOnQuantity,
              options: addOn.options?.map((option) => ({
                cartOptionId: option.cartOptionId,
                optionId: option.optionId,
                optionName: option.optionName,
                optionBasePrice: option.optionBasePrice,
                optionVatAmount: option.optionVatAmount,
                optionSdAmount: option.optionSdAmount,
                optionDiscountAmount: option.optionDiscountAmount,
                optionTotalPrice: option.optionTotalPrice,
                optionQuantity: option.optionQuantity,
                optionVatPercentOnSd: 0,
                optionVatOnSd: 0,
                optionInclusiveSD: 0,
                subOptions: option.subOptions?.map((subOption) => ({
                  cartSubOptionId: subOption.cartSubOptionId,
                  subOptionId: subOption.subOptionId,
                  subOptionName: subOption.subOptionName,
                  subOptionBasePrice: subOption.subOptionBasePrice,
                  subOptionVatAmount: subOption.subOptionVatAmount,
                  subOptionSdAmount: subOption.subOptionSdAmount,
                  subOptionDiscountAmount: subOption.subOptionDiscountAmount,
                  subOptionTotalPrice: subOption.subOptionTotalPrice,
                  subOptionVatPercentOnSd: 0,
                  subOptionVatOnSd: 0,
                  subOptionInclusiveSD: 0,
                })),
              })),
            })),
          };

          transformed.push(transformedItem);
        });
      });
    });

    return transformed;
  };

  useEffect(() => {
    if (userCartData) {
      setTransformedData(transformCartData(userCartData));
    }
  }, [userCartData]);

  const getPercentageOrAmount = (amount1, amount2, mode) => {
    if (mode === "percentage") {
      var percentageAmount = (amount2 / amount1) * 100;
      return percentageAmount.toFixed(2); // amount2 is what percentage of amount1
    } else if (mode === "amount") {
      return (amount1 * amount2) / 100; // amount2% of amount1
    } else {
      return "Invalid mode";
    }
  };

  const getBasePrice = () => {
    let total = 0;
    userCartData?.forEach((cart) => {
      cart.subOrders.forEach((subOrder) => {
        subOrder.itemDetails.forEach((item) => {
          total += item.basePrice;
        });
      });
    });

    return total;
  };

  // const calculateSubTotal = () => {
  //     if (!userCartData) return 0;

  //     let total = 0;

  //     userCartData.forEach(data => {
  //         data.subOrders.forEach(subOrder => {
  //             subOrder.itemDetails.forEach(item => {
  //                 // Calculate base item price
  //                 const itemTotal = item.basePrice + item.vatInAmount + item.sdInAmount;
  //                 total += itemTotal * item.foodItemQuantity;

  //                 // Calculate add-ons
  //                 (item.addOns || []).forEach(addOn => {
  //                     // Calculate options for each add-on
  //                     (addOn.options || []).forEach(option => {
  //                         const optionTotal = option.optionBasePrice + option.optionVatAmount + option.optionSdAmount;
  //                         total += optionTotal * option.optionQuantity;

  //                         // Calculate sub-options for each option
  //                         (option.subOptions || []).forEach(subOption => {
  //                             const subOptionTotal = subOption.subOptionBasePrice + subOption.subOptionVatAmount + subOption.subOptionSdAmount;
  //                             total += subOptionTotal;
  //                         });
  //                     });
  //                 });
  //             });
  //         });
  //     });

  //     return total;
  // };

  const [subTotal, setSubTotal] = useState(0);
  const [totalSD, setTotalSD] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const TotalTips = 0;
  // const deliveryCharge = 20;
  const serviceCharge = 0;

  /* Calculating Total of SubOrders */
  useEffect(() => {
    const calculateTotalBasePrice = () => {
      let subTotal = 0;
      let totalVat = 0;
      let totalSd = 0;
      userCartData?.forEach((data) => {
        data.subOrders?.forEach((subOrder) => {
          subOrder.itemDetails.forEach((item) => {
            subTotal += item.basePrice * item.foodItemQuantity;
            totalVat += item.vatInAmount * item.foodItemQuantity;
            totalSd += item.sdInAmount * item.foodItemQuantity;
            // Calculate add-ons
            (item.addOns || []).forEach((addOn) => {
              subTotal += addOn.addOnBasePrice * addOn.addOnQuantity;
              totalVat += addOn.addOnVatAmount * addOn.addOnQuantity;
              totalSd += addOn.addOnSdAmount * addOn.addOnQuantity;

              // Calculate options for each add-on
              (addOn.options || []).forEach((option) => {
                subTotal += option.optionBasePrice * option.optionQuantity;
                totalVat += option.optionVatAmount * option.optionQuantity;
                totalSd += option.optionSdAmount * option.optionQuantity;

                // Calculate sub-options for each option
                (option.subOptions || []).forEach((subOption) => {
                  subTotal += subOption.subOptionBasePrice;
                  totalVat += subOption.subOptionVatAmount;
                  totalSd += subOption.subOptionSdAmount;
                });
              });
            });
          });
        });
      });

      setTotalSD(totalSd);
      setTotalVAT(totalVat);
      setSubTotal(subTotal);
    };
    calculateTotalBasePrice();
  }, [userCartData]);

  const calCulateServiceCharge = () => {
    let serviceChargeAmount = 0;
    props.userCartData.map((d) => {
      var baseServicePrice = d.serviceCharge / 100;
      var totalPrice = 0;
      d.subOrders.map((s) => {
        s.itemDetails.map((i) => {
          totalPrice += i.basePrice * i.foodItemQuantity;
          i.addOns.map((a) => {
            totalPrice += a.addOnBasePrice * a.addOnQuantity;
            a.options.map((o) => {
              totalPrice += o.optionBasePrice * o.optionQuantity;
              o.subOptions.map((ss) => {
                totalPrice += ss.subOptionBasePrice;
              });
            });
          });
        });
      });
      var calculatedServiceCharge = baseServicePrice * totalPrice;
      serviceChargeAmount += calculatedServiceCharge;
    });
    return serviceChargeAmount;
  };

  const calculateTotal = () => {
    var serviceCharge = calCulateServiceCharge();
    var converteddeliveryCharge = deliveryCharge ? deliveryCharge : 0;
    var total =
      subTotal + totalSD+calCulateServiceCharge()*0.2 + totalVAT + converteddeliveryCharge + serviceCharge;
    return total.toFixed(2);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vright"
      righted
    >
      <div className="price-modal">
        <Modal.Header closeButton className="order-modal">
          <Modal.Title id="contained-modal-title-vright">
            Pricing Table
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="order-modal">
          {/* {JSON.stringify(props)} */}
          {transformedData?.map((cartData, idx) => {
            const calculateTableTotal = () => {
              // Total for main food item: (Exec. Price + VAT + SD) * Quantity
              const mainItemTotal =
                [
                  cartData.basePrice,
                  cartData.vatInAmount,
                  cartData.sdInAmount,
                ].reduce((a, b) => a + b, 0) * cartData.foodItemQuantity;

              const optionTotal = cartData.addOns
                ?.flatMap((addon) =>
                  addon.options?.map(
                    (option) =>
                      [
                        option.optionBasePrice,
                        option.optionVatAmount,
                        option.optionSdAmount,
                      ].reduce((a, b) => a + b, 0) * option.optionQuantity
                  )
                )
                .reduce((a, b) => a + b, 0);

              const subOptionTotal = cartData.addOns
                ?.flatMap((addon) =>
                  addon.options?.flatMap((option) =>
                    option.subOptions?.map((subOption) =>
                      [
                        subOption.subOptionBasePrice,
                        subOption.subOptionVatAmount,
                        subOption.subOptionSdAmount,
                      ].reduce((a, b) => a + b, 0)
                    )
                  )
                )
                .reduce((a, b) => a + b, 0);

              const addonsTotal = cartData.addOns
                ?.map(
                  (option) =>
                    [
                      option.addOnBasePrice,
                      option.addOnSdAmount,
                      option.addOnVatAmount,
                    ].reduce((a, b) => a + b, 0) * option.addOnQuantity
                )
                .reduce((a, b) => a + b, 0);
              // Return final total for the table
              return mainItemTotal + optionTotal + subOptionTotal + addonsTotal;
            };

            const tableTotal = calculateTableTotal();

            return (
              <div className="mb-5" key={idx}>
                <TableContainer component={Paper} className="table-container">
                  <Table
                    sx={{ minWidth: 950 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                    {restaurantName && restaurantName[idx] && (
                          <h5 style={{ marginLeft: '5px', color: 'gray' }}>
                            {restaurantName[idx]}
                          </h5>
                        )}
                      <TableRow>
                        
                        <TableCell></TableCell>
                        <TableCell align="right">Excl. Price</TableCell>
                        <TableCell align="right">VAT %</TableCell>
                        <TableCell align="right">VAT</TableCell>
                        <TableCell align="right">SD %</TableCell>
                        <TableCell align="right">SD</TableCell>
                        <TableCell align="right">VAT % on SD</TableCell>
                        <TableCell align="right">VAT on SD</TableCell>
                        <TableCell align="right">Inc. SD</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {cartData.foodItemName} * {cartData.foodItemQuantity}
                        </TableCell>
                        <TableCell align="right">
                          £{cartData.basePrice.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {getPercentageOrAmount(
                            cartData.basePrice,
                            cartData.vatInAmount,
                            "percentage"
                          )}
                          %
                        </TableCell>
                        <TableCell align="right">
                          £{cartData.vatInAmount.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {getPercentageOrAmount(
                            cartData.basePrice,
                            cartData.sdInAmount,
                            "percentage"
                          )}
                          %
                        </TableCell>
                        <TableCell align="right">
                          £{cartData.sdInAmount.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          {cartData.vatPercentOnSd}%
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          £{cartData.vatOnSd.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          £{cartData.inclusiveSD.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          £
                          {(
                            [
                              cartData.basePrice,
                              cartData.vatInAmount,
                              cartData.sdInAmount,
                            ].reduce((a, b) => a + b, 0) *
                            cartData.foodItemQuantity
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {/* addons list */}
                      {cartData.addOns?.map((addon, index) => (
                        <>
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="right">
                              +{addon.addOnName} * 1
                            </TableCell>
                            <TableCell align="right">
                              £{addon.addOnBasePrice.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {getPercentageOrAmount(
                                addon.addOnBasePrice,
                                addon.addOnVatAmount,
                                "percentage"
                              )}
                              %
                            </TableCell>
                            <TableCell align="right">
                              £{addon.addOnVatAmount.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {getPercentageOrAmount(
                                addon.addOnBasePrice,
                                addon.addOnSdAmount,
                                "percentage"
                              )}
                              %
                            </TableCell>
                            <TableCell align="right">
                              £{addon.addOnSdAmount.toFixed(2)}
                            </TableCell>
                            {/* [hardCodded] */}
                            <TableCell align="right">0%</TableCell>
                            <TableCell align="right">0%</TableCell>
                            <TableCell align="right">0%</TableCell>
                            {/* End [hardCodded] */}
                            <TableCell align="right">
                              £
                              {(
                                [
                                  addon.addOnBasePrice,
                                  addon.addOnVatAmount,
                                  addon.addOnSdAmount,
                                ].reduce((a, b) => a + b, 0) * 1
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      {/* Option List */}
                      {cartData.addOns?.map((addon) =>
                        addon.options?.map((option, index) => (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                +{option.optionName} * {option.optionQuantity}
                              </TableCell>
                              <TableCell align="right">
                                £{option.optionBasePrice.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {getPercentageOrAmount(
                                  option.optionBasePrice,
                                  option.optionVatAmount,
                                  "percentage"
                                )}
                                %
                              </TableCell>
                              <TableCell align="right">
                                £{option.optionVatAmount.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {getPercentageOrAmount(
                                  option.optionBasePrice,
                                  option.optionSdAmount,
                                  "percentage"
                                )}
                                %
                              </TableCell>
                              <TableCell align="right">
                                £{option.optionSdAmount.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {option.optionVatPercentOnSd}%
                              </TableCell>
                              <TableCell align="right">
                                £{option.optionVatOnSd.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {option.optionInclusiveSD}
                              </TableCell>
                              <TableCell align="right">
                                £
                                {(
                                  [
                                    option.optionBasePrice,
                                    option.optionVatAmount,
                                    option.optionSdAmount,
                                  ].reduce((a, b) => a + b, 0) *
                                  option.optionQuantity
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                            {/* SubOptionNames */}
                            {option?.subOptions.map((subOption, i) => (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="right">
                                  + {subOption.subOptionName}
                                </TableCell>
                                <TableCell align="right">
                                  £{subOption.subOptionBasePrice.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  {/* {getPercentageOrAmount(
                                    subOption.subOptionBasePrice,
                                    subOption.subOptionVatAmount,
                                    "percentage"
                                  )} */}
                                  0%
                                </TableCell>
                                <TableCell align="right">
                                  £{subOption.subOptionVatAmount.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  {/* {getPercentageOrAmount(
                                    subOption.subOptionBasePrice,
                                    subOption.subOptionSdAmount,
                                    "percentage"
                                  )} */}
                                  0%
                                </TableCell>
                                <TableCell align="right">
                                  £{subOption.subOptionSdAmount.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  {subOption.subOptionVatPercentOnSd}%
                                </TableCell>
                                <TableCell align="right">
                                  £{subOption.subOptionVatOnSd.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  £{subOption.subOptionInclusiveSD.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  £
                                  {[
                                    subOption.subOptionBasePrice,
                                    subOption.subOptionVatAmount,
                                    subOption.subOptionSdAmount,
                                  ]
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="d-flex justify-content-between mt-2 mx-3">
                  <div className="fw-bold">Total</div>
                  <div className="fw-bold">£{tableTotal.toFixed(2)}</div>
                </div>
              </div>
            );
          })}

          <hr />

          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Excl. Total Item Price</h5>
            <h5>£{exclusivePrice.toFixed(2)}</h5>
          </div>
          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Sub Total</h5>
            <h5>£{subTotal.toFixed(2)}</h5>
          </div>
          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Total SD</h5>
            <h5>£{(totalSD+calCulateServiceCharge()*0.2).toFixed(2)}</h5>
          </div>
          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Price Inc. SD</h5>
            <h5>£{(subTotal + totalSD+calCulateServiceCharge()*0.2).toFixed(2)}</h5>
          </div>
          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Service Charge </h5>
            <h5>£{calCulateServiceCharge().toFixed(2)}</h5>
          </div>
          {/* <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Service Charge VAT.(20%)</h5>
            <h5>£{serviceCharge.toFixed(2)}</h5>
          </div> */}

          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Delivery Charge</h5>
            <h5>{deliveryCharge ? <>£{deliveryCharge}</> : <>£0.00</>}</h5>
          </div>

          <div className="d-flex justify-content-between ms-3 orderDetails">
            <h5>Total VAT</h5>
            <h5>£{totalVAT.toFixed(2)}</h5>
          </div>

          <div className="d-flex justify-content-around mt-5">
            <div className="fw-bold fs-4">Total Price</div>
            <div className="fw-bold fs-4">£{calculateTotal()}</div>
          </div>
        </Modal.Body>

        <Modal.Footer className="order-modal">
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;
