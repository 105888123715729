import React from "react";

function NotificationData({ notification, indx, FollowImg, likedImg, tab }) {
  return (
    <div className="liked_reply">
      <div className="not_img">
        {notification.notificationType === "Follow" ? (
          <img src={FollowImg} />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853Z"></path>
          </svg>
        )}
      </div>
      <div>
        <div className="name_img">
          <img
            src={
              notification.restaurantImage
                ? notification.restaurantImage
                : likedImg
            }
            className="userImg"
          />
          {/* <span className="name">{notification.message.split(" ")[0]}</span>
          <span>{notification.message.split(" ").slice(1).join(" ")}</span> */}
          {tab == 1 && (
            <>
              {notification.notificationType == "CommentLike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Liked your reply
                </span>
              )}
              {notification.notificationType == "PostLike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Liked your Post 1
                </span>
              )}
              {notification.notificationType == "PostDislike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Dislike your Post
                </span>
              )}
              {notification.notificationType == "Follow" && (
                <span>
                  <span className="name">{notification.fromUserName}</span>
                  followed you
                </span>
              )}
            </>
          )}
          {tab == 2 && (
            <>
              {notification.notificationType == "CommentLike" && (
                <span>
                  You Liked{" "}
                  <span className="name">{notification.forUserName}'s</span>
                  reply
                </span>
              )}
              {notification.notificationType == "PostLike" && (
                <span>
                  You Like{" "}
                  <span className="name">{notification.forUserName}'s</span>
                  Post
                </span>
              )}
              {notification.notificationType == "Follow" && (
                <span>
                  You followed
                  <span className="name">{notification.forUserName}</span>
                </span>
              )}
            </>
          )}
          {tab == 3 && (
            <>
              {notification.notificationType == "CommentLike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Liked your reply
                </span>
              )}
              {notification.notificationType == "PostLike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Liked your Post
                </span>
              )}
              {notification.notificationType == "PostDislike" && (
                <span>
                  <span className="name">{notification.fromUserName} </span>
                  Dislike your Post
                </span>
              )}
              {notification.notificationType == "Follow" && (
                <span>
                  <span className="name">{notification.fromUserName}</span>
                  followed you
                </span>
              )}
            </>
          )}
          {!notification.isRead && <span className="unSeen_not"></span>}
        </div>
        <p className="desc">
          {notification.descriptions.length > 30
            ? notification.descriptions.slice(0, 30) + "..."
            : notification.descriptions}
        </p>
      </div>
    </div>
  );
}

export default NotificationData;
