import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setUsername,
  ReloadPageFalse,
  ReloadPageTrue,
  closeWindow,
} from "./../../actions/index";
import jwt_decode from "jwt-decode";

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.token;
    let expirationDate = params.expirationDate;
    localStorage.setItem("gotasteClienttoken", value);
    let decoded = null;
    try {
      const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");
      decoded = jwt_decode(gotasteClienttoken);
      console.log(decoded);
    } catch (error) {
      // Invalid gotasteClienttoken
    }
    // myWindow.opener.document.write("<p>This is the source window!</p>");
    // window.close(true);

    window.opener.postMessage(true, "*");

    dispatch(closeWindow(false));
    // return window.opener.value = "success";

    // window.location.reload(false);
    window.Location.href = "/";

    // navigate("/home");
  });
  return (
    <div>
      <h3>Success</h3>
      <button
        onClick={() => {
          dispatch(closeWindow(true));
        }}
      >
        close
      </button>
    </div>
  );
};

export default SuccessPage;
