import React, { useEffect, useRef, useState } from 'react';
import './DragModal.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import plus from "../../../../Assets/Icons/plus.png";
import close from "../../../../Assets/Icons/remove.png"
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MultiBackend } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TouchTransition } from 'react-dnd-multi-backend';

const DragModal = ({
    showDraggableModal,
    setShowDraggableModal,
    initialData,
    setInitialData,
    setEditImage,
    setEditImageUrl,
    setShow,
    uploadfiles,
    setUploadFiles,
    setImages,
    images,
    setSelectedImageIndex,
    initialCroppedAreaPixels,
    setInitialCroppedAreaPixels,
    croppedImages,
    setCroppedImages,
    setIsImageSelected,
    setCropForOneByOne,
    setZoomForOneByOne,
    setCropForFourByFive,
    setZoomForFourByFive,
    setTypeOfCrop,
    setCroppedAreaPixelsForOneByOne,
    setCroppedAreaPixelsForFourByFive,
    setInitialCroppedAreaPixelsForOneByOne,
    setInitialCroppedAreaPixelsForFourByFive,
    setCroppedImageForOnyByOne,
    setCroppedImageForFourByFive,
}) => {
    // const [data, setData] = useState([...initialData]);

    const draggableModalRef = useRef(null);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !draggableModalRef.current.contains(event.target)) {
                setShowDraggableModal(false);
                // setIsImageSelected(!isImageSelected);
            }
        };

        if (showDraggableModal) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowDraggableModal, showDraggableModal]);

    const openModal = (event) => {
        // if (modalVisible === false) {
        //     setShowDraggableModal(!showDraggableModal)
        // }
        event.stopPropagation();
        setShowDraggableModal(!showDraggableModal)
    };

    console.log("draggable: ", '\n', initialData, '\n', initialCroppedAreaPixels, '\n', croppedImages);

    const moveCard = (dragIndex, hoverIndex) => {
        const draggedCard = initialData[dragIndex];
        const newCards = [...initialData];
        newCards.splice(dragIndex, 1);
        newCards.splice(hoverIndex, 0, draggedCard);
        setInitialData(newCards);

        const newCroppedAreas = [...initialCroppedAreaPixels];
        const draggedCroppedArea = initialCroppedAreaPixels[dragIndex];
        newCroppedAreas.splice(dragIndex, 1);
        newCroppedAreas.splice(hoverIndex, 0, draggedCroppedArea);
        setInitialCroppedAreaPixels(newCroppedAreas);

        const newCroppedImages = [...croppedImages];
        const draggedCroppedImage = croppedImages[dragIndex];
        newCroppedImages.splice(dragIndex, 1);
        newCroppedImages.splice(hoverIndex, 0, draggedCroppedImage);
        setCroppedImages(newCroppedImages);

        console.log("draggable 2: ", '\n', initialData, '\n', initialCroppedAreaPixels, '\n', croppedImages);
    };

    const Card = ({ id, image, url, index }) => {
        const [{ isDragging }, drag] = useDrag({
            type: 'card', // Define the type property here
            item: { id, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: 'card',
            hover(item, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                const hoverBoundingRect = ref.current.getBoundingClientRect();
                const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientX = clientOffset.x - hoverBoundingRect.left;
                if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                    return;
                }
                moveCard(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });

        const ref = useRef(null);
        drag(drop(ref));

        const [isGrabbing, setIsGrabbing] = useState(false);

        const handleMouseDown = () => setIsGrabbing(true);
        const handleMouseUp = () => setIsGrabbing(false);

        return (
            <div ref={ref}
                style={{ opacity: isDragging ? 0 : 1 }}
                className={isGrabbing ? "grabbing-cursor" : "grab-cursor"}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}>
                <div className="dnd-item" onClick={() => handleSelectImage(image, index)}>
                    <img src={url} alt="image" />
                </div>
            </div>
        );
    };

    // const handleFileChange = (event) => {
    //     const files = event.target.files;
    //     const newImages = Array.from(files).map((file, index) => ({
    //         id: `id${Date.now()}_${index}`,
    //         url: URL.createObjectURL(file),
    //         selected: false // Use object URL to show image preview
    //     }));
    //     setData(prevData => [...prevData, ...newImages]);
    //     setInitialData(prevData => [...prevData, ...newImages])
    // };

    const handleFileChange = (event) => {
        console.log('event', event.target.files);
        const files = event.target.files;
        const newEvents = [];

        const newImages = [];
        for (let i = 0; i < files.length; i++) {
            newEvents.push(files[i]);

            const reader = new FileReader();
            reader.onloadend = () => {
                const id = `id${Date.now()}_${i}`;
                newImages.push({ id, url: reader.result, selected: false });

                if (newImages.length === files.length) {
                    setImages([...images, ...newImages]);
                    //   setData(prevData => [...prevData, ...newImages]);
                    setInitialData([...images, ...newImages]);
                }
            };
            reader.readAsDataURL(files[i]);
        }

        setUploadFiles((prevUploadFiles) => [...prevUploadFiles, ...newEvents]);
        console.log('upload files', uploadfiles);
    };

    const handleRemoveItem = (id, index) => {
        setImages((prevImages) => prevImages.filter((item) => item.id !== id));

        setCroppedImages((prevImages) =>
            prevImages.filter((item, idx) => idx !== index)
        );

        setInitialCroppedAreaPixels((prevInitialCroppedAreaPixels) =>
            prevInitialCroppedAreaPixels.filter((item, idx) => idx !== index)
        );

        setInitialData((prevData) => prevData.filter((item) => item.id !== id));
        setUploadFiles((prevUploadFiles) => prevUploadFiles.filter((item) => item.id !== id));
    };


    const handleSelectImage = (img, index) => {
        setInitialData([...initialData].map(image => ({
            ...image,
            selected: image.id === img.id
        })))

        setEditImageUrl(img.url);
        setShow(true);
        setEditImage(true);
        setIsImageSelected(true);
        setSelectedImageIndex(index);

        setCropForOneByOne({ x: 0, y: 0 });
        setZoomForOneByOne(1);
        setCropForFourByFive({ x: 0, y: 0 });
        setZoomForFourByFive(1);
        setTypeOfCrop('1:1');
        setCroppedAreaPixelsForOneByOne(null);
        setCroppedAreaPixelsForFourByFive(null);
        setInitialCroppedAreaPixelsForOneByOne(null);
        setInitialCroppedAreaPixelsForFourByFive(null);
        setCroppedImageForOnyByOne(null);
        setCroppedImageForFourByFive(null);
    }

    const HTML5toTouch = {
        backends: [
            {
                backend: HTML5Backend,
            },
            {
                backend: TouchBackend,
                options: { enableMouseEvents: true },
                preview: true,
                transition: TouchTransition,
            },
        ],
    };

    return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            {
                showDraggableModal && (
                    <div className={`modal-container ${initialData.length >= 2 ? "" : "modal-container-small"}`} ref={draggableModalRef}>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            slidesPerView={2}
                            navigation
                            allowTouchMove={false}
                            className="mySwiper drag-n-drop"
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {initialData.map((item, idx) => (
                                <SwiperSlide key={item.id} className={`image-container ${initialData.length > 2 ? "" : "image-container-width"}`}>
                                    <Card id={item.id} image={item} url={item.url} index={idx} />

                                    <div className="close-button" onClick={() => handleRemoveItem(item.id, idx)}>
                                        <img src={close} alt="close" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="file-container">
                            <label htmlFor='image' className="file-label">
                                <img src={plus} alt="plus" />
                            </label>
                            <input type="file" accept="image/*" multiple id="image" style={{ display: "none" }} onChange={(event) => handleFileChange(event)} />
                        </div>
                    </div>
                )
            }

            <div style={{ width: "100%" }} ref={wrapperRef}>
                <div className="layer-icon opened" onClick={openModal}>
                    <svg aria-label="Open media gallery" class="x1lliihq x1n2onr6 x9bdzbf" fill="currentColor" height="16" role="img" viewBox="0 0 24 24" width="16"><title>Open media gallery</title><path d="M19 15V5a4.004 4.004 0 0 0-4-4H5a4.004 4.004 0 0 0-4 4v10a4.004 4.004 0 0 0 4 4h10a4.004 4.004 0 0 0 4-4ZM3 15V5a2.002 2.002 0 0 1 2-2h10a2.002 2.002 0 0 1 2 2v10a2.002 2.002 0 0 1-2 2H5a2.002 2.002 0 0 1-2-2Zm18.862-8.773A.501.501 0 0 0 21 6.57v8.431a6 6 0 0 1-6 6H6.58a.504.504 0 0 0-.35.863A3.944 3.944 0 0 0 9 23h6a8 8 0 0 0 8-8V9a3.95 3.95 0 0 0-1.138-2.773Z" fill-rule="evenodd"></path></svg>
                </div>
            </div>
        </DndProvider>
    );
};

export default DragModal;
