import { FILTERED_SECTION_BY_MENU } from "../../actions/types";

const initialState = {
    filteredSectionAndSubSection: {
        sectionList: [], 
        subSectionList: [] 
    },
}

const filteredSectionByMenuReducer = (state = initialState, action) => {
    switch ( action.type ) {
        case FILTERED_SECTION_BY_MENU :
            return {
                ...state,
                filteredSectionAndSubSection: action.payload,
            };
        default:
            return state;
    }
};

export default filteredSectionByMenuReducer;