const initialState = {
    foodItemList: [],
    totalCount:0
}
  
  const fooditems = (state = initialState, action) => {
    switch (action.type) {
      case 'setfooditems':
        return  action.payload
      default:
        return state
    }
  }
  export default fooditems