import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";

const PersonalDetails = () => {
  const [userDetails, setUserDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");
    var decoded = {};

    if (gotasteClienttoken != null) {
      try {
        decoded = jwtDecode(gotasteClienttoken);
      } catch (error) {
        console.log(error);
      }
    }
    const apiAddress = process.env.REACT_APP_SECRET;
    axios({
      method: "get",
      url: `${apiAddress}/api/GoTasteUser/GetGoTasteUserByIdForCheckout/${decoded.UserId}`,
    })
      .then((res) => {
        setUserDetails(res.data);

        // Set the initial form data based on user details
        setFormData({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          phoneNumber: res.data.phoneNumber,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    formValidation();
  }, [formData, isSubmitted]);

  const formValidation = () => {
    if (isSubmitted) {
      const phoneRegex = /^\d{11}$/;

      if (!formData.firstName.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First Name is required.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
      }

      if (!formData.lastName.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last Name is required.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, lastName: "" }));
      }

      if (
        !formData.phoneNumber.trim() ||
        !phoneRegex.test(formData.phoneNumber)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Please enter a valid 10-digit phone number.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    formValidation();

    if (
      Object.values(errors).every((error) => error === "") &&
      Object.values(formData).every((value) => value.trim() !== "")
    ) {
      const gotasteClienttoken = localStorage.getItem("gotasteClienttoken");
      const decoded = jwtDecode(gotasteClienttoken);

      const apiAddress = process.env.REACT_APP_SECRET;
      const userId = decoded.UserId;

      const firstName = formData.firstName.trim();
      const lastName = formData.lastName.trim();
      const phoneNumber = formData.phoneNumber.trim();

      // Check if any data has been modified
      if (
        firstName !== userDetails.firstName ||
        lastName !== userDetails.lastName ||
        phoneNumber !== userDetails.phoneNumber
      ) {
        const data = {
          id: userId,
          firstName: firstName,
          lastName: lastName,
          fullName: firstName + " " + lastName,
          email: userDetails.email,
          phoneNumber: phoneNumber,
          userName: userDetails.userName,
        };

        axios({
          method: "put",
          url: `${apiAddress}/api/GoTasteUser/UpdateGoTasteUserDetails/${userId}`,
          data: data,
        })
          .then((res) => {
            console.log("User details updated successfully:", res.data);

            // Fetch the updated user details
            axios({
              method: "get",
              url: `${apiAddress}/api/GoTasteUser/GetGoTasteUserByIdForCheckout/${userId}`,
            })
              .then((res) => {
                setUserDetails(res.data);
                setSubmissionError(""); // Reset submission error on success
              })
              .catch((err) => {
                console.log(err);
              });

            setModalOpen(false);
          })
          .catch((err) => {
            console.log("Error updating user details:", err);
            setSubmissionError(
              "Error updating user details. Please try again."
            );
          });
      } else {
        // Display a message or handle the case where no changes were made
        setSubmissionError("No changes made. Submitting the same data.");
      }
    }
  };

  return (
    <div>
      <div className="deliveryDetails d-flex justify-content-between">
        <div className="d-flex locaionDetails">
          <div className="d-flex addressCheckOut">
            <h4 className="fw-bold pb-4">Personal details</h4>
            <h6 style={{ padding: "0", margin: "0" }}>
              {userDetails.fullName}
            </h6>
            <h6 className="city" style={{ padding: "0", margin: "0" }}>
              {userDetails.phoneNumber}
            </h6>
          </div>
        </div>
        <div className="d-flex align-items-start">
          <button
            className="btn text-danger"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Edit
          </button>
        </div>
      </div>
      <Modal
        show={modalOpen}
        onHide={() => {
          setModalOpen(false);
          setIsSubmitted(false);
          setSubmissionError("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="container">
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`form-control ${
                  errors.firstName ? "is-invalid" : ""
                }`}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`form-control ${
                  errors.lastName ? "is-invalid" : ""
                }`}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className={`form-control ${
                  errors.phoneNumber ? "is-invalid" : ""
                }`}
              />
              {errors.phoneNumber && (
                <div className="invalid-feedback">{errors.phoneNumber}</div>
              )}
            </div>
            {submissionError && (
              <div className="alert alert-danger mt-2">{submissionError}</div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Close
          </Button>
          <Button variant="dark" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PersonalDetails;
