import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./CustomImageSlider.css";
import CustomSwipperBackwardSvg from "../svg/CustomSwipperBackwardSvg";
import CustomSwipperForwardSvg from "../svg/CustomSwipperForwardSvg";
import MagnifierPlusSvg from "../svg/MagnifierPlusSvg";
import MagnifierMinusSvg from "../svg/MagnifierMinusSvg";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const CustomImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startCoord, setStartCoord] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const pinchStartDistance = useRef(0);
  const lastTap = useRef(0);
  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const MIN_ZOOM = 1;
  const MAX_ZOOM = 3;
  const SENSITIVITY = 2;

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    resetZoomAndPosition();
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex
    );
    resetZoomAndPosition();
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom * 1.2, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom / 1.2, MIN_ZOOM));
  };

  const resetZoomAndPosition = () => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e) => {
    if (zoomLevel <= 1) return;
    e.preventDefault();
    setIsDragging(true);
    setStartCoord({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = (e.clientX - startCoord.x) / SENSITIVITY;
    const y = (e.clientY - startCoord.y) / SENSITIVITY;
    setPosition(limitPosition(x, y));
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    if (zoomLevel <= 1) return;
    if (e.touches.length === 1) {
      e.preventDefault();
      setIsDragging(true);
      const touch = e.touches[0];
      setStartCoord({
        x: touch.clientX - position.x,
        y: touch.clientY - position.y,
      });
    } else if (e.touches.length === 2) {
      pinchStartDistance.current = calculatePinchDistance(e.touches);
    }
  };

  const handleTouchMove = (e) => {
    if (!isDragging && e.touches.length === 1) return;
    if (e.touches.length === 1) {
      e.preventDefault(); // Prevent page scrolling
      const touch = e.touches[0];
      const x = (touch.clientX - startCoord.x) / SENSITIVITY;
      const y = (touch.clientY - startCoord.y) / SENSITIVITY;
      setPosition(limitPosition(x, y));
    } else if (e.touches.length === 2) {
      const currentDistance = calculatePinchDistance(e.touches);
      if (pinchStartDistance.current) {
        const scaleChange = currentDistance / pinchStartDistance.current;
        setZoomLevel((prevZoom) =>
          Math.min(Math.max(prevZoom * scaleChange, MIN_ZOOM), MAX_ZOOM)
        );
      }
    }
  };

  const handleTouchEnd = (e) => {
    setIsDragging(false);
    if (e.touches.length < 2) {
      pinchStartDistance.current = 0;
    }
    handleDoubleTap(e);
  };

  const calculatePinchDistance = (touches) => {
    const [touch1, touch2] = touches;
    return Math.sqrt(
      Math.pow(touch1.clientX - touch2.clientX, 2) +
      Math.pow(touch1.clientY - touch2.clientY, 2)
    );
  };

  const handleDoubleTap = (e) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap.current;

    if (tapLength < 300 && tapLength > 0) {
      setZoomLevel((prevZoom) => (prevZoom === 1 ? 2 : 1));
      setPosition({ x: 0, y: 0 });
    } else {
      lastTap.current = currentTime;
    }
  };

  const limitPosition = (x, y) => {
    const image = imageRef.current;
    const container = containerRef.current;
    if (!image || !container) return { x: 0, y: 0 };

    const rect = image.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const maxX = (rect.width * zoomLevel - containerRect.width) / 2;
    const maxY = (rect.height * zoomLevel - containerRect.height) / 2;

    return {
      x: Math.max(Math.min(x, maxX), -maxX),
      y: Math.max(Math.min(y, maxY), -maxY),
    };
  };

  useEffect(() => {
    // Reset position when zoom level changes
    setPosition({ x: 0, y: 0 });
  }, [zoomLevel, currentIndex]);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="image-slider-container">
      {images.length > 1 && (
        <div
          className="image-slider-button image-slider-button-prev"
          onClick={handlePrevClick}
        >
          <CustomSwipperBackwardSvg width={24} height={24} />
        </div>
      )}
      {!isMobile && (
        <div className="zoom-controls">
          <MagnifierPlusSvg
            width={24}
            height={24}
            className="zoom-icon"
            onClick={handleZoomIn}
          />
          <MagnifierMinusSvg
            width={24}
            height={24}
            className={`zoom-icon ${zoomLevel === MIN_ZOOM ? "disabled" : ""}`}
            onClick={handleZoomOut}
          />
        </div>
      )}
      {!isMobile ? (
        <div
          className="image-container"
          ref={containerRef}
          onMouseDown={!isMobile ? handleMouseDown : null}
          onMouseMove={!isMobile ? handleMouseMove : null}
          onMouseUp={!isMobile ? handleMouseUp : null}
          onMouseLeave={!isMobile ? handleMouseUp : null}
          onTouchStart={isMobile ? handleTouchStart : null}
          onTouchMove={isMobile ? handleTouchMove : null}
          onTouchEnd={isMobile ? handleTouchEnd : null}
          style={{
            cursor: zoomLevel > 1 ? "grab" : "default",
            touchAction: "none", // This is the key property to prevent scroll
          }}
        >
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            ref={imageRef}
            className={`table-slider-image ${isDragging ? "dragging" : ""}`}
            style={{
              transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
              cursor:
                zoomLevel > 1 ? (isDragging ? "grabbing" : "grab") : "default",
            }}
            draggable={false}
          />
        </div>
      ) : (
        // <div className="image-container">
        //   <TransformWrapper
        //     doubleClick={{
        //       excluded: [], // No elements are excluded from double-click behavior
        //       step: 0.7, // Set double-click zoom step
        //     }}
        //     panning={{
        //       activationKeys: [], // No specific keys for panning activation
        //       excluded: [], // No elements excluded from panning
        //     }}
        //     pinch={{
        //       excluded: [], // No elements excluded from pinch gestures
        //       step: 5, // Set pinch zoom sensitivity
        //     }}
        //     wheel={{
        //       activationKeys: [], // No specific keys for wheel activation
        //       excluded: [], // No elements excluded from wheel gestures
        //       smoothStep: 0.001, // Smooth scrolling on wheel
        //       step: 0.2, // Set wheel zoom sensitivity
        //     }}
        //     onInit={() => console.log("Initialized")} // Placeholder callback for initialization
        //     onPanning={() => console.log("Panning")} // Placeholder callback for panning
        //     onPanningStart={() => console.log("Panning start")} // Placeholder callback for panning start
        //     onPanningStop={() => console.log("Panning stop")} // Placeholder callback for panning stop
        //     onPinching={() => console.log("Pinching")} // Placeholder callback for pinching
        //     onPinchingStart={() => console.log("Pinching start")} // Placeholder callback for pinching start
        //     onPinchingStop={() => console.log("Pinching stop")} // Placeholder callback for pinching stop
        //     onTransformed={() => console.log("Transformed")} // Placeholder callback for when transformation occurs
        //     onWheel={() => console.log("Wheel scroll")} // Placeholder callback for wheel event
        //     onWheelStart={() => console.log("Wheel start")} // Placeholder callback for wheel start
        //     onWheelStop={() => console.log("Wheel stop")} // Placeholder callback for wheel stop
        //     onZoom={() => console.log("Zoom event")} // Placeholder callback for zoom event
        //     onZoomStart={() => console.log("Zoom start")} // Placeholder callback for zoom start
        //     onZoomStop={() => console.log("Zoom stop")}
        //   >
        //     <TransformComponent>
        //       <img
        //         src={images[currentIndex]}
        //         alt={`Slide ${currentIndex + 1}`}
        //         className="table-slider-image"
        //         draggable={false}
        //       />
        //     </TransformComponent>
        //   </TransformWrapper>
        // </div>
        <div className="image-container">
          <TransformWrapper
            doubleClick={{
              excluded: [], // No elements are excluded from double-click behavior
              step: 0.7, // Set double-click zoom step
            }}
            panning={{
              activationKeys: [], // No specific keys for panning activation
              excluded: [], // No elements excluded from panning
            }}
            // panning={{
            //   // Disable panning if not zoomed in
            //   disabled: !isZoomedIn,
            //   activationKeys: [],
            //   excluded: [],
            // }}
            pinch={{
              excluded: [], // No elements excluded from pinch gestures
              step: 2, // Set pinch zoom sensitivity
            }}
            wheel={{
              activationKeys: [], // No specific keys for wheel activation
              excluded: [], // No elements excluded from wheel gestures
              smoothStep: 0.001, // Smooth scrolling on wheel
              step: 0.2, // Set wheel zoom sensitivity
            }}
            onInit={() => console.log("Initialized")} // Placeholder callback for initialization
            onPanning={() => console.log("Panning")} // Placeholder callback for panning
            onPanningStart={() => console.log("Panning start")} // Placeholder callback for panning start
            onPanningStop={() => console.log("Panning stop")} // Placeholder callback for panning stop
            onPinching={() => console.log("Pinching")} // Placeholder callback for pinching
            onPinchingStart={() => console.log("Pinching start")} // Placeholder callback for pinching start
            onPinchingStop={() => console.log("Pinching stop")} // Placeholder callback for pinching stop
            onTransformed={() => console.log("Transformed")} // Placeholder callback for when transformation occurs
            onWheel={() => console.log("Wheel scroll")} // Placeholder callback for wheel event
            onWheelStart={() => console.log("Wheel start")} // Placeholder callback for wheel start
            onWheelStop={() => console.log("Wheel stop")} // Placeholder callback for wheel stop
            onZoom={() => console.log("Zoom event")} // Placeholder callback for zoom event
            // onZoom={(ref) => {
            //   const { scale } = ref.state;
            //   setIsZoomedIn(scale > 1); // Assume scale > 1 indicates zoomed-in state
            // }}
            onZoomStart={() => console.log("Zoom start")} // Placeholder callback for zoom start
            onZoomStop={() => console.log("Zoom stop")} // Placeholder callback for zoom stop
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                {/* The content that will be zoomable */}
                <TransformComponent>
                  <img
                    src={images[currentIndex]}
                    alt="Zoomable content"
                    className='table-slider-image'
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      )}
      {images.length > 1 && (
        <div
          className="image-slider-button image-slider-button-next"
          onClick={handleNextClick}
        >
          <CustomSwipperForwardSvg width={24} height={24} />
        </div>
      )}
    </div>
  );
};

CustomImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomImageSlider;
