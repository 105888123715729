import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import FoodSelectionCardDisplay from "../../CardView/FoodSelectionCard/FoodSelectionCardDisplay";
import Starter from "./../../../Assets/Icons/snacks.png";
import mainDish from "./../../../Assets/Icons/main-course.png";
import dessert from "./../../../Assets/Icons/sweets.png";
import "./FoodSelection.css";

import cardImage from "./../../../Assets/Image/banner.jpg";
import img1 from "./../../../Assets/Image/1 (1).jpg";
import img2 from "./../../../Assets/Image/1 (1).png";
import img3 from "./../../../Assets/Image/1 (2).jpg";
import cakeImg from "./../../../Assets/Image/cake1 1.png";
import chickenImg from "./../../../Assets/Image/delicious-chicken-table 3.png";
import kababImg from "./../../../Assets/Image/Kabab 1.png";
import latteCoffeeImg from "./../../../Assets/Image/latte-coffee-cup 1.png";
import pastaImg from "./../../../Assets/Image/Pasta 1.png";
import soupImg from "./../../../Assets/Image/Soup 3.png";
import bannerImg from "./../../../Assets/Image/banner.jpg";
import LeoImg from "./../../../Assets/Image/Leo.png";
import loadingGif from "./../../../Assets/Image/loading.gif";
import "swiper/css/navigation";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation, FreeMode } from "swiper";
import Rating from "react-rating";

import axios from "axios";
import AllFoodCard from "./AllFoodCard";
import FoodCard from "./FoodCard";
import { FoodItemList, SearchLoader } from "./../../../actions";

const FoodSelection = () => {
  // const { ref: myRef, inView: myElementIsVisible } = useInView();
  const dispatch = useDispatch();
  const foodData = useSelector((state) => state.fooditems);
  const loaderState = useSelector((state) => state.searchLoader);
  const [TotalCount, setTotalCount] = useState(0);
  const [PageNumber, setPageNumber] = useState(1);
  const [FoodItems, setFoodItems] = useState({
    foodItemList: [],
    totalCount: 0,
  });

  console.log("FoodItems", FoodItems);

  const myRef = useRef();
  const [intersecting, setIntersecting] = useState(false);
  const [starterSelection, setstarterSelection] = useState();
  const [mainCourseSelection, setmainCourseSelection] = useState(false);
  const [DessertSelection, setDessertSelection] = useState(false);
  const [lastScrollValue, setLastScrollValue] = useState(0);

  const filterBottomRef = useRef(null);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(false);

  const [showArrows, setShowArrows] = useState(true);

  // useEffect(() => {
  //   // window.addEventListener("scroll", (event) => {
  //   //   if (filterBottomRef.current !== undefined) {
  //   //     const filterBarRect = filterBottomRef?.current?.getBoundingClientRect();
  //   //     const isAboveViewport =
  //   //       filterBarRect?.top < 0
  //   //         ? true
  //   //         : filterBarRect?.top == 0
  //   //         ? false
  //   //         : false;
  //   //     setIntersecting(isAboveViewport);
  //   //     var top = filterBarRect.top * -1;
  //   //     var scrollval = scrollValue * -1;
  //   //     if (top > scrollval) {
  //   //       setSlideUp(true);
  //   //     } else {
  //   //       setSlideUp(false);
  //   //     }
  //   //     var s = filterBarRect.top;
  //   //     setscrollValue(s);
  //   //   }
  //   // });
  // });
  const [dataFilterCollectionss, setDataFilterCollections] = useState([]);
  const [dataSelectedKeyword, setdataSelectedKeyword] = useState(null);

  const apiAddress = process.env.REACT_APP_SECRET;
  const handleItemClick = (id) => {
    setFoodItems(() => ({
      foodItemList: [],
      totalCount: 0,
    }));

    setPageNumber(1);

    // GetFoodByKeywordTagPaged(id, 1, 10);

    setdataSelectedKeyword(id);
    if (dataSelectedKeyword !== id) {
      setdataSelectedKeyword(id);
    } else {
      setDataFilterCollections([]);
      setdataSelectedKeyword(null);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth > 767); // Adjust breakpoint as per your requirement
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //test2 end
  const GetFoodByKeywordTagPaged = async (
    id,
    page,
    pageSize,
    initial = false
  ) => {
    axios
      .get(
        apiAddress +
        `/api/FoodItems/GetAllFoodItemListForClientEndByKeywordTagId?keywordId=${id}&pageNo=${page}&pageSize=${pageSize}`
      )
      .then((response) => {
        console.log(response.data);
        setFoodItems((prev) => ({
          foodItemList: [...prev.foodItemList, ...response.data.fooditemsList],
          totalCount: response.data.totalCount,
        }));
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });

    if (!initial) {
      setPageNumber(PageNumber + 1);
    }
  };

  var res = {
    foodItemList: [],
    totalCount: 0,
  };
  const GetAllFoodPaged = async (pageNo, pageSize, initial = false) => {
    if (dataSelectedKeyword == null) {
      await axios
        .get(
          apiAddress +
          "/api/FoodItems/GetFoodItemForClientendPaged?pageNo=" +
          pageNo +
          "&pageSize=" +
          pageSize
        )
        .then((response) => {
          if (pageNo == 1) {
            setFoodItems({
              foodItemList: [],
              totalCount: 0,
            });
          }
          res.totalCount = response.data.totalCount;
          res.foodItemList.push(...response.data.fooditemsList);
          setTotalCount(response.data.totalCount);
          // dispatch(SearchLoader(false));

          setFoodItems((prev) => ({
            foodItemList: [
              ...prev.foodItemList,
              ...response.data.fooditemsList,
            ],
            totalCount: response.data.totalCount,
          }));
        });
      if (!initial) {
        setPageNumber(PageNumber + 1);
      }
      dispatch(FoodItemList(FoodItems));
    } else {
      GetFoodByKeywordTagPaged(dataSelectedKeyword, pageNo, pageSize);
    }

    return res;
  };

  const [dataCollections, setDataCollections] = useState([]);
  useEffect(() => {
    var res = GetAllFoodPaged(1, 7, true);
    res.then((res) => {
      dispatch(FoodItemList(res));
    });
    axios
      .get(process.env.REACT_APP_SECRET + "/api/Keyword/GetALlKeywordTag")
      .then((response) => {
        setDataCollections(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (dataSelectedKeyword !== null) {
      GetFoodByKeywordTagPaged(dataSelectedKeyword, 1, 4, true);
    }
    // else {
    //   GetAllFoodPaged(1, 4, true);
    // }
  }, [dataSelectedKeyword]);


  /* ----- Functionalities for filter bar top/fixed at scroll ----  */

  // useEffect(() => {
  //   let lastScrollTop = 0;
    
  //   const filterBarDPosition = () => {
  //     var content = document.getElementById("FilterBarParent");
  //     var FilterBarChild = document.getElementById("FilterBarChild");
  //     const filterBarRect = content.getBoundingClientRect();
  //     var indicator = document.getElementById("beforeFood");
  //     const filterBarRectIndi = indicator.getBoundingClientRect();
  //     const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  //     if (currentScrollTop > lastScrollTop && filterBarRect.top <= 0) {
  //       content.style.position = "fixed";
  //       content.style.top = "0";
  //       indicator.style.height = "250px";
  //       FilterBarChild.style.marginTop = "0px";
  //       FilterBarChild.style.marginBottom = "0px";
        
  //     } else if (currentScrollTop < lastScrollTop) {
  //       content.style.top = "70px";
  //       if(filterBarRectIndi.top > 0){
  //         content.style.position = "relative";
  //         content.style.top = "0";
  //         indicator.style.height = "0";

  //         // Reseting margin
  //         FilterBarChild.style.marginTop = "48px";
  //         FilterBarChild.style.marginBottom = "48px";
  //       }
  //     }
  //     content.style.width = "100%";
  //     content.style.zIndex = "100";
  //     content.style.transition = "all 0.3s ease";
  //     content.style.background = "white";
  //     lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
  //   };

  //   window.addEventListener("scroll", filterBarDPosition);
  //   return () => {
  //     window.removeEventListener("scroll", filterBarDPosition);
  //   };
  // }, []);

  return (
    <div id="foodSelectionMainDiv">
      <div>

        <div className="FoodSelection customContainer" id="FilterBarParent">
          <div className={` g-4 mx-auto justify-content-center selectionButton scrollbtnMobile  ${intersecting ? "HideSelectionSingleButton" : ""
              }`} ref={myRef} id="FilterBarChild" style={{ margin: '48px 0px', }}>
            <Swiper
              className="menusliderDiv menuslider row row-cols-1  row-cols-md-4"
              spaceBetween={30}
              grabCursor={false}
              centeredSlides={false}
              slidesPerView={"auto"}
              hashNavigation={{
                watchState: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              navigation={showArrows}
              modules={[Navigation, Pagination]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
            >
              {dataCollections?.map((d, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={`d-flex cardShadow  align-items-center justify-content-between p-3 py-3 m-2 selectionSingleButton ${dataSelectedKeyword === d.id ? " pillCardSelect" : ""
                      }`}
                    onClick={() => handleItemClick(d.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <h2>{d.keywordTagName}</h2>
                    <img
                      className="img-fluid"
                      src={d.keywordTagImage}
                      alt="..."
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div ref={filterBottomRef} id="beforeFood"></div>
        {/* sticky design  */}
        <div
          className={`pillSticky ${intersecting
            ? slideUp
              ? "slide-down "
              : "slideDownMore"
            : "slide-up"
            }`}
        >
          <div className="pillDisplay">
            <div className="d-flex pillItem">
              <Swiper
                className=" row row-cols-1  row-cols-md-4"
                spaceBetween={30}
                grabCursor={false}
                centeredSlides={false}
                slidesPerView={"auto"}
                hashNavigation={{
                  watchState: true,
                }}
                breakpoints={{
                  300: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {dataCollections?.map((d, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className={`pillCard pillBtnOne d-flex align-items-center   ${starterSelection === d.id ? "pillCardSelect" : ""
                        }`}
                      onClick={() => {
                        handleItemClick(d.id);
                        setstarterSelection(d.id);
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={d.keywordTagImage}
                        alt="..."
                      />
                      <h2>{d.keywordTagName}</h2>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        {/* <AllFoodCard
          restaurantName={"Pot kettle"}
          dataFilterCollectionss={dataFilterCollectionss}
          dataSelectedKeyword={dataSelectedKeyword}
        ></AllFoodCard>{" "} */}
        <div className="foodSelectionContainer mx-auto newFeedback">
          <InfiniteScroll
            dataLength={FoodItems.foodItemList.length}
            next={() => GetAllFoodPaged(PageNumber + 1, 4)}
            hasMore={FoodItems.foodItemList.length < TotalCount}
            loader={
              <div className="d-flex justify-content-center ">
                <img src={loadingGif} alt="" srcset="" />
              </div>
            }
          >
            <div className="testContainer">
              {FoodItems.foodItemList.map((dt, i) => {
                return <FoodCard key={i} foodItemData={dt}></FoodCard>;
              })}
            </div>
          </InfiniteScroll>
        </div>

        {/* <div className="d-none">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={cardImage}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>

              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
                 
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={cakeImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>

              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
             
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={soupImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>

              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
            
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={bannerImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
              
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={pastaImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
             
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={latteCoffeeImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
          
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={chickenImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
               
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
              
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={latteCoffeeImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
              
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={kababImg}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
           
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
                
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={cardImage}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
                 
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="foodcart test m-3">
              <div className="d-flex justify-content-end cartIcon">
                <i className="fa fa-plus-circle "></i>
              
              </div>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="foodcartimg"
              >
                <SwiperSlide>
                  <img
                    src={img1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={cardImage}
                    className="card-img-top img-fluid "
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </SwiperSlide>
              </Swiper>
              <div className="foodCartText mt-2">
                <div className="d-flex justify-content-between commentLove">
                  <div className="d-flex commentLove">
                    <i className="fa-regular fa-heart "></i>
                    <i className="fa-sharp fa-regular fa-comment-dots"></i>
                  </div>
              
                  <div className="rating-Font mb-1 d-flex align-items-center starRating">
                    {" "}
                    <Rating
                      className="ratingStar"
                      initialRating={Number(4.5)}
                      emptySymbol="far fa-star icon-color"
                      fullSymbol="fas fa-star icon-color"
                      readonly
                    ></Rating>
                    4.5
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <h3 className="m-0 p-0">Bread Toast</h3>
                  <h4 className="m-0 p-0">10.5$</h4>
                </div>
                <p className="my-1">Topped with tomato, bocconcini... </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6>Red Flame Resta...</h6>
                    <div className="d-flex align-items-center starRating">
                      <i className="fa-sharp fa-solid fa-star"></i>
                      <h4 className="m-0 p-0">4.5</h4>
                    </div>
                  </div>

                  <button className="follow-BUtton btn ms-1">Follow</button>
                </div>

                <div className="d-flex align-items-center">
                  <img src={LeoImg} className="me-1 userImg" alt="" />
                  <p>
                    Leo Love the art of travel. So many plac So many foods...{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

function getCards() {
  let count = 10;
  let cards = [];
  for (let index = 0; index < count; index++) {
    cards.push(<FoodSelectionCardDisplay></FoodSelectionCardDisplay>);
  }
  return cards;
}
function scroll() {
  console.log("Scroll");
}

export default FoodSelection;
