import React from 'react'
import Test3 from '../Test3'
import './Offers.css'
function Offers() {
  return (
    <div>
      <div className="offerContainer  ">
        <div className="offerHeader d-flex justify-content-center">
          <h1 class="display-4 m-auto "> OFFERS</h1>
        </div>
      </div>
      <div class="  mt-3">
        <Test3 />
      </div>
    </div>
  )
}

export default Offers
