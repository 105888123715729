import React from 'react';
import removeIcon from '../../../Assets/Icons/remove.png';
import backButton from '../../../Assets/Icons/back-button.png';
import './ModalHeader.css';

const ModalHeader = ({
  show,
  showEditShareModalInfo,
  hideEditShareModalInfo,
  step,
  setStep,
  setShow,
  showFirstModal,
  showSecondModal,
  showThirdModal,
  setShowFirstModal,
  setShowSecondModal,
  setShowThirdModal,
  showCroppedImage,
  typeOfCrop,
  croppedImageForOnyByOne,
  croppedImageForFourByFive,
  resetCroppedAreaPixels,
  handleCloseAll,
  setEditImage,
  editLoading,
}) => {
  console.log('show', show);
  const editImage = false;

  console.log(
    'sfdsd_____',
    show,
    showFirstModal,
    showSecondModal,
    showThirdModal
  );
  return (
    <div className='modal-header d-flex justify-content-between loginModalCloseBtn'>
      {show &&
        (showFirstModal && !showSecondModal && !showThirdModal ? (
          <>
            <span></span>
            <span className='createPostModal me-4'>Edit post</span>
            <button type='button' className='close' onClick={handleCloseAll}>
              <span aria-hidden='true'>
                <img
                  src={removeIcon}
                  style={{ height: '30px', marginRight: '-10px' }}
                  alt=''
                />
              </span>
            </button>
          </>
        ) : !showFirstModal && !showThirdModal && showSecondModal ? (
          <>
            <button
              type='button'
              className='close'
              onClick={() => {
                // setShow(false);
                setEditImage(false);
                // setShowFirstModal(true);
                // setShowSecondModal(false);
                // hideEditShareModalInfo();

                setShowFirstModal(true);
                setShowSecondModal(false);
                setStep(0);
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={backButton}
                  style={{ height: '30px', marginLeft: '-10px' }}
                  alt=''
                />
              </span>
            </button>
            <span className='cropText'>Crop</span>

            <div
              type='button'
              className='close'
              // onClick={() => {
              //   setShow(false);
              //   onClick();
              // }}
            >
              <span
                aria-hidden='true'
                style={{
                  color: '#371b17',
                  fontWeight: '600',
                  cursor:
                    (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                    (typeOfCrop === '4:5' && croppedImageForFourByFive) ||
                    editLoading
                      ? 'not-allowed'
                      : 'pointer',
                  opacity:
                    (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                    (typeOfCrop === '4:5' && croppedImageForFourByFive) ||
                    editLoading
                      ? 0.5
                      : 1,
                }}
                onClick={
                  (typeOfCrop === '1:1' && croppedImageForOnyByOne) ||
                  (typeOfCrop === '4:5' && croppedImageForFourByFive)
                    ? null
                    : showCroppedImage
                }
              >
                Save
              </span>

              <span style={{ color: 'gray', cursor: 'default' }}> | </span>
              <span
                aria-hidden='true'
                style={{ color: '#371b17', fontWeight: '600' }}
                onClick={resetCroppedAreaPixels}
              >
                Reset
              </span>
            </div>
          </>
        ) : (
          <>
            <button
              type='button'
              className='close'
              onClick={() => {
                setShowFirstModal(true);
                setShowThirdModal(false);
                // setShow(false);
                setStep(0);
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={backButton}
                  style={{ height: '30px', marginLeft: '-10px' }}
                  alt=''
                />
              </span>
            </button>
            <span className='selectRestaurantModal'>
              {step === 0 && 'Search for restaurants'}
              {step === 1 && 'Select Menu'}
              {step === 2 && 'Select Section'}
              {step === 3 && 'Select Food Item'}
            </span>
            <button
              type='button'
              className='close'
              onClick={() => {
                setShow(false);
                hideEditShareModalInfo();
              }}
            >
              <span aria-hidden='true'>
                <img
                  src={removeIcon}
                  style={{ height: '30px', marginRight: '-10px' }}
                  alt=''
                />
              </span>
            </button>
          </>
        ))}
    </div>
  );
};

export default ModalHeader;
