import React from 'react';

export default function TaggedItems({
  linkActive,
  selectedRestaurantDatas,
  selectedMenuDatas,
  selectedSectionDatas,
  selectFoodItemDatas,
  handleRemoveRestaurantItem,
  handleRemoveMenuItem,
  handleRemoveSectionItem,
  handleRemoveFoodItem,
}) {
  console.log(
    'tagged items__',
    linkActive,
    selectFoodItemDatas,
    selectedRestaurantDatas,
    selectedSectionDatas
  );
  return (
    <>
      {linkActive &&
        // selectedRestaurantDatas.length > 0 &&
        // selectedMenuDatas.length === 0 &&
        // selectedSectionDatas.length === 0 &&
        // selectFoodItemDatas.length === 0 &&
        selectedRestaurantDatas.map((item) => (
          <div key={item.id} className='post-button-container'>
            <p className='pill-button'> 
              {' '}
              {item.title ? item.title : item?.restaurantName}{' '}
              <span
                className='cross-icon'
                onClick={() => handleRemoveRestaurantItem(item.id)}
              >
                ×
              </span>
            </p>
          </div>
        ))}
      {linkActive &&
        // selectedMenuDatas.length > 0 &&
        // selectedSectionDatas.length === 0 &&
        // selectFoodItemDatas.length === 0 &&
        selectedMenuDatas.map((item) => (
          <div key={item.id} className='post-button-container'>
            <p className='pill-button'>
              {item.title ? item.title : item?.menuName}{' '}
              <span
                className='cross-icon'
                onClick={() => handleRemoveMenuItem(item.id)}
              >
                ×
              </span>
            </p>
          </div>
        ))}
      {linkActive &&
        // selectedSectionDatas.length > 0 &&
        // selectFoodItemDatas.length === 0 &&
        selectedSectionDatas.map((item) => (
          <div key={item.id} className='post-button-container'>
            <p className='pill-button'>
              {item.label ? item.label : item?.sectionName}{' '}
              <span
                className='cross-icon'
                onClick={() => handleRemoveSectionItem(item.sectionId)}
              >
                ×
              </span>
            </p>
          </div>
        ))}
      {linkActive &&
        // selectFoodItemDatas.length > 0 &&
        selectFoodItemDatas.map((item) => (
          <div key={item.id} className='post-button-container'>
            <p className='pill-button'>
              {item.title || item?.itemName || item?.foodItemName}{' '}
              <span
                className='cross-icon'
                onClick={() => handleRemoveFoodItem(item.foodItemId)}
              >
                ×
              </span>
            </p>
          </div>
        ))}
    </>
  );
}
