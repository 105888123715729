import React from 'react'
import FilterBar from '../Restaurant/MenuSelection/FilterBar/FilterBar'
import RestaurantOffer from './RestaurantOffer/RestaurantOffer'


function AllRestaurantOffers() {
  return (
    <>
        <div className='customContainer'>
            <FilterBar></FilterBar>
        </div>
        <div className='customContainer'>
            <div className='mt-2'>
                <div>
                    <h2>Pot kettle Black</h2>
                </div>
                <RestaurantOffer></RestaurantOffer>
            </div>
            <div className='mt-2'>
                <div>
                    <h2>Domino's Pizza</h2>
                </div>
                <RestaurantOffer></RestaurantOffer>
            </div>
        </div>
    </>
  )
}

export default AllRestaurantOffers