import React from 'react';

export default function CustomSwipperForwardSvg({ width, height }) {
  return (
    <>
      <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12' cy='12' r='12' fill='#C4C4C4' />
        <line
          x1='9'
          y1='8'
          x2='15'
          y2='12'
          stroke='#000000'
          stroke-width='2'
          stroke-linecap='round'
        />
        <line
          x1='15'
          y1='12'
          x2='9'
          y2='16'
          stroke='#000000'
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  );
}
