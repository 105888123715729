// menuReducer.js
import { SET_SELECTED_MENU } from "../../actions/types";

const initialState = {
  selectedMenu: null,  // Add initial state for selected menu
};

const selectedMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: action.payload,
      };
      
    default:
      return state;
  }
};

export default selectedMenuReducer;