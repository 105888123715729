// Importing Action types from the type.js
import { FETCH_MENU_REQUEST, FETCH_MENU_SUCCESS, FETCH_MENU_FAILURE, SET_SELECTED_MENU } from '../types';
import { setSelectedMenu } from './selectedMenuActions';
import { SELECT_FIRST_MENU_ITEM } from '../types';
import axios from 'axios';
const apiAddress = process.env.REACT_APP_SECRET;

export const fetchMenuRequest = () => ({
  type: FETCH_MENU_REQUEST
});

export const fetchMenuSuccess = (menu) => ({
  type: FETCH_MENU_SUCCESS,
  payload: menu
});

export const fetchMenuFailure = (error) => ({
  type: FETCH_MENU_FAILURE,
  payload: error
});

export const fetchMenu = (restaurantId) => {
  return async (dispatch) => {
    dispatch(fetchMenuRequest());
    try {
      const response = await axios.get(`${apiAddress}/api/Menu/GetAllMenuByRestaurantUrlAsync`, {
        params: {
          restaurantId: restaurantId
        }
      });
      dispatch(fetchMenuSuccess(response.data));

      if (response.data.length > 0) {
        dispatch( selectFirstMenuItem(response.data[0]) );
      }

      // if (response.data.length > 0) {
      //   dispatch( setSelectedMenu(response.data[0]) );
      //   // dispatch(selectFirstMenuItem(response.data[0]));
      // }
    } catch (error) {
      dispatch(fetchMenuFailure(error.message || 'An error occurred'));
    }
  };
};

export const selectFirstMenuItem = (data) => ({
  type: SELECT_FIRST_MENU_ITEM,
  payload: data
});

