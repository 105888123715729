import React from 'react';
import '../components/CustomImageSlider.css';

export default function MagnifierPlusSvg({
  width,
  height,
  className,
  ...rest
}) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        className={className}
        {...rest} // This will spread any additional props like onClick, etc.
      >
        <circle cx='11' cy='11' r='8'></circle>
        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
        <line x1='11' y1='8' x2='11' y2='14'></line>
        <line x1='8' y1='11' x2='14' y2='11'></line>
      </svg>
    </>
  );
}
