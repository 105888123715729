import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Shimmer from "../components/Shimmer";
import BannerSection from "../../Home/BannerSection/BannerSection";
import RestaurantDetailView from "../RestaurantDetailView/RestaurantDetailView";
import FilterBar from "../MenuSelection/FilterBar/FilterBar";
import FoodCard from "../../Home/FoodSelection/FoodCard";

const SubSectionPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const keywordName = queryParams.get('name');

    const apiAddress = process.env.REACT_APP_SECRET;
    let { resUrl } = useParams();
    let SubSection = keywordName;

    const [foodItems, setFoodItems] = useState([]);
    useEffect(() => {
        const getFoodItems = async () => {
            const response = await axios.get(apiAddress + `/api/FoodItems/GetFoodItemsBySubSectionName?urlName=` + resUrl + `&SubSectionName=` + keywordName);
            setFoodItems(response.data);
        }
        getFoodItems();
    }, []);

    const [blur, setBlur] = useState(false);
    const searchDialogOpen = (NavbarData) => {
        setBlur(NavbarData);
    };

    const [restaurantData, setRestaurantData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const RestaruantDetails = async () => {
            const response = await axios.get(apiAddress + '/api/Restaurant/getrestaurantdetailsForRestaurantbyName?restaurantName=' +
                resUrl);
            setRestaurantData(response.data);
            setLoading(false);
        }
        RestaruantDetails();
    }, [])

    const [isScrollingUp, setIsScrollingUp] = useState(false);
    const handleScrollingUpValueChange = (value) => {
        setIsScrollingUp(false);
    }

    return (
        <div className='overFlowhidden'>
            <Navbar handleNavbar={searchDialogOpen} />
            {loading ? (
                <div>
                    <Shimmer />
                </div>
            ) : (
                <>

                    <BannerSection backgroundImage={restaurantData} from={'restaurant'} />
                    <RestaurantDetailView restaurantDetails={restaurantData} />
                    <div className={`customContainer me-2 row-filterbar `}>
                        <FilterBar resUrl={resUrl} SubSection={SubSection} currentCategory="" onValueChange={handleScrollingUpValueChange} />
                    </div>

                    {
                        foodItems.length === 0 ? (
                            <div className='foodSelectionContainer mx-auto'>
                                <p>No Food Items Available for this perticular subsection: {keywordName}</p>
                            </div>
                        ) : (
                            foodItems.map((foodItem, index) => (
                                <div className='foodSelectionContainer mx-auto' key={index}>
                                    <div className='testContainer'>
                                        <FoodCard key={foodItem.id} foodItemData={foodItem} />
                                    </div>
                                </div>
                            ))
                        )
                    }

                </>
            )}
        </div >
    );
}

export default SubSectionPage;