import { SET_POST_DATA } from '../actions/actions';

const postDataReducer = (state = null, action) => {
  switch (action.type) {
    case SET_POST_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default postDataReducer;
