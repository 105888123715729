export const changeLoadTrue = () => {
  return {
    type: "changeLoadTrue",
  };
};
export const changeLoadFalse = () => {
  return {
    type: "changeLoadFalse",
  };
};
export const getChangeLog = () => {
  return {
    type: "getChangeLog",
  };
};

export const openRegisterPageTrue = () => {
  return {
    type: "openRegisterPageTrue",
  };
};
export const openRegisterPageFalse = () => {
  return {
    type: "openRegisterPageFalse",
  };
};
export const ReloadPageTrue = () => {
  return {
    type: "reloadPageTrue",
  };
};

export const ReloadPageFalse = () => {
  return {
    type: "reloadPageFalse",
  };
};

export const setUsername = (name) => {
  return {
    type: "setName",
    payload: name,
  };
};

export const setSearchName = (name) => {
  return {
    type: "searchValue",
    payload: name,
  };
};

export const setCommentText = (text) => {
  return {
    type: "setCommentText",
    payload: text,
  };
};
export const searchClick = () => {
  return {
    type: "searchClick",
  };
};
export const commentImgClick = () => {
  return {
    type: "commentImgClicked",
  };
};
export const closeWindow = (load) => {
  return {
    type: "closeWindow",
    payload: load,
  };
};

export const closeForgetPassword = () => {
  return {
    type: "closeForgetPassword",
  };
};
export const openForgetPassword = () => {
  return {
    type: "openForgetPassword",
  };
};

export const setMenuList = (data) => {
  return {
    type: "menulist",
    payload: data,
  };
};

export const Menuselect = (menuId) => {
  return {
    type: "menuselection",
    payload: menuId,
  };
};

export const FoodItemList = (foods) => {
  return {
    type: "setfooditems",
    payload: foods,
  };
};

export const SearchLoader = (data) => {
  return {
    type: "setsearchloader",
    payload: data,
  };
};

export const SelectedMenu = (data) => {
  return {
    type: "setSelectedMenu",
    payload: data,
  };
};

export const SelectedSectionOrSubsection = (data) => {
  return {
    type: "setSelectedSectionOrSubsection",
    payload: data,
  };
};
