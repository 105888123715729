import React, { useRef, useState } from "react";
import cake from './../../Assets/Image/1 44 (1).png'
import star from './../../Assets/Icons/starBalck.png'
import './HorizontalPage.css'
import OtherOffers from './OtherOffers/OtherOffers'
import Recommendation from './Recommendation/Recommendation'
import { Link } from 'react-router-dom'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Navigation, Pagination, History } from "swiper";

function HorizontalPage() {
  return (
    <>
      


    <div className='pagePadding row horizontalSlider'>
        <div className='col-12 col-md-8'>
        
        <div class="wholeDivShadow mb-3  " >
            <div style={{padding:'20px 0px 0px 10px'}}>
                <h2 style={{color:'#f3cd8b'}}>Platinum</h2>
               
            </div>
            <hr ></hr>
            <div className="" style={{padding:'0.3rem 1rem 0rem 1rem'}}>
                <div class="row g-0">
                    
                    <div class="col-md-3 col-12 horizontalImg">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        pagination={true}
                        
                        
                        modules={[Navigation, Pagination, History]}
                        className="mySwiper"
                    >
                    <SwiperSlide data-history="1">
                        <img src={cake} class="img-fluid rounded-start" alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide data-history="2">
                        <img src={cake} class="img-fluid rounded-start" alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide data-history="3">
                        <img src={cake} class="img-fluid rounded-start" alt="..."/>
                    </SwiperSlide>
                    </Swiper>
                    </div>
                    <div class="col-12 col-md-9 p-4">
                        <div className='d-flex justify-content-between'>
                            <div class="">
                                <h5 class="card-title">Cheese-Stuffed Meatballs</h5>
                                <p class="card-text">Ground beef, string cheese, ground 

                                        pork, parmesan, olive oil</p>
                        
                            </div>
                            <div className=' d-flex flex-column align-items-end'>
                                <h5>10.5$</h5>
                                {/* <div className="quantityDiv">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Qty:</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                                </div> */}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-2 align-items-center cardratingsDiv'>
                            <div className='d-flex'>
                                <img src={star} alt='star'  />
                                <h3 >4.5</h3>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                <hr ></hr>
                <div class="row g-0">
                            
                                <div class="col-md-3 col-12 horizontalImg">
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    navigation={true}
                                    pagination={true}
                                    
                                    
                                    modules={[Navigation, Pagination, History]}
                                    className="mySwiper"
                                >
                                <SwiperSlide data-history="1">
                                <img src={cake} class="img-fluid rounded-start" alt="..."/>
                                </SwiperSlide>
                                <SwiperSlide data-history="2">
                                <img src={cake} class="img-fluid rounded-start" alt="..."/>
                                </SwiperSlide>
                                <SwiperSlide data-history="3">
                                <img src={cake} class="img-fluid rounded-start" alt="..."/>
                                </SwiperSlide>
                                </Swiper>
                                </div>
                                <div class="col-12 col-md-9 p-4">
                                    <div className='d-flex justify-content-between'>
                                        <div class="">
                                            <h5 class="card-title">Cheese-Stuffed Meatballs</h5>
                                            <p class="card-text">Ground beef, string cheese, ground 

                                                    pork, parmesan, olive oil</p>
                                    
                                        </div>
                                        <div className=' d-flex flex-column align-items-end'>
                                            <h5>10.5$</h5>
                                            {/* <div className="quantityDiv">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Qty:</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between mt-2 align-items-center cardratingsDiv'>
                                        <div className='d-flex'>
                                            <img src={star} alt='star'  />
                                            <h3 >4.5</h3>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                </div>
                <hr ></hr>
           </div>
            <div className="d-flex justify-content-end " style={{padding:'0rem 1.3rem 0.5rem 0rem'}}>
                <p style={{marginBottom:'0px'}}>Sub Total(5 items):<b> 50.5$</b></p>
            </div>
            </div>
     
        
        <div className='col-12 col-md-12'>
            <h3 style={{color:'#f2cd85'}}>Other Offers</h3>
        <OtherOffers></OtherOffers>
        </div>
        </div>

    
       
        <div className='col-md-4'>
            <div className="wholeDivShadow d-dlex flex-column justify-content-center">
                <div className="p-4 d-flex flex-column  align-items-center" >
                <p style={{marginBottom:'0px'}}>Sub Total(5 items):<b> 50.5$</b></p>
                <Link to='/orderPage'>
                <button className="btn "style={{backgroundColor:'#f3cd8b'}}>Proceed to checkout</button>
                </Link>
                </div>
            </div>
            <div className="pt-3">
            <Recommendation></Recommendation>
            </div>
        </div>
    </div>
    </>
  )
}

export default HorizontalPage