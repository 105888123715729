// const initialState = {
//   isSelected: false,
//   menuId:''
// }
const initialState = null;

const menuslected = (state = initialState, action) => {
  // switch (action.type) {
  //   case "menuselection":
  //     return {
  //       isSelected: !state.isSelected,
  //       menuId: action.payload,
  //     };
  //   default:
  //     return state;
  // }

  switch (action.type) {
    case "setSelectedMenu":
      return {
        menuId: action.payload,
      };
    default:
      return state;
  }
};
export default menuslected;
