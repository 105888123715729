import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import firstImg from './../../../Assets/Image/ChickenFry.jpg';
import secondImg from './../../../Assets/Image/image 35.png';
import thirdImg from './../../../Assets/Image/1 54.png';
import fourthImg from './../../../Assets/Image/1 (2).jpg';
import RestaurantCard from '../RestaurantComponents/RestaurantCard';
import './RestaurantSearch.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode } from 'swiper';
import axios from 'axios';
import backLeft from "../../../Assets/Icons/left.png";
import backRight from "../../../Assets/Icons/Nextx.png";
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import loadingGif from "./../../../Assets/Image/loading.gif";
import { sliceText } from '../../../utils/sliceText';

export default function RestaurantSearch() {
  const [blur, setBlur] = useState(false);
  const myRef = useRef();
  const [intersecting, setIntersecting] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    searchDataList: [],
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [clickedFilterId, setClickedFilterId] = useState(null);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const location = useLocation();
  const searchString = location.state;
  const [scrolling, setScrolling] = useState(false);
  // const [dataCollections, setDataCollections] = useState([]);
  // const [dataFilterCollectionss, setDataFilterCollections] = useState([]);
  // const [dataSelectedKeyword, setdataSelectedKeyword] = useState(null);

  // const [FoodItems, setFoodItems] = useState({
  //   foodItemList: [],
  //   totalCount: 0,
  // });

  // const apiAddress = process.env.REACT_APP_SECRET;

  const text = ['Burger King ttt', 'Pizza Hut'];

  const searchDialogOpen = (NavbarData) => {
    setBlur(NavbarData);
  };

  // const GetAllFoodPaged = async (pageNo, pageSize, initial = false) => {
  //   if (dataSelectedKeyword == null) {
  //     await axios
  //       .get(
  //         apiAddress +
  //           '/api/FoodItems/GetFoodItemForClientendPaged?pageNo=' +
  //           pageNo +
  //           '&pageSize=' +
  //           pageSize
  //       )
  //       .then((response) => {
  //         res.totalCount = response.data.totalCount;
  //         res.foodItemList.push(...response.data.fooditemsList);
  //         setTotalCount(response.data.totalCount);
  //         // dispatch(SearchLoader(false));
  //         setFoodItems((prev) => ({
  //           foodItemList: [
  //             ...prev.foodItemList,
  //             ...response.data.fooditemsList,
  //           ],
  //           totalCount: response.data.totalCount,
  //         }));
  //       });
  //     if (!initial) {
  //       setPageNumber(PageNumber + 1);
  //     }
  //     dispatch(FoodItemList(FoodItems));
  //   } else {
  //     GetFoodByKeywordTagPaged(dataSelectedKeyword, pageNo, pageSize);
  //   }

  //   return res;
  // };

  // useEffect(() => {
  //   var res = GetAllFoodPaged(1, 4, true);
  //   res.then((res) => {
  //     dispatch(FoodItemList(res));
  //   });
  //   axios
  //     .get(process.env.REACT_APP_SECRET + '/api/Keyword/GetALlKeywordTag')
  //     .then((response) => {
  //       setDataCollections(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // const getCategoryData = useCallback(async () => {
  //   try {
  //     setError("");
  //     setLoading(true);
  //     const response = await axios.get(`https://localhost:7175/api/FoodCategory/getFoodCategory`);
  //     console.log("category data: ", response.data);
  //     setLoading(false);
  //     setCategoryData(response.data);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //     setError(error.response.data);
  //   }
  // }, []);

  // useEffect(() => {
  //   getCategoryData();
  // }, [getCategoryData]);

  const getSearchedRestaurant = async (pageNo, pageSize, initial = false) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SECRET}/api/Search/GetRestaurantPagedData?searchString=${searchString}&pageNumber=${pageNo}&pageSize=${pageSize}`);
      if (response.data) {
        setSearchData((prevData) => ({
          searchDataList: initial
            ? response.data.restaurants
            : [...prevData.searchDataList, ...response.data.restaurants],
          totalCount: response.data.totalCount,
        }));
      }
    } catch (error) {
      console.log(error);
    }
    if (!initial) {
      setPageNumber(pageNumber + 1);
    }
  }

  useEffect(() => {
    getSearchedRestaurant(1, 2, true);
  }, [searchString]);

  useMemo(() => {
    if (searchData.searchDataList.length > 0) {
      const categories = searchData.searchDataList.flatMap((item) => item.categories);

      const uniqueFilters = categories.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.categoryId === value.categoryId
        ))
      );

      setCategoryData(uniqueFilters)
      console.log("searched data restauratn: ", categories, uniqueFilters);
    }
  }, [searchData]);

  const handleClickFilter = (id) => {
    console.log("searched data restauratn 2: ", searchData)
    if (id === null) {
      // "All" filter clicked: reset to show all items
      setClickedFilterId(null);  // Deselect any filter
      setIsFilterClicked(false); // "All" filter selected
      setFilteredItems([]);      // Reset filtered items (show all)
    } else if (clickedFilterId === id) {
      // The same filter clicked again: deselect it and select "All"
      setClickedFilterId(null);  // Deselect the filter
      setIsFilterClicked(false); // Select "All"
      setFilteredItems([]);      // Reset filtered items (show all)
    } else {
      // New filter clicked: apply the filter and update state
      const filtered = searchData.searchDataList.filter((item) =>
        item.categories.some((category) => category.categoryId === id)
      );
      setClickedFilterId(id); // Set the selected filter ID
      setIsFilterClicked(true); // Mark that a filter is active (not "All")
      setFilteredItems(filtered); // Apply the filtered items
    }
  }

  const dataCollections = [
    {
      id: 1,
      keywordTagName: 'Starter',
      keywordTagImage:
        'https://t4.ftcdn.net/jpg/01/07/93/25/360_F_107932517_bRTDt5PCP4mOxlnsifzR6kXxkR3xi8QA.jpg',
    },
    {
      id: 2,
      keywordTagName: 'Main Dish',
      keywordTagImage:
        'https://media.istockphoto.com/id/1403973419/photo/table-top-of-food-spread-on-table.jpg?s=612x612&w=0&k=20&c=2cROUMurZUtuvqF-bp8lViZ0wDXBNkZBcIjQj2QQlec=',
    },
    {
      id: 3,
      keywordTagName: 'Desserts',
      keywordTagImage:
        'https://images.pexels.com/photos/1126359/pexels-photo-1126359.jpeg?cs=srgb&dl=pexels-suzy-hazelwood-1126359.jpg&fm=jpg',
    },
    {
      id: 4,
      keywordTagName: 'Drinks',
      keywordTagImage:
        'https://t4.ftcdn.net/jpg/01/07/93/25/360_F_107932517_bRTDt5PCP4mOxlnsifzR6kXxkR3xi8QA.jpg',
    },
    {
      id: 5,
      keywordTagName: 'Burger',
      keywordTagImage:
        'https://media.istockphoto.com/id/1482650278/photo/juicy-ground-pork-and-bacon-cheeseburger-with-fries.webp?b=1&s=170667a&w=0&k=20&c=M-c-lOquS6GsMnBk1FAwfwBJ6-bpOmCUADlaFKxajHU=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
    {
      id: 6,
      keywordTagName: 'Appetizer',
      keywordTagImage:
        'https://media.istockphoto.com/id/174987545/photo/canapes-for-party.jpg?s=612x612&w=0&k=20&c=RtZXWZtOVWK3J5xKERls-o74EWdAXLP3y7S0IChfqOg=',
    },
  ];

  const dataSelectedKeyword = '';

  useEffect(() => {
    const swiper = document.querySelector('.swiper').swiper;
    swiper.params.navigation.prevEl = '.custom-prev-button';
    swiper.params.navigation.nextEl = '.custom-next-button';
    swiper.navigation.init();
    swiper.navigation.update();
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const content = document.getElementsByClassName("FilterBarMain")[0];
      if (content) {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollTop > lastScrollTop) {
          // Scrolling down
          content.style.transition = "all 0.3s ease";
          content.style.background = "white";
          content.style.top = "-90px";
        } else {
          // Scrolling up
          content.style.transition = "all 0.3s ease";
          content.style.background = "white";
          content.style.top = "-10px";
          setScrolling(true);
        }
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      }

      content.style.position = "fixed";
      content.style.width = "100%";
      content.style.zIndex = "100";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  //#region Swiper Navigation Button functionality

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const handleSlideChange = (swiper) => {

    if (swiper.isBeginning) {
      setShowPrev(false);
    } else {
      setShowPrev(true);
    }

    if (swiper.isEnd) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  useEffect(() => {
    const content = document.querySelector('.swiper-horizontal');
    if(content) {
      const isScrollable = content.scrollWidth > content.clientWidth;
      if (isScrollable) { setShowNext(true); }
    }
  }, [categoryData]);
  
  //#endregion

  return (
    <div>
      <div>
        <Navbar handleNavbar={searchDialogOpen} showSeeMore={false} />
      </div>

      {/* <div className='FoodSelection customContainer pt-4'>
        <div
          className={` g-4 mx-auto justify-content-center my-5 selectionButton scrollbtnMobile  ${
            intersecting ? 'HideSelectionSingleButton' : ''
          }`}
          ref={myRef}
        >
          <Swiper
            className='menusliderDiv menuslider row row-cols-1  row-cols-md-4'
            spaceBetween={30}
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={'auto'}
            hashNavigation={{
              watchState: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
          >
            {dataCollections?.map((d, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`d-flex cardShadow  align-items-center justify-content-between p-3 py-3 m-2 selectionSingleButton ${
                    dataSelectedKeyword === d.id ? ' pillCardSelect' : ''
                  }`}
                  // onClick={() => handleItemClick(d.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <h2>{d.keywordTagName}</h2>
                  <img
                    className='img-fluid'
                    src={d.keywordTagImage}
                    alt='...'
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> */}
      {scrolling && <div style={{ height: "160px" }}></div>}
      <div className="FoodSelection FilterBarMain customContainer pt-4 mt-4">
        <div className={`g-4 mx-auto justify-content-center mt-5 selectionButton scrollbtnMobile  ${intersecting ? "HideSelectionSingleButton" : ""}`} ref={myRef} >
          <div className="swiper-container">

            {/* <button className="custom-prev-button">
              <img src={backLeft} alt="left icon" />
            </button>
            <button className="custom-next-button">
              <img src={backRight} alt="right icon" />
            </button> */}

            <button ref={prevRef} style={{ display: showPrev ? "" : "none" }} className="custom-prev-button prev-button">
              <img src={backLeft} alt="left icon" />
            </button>
            <button ref={nextRef} style={{ display: showNext ? "" : "none" }} className="custom-next-button next-button">
              <img src={backRight} alt="right icon" />
            </button>

            <Swiper
              modules={[Navigation]}
              spaceBetween={3}
              onSlideChange={handleSlideChange}
              slidesPerView={20}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              // pagination={{ clickable: true }}
              breakpoints={{
                1000: {
                  slidesPerView: 12,
                },
                700: {
                  slidesPerView: 6,
                },
                500: {
                  slidesPerView: 5,
                },
                300: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            >

              <SwiperSlide
                className={`slider-content ${clickedFilterId === null ? "active-filter" : ""}`}
                onClick={() => handleClickFilter(null)} // Clicking "All" deselects any filter
              >
                <div className="keyword">
                  <div className="filter-img">
                    <img
                      src={"https://gotastelivestorage.blob.core.windows.net/gotasteimages/5f3632b2-78db-416c-a337-7af934c77e4f"}
                      alt="slider"
                    />
                  </div>
                  <p className="filter-name">All</p>
                </div>
              </SwiperSlide>

              {categoryData?.map((d, index) => (
                <SwiperSlide
                  key={index}
                  className={`slider-content ${clickedFilterId === d.categoryId ? "active-filter" : ""}`}
                  onClick={() => handleClickFilter(d.categoryId)} // Clicking "All" deselects any filter
                >
                  <div className="keyword">
                    <img
                      className="filter-img"
                      src={d.categoryImagePath ? d.categoryImagePath : "https://gotastelivestorage.blob.core.windows.net/gotasteimages/5f3632b2-78db-416c-a337-7af934c77e4f"}
                      alt="..."
                    />
                    <p className='filter-name large-tooltip' title={d.categoryName}>{sliceText(d.categoryName, 10)}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      {/* Fooditem section */}
      <div className='restaurantCards pb-4'>
        {/* <div className='row' style={{ marginTop: '1rem' }}> */}
        <div>
          {/* <div
            className='col-12 d-flex flex-wrap justify-content-center pt-4'
            style={{ gap: '20px' }}
          > */}
          {
            isFilterClicked ?
              filteredItems.length > 0
                ?
                <div className='px-4'>
                  {
                    filteredItems?.map((dt, idx) => <RestaurantCard key={idx} text={text[0]} restaurant={dt} img1={firstImg} img2={secondImg} img3={thirdImg} img4={fourthImg} />)
                  }
                </div>
                :
                <div className='default-text'>No food item available</div>
              :
              <div className='px-4'>
                <InfiniteScroll
                  dataLength={searchData.searchDataList.length}
                  next={() => getSearchedRestaurant(pageNumber + 1, 2)}
                  hasMore={searchData.searchDataList.length < searchData.totalCount}
                  loader={
                    <div className="d-flex justify-content-center ">
                      <img src={loadingGif} alt="" />
                    </div>
                  }
                >
                  <div>
                    {searchData.searchDataList.map((dt, i) => {
                      return <RestaurantCard text={text[0]} restaurant={dt} img1={firstImg} img2={secondImg} img3={thirdImg} img4={fourthImg} />;
                    })}
                  </div>
                </InfiniteScroll>
                {/* <RestaurantCard text={text[0]} img1={firstImg} img2={secondImg} img3={thirdImg} img4={fourthImg} />
            <RestaurantCard
              text={text[1]}
              img1={firstImg}
              img2={secondImg}
              img3={thirdImg}
              img4={fourthImg}
            /> */}
              </div>
          }
        </div>
      </div>
    </div>
  );
}
