import axios from "axios";
import { FETCH_FILTER_BAR_DATA } from "./../types";

const apiAddress = process.env.REACT_APP_SECRET;

export const fetchFilterBarData = (resUrl) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiAddress}/api/Filterbar/getallsectionSubsectionByRestaurantUrl`, {
                params: { restaurntUrl: resUrl }
            });
            dispatch({
                type: FETCH_FILTER_BAR_DATA,
                payload: response.data
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error if needed
        }
    };
};

